import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./suggest.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import AutoCompletionButton from "Components/AutoCompletionButton/AutoCompletionButton.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";

class RiskSuggest extends Page {

	constructor () {

		let routes = ["/cartographies/:idCartography/risk/suggest"];

		super(routes, AuthentifiedLayout, "Proposition de création d'un nouveau risque", [
			context,
			users,
			cartographies,
			branches,
			legalEntities
		]);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		menu.show();

		this.parseAndRender("page", html);

		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We define the id of the current cartography
		this.idCartography = params.idCartography;

		// We define the current cartography data
		this.cartographyData = cartographies.find(this.idCartography);

		// We get the current branch
		this.idBranch = context.getCurrentBranchId();

		// We get the current legal entity
		this.idLegalEntity = context.getCurrentLegalEntityId();

		// We initialize the page title
		this.initPageTitle();

		// We initialize the theme list
		this.initThemes();

		// We initialize the others risk list
		this.initOtherPotentialRisk();

		// We initialize the rich text editor
		this.initTextEditor();

		// We add translate buttons
		this.addTranslateButtons();

		// We add auto completion buttons
		if (process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === true || process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === "true") {

			this.addAutoCompletionButtons();

		}

	}

	initPageTitle () {

		// We get the current cartography name
		const curCartographyName = cartographies.find(this.idCartography)[`name_${this.currentLang}`];

		// We show the current cartography name on the page title
		$(".title-page h2").html(curCartographyName);

	}

	initTextEditor () {

		// Description Editor FR
		ClassicEditor.
	    create(document.querySelector("#description-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorDescriptionFr = editor;


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	  // Description Editor EN
		ClassicEditor.
	    create(document.querySelector("#description-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorDescriptionEn = editor;


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	addTranslateButtons () {

		// Title FR
		const translationButtonForTitleFr = new FieldTranslationButton("title_fr", "text", "fr");

		// Title EN
		const translationButtonForTitleEn = new FieldTranslationButton("title_en", "text", "en");

		setTimeout(() => {

			// Description FR
			const translationButtonForDescriptionFr = new FieldTranslationButton("description_fr", "textarea", "fr", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

			// Description EN
			const translationButtonForDescriptionEn = new FieldTranslationButton("description_en", "textarea", "en", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

		}, 100);

	}

	addAutoCompletionButtons () {

		setTimeout(() => {

			// Description FR
			const autoCompletionForDescriptionFr = new AutoCompletionButton("title_fr", "description_fr", this.richTextEditorDescriptionFr, this.cartographyData);

			// Description EN
			const autoCompletionForDescriptionEn = new AutoCompletionButton("title_en", "description_en", this.richTextEditorDescriptionEn, this.cartographyData);

		}, 100);

	}

	initThemes () {

		let cartography = cartographies.find(this.idCartography);

		let themesOptions = "<option></option>";
		$(cartography.themes).each((i, theme) => {

			let themeLib = theme.lib[this.currentLang];
			themesOptions += `<option value=${theme.slug}>${themeLib}</option>`;

		});

		$("select[name='theme']").html(themesOptions);

	}

	initOtherPotentialRisk () {

		let cartography = cartographies.find(this.idCartography);

		let otherPotentialRiskHTML = "";
		$(cartography.other_potential_risks).each((i, opr) => {

			otherPotentialRiskHTML += `
				<div class="line-checkbox">
					<div class="item-checkbox">
            <input id="consequences[${opr.slug}]" type="checkbox" name="consequences[${opr.slug}]" value="1" />
						<label for="consequences[${opr.slug}]">
              <i class="icon ${opr.icon}"></i>
              <span class="text">${opr.lib[this.currentLang]}</span>
            </label>
					</div>
        </div>`;

		});

		$(".box-consequencies").append(otherPotentialRiskHTML);

	}

	addRPOOptions () {

		cartographies.getPossibleRPOForCartography(this.idCartography).then((users) => {

			$(users).each((i, userToAdd) => {

				$("FORM [name=rpo_id]").append(`<option value='${userToAdd.id}'>${userToAdd.standard_name}</option>`);

			});

		}).catch((error) => {

			debug.v("RiskSuggest - addRPOOptions - error in User getPossibleRPOForCartography");

		});

		// We show the current cartography name on RPO tooltip
		let cartography = cartographies.find(this.idCartography);
		$("#tooltip-name-cartography").html(cartography.name);

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("create_risk");

		this.createdRisk = updateObjectWithForm({}, "#suggest_risk");

		this.createdRisk.description_fr = this.richTextEditorDescriptionFr.getData();
		this.createdRisk.description_en = this.richTextEditorDescriptionEn.getData();

		// We add a status field and we define the value to "pending"
		this.createdRisk.status = "pending";

		this.createdRisk.cartography_id = this.idCartography;

		risks.createOne(this.createdRisk).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("RiskSuggest - createSuccess");

		risks.getData(this.params).then(() => {

			router.navigate(`dashboard-grid/branches/${this.idBranch}/cartographies/${this.params.idCartography}/legal-entities/${this.idLegalEntity}`);

			// We add a class on the global container to hide the select of legal entity
			$("#content").addClass("new-risk");


		}).catch((error) => {

			debug.e("RiskSuggest - createSuccess - error in Risks getData");

		});

	}

	createFail (response, textStatus) {

		debug.v("RiskSuggest - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"title_fr": {
					"required": `${$.i18n("form-error-title-required")}`,
					"required_with_description_fr": `${$.i18n("form-error-title-required_with_description_fr")}`
				},
				"title_en": {
					"required": `${$.i18n("form-error-title-required")}`,
					"required_with_description_en": `${$.i18n("form-error-title-required_with_description_en")}`
				},
				"description_fr": {
					"fieldSelector": "FORM .richText",
					"required": `${$.i18n("form-error-description-required")}`,
					"required_with_title_fr": `${$.i18n("form-error-description-required_with_title_fr")}`
				},
				"description_en": {
					"fieldSelector": "FORM .richText",
					"required": `${$.i18n("form-error-description-required")}`,
					"required_with_title_en": `${$.i18n("form-error-description-required_with_title_en")}`
				},
				"theme": {
					"required": `${$.i18n("form-error-theme-required")}`
				},
				"rpo_id": {
					"required": `${$.i18n("form-error-rpo-required")}`
				},
				"origin": {
					"required": `${$.i18n("form-error-origin-required")}`
				}
			};

			// We remove the error messages
			$("#suggest_risk .msg-error").remove();

			// We show the errors
			showFormErrors("#suggest_risk", response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(".error").eq(0).parents("li").offset().top;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			// We remove the global error
			$("#global-error").remove();

			// We show the global error
			$("FORM").prepend(`<div class='error' id="global-error">${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new RiskSuggest();
