import router from "Core/router.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class HelpFirstActionItems extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData () {

		let data = [
			{
				"id": "help-after-first-risk-evaluation",
				"content": $.i18n("content-help-after-first-risk-evaluation"),
				"focus_html_block_primary": ".risk-container .header .btn-publish",
				"page_path": "/risks/",
				"page_path_exclude_1": "/evaluate",
				"page_path_exclude_2": "/edit",
				"page_path_exclude_3": "/create",
				"page_path_exclude_4": "/select-business-activities",
				"page_path_exclude_5": "#actions",
				"max_displays": 1
			}
		];

		this.updateData(data);

		return this.data;

	}

	find (idToFind) {

		return this.data.find((helpFirstActionItem) => helpFirstActionItem.id === idToFind);

	}

}
export default new HelpFirstActionItems();
