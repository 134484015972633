import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import riskEvaluateForm from "Components/RiskEvaluateForm/RiskEvaluateForm.js";
import actionContainer from "Components/Action/ActionContainer.js";
import html from "./evaluate_for_business_activities.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import actions from "Datapoints/Actions.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";

class RiskEvaluateForBusinessActivities extends Page {

	constructor () {

		let routes = [
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/evaluate-for-business-activities",
			"/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity/cartographies/:idCartography/risks/:idRisk/evaluate-for-business-activities",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/evaluate-for-business-activities",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity/cartographies/:idCartography/risks/:idRisk/evaluate-for-business-activities"
		];

		let datapointsToLoad = [
			users,
			cartographies,
			legalEntities,
			branches,
			countries,
			profitCenters,
			businessActivities,
			actions,
			context
		];

		super(routes, AuthentifiedLayout, "Évaluation d'un risque", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEvaluateThisRisk.bind(this)
		];

	}

	canCurrentUserEvaluateThisRisk (params) {

		return new Promise((resolve, reject) => {

			let risk = risks.find(params.idRisk);

			if (user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography})) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		this.params = params;

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We init events
		this.initEvents();

	}

	initEvents (params) {

		// We show the risk title
		this.showRiskTitle();

		// If there is no business activity selected
		const curPathUrl = window.location.pathname;
		if (curPathUrl.indexOf("/business-activities/") < 0) {

			// We hide the list of business activities options
			$("#name-business-activity .option-selected").hide();

			// We define the title to show
			const htmlOption = `<span class="title-business-activity msg-error" data-i18n="msg-no-business-activity-selected-for-context">${$.i18n("msg-no-business-activity-selected-for-context")}</span>`;

			// We show the title
			$("#name-business-activity").append(htmlOption);

		}

		// We init the list of business activities
		this.initBusinessActivitiesList();

		// We listen click on button evaluate for all business activities
		$("#btn-evaluate-all-business-activities").on("click", this.showLegalEntityForm.bind(this));

		// We listen click on button evaluate for each business activity
		$("#btn-evaluate-each-business-activity").on("click", this.showBusinessActivitiesForms.bind(this));

		// We listen click on the back button
		$(".btn-back-previous-page").on("click", this.handleClickOnBtnBack.bind(this));

	}

	initBusinessActivitiesList () {

		// We load the business activities of the legal entity
		legalEntities.getBusinessActivities(this.params.idLegalEntity).then((data) => {

			if (data !== undefined && data !== null && data.length > 0) {

				// For each business activity
				for (let iBusinessActivity = 0; iBusinessActivity < data.length; iBusinessActivity++) {

					const curBusinessActivity = data[iBusinessActivity];
					const curBusinessActivityId = parseInt(curBusinessActivity.id);
					const curBusinessActivityName = curBusinessActivity[`name_${$.i18n().locale}`];

					// We define the item html
					const htmlItem = `<li class="item-business-activities">
			        <span class="icon fas fa-building"></span>
			        <span class="text">${curBusinessActivityName}</span>
			      </li>`;

			    // We add the item to the list
			    $("#section-list-business-activities ul").append(htmlItem);

				}

				if (data.length > 1) {

					// We show the intro section
					$("#section-intro").addClass("active");

				} else {

					// We remove the intro section
					$("#section-intro").remove();

					// We show the legal entity evaluation form
					this.showLegalEntityForm();

				}

			}

		});

	}

	showRiskTitle () {

		// We load the risks
		risks.getRisksCartography(this.params.idCartography).then(() => {

			// We get the risk
			const curRiskData = risks.find(this.params.idRisk);

			// We show the risk title
			if (curRiskData[`title_${this.currentLang}`] !== "" && curRiskData[`title_${this.currentLang}`] !== null) {

				$(".title-page h1 .risk-title").html(curRiskData[`title_${this.currentLang}`]);

			} else if (this.currentLang === "fr" && curRiskData.title_en !== null) {

				$(".title-page h1 .risk-title").html(curRiskData.title_en);

			} else if (this.currentLang === "en" && curRiskData.title_fr !== null) {

				$(".title-page h1 .risk-title").html(curRiskData.title_fr);

			} else {

				$(".title-page h1 .risk-title").html("");

			}

		});

		$(".title-page h1 .risk-title").on("click", (event) => {

			// We show the risk page
			router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/business-activities/all`);

		});

	}

	showLegalEntityForm () {

		// We define the current button in intro section
		const buttonSelector = $("#btn-evaluate-all-business-activities");

		// We show the section with the list of business activities
		$("#section-list-business-activities").addClass("active");

		// We load the business activities of the legal entity
		legalEntities.getBusinessActivities(this.params.idLegalEntity, {"forceRequest": true}).then((data) => {

			if (data !== undefined && data !== null && data.length > 0) {

				// We add the form to the section
				const formSelector = $("#section-form");
				riskEvaluateForm.show(formSelector, this.params, null);

			}

		});

		// We clear the form section (the form can be already showed)
		this.clearSectionForm();

		// We show the form section
		this.displaySectionForm(buttonSelector);

	}

	showBusinessActivitiesForms () {

		// We define the current button in intro section
		const buttonSelector = $("#btn-evaluate-each-business-activity");

		// We remove the section with the list of business activities
		$("#section-list-business-activities").removeClass("active");

		// We load the business activities of the legal entity
		legalEntities.getBusinessActivities(this.params.idLegalEntity).then((data) => {

			if (data !== undefined && data !== null && data.length > 0) {

				// For each business activity
				for (let iBusinessActivity = 0; iBusinessActivity < data.length; iBusinessActivity++) {

					const curBusinessActivity = data[iBusinessActivity];
					const curBusinessActivityId = parseInt(curBusinessActivity.id);
					const curBusinessActivityName = curBusinessActivity[`name_${$.i18n().locale}`];

					// We define the html of the section form item
					const htmlItemSectionForm = `<div class="item-section-form active" id="container-form-business-activity-${curBusinessActivityId}">
						<div class="line-title-item">
							<span class="text">${curBusinessActivityName}</span>
							<span class="icon fas fa-angle-up"></span>
						</div>
						<div class="line-content-item"></div>
					</div>`;

					// We show the item
					$("#section-form").append(htmlItemSectionForm);

					// We add the form
					const formSelector = $(`#container-form-business-activity-${curBusinessActivityId} .line-content-item`);
					riskEvaluateForm.show(formSelector, this.params, curBusinessActivityId);

				}

			}

			// We listen click on title of the form item
			$(".item-section-form .line-title-item").on("click", this.handleClickOnItemSectionForm.bind(this));

		});

		// We clear the form section (the form can be already showed)
		this.clearSectionForm();

		// We show the form section
		this.displaySectionForm(buttonSelector);

	}

	handleClickOnItemSectionForm (e) {

		const curItem = $(e.currentTarget).parents(".item-section-form");

		if (curItem.attr("class").indexOf("active") >= 0) {

			// We hide the form
			curItem.removeClass("active");

		} else {

			// We show the form
			curItem.addClass("active");

		}


	}

	handleClickOnBtnBack () {

		// We show the risk page
		router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/business-activities/all#evaluations`);

	}

	clearSectionForm () {

		// We clear active buttons
		$("#section-intro .btn-intro").removeClass("active");

		// We remove the contents on the form section
		$("#section-form").html("");

		// We remove the active class on the form section
		$("#section-form").removeClass("active");

	}

	displaySectionForm (curButton) {

		// We change the style of the clicked button
		curButton.addClass("active");

		// We show the form section
		$("#section-form").addClass("active");

	}

}
export default new RiskEvaluateForBusinessActivities();
