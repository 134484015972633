import axios from "axios";
import html from "./auto_completion_button.html";
// OpenAI response exceptions
import openAiAutoCompletionFieldResponseExceptions from "Core/assets/json/openai_auto_completion_field_response_exceptions.json";
import AI from "Datapoints/AI.js";

class AutoCompletionButton {

	constructor (inputFieldName, outputFieldName, outputCKEditor, cartographyData) {

		// NOTES :
		// inputFieldName = the name of the title field
		// outputFieldName = the name of the desccription field
		// outputCKEditor = the object of the description CKEditor
		// cartographyData = current cartography data

		// We get the current language
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We init global vars
		this.inputFieldName = inputFieldName;
		this.outputFieldName = outputFieldName;
		this.outputCKEditor = outputCKEditor;
		this.inputFieldContainer = $(`[name='${this.inputFieldName}']`).parents("li");
		this.outputFieldContainer = $(`[name='${this.outputFieldName}']`).parents("li");
		this.fieldLanguage = this.inputFieldName.slice(-2);
		this.cartographyName = cartographyData[`name_${this.fieldLanguage}`];
		this.cartographyId = cartographyData.id;

		// We construct html
		this.appendAndFill();

		// We init global events
		this.initGlobalEvents();

	}

	appendAndFill () {

		// We add a margin bottom on the label
		this.outputFieldContainer.find(".label").css("margin-bottom", "20px");

		// We add the wrap button into the output field container
		this.outputFieldContainer.prepend(html);

		// We update the wrap button state
		this.updateWrapButtonState();

		// We recall the translation
		$("html").i18n();

	}

	updateWrapButtonState () {

		// We get the input field value
		const inputFieldValue = $(`[name='${this.inputFieldName}']`).val();

		if (inputFieldValue !== undefined && inputFieldValue !== null && inputFieldValue !== "") {

			// We remove the inactive state on the wrap button
			this.outputFieldContainer.find(".wrap-auto-completion-field").removeClass("inactive");

		} else {

			// We add the inactive state on the wrap button
			this.outputFieldContainer.find(".wrap-auto-completion-field").addClass("inactive");

		}

		// We update the tooltip content
		this.updateTooltipContent();

	}

	updateTooltipContent () {

		// We get the wrap output field class
		const wrapOutputFieldClass = this.outputFieldContainer.find(".wrap-auto-completion-field").attr("class");

		// We define the tootltip content
		let tooltipContent;
		if (wrapOutputFieldClass.indexOf("inactive") >= 0) {

			tooltipContent = $.i18n("tooltip-auto-completion-risk-description-inactive");

		} else {

			tooltipContent = $.i18n("tooltip-auto-completion-risk-description-active");

		}

		// We show the tooltip content
		this.outputFieldContainer.find(".wrap-auto-completion-field .tooltip .text").html(tooltipContent);

	}

	initGlobalEvents () {

		// We listen change event for input field
		$(`input[name='${this.inputFieldName}']`).on("change paste keyup input propertychange", this.handleChangeOnInputField.bind(this));

		const watchStateChange = setInterval(() => {

		  // We update the wrap button state
			this.updateWrapButtonState();

		}, 3000);

		// We listen click on the auto completion button
		this.outputFieldContainer.find(".btn-auto-completion-field").on("click", this.handleClickOnAutoCompletionFieldButton.bind(this));

	}

	handleChangeOnInputField (e) {

		// We update the wrap button state
		this.updateWrapButtonState();

	}

	handleClickOnAutoCompletionFieldButton (e) {

		// We define the current button
		const curButton = $(e.currentTarget);

		// We remove the error
		this.outputFieldContainer.find(".error").remove();

		// We show a loader on the field
		this.outputFieldContainer.find(".wrap-field").addClass("loading");
		this.outputFieldContainer.find(".wrap-field").prepend("<div class='field-loader'></div>");

		// We get the input field value
		const inputFieldValue = $(`input[name='${this.inputFieldName}']`).val();

		// We define the output language text for the prompt message
		let promptOutputLanguageText;
		if (this.fieldLanguage === "fr") {

			promptOutputLanguageText = "français";

		} else {

			promptOutputLanguageText = "anglais";

		}

		// We get the suggestion
		AI.getRiskDetail(inputFieldValue, this.fieldLanguage, this.cartographyId).then((response) => {

			// We remove the loader
			this.outputFieldContainer.find(".wrap-field").removeClass("loading");
			this.outputFieldContainer.find(".wrap-field").find(".field-loader").remove();

			this.outputCKEditor.setData(response.risk_detail);

		}).catch((error) => {

			// We remove the error
			this.outputFieldContainer.find(".wrap-field").find(".error").remove();

			// We remove the loader
			this.outputFieldContainer.find(".wrap-field").removeClass("loading");
			this.outputFieldContainer.find(".wrap-field").find(".field-loader").remove();

			// We define the error message
			const textErrorToShow = $.i18n("error-translation-field-global");

			// We define the html for the error to show
			const htmlErrorToShow = `<div class="error msg-error"><i class="icon fas fa-exclamation-triangle"></i>${textErrorToShow}</div>`;

			// We show the error
			this.outputFieldContainer.find(".wrap-field").append(htmlErrorToShow);

	     });

	}

}
export default AutoCompletionButton;
