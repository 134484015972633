import router from "Core/router.js";
import Page from "Pages/Page.js";
import debug from "Core/Debug.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./risk_evaluate_form.html";
import user from "Datapoints/User";
import risks from "Datapoints/Risks.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import scales from "Datapoints/Scales.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import actions from "Datapoints/Actions.js";
import {showFormErrors, updateObjectWithForm, slugify, replaceSpecialCharacters} from "Core/helpers.js";

class RiskEvaluateForm {

	show (jQuerySelector, params, idBusinessActivity) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		jQuerySelector.append(html);

		// We define global vars
		this.params = params;
		this.idRisk = this.params.idRisk;
		this.richTextEditor = {};
		this.legalEntity = legalEntities.find(this.params.idLegalEntity);
		this.previousPage = localStorage.getItem("navigation-previousPageUrl");

		// We get the current cartography
		this.curCartography = cartographies.find(this.params.idCartography);

		// We define if evaluation is for business activities
		if (idBusinessActivity !== undefined && idBusinessActivity !== null) {

			this.idBusinessActivity = parseInt(idBusinessActivity);
			this.isEvaluationForBusinessActivities = true;

		} else {

			this.idBusinessActivity = "all";
			this.isEvaluationForBusinessActivities = false;

		}

		// We initialize cartography impacts
		this.initCartographyImpacts();

		// We initialize global events
		this.initEvents(idBusinessActivity);

		// We setup the page for control mode (and not progress margin)
		this.initControlMode();

	}

	initControlMode () {

		if (process.env.SHOW_PROGRESS_MARGIN === undefined || process.env.SHOW_PROGRESS_MARGIN === false || process.env.SHOW_PROGRESS_MARGIN === "false") {

			// We add a class on the help tooltip of the control field to reverse the color graph
			$("input[name*='control']").parents("li").find(".help .tooltip").addClass("reverse");

			// We add the reverse class on the control scale
			$("[id^='scale-control'] .scale-table").addClass("reverse");

		}

	}

	initEvents (idBusinessActivity) {

		// We check the evaluations for the current cartography
		cartographies.getEvaluation(this.params.idCartography, this.params.idLegalEntity).then((evaluations) => {

			// We check if there is evaluations for the current risk
			const curRiskEvaluations = evaluations.filter((evaluation) => evaluation.risk_id === this.idRisk);

			if (curRiskEvaluations !== undefined && curRiskEvaluations.length > 0) {

				// We remove an item in the local storage
				localStorage.removeItem("risk-isCurrentRiskNotEvaluated");

			} else {

				// We save in the local storage that the risk is not evaluated
				localStorage.setItem("risk-isCurrentRiskNotEvaluated", true);

			}


		}).catch((error) => {

			debug.v("RiskEvaluateForm - initEvents - error in Cartographies getEvaluation");

		});


		// We initialize the evaluation
		this.evaluation = {};

		// We create the form
		this.createForm(this.idBusinessActivity);

		// We get contents translations
		this.getTranslations();

		// We initialize the scales
		this.initScales();

		// We hide the btn submit
		$(`#evaluate_form_${this.idBusinessActivity} .btn-submit`).hide();

		// We listen change on fields
		$(`#evaluate_form_${this.idBusinessActivity} input.text-field`).on("input", this.handleFieldChange.bind(this));

		// We listen change on checkbox
		$(`#evaluate_form_${this.idBusinessActivity} input[name='risk_ignored']`).on("change keypress keyup keydown", this.handleRiskIgnoredChange.bind(this));

		// We listen change on all scores fields
		$(`#evaluate_form_${this.idBusinessActivity} input[name*='scores']`).on("change keypress keyup keydown", this.handleScoreChange.bind(this));

		// We listen change on impact scores fields
		$(`#evaluate_form_${this.idBusinessActivity} input[name^='scores[impacts]']`).on("change keypress keyup keydown", this.handleImpactChange.bind(this));

		// We add the link on cancel button
		if (this.previousPage !== undefined && this.previousPage !== null) {

			$(`#evaluate_form_${this.idBusinessActivity} .btn-cancel`).attr("href", `${this.previousPage}#evaluations`);

		} else {

			$(`#evaluate_form_${this.idBusinessActivity} .btn-cancel`).attr("href", `/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/business-activities/all#evaluations`);

		}

		// We listen submit on the form
		$(`#evaluate_form_${this.idBusinessActivity} .btn-submit`).on("click", this.handleSubmit.bind(this));

	}

	initCartographyImpacts () {

		// We get the current cartography impacts
		const cartographyImpacts = this.curCartography.impacts;

		// We sort impacts by order descending
		const impactsSortByOrderDescending = cartographyImpacts.sort((a, b) => b.order - a.order);

		// For each impact
		$(impactsSortByOrderDescending).each((i, impact) => {

			// We define the html for the impact field
			const htmlImpactField = `
				<li class="third-width-field container-field-impact">
	        <div class="label">
	          <i class="icon fa-solid ${impact.picto}"></i>
	          <span class="text">${impact[`name_${this.currentLang}`]}</span>
	          <div class="help">
	            <div class="icon fas fa-info-circle"></div>
	            <div class="tooltip tooltip-scale">
	              <div class="text"><span data-i18n="risk-evaluate-tooltip-score">Note de 1 à 6</span></div>
	              <div class="ban-scale">
	                <div class="scale-graph">
	                  <div class="scale-graph-item green"></div>
	                  <div class="scale-graph-item yellow"></div>
	                  <div class="scale-graph-item orange"></div>
	                  <div class="scale-graph-item red"></div>
	                </div>
	                <div class="scale-text">
	                  <ul>
	                    <li>1</li>
	                    <li>2</li>
	                    <li>3</li>
	                    <li>4</li>
	                    <li>5</li>
	                    <li>6</li>
	                  </ul>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	        <input class="text-field score-field" type="text" name="scores[impacts][${i}][score]" id="field-impact-${impact.id}-${this.idBusinessActivity}" data-index-impact="${i}" data-id-impact="${impact.id}" autocomplete="off" />
	      </li>
			`;

			// We show the impact field just after the first field
			$("#evaluate_form li:first").after(htmlImpactField);

		});

	}

	initScales () {

		scales.getData(this.params).then((data) => {

			if (scales.data !== undefined) {

				// Select all li.container-field-impact elements
				const containerFieldImpacts = $("ul.list-form-fields-evaluation li.container-field-impact");

				// Loop through each third impact field container
				containerFieldImpacts.each(function (index) {

					// We get the current form
					const curForm = $(this).closest("form");

					// We define the current index
					const curIndex = curForm.find("ul.list-form-fields-evaluation li.container-field-impact").index(this);

					// Check if it's the third element
					if ((curIndex + 1) % 3 === 0) { // Add 1 because the index starts at 0

						// Check if there isn't already a div.scale-area after the li
						if (!$(this).next(".scale-area").length) {

							// Insert a div with the class "scale-area" after the third element
							$(this).after("<div class=\"scale-area\"></div>");

						}

					}

					// Also add a div.scale-area after the last element if it doesn't already exist
					if (curIndex === curForm.find("ul.list-form-fields-evaluation li.container-field-impact").length - 1 && !$(this).next(".scale-area").length) {

						$(this).after("<div class=\"scale-area\"></div>");

					}

				});

				// For each scale
				$(scales.data).each((i, scale) => {

					// We add field scale
					this.addFieldScale(scale);

				});

				// We show the scales tables
				$("form.risk-evaluate-form .scale").removeClass("inactive");

				// We listen focus in and focus out on scale button
				$("form.risk-evaluate-form .score-field").on("focusin", this.handleFocusInScoreField.bind(this));

				// We listen the click on item scale
				$("form.risk-evaluate-form .scale .scale-table .table-line").on("click", this.handleClickOnItemScale.bind(this));

				// We put the autofocus on the first impact score
				if (this.isEvaluationForBusinessActivities) {

					// We put the focus on first impact score of the first form
					$("#section-form .item-section-form:first").find("input[name^='scores[impacts]']:first").focus();

				} else {

					$("input[name^='scores[impacts]']:first").focus();

				}

				// We define the click outside the scales
				$(document).click(() => {

					// We hide all the scales tables
					$(".scale .scale-table").removeClass("inactive");
					$(".scale .scale-table").addClass("inactive");
					$(".scale .scale-table").css("height", 0);

				});
				$("#section-intro").click((e) => {

				    e.stopPropagation();

				});
				$("input[name*=scores]").click((e) => {

				    e.stopPropagation();

				});
				$(".scale").click((e) => {

				    e.stopPropagation();

				});

			}

			// We setup the page for control mode (and not progress margin)
			this.initControlMode();


		}).catch((error) => {

			debug.v("RiskEvaluateForm - initScales - error in Scales getData");

		});

	}

	addFieldScale (scale) {

		const self = this;

		// For each form
		$("form.risk-evaluate-form").each(function () {

			// We get the business activity form
			const curBusinessActivityId = $(this).data("business-activity-id");

			// We get the html for current field scale
			const htmlFieldScale = self.getHtmlForFieldScale(scale, curBusinessActivityId);

			// We define the current input
			let curInputField;

			// For others fields (occurrence, control)
			if (scale.field_name !== undefined && scale.field_name !== null) {

				curInputField = $(`input.text-field[id='field-${scale.field_name}-${curBusinessActivityId}']`);

			} else { // For impact field

				curInputField = $(`input.text-field[id='field-impact-${scale.impact_id}-${curBusinessActivityId}']`);

			}

			// We define the current input container selector
			const curInputContainerSelector = curInputField.parents("li");

			// We add the current field scale html to the scale-area block next to the current input container
			curInputContainerSelector.nextAll(".scale-area:first").append(htmlFieldScale);

		});

	}

	getHtmlForFieldScale (scale, businessActivityId) {

		// We define the scale container ID
		let scaleContainerId;
		if (scale.field_name !== undefined && scale.field_name !== null) {

			scaleContainerId = `scale-${scale.field_name}-${businessActivityId}`;

		} else {

			scaleContainerId = `scale-impact-${scale.impact_id}-${businessActivityId}`;

		}

		// We define the html for field scale
		const htmlForFieldScale = `
			<li class="full-width-field">
        <div class="scale inactive" id="${scaleContainerId}">
          <div class="scale-table inactive">
            ${this.getHtmlForScaleItem(scale.content)}
          </div>
        </div>
      </li>
		`;

		// We return the html
		return htmlForFieldScale;

	}

	getHtmlForScaleItem (scaleContent) {

		// Get the keys of the object
		let keys = Object.keys(scaleContent);

		// Define the html for scale item
		let htmlForScaleItem = "";

		// Iterate over the keys starting from index 6
		for (let i = keys.length; i >= 1; i--) {

			let key = i.toString();
			let item = scaleContent[key];

			// Build the HTML block
			let htmlBlock = `
        <div class="table-line color-alert-${key}">
	        <div class="table-cell-title">
	          <span class="num">${key}</span>
	          <span class="text">${replaceSpecialCharacters(item[this.currentLang].title)}</span>
	        </div>
	        <div class="content-cells">
	          <div class="table-cell cell-full-width">
	          	<span class="text">${replaceSpecialCharacters(item[this.currentLang].text)}</span>
	          </div>
	        </div>
        </div>
      `;

			// Add the HTML block to the variable
			htmlForScaleItem += htmlBlock;

		}

		// Return the html
		return htmlForScaleItem;

	}

	initTextEditor (idBusinessActivity) {

		// Comment Editor FR
		ClassicEditor.
	    create(document.querySelector(`#comment-editor-fr-${idBusinessActivity}`), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	    	this.richTextEditor[`comment_fr_${idBusinessActivity}`] = editor;

	      if (this.evaluation.comment_fr !== undefined && this.evaluation.comment_fr !== null) {

					editor.setData(this.evaluation.comment_fr);

				}

				// Bug correction : we remove the second ck-editor element
				$(`#comment-editor-fr-${idBusinessActivity}`).parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

		// Comment Editor EN
		ClassicEditor.
	    create(document.querySelector(`#comment-editor-en-${idBusinessActivity}`), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditor[`comment_en_${idBusinessActivity}`] = editor;

	      if (this.evaluation.comment_en != undefined && this.evaluation.comment_en != null) {

					editor.setData(this.evaluation.comment_en);

				}

				// Bug correction : we remove the second ck-editor element
				$(`#comment-editor-en-${idBusinessActivity}`).parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	createForm (idBusinessActivity) {

		// We define the id of the form
		if (this.isEvaluationForBusinessActivities) {

			$(`#container-form-business-activity-${idBusinessActivity} form`).attr("id", `evaluate_form_${idBusinessActivity}`);

		} else {

			$("form.risk-evaluate-form").attr("id", `evaluate_form_${idBusinessActivity}`);

		}

		// We add the current business id to the form container
		$(`#evaluate_form_${idBusinessActivity}`).attr("data-business-activity-id", idBusinessActivity);

		// We define ids of fields
		$(`#evaluate_form_${idBusinessActivity} input[name='risk_ignored']`).attr("id", `risk-ignored-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} .label-risk-ignored`).attr("for", `risk-ignored-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} input[name='scores[occurrence]']`).attr("id", `field-occurrence-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} input[name='scores[control]']`).attr("id", `field-control-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} textarea[name='comment_fr']`).attr("id", `comment-editor-fr-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} textarea[name='comment_en']`).attr("id", `comment-editor-en-${idBusinessActivity}`);

		// We define ids of scales
		$(`#evaluate_form_${idBusinessActivity} .scale-field-4 .scale`).attr("id", `scale-occurrence-${idBusinessActivity}`);
		$(`#evaluate_form_${idBusinessActivity} .scale-field-5 .scale`).attr("id", `scale-control-${idBusinessActivity}`);

		// We show evaluations
		this.showEvaluations(idBusinessActivity);

		// We initialize the rich text editor
		this.initTextEditor(idBusinessActivity);

	}

	showEvaluations (idBusinessActivity) {

		if (idBusinessActivity === "all") {

			// We get evaluation
			cartographies.getAvailableVersionsOfEvaluations(this.params.idRisk, this.params.idLegalEntity).then((evaluations) => {

				// We check if there is an evaluation in WIP
				const isCurrentEvaluationInWIP = evaluations.filter((evaluation) => evaluation.version.toLowerCase() === "wip")[0];


				// We define the current evaluation
				let curEvaluationLegalEntity;
				if (isCurrentEvaluationInWIP !== undefined && isCurrentEvaluationInWIP !== null) {

					// We get the evaluation in WIP as current evaluation
					curEvaluationLegalEntity = isCurrentEvaluationInWIP;

				} else {

					// We sort the evaluations by date descending
					const evaluationsSortByDateDescending = evaluations.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

					// We get the last evaluation as current evaluation
					curEvaluationLegalEntity = evaluationsSortByDateDescending[0];

				}

				// We auto fill the evaluation for legal entity
				this.autoFillEvaluation(idBusinessActivity, curEvaluationLegalEntity);

			}).catch((error) => {

				debug.v("RiskEvaluateForm - showEvaluations - error in Cartographies getAvailableVersionsOfEvaluations");

			});


		} else {

			// We get evaluation for business activity
			cartographies.getAvailableVersionsOfEvaluations(this.params.idRisk, this.params.idLegalEntity).then((evaluations) => {

				// We check if there is an evaluation in WIP
				const isCurrentEvaluationInWIP = evaluations.filter((evaluation) => parseInt(evaluation.business_activity_id) === parseInt(idBusinessActivity) && evaluation.version.toLowerCase() === "wip")[0];


				// We get the evaluation for business activity
				let curEvaluationBusinessActivity;
				if (isCurrentEvaluationInWIP !== undefined && isCurrentEvaluationInWIP !== null) {

					// We get the evaluation in WIP as current evaluation
					curEvaluationBusinessActivity = isCurrentEvaluationInWIP;

				} else {

					// We get the evaluations of the current business activity
					const evaluationsFilteredByCurBusinessActivity = evaluations.filter((evaluation) => parseInt(evaluation.business_activity_id) === parseInt(idBusinessActivity));

					// We sort the evaluations by date descending
					const evaluationsSortByDateDescending = evaluationsFilteredByCurBusinessActivity.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

					// We get the last evaluation as current evaluation
					curEvaluationBusinessActivity = evaluationsSortByDateDescending[0];

				}

				// We auto fill the evaluation for business activity
				this.autoFillEvaluation(idBusinessActivity, curEvaluationBusinessActivity);

			}).catch((error) => {

				debug.v("RiskEvaluateForm - showEvaluations - error in Cartographies getAvailableVersionsOfEvaluations");

			});

		}


	}

	autoFillEvaluation (idBusinessActivity, curEvaluation) {

		if (curEvaluation !== undefined) {

			// We auto fill impacts
			this.autoFillImpacts(`#evaluate_form_${idBusinessActivity}`, curEvaluation.scores.impacts);

			// We auto fill the others fields
			this.autoFillForm(`#evaluate_form_${idBusinessActivity}`, curEvaluation);

			// We show informations of the last evaluation
			this.showLastEvaluationInfos(`#evaluate_form_${idBusinessActivity}`, curEvaluation);

			// We listen change on risk ignored field
			const isRiskIgnoredChecked = Boolean(curEvaluation.risk_ignored);
			this.displayIgnoredForm(`evaluate_form_${idBusinessActivity}`, isRiskIgnoredChecked);

			// We initialize the rich text editor
			this.initTextEditor(idBusinessActivity);

			// If all scores are null
			if (
			  curEvaluation === undefined ||
				curEvaluation.scores.impact === null &&
				curEvaluation.scores.occurrence === null &&
				curEvaluation.scores.control === null
			) {

				if (parseInt(curEvaluation.risk_ignored) === 1) {

					// We show the btn submit
					$(`#evaluate_form_${idBusinessActivity} .btn-submit`).show();

				} else {

					// We hide the btn submit
					$(`#evaluate_form_${idBusinessActivity} .btn-submit`).hide();

				}

			} else {

				// We show the btn submit
				$(`#evaluate_form_${idBusinessActivity} .btn-submit`).show();

			}

			// We show the major impact
			if (curEvaluation !== undefined && curEvaluation.scores.impact_major_id !== undefined) {


				this.autoFillMajorImpact(idBusinessActivity, curEvaluation);

			}

		}

	}

	showLastEvaluationInfos (curForm, evaluation) {

		if (evaluation.author_standard_name !== undefined && evaluation.author_standard_name !== null) {

			// We get the evaluator name
			const evaluatorName = evaluation.author_standard_name;

			// We get the evaluator email
			const evaluatorEmail = evaluation.author_email;

			// We get the html of the evaluator name
			let htmlEvaluatorName = "";
			if (evaluatorName !== undefined && evaluatorEmail !== undefined) {

				htmlEvaluatorName = `
					<div class="tag-link evaluator-name">
						<a href="mailto:${evaluatorEmail}">
							<span class="icon far fa-user"></span>
							<span class="text">${evaluatorName}</span>
						</a>
					</div>
				`;

			}

			// We get the update evaluation date
			const curEvaluationSeconds = `0${new Date(evaluation.updated_at).getSeconds()}`.slice(-2);
			const curEvaluationMinutes = `0${new Date(evaluation.updated_at).getMinutes()}`.slice(-2);
			const curEvaluationHours = `0${new Date(evaluation.updated_at).getHours() + 1}`.slice(-2);
			const curEvaluationFullTime = `${curEvaluationHours}:${curEvaluationMinutes}:${curEvaluationSeconds}`;
			const curEvaluationDay = `0${new Date(evaluation.updated_at).getDate()}`.slice(-2);
			const curEvaluationMonth = `0${new Date(evaluation.updated_at).getMonth() + 1}`.slice(-2);
			const curEvaluationYear = new Date(evaluation.updated_at).getFullYear();
			const curEvaluationFullDate = `${curEvaluationDay}.${curEvaluationMonth}.${curEvaluationYear}`;

			// We get the html of evaluation time
			let htmlEvaluationTime = "";
			if (curEvaluationFullTime !== undefined) {

				htmlEvaluationTime = `
					<div class="tag">
						<span class="icon far fa-clock"></span>
						<span class="text">${curEvaluationFullTime}</span>
					</div>
				`;

			}

			// We get the html of evaluation date
			let htmlEvaluationDate = "";
			if (curEvaluationFullDate !== undefined) {

				htmlEvaluationDate = `
					<div class="tag date-last-evaluation">
						<span class="icon far fa-calendar-alt"></span>
						<span class="text">${curEvaluationFullDate}</span>
					</div>
				`;

			}

			// We define the html
			const htmlLineLastEvaluationInfos = `
				<div class="line-last-evaluation-infos">
					<div class="title" data-i18n="title-last-evaluation-infos">${$.i18n("title-last-evaluation-infos")}</div>
					<div class="infos">${htmlEvaluatorName}${htmlEvaluationDate}${htmlEvaluationTime}</div>
				</div>
			`;

			// We show the informations line
			$(curForm).prepend(htmlLineLastEvaluationInfos);

		}

	}

	handleFocusInScoreField (e) {

		e.preventDefault();
		e.stopPropagation();


		// We get the id of the current form
		const curIdForm = $(e.currentTarget).parents("form").attr("id");

		if (!$(`#${curIdForm}`).hasClass("inactive")) {

			const curField = $(e.currentTarget).attr("id").replace("field-", "");

			const scaleTableHeight = "auto";

			// We hide all the scales tables
			$(".scale .scale-table").not(".inactive").css("height", 0);
			$(".scale .scale-table").not(".inactive").addClass("inactive");

			// We show the scale table
			setTimeout(() => {

				$(`#scale-${curField}`).find(".scale-table").removeClass("inactive");
				$(`#scale-${curField}`).find(".scale-table").css("height", scaleTableHeight);

			}, 100);

		}

	}

	handleClickOnItemScale (e) {

		// We get the current form id
		const curFormId = $(e.currentTarget).parents("form").attr("id");

		// We get the item scale number
		const curNum = parseInt($(e.currentTarget).find(".table-cell-title .num").html());

		// We get the field id
		const fieldId = $(e.currentTarget).parents(".scale").attr("id").replace("scale-", "");

		// We get the input field of the current scale
		const curField = $(`#field-${fieldId}`);

		// We update the field value
		curField.val(curNum);

		// We show the btn submit
		$(`#${curFormId} .btn-submit`).show();

	}

	handleFieldChange (e) {

		const curVal = $(e.currentTarget).val();

		const curFormId = $(e.currentTarget).parents("form").attr("id");

		if (curVal !== "") {

			// We show the btn submit
			$(`#${curFormId} .btn-submit`).show();

			// We remove the error
			$(e.currentTarget).removeClass("error");
			$(e.currentTarget).parent("li").find("div.error").remove();

			// If the score field is for impact
			if ($(e.currentTarget).attr("name").indexOf("impact") >= 0) {

				// We remove errors for all scores of impact
				$(`#${curFormId} scores[impacts]`).removeClass("error");
				$(`#${curFormId} scores[impacts]`).parent("li").find("div.error").remove();

			}

		} else {

			let allTextFiedsValue = [];
			$(`#${curFormId} input.text-field`).each(function () {

				const valField = $(this).val();

				if (valField !== "") {

					allTextFiedsValue.push(valField);

				}

			});

			const isRiskIgnoredChecked = $(`#${curFormId} input[name='risk_ignored']`).prop("checked");

			if (allTextFiedsValue.length <= 0 && !isRiskIgnoredChecked) {

				// We hide the btn submit
				$(`#${curFormId} .btn-submit`).hide();

			}

		}

	}

	handleScoreChange (e) {

		// We get the current value
		const curValue = $(e.currentTarget).val();

		// If the value contain a comma
		if (curValue.indexOf(",") >= 0) {

			// We replace it by a dot
			const newValue = curValue.replace(/\,/g, ".");
			$(e.currentTarget).val(newValue);

		}

		if (curValue.split(".").length > 2) {

			const clearValue = curValue.replace(/\.+$/, "");
			$(e.currentTarget).val(clearValue);

		}

	}

	handleRiskIgnoredChange (e) {

		const curFormId = $(e.currentTarget).parents("form").attr("id");

		const isRiskIgnoredChecked = $(e.currentTarget).prop("checked");

		this.displayIgnoredForm(curFormId, isRiskIgnoredChecked);

		// We listen focus in and focus out on scale button
		$(".risk-evaluate-form .score-field").on("focusin", this.handleFocusInScoreField.bind(this));

	}

	displayIgnoredForm (curFormId, isRiskIgnoredChecked) {

		if (isRiskIgnoredChecked) {

			// We add a class to change form appearance
			$(`#${curFormId}`).addClass("inactive");
			$(`#${curFormId}`).find(".text-field").prop("readonly", true);

			// We show the btn submit
			$(`#${curFormId}`).find(".btn-submit").show();

		} else {

			$(`#${curFormId}`).removeClass("inactive");
			$(`#${curFormId}`).find(".text-field").prop("readonly", false);

			let allTextFiedsValue = [];
			$(`#${curFormId}`).find("input.text-field").each(function () {

				const valField = $(this).val();
				if (valField !== "") {

					allTextFiedsValue.push(valField);

				}

			});

			if (allTextFiedsValue.length <= 0) {

				// We hide the btn submit
				$(`#${curFormId} .btn-submit`).hide();

			}

		}

	}

	handleImpactChange (e) {

		const curForm = $(e.currentTarget).parents(".risk-evaluate-form");
		const curFormId = curForm.attr("id").replace("evaluate_form_", "");

		let highestValue = -1;
		let inputsWithHighestValue = [];

	  // For each impact field
	  curForm.find("input[name^=\"scores[impacts]\"]").each(function () {

	  	// We get the value of the current input
			let inputValue = parseFloat($(this).val());

			// We check if the current input value is higher than the current highest value
			if (inputValue > highestValue) {

				highestValue = inputValue;
				inputsWithHighestValue = [this];

			} else if (inputValue === highestValue) {

				// If the current input value is equal to the highest value, we add it to the array
				inputsWithHighestValue.push(this);

			}

		});

	  if (inputsWithHighestValue.length > 1) {

	  	// For each inputs
			inputsWithHighestValue.forEach((input) => {

				// We get the ID of the current input
				let inputId = $(input).attr("data-id-impact");

				// We add the HTML after the current input
				if ($(`input[id^=impact_major_${inputId}]`).length < 1) {

					curForm.find(`input[data-id-impact="${inputId}"]`).after(this.getHtmlForImpactMajor(inputId, curFormId));

				}

			});

		} else {

			$(".impact_major_content").remove();

		}

	}

	getHtmlForImpactMajor (value, idForm) {

		return `<span class='impact_major_content'><input id='impact_major_${value}_${idForm}' type='radio' name='scores[impact_major]' value='${value}'/><label for="impact_major_${value}_${idForm}" data-i18n="label-major-impact">${$.i18n("label-major-impact")}</label></span>`;

	}

	handleSubmit (e) {

		e.preventDefault();

		debug.v("RiskEvaluateForm - handleSubmit");

		// We define vars for current form
		const curForm = $(e.currentTarget).parents(".risk-evaluate-form");
		const curBusinessActivityId = curForm.attr("data-business-activity-id");

		// If no evaluation is found (first time the risk is evaluated for these legal entity), we create an empty one
		if (this.evaluation === undefined) {

			this.evaluation = {};

		}

		this.idBusinessActivity = null;

		// We send the request
		if (
			curBusinessActivityId === "all" &&
			window.location.pathname.indexOf("/evaluate-for-business-activities") < 0
		) {

			this.getFormFieldsValues(curForm, curBusinessActivityId);

			this.idBusinessActivity = curBusinessActivityId;

			cartographies.evaluateOne(this.evaluation).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

		} else if (
			curBusinessActivityId === "all" &&
			window.location.pathname.indexOf("/evaluate-for-business-activities") >= 0
		) {

			// We load the business activities of the legal entity
			legalEntities.getBusinessActivities(this.params.idLegalEntity).then((data) => {

				if (data !== undefined && data !== null && data.length > 0) {

					// For each business activity
					for (let iBusinessActivity = 0; iBusinessActivity < data.length; iBusinessActivity++) {

						const businessActivityData = data[iBusinessActivity];
						const businessActivityId = parseInt(businessActivityData.id);

						this.getFormFieldsValues(curForm, curBusinessActivityId);

						this.evaluation.business_activity_id = parseInt(businessActivityId);

						this.idBusinessActivity = curBusinessActivityId;

						if (iBusinessActivity < data.length - 1) {

							cartographies.evaluateOne(this.evaluation).then(() => {}, this.updateFail.bind(this));

						} else {

							cartographies.evaluateOne(this.evaluation).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

						}

					}

				}

			});

		} else {

			this.getFormFieldsValues(curForm, curBusinessActivityId);

			this.evaluation.business_activity_id = parseInt(curBusinessActivityId);

			this.idBusinessActivity = curBusinessActivityId;

			cartographies.evaluateOne(this.evaluation).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

		}

	}

	getFormFieldsValues (curForm, curBusinessActivityId) {

		// We get manually the scores
		this.evaluation.scores = {};
		this.evaluation.scores.impacts = this.getImpactsScores(curForm);
		this.evaluation.scores.occurrence = curForm.find("[name='scores[occurrence]']").val();
		this.evaluation.scores.control = curForm.find("[name='scores[control]']").val();
		this.evaluation.scores.impact_major_id = curForm.find("[name='scores[impact_major]']:checked").val();
		this.evaluation.risk_id = parseInt(this.idRisk);
		this.evaluation.legal_entity_id = parseInt(this.legalEntity.id);
		this.evaluation.risk_ignored = curForm.find("[name='risk_ignored']:checked").length == 1 ? 1 : 0;

		this.evaluation.comment_fr = this.richTextEditor[`comment_fr_${curBusinessActivityId}`].getData();
		this.evaluation.comment_en = this.richTextEditor[`comment_en_${curBusinessActivityId}`].getData();

	}

	getImpactsScores (curForm) {

		// We define the impacts scores
		let impactsScores = [];

		// For each impact field
		curForm.find("input[name^=\"scores[impacts]\"]").each(function () {

			// We get the score
			const impactScore = $(this).val();

			// We get the impact ID
			const impactId = $(this).attr("data-id-impact");

			// We add the value to the impacts scores array
			if (impactId) {

				const impactToAdd = {
					"id": parseInt(impactId),
					"score": impactScore
				};

				impactsScores.push(impactToAdd);

			}

		});

		// We return the impacts scores
		return impactsScores;

	}

	updateSuccess (response) {

		debug.v("RiskEvaluateForm - updateSuccess");

		risks.getData(this.params).then(() => {

			// We get actions of the current risk
			const curRiskActions = actions.data.filter((action) => parseInt(action.risk_id) === parseInt(this.idRisk));

			if (this.idBusinessActivity === "all") {

				if (user.canManageActionPlans() && curRiskActions !== undefined && curRiskActions.length > 0) {

					// We show the popin of actions choice
					this.showRiskActionsPopin();

				} else if (this.previousPage !== undefined && this.previousPage !== null) {

					// We show the previous page
					router.navigate(`${this.previousPage}#evaluations`);

				} else {

					// We show the risk page
					router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/business-activities/all#evaluations`);

				}

			} else {


				if (user.canManageActionPlans() && curRiskActions !== undefined && curRiskActions.length > 0) {

					// We show the popin of actions choice
					this.showRiskActionsPopin();

				}

				// We close the current form
				$(`#container-form-business-activity-${this.idBusinessActivity}`).removeClass("active");

				// We scroll to the saved form
				const positionTopSavedForm = $(`#container-form-business-activity-${this.idBusinessActivity}`).offset().top;
				const selectContextHeight = $("#main-select-context").height() + 50;
				$("html, body").animate({"scrollTop": positionTopSavedForm - selectContextHeight}, 400);

				// We change the icon of the title line of the saved form
				$(`#container-form-business-activity-${this.idBusinessActivity} .line-title-item .icon`).removeClass("fa-angle-up");
				$(`#container-form-business-activity-${this.idBusinessActivity} .line-title-item .icon`).removeClass("fa-angle-down");
				$(`#container-form-business-activity-${this.idBusinessActivity} .line-title-item .icon`).addClass("fa-check");

				// We change the style of the title line of the saved form
				$(`#container-form-business-activity-${this.idBusinessActivity}`).addClass("saved");

				// We put the focus on the next form
				const indexCurrentItemForm = $(`#container-form-business-activity-${this.idBusinessActivity}`).index();
				$("#section-form .item-section-form").eq(indexCurrentItemForm + 1).find("input[name^='scores[impacts]']:first").focus();

				// We show the line with the back button
				$(".line-btn-back").addClass("active");

				// We hide the fixed back button
				setTimeout(() => {

					if ($(".item-section-form").not(".saved").length < 1) {

						$(".btn-back-previous-page").not(".not-fixed").addClass("inactive");

					}

				}, 200);

			}


		}).catch((error) => {

			debug.v("RiskEvaluateForm - updateSuccess - error in Risks getData");

		});

	}

	updateFail (response, textStatus) {

		debug.v("RiskEvaluateForm - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"scores[occurrence]": {
					"required": `${$.i18n("form-error-score-occurrence-required")}`,
					"min_1_max_6": `${$.i18n("form-error-score-occurrence-min-max")}`,
					"required_when_scores_given": `${$.i18n("form-error-score-occurrence-required")}`
				},
				"scores[control]": {
					"required": `${$.i18n("form-error-score-control-required")}`,
					"min_1_max_6": `${$.i18n("form-error-score-control-min-max")}`,
					"required_when_scores_given": `${$.i18n("form-error-score-control-required")}`
				},
				"risk_id": {
					"required": `${$.i18n("form-error-risk-id")}`
				},
				"legal_entity_id": {
					"required": `${$.i18n("form-error-legal-entity-id")}`
				}
			};

			// For each impact field on the current form
			$(`#evaluate_form_${this.idBusinessActivity} input[name^='scores[impacts]']`).each(function () {

				// We get the current impact field index
				const curImpactFieldIndex = $(this).data("index-impact");

				// We define errors for current impact field
				const curImpactFieldErrors = {};
				curImpactFieldErrors[`scores[impacts][${curImpactFieldIndex}][score]`] = {
				    "required": `${$.i18n("form-error-score-impact-required")}`,
				    "at_least_one_impact_required": `${$.i18n("form-error-score-impact-required")}`,
				    "min_1_max_6": `${$.i18n("form-error-score-impact-min-max")}`,
				    "no_major_impact_given": `${$.i18n("form-error-score-impact-no-major-given")}`,
				    "required_when_scores_given": `${$.i18n("form-error-score-impact-required")}`
				};

				// We push the current impact field errors on the global errors array
				Object.assign(errorsToShow, curImpactFieldErrors);

			});

			showFormErrors(`#evaluate_form_${this.idBusinessActivity}`, response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(`#evaluate_form_${this.idBusinessActivity} .error`).eq(0).parents("li").offset().top - $("#main-select-context").height() - 50;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$(`#evaluate_form_${this.idBusinessActivity}`).find(".main-error").remove();

			$(`#evaluate_form_${this.idBusinessActivity}`).prepend(`<div class='main-error'>${$.i18n("form-error-global")}</div>`);

		}

	}

	autoFillMajorImpact (idBusinessActivity, curEvaluation) {

		// We store the reference to the object to avoid any problems with "this".
		const self = this;

		// We get the current form selector
		const curForm = $(`form.risk-evaluate-form[data-business-activity-id='${idBusinessActivity}']`);

		// We get the current major impact ID
		const curMajorImpactId = curEvaluation.scores.impact_major_id;

		// We get the current major impact value
		const curMajorImpactValue = curForm.find(`input[data-id-impact='${curMajorImpactId}']`).val();

		// For each impact field
	  curForm.find("input[name^=\"scores[impacts]\"]").each(function () {

	  	// We get the impact ID of the current input
	  	const impactId = $(this).attr("data-id-impact");

	  	// We get the input value
	  	const inputValue = $(this).val();

	  	if (inputValue === curMajorImpactValue) {

	  		curForm.find(`input[data-id-impact='${impactId}']`).after(self.getHtmlForImpactMajor(impactId, idBusinessActivity));

			}

		});

		// We check the radio of the major impact
		curForm.find(`.impact_major_content input[value='${curMajorImpactId}']`).prop("checked", true);
		curForm.find(".impact_major_content input").addClass("checked");


	}

	showRiskActionsPopin () {

		// Actions popin
		this.seeRiskActionsPopin = new Popin("see-risk-actions-popin", `${$.i18n("popin-see-risk-actions-confirmation-title")}`, [
			{
				"title": `${$.i18n("button-no")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleSeeRiskActionsCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-yes")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleSeeRiskActionsConfirm.bind(this)
			}
		]);

		$(".popin").remove();

		this.seeRiskActionsPopin.appendIn("#page");

		this.seeRiskActionsPopin.show();


	}

	handleSeeRiskActionsCancel () {

		if (this.idBusinessActivity === "all") {

			// We show the risk page
			router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/business-activities/all`);

		} else {


			// We hide the popin
			this.seeRiskActionsPopin.hide();

		}

	}

	handleSeeRiskActionsConfirm () {

		// We show the actions summary page of the current risk
		router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/actions-plan/list`);

	}

	autoFillForm (formCssSelector, data, arrayInput = "") {

		for (const prop in data) {

			let inputName = arrayInput != "" ? `${arrayInput}[${prop}]` : prop;

			if (_.isObject(data[prop])) {

				this.autoFillForm(formCssSelector, data[prop], prop);

			} else {

				let input = $(`${formCssSelector} [name='${inputName}']`);

				if (input.length > 0) {

					// We handle checkboxes and radio
					if (input[0].type == "checkbox") {

						if (input.val() == data[prop]) {

							input.attr("checked", true);

						}

					} else if (input[0].type == "radio") { // Radios

						for (let iRadio = 0; iRadio < input.length; iRadio++) {

							let radio = input[iRadio];
							if ($(radio).val() == data[prop]) {

								$(radio).attr("checked", true);

							}

						}

					} else if ($(input).attr("type") == "text") { // If it's an input, we set the value attribute so the selenium webdriver can see it

						$(input).attr("value", data[prop]);

					} else if ($(input)[0].tagName.toLowerCase() == "select") {

						$(input).find(`OPTION[value='${data[prop]}'], OPTION:contains('${data[prop]}')`).attr("selected", "selected");

					} else if ($(input)[0].tagName.toLowerCase() == "textarea") {

						$(input).html(data[prop]);

					}

				}


			}

		}

	}

	autoFillImpacts (curForm, impacts) {

		if (impacts !== undefined && impacts !== null && impacts.length > 0) {

			// For each impact
			$(impacts).each((i, impact) => {

				// We add the score to the corresponding impact field
				if (impact.score !== null && impact.score > 0) {

					$(curForm).find(`input[data-id-impact=${impact.id}]`).attr("value", impact.score);

				}

			});

		}

	}

	getTranslations () {

		$("[data-i18n='form-label-risk-ignored']").html($.i18n("form-label-risk-ignored"));
		$("[data-i18n='risk-evaluate-tooltip-score']").html($.i18n("risk-evaluate-tooltip-score"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-6']").html($.i18n("scale-table-carto-legal-impact-title-line-6"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-5']").html($.i18n("scale-table-carto-legal-impact-title-line-5"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-4']").html($.i18n("scale-table-carto-legal-impact-title-line-4"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-3']").html($.i18n("scale-table-carto-legal-impact-title-line-3"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-2']").html($.i18n("scale-table-carto-legal-impact-title-line-2"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-1']").html($.i18n("scale-table-carto-legal-impact-title-line-1"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-6']").html($.i18n("scale-table-carto-legal-impact-title-line-6"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-6']").html($.i18n("scale-table-carto-legal-impact-text-image-line-6"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-5']").html($.i18n("scale-table-carto-legal-impact-title-line-5"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-5']").html($.i18n("scale-table-carto-legal-impact-text-image-line-5"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-4']").html($.i18n("scale-table-carto-legal-impact-title-line-4"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-4']").html($.i18n("scale-table-carto-legal-impact-text-image-line-4"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-3']").html($.i18n("scale-table-carto-legal-impact-title-line-3"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-3']").html($.i18n("scale-table-carto-legal-impact-text-image-line-3"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-2']").html($.i18n("scale-table-carto-legal-impact-title-line-2"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-2']").html($.i18n("scale-table-carto-legal-impact-text-image-line-2"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-1']").html($.i18n("scale-table-carto-legal-impact-title-line-1"));
		$("[data-i18n='scale-table-carto-legal-impact-text-image-line-1']").html($.i18n("scale-table-carto-legal-impact-text-image-line-1"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-6']").html($.i18n("scale-table-carto-legal-impact-title-line-6"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-6']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-6"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-5']").html($.i18n("scale-table-carto-legal-impact-title-line-5"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-5']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-5"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-4']").html($.i18n("scale-table-carto-legal-impact-title-line-4"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-4']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-4"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-3']").html($.i18n("scale-table-carto-legal-impact-title-line-3"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-3']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-3"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-2']").html($.i18n("scale-table-carto-legal-impact-title-line-2"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-2']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-2"));
		$("[data-i18n='scale-table-carto-legal-impact-title-line-1']").html($.i18n("scale-table-carto-legal-impact-title-line-1"));
		$("[data-i18n='scale-table-carto-legal-impact-text-criminal-line-1']").html($.i18n("scale-table-carto-legal-impact-text-criminal-line-1"));
		$("[data-i18n='form-label-field-occurrence']").html($.i18n("form-label-field-occurrence"));
		$("[data-i18n='form-label-field-control']").html($.i18n("form-label-field-control"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-6']").html($.i18n("scale-table-carto-legal-occurrence-title-line-6"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-6']").html($.i18n("scale-table-carto-legal-occurrence-text-line-6"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-5']").html($.i18n("scale-table-carto-legal-occurrence-title-line-5"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-5']").html($.i18n("scale-table-carto-legal-occurrence-text-line-5"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-4']").html($.i18n("scale-table-carto-legal-occurrence-title-line-4"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-4']").html($.i18n("scale-table-carto-legal-occurrence-text-line-4"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-3']").html($.i18n("scale-table-carto-legal-occurrence-title-line-3"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-3']").html($.i18n("scale-table-carto-legal-occurrence-text-line-3"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-2']").html($.i18n("scale-table-carto-legal-occurrence-title-line-2"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-2']").html($.i18n("scale-table-carto-legal-occurrence-text-line-2"));
		$("[data-i18n='scale-table-carto-legal-occurrence-title-line-1']").html($.i18n("scale-table-carto-legal-occurrence-title-line-1"));
		$("[data-i18n='scale-table-carto-legal-occurrence-text-line-1']").html($.i18n("scale-table-carto-legal-occurrence-text-line-1"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-1']").html($.i18n("scale-table-carto-legal-control-title-line-1"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-1']").html($.i18n("scale-table-carto-legal-control-text-line-1"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-2']").html($.i18n("scale-table-carto-legal-control-title-line-2"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-2']").html($.i18n("scale-table-carto-legal-control-text-line-2"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-3']").html($.i18n("scale-table-carto-legal-control-title-line-3"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-3']").html($.i18n("scale-table-carto-legal-control-text-line-3"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-4']").html($.i18n("scale-table-carto-legal-control-title-line-4"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-4']").html($.i18n("scale-table-carto-legal-control-text-line-4"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-5']").html($.i18n("scale-table-carto-legal-control-title-line-5"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-5']").html($.i18n("scale-table-carto-legal-control-text-line-5"));
		$("[data-i18n='scale-table-carto-legal-control-title-line-6']").html($.i18n("scale-table-carto-legal-control-title-line-6"));
		$("[data-i18n='scale-table-carto-legal-control-text-line-6']").html($.i18n("scale-table-carto-legal-control-text-line-6"));
		$("[data-i18n='form-label-field-comment-fr']").html($.i18n("form-label-field-comment-fr"));
		$("[data-i18n='form-label-field-comment-en']").html($.i18n("form-label-field-comment-en"));
		$("[data-i18n='form-label-btn-cancel']").html($.i18n("form-label-btn-cancel"));
		$("[data-i18n='form-label-btn-validate']").html($.i18n("form-label-btn-validate"));

	}

}
export default new RiskEvaluateForm();
