import axios from "axios";
import html from "./field_translation_button.html";
import AutoCompletionButton from "Components/AutoCompletionButton/AutoCompletionButton.js";
import AI from "Datapoints/AI.js";
// OpenAI response exceptions
import openAiTranslationResponseExceptions from "Core/assets/json/openai_translation_response_exceptions.json";
// Import flags
import countriesFlags from "Core/assets/lib/flag-icons/css/flag-icons.min.css";

class FieldTranslationButton {

	constructor (nameField, typeField, langField, CKEditorFr, CKEditorEn) {

		// NOTES :
		// nameField = the name of the form field
		// typeField = "text" for input (text, email, tel, etc.) and "textarea" for textarea and CKEditor
		// langField = the alpha2 language code of the form field (example: fr, en, es, etc.)
		// CKEditorFr = CKEditor object in french (only for textarea field with a CKEditor like description or comment)
		// CKEditorEn = CKEditor object in english (only for textarea field with a CKEditor like description or comment)

		// We init global vars
		this.nameField = nameField;
		this.typeField = typeField;
		this.outputLang = langField;
		this.CKEditorFr = CKEditorFr;
		this.CKEditorEn = CKEditorEn;

		if (this.outputLang === "en") {

			this.inputLang = "fr";

		} else {

			this.inputLang = "en";

		}

		if (process.env.SHOW_TRANSLATION_BUTTONS === true || process.env.SHOW_TRANSLATION_BUTTONS === "true") {

			// We construct html
			this.appendAndFill();

			// We init global events
			this.initGlobalEvents();

		}

	}

	appendAndFill () {

		// We define the field main container
		this.fieldContainer = $(`[name='${this.nameField}']`).parents("li");

		// We define the field and translation button wrap
		const htmlWrap = `<div class="wrap-field wrap-${this.typeField}"></div>`;

		// We show the wrap
		this.fieldContainer.append(htmlWrap);

		// We move the field into the new wrap
		$(`[name='${this.nameField}']`).appendTo(this.fieldContainer.find(".wrap-field"));

		if (this.typeField === "textarea") {

			// We move the CKEditor blocks into the new wrap
			$(`[name='${this.nameField}']`).parents("li").find(".ck-editor").appendTo(this.fieldContainer.find(".wrap-field"));

		}

		// We show the translate button into the new wrap
		this.addTranslateButton();

	}

	addTranslateButton () {

		// We show the translate button into the new wrap
		this.fieldContainer.find(".wrap-field").append(html);

		// We define the current translate button
		this.curTranslateButton = this.fieldContainer.find(".btn-translate-field");

		// We add an id to the btn translate
		this.translateButtonId = `btn-translate-${this.nameField}`;
		this.curTranslateButton.attr("id", this.translateButtonId);


		// We add the input and output lang attributes on the translate button
		this.curTranslateButton.attr("data-input-language", this.inputLang);
		this.curTranslateButton.attr("data-output-language", this.outputLang);

		// We update the flags on the current translate button
		const codeInputLangFlag = this.inputLang === "en" ? "GB" : this.inputLang.toUpperCase();
		const codeOutputLangFlag = this.outputLang === "en" ? "GB" : this.outputLang.toUpperCase();
		this.curTranslateButton.find(".flag-icons.input-lang").addClass(codeInputLangFlag);
		this.curTranslateButton.find(".flag-icons.output-lang").addClass(codeOutputLangFlag);
		this.curTranslateButton.find(".flag-icons").removeClass("input-lang");
		this.curTranslateButton.find(".flag-icons").removeClass("output-lang");

	}

	initGlobalEvents () {

		if (this.typeField === "textarea" && this.outputLang === "fr") {

			const curTextareaId = $(`textarea[name='${this.nameField}']`).attr("id");

			// We listen the editor change event for CKEditorFr
			this.CKEditorFr.model.document.on("change:data", (evt, data) => {

				// We get the editor value without html tags
				const curEditorValue = this.CKEditorFr.getData().replace(/(<([^>]+)>)/ig, "");

				this.handleChangeOnEditorFr(curTextareaId, curEditorValue);

			});

		} else if (this.typeField === "textarea" && this.outputLang === "en") {

			const curTextareaId = $(`textarea[name='${this.nameField}']`).attr("id");

			// We listen the editor change event for CKEditorFr
			this.CKEditorEn.model.document.on("change:data", (evt, data) => {

				// We get the editor value without html tags
				const curEditorValue = this.CKEditorEn.getData().replace(/(<([^>]+)>)/ig, "");

				this.handleChangeOnEditorEn(curTextareaId, curEditorValue);

			});

		} else {

			// We listen change event for input text
			$(`input[name='${this.nameField}']`).on("keyup", this.handleChangeOnInputText.bind(this));

		}

		// We listen click on the translate button
		$(`#${this.translateButtonId}`).on("click", this.handleClickOnTranslateFieldButton.bind(this));

	}

	handleChangeOnInputText (e) {

		if (e !== undefined) {

			const curValue = $(e.currentTarget).val();

			const inputGroupName = $(e.currentTarget).attr("name").slice(0, -2);

			const inputLanguageFieldName = inputGroupName + $(e.currentTarget).parents(".wrap-field").find(".btn-translate-field").attr("data-input-language");

			if (curValue !== undefined && curValue !== null && curValue !== "") {

				// We show translate button on the others fields
				$(`input[name='${inputLanguageFieldName}']`).parents(".wrap-field").addClass("active-translation");

			} else {

				// We hide translate button on the others fields
				$(`input[name='${inputLanguageFieldName}']`).parents(".wrap-field").removeClass("active-translation");

			}

		} else {

			$(".wrap-text").each(function () {

				const curInputTextValue = $(this).find("input").val();

				const inputTextGroupName = $(this).find("input").attr("name").slice(0, -2);

				const inputLanguageFieldName = inputTextGroupName + $(this).find(".btn-translate-field").attr("data-input-language");

				if (curInputTextValue !== undefined && curInputTextValue !== null && curInputTextValue !== "") {

					// We show translate button on the others fields
					$(`input[name='${inputLanguageFieldName}']`).parents(".wrap-field").addClass("active-translation");

				} else {

					// We hide translate button on the others fields
					$(`input[name='${inputLanguageFieldName}']`).parents(".wrap-field").removeClass("active-translation");

				}

			});

		}

	}

	handleChangeOnEditorFr (idTextarea, curEditorValue) {

		const textareaGroupName = $(`#${idTextarea}`).attr("name").slice(0, -2);

		const inputLanguageFieldName = `${textareaGroupName}en`;

		if (curEditorValue !== undefined && curEditorValue !== null && curEditorValue !== "") {

			// We show translate button on the others fields
			$(`textarea[name='${inputLanguageFieldName}']`).parents(".wrap-field").addClass("active-translation");

		} else {

			// We hide translate button on the others fields
			$(`textarea[name='${inputLanguageFieldName}']`).parents(".wrap-field").removeClass("active-translation");

		}

	}

	handleChangeOnEditorEn (idTextarea, curEditorValue) {

		const textareaGroupName = $(`#${idTextarea}`).attr("name").slice(0, -2);

		const inputLanguageFieldName = `${textareaGroupName}fr`;

		if (curEditorValue !== undefined && curEditorValue !== null && curEditorValue !== "") {

			// We show translate button on the others fields
			$(`textarea[name='${inputLanguageFieldName}']`).parents(".wrap-field").addClass("active-translation");

		} else {

			// We hide translate button on the others fields
			$(`textarea[name='${inputLanguageFieldName}']`).parents(".wrap-field").removeClass("active-translation");

		}

	}

	handleClickOnTranslateFieldButton (e) {

		// We define the current button
		const curButton = $(e.currentTarget);

		// We remove the error
		curButton.parents(".wrap-field").find(".msg-error").remove();

		// We define the current field name
		let curFieldName;

		// We define the input language field value
		let inputLanguageFieldValue;

		// We get the input language
		const inputLanguage = curButton.attr("data-input-language");

		// We get the output language
		const outputLanguage = curButton.attr("data-output-language");

		// We define the output language text for the prompt message
		let promptOutputLanguageText;
		if (outputLanguage === "fr") {

			promptOutputLanguageText = "français";

		} else {

			promptOutputLanguageText = "anglais";

		}

		// We check if we are on an editor or not and we get the current field value to translate
		let translateForEditor = false;
		if (curButton.parents(".wrap-field").find(".ck-editor").length > 0) {

			translateForEditor = true;

			// We get the field name without code language
			curFieldName = curButton.parents(".wrap-field").find("textarea").attr("name").slice(0, -2);

			// We get the input language field value
			if (inputLanguage === "fr") {

				inputLanguageFieldValue = this.CKEditorFr.getData().replace(/(<([^>]+)>)/ig, "");

			} else {

				inputLanguageFieldValue = this.CKEditorEn.getData().replace(/(<([^>]+)>)/ig, "");

			}

		} else {

			translateForEditor = false;

			// We get the field name without code language
			curFieldName = curButton.parents(".wrap-field").find("input").attr("name").slice(0, -2);

			// We get the input language field value
			inputLanguageFieldValue = $(`input[name='${curFieldName}${inputLanguage}']`).val();

		}

		// We show a loader on the field
		curButton.parents(".wrap-field").addClass("loading");
		curButton.parents(".wrap-field").prepend("<div class='field-loader'></div>");

		// We make the translation
		AI.getTranslation(inputLanguageFieldValue, promptOutputLanguageText).then((response) => {

			// We remove the loader
			curButton.parents(".wrap-field").removeClass("loading");
			curButton.parents(".wrap-field").find(".field-loader").remove();

			// We set the translation
			if (translateForEditor) {

				const curTextareaId = $(`textarea[name='${curFieldName}${outputLanguage}']`).attr("id");

				if (outputLanguage === "fr") {

					this.CKEditorFr.setData(response.translation);

					this.handleChangeOnEditorFr(curTextareaId, response.translation);

				} else {

					this.CKEditorEn.setData(response.translation);

					this.handleChangeOnEditorEn(curTextareaId, response.translation);

				}

			} else {

				// We show the translation on the output language field
				$(`input[name='${curFieldName}${outputLanguage}']`).val(response.translation);

				this.handleChangeOnInputText();

			}

		}).catch((error) => {

			// We remove the error
			curButton.parents(".wrap-field").find(".msg-error").remove();

			// We remove the loader
			curButton.parents(".wrap-field").removeClass("loading");
			curButton.parents(".wrap-field").find(".field-loader").remove();

			// We define the error message
			const textErrorToShow = $.i18n("error-translation-field-global");

			// We define the html for the error to show
			const htmlErrorToShow = `<div class="error msg-error"><i class="icon fas fa-exclamation-triangle"></i>${textErrorToShow}</div>`;

			// We show the error
			curButton.parents(".wrap-field").append(htmlErrorToShow);

	     });

	}

}
export default FieldTranslationButton;
