import html from "./cartography_impact_field.html";
import cartographies from "Datapoints/Cartographies.js";
import Popin from "Components/Popin/Popin.js";
import cartographyScaleField from "Components/CartographyScaleField/CartographyScaleField.js";
import {slugify, getFontAwesomeIcon} from "Core/helpers.js";

class CartographyImpactField {

	init (containerSelector, params, currentLang) {

		// We define the current language
		this.currentLang = currentLang;

		// We get the current cartography ID
		if (params !== undefined && params !== null) {

			this.cartographyId = params.idCartography;

		} else {

			this.cartographyId = null;

		}

		// We add the component html to the container
		$(containerSelector).append(html);

		// We define the impacts
		this.dataCartographyImpacts = [];

		// We init the events
		this.initGlobalEvents();

	}

	initGlobalEvents () {

		// We listen change on the impact fields
		$("#section_cartography_impacts input[name='impact_fr'], #section_cartography_impacts input[name='impact_en']").on("input", this.handleChangeOnImpactFields.bind(this));

		// We listen click on the add impact button
		$("#btn-add-impact").on("click", this.handleClickOnAddImpactButton.bind(this));

		// We listen click on the impact translate button
		$("#cartography_impacts .btn-translate-field").on("click", this.handleClickOnImpactTranslateButton.bind(this));

	}

	initEditAndDeleteButtonsEvents (selector) {

		// We listen click on the edit impact button
		selector.find(".head-infos").on("click", (e) => {

			if (!$(e.target).hasClass("btn-delete-impact")) {

				this.handleClickOnEditImpactButton(e);

			}

		});

		// We listen click on the delete impact button
		selector.find(".btn-delete-impact").on("click", this.handleClickOnDeleteImpactButton.bind(this));

		// We listen click on the edit impact button
		selector.find("form.edit-impact .submit-button").on("click", this.handleClickOnSubmitEditImpactButton.bind(this));

	}

	handleClickOnAddImpactButton (e) {


		if (!$("#btn-add-impact").hasClass("inactive")) {

			// We get new impact values
			const impactValueFr = $("#section_cartography_impacts input[name='impact_fr']").val();
			const impactValueEn = $("#section_cartography_impacts input[name='impact_en']").val();
			const impactSlug = this.getImpactSlug(impactValueEn);

			// We check if the new impact is already taken
			const isImpactTaken = this.getImpactTaken(impactValueFr, impactValueEn);

			if (!isImpactTaken) {

				getFontAwesomeIcon(impactSlug).then((iconClass) => {

					// We define the impact value icon
					const impactValueIcon = iconClass;

				  // We add the impact on data cartography impacts
					this.dataCartographyImpacts.push({
						"index": this.dataCartographyImpacts.length,
						"name_fr": impactValueFr,
						"name_en": impactValueEn,
						"picto": impactValueIcon,
						"order": 0
					});

					// We show the impact item
					const impactItemContainer = "#section_cartography_impacts .tab-result";
					const itemData = {
						"index": this.dataCartographyImpacts.length - 1,
						"name_fr": impactValueFr,
						"name_en": impactValueEn,
						"picto": impactValueIcon
					};
					this.appendAndFill(impactItemContainer, itemData);

					// We clear the add impact form
					this.clearAddImpactForm();

					// We remove the impact field error
					$("#cartography_impacts .tab-result").removeClass("tab-result-error");
					$("#cartography_impacts .msg-error").remove();

					// We update the scales section
					cartographyScaleField.initScalesSections(this.dataCartographyImpacts);

				}).catch((error) => {

				    console.error(error);

				});

			} else {

				// We remove the error
				$("#section_cartography_impacts .msg-error").remove();


				// We define the html for the error message
				const htmlErrorMessage = `
					<div class="error msg-error margin-top" data-i18n="form-error-impact-taken">${$.i18n("form-error-impact-taken")}</div>
				`;

				// We show the error message
				$(htmlErrorMessage).insertAfter("#section_cartography_impacts .line-add");

			}

		}

	}

	getImpactTaken (impactValueFr, impactValueEn) {

		const impactFrToCheck = impactValueFr.toLowerCase();
		const impactEnToCheck = impactValueEn.toLowerCase();

		let isImpactTaken = false;
		let impactCount = 0;

		// For each existing impact
		$(".impact-name").each(function () {

			// We get the impact name
			const curImpactName = $(this).text().toLowerCase();

			if (curImpactName === impactFrToCheck || curImpactName === impactEnToCheck) {

				impactCount++;

			}

		});

		if (impactCount > 0) {

			isImpactTaken = true;

		} else {

			isImpactTaken = false;

		}

		return isImpactTaken;

	}

	clearAddImpactForm () {

		// We clear the fields
		$("#section_cartography_impacts input[name='impact_fr']").val("");
		$("#section_cartography_impacts input[name='impact_en']").val("");

		// We clear the add impact button
		$("#btn-add-impact").addClass("inactive");

	}

	handleChangeOnImpactFields (e) {

		if ($("#section_cartography_impacts input[name='impact_fr']").val() !== "" && $("#section_cartography_impacts input[name='impact_en']").val() !== "") {

			// We active the add button
			$("#btn-add-impact").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-impact").addClass("inactive");

		}

	}

	handleClickOnEditImpactButton (e) {

		// We define the container selector
		const containerSelector = $(e.currentTarget).parents(".label-result");

		if (containerSelector.attr("class").indexOf("active") < 0) {

			// We show the edit impact content
			this.showEditImpactContent(containerSelector);

		} else {

			// We hide the edit impact content
			this.hideEditImpactContent(containerSelector);

		}

	}

	showEditImpactContent (containerSelector) {

		// We add the active class on the edit button
		containerSelector.find(".btn-edit-impact").addClass("active");

		// We add the active class on the container
		containerSelector.addClass("active");

		// We open the content
		containerSelector.find(".hidden-content").slideDown(200);

	}

	hideEditImpactContent (containerSelector) {

		// We remove the active class on the edit button
		containerSelector.find(".btn-edit-impact").removeClass("active");

		// We remove the active class on the container
		containerSelector.removeClass("active");

		// We close the content
		containerSelector.find(".hidden-content").slideUp(200);

	}

	handleClickOnDeleteImpactButton (e) {

		// We stop the click propagation on the parent
		e.stopPropagation();

		// We get the selected branch ID
		const selectedBranchId = $("#branch-field select[name='branch_id']").val();

		// We define the context data to load evaluations
		const contextData = {
			"cartography_id": this.cartographyId,
			"branch_id": selectedBranchId
		};

		if (this.cartographyId !== null) {

			// We load evaluations for context to check if we can delete this impact
			cartographies.getEvaluationForContext(contextData).then((evaluations) => {

				if (evaluations !== undefined && evaluations !== null && evaluations.length > 0) {

					// Delete impact error popin
					this.deleteImpactErrorPopin = new Popin("delete-impact-error-popin", `${$.i18n("popin-delete-impact-error-title")}`, [
						{
							"title": `${$.i18n("button-ok")}`,
							"class": "btn-submit",
							"iconClass": "icon fas fa-check",
							"callback": this.handleDeleteImpactErrorConfirm.bind(this)
						}
					]);

					$(".popin").remove();

					this.deleteImpactErrorPopin.appendIn("#page");

					this.deleteImpactErrorPopin.show();

				} else {

					// We delete the impact item
					this.deleteImpactItem($(e.currentTarget));

				}


			}).catch((error) => {

				// We delete the impact item
				this.deleteImpactItem($(e.currentTarget));

				debug.v("CartographyImpactField - handleClickOnDeleteImpactButton - error in Cartographies getEvaluationForContext");
				reject();

			});

		} else {

			// We delete the impact item
			this.deleteImpactItem($(e.currentTarget));

		}

	}

	deleteImpactItem (curImpactItem) {

		// We get the index of the current impact
		const curIndexImpact = curImpactItem.parents(".label-result").attr("data-index-cartography-impact");

		// We get the ID of the current impact
		const curIdImpact = curImpactItem.parents(".label-result").attr("data-id-cartography-impact");

		// We get the title of the current impact
		const curTitleImpact = curImpactItem.parents(".label-result").find(".name-context .text").html();

		// We delete the impact in the array of list of impacts
		this.dataCartographyImpacts.splice(curIndexImpact, 1);

		// We delete the label of the impact
		curImpactItem.parents(".label-result").remove();

		// We delete the scales section
		cartographyScaleField.removeImpactScaleSection(curIdImpact, curTitleImpact);

	}

	handleDeleteImpactErrorConfirm (e) {

		this.deleteImpactErrorPopin.hide();

	}

	handleClickOnImpactTranslateButton (e) {

		// We get the current button id
		const buttonId = $(e.currentTarget).attr("id");

		// We get the current container
		const buttonContainer = $(e.currentTarget).parents(".wrap-field");

		// We get the current text field value
		const textFieldValue = buttonContainer.find(".text-field").val();

		if (textFieldValue === "") {

			// We check if the impact is translate and activate the impact creation button
			const refreshFieldToActivateImpactCreationButton = setInterval(() => {

				const newTextFieldValue = buttonContainer.find(".text-field").val();

				if (
				  buttonContainer.attr("class").indexOf("loading") < 0 &&
					newTextFieldValue !== undefined &&
					newTextFieldValue !== null &&
					newTextFieldValue !== ""
				) {

					// We active the add button
					$("#btn-add-impact").removeClass("inactive");

					clearInterval(refreshFieldToActivateImpactCreationButton);

				}

			}, 500);


		}

	}

	autoFillCartographyImpacts (editedCartography) {

		if (editedCartography != undefined && editedCartography.impacts !== undefined && editedCartography.impacts !== null && editedCartography.impacts.length > 0) {

			// We define cartography impacts
			this.dataCartographyImpacts = editedCartography.impacts;

			editedCartography.impacts.forEach((impact, index) => {

				// We show the impact item
				const impactItemContainer = "#section_cartography_impacts .tab-result";
				const itemData = {
					index,
					"id": impact.id,
					"picto": impact.picto,
					"name_fr": impact.name_fr,
					"name_en": impact.name_en
				};
				this.appendAndFill(impactItemContainer, itemData);

			});

		}

	}

	appendAndFill (containerSelector, data) {

		// We get the impact lib in the current language
		const libCurrentLang = data[`name_${this.currentLang}`];

		// We get the impact lib in the other language
		let libOtherLang;
		if (this.currentLang === "fr") {

			libOtherLang = data.name_en;

		} else if (this.currentLang === "en") {

			libOtherLang = data.name_fr;

		}

		// We define the impact id
		let impactId = null;
		if (data.id !== undefined && data.id !== null) {

			impactId = data.id;

		}

		// We define the html of the label of impact
		const htmlLabelImpact = `
			<div class="label-result full-width accordion" data-id-cartography-impact="${impactId}" data-index-cartography-impact="${data.index}">
				<div class="head-infos">
					<div class="info-context">
						<div class="name-context">
							<span class="icon ${data.picto}"></span>
							<span class="text impact-name">${libCurrentLang}</span>
						</div>
						<div class="name-sub-context impact-other-lang impact-name">${libOtherLang}</div>
					</div>
					<div class="other-info">
						<div class="btn-edit-impact">
							<span class="icon fas fa-pencil-alt"></span>
						</div>
						<div class="btn-delete-impact">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
				<div class="hidden-content">
					<form class="edit-impact">
						<div class="content-form">
							<div class="content-form-field">
								<div class="label">
						      <i class="flag flag-fr"></i>
						      <span class="text" data-i18n="form-label-field-title-fr">${$.i18n("form-label-field-title-fr")}</span>
						    </div>
								<input class="text-field impact_fr fake-input" type="text" name="impact_${data.index}_fr" value="${data.name_fr}" />
							</div>
							<div class="content-form-field">
								<div class="label">
						      <i class="flag flag-en"></i>
						      <span class="text" data-i18n="form-label-field-title-en">${$.i18n("form-label-field-title-en")}</span>
						    </div>
								<input class="text-field impact_en fake-input" type="text" name="impact_${data.index}_en" value="${data.name_en}" />
							</div>
						</div>
						<div class="line-submit-button">
							<div class="submit-button">
								<span class="button-icon fas fa-check"></span>
								<span class="button-text" data-i18n="form-label-btn-update">${$.i18n("form-label-btn-update")}</span>
							</div>
						</div>
					</form>
				</div>
			</div>`;

		// We show the label of impact
		$(containerSelector).prepend(htmlLabelImpact);

		// We define the current label result selector
		const curLabelResultSelector = $("#section_cartography_impacts").find(`.label-result[data-id-cartography-impact="${impactId}"]`);

		// We init the events for edit and delete buttons
		this.initEditAndDeleteButtonsEvents(curLabelResultSelector);

	}

	handleClickOnSubmitEditImpactButton (e) {

		if (!$(e.currentTarget).hasClass("inactive")) {

			// We add the loader on the submit edit button
			this.addLoaderOnSubmitEditButton($(e.currentTarget));

			// We define the container selector
			const containerSelector = $(e.currentTarget).parents(".label-result");

			// We define the input selector
			const inputFrSelector = containerSelector.find("form.edit-impact input.impact_fr");
			const inputEnSelector = containerSelector.find("form.edit-impact input.impact_en");

			// We get new impact values
			const impactValueFr = inputFrSelector.val();
			const impactValueEn = inputEnSelector.val();
			const impactSlug = this.getImpactSlug(impactValueEn);

			// We get the impact id
			const curImpactId = containerSelector.attr("data-id-cartography-impact");

			// We get the impact index
			const curImpactIndex = containerSelector.attr("data-index-cartography-impact");

			getFontAwesomeIcon(impactSlug).then((iconClass) => {

				// We define the index data to update on the data cartography impacts array
				let indexDataToUpdate;

				if (curImpactId !== undefined && curImpactId !== null && curImpactId !== "null") {

					// We get the index data to update on the data cartography impacts array
					indexDataToUpdate = this.dataCartographyImpacts.findIndex((impact) => parseInt(impact.id) === parseInt(curImpactId));

				} else {

					// We get the index data to update on the data cartography impacts array
					indexDataToUpdate = this.dataCartographyImpacts.findIndex((impact) => parseInt(impact.index) === parseInt(curImpactIndex));

				}

				// We update the impact data
				this.dataCartographyImpacts[indexDataToUpdate].name_fr = impactValueFr;
				this.dataCartographyImpacts[indexDataToUpdate].name_en = impactValueEn;
				this.dataCartographyImpacts[indexDataToUpdate].picto = iconClass;

				// We get the impact libs
				let libCurrentLang;
				let libOtherLang;
				if (this.currentLang === "fr") {

					libCurrentLang = impactValueFr;
					libOtherLang = impactValueEn;

				} else if (this.currentLang === "en") {

					libCurrentLang = impactValueEn;
					libOtherLang = impactValueFr;

				}

				// We update the icon and the lib of the current language
				const newHtmlNameContext = `
					<span class="icon ${iconClass}"></span>
					<span class="text">${libCurrentLang}</span>
				`;
				containerSelector.find(".head-infos .name-context").html(newHtmlNameContext);

				// We update the lib of the other language
				containerSelector.find(".head-infos .name-sub-context").html(libOtherLang);

				// We hide the edit impact content
				this.hideEditImpactContent(containerSelector);

				// We update the scales section
				cartographyScaleField.initScalesSections(this.dataCartographyImpacts);

				// We remove the loader on the submit edit button
				this.removeLoaderOnSubmitEditButton($(e.currentTarget));


			}).catch((error) => {

			    console.error(error);

			});

		}

	}

	addLoaderOnSubmitEditButton (curButton) {

		// We define the container button selector
		const containerSelector = curButton.parents(".line-submit-button");

		// We add a class to the container to change the flex-direction
		containerSelector.addClass("flex-direction-row");

		// We add the inactive class on the button
		curButton.addClass("inactive");

		// We add the button loader
		containerSelector.append("<div class='button-loader'></div>");

	}

	removeLoaderOnSubmitEditButton (curButton) {

		// We define the container button selector
		const containerSelector = curButton.parents(".line-submit-button");

		// We remove the class to the container to change the flex-direction
		containerSelector.removeClass("flex-direction-row");

		// We remove the inactive class on the button
		curButton.removeClass("inactive");

		// We remove the button loader
		containerSelector.find(".button-loader").remove();

	}

	getImpactSlug (value) {

		let impactSlug;

		if (value !== undefined && value !== null) {

			impactSlug = slugify(value).replace("-impact", "").replace("impact-", "").replace("impact-of-", "");

		} else {

			impactSlug = "";

		}


		return impactSlug;

	}

	getCartographyImpacts () {

		// For each cartography impacts saved
		this.dataCartographyImpacts.forEach((impact, index) => {

			// We clean the values
			delete impact.cartography_id;
			delete impact.created_at;
			delete impact.updated_at;
			delete impact.status;


		});

		// We return the cartography impacts
		return this.dataCartographyImpacts;

	}

}
export default new CartographyImpactField();
