// Not found
import NotFound from "Pages/NotFound/NotFound.js";

// Not Allowed
import NotAllowed from "Pages/NotAllowed/NotAllowed.js";

// Approach
import Approach from "Pages/Approach/Approach.js";

// About
import About from "Pages/About/About.js";

// News
import News from "Pages/News/News.js";

// Exports
import Exports from "Pages/Exports/Exports.js";

// Notifications
import Notifications from "Pages/Notifications/Notifications.js";

// Statistics
import Statistics from "Pages/Statistics/Statistics.js";

// User
import Login from "Pages/Login/Login.js";
import Logout from "Pages/Logout/Logout.js";
import TempPassword from "Pages/TempPassword/TempPassword.js";

// Dashboard
import DashboardGrid from "Pages/Dashboard/Grid/DashboardGrid.js";
import DashboardList from "Pages/Dashboard/List/DashboardList.js";
import DashboardGraph from "Pages/Dashboard/Graph/DashboardGraph.js";
import DashboardCartographies from "Pages/Dashboard/Cartographies/DashboardCartographies.js";

// Admin users
import UsersList from "Pages/Admin/Users/List/UsersList.js";
import UserShow from "Pages/Admin/Users/Show/UserShow.js";
import UserEdit from "Pages/Admin/Users/Edit/UserEdit.js";

// Admin cartographies
import CartographiesList from "Pages/Admin/Cartographies/List/CartographiesList.js";
import CartographyEdit from "Pages/Admin/Cartographies/Edit/CartographyEdit.js";

// Admin news
import NewsList from "Pages/Admin/News/List/NewsList.js";
import NewsEdit from "Pages/Admin/News/Edit/NewsEdit.js";

// Admin business activities
import BusinessActivitiesList from "Pages/Admin/BusinessActivities/List/BusinessActivitiesList.js";
import BusinessActivityEdit from "Pages/Admin/BusinessActivities/Edit/BusinessActivityEdit.js";
import BusinessActivitiesSelect from "Pages/Admin/BusinessActivities/Select/BusinessActivitiesSelect.js";

// Admin legal entity category for cartography
import LegalEntityCategoryForCartographyEdit from "Pages/Admin/LegalEntityCategoryForCartography/Edit/LegalEntityCategoryForCartographyEdit.js";

// Admin action plans
import ActionPlanEdit from "Pages/Admin/ActionsPlan/Edit/ActionPlanEdit.js";
import ActionPlanList from "Pages/Admin/ActionsPlan/List/ActionsPlanList.js";

// Single Page
import SinglePage from "Pages/SinglePage/SinglePage.js";

// Risks
import Risk from "Pages/Risks/Risk/Risk.js";
import RiskCreate from "Pages/Risks/Create/RiskCreate.js";
import RiskEdit from "Pages/Risks/Edit/RiskEdit.js";
import RiskEvaluate from "Pages/Risks/Evaluate/RiskEvaluate.js";
import RiskEvaluateForBusinessActivities from "Pages/Risks/Evaluate/RiskEvaluateForBusinessActivities.js";
import RiskSuggest from "Pages/Risks/Suggest/RiskSuggest.js";
import RiskActionEdit from "Pages/Risks/ActionEdit/ActionEdit.js";

// Current user
import CurrentUserShow from "Pages/User/Show/CurrentUserShow.js";

// Catalog
import Catalog from "Pages/Catalog/Catalog.js";
