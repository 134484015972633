import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import graphsContainer from "Components/Graphs/GraphsContainer.js";
import html from "./dashboard_cartographies.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import "../Dashboard.scss";
import "../DashboardResponsive.scss";
// Import select2 library
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";
// Import mapping categories JSON
import cartographyCategories from "Core/assets/json/mapping_categories.json";

class DashboardCartographies extends Page {

	constructor () {

		let routes = [
			"/dashboard/cartographies/all",
			"/dashboard/branches/:idBranch/cartographies/all/legal-entities/:idLegalEntity",
			"/dashboard/branches/:idBranch/cartographies/all/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/all/legal-entities/:idLegalEntity",
			"/dashboard/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/all/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard/branches/:idBranch/countries/:idCountry/cartographies/all/legal-entities/:idLegalEntity",
			"/dashboard/branches/:idBranch/countries/:idCountry/cartographies/all/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			countries,
			businessActivities,
			context
		];

		super(routes, AuthentifiedLayout, "Risk Management Platform", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		// We get the params
		this.params = params;

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We define the current branch
		this.branch = branches.find(context.getCurrentBranchId());

		// We define the current cartography
		this.cartography = cartographies.find(cartographies.getCurrentCartographyId());

		// We get the current branch id
		this.curBranchId = this.branch.id;

		// We get the current profit center id
		this.curProfitCenterId = context.getCurrentProfitCenterId();

		// We load the current legal entity
		this.curLegalEntityId = context.getCurrentLegalEntityId();

		// We get the current country id
		this.curCountryId = context.getCurrentCountryId();

		// We init cartographies data
		this.initCartographies();

	}

	initCartographies () {

		// We get cartographies for the current branch
		const listCartographies = cartographies.data.filter((cartography) => cartography.branch_id === this.branch.id);

		if (listCartographies !== undefined && listCartographies !== null && listCartographies.length > 0) {

			// We show the cartographies container
			$("#cartographies-container").addClass("active");

			// For each cartography
			listCartographies.forEach((cartography) => {

				// We get the cartography name
				const curCartographyName = cartography[`name_${this.currentLang}`];

				// We define the html for the cartography line
				const htmlCartographyLine = `
					<div class="cartography-line" data-cartography-id="${cartography.id}">
						<div class="cartography-title">
							<div class="button">
								<div class="text">${curCartographyName}</div>
							</div>
							<div class="btn-more">
								<div class="text" data-i18n="btn-see-mapping">${$.i18n("btn-see-mapping")}</div>
								<div class="icon fas fa-angle-right"></div>
							</div>
						</div>
						<div class="cartography-graph"></div>
						<div class="cartography-container-risks"></div>
					</div>
				`;

				// If the section doesn't exists
				if ($(`#cartographies-container .cartography-section[data-category-id=${parseInt(cartography.category)}]`).length < 1) {

					const curCartographyCategoryData = cartographyCategories.data.filter((category) => parseInt(category.id) === parseInt(cartography.category))[0];

					if (curCartographyCategoryData !== undefined) {

						// We define the html of the section
						const htmlSectionCartography = `
							<div class="cartography-section" data-category-id="${parseInt(curCartographyCategoryData.id)}" data-category-position="${parseInt(curCartographyCategoryData.position)}">
								<div class="title-category">
									<span class="icon fas fa-th"></span>
									<span class="text">${curCartographyCategoryData[this.currentLang]}</span>
								</div>
								<div class="list-cartographies"></div>
							</div>
						`;

						// We add the new section on the cartography container
						$("#cartographies-container").append(htmlSectionCartography);

					}

				}

				// If current cartograhy has a category
				if (cartography.category !== undefined && cartography.category !== null) {

					// We show the cartography line in the cartography section
					$(`#cartographies-container .cartography-section[data-category-id=${parseInt(cartography.category)}] .list-cartographies`).append(htmlCartographyLine);

				} else if ($("#cartographies-container .cartography-section[data-category-id='1']").length > 0) {

					// We show the cartography line in the section of the first category
					$("#cartographies-container .cartography-section[data-category-id='1'] .list-cartographies").append(htmlCartographyLine);

				} else {

					// We show the cartography line in the container
					$("#cartographies-container").append(htmlCartographyLine);

				}

				// We sort the cartographies sections
				$("#cartographies-container").find(".cartography-section").sort((a, b) => $(a).attr("data-category-position") - $(b).attr("data-category-position")).appendTo("#cartographies-container");

				// We load risks for the current cartography
				this.loadCartographyRisks(cartography.id);

			});

		} else {

			// We remove the container
			$("#cartographies-container").remove();

			// We show the message for no cartography
			$("#msg-no-cartography .text .standard").html($.i18n("msg-error-no-cartography-for-current-branch"));
			$("#msg-no-cartography").addClass("active");

		}

	}

	loadCartographyRisks (curCartographyId) {

		return new Promise((resolve, reject) => {

			// We defines booleean to ease algo reading
			const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
			const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
			const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
			const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
			const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
			const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
			const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

			// If no parameters are given, we check if the legal entities has business activities
			if (!paramsGiven) {

				this.loadRisksForLegalEntity(curCartographyId, this.curLegalEntityId, resolve, reject);

			} else if (isProfitCenterOnAll && isLegalEntityOnAll) {

				this.loadRisksForBranch(curCartographyId, this.curBranchId, resolve, reject);

			} else if (isCountryOnAll && isLegalEntityOnAll) {

				this.loadRisksForProfitCenter(curCartographyId, this.curProfitCenterId, resolve, reject);

			} else if (isLegalEntityOnAll) {

				this.loadRisksForCountry(curCartographyId, this.curCountryId, resolve, reject);

			} else if (isBusinessActivityAnId) {

				this.loadRisksForBusinessActivity(curCartographyId, this.curLegalEntityId, this.params.idBusinessActivity, resolve, reject);

			} else if (isBusinessActivityOnAll) {

				this.loadRisksForLegalEntity(curCartographyId, this.curLegalEntityId, resolve, reject);

			} else {

				this.loadRisksForLegalEntity(curCartographyId, this.curLegalEntityId, resolve, reject);

			}

		}).catch(console.e);

	}

	loadRisksForBusinessActivity (curCartographyId, curLegalEntityId, businessActivityId, resolve, reject) {

		// We load evaluations
		cartographies.getEvaluationForBusinessActivity(curCartographyId, curLegalEntityId, businessActivityId).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(curCartographyId).then((risks) => {

				this.evaluation = evaluations;

				// We show the cartography graph
				this.showCartographyGraph(curCartographyId, risks, this.evaluation);

				resolve();

			}).catch((error) => {

				debug.e("DashboardCartographies - loadRisksForBusinessActivity - error in Risks getRisksCartography");
				reject();

			});

		}).catch((error) => {

			debug.e("DashboardCartographies - loadRisksForBusinessActivity - error in Cartographies getEvaluationForBusinessActivity");
			reject();

		});

	}

	loadRisksForLegalEntity (curCartographyId, curLegalEntityId, resolve, reject) {

		legalEntities.getBusinessActivities(curLegalEntityId).then((businessActivities) => {

			// We show legal entity consolidation if at least one business activity exists
			if (businessActivities.length > 0) {

				const contextData = {
					"cartography_id": curCartographyId,
					"legal_entity_id": curLegalEntityId
				};

				// We load evaluations for context
				cartographies.getEvaluationForContext(contextData).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						// We show the cartography graph
						if (
							risks !== undefined &&
							risks !== null &&
							risks.length > 0 &&
							evaluations !== undefined &&
							evaluations !== null &&
							evaluations.length > 0 &&
							evaluations.error === undefined
						) {

							this.showCartographyGraph(curCartographyId, risks, evaluations);

						} else {

							// We remove the cartography line
							this.removeCartographyLine(curCartographyId);

							// We display the global error message
							this.displayGlobalErrorMessage();

						}

						resolve();

					});

				}).catch((error) => {

					debug.v("DashboardCartographies - loadRisksForLegalEntity - error in Cartographies getEvaluationForContext");
					reject();

				});

			}
			// Otherwise we show legal entity evaluations
			else {

				cartographies.getEvaluation(curCartographyId, curLegalEntityId).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						// We show the cartography graph
						if (
							risks !== undefined &&
							risks !== null &&
							risks.length > 0 &&
							evaluations !== undefined &&
							evaluations !== null &&
							evaluations.length > 0 &&
							evaluations.error === undefined
						) {

							this.showCartographyGraph(curCartographyId, risks, evaluations);

						} else {

							// We remove the cartography line
							this.removeCartographyLine(curCartographyId);

							// We display the global error message
							this.displayGlobalErrorMessage();

						}

						resolve();

					});

				}).catch((error) => {

					debug.v("DashboardCartographies - loadRisksForLegalEntity - error in Cartographies getEvaluation");
					reject();

				});

			}

		});

	}

	removeCartographyLine (cartographyId) {

		// We remove the cartography line
		$(`#cartographies-container .cartography-line[data-cartography-id="${cartographyId}"]`).remove();

		// We get the cartography data
		const cartographyData = cartographies.find(cartographyId);

		// We get the cartography category id
		const cartographyCategoryId = parseInt(cartographyData.category);

		// We get the number of cartographies lines in the cartography section
		const numCartographiesLines = $(`.cartography-section[data-category-id="${cartographyCategoryId}"] .cartography-line`).length;

		// If the cartography section is empty
		if (numCartographiesLines < 1) {

			// We remove it
			$(`.cartography-section[data-category-id="${cartographyCategoryId}"]`).remove();

		}

	}

	loadRisksForBranch (curCartographyId, curBranchId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"branch_id": curBranchId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForProfitCenter (curCartographyId, curProfitCenterId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"profit_center_id": curProfitCenterId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForCountry (curCartographyId, curCountryId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"country_id": curCountryId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForContext (contextData, resolve, reject) {

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(contextData.cartography_id).then((risks) => {

				// We show the cartography graph
				if (
					risks !== undefined &&
						risks !== null &&
						risks.length > 0 &&
						evaluations !== undefined &&
						evaluations !== null &&
						evaluations.length > 0 &&
						evaluations.error === undefined
				) {

					this.showCartographyGraph(curCartographyId, risks, evaluations);

				} else {

					// We remove the cartography line
					this.removeCartographyLine(curCartographyId);

					// We display the global error message
					this.displayGlobalErrorMessage();

				}

				resolve();

			});

		}).catch((error) => {

			debug.v("DashboardCartographies - loadRisksForContext - error in Cartographies getEvaluationForContext");
			reject();

		});

	}

	showCartographyGraph (cartographyId, risks, evaluations) {

		// We define the cartography line container
		const cartographyLine = $(`#cartographies-container .cartography-line[data-cartography-id="${cartographyId}"]`);

		// We have an exeception for the cartographies with only one evaluation with nothing
		if (
			evaluations.length === 1 &&
			evaluations[0].scores.gross_risk !== undefined &&
			evaluations[0].scores.net_risk !== undefined &&
			evaluations[0].scores.gross_risk === null &&
			evaluations[0].scores.net_risk === null
		) {

			cartographyLine.remove();

			// We display the global error message
			this.displayGlobalErrorMessage();

		}

		// We clear the container
		cartographyLine.find(".cartography-container-risks").html("");

		// If the current cartography have some risks
		if (
			risks !== undefined &&
			risks !== null &&
			risks.length > 0
		) {

			// We show the cartography line
			cartographyLine.addClass("active");

			// We show the risks
			risks.forEach((item) => {

				const card = new CardRisk(item);

				if (item.status !== undefined && item.status === "valid") {

					// We get the evaluation
					if (evaluations !== undefined && evaluations.error === undefined) {

						const curRiskEvaluation = evaluations.filter((evaluation) => parseInt(evaluation.risk_id) === parseInt(item.id));

						let curItemColor;
						if (
							curRiskEvaluation[0] !== undefined &&
							curRiskEvaluation[0].scores.impact !== null &&
							curRiskEvaluation[0].scores.gross_risk !== null &&
							(curRiskEvaluation[0].scores.control !== null || curRiskEvaluation[0].scores.control.average !== null)
						) {

							curItemColor = card.getCardColor(curRiskEvaluation[0]);

							let convertedScoreImpact;
							if (curRiskEvaluation[0].scores.impact !== null && curRiskEvaluation[0].scores.impact.toString().indexOf(",") > -1) {

								convertedScoreImpact = curRiskEvaluation[0].scores.impact.toString().replace(",", ".");

							} else {

								convertedScoreImpact = curRiskEvaluation[0].scores.impact;

							}

							let convertedScoreOccurrence;
							if (
							  curRiskEvaluation[0].scores.occurrence.average === undefined &&
								curRiskEvaluation[0].scores.occurrence !== null &&
								curRiskEvaluation[0].scores.occurrence.toString().indexOf(",") > -1
							) {

								convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.toString().replace(",", ".");

							} else if (
								curRiskEvaluation[0].scores.occurrence.average !== undefined &&
								curRiskEvaluation[0].scores.occurrence.average !== null &&
								curRiskEvaluation[0].scores.occurrence.average.toString().indexOf(",") > -1
							) {

								convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.average.toString().replace(",", ".");

							} else if (
								curRiskEvaluation[0].scores.occurrence.average !== undefined &&
								curRiskEvaluation[0].scores.occurrence.average !== null &&
								curRiskEvaluation[0].scores.occurrence.average.toString().indexOf(",") < 0
							) {

								convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.average;

							} else {

								convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence;

							}

							let convertedScoreControl;
							if (
							  curRiskEvaluation[0].scores.control.average === undefined &&
								curRiskEvaluation[0].scores.control !== null &&
								curRiskEvaluation[0].scores.control.toString().indexOf(",") > -1
							) {

								convertedScoreControl = curRiskEvaluation[0].scores.control.toString().replace(",", ".");

							} else if (
								curRiskEvaluation[0].scores.control.average !== undefined &&
								curRiskEvaluation[0].scores.control.average !== null &&
								curRiskEvaluation[0].scores.control.average.toString().indexOf(",") > -1
							) {

								convertedScoreControl = curRiskEvaluation[0].scores.control.average.toString().replace(",", ".");

							} else if (
								curRiskEvaluation[0].scores.control.average !== undefined &&
								curRiskEvaluation[0].scores.control.average !== null &&
								curRiskEvaluation[0].scores.control.average.toString().indexOf(",") < 0
							) {

								convertedScoreControl = curRiskEvaluation[0].scores.control.average;

							} else {

								convertedScoreControl = curRiskEvaluation[0].scores.control;

							}

							// We define the graph container for the risks cards
							const risksCardsSelector = cartographyLine.find(`.cartography-graph .list-risks.${curItemColor}`);

							// If the list of risks of this color isn't present on the graph
							if (risksCardsSelector.length < 1) {

								// We create the html of the risks list
								const htmlListRisks = `
									<div class="list-risks ${curItemColor}">
										<div class="button">
											<div class="text">
												<span class="highlight" data-num-risks="0"></span>
											</div>
											<div class="icon fas fa-angle-right"></div>
										</div>
									</div>
								`;

								// We add it in the cartography line graph
								if (curItemColor === "green") {

									cartographyLine.find(".cartography-graph").prepend(htmlListRisks);

								} else if (curItemColor === "yellow") {

									if (cartographyLine.find(".cartography-graph .list-risks.green").length > 0) {

										$(htmlListRisks).insertAfter(cartographyLine.find(".cartography-graph .list-risks.green"));

									} else {

										cartographyLine.find(".cartography-graph").prepend(htmlListRisks);

									}

								} else if (curItemColor === "orange") {

									if (cartographyLine.find(".cartography-graph .list-risks.red").length > 0) {

										$(htmlListRisks).insertBefore(cartographyLine.find(".cartography-graph .list-risks.red"));

									} else {

										cartographyLine.find(".cartography-graph").append(htmlListRisks);

									}

								} else if (curItemColor === "red") {

									cartographyLine.find(".cartography-graph").append(htmlListRisks);

								}


							}

							// We define the current card risk
							const curCardRisk = cartographyLine.find(`.cartography-container-risks .card-risk[data-id-risk=${item.id}]`);

							// If the card of the current risk isn't present in the risks container
							if (curCardRisk.length < 1) {

								// We add it
								card.appendAndFill(`#cartographies-container .cartography-line[data-cartography-id="${cartographyId}"] .cartography-container-risks`, curItemColor, item, curRiskEvaluation[0].scores);

							}

						}

					}

				}

			});

			setTimeout(() => {

				// We add graph informations
				this.addGraphInformations(cartographyId);

				// We listen events
				cartographyLine.on("mouseenter", this.handleMouseOverOnCartographyLine.bind(this));
				cartographyLine.on("mouseleave", this.handleMouseOutOnCartographyLine.bind(this));
				cartographyLine.on("click", this.handleClickOnCartographyLine.bind(this));
				cartographyLine.find(".cartography-graph .list-risks").on("mouseenter", this.handleMouseOverOnGraphListRisk.bind(this));
				cartographyLine.find(".cartography-graph .list-risks").on("mouseleave", this.handleMouseOutOnGraphListRisk.bind(this));
				cartographyLine.find(".cartography-graph .list-risks").on("click", this.handleClickOnGraphListRisk.bind(this));

				// We listen click on the risks
				cartographyLine.find(".cartography-container-risks .card-risk").on("click", this.handleClickOnRisks.bind(this));

			}, 50);

		} else {

			// We remove the cartography line
			cartographyLine.remove();

			// We display the global error message
			this.displayGlobalErrorMessage();

		}

	}

	addGraphInformations (cartographyId) {

		// For each card risk on the current cartography line
		$(`#cartographies-container .cartography-line[data-cartography-id='${cartographyId}'] .cartography-container-risks .card-risk`).each((i, item) => {

			// We get the card risk color
			const cardRiskColor = this.getCardRiskColorByClassName($(item).attr("class"));

			// We define the risk selector on graph
			const riskSelectorOnGraph = $(`#cartographies-container .cartography-line[data-cartography-id='${cartographyId}'] .cartography-graph .list-risks.${cardRiskColor}`);

			// We define the risk number selector on graph
			const riskNumberSelectorOnGraph = riskSelectorOnGraph.find(".button .highlight");

			// We get the risks number on the graph
			const risksNumber = parseInt(riskNumberSelectorOnGraph.attr("data-num-risks"));

			// We increment the risks number on the graph
			const newRisksNumber = risksNumber + 1;
			riskNumberSelectorOnGraph.attr("data-num-risks", newRisksNumber);
			riskNumberSelectorOnGraph.html(newRisksNumber);

		});

		// We remove all the risks cards in grey
		$(`#cartographies-container .cartography-line[data-cartography-id='${cartographyId}'] .cartography-container-risks .card-risk.color-grey`).remove();

		// We get the number of all risks
		const numAllRisks = $(`#cartographies-container .cartography-line[data-cartography-id='${cartographyId}'] .cartography-container-risks .card-risk`).length;

		// For each risk color on the graph
		$(`#cartographies-container .cartography-line[data-cartography-id='${cartographyId}'] .cartography-graph .list-risks`).each((i, item) => {

			// We get the risks number with the current color
			const risksNumberByColor = parseInt($(item).find(".button .text .highlight").attr("data-num-risks"));

			// We calculate the item width (in percent)
			const itemWidth = risksNumberByColor * 100 / numAllRisks;

			// We update the item width
			$(item).css("width", `${itemWidth}%`);

		});

	}

	displayGlobalErrorMessage () {

		if ($("#cartographies-container .cartography-line").length < 1) {

			// We show the message for no cartography
			$("#msg-no-cartography .text .standard").html($.i18n("msg-error-no-cartography-for-current-branch"));
			$("#msg-no-cartography").addClass("active");

		}

	}

	getCardRiskColorByClassName (curClassName) {

		let color;

		if (curClassName.indexOf("green") >= 0) {

			color = "green";

		} else if (curClassName.indexOf("yellow") >= 0) {

			color = "yellow";

		} else if (curClassName.indexOf("orange") >= 0) {

			color = "orange";

		} else if (curClassName.indexOf("red") >= 0) {

			color = "red";

		} else {

			color = "grey";

		}

		return color;

	}

	handleMouseOverOnCartographyLine (e) {

		$(e.currentTarget).addClass("hover");

	}

	handleMouseOutOnCartographyLine (e) {

		$(e.currentTarget).removeClass("hover");

	}

	handleClickOnCartographyLine (e) {

		// We get the clicked cartograohy id
		const clickedCartographyId = $(e.currentTarget).attr("data-cartography-id");

		// We show the dashboard of the clicked cartography
		router.navigate(`/dashboard-grid/branches/${context.getCurrentBranchId()}/cartographies/${clickedCartographyId}/legal-entities/${context.getCurrentLegalEntityId()}`);

		// We reload the page
  	document.location.reload();

	}

	handleMouseOverOnGraphListRisk (e) {

		$(e.currentTarget).parents(".cartography-line").removeClass("hover");

	}

	handleMouseOutOnGraphListRisk (e) {

		$(e.currentTarget).parents(".cartography-line").addClass("hover");

	}

	handleClickOnGraphListRisk (e) {

		e.stopPropagation();

		if ($(e.currentTarget).attr("class").indexOf("active") < 0) {

			// We remove the active class on all the items
			$(e.currentTarget).parents(".cartography-line").find(".cartography-graph .list-risks").removeClass("active");

			// We add the active class on the current item
			$(e.currentTarget).addClass("active");

			// We get the risk color
			const curRiskColor = this.getCardRiskColorByClassName($(e.currentTarget).attr("class"));

			// We hide all the risks cards
			$(e.currentTarget).parents(".cartography-line").find(".cartography-container-risks .card-risk").removeClass("active");

			// We show the risks cards with the current color
			$(e.currentTarget).parents(".cartography-line").find(`.cartography-container-risks .card-risk.color-${curRiskColor}`).addClass("active");

			// We show the cards risks container
			$(e.currentTarget).parents(".cartography-line").find(".cartography-container-risks").slideDown(300);

		} else {

			// We remove the active class on the current item
			$(e.currentTarget).removeClass("active");

			// We hide the cards risks container
			$(e.currentTarget).parents(".cartography-line").find(".cartography-container-risks").slideUp(300);

		}

	}

	handleClickOnRisks (e) {

		e.stopPropagation();

		// Get the ID of the current risk
		const curIdRisk = $(e.currentTarget).attr("data-id-risk");

		let routeToShow;

		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all legal entities context
			routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all profit centers context
			routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			// We show the risk page with all countries context
			routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else {

			// We show the risk page
			routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

}
export default new DashboardCartographies();
