import router from "Core/router.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import html from "./graph_priority_matrix.html";

class GraphPriorityMatrix {

	appendAndFill (cssSelector, risks, evaluations, style) {

		// We show the graph
		$(cssSelector).append(html);

		// We get the graph item height
		this.graphItemHeight = $("#graph-priority-matrix .graph-title").outerHeight() + $("#graph-priority-matrix .graph-card").outerHeight();

		// We show the risks
		this.showRisksOnGraph(risks, evaluations, style);

		// We init tooltips
		this.initTooltips();

		if (process.env.SHOW_PROGRESS_MARGIN === true || process.env.SHOW_PROGRESS_MARGIN === "true") {

			// We setup the graph for progress margin mode
			this.initProgressMargin();

		}

	}

	initProgressMargin () {

		// We get the thumbnail url of the graph
		const thumbGraphUrl = $("#graph-priority-matrix .graph-title .graph-title-infos .picture img").attr("src");

		// We define the new thumbnail url of the graph
		const newThumbGraphUrl = thumbGraphUrl.replace("_reverse", "");

		// We update the thumbnail of the graph
		$("#graph-priority-matrix .graph-title .graph-title-infos .picture img").attr("src", newThumbGraphUrl);

		// We remove the reverse class on the canvas tab
		$("#graph-priority-matrix .box-graph .canvas .graduation .tab").removeClass("reverse");

	}

	initTooltips () {

		$("#graph-priority-matrix .tooltip-legend").each(function () {

			// We get the height of the tooltip
			const curTooltipHeight = $(this).outerHeight();

			// We get the class name of the container
			const curContainerClassName = $(this).parents(".legend").attr("class");

			// We check if the container is in the bottom of the graph
			let curTooltipTopPosition;
			if (curContainerClassName.indexOf("bottom") >= 0) {

				// We define the top position of the tooltip
				curTooltipTopPosition = `${-curTooltipHeight}px`;

			} else {

				// We define the top position of the tooltip
				curTooltipTopPosition = "25px";

			}

			// We change the position top of the tooltip
			$(this).css("top", curTooltipTopPosition);

		});

	}

	showRisksOnGraph (riskData, evaluationData, style) {

		// We show the risks
		riskData.forEach((item) => {

			const card = new CardRisk(item);

			if (item.status !== undefined && item.status === "valid") {

				// We get the evaluation
				if (evaluationData !== undefined && evaluationData.error === undefined) {

					const curRiskEvaluation = evaluationData.filter((evaluation) => parseInt(evaluation.risk_id) === parseInt(item.id));

					let curItemColor;
					if (
						curRiskEvaluation[0] !== undefined &&
						curRiskEvaluation[0].scores.impact !== null &&
						curRiskEvaluation[0].scores.gross_risk !== null &&
						(curRiskEvaluation[0].scores.control !== null || curRiskEvaluation[0].scores.control.average !== null)
					) {

						curItemColor = card.getCardColor(curRiskEvaluation[0]);

						let convertedScoreImpact;
						if (curRiskEvaluation[0].scores.impact !== null && curRiskEvaluation[0].scores.impact.toString().indexOf(",") > -1) {

							convertedScoreImpact = curRiskEvaluation[0].scores.impact.toString().replace(",", ".");

						} else {

							convertedScoreImpact = curRiskEvaluation[0].scores.impact;

						}

						let convertedScoreOccurrence;
						if (
						  curRiskEvaluation[0].scores.occurrence.average === undefined &&
							curRiskEvaluation[0].scores.occurrence !== null &&
							curRiskEvaluation[0].scores.occurrence.toString().indexOf(",") > -1
						) {

							convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.occurrence.average !== undefined &&
							curRiskEvaluation[0].scores.occurrence.average !== null &&
							curRiskEvaluation[0].scores.occurrence.average.toString().indexOf(",") > -1
						) {

							convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.average.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.occurrence.average !== undefined &&
							curRiskEvaluation[0].scores.occurrence.average !== null &&
							curRiskEvaluation[0].scores.occurrence.average.toString().indexOf(",") < 0
						) {

							convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence.average;

						} else {

							convertedScoreOccurrence = curRiskEvaluation[0].scores.occurrence;

						}

						let convertedScoreControl;
						if (
						  curRiskEvaluation[0].scores.control.average === undefined &&
							curRiskEvaluation[0].scores.control !== null &&
							curRiskEvaluation[0].scores.control.toString().indexOf(",") > -1
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.control.average !== undefined &&
							curRiskEvaluation[0].scores.control.average !== null &&
							curRiskEvaluation[0].scores.control.average.toString().indexOf(",") > -1
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.average.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.control.average !== undefined &&
							curRiskEvaluation[0].scores.control.average !== null &&
							curRiskEvaluation[0].scores.control.average.toString().indexOf(",") < 0
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.average;

						} else {

							convertedScoreControl = curRiskEvaluation[0].scores.control;

						}

						// We calculate the severity score (between 1 and 4)
						const severityScore = this.getSeverityScore(convertedScoreOccurrence, convertedScoreImpact);

						// We define the cell id
						const cellId = `${Math.round(convertedScoreControl)}-${severityScore}`;

						// We show a temporary bullet on the right cell on the tab for the current risk
						$("#graph-priority-matrix .canvas .tab-line").eq(severityScore - 1).find(".tab-col").eq(Math.round(convertedScoreControl) - 1).append(`<span class="temp-risk-bullet" data-risk-id="${item.id}"></span>`);

						// If the list of risks relative to this cell isn't present
						if ($(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${cellId}]`).length < 1) {

							// We create the html of the relative risks list
							const htmlListRelativeRisks = `<div class="list-relative-risks" data-cell-id="${cellId}" data-cell="${cellId.replace("-", "")}"></div>`;

							// We add it in the relative risks container
							$("#graph-priority-matrix .container-relative-risks").append(htmlListRelativeRisks);

						}

						// If the card of the current risk isn't present in the relative risks list
						if ($(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${cellId}] .card-risk[data-id-risk=${item.id}]`).length < 1) {

							// We add it
							card.appendAndFill(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${cellId}]`, curItemColor, item, curRiskEvaluation[0].scores);

						}

					}

				}

			}

		});

		$("#graph-priority-matrix .canvas .tab .tab-line .tab-col").each(function () {

			// We create an id for the current cell
			const curCellId = `${$(this).index() + 1}-${$(this).parent().index() + 1}`;

			// We add an id attribute to the current cell
			$(this).attr("data-cell-id", curCellId);

			// We define the number of risks in the current cell (by default : 0)
			let numRisksInCurrentCell = 0;

			$(this).find(".temp-risk-bullet").each(() => {

				// We increment the number of risks
				numRisksInCurrentCell += 1;

			});

			// We define the html of the cell button
			let htmlCellButton;

			if (window.location.pathname.indexOf("preview") >= 0) {

				// We add the preview class on the container
				$("#graph-priority-matrix .box-graph").addClass("preview");

				// We add the preview class on the risks cards
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk").addClass("preview");

				// We remove all the score tooltips and other elemnens on the risks cards
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .tooltip-score").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .arrow").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .sep").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .btn-more").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .content-card-risk").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk .evaluate-risk").remove();
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks .tooltip-score").remove();

				// We define the html of the cell button
				htmlCellButton = `
					<div class="btn-cell preview">
						<div class="text"><span class="highlight">${curCellId}</span></div>
					</div>
				`;

				// We define the html of the title to add in the relative risks list
				const htmlRelativeRisksTitle = `
					<div class="title-relative-risks">
						<span class="icon fas fa-th-list"></span>
						<span class="text">${curCellId}</span>
					</div>
				`;

				// We add the section title in the list of relative risks
				$(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${curCellId}]`).prepend(htmlRelativeRisksTitle);

			} else {

				// We define the text risk
				let textRisk;
				if (numRisksInCurrentCell <= 1) {

					textRisk = $.i18n("text-risk");

				} else {

					textRisk = $.i18n("text-risks");

				}

				// We define the html of the cell button
				htmlCellButton = `
				<div class="btn-cell">
					<div class="text"><span class="highlight">${numRisksInCurrentCell}</span> <span class="standard">${textRisk}</span></div>
					<div class="icon fas fa-angle-right"></div>
				</div>
			`;

			}

			// We show the cell button (in place of the temp risks bullets)
			if (numRisksInCurrentCell > 0) {

				$(this).html(htmlCellButton);

			}

		});

		// We listen click on the cell button
		$("#graph-priority-matrix .canvas .tab .tab-line .tab-col").on("click", this.handleClickOnCellButton.bind(this));

		// We listen click on the risks
		$("#graph-priority-matrix .container-relative-risks .list-relative-risks .card-risk").on("click", this.handleClickOnRisks.bind(this));

		// We sort the relative risks list
		this.sortRelativeRisks();

		// We add the style class to the graph container
		if (style !== undefined && style !== null) {

			$("#graph-priority-matrix").addClass(style);

		}

	}

	sortRelativeRisks () {

		$("#graph-priority-matrix .container-relative-risks").find(".list-relative-risks").sort((a, b) => $(b).attr("data-cell") - $(a).attr("data-cell")).appendTo("#graph-priority-matrix .container-relative-risks");

	}

	getSeverityScore (scoreOccurrence, scoreImpact) {

		let severityScore = 0;

		if (
		  scoreOccurrence !== undefined &&
			scoreOccurrence !== null &&
			scoreOccurrence !== "" &&
			scoreImpact !== undefined &&
			scoreImpact !== null &&
			scoreImpact !== ""
		) {

			const roundedScoreOccurrence = Math.round(scoreOccurrence);
			const roundedScoreImpact = Math.round(scoreImpact);

			if (roundedScoreOccurrence <= 1) {

				if (roundedScoreImpact <= 5) {

					severityScore = 1;

				} else {

					severityScore = 2;

				}

			} else if (roundedScoreOccurrence > 1 && roundedScoreOccurrence <= 2) {

				if (roundedScoreImpact <= 3) {

					severityScore = 1;

				} else {

					severityScore = 2;

				}

			} else if (roundedScoreOccurrence > 2 && roundedScoreOccurrence <= 3) {

				if (roundedScoreImpact <= 2) {

					severityScore = 1;

				} else if (roundedScoreImpact > 2 && roundedScoreImpact <= 4) {

					severityScore = 2;

				} else {

					severityScore = 3;

				}

			} else if (roundedScoreOccurrence > 3 && roundedScoreOccurrence <= 4) {

				if (roundedScoreImpact <= 1) {

					severityScore = 1;

				} else if (roundedScoreImpact > 1 && roundedScoreImpact <= 3) {

					severityScore = 2;

				} else if (roundedScoreImpact > 3 && roundedScoreImpact <= 5) {

					severityScore = 3;

				} else {

					severityScore = 4;

				}

			} else if (roundedScoreOccurrence > 4 && roundedScoreOccurrence <= 5) {

				if (roundedScoreImpact <= 1) {

					severityScore = 1;

				} else if (roundedScoreImpact > 1 && roundedScoreImpact <= 2) {

					severityScore = 2;

				} else if (roundedScoreImpact > 2 && roundedScoreImpact <= 4) {

					severityScore = 3;

				} else {

					severityScore = 4;

				}

			} else if (roundedScoreOccurrence > 5 && roundedScoreOccurrence <= 6) {

				if (roundedScoreImpact <= 2) {

					severityScore = 2;

				} else if (roundedScoreImpact > 2 && roundedScoreImpact <= 3) {

					severityScore = 3;

				} else {

					severityScore = 4;

				}

			} else {
				// Do nothing
			}

		}

		return severityScore;

	}

	handleClickOnCellButton (e) {

		if ($(e.currentTarget).find(".btn-cell").length >= 1) {

			// We get the current cell id
			const curCellId = $(e.currentTarget).attr("data-cell-id");

			// We remove the active class on all the tab cells
			$("#graph-priority-matrix .canvas .tab .tab-line .tab-col").removeClass("active");

			// We add the active class to the cell
			$(e.currentTarget).addClass("active");

			if (window.location.pathname.indexOf("preview") >= 0) {

				// We scroll to the relative risks container
				$("html, body").animate({"scrollTop": $(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${curCellId}]`).offset().top}, 400);

			} else {

				// We remove the active class on all the lists of relative risks
				$("#graph-priority-matrix .container-relative-risks .list-relative-risks").removeClass("active");

				// We add the active class on the list of relative risks
				$(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${curCellId}]`).addClass("active");

				// We show the relative risks container
				$("#graph-priority-matrix .container-relative-risks").slideDown(400);

				// We scroll to the relative risks container
				$("html, body").animate({"scrollTop": $("#graph-priority-matrix .container-relative-risks").offset().top - 100}, 400);

				// We calculate new the graph item height
				const newGraphItemHeight = this.graphItemHeight + $(`#graph-priority-matrix .container-relative-risks .list-relative-risks[data-cell-id=${curCellId}]`).outerHeight() + 30;

				// We change the height of the graph item
				$("#graph-priority-matrix").css("height", newGraphItemHeight);

			}

		}

	}

	handleClickOnRisks (e) {

		// Get the ID of the current risk
		const curIdRisk = $(e.currentTarget).attr("data-id-risk");

		let routeToShow;

		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all legal entities context
			routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all profit centers context
			routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			// We show the risk page with all countries context
			routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else {

			// We show the risk page
			routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

}
export default new GraphPriorityMatrix();
