import router from "Core/router.js";
import html from "./menu.html";
import debug from "Core/Debug.js";
import user from "Datapoints/User";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import helpFirstActionContainer from "Components/HelpFirstActionContainer/HelpFirstActionContainer.js";
import mainMenuContainer from "Components/MainMenuContainer/MainMenuContainer.js";
import footer from "Components/Footer/Footer.js";
import "./Menu.scss";
import "./MenuResponsive.scss";

// Import i18n
import "Core/assets/lib/i18n/CLDRPluralRuleParser.js";
import "Core/assets/lib/i18n/jquery.i18n.js";
import "Core/assets/lib/i18n/jquery.i18n.messagestore.js";
import "Core/assets/lib/i18n/jquery.i18n.fallbacks.js";
import "Core/assets/lib/i18n/jquery.i18n.language.js";
import "Core/assets/lib/i18n/jquery.i18n.parser.js";
import "Core/assets/lib/i18n/jquery.i18n.emitter.js";
import "Core/assets/lib/i18n/jquery.i18n.emitter.bidi.js";
import flagFr from "Core/assets/icons/flags/fr.png";
import flagEn from "Core/assets/icons/flags/en.png";

class Menu {

	show (mode) {

		return new Promise((resolve, reject) => {

			$("#menu").html(html);

			// We get the width of the window
			const curWindowWidth = $(window).width();

			// If we want to show the menu in minify mode
			if (
				mode !== undefined && mode === "minify" ||
				curWindowWidth <= 1320
			) {

				// We show the minified menu
				this.showMinifyMode();

			} else {

				// We hide the minified menu
				this.hideMinifyMode();

			}

			this.update(resolve, reject);

		});

	}

	update (resolve, reject) {

		// We show the footer
		footer.show();

		// We remove temporary items in the local storage
		this.clearLocalStorage();

		// We init the help first action container
		helpFirstActionContainer.init();

		// We init the animation interval of the notification icon (bell)
		this.notificationIconAnimationInterval;
		clearInterval(this.notificationIconAnimationInterval);

		// We init global events
		this.initGlobalEvents();

		// We init the switch language events
		this.initEventsLanguage();

		// We init logo events
		this.initLogo();

		// We get the current language
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We get the branch id
		this.branchId = context.getCurrentBranchId();

		// We get the cartography id
		this.cartographyId = cartographies.getCurrentCartographyId();

		// We get the legeal entity id
		this.legalEntityId = context.getCurrentLegalEntityId();

		// We reload current data user
		user.getData().then(() => {

			this.handleUserLoggedIn();

			// We init the main menu component
			mainMenuContainer.init();

			// We init the methodo button
			this.initUserProfileButton();

			// We init notifications
			this.initNotifications();

			// We init logout button
			this.initLogoutButton();

			// We init the switch dashboard events
			this.initEventsSwitchDashboardView().then(resolve);

		}).catch(debug.e);

	}

	showMinifyMode () {

		// We hide the sidebar menu
		this.hideSidebarMenu();

		// We init the burger nav button
		this.initBurgerNavButton();

		// We change the menu style
		$("#menu").addClass("minify");

	}

	hideMinifyMode () {

		// We remove the burger nav button
		$("#burger-nav-button").remove();

		// We change the menu style
		$("#menu").removeClass("minify");
		$("#menu").removeClass("active");

		// We change the page style
		$("#page").removeClass("inactive");

	}

	initBurgerNavButton () {

		// We remove the top banner
		$("#top-banner").remove();

		// We remove the burger nav button
		$("#burger-nav-button").remove();

		// We define the html for the burger nav button
		const htmlBurgerNavButton = `
			<div id="burger-nav-button" class="default">
			  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 200 200">
			        <g stroke-width="6.5" stroke-linecap="round">
			          <path
			            d="M72 82.286h28.75"
			            fill="#009100"
			            fill-rule="evenodd"
			            stroke="#024194"
			          />
			          <path
			            d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
			            fill="none"
			            stroke="#024194"
			          />
			          <path
			            d="M72 125.143h28.75"
			            fill="#009100"
			            fill-rule="evenodd"
			            stroke="#024194"
			          />
			          <path
			            d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
			            fill="none"
			            stroke="#024194"
			          />
			          <path
			            d="M100.75 82.286h28.75"
			            fill="#009100"
			            fill-rule="evenodd"
			            stroke="#024194"
			          />
			          <path
			            d="M100.75 125.143h28.75"
			            fill="#009100"
			            fill-rule="evenodd"
			            stroke="#024194"
			          />
			        </g>
			      </svg>
			</div>
		`;

		// If the context selector is shown
		if ($("body").attr("class").indexOf("select-context-show-on-page") >= 0) {

			// We show the burger nav button
			$("#content").prepend(htmlBurgerNavButton);

		} else if (window.location.pathname.indexOf("preview") >= 0) {

			// We show the burger nav button
			$("#content").prepend(htmlBurgerNavButton);

			// We add a preview class to the burger nav button
			$("#burger-nav-button").addClass("preview");

		} else {

			// We show the burger nav button with the top banner
			const htmlContainerTopBanner = `<div id="top-banner"><div class="logo"><a href="/"></a></div></div>${htmlBurgerNavButton}`;

			$("#content").prepend(htmlContainerTopBanner);

			// We add a class to the burger nav button
			$("#burger-nav-button").addClass("no-select-context");

		}

		// We listen click on the burger nav button
		$("#burger-nav-button").on("click", this.handleClickOnBurgerNavButton.bind(this));

	}

	handleClickOnBurgerNavButton (e) {

		if ($(e.currentTarget).attr("class").indexOf("active") < 0) {

			// We show the sidebar menu
			this.showSidebarMenu();


		} else {

			// We hide the sidebar menu
			this.hideSidebarMenu();

		}

	}

	showSidebarMenu () {

		const curButtonWidth = $("#burger-nav-button").outerWidth();

		const menuWidth = $("#menu").outerWidth();

		let marginLeftMenu;

		if ($(window).width() > 1320) {

			marginLeftMenu = 20;

		} else {

			marginLeftMenu = 0;

		}

		const newButtonPosition = (menuWidth - curButtonWidth) / 2 + 13 + marginLeftMenu;


		// We change the button position
		$("#burger-nav-button").css("left", newButtonPosition);

		// We add the active class to the button
		$("#burger-nav-button").addClass("active");

		// We scroll to the top of the page
		$("html, body").animate({"scrollTop": 0}, 400);

		// We show the menu
		$("#menu").addClass("active");

		// We change the page style
		$("#page").addClass("inactive");

		// We hide the button back on the preview page
		$("#btn-back-preview-page").addClass("inactive");

		// We hide the select context banner
		$("#main-select-context").addClass("inactive");

		// We hide the top banner
		$("#top-banner").addClass("inactive");

	}

	hideSidebarMenu () {

		// We change the button position
		$("#burger-nav-button").css("left", "13px");

		// We remove the active class to the button
		$("#burger-nav-button").removeClass("active");

		// We hide the menu
		$("#menu").removeClass("active");

		// We change the page style
		$("#page").removeClass("inactive");

		// We show the button back on the preview page
		$("#btn-back-preview-page").removeClass("inactive");

		// We show the select context banner
		$("#main-select-context").removeClass("inactive");

		// We show the top banner
		$("#top-banner").removeClass("inactive");

	}

	clearLocalStorage () {

		// We remove temporary items in the local storage
		const curPathUrl = window.location.pathname;
		if (curPathUrl.indexOf("evaluate") < 0 && curPathUrl.indexOf("/risks/") < 0) {

			localStorage.removeItem("risk-isCurrentRiskNotEvaluated");

		}

		if (curPathUrl.indexOf("/admin/user/edit/") < 0) {

			// We remove user rights saved in the local storage
			localStorage.removeItem("editedUser-currentRights");

		}

	}

	initGlobalEvents () {

		// We get the current url pathname
		const curPathUrl = window.location.pathname;

		// We remove the style of the average context on the body if necessary
		if (curPathUrl.indexOf("/profit-centers/all") < 0 && curPathUrl.indexOf("/legal-entities/all") < 0) {

			$("body").removeClass("average-context");

		}

		// For the cartographies dashboard view
		if (curPathUrl.indexOf("/cartographies/all") >= 0) {

			// We remove the switch menu
			$("#menu .menu-switch").remove();

		}

		// We listen the window resize
		$(window).on("resize", () => {

			// We get the width of the window
			const curWindowWidth = $(window).width();

			if (curWindowWidth <= 1320) {

				if (window.location.pathname.indexOf("preview") < 0) {

					// We show the minified menu
					this.showMinifyMode();

				}

			} else if (window.location.pathname.indexOf("preview") < 0) {

				// We hide the minified menu
				this.hideMinifyMode();

			}

		});

	}

	initNotifications () {

		user.getNotifications().then((userNotifications) => {

			// We get notifications not read
			const notificationsUnread = userNotifications.filter((notification) => notification.read_at === null);

			if (notificationsUnread !== undefined && notificationsUnread.length > 0) {

				// If we are not on the notifications page
				if (window.location.pathname.indexOf("/notifications") < 0) {

					// We add the alert bullet on the notifications button
					$("#menu .user-menu ul li.btn-notifications .alert-icon").addClass("active");

					this.notificationIconAnimationInterval = setInterval(() => {

						// We loop the animation on the bell icon
						$("#menu .user-menu ul li.btn-notifications .icon").removeClass("animate");
						$("#menu .user-menu ul li.btn-notifications .icon").addClass("animate");

					}, 3000);

				}

			} else {

				// We remove the alert bullet on the notifications button
				$("#menu .user-menu ul li.btn-notifications .alert-icon").removeClass("active");

				// We remove the animation on the bell icon
				$("#menu .user-menu ul li.btn-notifications .icon").removeClass("animate");

			}

		});

	}

	initLogoutButton () {

		// If the current env is setup on SSO (and not on basic login)
		if (process.env.BASIC_LOGIN_ACTIVE === false || process.env.BASIC_LOGIN_ACTIVE === "false") {

			// We remove the logout button
			$(".btn-logout").remove();

		}

	}

	initLogo () {

		// We listen click on the main logo
		$("#menu .logo .link-logo").on("click", this.handleClickOnLogo.bind(this));

	}

	initUserProfileButton () {

		// We listen click on the profile button
		$("#menu .user-menu .list-buttons .btn-edit-profile").on("click", this.handleClickOnUserProfileButton.bind(this));

	}

	initEventsSwitchDashboardView () {

		return new Promise((resolve, reject) => {

			// We listen click on the grid button
			$("#menu .menu-switch .switch-view .btn-grid").on("click", this.handleClickOnDashboardGridView.bind(this));

			// We listen click on the list button
			$("#menu .menu-switch .switch-view .btn-list").on("click", this.handleClickOnDashboardListView.bind(this));

			// We listen click on the graph button
			$("#menu .menu-switch .switch-view .btn-graph").on("click", this.handleClickOnDashboardGraphView.bind(this));

			resolve();

		});

	}

	initEventsLanguage () {

		// We init the site translation
		$("html").i18n();

		// We init the lang menu
		$("#lang-menu .current-lang").attr("data-lang", user.data.language);

		if (user.data.language === "fr") {

			$("#lang-menu .current-lang .flag img").attr("src", flagFr);
			$("#lang-menu .other-lang .flag img").attr("src", flagEn);
			$("#lang-menu .other-lang").attr("data-lang", "en");

		} else if (user.data.language === "en") {

			$("#lang-menu .current-lang .flag img").attr("src", flagEn);
			$("#lang-menu .other-lang .flag img").attr("src", flagFr);
			$("#lang-menu .other-lang").attr("data-lang", "fr");

		}

		// We listen click on the other lang
		$("#lang-menu .other-lang").on("click", this.handleClickOnLang.bind(this));

	}

	handleClickOnLogo () {

		// If current user has right on multiple cartographies
		if (cartographies.data !== undefined && cartographies.data.length > 1) {

			// We redirect the user to the dashboard with global cartographies view
			router.navigate(`/dashboard/branches/${context.getCurrentBranchId()}/cartographies/all/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`);

		} else {

			// We handle the homepage redirection
			this.handleHomepageRedirection();

		}

	}

	handleHomepageRedirection () {

		let routeToShow;

		// We show the page with the selected context
		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/profit-centers/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/countries/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else {

			routeToShow = `/dashboard-grid/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	handleClickOnUserProfileButton () {

		// We reload current data user
		user.getData().then(() => {

			// We navigate to the user profile page
			router.navigate("/user/show");

		}).catch(debug.e);

	}

	handleClickOnDashboardGridView () {

		// We update switch button position
		this.clearButtonsSwitchDashboardView();
		$("#menu .menu-switch .switch-view").addClass("active-grid");

		let routeToShow;

		// We show the page with the selected context
		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/profit-centers/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/countries/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else {

			routeToShow = `/dashboard-grid/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/${this.legalEntityId}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	handleClickOnLang (e) {

		// We switch the flag on the lang menu
		const currentLangUrlFlag = $("#lang-menu .current-lang").find(".flag img").attr("src");
		const selectedLangUrlFlag = $(e.currentTarget).find(".flag img").attr("src");
		$("#lang-menu .current-lang").find(".flag img").attr("src", selectedLangUrlFlag);
		$("#lang-menu .other-lang").find(".flag img").attr("src", currentLangUrlFlag);

		// We switch the data-lang on the lang menu
		const currentCodeLang = $("#lang-menu .current-lang").attr("data-lang");
		const selectedCodeLang = $(e.currentTarget).attr("data-lang");
		$("#lang-menu .current-lang").attr("data-lang", selectedCodeLang);
		$("#lang-menu .other-lang").attr("data-lang", currentCodeLang);

		// We save the new language for the current user
		user.update({
			"language": selectedCodeLang
		}).
			then(this.updateUserSuccess.bind(this)).
			catch((error) => {

				debug.e(error);

			});

	}

	updateUserSuccess (data) {

		// We reload the page
		document.location.reload();

		// We force the request to get the new data for the language update
		user.getData({"forceRequest": true}).then(() => {}).catch(debug.e);

	}

	handleClickOnDashboardListView () {

		// We update switch button position
		this.clearButtonsSwitchDashboardView();
		$("#menu .menu-switch .switch-view").addClass("active-list");

		let routeToShow;

		// We show the page with the selected context
		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-list/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-list/branches/${this.branchId}/profit-centers/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			routeToShow = `/dashboard-list/branches/${this.branchId}/countries/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else {

			routeToShow = `/dashboard-list/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/${this.legalEntityId}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
		  routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	handleClickOnDashboardGraphView () {

		// We update switch button position
		this.clearButtonsSwitchDashboardView();
		$("#menu .menu-switch .switch-view").addClass("active-graph");

		let routeToShow;

		// We show the page with the selected context
		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-graph/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-graph/branches/${this.branchId}/profit-centers/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			routeToShow = `/dashboard-graph/branches/${this.branchId}/countries/all/cartographies/${this.cartographyId}/legal-entities/all`;

		} else {

			routeToShow = `/dashboard-graph/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/${this.legalEntityId}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
		  routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	handleUserLoggedIn () {

		if (user.isLoggedIn()) {

			$(".user-menu .user .name .user-firstname").html(user.data.firstname);
			$(".user-menu .user .name .user-lastname").html(user.data.lastname);

			// We set the active menu
			setTimeout(() => {

				this.setActiveMenu();

			}, 50);

			// We recall the translation
			$("html").i18n();

		}

		// After render we make sure the shown links are handled by the router, not thrue the browser
		router.refreshLinks();

	}

	setActiveMenu () {

		const curRoute = document.location.pathname;

		if (
			curRoute === "/" ||
			curRoute === "/home" ||
			curRoute === "/dashboard" ||
			curRoute.indexOf("/dashboard-grid") > -1
		) {

			this.clearButtonsSwitchDashboardView();
			$("#menu .menu-switch .switch-view").addClass("active-grid");


		} else if (curRoute.indexOf("/dashboard-list") > -1) {

			this.clearButtonsSwitchDashboardView();
			$("#menu .menu-switch .switch-view").addClass("active-list");

		} else if (curRoute.indexOf("/dashboard-graph") > -1) {

			this.clearButtonsSwitchDashboardView();
			$("#menu .menu-switch .switch-view").addClass("active-graph");

		} else if (curRoute.indexOf("/catalog") > -1) {

			this.clearButtonsSwitchDashboardView();
			$("#menu .menu-switch .switch-view").addClass("active-catalog");

		} else if (curRoute.indexOf("/user/show") > -1 && curRoute.indexOf("/admin/") < 0) {

			$("#menu .user-menu ul li.btn-edit-profile").addClass("active");

		} else if (curRoute.indexOf("/notifications") > -1) {

			$("#menu .user-menu ul li.btn-notifications").addClass("active");

		}

	}

	clearButtonsSwitchDashboardView () {

		$("#menu .menu-switch .switch-view").removeClass("active-grid");
		$("#menu .menu-switch .switch-view").removeClass("active-list");
		$("#menu .menu-switch .switch-view").removeClass("active-graph");
		$("#menu .menu-switch .switch-view").removeClass("active-catalog");

	}

}
export default new Menu();
