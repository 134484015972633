import user from "Datapoints/User";
import context from "Datapoints/Context.js";
import helpItems from "Datapoints/HelpItems";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import helpItemContainer from "Components/HelpItem/HelpItemContainer.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import html from "./help_menu.html";
import "./HelpMenu.scss";

class HelpMenu {

	init () {

		// We init global vars
		this.initVars();

		// We display the help menu
		this.displayHelpMenu();

		// We init the global events
		this.initEvents();

		// We init the help sequence
		helpSequence.init();

	}

	initVars () {

		// We get the current branch id
		this.branchId = context.getCurrentBranchId();

		// We load the current cartography id
		this.cartographyId = cartographies.getCurrentCartographyId();

		// We load the current legal entity id
		this.legalEntityId = context.getCurrentLegalEntityId();

		// We get the user first login information
		this.isCurrentUserFirstLogin = localStorage.getItem("user-isFirstLogin");

		// We get the help items data
		this.helpItemsData = helpItems.getData();

		// We define the current user help items
		this.userHelpItems = [];

	}

	displayHelpMenu () {

		// For each help items
		this.helpItemsData.forEach((helpItem, index) => {

			// We filter the user data rights with the help item user right
			const userRightsFilteredByHelpItemRight = user.data.rights.filter((right) => right.rights.indexOf(helpItem.user_right) >= 0);

			if (userRightsFilteredByHelpItemRight !== undefined && userRightsFilteredByHelpItemRight.length > 0) {

				let countUserRightValid = 0;
				userRightsFilteredByHelpItemRight.forEach((userRight, index) => {

					// CARTOGRAPHY ONLY
					if (
						userRight.cartography_id !== undefined &&
						userRight.branch_id === undefined &&
						userRight.legal_entity_id === undefined &&
						parseInt(userRight.cartography_id) === parseInt(this.cartographyId)
					) {

						countUserRightValid += 1;

					}
					// BRANCH ONLY
					else if (
						userRight.cartography_id === undefined &&
						userRight.branch_id !== undefined &&
						userRight.legal_entity_id === undefined &&
						parseInt(userRight.branch_id) === parseInt(this.branchId)
					) {

						countUserRightValid += 1;

					}
					// LEGAL ENTITY ONLY
					else if (
						userRight.cartography_id === undefined &&
						userRight.branch_id === undefined &&
						userRight.legal_entity_id !== undefined &&
						parseInt(userRight.legal_entity_id) === parseInt(this.legalEntityId)
					) {

						countUserRightValid += 1;

					}
					// CARTOGRAPHY + BRANCH
					else if (
						userRight.cartography_id !== undefined &&
						userRight.branch_id !== undefined &&
						userRight.legal_entity_id === undefined &&
						parseInt(userRight.cartography_id) === parseInt(this.cartographyId) &&
						parseInt(userRight.branch_id) === parseInt(this.branchId)
					) {

						countUserRightValid += 1;

					}
					// CARTOGRAPHY + LEGAL ENTITY
					else if (
						userRight.cartography_id !== undefined &&
						userRight.branch_id === undefined &&
						userRight.legal_entity_id !== undefined &&
						parseInt(userRight.cartography_id) === parseInt(this.cartographyId) &&
						parseInt(userRight.legal_entity_id) === parseInt(this.legalEntityId)
					) {

						countUserRightValid += 1;

					} else {

						countUserRightValid += 0;

					}

				});

				if (countUserRightValid > 0) {

					this.userHelpItems.push(helpItem);

				}

			}

		});


		if (this.userHelpItems.length > 0) {

			// We show the help menu
			this.showHelpMenu();

			// If this is the first login for the current user
			if (this.isCurrentUserFirstLogin === true || this.isCurrentUserFirstLogin === "true") {

				// We show the active help button menu
				this.showHelpButtonMenu();

				// We show the help sub menu
				this.showHelpSubMenu();

				// We update the first login information on local storage
				localStorage.setItem("user-isFirstLogin", false);

			}

		} else {

			// We hide the help menu
			this.hideHelpMenu();

		}


	}

	showHelpMenu () {

		// We remove the help button on the menu
		$("#menu .main-menu ul .btn-help").remove();

		// We add the help button on the menu
		$("#menu .main-menu ul").append(html);

		// We add the help sub menu div on the page
		$("#help-sub-menu").remove();
		const htmlHelpSubMenu = `
			<div id="help-sub-menu">
				<div class="btn-close">
					<span class="icon fas fa-times"></span>
				</div>
				<div class="title">
					<span class="icon far fa-question-circle"></span>
					<span class="text" data-i18n="title-help-sub-menu">${$.i18n("title-help-sub-menu")}</span>
				</div>
				<ul class="list-items"></ul>
			</div>
		`;
		$(htmlHelpSubMenu).insertAfter("#menu");

		// Exception : if the minify menu is shown but not active
		if ($("#menu").attr("class").indexOf("minify") >= 0 && $("#menu").attr("class").indexOf("active") < 0) {

			// We add a class on the help menu to show it in popin mode
			$("#help-sub-menu").addClass("popin-mode");

		} else {

			// We remove the popin mode class on the help menu
			$("#help-sub-menu").removeClass("popin-mode");

		}

		// We listen click on the close button
		$("#help-sub-menu .btn-close").click((e) => {

	  	// We hide the help button menu
			this.hideHelpButtonMenu();

			// We hide the help sub menu
		  this.hideHelpSubMenu();

		});

		// We define the click outside the sub menu
		$(document).click(() => {

			// We hide the help button menu
			this.hideHelpButtonMenu();

			// We hide the help sub menu
		  this.hideHelpSubMenu();

		});
		$("#menu .main-menu ul .btn-help, #help-sub-menu").click((e) => {

		    e.stopPropagation();

		});

	}

	hideHelpMenu () {

		// We remove the help button on the menu
		$("#menu .main-menu ul .btn-help").remove();

		// We remove the sub menu div
		$("#help-sub-menu").remove();

	}

	initEvents () {

		// We listen click on the help button
		$("#menu .main-menu ul .btn-help").on("click", this.handleClickOnHelpButtonMenu.bind(this));

	}

	handleClickOnHelpButtonMenu (e) {

		if ($(e.currentTarget).attr("class").indexOf("active") < 0 && $("body").attr("class").indexOf("help-mode-active") < 0) {

			// We show the help button menu
			this.showHelpButtonMenu();

			// We show the help sub menu
			this.showHelpSubMenu();

		} else {

			// We hide the help button menu
			this.hideHelpButtonMenu();

			// We hide the help sub menu
			this.hideHelpSubMenu();

		}

	}

	showHelpButtonMenu () {

		// We add the active class on the help button
		$("#menu .main-menu ul .btn-help").addClass("active");

	}

	hideHelpButtonMenu () {

		// We remove the active class on the help button
		$("#menu .main-menu ul .btn-help").removeClass("active");

	}

	showHelpSubMenu () {

		// We get the top position of the help button
		const buttonHelpPositionTop = $(".sidebar-menu .main-menu ul li.btn-help").offset().top;

		// We define the top position of the help sub menu
		$("#help-sub-menu").css("top", buttonHelpPositionTop - 50);

		// We remove all the help items on the list
		$("#help-sub-menu ul.list-items").html("");

		// We show help items
		helpItemContainer.load(this.userHelpItems);

		// We show the sub menu
		$("#help-sub-menu").show().addClass("active");

		// We change the opacity of the main content
		$("#page > *:not(#help-sub-menu,#main-select-context)").css("opacity", 0.2);

	}

	hideHelpSubMenu () {

		// We remove the clas of the popin mode
		$("#help-sub-menu").removeClass("popin-mode");

		// We hide the sub menu
		$("#help-sub-menu").removeClass("active").hide();

		// We change the opacity of the main content
		$("#page > *:not(#help-sub-menu,#main-select-context)").css("opacity", 1);

	}

}
export default new HelpMenu();
