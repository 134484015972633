import html from "./cartography_consequence_field.html";
import {slugify} from "Core/helpers.js";

class CartographyConsequenceField {

	init (containerSelector, currentLang) {

		// We define the current language
		this.currentLang = currentLang;

		// We add the component html to the container
		$(containerSelector).append(html);

		// We define the consequencies
		this.dataCartographyConsequencies = [];

		// We load the icons
		this.loadCartographyConsequenciesIcons();

		// We init the global events
		this.initGlobalEvents();

		// We init the events for icon button
		this.initIconButtonEvents();

	}

	initGlobalEvents () {

		// We listen the clic on the delete consequence button
		$("#section_cartography_consequencies .tab-result .label-result .btn-delete-consequence").on("click", this.handleClickOnDeleteConsequenceButton.bind(this));

		// We listen change on the consequence fields
		$("#section_cartography_consequencies .text-field").on("input", this.handleChangeOnConsequenceFields.bind(this));
		$("#section_cartography_consequencies .radio-field").on("change", this.handleChangeOnConsequenceFields.bind(this));

		// We listen click on the add consequence button
		$("#btn-add-consequence").on("click", this.handleClickOnAddConsequenceButton.bind(this));

	}

	initIconButtonEvents () {

		// We listen the clic on the show icons button
		$("#btn-show-consequencies-icons").on("click", this.handleClickOnShowConsequenciesIconsButton.bind(this));

	}

	handleClickOnAddConsequenceButton (e) {

		if (!$("#btn-add-consequence").hasClass("inactive")) {

			// We get new consequence values
			const consequenceValueFr = $("#section_cartography_consequencies input[name='consequence_fr']").val();
			const consequenceValueEn = $("#section_cartography_consequencies input[name='consequence_en']").val();
			const consequenceSlug = slugify(consequenceValueEn);
			const consequenceValueIcon = $("#section_cartography_consequencies input[name='icon']:checked").val();

			// We add the consequence on data cartography consequencies
			this.dataCartographyConsequencies.push({
				"lib": {
					"en": consequenceValueEn,
					"fr": consequenceValueFr
				},
				"slug": consequenceSlug,
				"icon": consequenceValueIcon
			});

			// We get the new consequence lib in the current language
			const consequenceLibCurrentLang = $(`#section_cartography_consequencies input[name='consequence_${this.currentLang}']`).val();

			// We get the consequence lib in the other language
			let consequenceLibOtherLang;
			if (this.currentLang === "fr") {

				consequenceLibOtherLang = $("#section_cartography_consequencies input[name='consequence_en']").val();

			} else if (this.currentLang === "en") {

				consequenceLibOtherLang = $("#section_cartography_consequencies input[name='consequence_fr']").val();

			}

			// We define the html of the label of consequence
			const htmlLabelConsequence = `<div class="label-result" data-index-cartography-consequence="${this.dataCartographyConsequencies.length - 1}"><div class="info-context"><div class="name-context"><span class="icon ${consequenceValueIcon}"></span><span class="text">${consequenceLibCurrentLang}</span></div><div class="name-sub-context consequence-other-lang">${consequenceLibOtherLang}</div></div><div class="other-info"><div class="btn-delete-consequence"><span class="icon fas fa-trash-alt"></span></div></div></div>`;

			// We show the label of the new consequence
			$("#section_cartography_consequencies .tab-result").prepend(htmlLabelConsequence);

			// We init the events
			this.initGlobalEvents();

			// We clear the add consequence form
			this.clearAddConsequenceForm();

			// We remove the consequence field error
			$("#cartography_consequencies .tab-result").removeClass("tab-result-error");
			$("#cartography_consequencies .msg-error").remove();

		}


	}

	clearAddConsequenceForm () {

		// We clear the fields
		$("#section_cartography_consequencies input[name='consequence_fr']").val("");
		$("#section_cartography_consequencies input[name='consequence_en']").val("");
		document.querySelector("input[name=\"icon\"]:checked").checked = false;

		// We clear the add consequence button
		$("#btn-add-consequence").addClass("inactive");

	}

	handleChangeOnConsequenceFields (e) {

		if ($("#section_cartography_consequencies input[name='consequence_fr']").val() !== "" && $("#section_cartography_consequencies input[name='consequence_en']").val() !== "" && $("#section_cartography_consequencies input[name='icon']:checked").val() !== undefined) {

			// We active the add button
			$("#btn-add-consequence").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-consequence").addClass("inactive");

		}


	}

	handleClickOnDeleteConsequenceButton (e) {

		// We get the index of the current consequence
		const curIndexConsequence = $(e.currentTarget).parents(".label-result").attr("data-index-cartography-consequence");

		// We delete the consequence in the array of list of consequencies
		this.dataCartographyConsequencies.splice(curIndexConsequence, 1);

		// We delete the label of the consequence
		$(e.currentTarget).parents(".label-result").remove();

	}

	autoFillCartographyConsequencies (editedCartography) {

		if (editedCartography != undefined && editedCartography.other_potential_risks !== undefined && editedCartography.other_potential_risks !== null && editedCartography.other_potential_risks.length > 0) {

			// We define cartography consequencies
			this.dataCartographyConsequencies = editedCartography.other_potential_risks;

			editedCartography.other_potential_risks.forEach((consequence, index) => {

				// We get the consequence lib in the current language
				const consequenceLibCurrentLang = consequence.lib[`${this.currentLang}`];

				// We get the consequence lib in the other language
				let consequenceLibOtherLang;
				if (this.currentLang === "fr") {

					consequenceLibOtherLang = consequence.lib.en;

				} else if (this.currentLang === "en") {

					consequenceLibOtherLang = consequence.lib.fr;

				}

				// We define the html of the label of consequence
				const htmlLabelConsequence = `
					<div class="label-result" data-index-cartography-consequence="${index}">
						<div class="info-context">
							<div class="name-context">
								<span class="icon ${consequence.icon}"></span>
								<span class="text">${consequenceLibCurrentLang}</span>
							</div>
						<div class="name-sub-context consequence-other-lang">${consequenceLibOtherLang}</div>
					</div>
					<div class="other-info">
						<div class="btn-delete-consequence">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>`;

		    // We show the label of consequence
				$("#section_cartography_consequencies .tab-result").prepend(htmlLabelConsequence);

				// We init the events
				this.initGlobalEvents();

			});

		}

	}

	loadCartographyConsequenciesIcons () {

		// We define all the icon class
		const iconsClass = [
			"address-book",
			"ban",
			"puzzle-piece",
			"times-circle",
			"address-card",
			"database",
			"business-time",
			"ellipsis-h",
			"ellipsis-v",
			"hand-holding-usd",
			"microchip",
			"chart-pie",
			"search-dollar",
			"hand-paper",
			"signal-slash",
			"store-slash",
			"file-certificate",
			"users-slash",
			"user-tag",
			"user-shield",
			"user-slash",
			"user-tie",
			"user-times",
			"user-lock",
			"user-check",
			"user-clock",
			"user-cog",
			"adjust",
			"ambulance",
			"anchor",
			"archway",
			"assistive-listening-systems",
			"asterisk",
			"at",
			"atlas",
			"atom",
			"award",
			"bahai",
			"balance-scale",
			"barcode",
			"bell",
			"bell-slash",
			"bible",
			"bomb",
			"book-medical",
			"boxes",
			"brain",
			"briefcase",
			"briefcase-medical",
			"broadcast-tower",
			"calculator",
			"building",
			"bullhorn",
			"bullseye",
			"burn",
			"bus",
			"brush",
			"camera-retro",
			"campground",
			"capsules",
			"cart-arrow-down",
			"cart-plus",
			"certificate",
			"chalkboard-teacher",
			"charging-station",
			"chart-area",
			"chart-bar",
			"chart-line",
			"chart-pie",
			"check",
			"check-circle",
			"church",
			"city",
			"clinic-medical",
			"clipboard",
			"clipboard-check",
			"clipboard-list",
			"clock",
			"circle",
			"clone",
			"cloud",
			"cloud-download-alt",
			"coins",
			"cogs",
			"cog",
			"coffee",
			"code",
			"code-branch",
			"comment-dots",
			"comment-slash",
			"copy",
			"concierge-bell",
			"compress-arrows-alt",
			"compress-alt",
			"compress",
			"compass",
			"copyright",
			"credit-card",
			"crosshairs",
			"deaf",
			"cubes",
			"crown",
			"desktop",
			"dna",
			"dolly-flatbed",
			"donate",
			"download",
			"door-closed",
			"external-link-alt",
			"exchange-alt",
			"exclamation",
			"envelope-open-text",
			"euro-sign",
			"eye",
			"file-alt",
			"file-upload",
			"file-invoice-dollar",
			"file-export",
			"file-image",
			"fingerprint",
			"fire-alt",
			"first-aid",
			"folder",
			"folder-minus",
			"fist-raised",
			"flag",
			"flag-usa",
			"flask",
			"globe",
			"gift",
			"hand-holding-heart",
			"hand-holding",
			"hand-holding-medical",
			"hands",
			"hands-helping",
			"handshake",
			"handshake-alt-slash",
			"hashtag",
			"hdd",
			"id-card-alt",
			"id-card",
			"house-damage",
			"hospital-user",
			"home",
			"hourglass-half",
			"laptop-house",
			"laptop-medical",
			"list",
			"lock",
			"lock-open",
			"location-arrow",
			"link",
			"unlink",
			"layer-group",
			"leaf",
			"mail-bulk",
			"low-vision",
			"map-marked-alt",
			"medal",
			"microphone",
			"paperclip",
			"paper-plane",
			"percent",
			"plane-slash",
			"plane-departure",
			"plus-circle",
			"question-circle",
			"retweet",
			"share",
			"share-alt",
			"shield-alt",
			"sim-card",
			"sign-in-alt",
			"sign-out-alt",
			"shopping-cart",
			"tachometer-alt",
			"tag",
			"temperature-low",
			"tasks",
			"tablet-alt",
			"suitcase",
			"thumbtack",
			"times-circle",
			"tint",
			"tint-slash",
			"tree",
			"tv",
			"unlock-alt",
			"upload",
			"volume-up",
			"volume-down",
			"volume-mute",
			"wifi",
			"virus-slash",
			"water",
			"video",
			"video-slash",
			"umbrella"
		];

		$.each(iconsClass, (index, icon) => {

			// We define html item radio icon
			const htmlItemRadioIcon = `<div class="item-radio">
                <input id="cartography_consequence_icon_${index}" class="radio-field" type="radio" name="icon" value="fas fa-${icon}">
                <label for="cartography_consequence_icon_${index}">
                  <i class="icon fas fa-${icon}"></i>
                </label>
              </div>`;

			// We show the item
			$("#section_cartography_consequencies .line-radio-icon .line-items").append(htmlItemRadioIcon);

		});

	}

	handleClickOnShowConsequenciesIconsButton () {

		if (!$("#btn-show-consequencies-icons").hasClass("active")) {

			// We show the icons
			$("#section_cartography_consequencies .line-radio-icon .line-items").addClass("active");

			// We add the active class on the button
			$("#btn-show-consequencies-icons").addClass("active");

			// We change the direction of the arrow on the button
			$("#btn-show-consequencies-icons .icon").removeClass("fa-angle-down");
			$("#btn-show-consequencies-icons .icon").addClass("fa-angle-up");

		} else {

			// We hide the icons
			$("#section_cartography_consequencies .line-radio-icon .line-items").removeClass("active");

			// We remove the active class on the button
			$("#btn-show-consequencies-icons").removeClass("active");

			// We change the direction of the arrow on the button
			$("#btn-show-consequencies-icons .icon").removeClass("fa-angle-up");
			$("#btn-show-consequencies-icons .icon").addClass("fa-angle-down");

		}

	}

	getCartographyConsequencies () {

		return this.dataCartographyConsequencies;

	}

}
export default new CartographyConsequenceField();
