import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./edit.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import actions from "Datapoints/Actions.js";
import actionsPlan from "Datapoints/ActionsPlan.js";
import cartographies from "Datapoints/Cartographies.js";
import users from "Datapoints/Users.js";
import legalEntities from "Datapoints/LegalEntities.js";
import user from "Datapoints/User.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import {showFormErrors, updateObjectWithForm, getUrlVars} from "Core/helpers.js";
// Import Datepicker
import "Core/assets/lib/datepicker.min.css";
import "Core/assets/lib/datepicker.min.js";
import "Core/assets/lib/datepicker.fr-FR.js";


class ActionPlanEdit extends Page {

	constructor () {

		let routes = ["/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/actions/:idAction/actions-plan/edit"];

		super(routes, AuthentifiedLayout, "Modification de l'état d'une action", [
			users,
			risks,
			actions,
			actionsPlan,
			legalEntities,
			cartographies
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEditThisActionPlan.bind(this)
		];

	}

	canCurrentUserEditThisActionPlan () {

		return new Promise((resolve, reject) => {

			if (user.canManageActionPlans()) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		this.urlVars = getUrlVars();

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		menu.show();

		this.params = params;

		this.parseAndRender("page", html);

		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We get the previous page saved
		this.previousPage = localStorage.getItem("navigation-previousPageUrl");

		// We define the form type
		this.formType = "toto";

		// We define the id of the current cartography
		this.idCartography = params.idCartography;

		// We define the id of the current legal entity
		this.idLegalEntity = params.idLegalEntity;

		// We get the legal entity data
		this.legalEntityData = legalEntities.find(this.idLegalEntity);

		// We define the id of the current risk
		this.idRisk = params.idRisk;

		// We define the id of the current action
		this.idAction = params.idAction;

		// We define the data of the current action
		this.dataAction = actions.find(this.idAction);

		// We define the form data
		this.editedActionPlan = {};

		// We initialize the page events
		this.initEvents();

		// We add translate buttons
		this.addTranslateButtons();

	}

	initEvents () {

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			// We update the title of the page
			$("html").find("title").html(`${$.i18n("action-plan-edit-title-page")}`);

			if (actionsPlan.data !== undefined) {

				// We get the data of the action plan
				const filteredActionPlan = actionsPlan.data.actionsPlan.filter((action) => parseInt(action.action_id) === parseInt(this.idAction));

				if (filteredActionPlan !== undefined && filteredActionPlan.length > 0) {

					// We get the existing action plan data
					this.editedActionPlan = filteredActionPlan[filteredActionPlan.length - 1];

					// We display the date field
					this.displayDateField(this.editedActionPlan.status);

					// We fill the form fields
					this.autoFillPage(this.editedActionPlan);
					this.autoFillForm("#edit_action_plan", this.editedActionPlan);

					// We show the todo date
					if (
						this.editedActionPlan.status === "todo" &&
						this.editedActionPlan.should_be_done_at !== undefined &&
						this.editedActionPlan.should_be_done_at !== null &&
						this.editedActionPlan.should_be_done_at !== ""
					) {

						$("#edit_action_plan [name='should_be_done_at']").attr("value", this.editedActionPlan.should_be_done_at.replace(" ", "").replace("00:00:00", "").split("-").reverse().join("/"));

					}

					// We show the done date
					if (this.editedActionPlan.status === "done") {

						$("#edit_action_plan [name='done_at']").attr("value", this.editedActionPlan.done_at.replace(" ", "").replace("00:00:00", "").split("-").reverse().join("/"));

					}

					// We show the last modification informations
					this.showLastModificationInfos(this.editedActionPlan);

				}

			}

			// We show the action title
			this.showActionTitle();

			// We show the legal entity title
			this.showLegalEntityTitle();

			// We init the datepicker
			this.initDatepicker();

			// We listen change on status select
			$("FORM select[name=status]").on("change", this.handleChangeStatusSelect.bind(this));

			// We initialize the rich text editor
			this.initTextEditor();

			// We listen click on cancel button
			$("#edit_action_plan .btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

		}

	}

	handleClickOnCancelButton () {

		// We redirect to the previous page
		if (this.previousPage !== undefined && this.previousPage.indexOf("/risks/") >= 0) {

			router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all#actions`);


		} else {

			router.navigate(this.previousPage);

		}

	}

	showLastModificationInfos (data) {

		// We get the author id
		const authorId = data.last_modification_author_id;

		// We get the author standard name
		const authorStandardName = data.last_modification_author_standard_name;

		// We get the author email if the user can see him
		let authorEmail;
		if (users.find(authorId) != null) {

			authorEmail = users.find(authorId).email;

		}

		// We get the html of the author name
		let htmlAuthorName = "";
		if (authorStandardName !== undefined) {

			if (authorEmail !== undefined) {

				htmlAuthorName = `
				<div class="tag-link evaluator-name">
					<a href="mailto:${authorEmail}">
						<span class="icon far fa-user"></span>
						<span class="text">${authorStandardName}</span>
					</a>
				</div>
			`;

			} else {

				htmlAuthorName = `
				<div class="tag-link evaluator-name">
					<div class="link">
						<span class="icon far fa-user"></span>
						<span class="text">${authorStandardName}</span>
					</div>
				</div>
			`;

			}

		}

		// We get the last modification date
		const curLastUpdateSeconds = `0${new Date(data.updated_at).getSeconds()}`.slice(-2);
		const curLastUpdateMinutes = `0${new Date(data.updated_at).getMinutes()}`.slice(-2);
		const curLastUpdateHours = `0${new Date(data.updated_at).getHours() + 1}`.slice(-2);
		const curLastUpdateFullTime = `${curLastUpdateHours}:${curLastUpdateMinutes}:${curLastUpdateSeconds}`;
		const curLastUpdateDay = `0${new Date(data.updated_at).getDate()}`.slice(-2);
		const curLastUpdateMonth = `0${new Date(data.updated_at).getMonth() + 1}`.slice(-2);
		const curLastUpdateYear = new Date(data.updated_at).getFullYear();
		const curLastUpdateFullDate = `${curLastUpdateDay}.${curLastUpdateMonth}.${curLastUpdateYear}`;

		// We get the html of last modification time
		let htmlLastUpdateTime = "";
		if (curLastUpdateFullTime !== undefined) {

			htmlLastUpdateTime = `
				<div class="tag">
					<span class="icon far fa-clock"></span>
					<span class="text">${curLastUpdateFullTime}</span>
				</div>
			`;

		}

		// We get the html of last modification date
		let htmlLastUpdateDate = "";
		if (curLastUpdateFullDate !== undefined) {

			htmlLastUpdateDate = `
				<div class="tag date-last-evaluation">
					<span class="icon far fa-calendar-alt"></span>
					<span class="text">${curLastUpdateFullDate}</span>
				</div>
			`;

		}

		// We define the html
		const htmlLineLastUpdateInfos = `
			<div class="line-last-update-infos">
				<div class="title" data-i18n="title-last-update-infos">${$.i18n("title-last-update-infos")}</div>
				<div class="infos">${htmlAuthorName}${htmlLastUpdateDate}${htmlLastUpdateTime}</div>
			</div>
		`;

		// We show the informations line
		$("#edit_action_plan").prepend(htmlLineLastUpdateInfos);

	}

	showActionTitle () {

		if (this.dataAction !== undefined) {

			$(".title-page .action-title").html(this.dataAction[`title_${this.currentLang}`]);

		}

	}

	showLegalEntityTitle () {

		if (this.legalEntityData !== undefined && this.legalEntityData !== null) {

			$(".title-page .legal-entity-title").html(this.legalEntityData.name);

		}

	}

	initDatepicker () {

		$("[data-toggle=\"datepicker\"]").datepicker({
			"language": "fr-FR",
			"format": "dd/mm/yyyy",
			"autoHide": true
		});

	}

	handleChangeStatusSelect (e) {

		// We get the selected status
		const selectedStatus = $("FORM select[name=status]").val();

		// We display the date field
		this.displayDateField(selectedStatus);

		// We display the help message
		this.displayStatusHelpMessage(selectedStatus);

	}

	displayDateField (status) {

		// We clear the field
		$(".date-field .box-datepicker .text-field").attr("value", "");

		if (status === "todo") {

			// We show the date field
			$(".date-field").removeClass("inactive");

			// We hide the mandatory label
			$(".date-field .label .mandatory").addClass("inactive");

			// We update the name attibute of the date field
			$(".date-field .box-datepicker .text-field").attr("name", "should_be_done_at");

			// We update the form type
			this.formType = "todo";

		} else if (status === "done") {

			// We show the date field
			$(".date-field").removeClass("inactive");

			// We show the mandatory label
			$(".date-field .label .mandatory").removeClass("inactive");

			// We update the name attibute of the date field
			$(".date-field .box-datepicker .text-field").attr("name", "done_at");

			// We update the form type
			this.formType = "done";

		} else if (status === "ignored") {

			// We hide the date field
			$(".date-field").addClass("inactive");

			// We update the name attibute of the date field
			$(".date-field .box-datepicker .text-field").attr("name", "should_be_done_at");

		}

	}

	displayStatusHelpMessage (status) {

		if (status === "ignored" && this.dataAction.status === "deactivated") {

			// We define the html of the author
			let htmlAuthor;

			if (
				users.find(this.dataAction.author_id) !== undefined &&
				users.find(this.dataAction.author_id) !== null &&
				users.find(this.dataAction.author_id).email !== undefined &&
				users.find(this.dataAction.author_id).email !== null
			) {

				htmlAuthor = `<a href="mailto:${users.find(this.dataAction.author_id).email}">${this.dataAction.author_standard_name}</a>`;

			} else {

				htmlAuthor = `<span class="highlight">${this.dataAction.author_standard_name}</span>`;

			}

			// We define the message to show
			const htmlHelpMessage = `<div class="msg-help"><div class="icon fas fa-exclamation-triangle"></div><div class="text">${$.i18n("text-message-action-deactivated-status-ignored-part-1")} ${htmlAuthor}${$.i18n("text-message-action-deactivated-status-ignored-part-2")}</div></div>`;

			// We show a message after the select
			$("FORM [name=status]").parents("li").find(".msg-help").remove();
			$("FORM [name=status]").parents("li").append(htmlHelpMessage);

		} else {

			// We remove the message on the status field
			$("FORM [name=status]").parents("li").find(".msg-help").remove();

		}

	}

	initTextEditor () {

		// Content Editor FR
		ClassicEditor.
	    create(document.querySelector("#comment-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorCommentFr = editor;

	      if (
	        this.editedActionPlan !== undefined &&
	      	this.editedActionPlan.comment_fr !== undefined &&
	      	this.editedActionPlan.comment_fr !== null
	      ) {

					editor.setData(this.editedActionPlan.comment_fr);

				}

				// Bug correction : we remove the second ck-editor element
				$("#comment-editor-fr").parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

		// Content Editor EN
		ClassicEditor.
	    create(document.querySelector("#comment-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorCommentEn = editor;

	      if (
	        this.editedActionPlan !== undefined &&
	      	this.editedActionPlan.comment_en !== undefined &&
	      	this.editedActionPlan.comment_en !== null
	      ) {

					editor.setData(this.editedActionPlan.comment_en);

				}

				// Bug correction : we remove the second ck-editor element
				$("#comment-editor-en").parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	addTranslateButtons () {

		setTimeout(() => {

			// Comment FR
			const translationButtonForCommentFr = new FieldTranslationButton("comment_fr", "textarea", "fr", this.richTextEditorCommentFr, this.richTextEditorCommentEn);

			// Comment EN
			const translationButtonForCommentEn = new FieldTranslationButton("comment_en", "textarea", "en", this.richTextEditorCommentFr, this.richTextEditorCommentEn);

		}, 100);

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("edit_action_plan");

		// We get the status value
		const statusValue = $("FORM#edit_action_plan select[name='status']").val();

		// We get the comment FR value
		const commentFrValue = this.richTextEditorCommentFr.getData();

		// We get the comment EN value
		const commentEnValue = this.richTextEditorCommentEn.getData();

		// We get the recurrence value
		let recurrenceValue = 0;
		if (
		  $("FORM#edit_action_plan input[name='recurrence_in_days']").val() !== undefined &&
			$("FORM#edit_action_plan input[name='recurrence_in_days']").val() !== ""
		) {

			recurrenceValue = parseInt($("FORM#edit_action_plan input[name='recurrence_in_days']").val());

		}

		// We get the dates values
		const dateShouldBeDoneAtValue = `${$("FORM#edit_action_plan INPUT[name=\"should_be_done_at\"]").val()}`.split("/").reverse().join("-");

		const dateDoneAtValue = `${$("FORM#edit_action_plan INPUT[name=\"done_at\"]").val()}`.split("/").reverse().join("-");

		this.editedActionPlan.cartography_id = parseInt(this.idCartography);
		this.editedActionPlan.legal_entity_id = parseInt(this.idLegalEntity);
		this.editedActionPlan.actionsPlan = [
			{
				"action_id": this.idAction,
				"status": statusValue,
				"recurrence_in_days": recurrenceValue
			}
		];

		if (dateShouldBeDoneAtValue !== undefined && dateShouldBeDoneAtValue !== "undefined" && dateShouldBeDoneAtValue !== "") {

			this.editedActionPlan.actionsPlan[0].should_be_done_at = dateShouldBeDoneAtValue;

		}

		if (dateDoneAtValue !== undefined && dateDoneAtValue !== "undefined" && dateDoneAtValue !== "") {

			this.editedActionPlan.actionsPlan[0].done_at = dateDoneAtValue;

		}

		if (commentFrValue !== undefined && commentFrValue !== "") {

			this.editedActionPlan.actionsPlan[0].comment_fr = commentFrValue;

		}

		if (commentEnValue !== undefined && commentEnValue !== "") {

			this.editedActionPlan.actionsPlan[0].comment_en = commentEnValue;

		}

		actionsPlan.updateOne(this.editedActionPlan).then(this.editSuccess.bind(this), this.editFail.bind(this));

	}

	editSuccess (response) {

		// We update the menu
		menu.update();

		// We redirect to the previous page
		if (this.previousPage !== undefined && this.previousPage.indexOf("/risks/") >= 0) {

			router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all#actions`);


		} else {

			router.navigate(this.previousPage);

		}

	}

	editFail (response, textStatus) {

		debug.v("ActionPlanEdit - editFail");

		if (response.status == 422) {

			let errorsToShow = {
				"status": {
					"required": `${$.i18n("form-error-status-required")}`
				},
				"actionsPlan[0][done_at]": {
					"required_with_status_done": `${$.i18n("form-error-date-todo-required")}`
				}
			};

			if (response !== undefined && response.responseText !== undefined && response.responseText !== null && response.responseText.indexOf("required_with_status_done") >= 0) {

				$("input[name='done_at']").after(`<div class="error msg-error">${$.i18n("form-error-date-todo-required")}</div>`);

			}

			showFormErrors("#edit_action_plan", response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(".error").eq(0).parents("li").offset().top;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new ActionPlanEdit();
