import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./create.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import user from "Datapoints/User.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import AutoCompletionButton from "Components/AutoCompletionButton/AutoCompletionButton.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";

class RiskCreate extends Page {

	constructor () {

		let routes = [
			"/cartographies/:idCartography/risk/create",
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risk/create"
		]; // We are using risk (singular) instead of risks (plural) so this route is not mistaken with /cartographies/:idCartography/risks/:idRisk

		super(routes, AuthentifiedLayout, "Nouveau risque", [
			users,
			cartographies
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminRisks.bind(user)
		];

	}

	render (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		menu.show();

		this.parseAndRender("page", html);

		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We define the id of the current cartography
		this.idCartography = params.idCartography;

		// We define the current cartography data
		this.cartographyData = cartographies.find(this.idCartography);

		// We define the risk editors
		this.dataRiskEditors = [];

		// We define the users list for editors
		this.usersListForEditors = users.data;

		// We initialize the page title
		this.initPageTitle();

		// We load the possible RPOs
		this.addRPOOptions();

		// We initialize the theme list
		this.initThemes();

		// We initialize the editors
		this.initEditors();

		// We initialize the others risk list
		this.initOtherPotentialRisk();

		// We initialize the rich text editor
		this.initTextEditor();

		// We initialize information messages
		this.initInformationMessages();

		// We add translate buttons
		this.addTranslateButtons();

		// We add auto completion buttons
		if (process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === true || process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === "true") {

			this.addAutoCompletionButtons();

		}

	}

	initPageTitle () {

		// We get the current cartography name
		const curCartographyName = cartographies.find(this.idCartography)[`name_${this.currentLang}`];

		// We show the current cartography name on the page title
		$(".title-page h2").html(curCartographyName);

	}

	initTextEditor () {

		// Description Editor FR
		ClassicEditor.
	    create(document.querySelector("#description-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editorFr) => {

	      this.richTextEditorDescriptionFr = editorFr;


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	  // Description Editor EN
		ClassicEditor.
	    create(document.querySelector("#description-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editorEn) => {

	      this.richTextEditorDescriptionEn = editorEn;


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	addTranslateButtons () {

		// Title FR
		const translationButtonForTitleFr = new FieldTranslationButton("title_fr", "text", "fr");

		// Title EN
		const translationButtonForTitleEn = new FieldTranslationButton("title_en", "text", "en");

		setTimeout(() => {

			// Description FR
			const translationButtonForDescriptionFr = new FieldTranslationButton("description_fr", "textarea", "fr", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

			// Description EN
			const translationButtonForDescriptionEn = new FieldTranslationButton("description_en", "textarea", "en", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

		}, 100);

	}

	addAutoCompletionButtons () {

		setTimeout(() => {

			// Description FR
			const autoCompletionForDescriptionFr = new AutoCompletionButton("title_fr", "description_fr", this.richTextEditorDescriptionFr, this.cartographyData);

			// Description EN
			const autoCompletionForDescriptionEn = new AutoCompletionButton("title_en", "description_en", this.richTextEditorDescriptionEn, this.cartographyData);

		}, 100);

	}

	initThemes () {

		let themesOptions = "<option></option>";
		$(this.cartographyData.themes).each((i, theme) => {

			let themeLib = theme.lib[this.currentLang];
			themesOptions += `<option value=${theme.slug}>${themeLib}</option>`;

		});

		$("select[name='theme']").html(themesOptions);

	}

	initEditors () {

		// We define editors selector
		this.setEditorsSelector();

		// We listen click on the delete editor button
		$("#section_risk_editors .tab-result .label-result .btn-delete-editor").on("click", this.handleClickOnDeleteEditorButton.bind(this));

		// We listen change on the editor select
		$("#section_risk_editors select").on("change", this.handleChangeOnEditorSelect.bind(this));

		// We listen click on the add editor button
		$("#btn-add-editor").on("click", this.handleClickOnAddEditorButton.bind(this));

	}

	setEditorsSelector () {

		let editorsOptions = "<option></option>";
		$(this.usersListForEditors).each((i, curUser) => {

			let editorId = curUser.id;
			let editorName = curUser.standard_name;

			editorsOptions += `<option value=${editorId}>${editorName}</option>`;

		});

		$("select[name='editor_id']").html(editorsOptions);

	}

	handleClickOnDeleteEditorButton (e) {

		// We get the current editor id
		const curEditorId = parseInt($(e.currentTarget).parents(".label-result").attr("data-editor-id"));

		// We get the current editor data
		const curEditorData = users.find(curEditorId);

		// We get the index of the current editor
		const curIndexEditor = parseInt($(e.currentTarget).parents(".label-result").attr("data-index-risk-editor"));

		// We add the editor in the users list for editors
		this.usersListForEditors.splice(curIndexEditor, 0, curEditorData);

		// We update editors selector
		this.setEditorsSelector();

		// We delete the editor in the array of list of editors
		this.dataRiskEditors = this.dataRiskEditors.filter((idEditor) => idEditor !== curEditorId);

		// We delete the label of the editor
		$(e.currentTarget).parents(".label-result").remove();

	}

	handleChangeOnEditorSelect (e) {

		if ($("#section_risk_editors select[name='editor_id']").val() !== "" && $("#section_risk_editors select[name='editor_id']").val() !== "") {

			// We active the add button
			$("#btn-add-editor").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-editor").addClass("inactive");

		}

	}

	handleClickOnAddEditorButton () {

		if (!$("#btn-add-editor").hasClass("inactive")) {

			// We get new editor id
			const newEditorId = parseInt($("#section_risk_editors select[name='editor_id']").val());

			// We update the users list for editors
			this.usersListForEditors = this.usersListForEditors.filter((user) => parseInt(user.id) !== newEditorId);

			// We update editors selector
			this.setEditorsSelector();

			// We get the new editor data
			const newEditorData = users.find(parseInt(newEditorId));

			// We get the new editor fullname
			const newEditorFullname = newEditorData.standard_name;

			// We add the editor on the editors array
			this.dataRiskEditors.push(newEditorId);

			// We get the index of current editor in the users list
			const editorIndex = users.data.findIndex((user) => user.id === newEditorId);

			// We define the html of the new editor label
			const htmlLabelEditor = `
				<div class="label-result" data-editor-id="${newEditorId}" data-index-risk-editor="${editorIndex}">
					<div class="info-context">
						<span class="icon fas fa-user"></span>
						<span class="name-context">${newEditorFullname}</span>
					</div>
					<div class="other-info">
						<div class="btn-delete-editor">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
			`;

			// We show the label of the new editor
			$("#section_risk_editors .tab-result").prepend(htmlLabelEditor);

			// We listen the clic on the delete editor button
			$(`#section_risk_editors .tab-result .label-result[data-editor-id='${newEditorId}'] .btn-delete-editor`).on("click", this.handleClickOnDeleteEditorButton.bind(this));

			// We clear the add editor form
			this.clearAddEditorForm();

		}

	}

	clearAddEditorForm () {

		// We clear the fields
		$("#section_risk_editors select[name='editor_id']").val("");

		// We clear the add editor button
		$("#btn-add-editor").addClass("inactive");

	}

	initOtherPotentialRisk () {

		let otherPotentialRiskHTML = "";
		$(this.cartographyData.other_potential_risks).each((i, opr) => {

			otherPotentialRiskHTML += `
				<div class="line-checkbox">
					<div class="item-checkbox">
            <input id="consequences[${opr.slug}]" type="checkbox" name="consequences[${opr.slug}]" value="1" />
						<label for="consequences[${opr.slug}]">
              <i class="icon ${opr.icon}"></i>
              <span class="text">${opr.lib[this.currentLang]}</span>
            </label>
					</div>
        </div>`;

		});

		$(".box-consequencies").append(otherPotentialRiskHTML);

	}

	initInformationMessages () {

		// If the user can admin risks (admin carto)
		user.canAdminRisks({"idCartography": this.idCartography}).then((result) => {

			let adminRisksInformationMessage = "";

			if (result) {

				if (
					this.cartographyData.themes === undefined ||
					this.cartographyData.themes === null ||
					this.cartographyData.themes.length < 1
				) {

					adminRisksInformationMessage += `<div class='message'><span class=\"icon fas fa-info-circle\"></span>${$.i18n("info-message-no-theme")}</div>`;

				}

				if (
					this.cartographyData.other_potential_risks === undefined ||
					this.cartographyData.other_potential_risks === null ||
					this.cartographyData.other_potential_risks.length < 1
				) {

					adminRisksInformationMessage += `<div class='message'><span class=\"icon fas fa-info-circle\"></span>${$.i18n("info-message-no-consequence")}</div>`;

				}

			}

			// If we have a message to show
			if (adminRisksInformationMessage !== undefined && adminRisksInformationMessage !== "") {

				const htmlInformationMessage = `<div class='line-info-message'>${adminRisksInformationMessage}</div>`;

				// We hide the form
				$(".form-admin form").remove();

				// We show the message
				$(".form-admin").prepend(htmlInformationMessage);

			}

			// We listen the click on the link message
			$(".line-info-message .link-message").on("click", () => {

				router.navigate(`/admin/cartography/edit/${this.idCartography}`);

			});

		}).catch((e) => {});

	}

	addRPOOptions () {

		cartographies.getPossibleRPOForCartography(this.idCartography).then((users) => {

			$(users).each((i, userToAdd) => {

				$("FORM [name=rpo_id]").append(`<option value='${userToAdd.id}'>${userToAdd.standard_name}</option>`);

			});

		}).catch((error) => {

			debug.v("RiskCreate - addRPOOptions - error in User getPossibleRPOForCartography");

		});

		// We show the current cartography name on RPO tooltip
		$("#tooltip-name-cartography").html(this.cartographyData.name);

		// We listen change on RPO select
		$("FORM [name=rpo_id]").on("change", this.handleChangeRPOSelect.bind(this));

	}

	handleChangeRPOSelect () {

		// We get the id of the selected user
		const rpoId = $("FORM [name=rpo_id]").val();

		cartographies.getPossibleRPOForCartography(this.idCartography).then((users) => {

			$(users).each((i, userToCheck) => {

				if (parseInt(rpoId) == parseInt(userToCheck.id)) {

					if (userToCheck.rights_that_will_be_added !== undefined && userToCheck.rights_that_will_be_added !== null) {

						if (userToCheck.rights_that_will_be_added.rights.indexOf("view_evaluation") > -1) {

							const cartographyName = this.cartographyData[`name_${$.i18n().locale}`];

							// We define the message to show
							const htmlUserMessage = `<div class="msg-user"><div class="icon fas fa-exclamation-triangle"></div><div class="text">${$.i18n("text-message-right-that-will-be-added-part-1")} <span class="highlight">${cartographyName}</span> ${$.i18n("text-message-right-that-will-be-added-part-2")}</div></div>`;

							// We show a message after the select
							$("FORM [name=rpo_id]").parents("li").find(".msg-user").remove();
							$("FORM [name=rpo_id]").parents("li").append(htmlUserMessage);

						}

					} else {

						$("FORM [name=rpo_id]").parents("li").find(".msg-user").remove();

					}

				}

			});

		}).catch((error) => {

			debug.v("RiskCreate - handleChangeRPOSelect - error in User getPossibleRPOForCartography");

		});

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("create_risk");

		this.createdRisk = updateObjectWithForm({}, "#create_risk");

		this.createdRisk.description_fr = this.richTextEditorDescriptionFr.getData();
		this.createdRisk.description_en = this.richTextEditorDescriptionEn.getData();

		this.createdRisk.cartography_id = this.idCartography;

		this.createdRisk.editors_ids = this.dataRiskEditors;

		risks.createOne(this.createdRisk).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("RiskCreate - createSuccess");

		risks.getData(this.params).then(() => {

			router.navigate(`legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${response.id}/business-activities/all`);

			// We add a class on the global container to hide the select of legal entity
			$("#content").addClass("new-risk");


		}).catch((error) => {

			debug.e("RiskCreate - createSuccess - error in Risks getData");

		});

	}

	createFail (response, textStatus) {

		debug.v("RiskCreate - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"title_fr": {
					"required": `${$.i18n("form-error-title-fr-required")}`
				},
				"title_en": {
					"required": `${$.i18n("form-error-title-en-required")}`
				},
				"description_fr": {
					"fieldSelector": "FORM .richText",
					"required": `${$.i18n("form-error-description-fr-required")}`
				},
				"description_en": {
					"fieldSelector": "FORM .richText",
					"required": `${$.i18n("form-error-description-en-required")}`
				},
				"theme": {
					"required": `${$.i18n("form-error-theme-required")}`
				},
				"rpo_id": {
					"required": `${$.i18n("form-error-rpo-required")}`
				},
				"origin": {
					"required": `${$.i18n("form-error-origin-required")}`
				}
			};

			showFormErrors("#create_risk", response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(".error").eq(0).parents("li").offset().top;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new RiskCreate();
