import html from "./resource.html";
import resources from "Datapoints/Resources";
import user from "Datapoints/User.js";
import Popin from "Components/Popin/Popin.js";
import debug from "Core/Debug.js";
import Resource from "./Resource.js";
import {showFormErrors} from "Core/helpers.js";

class ResourceContainer {

	constructor (risk) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		this.risk = risk;

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.risk.id);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.risk.id);

		// If the user is RPO or Editor, we show the buttons
		if (this.isCurrentUserRPO || this.isCurrentUserRiskEditor) {

			this.canAdmin = true;
			this.startShowing();

		} else { // Otherwise we check if he has appropriate right

			user.canAdminRisks({"idCartography": this.risk.cartography_id}).then((result) => {

				this.canAdmin = true;
				this.startShowing();

			}).catch((e) => {

				this.canAdmin = false;
				this.startShowing();

			});

		}

	}

	startShowing () {

		this.initAdminSidebar();
		this.showResources(resources.data);
		this.showFilterByLanguage(resources.data);
		this.initRadioTypeEvents();

		// We define the click outside the select custom filter
		$(document).click(() => {

		    this.hideSelectCustomFilter();

		});
		$("#resources-filter-language").click((e) => {

		    e.stopPropagation();

		});

		// We define click on select custom filter
		$("#resources-filter-language .option-selected").on("click", this.handleClickOnSelectCustomFilter.bind(this));

		// We define click on filter option
		$("#resources-filter-language ul.list-options li.option").on("click", this.handleClickOnFilterOption.bind(this));

	}

	showResources (resourcesData) {

		if (
			resourcesData !== null &&
			resourcesData !== undefined &&
			resourcesData.length === 0
		) {

			const htmlNoItem = `<div class="no-item" data-i18n="label-msg-no-resources">${$.i18n("label-msg-no-resources")}</div>`;

			$("#resources ul.list-links").html(htmlNoItem);

		} else {

			// By default, we sort the resources by language
			let resourcesSortingByLanguage = [];
			$.each(resourcesData, (index, item) => {

				if (item.language === this.currentLang) {

					resourcesSortingByLanguage.unshift(item);

				} else {

					resourcesSortingByLanguage.push(item);

				}

			});

			// We clear container
			$("#resources ul.list-links").html("");

			// We show all resources
			$.each(resourcesSortingByLanguage, (index, item) => {

				const newResource = new Resource(item);

				newResource.appendAndFill("#resources ul.list-links", item, this.canAdmin);
				$(newResource).on("edit", this.handleClickEditSidebarItem.bind(this));
				$(newResource).on("delete", this.handleClickDeleteSidebarItem.bind(this));

			});

		}

		// We update the control instruments number of the sub menu in the risk page
		const numItemLink = $("#slide-control-instruments .item-link").length;
		$("#sub-menu-item-control-instruments .num").html(numItemLink);

	}

	showFilterByLanguage (resourcesData) {

		// We init the language filter options
		this.initLanguageFilterOptions();

		if (
			resourcesData !== null &&
			resourcesData !== undefined &&
			resourcesData.length > 0
		) {

			$("#resources-filter-language").removeClass("enable");
			$("#resources-filter-language").addClass("enable");

		} else {

			$("#resources-filter-language").removeClass("enable");

		}

	}

	initLanguageFilterOptions () {

		const htmlDefaultSelectedOption = `
			<span class="picto far fa-globe"></span>
      <span class="text" data-i18n="label-btn-all-language">${$.i18n("label-btn-all-language")}</span>
      <span class="icon fas fa-angle-down"></span>
		`;

		// We replace the selected option
		$("#resources-filter-language .option-selected").html(htmlDefaultSelectedOption);
		$("#resources-filter-language .option-selected").attr("data-value", "none");

		// We update the option list
		$("#resources-filter-language .list-options .option").removeClass("inactive");
		$("#resources-filter-language .list-options .option").eq(0).addClass("inactive");
		$("#resources-filter-language .list-options .option").removeClass("no-sep");
		$("#resources-filter-language .list-options .option:not(.inactive)").eq(0).addClass("no-sep");

	}

	handleClickOnSelectCustomFilter () {

		if ($("#resources-filter-language").find(".list-options").css("display") === "none") {

			// We show the select custom filter
			this.showSelectCustomFilter();

		} else {

			// We hide the select custom filter
			this.hideSelectCustomFilter();

		}

	}

	showSelectCustomFilter () {

		// We open the list of options
		$("#resources-filter-language").addClass("active");

		// We change the arrow icon
		$("#resources-filter-language").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#resources-filter-language").find(".option-selected .icon").addClass("fa-angle-up");

	}

	hideSelectCustomFilter () {

		// We close the list of options
		$("#resources-filter-language").removeClass("active");

		// We change the arrow icon
		$("#resources-filter-language").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#resources-filter-language").find(".option-selected .icon").addClass("fa-angle-down");

	}


	handleClickOnFilterOption (e) {

		// We get the selected option value
		const selectedOptionValue = $("#resources-filter-language .option-selected").attr("data-value");

		// We get the clicked option value
		const clickedOptionValue = $(e.currentTarget).attr("data-value");

		// We get the html of the clicked option
		const htmlClickedOption = $(e.currentTarget).html();

		// We define the html of the new selected option
		let htmlNewSelectedOption = `${htmlClickedOption}<span class="icon fas fa-angle-down"></span>`;

		// We replace the selected option
		$("#resources-filter-language .option-selected").html(htmlNewSelectedOption);
		$("#resources-filter-language .option-selected").attr("data-value", clickedOptionValue);

		// We update the option list
		$("#resources-filter-language .list-options .option").removeClass("inactive");
		$(e.currentTarget).addClass("inactive");
		$("#resources-filter-language .list-options .option").removeClass("no-sep");
		$("#resources-filter-language .list-options .option:not(.inactive)").eq(0).addClass("no-sep");

		// We hide the select custom filter
		this.hideSelectCustomFilter();

		// We update the resources
		let resourcesData;
		if (clickedOptionValue !== "none" && clickedOptionValue !== undefined && clickedOptionValue !== null) {

			resourcesData = resources.data.filter((resource) => resource.language === clickedOptionValue);

		} else {

			resourcesData = resources.data;

		}
		this.showResources(resourcesData);

	}

	initAdminSidebar () {


		const btnAddItemSelector = $("#btn-add-resource");
		const formAddItemSelector = $("#resources .content-box-sidebar .form-add-item");

		if (this.canAdmin) {

			// We define click on btn add item
			btnAddItemSelector.on("click", this.handleClickAddSidebarItem.bind(this));

			// We define click on file input
			formAddItemSelector.find(".file-field").on("change", this.handleChangeFileField.bind(this));

			// We define click on submit buttons
			$("#resources .content-box-sidebar").find(".btn-submit").on("click", this.handleClickBtnCreateResource.bind(this));

			// We define click on cancel buttons
			$("#resources .content-box-sidebar").find(".btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

		} else {

			btnAddItemSelector.hide();

		}

	}

	initRadioTypeEvents () {

		// We define click on radio
		$("#resources .item-radio label").on("click", this.handleClickItemRadioType.bind(this));
		$("#resources .item-radio input").on("click", this.handleClickItemRadioType.bind(this));

	}

	handleClickItemRadioType (e) {

		// Get parent form id
		const curParentFormId = $(e.currentTarget).parents("form").attr("id");

		// Get data field of clicked element
		const curDataField = $(e.currentTarget).parents(".item-radio").attr("data-field");

		// We hide type fields
		$(".line-type").removeClass("active");

		// We show type field
		$(`#${curParentFormId}`).find(`.line-type-${curDataField}`).addClass("active");

	}

	handleClickAddSidebarItem (e) {

		const formSelector = $("#create_resource");

		// We clear all errors form
		formSelector.find("input").removeClass("error");
		formSelector.find(".error").html("");

		if (formSelector.attr("class").indexOf("active") < 0) {

			formSelector.addClass("active");

			// We clear all fields
			formSelector.find("INPUT[name=name]").val("");
			formSelector.find("INPUT[name=file]").val("");
			formSelector.find("INPUT[name=link_url]").val("");
			formSelector.find("INPUT[name=file_created_at]").val("");
			formSelector.find(".label-file").removeClass("active");
			formSelector.find(".label-file").html($.i18n("form-label-field-select-file"));

			// We clear radio of type
			$("#show_link_create").prop("checked", false);
			$("#show_file_create").prop("checked", false);

			// We hide type fields
			$(".line-type").removeClass("active");

			// We hide all opened forms
			$(e.currentTarget).parent().find(".item-link").removeClass("active-form");
			$(e.currentTarget).parent().find(".item-link .form-admin-item").remove();

		} else {

			formSelector.removeClass("active");

		}

	}

	handleClickEditSidebarItem (e, resourceId) {

		// We define parent item selector
		const parentItemSelector = $("#resources").find(`.item-link[data-item-id='${resourceId}']`);

		// We hide all opened edit forms
		$("#resources").find(".item-link").removeClass("active-form");
		$("#resources").find(".form-admin-item").removeClass("active");

		// We hide the add item form
		$("#create_scenario").removeClass("active");

		// We show the edit item form
		parentItemSelector.addClass("active-form");

		const htmlForm = $("#create_resource").html().replace(/file-resource/g, "file-resource-edit").replace(/show_file_create/g, "show_file_edit").replace(/show_link_create/g, "show_link_edit");

		parentItemSelector.append(`<form id="edit_resource" class="form-edit-item form-admin-item active">${htmlForm}</form>`);

		this.resourceToEdit = resources.find(parentItemSelector.attr("data-item-id"));

		$(`#edit_resource [name='language'] OPTION[value='${this.resourceToEdit.language}']`).attr("selected", true);
		$("#edit_resource [name='name']").attr("value", this.resourceToEdit.name);

		if (this.resourceToEdit.link_url !== undefined && this.resourceToEdit.link_url !== null && this.resourceToEdit.link_url !== "" && this.resourceToEdit.link_url !== "http://") {

			// We hide type fields
			$(".line-type").removeClass("active");

			// We show type field for link
			$("#edit_resource").find(".line-type-link").addClass("active");

			// We check the link radio
			$("#show_link_edit").prop("checked", true);

			$("#edit_resource [name='link_url']").attr("value", this.resourceToEdit.link_url);

		} else if (this.resourceToEdit.file_url !== undefined && this.resourceToEdit.file_url !== null && this.resourceToEdit.file_url !== "") {

			// We hide type fields
			$(".line-type").removeClass("active");

			// We show type field for link
			$("#edit_resource").find(".line-type-file").addClass("active");

			// We check the file radio
			$("#show_file_edit").prop("checked", true);

		}

		// We set the filename in the file label
		this.setFileFieldLabel($("#edit_resource [type='file']"), this.resourceToEdit.file_url);

		if (this.resourceToEdit.file_created_at != null && this.resourceToEdit.file_created_at != undefined) {

			$("#edit_resource [name='file_created_at']").attr("value", this.resourceToEdit.file_created_at.split("-").reverse().join("/"));

		}

		// We define clicks
		$("#edit_resource .file-field").on("change", this.handleChangeFileField.bind(this));
		$("#edit_resource .btn-submit").on("click", this.handleClickBtnUpdateResource.bind(this));
		$("#edit_resource .btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));
		this.initRadioTypeEvents();

		// We init the datepicker
		this.initDatepicker();

	}

	initDatepicker () {

		$("[data-toggle=\"datepicker-small\"]").datepicker({
			"language": "fr-FR",
			"format": "mm/yyyy",
			"autoHide": true
		});

	}

	handleClickDeleteSidebarItem (e, resourceId) {

		this.resourceIdToDelete = resourceId;
		const resourceName = $(`#resources .item-link[data-item-id='${resourceId}']`).find(".desc-link .text").html();

		$("#delete-item-sidebar-popin").remove();

		this.deleteSidebarItemPopin = new Popin("delete-item-sidebar-popin", `${$.i18n("popin-delete-resource-confirmation-title")} '${resourceName}' ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteSidebarItemCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteResourceConfirm.bind(this)
			}
		]);

		this.deleteSidebarItemPopin.appendIn("#page");

		this.deleteSidebarItemPopin.show();

	}

	handleClickBtnCancelForm (e) {

		// We cancel the link submission
		e.preventDefault();

		// We define the current form
		const form = $(e.currentTarget).parents("form");

		// We show item informations
		$(e.currentTarget).parents(".item-link").removeClass("active-form");

		// We hide the form
		form.removeClass("active");
		$(e.currentTarget).parents(".form-edit-item").remove();

	}

	handleChangeFileField (e) {

		this.setFileFieldLabel(e.currentTarget);

	}

	setFileFieldLabel (inputjQueryObj, urlPath = undefined) {

		// We get the file name uploaded
		const inputFilepath = urlPath != undefined ? urlPath : $(inputjQueryObj).val();
		const curFileName = inputFilepath.replace(/.*(\/|\\)/, "");

		// We define the label
		const labelSelector = $(inputjQueryObj).parent("li").find(".label-file");

		// We add the file name on the label
		labelSelector.addClass("active");
		labelSelector.html(curFileName);

	}

	handleClickBtnCreateResource (e) {

		// We cancel the link submission
		e.preventDefault();

		// We create and populate the form data
		let form_data = new FormData();
		let resourceLanguage = $("#create_resource SELECT[name=\"language\"]").val();
		let resourceName = $("#create_resource INPUT[name=\"name\"]").val();
		let resourceFile = $("#create_resource INPUT[type=\"file\"]").prop("files")[0];
		let resourceLinkUrl = $("#create_resource INPUT[name=\"link_url\"]").val();
		let resourceFileCreatedAt = `${$("#create_resource INPUT[name=\"file_created_at\"]").val()}`.split("/").reverse().join("-");

		// We add http:// on link url
		let linkUrlToSend;
		if (resourceLinkUrl !== "" && resourceLinkUrl.indexOf("http://") === -1 && resourceLinkUrl.indexOf("https://") === -1) {

			linkUrlToSend = "http://".concat(resourceLinkUrl);

		} else {

			linkUrlToSend = resourceLinkUrl;

		}

		form_data.append("language", resourceLanguage);
		form_data.append("name", resourceName);
		if (resourceFile !== undefined && resourceLinkUrl === "") {

			form_data.append("file", resourceFile);

		} else {

			form_data.append("link_url", linkUrlToSend);

		}
		form_data.append("file_created_at", resourceFileCreatedAt);

		resources.addResource(form_data, this.risk.id).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("Risk resource creation - createSuccess");

		// We hide the form
		$("#create_resource").removeClass("active");

		resources.getData({"idRisk": this.risk.id}).then(() => {

			this.showResources(resources.data);
			this.showFilterByLanguage(resources.data);

			// We clear all fields
			$("#create_resource SELECT[name=\"language\"]").val("");
			$("#create_resource INPUT[name=\"name\"]").val("");
			$("#create_resource INPUT[type=\"file\"]").val("");
			$("#create_resource .label-file").removeClass("active");
			$("#create_resource .label-file").html($.i18n("form-label-field-select-file"));
			$("#create_resource INPUT[type=\"link_url\"]").val("");
			$("#create_resource INPUT[name=\"file_created_at\"]").val("");

		}).catch((error) => {

			debug.e("Risk resource creation - createSuccess - error in Resources getData");
			debug.e(error);

		});

	}

	createFail (response, textStatus) {

		debug.v("Risk resource creation - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": `${$.i18n("form-error-global-name-required")}`
				},
				"language": {
					"required": `${$.i18n("form-error-language-required")}`,
					"not_allowed_only_allowed_en_fr": `${$.i18n("form-error-language-required")}`
				},
				"link_url": {
					"required_without_file": `${$.i18n("form-error-link-required")}`
				},
				"file": {
					"required_without_link_url": `${$.i18n("form-error-file-required")}`,
					"must_be_a_file": `${$.i18n("form-error-file-required")}`
				},
				"file_created_at": {
					"bad_format": `${$.i18n("form-error-file-date-bad-format")}`
				}
			};

			showFormErrors("#create_resource", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk resource creation - createSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleClickBtnUpdateResource (e) {

		e.preventDefault();

		// We create and populate the form data
		let form_data_resource = new FormData();
		let languageName = $("#edit_resource SELECT[name=\"language\"]").val();
		let resourceName = $("#edit_resource INPUT[name=\"name\"]").val();
		let resourceType = $("#edit_resource INPUT[name=\"type\"]:checked").val();
		let resourceFile = $("#edit_resource INPUT[type=\"file\"]").prop("files")[0];
		let resourceLinkUrl = $("#edit_resource INPUT[name=\"link_url\"]").val();
		let resourceFileCreatedAt = `${$("#edit_resource INPUT[name=\"file_created_at\"]").val()}`.split("/").reverse().join("-");

		// We add http:// on link url
		let linkUrlToSend;
		if (resourceLinkUrl !== "" && resourceLinkUrl.indexOf("http://") === -1 && resourceLinkUrl.indexOf("https://") === -1) {

			linkUrlToSend = "https://".concat(resourceLinkUrl);

		} else {

			linkUrlToSend = resourceLinkUrl;

		}

		form_data_resource.append("language", languageName);
		form_data_resource.append("name", resourceName);
		if (resourceType == "file") {

			form_data_resource.append("link_url", "");
			form_data_resource.append("file", resourceFile);

		} else if (resourceType == "link") {

			form_data_resource.append("link_url", linkUrlToSend);
			form_data_resource.append("file", null);

		}
		form_data_resource.append("file_created_at", resourceFileCreatedAt);

		// We get the resourceId and risk id
		form_data_resource.append("id", this.resourceToEdit.id);
		form_data_resource.append("risk_id", this.resourceToEdit.risk_id);

		resources.updateResource(form_data_resource).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

	}

	updateSuccess (response) {

		debug.v("Risk resource update - updateSuccess");

		resources.getData({"idRisk": this.risk.id}).then(() => {

			this.showResources(resources.data);

		}).catch((error) => {

			debug.e("Risk resource update - updateSuccess - error in Resources getData");
			debug.e(error);

		});

	}

	updateFail (response, textStatus) {

		debug.v("Risk resource update - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": `${$.i18n("form-error-global-name-required")}`
				},
				"language": {
					"required": `${$.i18n("form-error-language-required")}`,
					"not_allowed_only_allowed_en_fr": `${$.i18n("form-error-language-required")}`
				},
				"link_url": {
					"required_without_file": `${$.i18n("form-error-link-required")}`
				},
				"file": {
					"required_without_link_url": `${$.i18n("form-error-file-required")}`
				}
			};

			showFormErrors("#edit_resource", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk resource update - updateSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteSidebarItemCancel () {

		this.deleteSidebarItemPopin.hide();

	}

	handleDeleteResourceConfirm () {

		resources.deleteResource(this.risk.id, this.resourceIdToDelete).then(() => {

			this.deleteSidebarItemPopin.hide();

			resources.getData({"idRisk": this.risk.id}).then(() => {

				this.showResources(resources.data);
				this.showFilterByLanguage(resources.data);

			}).catch((error) => {

				debug.e("Risk resource deletion - handleDeleteResourceConfirm - error in Resources getData");
				debug.e(error);

			});

		}).catch(debug.e);

	}

}
export default ResourceContainer;
