import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Scales extends Datapoint {

	createOne (scaleToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/scale/create",
				"data": scaleToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (scaleToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/scale/update",
				"data": scaleToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (scaleId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/scale/delete/${scaleId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			// If we are not on a creation page (=> in creation page we don't know the cartography ID)
			if (window.location.pathname.indexOf("/create") < 0) {

				let cartographyId;

				if (params !== undefined && params.idCartography !== undefined) {

					cartographyId = params.idCartography;

				} else {

					debug.e("Error in getData of Scales, the Cartography id is undefined.");
					return;

				}

				api.call({
					"method": "GET",
					"url": `/scales/${cartographyId}`
				}, false).
					done((dataReturned) => {

						this.updateData(dataReturned);
						resolve();

					}).
					fail((error, textStatus) => {

						// We accept the 403
						if (error.status == 403) {

							resolve();

						} else {

							error.objCalling = this.constructor.name;
							reject(error);

						}

					});

			} else {

				resolve();

			}

		});

	}

	find (idToFind) {

		let scale;
		if (this.data != undefined) {

			scale = this.data.find((scale) => scale.id == idToFind);

		}

		return scale;

	}


}
export default new Scales();
