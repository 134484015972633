import Dashboard from "../Dashboard.js";
import html from "./dashboard_grid.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import licencingVerification from "Datapoints/LicencingVerification.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import debug from "Core/Debug.js";
import router from "Core/router.js";

class DashboardGrid extends Dashboard {

	constructor () {

		let routes = [
			"/",
			"/home",
			"/dashboard",
			"/dashboard-grid",
			"/dashboard-grid/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-grid/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-grid/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-grid/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-grid/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-grid/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			context,
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			countries,
			businessActivities,
			user
		];

		// We make sure the current licence is valid
		licencingVerification.check().then(() => {

			debug.v("Licence verification succeeded.");

		}).catch(() => {

			debug.e("!!! Licence verification failed.");
			console.error("!!! Licence verification failed.");
			router.navigate("/licence-verification-failed");

		});

		super(routes, AuthentifiedLayout, "Risk Management Platform", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

		this.html = html;

	}

	initEvents () {

		// We define mouse events on card risks
		$(".home-container .grid-content .card-risk").on("mouseover", this.handleMouseOverOnCardRisk.bind(this));
		$(".home-container .grid-content .card-risk").on("mouseout", this.handleMouseOutOnCardRisk.bind(this));

	}

	handleMouseOutOnCardRisk (e) {

		$(".home-container .grid-content .card-risk").removeClass("active");
		$(".home-container .grid-content").removeClass("over-card-risk");

	}

	addRiskToEvaluatedSection (card, data, evaluation) {

		$("#section-evaluated-risks").find(".cols-grid").css("display", "flex");
		const cardColor = card.getCardColor(evaluation);
		const selector = `#content-home-col-grid-${cardColor}`;
		card.appendAndFill(selector, cardColor, data, evaluation.scores);

	}

	addRiskToNotEvaluatedSection (card, data) {

		const htmlColGridRiskNotEvaluated = "<div class=\"col-grid\"></div>";
		$("#section-not-evaluated-risks .cols-grid").append(htmlColGridRiskNotEvaluated);
		const selector = $("#section-not-evaluated-risks .cols-grid").find(".col-grid").last();
		card.appendAndFill(selector, "", data, []);

		this.updateNotEvaluatedCounter();

	}

	addRiskToIgnoredSection (card, data) {

		const htmlColGridRiskIgnored = "<div class=\"col-grid\"></div>";
		$("#section-ignored-risks .cols-grid").append(htmlColGridRiskIgnored);
		const selector = $("#section-ignored-risks .cols-grid").find(".col-grid").last();
		card.appendAndFill(selector, "", data, []);

		this.updateIgnoredCounter();

	}

	addRiskToPendingSection (card, data) {

		const htmlColGridRiskPending = "<div class=\"col-grid\"></div>";
		$("#section-pending-risks .cols-grid").append(htmlColGridRiskPending);
		const selector = $("#section-pending-risks .cols-grid").find(".col-grid").last();
		card.appendAndFill(selector, "", data, []);

		this.updatePendingCounter();

	}

	handleClickOnBtnRisksNotEvaluated () {

		// We get the position top of the section
		const posTopSection = $("#section-not-evaluated-risks").offset().top;

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection}, 500);

	}

	handleClickOnBtnRisksIgnored () {

		// We get the position top of the section
		const posTopSection = $("#section-ignored-risks").offset().top;

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection}, 500);

	}

	handleClickOnBtnRisksPending () {

		// We get the position top of the section
		const posTopSection = $("#section-pending-risks").offset().top;

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection}, 500);

	}

}
export default new DashboardGrid();
