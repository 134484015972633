import html from "./scenario.html";
import scenarios from "Datapoints/Scenarios";
import user from "Datapoints/User.js";
import Popin from "Components/Popin/Popin.js";
import debug from "Core/Debug.js";
import Scenario from "./Scenario.js";
import {showFormErrors} from "Core/helpers.js";

class ScenarioContainer {

	constructor (risk) {

		this.risk = risk;

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.risk.id);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.risk.id);

		// If the user is RPO or Editor, we show the buttons
		if (this.isCurrentUserRPO || this.isCurrentUserRiskEditor) {

			this.canAdmin = true;
			this.startShowing();

		} else { // Otherwise we check if he has appropriate right

			user.canAdminRisks({"idCartography": this.risk.cartography_id}).then((result) => {

				this.canAdmin = true;
				this.startShowing();

			}).catch((e) => {

				this.canAdmin = false;
				this.startShowing();

			});

		}

	}

	startShowing () {


		this.initAdminSidebar();
		this.showScenarios();

	}

	showScenarios () {

		if (
			scenarios.data !== null &&
			scenarios.data !== undefined &&
			scenarios.data.length === 0
		) {

			const htmlNoItem = `<div class="no-item" data-i18n="label-msg-no-scenario">${$.i18n("label-msg-no-scenario")}</div>`;

			$("#scenarios ul.list-links").html(htmlNoItem);

		} else {

			// We clear container
			$("#scenarios ul.list-links").html("");

			// We show all scenarios
			$.each(scenarios.data, (index, item) => {

				const newScenario = new Scenario(item);

				newScenario.appendAndFill("#scenarios ul.list-links", item, this.canAdmin);
				$(newScenario).on("edit", this.handleClickEditSidebarItem.bind(this));
				$(newScenario).on("delete", this.handleClickDeleteSidebarItem.bind(this));

			});

		}

		// We update the control instruments number of the sub menu in the risk page
		const numItemLink = $("#slide-control-instruments .item-link").length;
		$("#sub-menu-item-control-instruments .num").html(numItemLink);

	}

	initAdminSidebar () {

		const btnAddItemSelector = $("#btn-add-scenario");
		const formAddItemSelector = $("#scenarios .content-box-sidebar .form-add-item");

		// If the user do not have the right to admin cartography, we remove the button to create / update / delete scenario
		if (!this.canAdmin) {

			btnAddItemSelector.hide();

		} else {

			// We define click on btn add item
			btnAddItemSelector.on("click", this.handleClickAddSidebarItem.bind(this));

			// We define click on file input
			formAddItemSelector.find(".file-field").on("change", this.handleChangeFileField.bind(this));

			// We define click on submit buttons
			$("#scenarios .content-box-sidebar").find(".btn-submit").on("click", this.handleClickBtnCreateScenario.bind(this));

			// We define click on cancel buttons
			$("#scenarios .content-box-sidebar").find(".btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

		}

	}

	handleClickAddSidebarItem (e) {

		const formSelector = $("#create_scenario");

		// We clear all errors form
		formSelector.find("input").removeClass("error");
		formSelector.find(".error").html("");

		if (formSelector.attr("class").indexOf("active") < 0) {

			formSelector.addClass("active");

			formSelector.find(".label-file").removeClass("active");
			formSelector.find(".label-file").html($.i18n("form-label-field-select-file"));

			// We hide all opened forms
			$(e.currentTarget).parent().find(".item-link").removeClass("active-form");
			$(e.currentTarget).parent().find(".item-link .form-admin-item").remove();

		} else {

			formSelector.removeClass("active");

		}

	}

	handleClickEditSidebarItem (e, scenarioId) {

		// We define parent item selector
		const parentItemSelector = $("#scenarios").find(`.item-link[data-item-id='${scenarioId}']`);

		// We hide all opened edit forms
		$("#scenarios").find(".item-link").removeClass("active-form");
		$("#scenarios").find(".form-admin-item").removeClass("active");

		const htmlForm = $("#create_scenario").html().replace(/file-scenario/g, "file-scenario-edit");

		// We hide the add item form
		$("#create_scenario").removeClass("active");

		// We show the edit item form
		parentItemSelector.addClass("active-form");

		parentItemSelector.append(`<form id="edit_scenario" class="form-edit-item form-admin-item active">${htmlForm}</form>`);

		this.scenarioToEdit = scenarios.find(parentItemSelector.attr("data-item-id"));

		$("#edit_scenario [name='name']").attr("value", this.scenarioToEdit.name);

		// We set the filename in the file label
		this.setFileFieldLabel($("#edit_scenario [type='file']"), this.scenarioToEdit.file_url);

		// We define clicks
		$("#edit_scenario .file-field").on("change", this.handleChangeFileField.bind(this));
		$("#edit_scenario .btn-submit").on("click", this.handleClickBtnUpdateScenario.bind(this));
		$("#edit_scenario .btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

	}

	handleClickDeleteSidebarItem (e, scenarioId) {

		this.scenarioIdToDelete = scenarioId;
		const scenarioName = $(`.item-link[data-item-id='${scenarioId}']`).find(".desc-link .text").html();

		$("#delete-item-sidebar-popin").remove();

		this.deleteSidebarItemPopin = new Popin("delete-item-sidebar-popin", `${$.i18n("popin-delete-scenario-confirmation-title")} '${scenarioName}' ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteSidebarItemCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteScenarioConfirm.bind(this)
			}
		]);

		this.deleteSidebarItemPopin.appendIn("#page");

		this.deleteSidebarItemPopin.show();

	}

	handleClickBtnCancelForm (e) {

		// We cancel the link submission
		e.preventDefault();

		// We define the current form
		const form = $(e.currentTarget).parents("form");

		// We show item informations
		$(e.currentTarget).parents(".item-link").removeClass("active-form");

		// We hide the form
		form.removeClass("active");
		$(e.currentTarget).parents(".form-edit-item").remove();

	}

	handleChangeFileField (e) {

		this.setFileFieldLabel(e.currentTarget);

	}

	setFileFieldLabel (inputjQueryObj, urlPath = undefined) {

		// We get the file name uploaded
		const inputFilepath = urlPath != undefined ? urlPath : $(inputjQueryObj).val();
		const curFileName = inputFilepath.replace(/.*(\/|\\)/, "");

		// We define the label
		const labelSelector = $(inputjQueryObj).parent("li").find(".label-file");

		// We add the file name on the label
		labelSelector.addClass("active");
		labelSelector.html(curFileName);

	}

	handleClickBtnCreateScenario (e) {

		// We cancel the link submission
		e.preventDefault();

		// We create and populate the form data
		let form_data = new FormData();
		let scenarioName = $("#create_scenario INPUT[name=\"name\"]").val();
		let scenarioFile = $("#create_scenario INPUT[type=\"file\"]").prop("files")[0];
		form_data.append("name", scenarioName);
		form_data.append("file", scenarioFile);

		scenarios.addScenario(form_data, this.risk.id).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("Risk scenario creation - createSuccess");

		// We hide the form
		$("#create_scenario").removeClass("active");

		scenarios.getData({"idRisk": this.risk.id}).then(() => {

			this.showScenarios(scenarios.data);

			// We clear all fields
			$("#create_scenario INPUT[name=\"name\"]").val("");
			$("#create_scenario INPUT[type=\"file\"]").val("");
			$("#create_scenario .label-file").removeClass("active");
			$("#create_scenario .label-file").html($.i18n("form-label-field-select-file"));

		}).catch((error) => {

			debug.e("Risk scenario creation - createSuccess - error in Scenarios getData");
			debug.e(error);

		});

	}

	createFail (response, textStatus) {

		debug.v("Risk scenario creation - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": "Vous devez saisir un nom"
				},
				"file": {
					"required": "Vous devez choisir un fichier",
					"must_be_a_file": "Vous devez choisir un fichier"
				}
			};

			showFormErrors("#create_scenario", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk scenario creation - createSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleClickBtnUpdateScenario (e) {

		e.preventDefault();

		// We create and populate the form data
		let form_data_scenario = new FormData();
		let scenarioName = $("#edit_scenario INPUT[name=\"name\"]").val();
		form_data_scenario.append("name", scenarioName);

		let scenarioFile = $("#edit_scenario INPUT[type=\"file\"]").prop("files")[0];

		if (scenarioFile != undefined) {

			form_data_scenario.append("file", scenarioFile);

		}

		// We get the scenarioId and risk id
		form_data_scenario.append("id", this.scenarioToEdit.id);
		form_data_scenario.append("risk_id", this.scenarioToEdit.risk_id);

		scenarios.updateScenario(form_data_scenario).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

	}

	updateSuccess (response) {

		debug.v("Risk scenario update - updateSuccess");

		scenarios.getData({"idRisk": this.risk.id}).then(() => {

			this.showScenarios(scenarios.data);

		}).catch((error) => {

			debug.e("Risk scenario update - updateSuccess - error in Scenarios getData");
			debug.e(error);

		});

	}

	updateFail (response, textStatus) {

		debug.v("Risk scenario update - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": "Vous devez saisir un nom"
				}
			};

			showFormErrors("#edit_scenario", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk scenario update - updateSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteSidebarItemCancel () {

		this.deleteSidebarItemPopin.hide();

	}

	handleDeleteScenarioConfirm () {

		scenarios.deleteScenario(this.risk.id, this.scenarioIdToDelete).then(() => {

			this.deleteSidebarItemPopin.hide();

			scenarios.getData({"idRisk": this.risk.id}).then(() => {

				this.showScenarios(scenarios.data);

			}).catch((error) => {

				debug.e("Risk scenario deletion - handleDeleteScenarioConfirm - error in Scenarios getData");
				debug.e(error);

			});

		}).catch(debug.e);

	}

}
export default ScenarioContainer;
