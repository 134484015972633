import html from "./gofurther.html";
import gofurthers from "Datapoints/GoFurther";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import Popin from "Components/Popin/Popin.js";

class GoFurther {

	appendAndFill (cssSelector, data, isAdminButtons) {

		// We show the go further link
		$(cssSelector).append(html);

		// We define the go further link
		const curGoFurther = $(cssSelector).find(".item-link").last();

		// We add the id
		curGoFurther.attr("data-item-id", data.id);

		// We add the link
		curGoFurther.find("a.desc-link").attr("href", data.link_url);

		// We define and add the icon
		curGoFurther.find("a.desc-link .icon").addClass("fa-link");

		// We add the name
		curGoFurther.find("a.desc-link .text").html(data.name);

		// We add the author name
		const authorData = data.author;
		if (authorData != null) {

			const htmlLineAuthor = `${$.i18n("text-add-item-by")} <span class="highlight">${authorData.firstname} ${authorData.lastname}</span>`;
			curGoFurther.find("a.desc-link .infos-link .author .name").html(htmlLineAuthor);

		} else {

			curGoFurther.find("a.desc-link .infos-link .author").html("");

		}

		// We add the admin buttons
		if (isAdminButtons) {

			const htmlAdminButtons = "<div class=\"admin-buttons\"><div class=\"btn-admin btn-edit\" title='Modifier le lien'><span class=\"icon far fa-pencil-alt\"></span></div><div class=\"btn-admin btn-delete\" title='Supprimer le lien'><span class=\"icon far fa-trash-alt\"></span></div></div>";

			curGoFurther.append(htmlAdminButtons);

		}

		// We trigger the events on button clicks
		curGoFurther.find(".btn-edit").on("click", () => {

			$(this).trigger("edit", [data.id]);

		});
		curGoFurther.find(".btn-delete").on("click", () => {

			$(this).trigger("delete", [data.id]);

		});

	}

}
export default GoFurther;
