import Page from "Pages/Page.js";
import html from "./temp_password.html";
import router from "Core/router.js";
import user from "Datapoints/User.js";
import menu from "Components/Menu/Menu.js";
import api from "Core/Api.js";
import footer from "Components/Footer/Footer.js";
import LoginLayout from "Layouts/Login/LoginLayout.js";
import debug from "Core/Debug.js";
import "./TempPassword.scss";
import {showFormErrors} from "Core/helpers.js";

class TempPassword extends Page {

	constructor () {

		let routes = ["user/change-temporary-password"];

		super(routes, LoginLayout, "Risk Management Platform");
		this.rightsValidations = [user.getData.bind(user)];

	}

	render () {

		this.parseAndRender("page", html);
		footer.show();

		this.initEvents();

	}

	initEvents () {

		$("FORM").on("submit", this.handleSubmit.bind(this));

	}

	handleSubmit (e) {

		e.preventDefault();
		$(".error").html("");

		if ($("input[name='new_password']").val() == "") {

			$(".error").append("<li>Vous devez saisir un mot de passe");

		}
		if ($("input[name='new_password_confirmation']").val() == "") {

			$(".error").append("<li>Vous devez saisir une confirmation de mot de passe");

		}

		user.update({
			"new_password": $("input[name='new_password']").val(),
			"new_password_confirmation": $("input[name='new_password_confirmation']").val()
		}).
			then(this.updateSuccess.bind(this)).
			catch(this.updateFail.bind(this));

	}


	updateSuccess (data) {

		// We force the request to get the new data for the status update
		user.getData({"forceRequest": true}).then(() => {

			user.redirectAfterLogin("/dashboard-grid");

		}).catch(debug.e);

	}

	updateFail (response, textStatus) {

		console.log("updateFail");
		if (response.status == 422) {

			let errorsToShow = {
				"new_password": {
					"required": `${$.i18n("form-error-password-required")}`,
					"bad_format": `${$.i18n("form-error-password-bad-format")}`
				},
				"new_password_confirmation": {
					"required": `${$.i18n("form-error-password-confirmation-required")}`
				}
			};

			showFormErrors(".temp-password-form", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");

		}

	}

}
export default new TempPassword();
