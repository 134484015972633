import mainMenuItem from "Components/MainMenuItem/MainMenuItem.js";
import helpMenu from "Components/HelpMenu/HelpMenu.js";
// Import main menu items JSON
import mainMenuItems from "Core/assets/json/main_menu_items.json";

class MainMenuContainer {

	init () {

		// We define the css container selector of the main menu
		this.containerSelector = $("#menu .main-menu ul");

		// We remove the main menu selector content
		this.containerSelector.html("");

		// We init main meu items
		this.initMainMenuItems();

		// We init the help menu
		helpMenu.init();

		// We define active menu
		this.setActiveMenu();

	}

	initMainMenuItems () {

		this.mainMenuItems = mainMenuItems.data.sort((a, b) => a.position - b.position);

		// We loop on the main menu items
		this.mainMenuItems.forEach((item) => {

			// We show the item
			mainMenuItem.init(this.containerSelector, item.id);

		});

	}

	setActiveMenu () {

		const curRoute = document.location.pathname;

	 if (curRoute.indexOf("/admin/user") > -1) {

			$("#menu .main-menu ul li.btn-users_management").addClass("active");

		} else if (curRoute.indexOf("/admin/cartographies") > -1) {

			$("#menu .main-menu ul li.btn-cartographies_management").addClass("active");

		} else if (curRoute.indexOf("/admin/business-activities") > -1) {

			$("#menu .main-menu ul li.btn-business-activities_management").addClass("active");

		} else if (curRoute.indexOf("/approach") > -1) {

			$("#menu .main-menu ul li.btn-approach").addClass("active");

		} else if (curRoute.indexOf("/about") > -1) {

			$("#menu .main-menu ul li.btn-about").addClass("active");

		} else if (curRoute.indexOf("/actions-plan/list") > -1) {

			$("#menu .main-menu ul li.btn-summary-action-plans").addClass("active");

		} else if (curRoute.indexOf("/statistics") > -1) {

			$("#menu .main-menu ul li.btn-statistics").addClass("active");

		} else if (curRoute.indexOf("/exports") > -1) {

			$("#menu .main-menu ul li.btn-exports").addClass("active");

		}

	}

}
export default new MainMenuContainer();
