import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./edit.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import scales from "Datapoints/Scales.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import {showFormErrors, updateObjectWithForm, getUrlVars} from "Core/helpers.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import cartographyImpactField from "Components/CartographyImpactField/CartographyImpactField.js";
import cartographyScaleField from "Components/CartographyScaleField/CartographyScaleField.js";
import cartographyThemeField from "Components/CartographyThemeField/CartographyThemeField.js";
import cartographyConsequenceField from "Components/CartographyConsequenceField/CartographyConsequenceField.js";
// Import select2 library
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";
// Import mapping categories JSON
import cartographyCategories from "Core/assets/json/mapping_categories.json";

class CartographyEdit extends Page {

	constructor (params) {

		let routes = [
			"/admin/cartography/edit/:idCartography",
			"/admin/cartography/create"
		];

		let datapointsToLoad = [
			user,
			context,
			users,
			cartographies,
			scales
		];

		super(routes, AuthentifiedLayout, "Modification d'une cartographie", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminRisksOrCanAdminCartographies.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			this.urlVars = getUrlVars();

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.params = params;
			this.idCartography = null;
			this.isUserAdminRisks = false;

			this.parseAndRender("page", html);

			this.editedCartography = {};
			this.formType = null;

			// We get the current branch id
			this.branchId = context.getCurrentBranchId();

			// We define the cartography managers
			this.dataCartographyManagers = [];

			// We define the users list for cartography managers
			this.usersListForCartographyManagers = users.data;

			// We init the impacts
			cartographyImpactField.init("#cartography_impacts", this.params, this.currentLang);

			// We init the scales
			cartographyScaleField.init("#cartography_scales", this.params, this.currentLang);

			// We init the themes
			cartographyThemeField.init("#cartography_themes", this.currentLang);

			// We init the consequencies
			cartographyConsequenceField.init("#cartography_consequencies", this.currentLang);

			// We add translate buttons
			this.addTranslateButtons();

			// We load global events
			this.initEvents();

			// We init the cartography categories options
			this.initCartographyCategories();

			// We initialize the cartography managers
			this.initCartographyManagers();

			// If the config shows the methodo's image
			if (process.env.SHOW_METHODO_IMAGE === true || process.env.SHOW_METHODO_IMAGE === "true") {

				// We show the methodo's image fields
				this.showMethodoImageFields();

			}

			// We init events for admin risks
			if (
				this.params !== undefined &&
				this.params !== null &&
				this.params.idCartography !== undefined &&
				!user.hasAdminCartographiesRight() && user.canAdminRisks({"idCartography": this.params.idCartography})
			) {

				this.initEventsForAdminRisks();

			}

			$("FORM#edit_cartography").on("submit", this.handleSubmit.bind(this));

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.addBranchesOptions());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initEvents () {

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			if (this.urlVars[0].indexOf("create") >= 0) {

				this.formType = "create";

				// We check if the current user can add a new cartography
				cartographies.canNewCartographyBeCreatedInSubscription(this.branchId).then((response) => {

					// If the user can't add a new cartography
					if (!response.canACartographyBeCreatedInSubscription) {

						// We define the message
						const htmlMessage = `<div class="msg-no-item space-on-top"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-subscription-limitation-for-page">${$.i18n("msg-subscription-limitation-for-page")}</span></div></div>`;

						// We add the message to the container
						$(".admin-container").html(htmlMessage);

					}

				}).catch((error) => {

					debug.v("CartographyEdit - initEvents - error in Cartographies canNewCartographyBeCreatedInSubscription");
					reject(error);

				});

				if (!user.hasAdminCartographiesRight()) {

					router.navigate("/not-allowed");

				}

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("admin-cartographies-create-title-page")}`);
				const htmlTitleCreate = `<h1 data-i18n="admin-cartographies-create-title-page">${$.i18n("admin-cartographies-create-title-page")}</h1>`;
				$(".title-page").html(htmlTitleCreate);

				// We update the label of the submit button
				const htmlButtonCreate = `<i class="icon fas fa-check"></i><span class="text" data-i18n="button-create">${$.i18n("button-create")}</span>`;
				$(".btn-submit").html(htmlButtonCreate);

			} else if (this.urlVars[0].indexOf("edit") >= 0) {

				this.formType = "edit";

				this.idCartography = this.params.idCartography;

				this.editedCartography = cartographies.find(this.idCartography);

				this.autoFillPage(this.editedCartography);
				this.autoFillForm("#edit_cartography", this.editedCartography);
				this.autoFillCartographyMethodo();
				cartographyImpactField.autoFillCartographyImpacts(this.editedCartography);
				cartographyThemeField.autoFillCartographyThemes(this.editedCartography);
				cartographyConsequenceField.autoFillCartographyConsequencies(this.editedCartography);

			}

			// We init the field of risk prefix
			this.initRiskPrefix();

			// We init the field of methodo
			this.initCartographyMethodo();

		}

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	initRiskPrefix () {

		$("#risk-prefix-field input.text-field").on("keyup", this.handleChangeOnInputRiskPrefix.bind(this));

	}

	handleChangeOnInputRiskPrefix (e) {

		const curValue = $(e.currentTarget).val();

		if (curValue !== undefined && curValue !== null && curValue !== "") {

			// We show the dynamic mention container
			if ($("#risk-prefix-field .dynamic-mention").attr("class").indexOf("active") < 0) {

				$("#risk-prefix-field .dynamic-mention").slideDown(200);

			}

			// We show the mention text
			$("#risk-prefix-field .dynamic-mention .val-prefix").html(curValue);

		} else {

			$("#risk-prefix-field .dynamic-mention").slideUp(200);

		}

	}

	initCartographyCategories () {

		// We get the categories
		this.cartographyCategories = cartographyCategories.data;

		if (
			this.cartographyCategories !== undefined &&
			this.cartographyCategories !== null &&
			this.cartographyCategories.length > 0
		) {

			// We load the categories options
			this.addCategoriesOptions();

		} else {

			this.cartographyCategories = null;

			// We remove the category field
			$("select[name='category']").parents("li").remove();

		}

	}

	initEventsForAdminRisks () {

		// We update the state
		this.isUserAdminRisks = true;

		// We remove some elements in the form
		$("input[name=name_fr]").parents("li").remove();
		$("input[name=name_en]").parents("li").remove();
		$("#cartography_managers").remove();
		$("select[name=branch_id]").parents("li").remove();
		$("select[name=category]").parents("li").remove();
		$("input[name=risk_prefix]").parent("li").remove();

	}

	showMethodoImageFields () {

		$(".methodo-field").css("display", "flex");

	}

	handleClickOnCancelButton () {

		// If user is admin risks
		if (!user.hasAdminCartographiesRight() && user.canAdminRisks({"idCartography": this.params.idCartography})) {

			router.navigate("/dashboard");

		} else {

			router.navigate("/admin/cartographies");

		}

	}

	autoFillCartographyMethodo () {

		// We get the current url of the methodo in french
		const curApproachImageUrlFr = this.editedCartography.approach_image_url_fr;

		// We fill the methodo field in french
		if (curApproachImageUrlFr !== null && curApproachImageUrlFr !== undefined) {

			$("#methodo-field-fr").find(".custom-file-name").html(curApproachImageUrlFr);

			const htmlPreviewFileFr = `<div class="preview-file"><img src="${curApproachImageUrlFr}" border="0" alt="" /></div>`;
			$("#methodo-field-fr").append(htmlPreviewFileFr);

		}

		// We get the current url of the methodo in english
		const curApproachImageUrlEn = this.editedCartography.approach_image_url_en;

		// We fill the methodo field in english
		if (curApproachImageUrlEn !== null && curApproachImageUrlEn !== undefined) {

			$("#methodo-field-en").find(".custom-file-name").html(curApproachImageUrlEn);

			const htmlPreviewFileEn = `<div class="preview-file"><img src="${curApproachImageUrlEn}" border="0" alt="" /></div>`;
			$("#methodo-field-en").append(htmlPreviewFileEn);

		}

	}

	initCartographyMethodo () {

		// We listen change on the input file of the methodo
		$("input[name='approach_image_fr']").on("change", this.handleChangeOnMethodoFile.bind(this));
		$("input[name='approach_image_en']").on("change", this.handleChangeOnMethodoFile.bind(this));

	}

	handleChangeOnMethodoFile (e) {

		// We get the name of the selected file
		const curFileName = e.target.files[0].name;

		// We show the selected file name
		$(e.currentTarget).parents(".methodo-field").find(".custom-file-name").html(curFileName);

	}

	addCategoriesOptions () {

		$(this.cartographyCategories).each((i, category) => {

			$("FORM [name=category]").append(`<option value='${category.id}'>${category[this.currentLang]}</option>`);

		});

		if (this.formType === "edit") {

			this.autoFillForm("#edit_cartography", this.editedCartography);

		}

	}

	addBranchesOptions () {

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			user.getUserBranchesForAdminCartographiesRight().then((data) => {

				$(data.branches).each((i, branch) => {

					$("FORM [name=branch_id]").append(`<option value='${branch.id}'>${branch[this.currentLang]}</option>`);

				});

				if (this.formType === "edit") {

					this.autoFillForm("#edit_cartography", this.editedCartography);

				}

			}).catch((error) => {

				debug.v("CartographyEdit - addLegalEntitiesOptions - error in User getUserBranchesForAdminCartographiesRight");

			});

		} else {

			// We remove the branch field
			$("#branch-field").remove();

		}

	}

	initCartographyManagers () {

		if (this.formType === "edit") {

			// We autofill the cartography managers section
			this.autoFillCartographyManagers();

		}

		// We define cartography managers selector
		this.setCartographyManagersSelector();

		// We listen click on the delete cartography manager button
		$("#section_cartography_managers .tab-result .label-result .btn-delete-cartography-manager").on("click", this.handleClickOnDeleteCartographyManagerButton.bind(this));

		// We listen change on the cartography manager select
		$("#section_cartography_managers select").on("change", this.handleChangeOnCartographyManagerSelect.bind(this));

		// We listen click on the add cartography manager button
		$("#btn-add-cartography-manager").on("click", this.handleClickOnAddCartographymanagerButton.bind(this));

	}

	autoFillCartographyManagers () {

		// We get the list of cartography managers
		$.each(users.data, (index, user) => {

			if (user.rights != null) {

				const isRightAdminRiskForThisCartographyExist = user.rights.filter((right) => parseInt(this.idCartography) === parseInt(right.cartography_id) && right.rights.indexOf("admin_risks") >= 0)[0];

				if (isRightAdminRiskForThisCartographyExist !== undefined) {

					// We get current manager id
					const curManagerId = parseInt(user.id);

					// We update the users list for editors
					this.usersListForCartographyManagers = this.usersListForCartographyManagers.filter((user) => parseInt(user.id) !== curManagerId);

					// We get the current manager data
					const curManagerData = users.find(curManagerId);

					// We get the current manager fullname
					const curManagerFullname = curManagerData.standard_name;

					// We save the cartography managers id in the data cartography managers list
					this.dataCartographyManagers.push(curManagerId);

					// We get the index of current manager in the users list
					const managerIndex = users.data.findIndex((user) => user.id === curManagerId);

					// We define the html of the new manager label
					const htmlLabelManager = `
						<div class="label-result" data-manager-id="${curManagerId}" data-index-cartography-manager="${managerIndex}">
							<div class="info-context">
								<span class="icon fas fa-user-cog"></span>
								<span class="name-context">${curManagerFullname}</span>
							</div>
							<div class="other-info">
								<div class="btn-delete-cartography-manager">
									<span class="icon fas fa-trash-alt"></span>
								</div>
							</div>
						</div>
					`;

					// We show the label of the new manager
					$("#section_cartography_managers .tab-result").prepend(htmlLabelManager);

				}

			}

		});

	}

	setCartographyManagersSelector () {

		let managersOptions = "<option></option>";
		$(this.usersListForCartographyManagers).each((i, curUser) => {

			let managerId = curUser.id;
			let managerName = curUser.standard_name;

			managersOptions += `<option value=${managerId}>${managerName}</option>`;

		});

		// We fill the selector
		$("select[name='cartography_manager_id']").html(managersOptions);

		// We initialize select 2
		$("#cartography_manager_selector").select2({
			"language": "fr"
		});

		// We put the placeholder on the input search of the select2
		$("#cartography_manager_selector").one("select2:open", (e) => {

			$("input.select2-search__field").prop("placeholder", `${$.i18n("form-placeholder-admin-cartography")}`);

		});

		// Customize select 2
		$(".select2-selection__arrow").find("b").hide();
		$(".select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");

	}

	handleClickOnDeleteCartographyManagerButton (e) {

		// We get the current cartography manager id
		const curCartographyManagerId = parseInt($(e.currentTarget).parents(".label-result").attr("data-manager-id"));

		// We get the current cartography manager data
		const curCartographyManagerData = users.find(curCartographyManagerId);

		// We get the index of the current cartography manager
		const curIndexCartographyManager = parseInt($(e.currentTarget).parents(".label-result").attr("data-index-cartography-manager"));

		// We add the cartography manager in the users list for cartography managers
		this.usersListForCartographyManagers.splice(curIndexCartographyManager, 0, curCartographyManagerData);

		// We update cartography managers selector
		this.setCartographyManagersSelector();

		// We delete the cartography manager in the array of list of cartography managers
		this.dataCartographyManagers = this.dataCartographyManagers.filter((idManager) => idManager !== curCartographyManagerId);

		// We delete the label of the cartography manager
		$(e.currentTarget).parents(".label-result").remove();

	}

	handleChangeOnCartographyManagerSelect () {

		if ($("#section_cartography_managers select[name='cartography_manager_id']").val() !== "" && $("#section_cartography_managers select[name='cartography_manager_id']").val() !== "") {

			// We active the add button
			$("#btn-add-cartography-manager").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-cartography-manager").addClass("inactive");

		}

	}

	handleClickOnAddCartographymanagerButton () {

		if (!$("#btn-add-cartography-manager").hasClass("inactive")) {

			// We get new cartography manager id
			const newCartographyManagerId = parseInt($("#section_cartography_managers select[name='cartography_manager_id']").val());

			// We update the users list for cartography managers
			this.usersListForCartographyManagers = this.usersListForCartographyManagers.filter((user) => parseInt(user.id) !== newCartographyManagerId);

			// We update cartography managers selector
			this.setCartographyManagersSelector();

			// We get the new cartography manager data
			const newCartographyManagerData = users.find(parseInt(newCartographyManagerId));

			// We get the new cartography manager fullname
			const newCartographyManagerFullname = newCartographyManagerData.standard_name;

			// We add the cartography manager on the cartography managers array
			this.dataCartographyManagers.push(newCartographyManagerId);

			// We get the index of current cartography manager in the users list
			const cartographyManagerIndex = users.data.findIndex((user) => user.id === newCartographyManagerId);

			// We define the html of the new cartography manager label
			const htmlLabelCartographymanager = `
				<div class="label-result" data-manager-id="${newCartographyManagerId}" data-index-cartography-manager="${cartographyManagerIndex}">
					<div class="info-context">
						<span class="icon fas fa-user-cog"></span>
						<span class="name-context">${newCartographyManagerFullname}</span>
					</div>
					<div class="other-info">
						<div class="btn-delete-cartography-manager">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
			`;

			// We show the label of the new cartography manager
			$("#section_cartography_managers .tab-result").prepend(htmlLabelCartographymanager);

			// We listen the click on the delete cartography manager button
			$(`#section_cartography_managers .tab-result .label-result[data-manager-id='${newCartographyManagerId}'] .btn-delete-cartography-manager`).on("click", this.handleClickOnDeleteCartographyManagerButton.bind(this));

			// We clear the add cartography manager form
			this.clearAddCartographyManagerForm();

		}

	}

	clearAddCartographyManagerForm () {

		// We clear the fields
		$("#section_cartography_managers select[name='cartography_manager_id']").val("");

		// We clear the add cartography manager button
		$("#btn-add-cartography-manager").addClass("inactive");

	}

	addTranslateButtons () {

		// Name FR
		const translationButtonForNameFr = new FieldTranslationButton("name_fr", "text", "fr");

		// Name EN
		const translationButtonForNameEn = new FieldTranslationButton("name_en", "text", "en");

		// Impact FR
		const translationButtonForImpactFr = new FieldTranslationButton("impact_fr", "text", "fr");

		// Impact EN
		const translationButtonForImpactEn = new FieldTranslationButton("impact_en", "text", "en");

		// Theme FR
		const translationButtonForThemeFr = new FieldTranslationButton("theme_fr", "text", "fr");

		// Theme EN
		const translationButtonForThemeEn = new FieldTranslationButton("theme_en", "text", "en");

		// Consequence FR
		const translationButtonForConsequenceFr = new FieldTranslationButton("consequence_fr", "text", "fr");

		// Consequence EN
		const translationButtonForConsequenceEn = new FieldTranslationButton("consequence_en", "text", "en");

	}

	handleSubmit (e) {

		e.preventDefault();
		this.clearFormErrors("edit_cartography");

		// We get the form values and we delete the approach_image value (because we send it separatly)
		this.editedCartography = updateObjectWithForm(this.editedCartography, "#edit_cartography");
		this.editedCartography.impacts = cartographyImpactField.getCartographyImpacts();
		this.editedCartography.themes = cartographyThemeField.getCartographyThemes();
		this.editedCartography.other_potential_risks = cartographyConsequenceField.getCartographyConsequencies();
		delete this.editedCartography.approach_image_fr;
		delete this.editedCartography.approach_image_en;

		// We get the cartography scales
		const cartographyScales = cartographyScaleField.getCartographyScales();

		if (process.env.SHOW_BRANCHES === undefined || process.env.SHOW_BRANCHES === false || process.env.SHOW_BRANCHES === "false") {

			this.editedCartography.branch_id = parseInt(this.branchId);

		}

		// For admin risks, we remove some unauthorized fields
		if (this.isUserAdminRisks) {

			delete this.editedCartography.name_fr;
			delete this.editedCartography.name_en;
			delete this.editedCartography.branch_id;
			delete this.editedCartography.risk_prefix;

		}

		// We define category value if there is no data
		if (this.cartographyCategories === null) {

			this.editedCartography.category = null;

		}

		if (this.formType === "edit") { // EDIT CARTO

			// We delete admin_users from data form
			delete this.editedCartography.admin_users;

			// First, we update the user admin carto
			if (
				!this.isUserAdminRisks &&
				this.dataCartographyManagers !== undefined &&
				this.dataCartographyManagers !== null &&
				this.dataCartographyManagers.length > 0
			) {

				users.setAdminRiskForCartography(this.dataCartographyManagers, this.idCartography).then();

			}

			// Then we update all the fields except approach_image
			cartographies.updateOne(this.editedCartography).then(() => {

				// We get the approach_image field value
				let approachImageFr = $("#edit_cartography INPUT[name=\"approach_image_fr\"]").prop("files")[0];
				let approachImageEn = $("#edit_cartography INPUT[name=\"approach_image_en\"]").prop("files")[0];

				// For each cartography scale
				$(cartographyScales).each((i, scale) => {

					// We check if the scale already exist in the current cartography scales
					let isScaleExist;
					if (scales.data !== undefined && scales.data.length > 0) {

						isScaleExist = scales.data.filter((curCartographyScale) => parseInt(curCartographyScale.id) === parseInt(scale.id));

					}

					// If the scale already exist
					if (isScaleExist !== undefined && isScaleExist.length > 0) {

						// We update the scale
						this.updateScale(scale);

					} else {

						cartographies.getData({"forceRequest": true}).then(() => {

							// If the scale is for a new impact
							if (scale.impact_id === "new_impact") {

								// First, we want to get the new impact ID to add the new attached scale
								// We get the impact name FR of the new impact
								const newImpactNameFr = scale.impact_name_fr;

								// We get the impact name EN of the new impact
								const newImpactNameEn = scale.impact_name_en;

								// We get the cartography data updated
								const cartographyDataUpdated = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(this.idCartography))[0];

								// We get the new impact data
								const newImpactData = cartographyDataUpdated.impacts.filter((impact) => impact.name_fr === newImpactNameFr && impact.name_en === newImpactNameEn)[0];

								// We get the new impact ID
								const newImpactId = parseInt(newImpactData.id);

								// We change the impact_id value in the current scale
								scale.impact_id = newImpactId;

								// We clean data, we delete the impact name FR/EN from the current scale
								delete scale.impact_name_fr;
								delete scale.impact_name_en;

								// Then, we create the scale
								this.createScale(scale);

							} else {

								// We create the scale
								this.createScale(scale);

							}

						}).catch((error) => {

							debug.v("CartographyEdit - editSuccess - error in Cartographies getData");

						});

					}

				});

				// Then, we update the approach_image field
				if (approachImageFr !== undefined && approachImageFr !== null && approachImageEn !== undefined && approachImageEn !== null) {

					// We create and populate the form data
					let formData = new FormData();
					let cartographyId = this.editedCartography.id;
					formData.append("id", cartographyId);
					formData.append("approach_image_fr", approachImageFr);
					formData.append("approach_image_en", approachImageEn);

					cartographies.updateApproachImage(formData).then(this.editSuccess.bind(this), this.editFail.bind(this));

				} else {

					this.editSuccess();

				}

			}, this.editFail.bind(this));

		} else if (this.formType === "create") { // CREATE CARTO

			// First, we add all the fields except approach_image
			cartographies.createOne(this.editedCartography).then((cartographyCreationData) => {

				// We get the new cartography ID
				let cartographyId = cartographyCreationData.id;

				// We get the approach_image field value
				let approachImageFr = $("#edit_cartography INPUT[name=\"approach_image_fr\"]").prop("files")[0];
				let approachImageEn = $("#edit_cartography INPUT[name=\"approach_image_en\"]").prop("files")[0];

				// For each cartography scale
				$(cartographyScales).each((i, scale) => {

					// We add the new cartography ID to the cartography scales data
					scale.cartography_id = cartographyId;

					// If the scale is for a new impact
					if (scale.impact_id === "new_impact") {

						// First, we want to get the new impact ID to add the new attached scale
						// We get the impact name FR of the new impact
						const newImpactNameFr = scale.impact_name_fr;

						// We get the impact name EN of the new impact
						const newImpactNameEn = scale.impact_name_en;

						// We get the new impact data
						const newImpactData = this.editedCartography.impacts.filter((impact) => impact.name_fr === newImpactNameFr && impact.name_en === newImpactNameEn)[0];

						// We get the new impact ID
						const newImpactId = parseInt(newImpactData.id);

						// We change the impact_id value in the current scale
						scale.impact_id = newImpactId;

						// We clean data, we delete the impact name FR/EN from the current scale
						delete scale.impact_name_fr;
						delete scale.impact_name_en;

						// Then, we create the scale
						this.createScale(scale);

					} else {

						// We create the scale
						this.createScale(scale);

					}

				});

				// Then, we add the approach_image field
				if (approachImageFr !== undefined && approachImageFr !== null && approachImageEn !== undefined && approachImageEn !== null) {

					// We create and populate the form data
					let formData = new FormData();
					formData.append("id", cartographyId);
					formData.append("approach_image_fr", approachImageFr);
					formData.append("approach_image_en", approachImageEn);

					cartographies.updateApproachImage(formData).then(this.createSuccess.bind(this), this.editFail.bind(this));

				} else {

					this.createSuccess(cartographyCreationData);

				}

			}, this.editFail.bind(this));

		}

	}

	updateScale (scale) {

		scales.updateOne(scale).then(() => {}, () => {

			debug.v("CartographyEdit - handleSubmit - error in Scales updateOne");

		});

	}

	createScale (scale) {

		scales.createOne(scale).then(() => {}, () => {

			debug.v("CartographyEdit - handleSubmit - error in Scales createOne");

		});

	}

	editSuccess () {

		debug.v("CartographyEdit - editSuccess");

		cartographies.getData({"forceRequest": true}).then(() => {

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("CartographyEdit - editSuccess - error in User getData");

			});

			// We have an exception for admin users
			if (this.isUserAdminRisks) {

				// We redirect to the dashboard
				router.navigate("/dashboard");

			} else {

				// We force users data
				users.getData({"forceRequest": true}).then(() => {

					// We redirect to the list cartographies page
					router.navigate("/admin/cartographies");

				}).catch((error) => {

					debug.v("CartographyEdit - editSuccess - error in Users getData");

				});

			}

		}).catch((error) => {

			debug.v("CartographyEdit - editSuccess - error in Cartographies getData");

		});

	}

	createSuccess (creationData) {

		debug.v("CartographyCreate - createSuccess");

		cartographies.getData({"forceRequest": true}).then(() => {

			// If an admin carto has been chosen, we give him the right
			if (this.dataCartographyManagers !== undefined && this.dataCartographyManagers !== "" && this.dataCartographyManagers !== null && this.dataCartographyManagers.length > 0) {

				users.setAdminRiskForCartography(this.dataCartographyManagers, creationData.id).then((result) => {

					router.navigate("/admin/cartographies");

				});

			} else {

				router.navigate("/admin/cartographies");

			}

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("CartographyCreate - createSuccess - error in User getData");

			});


		}).catch((error) => {

			debug.v("CartographyCreate - createSuccess - error in Cartographies getData");

		});

	}

	editFail (response, textStatus) {

		if (response.status === 422) {

			let errorsToShow = {
				"name_fr": {
					"required": `${$.i18n("form-error-cartography-name-fr-required")}`,
					"taken": `${$.i18n("form-error-cartography-name-taken")}`
				},
				"name_en": {
					"required": `${$.i18n("form-error-cartography-name-en-required")}`,
					"taken": `${$.i18n("form-error-cartography-name-taken")}`
				},
				"impacts": {
					"required": `${$.i18n("form-error-cartography-impacts-required")}`
				},
				"themes": {
					"required": `${$.i18n("form-error-cartography-themes-required")}`
				},
				"other_potential_risks": {
					"required": `${$.i18n("form-error-cartography-consequencies-required")}`
				},
				"risk_prefix": {
					"required": `${$.i18n("form-error-risk-prefix-required")}`,
					"taken": `${$.i18n("form-error-risk-prefix-taken")}`
				},
				"branch_id": {
					"required": `${$.i18n("form-error-branch-required")}`,
					"not_allowed": `${$.i18n("form-error-branch-not-allowed")}`
				},
				"approach_image_fr": {
					"not_an_image": `${$.i18n("form-error-not-an-image")}`
				},
				"approach_image_en": {
					"not_an_image": `${$.i18n("form-error-not-an-image")}`
				}
			};

			showFormErrors("#edit_cartography", response, errorsToShow);

			// We show the first error
			let positionTopFirstError;
			if ($(".error").eq(0).parents(".section").length > 0) {

				positionTopFirstError = $(".error").eq(0).parents(".section").offset().top;

			} else {

				positionTopFirstError = $(".error").eq(0).parents("li").offset().top;

			}

			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new CartographyEdit();
