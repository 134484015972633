import user from "Datapoints/User";
import helpItems from "Datapoints/HelpItems";
import "./HelpSequence.scss";
import loader from "Components/Loader/Loader.js";
import menu from "Components/Menu/Menu.js";

class HelpSequence {

	init () {

		// We get the help items data
		this.helpItemsData = helpItems.getData();

		// We get the global vars
		this.curHelpItemId = localStorage.getItem("help-itemId");
		if (localStorage.getItem("help-itemStepId") !== undefined && localStorage.getItem("help-itemStepId") !== null) {

			this.curHelpItemStepId = parseInt(localStorage.getItem("help-itemStepId")) + 1;
			localStorage.setItem("help-itemStepId", this.curHelpItemStepId);

		}

		// We load the global vars
		this.initGlobalVars(this.curHelpItemId, this.curHelpItemStepId);

		if (this.isCurPageExistInCurrentHelpSequence !== undefined && this.isCurPageExistInCurrentHelpSequence.length > 0) {

			// We update the step id saved on the local storage
			this.curHelpItemStepId = this.isCurPageExistInCurrentHelpSequence[0].step_id;

			// We reload the global vars
			this.initGlobalVars(this.curHelpItemId, this.curHelpItemStepId);

		} else {

			// We remove the help tooltip
			this.hideTooltip();

			// We hide the help mode style
			this.hideHelpMode();

			// We remove the help informations saved on the local storage
			this.removeLocalStorageInfos();

		}

		if (
			this.curHelpItemId !== undefined &&
			this.curHelpItemId !== null &&
			this.curHelpItemId !== "" &&
			this.curHelpItemStepId !== undefined &&
			this.curHelpItemStepId !== null &&
			this.curHelpItemStepId !== ""
		) {

			// We launch the help item sequence
			this.playHelpItemSequence(this.curHelpItemId, this.curHelpItemStepId);

		}

	}

	initGlobalVars (curHelpItemId, curHelpItemStepId) {

		// We get the current help item data
		this.curHelpItemData = this.helpItemsData.filter((helpItem) => helpItem.id === curHelpItemId)[0];

		// We check if the current page url exist in the current help sequence
		if (this.curHelpItemData !== undefined) {

			this.isCurPageExistInCurrentHelpSequence = this.curHelpItemData.steps.filter((step) => window.location.pathname.indexOf(step.step_url) >= 0 && window.location.pathname.indexOf(step.step_url_exclude_1) < 0 && window.location.pathname.indexOf(step.step_url_exclude_2) < 0 && window.location.pathname.indexOf(step.step_url_exclude_3) < 0 && window.location.pathname.indexOf(step.step_url_exclude_4) < 0 && parseInt(step.step_id) === parseInt(curHelpItemStepId));

		}

	}

	updateLocalStorageInfos (helpId, helpStepId) {

		// We remove the help informations saved on the local storage
		this.removeLocalStorageInfos();

		// We save item infos on local storage
		localStorage.setItem("help-itemId", helpId);
		localStorage.setItem("help-itemStepId", helpStepId);

	}

	removeLocalStorageInfos () {

		// We remove informations saved on the local storage
		localStorage.removeItem("help-itemId");
		localStorage.removeItem("help-itemStepId");

	}

	playHelpItemSequence (helpItemId, helpItemStepId) {

		// If the loader is shown, we wait a bit
		if (loader.isShown()) {

			setTimeout(function () {

				this.playHelpItemSequence(helpItemId, helpItemStepId);

			}.bind(this, helpItemId, helpItemStepId), 500);

		} else {

			// We get the data of the step to show
			this.stepDataToShow = this.curHelpItemData.steps.filter((step) => parseInt(step.step_id) === parseInt(helpItemStepId))[0];

			// We get the data of the tooltips to show
			this.stepTooltipsToShow = this.stepDataToShow.tooltips;

			if (this.isCurPageExistInCurrentHelpSequence !== undefined && this.isCurPageExistInCurrentHelpSequence.length > 0) {

				// We show the help mode style
				this.showHelpMode();

				// We show the tooltips of the first step
				this.tooltipTimeout = setTimeout(() => {

					this.showStepTooltips(this.stepTooltipsToShow);

				}, 250);

			} else {

				// We hide the tooltip
				this.hideTooltip();

				// We hide the help mode style
				this.hideHelpMode();

			}

		}

	}

	showHelpMode () {

		// we remove the help mode container
		$("#container-help-mode").remove();

		// We show the help mode ban and borders
		const htmlHelpModeBanAndBorders = `
			<div id="container-help-mode">
				<div id="ban-help-mode" title="${this.curHelpItemData.title}">
					<div class="title">
						<span class="text" data-i18n="title-ban-help-mode">${$.i18n("title-ban-help-mode")}</span>
						<span class="highlight">${this.curHelpItemData.title}</span>
					</div>
					<div class="line-buttons">
						<div class="btn-close">
							<span class="icon fas fa-times-circle"></span>
							<span class="text" data-i18n="btn-close-help">${$.i18n("btn-close-help")}</span>
						</div>
					</div>
				</div>
				<div id="help-mode-border-top"></div>
				<div id="help-mode-border-right"></div>
				<div id="help-mode-border-bottom"></div>
				<div id="help-mode-border-left"></div>
			</div>
		`;

		$("body").prepend(htmlHelpModeBanAndBorders);

		// We listen click on the close button
		$("#ban-help-mode .line-buttons .btn-close").on("click", this.handleClickOnBtnCloseHelpSequence.bind(this));

		// We listen the window resize
		$(window).on("resize", () => {

			// We remove the help tooltip
			this.hideTooltip();

			// We hide the help mode overlay
			this.hideHelpMode();

			// We remove the help informations saved on the local storage
			this.removeLocalStorageInfos();

		});

	}

	handleClickOnBtnCloseHelpSequence () {

		// We remove the help tooltip
		this.hideTooltip();

		// We hide the help mode overlay
		this.hideHelpMode();

		// We remove the help informations saved on the local storage
		this.removeLocalStorageInfos();

	}

	hideHelpMode () {

		// We add the class to the container global
		$("body").removeClass("help-mode-active");

		// We remove help mode ban and borders
		$("#container-help-mode").remove();

	}

	showStepTooltips (tooltips) {

		// If the loader is present, we wait a bit before showing the tooltip
		if ($(".spinner").length > 0) {

			this.loaderTimeout = setTimeout(() => {

				this.showStepTooltips(this.stepTooltipsToShow);

			}, 250);
			return;

		}

		if (tooltips !== undefined && tooltips !== null && tooltips.length > 0) {

			if (
				tooltips.length > 1 &&
				(tooltips[0].tooltip_duration === null || tooltips[0].tooltip_duration === 0)
			) {

				this.stepTooltipsType = "multiple";

				this.showMultipleTooltips(tooltips);

			} else {

				this.stepTooltipsType = "single";

				const tooltipId = tooltips[0].tooltip_id;
				this.showSingleTooltip(tooltipId);

			}

		}

	}

	showSingleTooltip (tooltipId) {

		// We remove the focus class
		if (this.stepTooltipsType === "single") {

			$("#page").find(".help-block-focus").removeClass("help-block-focus");

		}

		// We get the current tooltip data
		const tooltipData = this.stepTooltipsToShow.filter((tooltip) => tooltip.tooltip_id === tooltipId)[0];

		// We define the tooltip block
		let tooltipBlock;

		// We define the focus block
		let focusBlock;

		// We define if mobile sidebar menu is open
		let isMobileSidebarMenuMustBeOpened = false;

		if ($(tooltipData.tooltip_html_block_primary).length > 0 && $(tooltipData.tooltip_html_block_primary).css("display") !== "none") {

			tooltipBlock = tooltipData.tooltip_html_block_primary;
			focusBlock = tooltipData.focus_html_block_primary;

		} else if ($(tooltipData.tooltip_html_block_secondary).length > 0 && $(tooltipData.tooltip_html_block_secondary).css("display") !== "none") {

			tooltipBlock = tooltipData.tooltip_html_block_secondary;
			focusBlock = tooltipData.focus_html_block_secondary;

		} else {

			tooltipBlock = null;
			focusBlock = null;

		}

		// If we want to show a tooltip on the minify menu
		if (
			(tooltipBlock.indexOf(".sidebar-menu") >= 0 || focusBlock.indexOf(".sidebar-menu") >= 0) &&
			$("#menu").attr("class").indexOf("minify") >= 0
		) {

			// The mobile sidebar menu must be opened
			isMobileSidebarMenuMustBeOpened = true;

		} else {

			// The mobile sidebar menu must be closed
			isMobileSidebarMenuMustBeOpened = false;

		}

		// If the mobile sidebar menu must be closed
		if (!isMobileSidebarMenuMustBeOpened) {

			// We hide the mobile sidebar menu
			menu.hideSidebarMenu();

		}

		if (tooltipBlock !== undefined && tooltipBlock !== null) {

			// We define the top position of the tooltip
			const tooltipPositionTop = $(tooltipBlock).offset().top + parseInt(tooltipData.tooltip_gap_position_y);

			// We define the left position of the tooltip
			const tooltipPositionLeft = $(tooltipBlock).offset().left + parseInt(tooltipData.tooltip_gap_position_x);

			// We define if the tooltip is fixed or not
			let classTooltipFixed = "not-fixed";
			if (tooltipData.tooltip_fixed !== undefined && tooltipData.tooltip_fixed !== null && tooltipData.tooltip_fixed) {

				classTooltipFixed = "fixed";

			}

			// We add the html of the tooltip
			const htmlTooltip = `
				<div class="help-tooltip ${classTooltipFixed}" id="help-tooltip-${tooltipId}" data-block-position="${tooltipBlock}" data-gap-position-y="${tooltipData.tooltip_gap_position_y}" data-gap-position-x="${tooltipData.tooltip_gap_position_x}">
					<div class="arrow fas"></div>
					<div class="content"></div>
				</div>
			`;
			$("#container-help-mode").prepend(htmlTooltip);

			// We add the tooltip content
			$(`#help-tooltip-${tooltipId} .content`).html(tooltipData.tooltip_content);

			// We define the positions of the tooltip
			$(`#help-tooltip-${tooltipId}`).css({
				"top": tooltipPositionTop,
				"left": tooltipPositionLeft
			});

			// We scroll to the tooltip
			if (this.stepTooltipsType === "single") {

				$("html, body").animate({"scrollTop": tooltipPositionTop - 200}, 600);

			}

			// We make the focus on the html block
			if (focusBlock !== undefined && focusBlock !== null) {

				$(focusBlock).addClass("help-block-focus");

			}

			// We add a class to the container global (to control the opacity)
			$("body").removeClass("help-mode-active");
			$("body").addClass("help-mode-active");

			// We show the tooltip
			$(`#help-tooltip-${tooltipId}`).addClass(tooltipData.tooltip_orientation);
			$(`#help-tooltip-${tooltipId}`).addClass("active");

			// We add the class of the arrow icon
			let arrowIconClass;

			if (tooltipData.tooltip_orientation.indexOf("top") >= 0) {

				arrowIconClass = "fa-sort-up";

			} else {

				arrowIconClass = "fa-sort-down";

			}

			$(`#help-tooltip-${tooltipId} .arrow`).removeClass("fa-caret-up");
			$(`#help-tooltip-${tooltipId} .arrow`).removeClass("fa-caret-down");
			$(`#help-tooltip-${tooltipId} .arrow`).addClass(arrowIconClass);

			// If there is a display duration
			if (
				tooltipData.tooltip_duration !== undefined &&
				tooltipData.tooltip_duration !== null &&
				tooltipData.tooltip_duration > 0
			) {

				// We add a tooltip loader
				this.showTooltipLoader(tooltipId, tooltipData.tooltip_duration);

				// We show the next tooltip after the display duration
				this.tooltipTimeout = setTimeout(() => {

					// We hide the current tooltip
					$(`#help-tooltip-${tooltipId}`).removeClass("active");

					// We show the next tooltip
					this.showSingleTooltip(tooltipId + 1);

				}, parseInt(tooltipData.tooltip_duration));

			} else {

				// We hide the tooltip loader
				this.hideTooltipLoader();

			}

		} else {

			// We show the help error message
			this.showHelpErrorMessage(tooltipData);

		}

	}

	updateTooltipsPositions () {

		setTimeout(() => {

			$(".help-tooltip").each(function () {

				// We get the tooltip id
				const tooltipId = $(this).attr("id");

				// We get the tooltip block position
				const tooltipBlockPosition = $(this).attr("data-block-position");

				// We get the tooltip gap position for the Y axis
				const tooltipGapPositionY = $(this).attr("data-gap-position-y");

				// We get the tooltip gap position for the X axis
				const tooltipGapPositionX = $(this).attr("data-gap-position-x");

				// We define the top position of the tooltip
				const tooltipPositionTop = $(tooltipBlockPosition).offset().top + parseInt(tooltipGapPositionY);

				// We define the left position of the tooltip
				const tooltipPositionLeft = $(tooltipBlockPosition).offset().left + parseInt(tooltipGapPositionX);

				// We update the positions of the tooltip
				$(`#${tooltipId}`).css({
					"top": tooltipPositionTop,
					"left": tooltipPositionLeft
				});

			});

		}, 1000);

	}

	showMultipleTooltips (tooltips) {

		tooltips.forEach((tooltip, index) => {

			// We get the current tooltip id
			const tooltipId = tooltip.tooltip_id;

			// We show the current tooltip
			this.showSingleTooltip(tooltipId);

		});

	}

	hideTooltip () {

		// We remove the tooltips
		$(".help-tooltip").remove();

		// We remove the tooltip timeout
		clearTimeout(this.tooltipTimeout);

		// We hide the tooltip loader
		this.hideTooltipLoader();

	}

	showTooltipLoader (tooltipId, tooltipDuration) {

		// We remove the tooltip loader block
		$("#help-tooltip-loader").remove();

		// We get tooltip positions
		const tooltipPositionTop = $(`#help-tooltip-${tooltipId}`).offset().top;
		const tooltipPositionLeft = $(`#help-tooltip-${tooltipId}`).offset().left;

		// We get the tooltip width
		const tooltipWidth = $(`#help-tooltip-${tooltipId}`).outerWidth();

		// We define the html block of the tooltip loader
		const htmlTooltipLoader = "<div id='help-tooltip-loader'></div>";

		// We add the tooltip loader block on the page
		$(htmlTooltipLoader).insertBefore(`#help-tooltip-${tooltipId}`);

		// We update tooltip loader styles
		$("#help-tooltip-loader").css({
			"top": tooltipPositionTop - 10,
			"left": tooltipPositionLeft,
			"width": tooltipWidth
		});

		// We show the tooltip loader
		$("#help-tooltip-loader").addClass("active");

		// We animate the tooltip loader
		$("#help-tooltip-loader").animate({
			"width": 0
		}, parseInt(tooltipDuration));

	}

	hideTooltipLoader () {

		// We remove the tooltip loader block
		$("#help-tooltip-loader").remove();

	}

	showHelpErrorMessage (tooltipData) {

		if (tooltipData.error_message !== undefined && tooltipData.error_message !== null) {

			// We define the help error message
			const htmlHelpErrorMessage = `
				<div id="help-error-message">
					<div class="content">
						<span class="icon fas fa-exclamation-triangle"></span>
						<span class="text">${tooltipData.error_message}</span>
					</div>
				</div>
			`;

			// We add the help error message on the page
			$("#container-help-mode").prepend(htmlHelpErrorMessage);

			// We define the height of the help message error
			$("#help-error-message").css("height", $("#ban-help-mode").outerHeight());

			// We show the help error message
			$("#help-error-message").addClass("active");

		}

	}

}
export default new HelpSequence();
