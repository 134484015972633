import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import risks from "Datapoints/Risks.js";
import loader from "Components/Loader/Loader.js";
import Datapoint from "Core/Datapoint.js";

class User extends Datapoint {

	constructor () {

		super();
		this.updateData({});
		this.hasFailedInThisSession = false;
		this.cacheCanEvaluateThisCartographyForThisLegalEntity = undefined;

	}

	redirectUnauthorizedUser (SSO_URLToUse = undefined) {

		// If he's a logged in user, we redirect him to home
		if (this.isLoggedIn()) {

			router.navigate("/not-allowed");

		} else {

			// We save the targeted URL
			localStorage.setItem("user-redirectAfterLogin", document.location.pathname);

			/* We redirect him to API SSO
			API SSO will :
			- Send to home if login is successfull
			- Send to login if SSO is not setup
			- Send to login if user is recognized by SSO but not by application
			*/
			document.location.href = "/api/user/sso-auth";

		}

	}

	redirectAfterLogin (pageToRedirectByDefault = undefined) {

		const redirectAfterLogin = localStorage.getItem("user-redirectAfterLogin");

		if (redirectAfterLogin != null) {

			router.navigate(redirectAfterLogin);
			localStorage.removeItem("user-redirectAfterLogin");

		} else if (pageToRedirectByDefault != undefined) {

			router.navigate(pageToRedirectByDefault);

		}

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			debug.v(`${this.constructor.name} - getData - forceRequest => ${forceRequest}`);

			/*
			 * If the user is still valid we don't make the request
			 * But, if the API answer a 401, the user will be redirected to login
			 * and if the front thinks the user is still valid it wont reload the user eventho he did a new login.
			 * In that case, we need to force the request
			 */
		 	if (!forceRequest) {

		 		debug.v(`${this.constructor.name} - getData - not calling the api - hasFailedInThisSession : ${this.hasFailedInThisSession}`);

				let now = new Date();
				if (this.isLoggedIn()) {

					resolve(true);
					return;

				} else if (this.hasFailedInThisSession) { // If we already checked for invalid user in this session, we don't do it again except if it's forced

					resolve(false); // We resolve because non-user should not crash
					return;

				}

			}

			debug.v(`${this.constructor.name} - getData - Calling the api...`);

			this.loadCurrentUser(resolve, reject);

		});

	}

	loadCurrentUser (resolve, reject) {

		// We show the loader
		if (
			process.env.SHOW_LOADER_FOR_USER !== undefined &&
			(process.env.SHOW_LOADER_FOR_USER === true ||
			process.env.SHOW_LOADER_FOR_USER === "true")
		) {

			loader.show();

		}

		debug.v(`${this.constructor.name} - loadCurrentUser`);

		api.call({
			"method": "GET",
			"url": "/user"
		}, false).
			done((dataReturned) => {

				debug.v(`${this.constructor.name} - loadCurrentUser - id user returned : ${dataReturned.id}`);

				this.updateData(dataReturned);

				// We refresh the valid until date
				let now = new Date();
				this.data.valid_until = new Date(now.getTime() + this.data.valid_for * 60000);
				this.hasFailedInThisSession = false;

				// We hide the loader
				if (
					process.env.SHOW_LOADER_FOR_USER !== undefined &&
					(process.env.SHOW_LOADER_FOR_USER === true ||
					process.env.SHOW_LOADER_FOR_USER === "true")
				) {

					loader.hide();

				}

				resolve(true);

			}).
			fail((jqXHR, textStatus) => {

				debug.v(`${this.constructor.name} - loadCurrentUser - error in api call - jqXHR.status : ${jqXHR.status}`);

				if (jqXHR.status == 401) {

					// We hide the loader
					if (
						process.env.SHOW_LOADER_FOR_USER !== undefined &&
						(process.env.SHOW_LOADER_FOR_USER === true ||
						process.env.SHOW_LOADER_FOR_USER === "true")
					) {

						loader.hide();

					}

					resolve(false); // We resolve because right trouble should not crash

				} else {

					reject(false);

				}

			});


	}

	login (igg, password) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/auth",
				"data": {
					igg,
					password
				}
			}).
				done(() => {

					this.updateData({});
					resolve();

				}).
				fail(reject);

		});

	}

	loginWithEmail (email, password) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/auth",
				"data": {
					email,
					password
				}
			}).
				done(() => {

					this.updateData({});
					resolve();

				}).
				fail(reject);

		});

	}

	logout () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/logout"
			}).
				done(() => {

					this.updateData({});
					resolve();

				}).
				fail(reject);

		});

	}

	update (dataToUpdate) {

		return new Promise((resolve, reject) => {

			// We make sure the user id is present
			if (dataToUpdate.id == undefined) {

				dataToUpdate.id = this.data.id;

			}

			api.call({
				"method": "POST",
				"url": "/user/update",
				"data": dataToUpdate
			}).
				done(resolve).
				fail(reject);

		});

	}

	isLoggedIn () {

		let now = new Date();
		let isLoggedIn = this.data.valid_until != undefined && now.getTime() <= this.data.valid_until.getTime();

		debug.v(`${this.constructor.name} - isLoggedIn ? => ${isLoggedIn}`);

		return isLoggedIn;

	}

	getNotifications () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/notifications"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getNotificationsCountUnread () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/notifications/count-unread"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	setNotificationsAsRead (notificationsIdsToMarkAsRead) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/notifications/set-read",
				"data": notificationsIdsToMarkAsRead
			}).
				done(resolve).
				fail(reject);

		});

	}

	setNotificationsAsUnread (notificationsIdsToMarkAsUnread) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/notifications/set-unread",
				"data": notificationsIdsToMarkAsUnread
			}).
				done(resolve).
				fail(reject);

		});

	}

	getCartographiesUserHasRightFor () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/rights/cartographies"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getCartographiesByBranchesRights (branchId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${branchId}/cartographies`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getUserBranchesForAdminUsersRight () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/rights/branches/admin_users"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getUserBranchesForAdminCartographiesRight () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/rights/branches/admin_cartographies"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getUserBranchesForAdminBusinessActivitiesRight () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/user/rights/branches/admin_business_activities"
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getBranchesRights (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${cartographyId}/branches`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getCountriesRights (cartographyId, branchId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${cartographyId}/${branchId}/countries`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getCountriesForRightToFilterOn (branchId, rightToFilterOn) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${branchId}/countries/${rightToFilterOn}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getLegalEntitiesRights (cartographyId, branchId, countryId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${cartographyId}/${branchId}/${countryId}/legalEntities`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getLegalEntitiesForRightToFilterOn (branchId, countryId, rightToFilterOn) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/rights/${branchId}/${countryId}/legalEntities/${rightToFilterOn}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 401
					if (error.status == 401) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getLegalEntitiesUserCanAdminUsersForRights () {

		return new Promise((resolve, reject) => {

			resolve(this.data.legalEntitiesUserCanAdminUserFor);

		});

	}

	hasRight (rightToFind, otherAttributeToRight) {

		if (this.data.rights == undefined) {


			return false;

		}

		let hasRight = false;
		this.data.rights.forEach((rightObj, i) => {

			if (rightObj.rights.indexOf(rightToFind) > -1) {

				if (otherAttributeToRight != undefined) {

					for (const attribute in otherAttributeToRight) {

						if (rightObj[attribute] != undefined && rightObj[attribute] == otherAttributeToRight[attribute]) {

							hasRight = true;

						}

					}

				} else {

					hasRight = true;

				}

			}

		});

		return hasRight;

	}

	canAdminUsers (params) {

		return new Promise((resolve, reject) => {

			if (this.hasRight("admin_users")) {

				resolve(true);

			} else {

				reject({
					"error": "User can't admin users",
					"code": 401
				});

			}

		});

	}

	canAdminCartographies (params) {

		return new Promise((resolve, reject) => {

			if (this.hasRight("admin_cartographies")) {

				resolve(true);

			} else {

				reject({
					"error": "User can't admin cartographies",
					"code": 401
				});

			}

		});

	}

	canAdminBusinessActivities (params) {

		return new Promise((resolve, reject) => {

			if (this.hasRight("admin_business_activities")) {

				resolve(true);

			} else {

				reject({
					"error": "User can't admin business activities",
					"code": 401
				});

			}

		});

	}

	canAccessStatistics (params) {

		return new Promise((resolve, reject) => {

			if (this.hasRight("access_statistics")) {

				resolve(true);

			} else {

				reject({
					"error": "User can't access statistics",
					"code": 401
				});

			}

		});

	}

	canCommunicate (params) {

		return new Promise((resolve, reject) => {

			if (this.hasRight("communicate")) {

				resolve(true);

			} else {

				reject({
					"error": "User can't communicate",
					"code": 401
				});

			}

		});

	}

	canAdminRisks (params) {

		return new Promise((resolve, reject) => {

			if (params !== undefined && this.hasRight("admin_risks", {"cartography_id": parseInt(params.idCartography)})) {

				resolve(true);

			} else {

				reject({
					"error": "User can't create risks for current cartography",
					"code": 401
				});

			}

		});

	}

	// CREATION : 19.09.2022 / SPRINT 48
	// UPDATE : 27.09.2023 / SPRINT 62
	canAdminRisksOrCanAdminCartographies (params) {

		return new Promise((resolve, reject) => {

			let promiseRights = [];
			promiseRights.push(this.canAdminRisks(params));
			promiseRights.push(this.canAdminCartographies());

			Promise.allSettled(promiseRights).then((results) => {

				// If both are rejected, we reject
				if (results[0].status === "rejected" && results[1].status === "rejected") {

					reject({
						"error": "User can't create risks for current cartography or user can't admin cartographies",
						"code": 401
					});

				}
				// Otherwise, we resolve
				else {

					resolve(true);

				}


			}).catch((error) => {

				console.error(error);

			});

		});

	}

	canViewActionPlans () {

		let userHasViewActionPlansRight = false;

		const curCartographyId = cartographies.getCurrentCartographyId();
		const curBranchId = context.getCurrentBranchId();
		const curCountryId = context.getCurrentCountryId();
		const curLegalEntityId = context.getCurrentLegalEntityId();

		if (this.data !== undefined) {

			if (this.data.rights !== undefined && this.data.rights !== null && this.data.rights.length > 0) {

				const userViewActionPlansRights = this.data.rights.filter((right) => right.rights.indexOf("view_actions_plan") >= 0 && parseInt(right.cartography_id) === parseInt(curCartographyId));

				if (userViewActionPlansRights !== undefined && userViewActionPlansRights !== null && userViewActionPlansRights.length > 0) {

					$(userViewActionPlansRights).each((i, right) => {

						if (
							right.branch_id !== undefined &&
							right.branch_id !== null &&
							parseInt(right.branch_id) === parseInt(curBranchId) &&
							(right.country_id === undefined || right.country_id === null)
						) {

							userHasViewActionPlansRight = true;

						} else if (
						  right.branch_id !== undefined &&
							right.branch_id !== null &&
							parseInt(right.branch_id) === parseInt(curBranchId) &&
							right.country_id !== undefined &&
							right.country_id !== null &&
							parseInt(right.country_id) === parseInt(curCountryId)
						) {

							userHasViewActionPlansRight = true;

						} else if (
							right.legal_entity_id !== undefined &&
							right.legal_entity_id !== null &&
							parseInt(right.legal_entity_id) === parseInt(curLegalEntityId)
						) {

							userHasViewActionPlansRight = true;

						} else {

							userHasViewActionPlansRight = false;

						}

					});

				}

			}

		}

		return userHasViewActionPlansRight;

	}

	canManageActionPlans () {

		let userHasManageActionPlansRight = false;

		const curCartographyId = cartographies.getCurrentCartographyId();
		const curBranchId = context.getCurrentBranchId();
		const curCountryId = context.getCurrentCountryId();
		const curLegalEntityId = context.getCurrentLegalEntityId();

		if (this.data !== undefined) {

			if (this.data.rights !== undefined && this.data.rights !== null && this.data.rights.length > 0) {

				const userManageActionPlansRights = this.data.rights.filter((right) => right.rights.indexOf("manage_actions_plan") >= 0 && parseInt(right.cartography_id) === parseInt(curCartographyId));

				if (userManageActionPlansRights !== undefined && userManageActionPlansRights !== null && userManageActionPlansRights.length > 0) {

					$(userManageActionPlansRights).each((i, right) => {

						if (
							right.branch_id !== undefined &&
							right.branch_id !== null &&
							parseInt(right.branch_id) === parseInt(curBranchId) &&
							(right.country_id === undefined || right.country_id === null)
						) {

							userHasManageActionPlansRight = true;

						} else if (
						  right.branch_id !== undefined &&
							right.branch_id !== null &&
							parseInt(right.branch_id) === parseInt(curBranchId) &&
							right.country_id !== undefined &&
							right.country_id !== null &&
							parseInt(right.country_id) === parseInt(curCountryId)
						) {

							userHasManageActionPlansRight = true;

						} else if (
							right.legal_entity_id !== undefined &&
							right.legal_entity_id !== null &&
							parseInt(right.legal_entity_id) === parseInt(curLegalEntityId)
						) {

							userHasManageActionPlansRight = true;

						} else {

							userHasManageActionPlansRight = false;

						}

					});

				}

			}

		}

		return userHasManageActionPlansRight;

	}

	canEvaluateCartography () {

		let userHasEvaluateCartographyRight = false;

		const curCartographyId = cartographies.getCurrentCartographyId();
		const curBranchId = context.getCurrentBranchId();
		const curLegalEntityId = context.getCurrentLegalEntityId();

		if (this.data !== undefined) {

			if (this.data.rights !== undefined && this.data.rights !== null && this.data.rights.length > 0) {

				const userEvaluateCartographyRights = this.data.rights.filter((right) => right.rights.indexOf("evaluate_cartography") >= 0 && parseInt(right.cartography_id) === parseInt(curCartographyId));

				if (userEvaluateCartographyRights !== undefined && userEvaluateCartographyRights !== null && userEvaluateCartographyRights.length > 0) {

					$(userEvaluateCartographyRights).each((i, right) => {

						if (
							right.branch_id !== undefined &&
							right.branch_id !== null &&
							parseInt(right.branch_id) === parseInt(curBranchId)
						) {

							userHasEvaluateCartographyRight = true;

						} else if (
							right.legal_entity_id !== undefined &&
							right.legal_entity_id !== null &&
							parseInt(right.legal_entity_id) === parseInt(curLegalEntityId)
						) {

							userHasEvaluateCartographyRight = true;

						} else {

							userHasEvaluateCartographyRight = false;

						}

					});

				}

			}

		}

		return userHasEvaluateCartographyRight;

	}

	canEvaluateThisCartographyForThisLegalEntity (cartographyId, legalEntityId) {

		return new Promise((resolve, reject) => {

			if (this.cacheCanEvaluateThisCartographyForThisLegalEntity === undefined) {

				api.call({
					"method": "GET",
					"url": `/user/rights/can-evaluate-this-cartograhpy-for-this-legal-entity/${cartographyId}/${legalEntityId}`
				}, false).
					done((dataReturned) => {

						if (dataReturned.indexOf("true") >= 0) {

							this.cacheCanEvaluateThisCartographyForThisLegalEntity = true;

						} else {

							this.cacheCanEvaluateThisCartographyForThisLegalEntity = false;
							reject({
								"error": `User can't evaluate the cartography ${cartographyId} for the legal entity ${legalEntityId}`,
								"code": 401
							});

						}

						resolve(this.cacheCanEvaluateThisCartographyForThisLegalEntity);

					}).
					fail((error, textStatus) => {

						// We accept the 401
						if (error.status == 401) {

							this.cacheCanEvaluateThisCartographyForThisLegalEntity = undefined;

						} else {

							error.objCalling = this.constructor.name;
							reject(error);

						}

					});

			} else {

				resolve(this.cacheCanEvaluateThisCartographyForThisLegalEntity);

			}

		});

	}

	canEvaluateThisCartographyForThisBranch () {

		const cartographyId = cartographies.getCurrentCartographyId();
		const branchId = context.getCurrentBranchId();

		return new Promise((resolve, reject) => {

			if (this.data !== undefined) {

				if (this.data.rights !== undefined && this.data.rights !== null) {

					if (this.data.rights.length > 0) {

						const userHasRight = this.data.rights.filter((curRight) => curRight.rights.indexOf("evaluate_cartography") >= 0 && curRight.cartography_id !== undefined && curRight.branch_id !== undefined && parseInt(curRight.cartography_id) === parseInt(cartographyId) && parseInt(curRight.branch_id) === parseInt(branchId));

						if (userHasRight !== undefined && userHasRight.length > 0) {

							resolve(true);

						} else {

							resolve(false);
							reject({
								"error": `User can't evaluate the cartography ${cartographyId} for the branch ${branchId}`,
								"code": 401
							});

						}

					}

				}

			}

		});

	}

	canCreateCartographies (params) {

		return new Promise((resolve, reject) => {

			resolve(this.hasRight("admin_cartographies"));

		});

	}

	// CREATION : 24.03.2022 / SPRINT 43
	hasAdminUsersRight () {

		let userHasAdminUsersRight = false;

		if (this.hasRight("admin_users")) {

			userHasAdminUsersRight = true;

		}

		return userHasAdminUsersRight;

	}

	// CREATION : 27.09.2023 / SPRINT 62
	hasAdminCartographiesRight () {

		let userHasAdminCartographiesRight = false;

		if (this.hasRight("admin_cartographies")) {

			userHasAdminCartographiesRight = true;

		}

		return userHasAdminCartographiesRight;

	}

	// CREATION : 27.09.2023 / SPRINT 62
	hasAdminBusinessActivitiesRight () {

		let userHasAdminBusinessActivitiesRight = false;

		if (this.hasRight("admin_business_activities")) {

			userHasAdminBusinessActivitiesRight = true;

		}

		return userHasAdminBusinessActivitiesRight;

	}

	// CREATION : 27.09.2023 / SPRINT 62
	hasAccessStatisticsRight () {

		let userHasAccessStatisticsRight = false;

		if (this.hasRight("access_statistics")) {

			userHasAccessStatisticsRight = true;

		}

		return userHasAccessStatisticsRight;

	}

	// CREATION : 24.03.2022 / SPRINT 43
	hasCommunicateRight () {

		let userHasCommunicateRight = false;

		if (this.hasRight("communicate")) {

			userHasCommunicateRight = true;

		}

		return userHasCommunicateRight;

	}

	// CREATION : 07.03.2023 / SPRINT 56
	hasAdminRisksRight () {

		let userHasAdminRisksRight = false;

		if (this.hasRight("admin_risks", {"cartography_id": parseInt(cartographies.getCurrentCartographyId())})) {

			userHasAdminRisksRight = true;

		}

		return userHasAdminRisksRight;

	}

	isRPO (idRisk) {

		let isCurrentUserRPO = false;

		if (this.data !== undefined) {

			// We get the current risk data
			const riskData = risks.find(parseInt(idRisk));

			// We get the current risk rpo id
			const riskRpoId = riskData.rpo_id;

			if (
				riskData !== undefined &&
				riskRpoId !== undefined &&
				riskRpoId !== null &&
				parseInt(riskRpoId) === parseInt(this.data.id)
			) {

				isCurrentUserRPO = true;

			}

		}

		return isCurrentUserRPO;


	}

	isRiskEditor (idRisk) {

		let isCurrentUserRiskEditor = false;

		if (this.data !== undefined) {

			// We get the current risk data
			const riskData = risks.find(parseInt(idRisk));

			// We filter risk editors with current user
			const isCurrentUserExistsInRiskEditors = riskData.editors.filter((editor) => parseInt(editor.id) === parseInt(this.data.id));

			if (isCurrentUserExistsInRiskEditors !== undefined && isCurrentUserExistsInRiskEditors.length > 0) {

				isCurrentUserRiskEditor = true;

			}

		}

		return isCurrentUserRiskEditor;


	}

	resetCurrentUserEvaluationRightCache () {

		this.cacheCanEvaluateThisCartographyForThisLegalEntity = undefined;

	}

}
export default new User();
