import router from "Core/router.js";
import debug from "Core/Debug.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import graphGrossRiskAndProgressMargin from "Components/Graphs/GraphGrossRiskAndProgressMargin/GraphGrossRiskAndProgressMargin.js";
import graphSeverityMatrix from "Components/Graphs/GraphSeverityMatrix/GraphSeverityMatrix.js";
import graphPriorityMatrix from "Components/Graphs/GraphPriorityMatrix/GraphPriorityMatrix.js";
import "./Graphs.scss";
import "./GraphsResponsive.scss";


class GraphsContainer {

	init (risks, evaluations) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We define global vars
		this.risks = risks;
		this.evaluations = evaluations;

		// We show the risks
		this.showRisks();

		// We initialize page events
		this.initEvents();

	}

	initEvents () {

		// We listen the window resize
		$(window).on("resize", this.handleResizeWindow.bind(this));

		// We listen click on the graph title
		$(".graph-item .graph-title").on("click", this.handleClickOnGraphTitle.bind(this));

	}

	showGraphs (risks, evaluations) {

		// We define the container of the graphs
		const graphsContainer = "#graphs-container";

		// We clear the container
		$(graphsContainer).html("");

		// We show all the graphs one by one
		graphGrossRiskAndProgressMargin.appendAndFill(graphsContainer, risks, evaluations);
		graphSeverityMatrix.appendAndFill(graphsContainer, risks, evaluations);
		graphPriorityMatrix.appendAndFill(graphsContainer, risks, evaluations);

	}

	showRisks () {

		if (this.risks !== undefined) {

			if (this.risks.length > 0) {

				// We hide the message
				$("#msg-no-risk").removeClass("active");

				// We show the graph
				if ($(".graph-content").attr("class").indexOf("active") < 0) {

					$(".graph-content").addClass("active");

				}

				// We hide all risks
				$("ul.list-risks").html("");

				// We remove all risk cards
				$("#graph-card-risk-active").find(".card-risk").remove();

				// We show the graphs
				this.showGraphs(this.risks, this.evaluations);

			} else {

				// We define html of the message
				const htmlMsgNoRisk = "<div class=\"text\"><i class=\"icon fas fa-info-circle\"></i>&nbsp;&nbsp;</div>";

				// We get current legal entity data
				const curLegalEntityData = legalEntities.find(context.getCurrentLegalEntityId());
				// We get current legal entity name
				const curLegalEntityName = curLegalEntityData.name;

				// We define the message
				const textMessageCartoNoExists = `${$.i18n("msg-carto-no-exists")} <span class="highlight">${curLegalEntityName}</span>`;

				// We show the message
				$("#msg-no-risk").removeClass("active");
				$("#msg-no-risk").html(htmlMsgNoRisk);
				$("#msg-no-risk .text").append(textMessageCartoNoExists);
				$("#msg-no-risk").addClass("active");

				// We hide the graph
				$(".graph-content").removeClass("active");

			}

		}

		// We define html of the message
		const htmlMsgNoRisk = "<div class=\"text\"><i class=\"icon fas fa-info-circle\"></i>&nbsp;&nbsp;</div>";

		// We get current cartography data
		const curCartographyData = cartographies.find(cartographies.getCurrentCartographyId());

		// If there is an 403 error AND at least one admin user for the current cartography
		if (
			this.evaluations.error !== undefined &&
			this.evaluations.error === "403" &&
			curCartographyData.admin_users !== undefined &&
			curCartographyData.admin_users !== null &&
			curCartographyData.admin_users.length > 0
		) {

			// We hide the graph
			$(".graph-content").removeClass("active");

			// We get current cartography name
			const curCartographyName = curCartographyData[`name_${this.currentLang}`];

			// We get the mail of the first admin users of the current cartography
			let curCartographyAdminUserMail;

			if (curCartographyData.admin_users[0].email !== undefined && curCartographyData.admin_users[0].email !== null && curCartographyData.admin_users[0].email !== "") {

				curCartographyAdminUserMail = curCartographyData.admin_users[0].email;

			} else {

				curCartographyAdminUserMail = process.env.CONTACT_EMAIL;

			}

			// We define the message
			let textMessageNoAccessEvaluation;
			if (curCartographyAdminUserMail.indexOf("@") >= 0) {

				textMessageNoAccessEvaluation = `${$.i18n("msg-no-access-evaluation-part-1")} <a href="mailto:${curCartographyAdminUserMail}?subject=${user.data.firstname} ${user.data.lastname} ${$.i18n("msg-no-access-evaluation-subject-mailto")} ${curCartographyName}">${$.i18n("msg-no-access-evaluation-part-2")}</a> ${$.i18n("msg-no-access-evaluation-part-3")}`;

			} else {

				textMessageNoAccessEvaluation = `${$.i18n("msg-no-access-evaluation-part-1")} <a href="${curCartographyAdminUserMail}" target="_blank">${$.i18n("msg-no-access-evaluation-part-2")}</a> ${$.i18n("msg-no-access-evaluation-part-3")}`;

			}

			// We show the message
			$("#msg-no-risk").removeClass("active");
			$("#msg-no-risk").html(htmlMsgNoRisk);
			$("#msg-no-risk .text").append(textMessageNoAccessEvaluation);
			$("#msg-no-risk").addClass("active");

		} else if (
			this.evaluations.error === undefined &&
			this.evaluations.length === 0 ||
			this.evaluations[0].risk_id === null
		) {

			// We hide the graph
			$(".graph-content").removeClass("active");

			// We get current legal entity data
			const curLegalEntityData = legalEntities.find(context.getCurrentLegalEntityId());
			// We get current legal entity name
			const curLegalEntityName = curLegalEntityData.name;

			// We define the message to show
			legalEntities.getBusinessActivities(context.getCurrentLegalEntityId()).then((businessActivities) => {

				// We get the current business activity
				const selectedBusinessActivity = $(".select-custom-name-business-activity .option-selected").attr("data-business-activity-id");

				let textMessage;

				if (
					businessActivities !== undefined &&
					businessActivities.length > 0 &&
					selectedBusinessActivity === "all"
				) {

					textMessage = `${$.i18n("msg-carto-no-exists-all-business-activities")} <span class="highlight">${curLegalEntityName}</span>`;

				} else if (
					businessActivities !== undefined &&
					businessActivities.length > 0 &&
					selectedBusinessActivity !== "all"
				) {

					const curBusinessActivitydata = businessActivities.filter((businessActivity) => parseInt(businessActivity.id) === parseInt(selectedBusinessActivity))[0];

					const curBusinessActivityName = curBusinessActivitydata[`name_${this.currentLang}`];

					textMessage = `${$.i18n("msg-carto-no-exists-one-business-activity-part-1")} <span class="highlight">${curBusinessActivityName}</span>${$.i18n("msg-carto-no-exists-one-business-activity-part-2")} <span class="highlight">${curLegalEntityName}</span>`;

				} else {

					textMessage = `${$.i18n("msg-carto-no-exists")} <span class="highlight">${curLegalEntityName}</span>`;

				}

				// We show the message
				$("#msg-no-risk").removeClass("active");
				$("#msg-no-risk").html(htmlMsgNoRisk);
				$("#msg-no-risk .text").append(textMessage);
				$("#msg-no-risk").addClass("active");

			});

		} else {

			// We hide the message
			$("#msg-no-risk").removeClass("active");

			// We show the graph
			if ($(".graph-content").attr("class").indexOf("active") < 0) {

				$(".graph-content").addClass("active");

			}

		}

	}

	handleResizeWindow () {

		// We show the risks
		this.showRisks();

		// We listen click on the graph title
		$(".graph-item .graph-title").on("click", this.handleClickOnGraphTitle.bind(this));

	}

	handleClickOnGraphTitle (e) {

		const curGraphItem = $(e.currentTarget).parents(".graph-item");

		// If the graph item isn't active
		if (curGraphItem.attr("class").indexOf("active") < 0) {

			// We add the active class to the item
			curGraphItem.addClass("active");

			// We get the height of the graph item
			const curGraphItemHeight = curGraphItem.outerHeight();

			// We get the height of the graph
			const curGraphHeight = curGraphItem.find(".graph-card").outerHeight();

			// We change the height of the graph item
			curGraphItem.css("height", curGraphItemHeight + curGraphHeight);

		} else {

			// We remove the active class to the item
			curGraphItem.removeClass("active");

			// We change the height of the graph item
			curGraphItem.css("height", "100px");

		}

	}

}
export default new GraphsContainer();
