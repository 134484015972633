import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./select.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import legalEntities from "Datapoints/LegalEntities.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import branches from "Datapoints/Branches.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import {showFormErrors, updateObjectWithForm, slugify, getUrlVars} from "Core/helpers.js";
import cartographies from "Datapoints/Cartographies.js";

class BusinessActivitiesSelect extends Page {

	constructor () {

		let routes = [
			"/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/select-business-activities",
			"/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/risks/:idRisk/select-business-activities"
		];

		let datapointsToLoad = [
			context,
			users,
			branches,
			legalEntities,
			cartographies,
			businessActivities
		];

		super(routes, AuthentifiedLayout, "Choix des activités opérationnelles", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEvaluateThisCartography.bind(this)
		];

	}

	canCurrentUserEvaluateThisCartography (params) {

		return new Promise((resolve, reject) => {

			if (user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography})) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		return new Promise((resolve, reject) => {

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.params = params;
			this.urlVars = getUrlVars();

			this.parseAndRender("page", html);

			this.selectedBusinessActivities = [];
			this.formType = null;
			this.legalEntityData = legalEntities.find(context.getCurrentLegalEntityId());

			this.initEvents();

			$("FORM").on("submit", this.handleSubmit.bind(this));

			router.refreshLinks();

			let renderPromises = [];
			renderPromises.push(menu.show());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initEvents () {

		legalEntities.getBusinessActivities(this.params.idLegalEntity).then((data) => {

			// We define the form type
			if (
				data !== undefined && data !== null && data.length > 0 ||
				this.legalEntityData.has_no_business_activity
			) {

				this.formType = "edit";

				// We auto fill the business activity
				this.autoFillBusinessActivity(data);

			} else {

				this.formType = "create";

				// We change the text intro
				const newTextIntroPart1 = $.i18n("admin-business-activities-select-text-intro-alternative-part-1");
				const newTextIntroPart2 = $.i18n("admin-business-activities-select-text-intro-alternative-part-2");
				const htmlNewTextIntro = `${newTextIntroPart1} <span class="highlight"></span>.<br />${newTextIntroPart2}`;
				$(".box-intro .text-intro").html(htmlNewTextIntro);

				// We show current legal entity name
				const curLegalEntity = legalEntities.find(this.params.idLegalEntity);
				$(".box-intro .text-intro .highlight").html(curLegalEntity.name);

			}

		});

		// We show the list of business activities
		this.showBusinessActivitiesList();

		// We get current legal entity data
		const curLegalEntity = legalEntities.find(this.params.idLegalEntity);

		// We show the name of the current legal entity on the title page and on the text intro
		$(".title-page .business-activity").html(curLegalEntity.name);
		$(".box-intro .text-intro .highlight").html(curLegalEntity.name);

		// We listen click on card business activity
		$(".card-business-activity").on("click", this.handleClickOnCardBusinessActivity.bind(this));

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	showBusinessActivitiesList () {

		// We get current legal entity data
		const curLegalEntity = legalEntities.find(this.params.idLegalEntity);

		// We filter business activities by current branch
		const curBranchId = context.getCurrentBranchId();
		const businessActivitiesByCurrentBranch = businessActivities.data.filter((businessActivity) => parseInt(businessActivity.branch_id) === parseInt(curBranchId));

		// We loop on business activities data
		if (businessActivitiesByCurrentBranch !== undefined && businessActivitiesByCurrentBranch.length > 0) {

			// We define the business activity choice "None"
			const htmlItemNone = `
				<li class="full-width-field">
          <div class="card-business-activity card-none" data-business-activity-id="none">
            <div class="title-card-business-activity">
            	${$.i18n("select-business-activity-text-choice-none")}
            	<span class="notice"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;${$.i18n("select-business-activity-notice-choice-none-part-1")} <span class="highlight">${curLegalEntity.name}</span> ${$.i18n("select-business-activity-notice-choice-none-part-2")}</span>
            </div>
            <div class="fake-checkbox">
              <div class="bullet">
                <span class="checkbox-icon fas fa-check"></span>
              </div>
            </div>
          </div>
        </li>`;

			// We add the business activity choice "None"
			$(".list-form-fields").prepend(htmlItemNone);

			for (let iBusinessActivity = 0; iBusinessActivity < businessActivitiesByCurrentBranch.length; iBusinessActivity++) {

				const curBusinessActivity = businessActivitiesByCurrentBranch[iBusinessActivity];
				const curBusinessActivityId = curBusinessActivity.id;
				const curBusinessActivityName = curBusinessActivity[`name_${$.i18n().locale}`];

				const htmlItemBusinessActivity = `
					<li class="full-width-field">
	          <div class="card-business-activity" data-business-activity-id="${curBusinessActivityId}">
	          	<div class="tooltip business-activity-inactive warning">
	          		<i class="icon fas fa-exclamation-triangle"></i>
	              <div class="text">
	              	<span data-i18n="[html]tooltip-message-business-activity-inactive">${$.i18n("tooltip-message-business-activity-inactive")}</span>
	              </div>
	            </div>
	            <div class="title-card-business-activity">${curBusinessActivityName}</div>
	            <div class="fake-checkbox">
	              <div class="bullet">
	                <span class="checkbox-icon fas fa-check"></span>
	              </div>
	            </div>
	          </div>
	        </li>`;

				// We add the business activity to the list
				$(".list-form-fields").append(htmlItemBusinessActivity);

			}

		}

	}

	autoFillBusinessActivity (data) {

		// If the current legal entity have at least one business activity
		if (data !== undefined && data !== null && data.length > 0) {

			for (let iBusinessActivity = 0; iBusinessActivity < data.length; iBusinessActivity++) {

				const curBusinessActivity = data[iBusinessActivity];
				const curBusinessActivityId = parseInt(curBusinessActivity.id);

				// We push the business activity id to the array of selected business activities
				this.selectedBusinessActivities.push(curBusinessActivityId);

				// We select the current business activity card
				$(".card-business-activity").each((index, cardBusinessActivity) => {

					if ($(cardBusinessActivity).attr("data-business-activity-id") == curBusinessActivityId) {

						$(cardBusinessActivity).addClass("active");

					}

			  });

			}

		}


		// If no business activity was selected for the current legal entity
		if (this.legalEntityData.has_no_business_activity) {

			// We define the selected business activities
			this.selectedBusinessActivities = [];

			// We remove the active class on all the business activities cards
			$(".card-business-activity").removeClass("active");

			// We add the inactive class on all the business activities cards
			$(".card-business-activity").addClass("inactive");

			// We remove the inactive class on the none card
			$(".card-business-activity.card-none").removeClass("inactive");

			// We add the active class to the none card
			$(".card-business-activity.card-none").addClass("active");

		}

	}

	handleClickOnCardBusinessActivity (e) {

		// We get the class of the current item
		const curItemClass = $(e.currentTarget).attr("class");

		// We get the id of selected business activity
		const selectedBusinessActivityId = $(e.currentTarget).attr("data-business-activity-id");

		if (
			selectedBusinessActivityId === "none" &&
			curItemClass.indexOf("active") < 0
		) {

			// We remove all the ids on the selected business activities
			this.selectedBusinessActivities = [];

			// We remove the active class on all the business activities cards
			$(".card-business-activity").removeClass("active");

			// We add the inactive class on all the business activities cards
			$(".card-business-activity").addClass("inactive");

			// We remove the inactive class on the selected card
			$(e.currentTarget).removeClass("inactive");

			// We add the active class to the selected card
			$(e.currentTarget).addClass("active");

		} else if (
			selectedBusinessActivityId === "none" &&
			curItemClass.indexOf("active") >= 0
		) {

			// We remove the inactive class on all the business activities cards
			$(".card-business-activity").removeClass("inactive");

			// We remove the active class to the selected card
			$(e.currentTarget).removeClass("active");

		} else if (
			selectedBusinessActivityId !== "none" &&
			curItemClass.indexOf("active") >= 0 &&
			curItemClass.indexOf("inactive") < 0
		) {

			// We get the index of the id in the array of selected business activities
			const selectedBusinessActivityIndex = this.selectedBusinessActivities.indexOf(parseInt(selectedBusinessActivityId));

			// We remove the id to the selected business activities
			if (selectedBusinessActivityIndex >= 0) {

				this.selectedBusinessActivities.splice(selectedBusinessActivityIndex, 1);

			}

			// We remove the active class to the selected card
			$(e.currentTarget).removeClass("active");

		} else if (
			selectedBusinessActivityId !== "none" &&
			curItemClass.indexOf("active") < 0 &&
			curItemClass.indexOf("inactive") < 0
		) {

			// We add the id to the selected business activities
			this.selectedBusinessActivities.push(parseInt(selectedBusinessActivityId));

			// We add the active class to the selected card
			$(e.currentTarget).addClass("active");

		}

	}

	handleClickOnCancelButton () {

		// We redirect to the previous page
		const previousPage = localStorage.getItem("navigation-previousPageUrl");
		if (previousPage !== undefined) {

			router.navigate(previousPage);

		} else {

			router.navigate("/dashboard-grid");

		}

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("edit_business_activities_for_legal_entity");

		const selectedCategory = $("input[name='category']").val();

		// We define the value to send for the params "business_activities_ids"
		let curBusinessActivitiesIds;
		if (this.selectedBusinessActivities.length > 0) {

			curBusinessActivitiesIds = this.selectedBusinessActivities;

		}
		// If the user select "none"
		else if ($("[data-business-activity-id='none']").hasClass("active")) {

			curBusinessActivitiesIds = "none";

		}
		// Otherwise, we send an empty array
		else {

			curBusinessActivitiesIds = [];

		}

		const dataToSend = {
			"legal_entity_id": parseInt(this.params.idLegalEntity),
			"business_activities_ids": curBusinessActivitiesIds
		};

		legalEntities.setBusinessActivities(dataToSend).then(this.editSuccess.bind(this), this.editFail.bind(this));

	}

	editSuccess () {

		debug.v("BusinessActivitiesSelect - editSuccess");

		// We reset the legal entities' business activities cache
		legalEntities.resetBusinessActivitiesCache();
		legalEntities.getBusinessActivities(this.params.idLegalEntity, {"forceRequest": true}).then((data) => {

			if (this.formType === "create") {

				if (this.selectedBusinessActivities.length > 0) {

					// We redirect to the risk evaluation page for business activities
					router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/evaluate-for-business-activities`);

				} else {

					// We redirect to the risk evaluation page for legal entity
					router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/evaluate`);

				}

			} else {

				// We redirect to the previous page
				const previousPage = localStorage.getItem("navigation-previousPageUrl");
				if (previousPage !== undefined) {

					router.navigate(previousPage);

				} else {

					router.navigate("/dashboard-grid");

				}

			}

		});

	}

	editFail (response, textStatus) {

		if (response.status == 422) {

			let errorsToShow = {
				"legal_entity_id": {
					"required": `${$.i18n("form-error-legal-entity-id-required")}`
				},
				"business_activities_ids": {
					"required": `${$.i18n("form-error-business-activities-ids-required")}`
				}
			};

			showFormErrors("#edit_business_activities_for_legal_entity", response, errorsToShow);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new BusinessActivitiesSelect();
