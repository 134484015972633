import router from "Core/router.js";
import {sortUserRightsForProfileDisplay} from "Core/helpers.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./show.html";
import user from "Datapoints/User.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import users from "Datapoints/Users.js";

class CurrentUserShow extends Page {

	constructor () {

		let routes = ["/user/show"];

		super(
			routes, AuthentifiedLayout, "Mes informations", [
				user,
				cartographies,
				legalEntities,
				branches,
				countries,
				users
			],
			[user]
		);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		menu.show();

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		this.parseAndRender("page", html);

		this.currentUser = user.data;

		// We replace the status with it's lib
		this.currentUser.status = this.currentUser.status == "valid" ? "Valide" : "deactivated" ? "Désactivé" : this.currentUser.status;

		this.autoFillPage(this.currentUser, "page");
		router.refreshLinks();

		this.showLegalEntityLib(this.currentUser);
		this.showLanguageLib(this.currentUser);
		this.showNotificationsSettings(this.currentUser);
		this.showRights(this.currentUser);
		this.initContactLink();

		// If we are not in an env with IGG
		if (
			process.env.SHOW_IGG !== undefined &&
			(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
		) {

			// We remove the IGG line
			$("#line-field-igg").remove();

		}

	}

	initContactLink () {

		// We define the link of the contact button
		let linkContactButton;
		if (process.env.CONTACT_EMAIL.indexOf("@") >= 0) {

			linkContactButton = `mailto:${process.env.CONTACT_EMAIL}`;

		} else {

			linkContactButton = process.env.CONTACT_EMAIL;

		}

		// We init the href of the contact link
		$(".content-show-user .text-contact a").attr("href", linkContactButton);

	}

	showLegalEntityLib (currentUser) {

		legalEntities.getAllLegalEntities().then((allLegalEntities) => {

			const dataLegalEntity = allLegalEntities.filter((legalEntity) => parseInt(legalEntity.id) === parseInt(currentUser.legal_entity_id))[0];

			$(".legal_entity_lib").html(dataLegalEntity.name);


		});

	}

	showLanguageLib (currentUser) {

		let languageLib;

		if (currentUser.language === "fr") {

			languageLib = "Français";

		} else if (currentUser.language === "en") {

			languageLib = "Anglais";

		} else {

			languageLib = "Non renseignée";

		}

		$(".language_lib").html(languageLib);

	}

	showNotificationsSettings (currentUser) {

		if (process.env.SEND_EMAIL_NOTIFICATIONS === true || process.env.SEND_EMAIL_NOTIFICATIONS === "true") {

			if (currentUser !== undefined) {

				// We get the notifications email value
				const notificationsByEmail = currentUser.notifications_by_mail;

				if (currentUser !== undefined && notificationsByEmail) {

					// We show the check
					$("#notifications-settings .fake-checkbox").removeClass("inactive");

				}

				// We listen click on notifications checkbox
				$("#notifications-settings .line-field").on("click", this.handleClickOnNotificationsCheckbox.bind(this));

			}

		} else {

			// We remove the notifications by email checkbox
			$("#notifications-field").remove();

		}

	}

	handleClickOnNotificationsCheckbox (e) {

		const checkboxSelector = $(e.currentTarget).find(".fake-checkbox");
		const checkboxInactiveSelector = $(e.currentTarget).find(".fake-checkbox.inactive");

		if (checkboxInactiveSelector.length > 0) {

			// We show the check
			checkboxSelector.removeClass("inactive");

			// We update the value
			this.currentUser.notifications_by_mail = true;

		} else {

			// We hide the check
			checkboxSelector.addClass("inactive");

			// We update the value
			this.currentUser.notifications_by_mail = false;

		}

		// We replace the status
		this.currentUser.status = user.data.status;

		// We send only the required information for update
		const updateData = {
			"id": this.currentUser.id,
			"notifications_by_mail": this.currentUser.notifications_by_mail
		};

		users.updateOne(updateData).then(this.editSuccessNotificationsSettings.bind(this), this.editFailNotificationsSettings.bind(this));

	}

	editSuccessNotificationsSettings () {

		// We load the style of the message
		$("#notifications-settings .msg-field").removeClass("valid");
		$("#notifications-settings .msg-field").removeClass("error");
		$("#notifications-settings .msg-field").addClass("valid");

		// We show the success message
		$("#notifications-settings .msg-field").html(`<span>${$.i18n("msg-success-notifications-settings-update")}</span>`);

	}

	editFailNotificationsSettings () {

		// We load the style of the message
		$("#notifications-settings .msg-field").removeClass("valid");
		$("#notifications-settings .msg-field").removeClass("error");
		$("#notifications-settings .msg-field").addClass("error");

		// We show the success message
		$("#notifications-settings .msg-field").html(`<span>${$.i18n("msg-error-notifications-settings-update")}</span>`);

	}


	showRights (currentUser) {

		if (currentUser.rights == undefined || currentUser.rights.length == 0) {

			// We show the message
			$("#section_user_rights .line-content .tab-result").append("<div class='line-result-no-right'>Aucun droit</div>");

		} else {

			this.showUserRights(currentUser);

		}

	}

	showUserRights (user) {

		// We get the sorted user rights
		const sortedUserRights = sortUserRightsForProfileDisplay(user.rights);

		sortedUserRights.forEach((right, index) => {


			let contextName;

			if (
				right.legal_entity_id !== undefined &&
					right.legal_entity_id !== null &&
					right.branch_id === undefined &&
					right.country_id === undefined
			) {

				// We get the context data
				const dataContext = legalEntities.data.filter((legalEntity) => parseInt(legalEntity.id) === parseInt(right.legal_entity_id))[0];

				// We get the context name
				contextName = dataContext.name;

			} else if (
				right.legal_entity_id === undefined &&
					right.branch_id === undefined &&
					right.country_id !== undefined &&
					right.country_id !== null
			) {

				// We get the context data
				const dataContext = countries.data.filter((country) => parseInt(country.id) === parseInt(right.country_id))[0];

				// We get the context name
				contextName = dataContext[this.currentLang];

			} else if (
				right.legal_entity_id === undefined &&
					right.branch_id !== undefined &&
					right.branch_id !== null &&
					right.country_id === undefined
			) {

				// We get the context data
				const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(right.branch_id))[0];

				// We get the context name
				contextName = dataContext.fr;

			} else if (
				right.cartography_id !== undefined &&
					right.rights.indexOf("admin_risks") >= 0
			) {

				const cartographyData = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

				// We get the context data
				const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(cartographyData.branch_id))[0];

				// We get the context name
				contextName = dataContext.fr;

			}

			// We get the data of the cartography
			const dataCartography = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

			// We get the cartography name
			let cartographyName;
			let htmlSeparator;
			if (dataCartography !== undefined) {

				cartographyName = dataCartography[`name_${this.currentLang}`];

				htmlSeparator = "&nbsp;&nbsp;|&nbsp;&nbsp;";

			} else {

				cartographyName = "";

				htmlSeparator = "";

			}

			// We get the class of icon of the new right
			const classIconRight = this.getClassIconRight(right.rights[0]);

			// We get the lib of the new right
			const libRight = this.getLibRight(right.rights[0]);

			// We define the html of the line of right
			const htmlLineRight = `<div class="line-result"><div class="info-context"><span class="name-context">${contextName}</span>${htmlSeparator}<span class="name-cartography">${cartographyName}</span></div><div class="other-info"><div class="label-right"><span class="icon fas ${classIconRight}"></span><span class="text">${libRight}</span></div></div></div>`;

		    // We show the line of right
			$("#section_user_rights .tab-result").prepend(htmlLineRight);

		});

	}

	getClassIconRight (right) {

		let classIconRight;

		if (right === "view_evaluation") {

			classIconRight = "fa-eye";

		} else if (right === "evaluate_cartography") {

			classIconRight = "fa-star";

		} else if (right === "admin_users") {

			classIconRight = "fa-users-cog";

		} else if (right === "communicate") {

			classIconRight = "fa-comments";

		} else if (right === "manage_actions_plan") {

			classIconRight = "fa-clipboard-check";

		} else if (right === "view_actions_plan") {

			classIconRight = "fa-mask";

		} else if (right === "admin_risks") {

			classIconRight = "fa-chalkboard-teacher";

		} else if (right === "access_statistics") {

			classIconRight = "fa-chart-pie";

		} else if (right === "admin_cartographies") {

			classIconRight = "fa-cogs";

		} else if (right === "admin_business_activities") {

			classIconRight = "fa-building";

		} else {

			classIconRight = "fa-exclamation-triangle";

		}

		return classIconRight;

	}

	getLibRight (right) {

		let libRight;

		if (right === "view_evaluation") {

			libRight = $.i18n("form-label-field-right-view");

		} else if (right === "evaluate_cartography") {

			libRight = $.i18n("form-label-field-right-evaluate");

		} else if (right === "admin_users") {

			libRight = $.i18n("form-label-field-right-admin-users");

		} else if (right === "communicate") {

			libRight = $.i18n("form-label-field-right-communicate");

		} else if (right === "manage_actions_plan") {

			libRight = $.i18n("form-label-field-right-manage-action-plans");

		} else if (right === "view_actions_plan") {

			libRight = $.i18n("form-label-field-right-view-action-plans");

		} else if (right === "admin_risks") {

			libRight = $.i18n("form-label-field-right-manage-cartography");

		} else if (right === "access_statistics") {

			libRight = $.i18n("form-label-field-right-view-statistics");

		} else if (right === "admin_cartographies") {

			libRight = $.i18n("form-label-field-right-admin-cartographies");

		} else if (right === "admin_business_activities") {

			libRight = $.i18n("form-label-field-right-admin-business-activities");

		} else {

			libRight = $.i18n("form-label-field-right-other");

		}

		return libRight;

	}

}
export default new CurrentUserShow();
