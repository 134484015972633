import router from "./router.js";
import user from "Datapoints/User.js";
import loader from "Components/Loader/Loader.js";

export default class API {

	static call (settings, shouldRedirectOn401 = true, shouldShowLoader = true) {

		// Before we answer, we set the loader on the content
		if (shouldShowLoader) {

			loader.show();

		}

		/*
		 * If the data has an empty array or is undefined, we have to set the property to null so jQuery send something, otherwise it doesn't send the property
		 * https://stackoverflow.com/questions/6622224/jquery-removes-empty-arrays-when-sending
		 * https://stackoverflow.com/questions/23334331/post-an-empty-array-using-ajax-in-jquery
		 * 'traditional: true' do not work
		 */
		for (const prop in settings.data) {

			if (_.isArray(settings.data[prop]) && settings.data[prop].length == 0) {

				settings.data[prop] = null;

			} else if (settings.data[prop] == undefined) {

				settings.data[prop] = null;

			}

		}

		// We prefix the base_url and /api to requests
		$.ajaxPrefilter((options) => {

			options.url = `${location.protocol}//${location.hostname}/api${settings.url}`;

		});
		let call = $.ajax(settings);

		call.done((response) => {

			if (shouldShowLoader) {

				loader.hide();

			}

		});

		call.fail((jqXHR, textStatus) => {

			loader.hide();

			// If we catch a 401, we redirect the visitor
			if (jqXHR.status == 401) {

				// We get 401 informations to update user's informations
				user.hasBeenAuthBySSO = jqXHR.responseJSON.user_is_auth_with_sso;
				user.isAllowedOnRMP = jqXHR.responseJSON.user_allowed_on_rmp;

				if (shouldRedirectOn401) {

					user.redirectUnauthorizedUser();

				}

			}

		});

		return call;

	}

}
