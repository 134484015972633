import router from "Core/router.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import actionsPlan from "Datapoints/ActionsPlan.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import risks from "Datapoints/Risks.js";
import html from "./action.html";
import "./Action.scss";

class Action {

	constructor (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We define current legal entity ID
		this.curLegalEntityId = context.getCurrentLegalEntityId();

		// We define current cartography ID
		this.curCartographyId = cartographies.getCurrentCartographyId();

		// We define current risk ID
		this.curRiskId = params.risk_id;

		// We get risk data
		this.risk = risks.find(this.curRiskId);

		// We define current action ID
		this.curActionId = params.id;

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.curRiskId);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.curRiskId);

		// We define if the current user has the admin risks right
		this.isCurrentUserAdminRisks = false;
		const userRightsFilteredOnAdminRisks = user.data.rights.filter((right) => parseInt(right.cartography_id) === parseInt(this.curCartographyId) && right.rights.indexOf("admin_risks") >= 0);

		if (
			userRightsFilteredOnAdminRisks !== undefined &&
			userRightsFilteredOnAdminRisks !== null &&
			userRightsFilteredOnAdminRisks.length > 0
		) {

			this.isCurrentUserAdminRisks = true;

		}

		// We define if the current user has the evaluate right
		this.isCurrentUserEvaluateCartography = user.canEvaluateCartography();

		// We define if the current user has the view action plans right
		this.isCurrentUserViewActionPlans = user.canViewActionPlans();

		// We define if the current user has the manage action plans right
		this.isCurrentUserManageActionPlans = user.canManageActionPlans();


		// We define the params for the actions plan
	  this.actionsPlanParams = {
 			"idCartography": parseInt(this.curCartographyId),
 			"idLegalEntity": parseInt(this.curLegalEntityId)
		};

	}

	appendAndFill (cssSelector, data, state) {

		// We define if the action is deactivated
		let isDeactivatedAction = false;
		if (data.status === "deactivated") {

			isDeactivatedAction = true;

		} else {

			isDeactivatedAction = false;

		}

		// We init the action
		$(cssSelector).prepend(html);

		// We get the current action
		const curAction = $(cssSelector).find(".line-action").first();

		// We show the action title
		curAction.find(".title-action").html(data[`title_${this.currentLang}`]);

		// We show the action goal
		curAction.find(".goal-action .text").html(data[`goal_${this.currentLang}`]);

		// We clean content
		curAction.find(".content").html("");

		if (state !== undefined) {

			// We get the date
			let actionDateToShow = false;
			let actionDateText;
			let actionDate;
			if (state.status === "done" && state.done_at !== undefined && state.done_at !== null) {

				actionDateToShow = true;
				actionDateText = $.i18n("action-date-text-done-at");
				actionDate = state.done_at;

			} else if (state.status === "todo" && state.should_be_done_at !== undefined && state.should_be_done_at !== null) {

				actionDateToShow = true;
				actionDateText = $.i18n("action-date-text-should-be-done-at");
				actionDate = state.should_be_done_at;

			} else {

				actionDateToShow = false;

			}

			// If the date must be shown
			if (actionDateToShow) {

				const actionDateDay = `0${new Date(actionDate).getDate()}`.slice(-2);
				const actionDateMonth = `0${new Date(actionDate).getMonth() + 1}`.slice(-2);
				const actionDateYear = new Date(actionDate).getFullYear();
				const actionFullDate = `${actionDateDay}.${actionDateMonth}.${actionDateYear}`;

				// We define the html
				const htmlActionDate = `<span class="content-date"><i class="icon far fa-calendar-alt"></i>&nbsp;&nbsp;${actionDateText} ${actionFullDate}</span><br /><br />`;

				// We show the date
				curAction.find(".content").append(htmlActionDate);

			}

		}


		// We show the action content
		this.showActionContent(curAction, data);

		// We load the action comment
		this.showActionComment(curAction, state);

		// We add the action id
		curAction.attr("data-action-id", data.id);

		// We add the risk id
		curAction.attr("data-risk-id", data.risk_id);

		// We manage the admin buttons display
		this.displayAdminButtons(curAction, state);

		// We add a target _blank in all description links
		$(curAction).find(".content a").attr("target", "_blank");

		// We add a http:// prefix in all description links
		$(curAction).find(".content a").each(function () {

			const curHrefLink = $(this).attr("href");
			const prefixUrl = "https://";

			if (curHrefLink.indexOf("http://") === -1 && curHrefLink.indexOf("https://") === -1) {

				$(this).attr("href", prefixUrl.concat(curHrefLink));

			}

		});

		// We show the state of the action
		if (this.isCurrentUserManageActionPlans || this.isCurrentUserViewActionPlans) {

			if (window.location.pathname.indexOf("/actions-plan/list") >= 0) {

				this.showActionStateOnActionPlanSummary(curAction, state);

			} else {

				this.showActionState(curAction, state);

			}

		}

		// We manage the deactivated and not adopted action
		if (isDeactivatedAction) {

			// We remove config buttons
			curAction.find(".btn-adopt").remove();
			curAction.find(".btn-ignore").remove();

			// We replace the icon of the deactivate button
			curAction.find(".btn-deactivate .icon").removeClass("fa-ban");
			curAction.find(".btn-deactivate .icon").addClass("fa-check");

			// We change the title of the deactivate button
			curAction.find(".btn-deactivate").attr("title", $.i18n("button-activate"));

			if (state === undefined) {

				// We add the status on the line action container
				curAction.removeClass("status-to-consider");
				curAction.addClass("status-deactivated");

				// We move the line action in last position of the list
				$(".line-action").last().after(curAction);

				// If the button to show the deactivated actions is not present
				if ($("#btn-show-deactivated-actions").length < 1) {

					// We define the html of the button
					const htmlBtnToShowDeactivatedActions = `
						<div class="line-deactivated-actions">
							<div class="sep"></div>
							<div id="btn-show-deactivated-actions">
								<span class="icon fas fa-eye"></span>
								<span class="text" data-i18n="button-show-deactivated-actions">${$.i18n("button-show-deactivated-actions")}</span>
							</div>
							<div class="sep"></div>
						</div>
					`;

					// We show the button
					if ($(".line-action.status-deactivated:first-child").length > 0) {

						$(htmlBtnToShowDeactivatedActions).insertBefore(".line-action.status-deactivated:first-child");

					} else {

						$(htmlBtnToShowDeactivatedActions).insertBefore(".line-action.status-deactivated");

					}

				}

			}

		}

		// We show the action
		curAction.addClass("loaded");

	}

	showActionContent (cssSelector, data) {

		// We define the html of the content block
		const htmlBoxComment = `
				<div class="box-content">
					<div class="title-content">
						<span class="icon fas fa-file-alt"></span>
					</div>
					<div class="content-text">${data[`content_${this.currentLang}`]}</div>
				</div>
			`;

		// We show the comment in the action
		$(cssSelector).find(".content").append(htmlBoxComment);

		// We add a target _blank in all comment links
		$(cssSelector).find(".content-comment a").attr("target", "_blank");

		// We add a http:// prefix in all comment links
		$(cssSelector).find(".content-comment a").each(function () {

			const curHrefLink = $(this).attr("href");
			const prefixUrl = "http://";

			if (curHrefLink.indexOf("http://") === -1) {

				$(this).attr("href", prefixUrl.concat(curHrefLink));

			}

		});

	}

	showActionComment (cssSelector, state) {

		if (state !== undefined) {

			// We define the html of the button to show comments
			const htmlBtnShowComments = `
				<div class="btn-show-actions-comments">
					<span class="icon fas fa-comment"></span>
					<span class="text" data-i18n="button-show-comments">${$.i18n("button-show-comments")}</span>
				</div>
			`;

			// We define the comment to show
			let commentToShow;
			if (
				state[`comment_${this.currentLang}`] !== undefined &&
				state[`comment_${this.currentLang}`] !== null &&
				state[`comment_${this.currentLang}`] !== ""
			) {

				commentToShow = state[`comment_${this.currentLang}`].replace(/\n/g, "<br />");


			} else if (this.currentLang === "fr" && state.comment_en !== undefined && state.comment_en !== null) {

				commentToShow = state.comment_en.replace(/\n/g, "<br />");

			} else if (this.currentLang === "en" && state.comment_fr !== undefined && state.comment_fr !== null) {

				commentToShow = state.comment_fr.replace(/\n/g, "<br />");

			}

			if (commentToShow !== undefined) {


				// If the comment button doesn't exist in the container
				if ($("#section-actions-risk .line-top").find(".btn-show-actions-comments").length < 1) {

					// We show the button
					$("#section-actions-risk .line-top").append(htmlBtnShowComments);

				}

				// We define the html of the comment block
				const htmlBoxComment = `
					<div class="box-comment">
						<div class="title-comment">
							<span class="icon fas fa-comment"></span>
							<span class="text" data-i18n="form-label-field-comment">${$.i18n("form-label-field-comment")}</span>
						</div>
						<div class="content-comment">${commentToShow}</div>
					</div>
				`;

				// We show the comment in the action
				$(cssSelector).find(".content").append(htmlBoxComment);

				// We add a target _blank in all comment links
				$(cssSelector).find(".content-comment a").attr("target", "_blank");

				// We add a http:// prefix in all comment links
				$(cssSelector).find(".content-comment a").each(function () {

					const curHrefLink = $(this).attr("href");
					const prefixUrl = "http://";

					if (curHrefLink.indexOf("http://") === -1) {

						$(this).attr("href", prefixUrl.concat(curHrefLink));

					}

				});

			}

		}

	}

	displayAdminButtons (cssSelector, state) {

		this.displayConfigButtons(cssSelector, state);
		this.displayEditButton(cssSelector);

		if (window.location.pathname.indexOf("/actions-plan/list") < 0) {

			this.displayDeleteButton(cssSelector);
			this.displayDeactivateButton(cssSelector);

		}

	}

	displayConfigButtons (cssSelector, state) {

		if (this.isCurrentUserManageActionPlans) {

			// We save the current page url on the local storage
			localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

			if (state !== undefined) {

				// We get the status of the current action
				const curActionStatus = state.status;

				// We show config buttons
				this.showConfigButtons(cssSelector, curActionStatus);

			} else {

				// We show config buttons
				this.showConfigButtons(cssSelector, "to-consider");

			}

		}

	}

	showConfigButtons (cssSelector, actionStatus) {

		// We define the config buttons html
		let htmlConfigButtons = "";

		// We define the html for adopt button
		const htmlAdoptButton = `
			<div class="btn-admin-config btn-adopt">
				<span class="icon fas fa-plus-circle"></span>
				<span class="text" data-i18n="btn-action-status-adopt">${$.i18n("btn-action-status-adopt")}</span>
			</div>
		`;

		// We define the html for status update button
		const htmlStatusUpdateButton = `
			<div class="btn-admin-config btn-status-update">
				<span class="icon fas fa-edit"></span>
				<span class="text" data-i18n="btn-action-status-update">${$.i18n("btn-action-status-update")}</span>
			</div>
		`;

		// We define the html for ignored button
		const htmlIgnoreButton = `
			<div class="btn-admin-config btn-ignore">
				<span class="icon fas fa-minus-circle"></span>
				<span class="text" data-i18n="btn-action-status-ignored">${$.i18n("btn-action-status-ignored")}</span>
			</div>
		`;

		if (actionStatus === "todo" || actionStatus === "done") {

			htmlConfigButtons = htmlStatusUpdateButton;

		} else if (actionStatus === "ignored") {

			htmlConfigButtons = htmlAdoptButton;

		} else {

			htmlConfigButtons = htmlAdoptButton + htmlIgnoreButton;

		}

		// We show the config buttons
		$(cssSelector).find(".admin-buttons").prepend(htmlConfigButtons);

	}

	displayDeactivateButton (cssSelector) {

		if (this.isCurrentUserRPO || this.isCurrentUserAdminRisks) {

			// We define the deactivate button
			let htmlDeactivateButton = "";

			htmlDeactivateButton = `
				<div class="btn-admin-action btn-deactivate" title="${$.i18n("button-deactivate")}">
					<span class="icon fas fa-ban"></span>
				</div>
			`;

			// We show the deactivate button
			$(cssSelector).find(".admin-buttons").append(htmlDeactivateButton);

		}

	}

	displayDeleteButton (cssSelector) {

		if (this.isCurrentUserRiskEditor || this.isCurrentUserRPO || this.isCurrentUserAdminRisks) {

			// We define the delete button
			let htmlDeleteButton = "";

			htmlDeleteButton = `
				<div class="btn-admin-action btn-delete" title="${$.i18n("button-delete")}">
					<span class="icon fas fa-trash-alt"></span>
				</div>
			`;

			// We show the delete button
			$(cssSelector).find(".admin-buttons").append(htmlDeleteButton);

		}

	}

	displayEditButton (cssSelector) {

		if (this.isCurrentUserRiskEditor || this.isCurrentUserRPO || this.isCurrentUserAdminRisks) {

			// We define the edit button
			let htmlEditButton = "";

			// We save the current page url on the local storage
			localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

			htmlEditButton = `
				<div class="btn-admin-action btn-edit" title="${$.i18n("button-update")}">
					<span class="icon fas fa-pencil-alt"></span>
				</div>
			`;

			// We show the edit button
			$(cssSelector).find(".admin-buttons").append(htmlEditButton);

		}

	}

	showActionState (cssSelector, state) {

		if (state !== undefined) {

			// We get the status of the current action
			const curActionStatus = state.status;

			// We add the status on the line action container
			$(cssSelector).addClass(`status-${curActionStatus}`);

			// We organize actions according to their status
			if (curActionStatus === "todo") {

				// If there is an action with the status "to consider"
				if ($(".list-actions").find(".line-action.status-to-consider").length > 0) {

					// We move the line action after the last action with the status "to consider"
					$(".line-action.status-to-consider").last().after(cssSelector);

				} else {

					// We move the line action in first of the list
					$(cssSelector).insertBefore(".line-action:first-child");

				}


			} else if (curActionStatus === "done") {

				// If there is an action with the status "to consider" and no action with the status "todo"
				if (
					$(".list-actions").find(".line-action.status-to-consider").length > 0 &&
					$(".list-actions").find(".line-action.status-todo").length <= 0
				) {

					// We move the line action after the last action with the status "to consider"
					$(".line-action.status-to-consider").last().after(cssSelector);

				}
				// Else if there is an action with the status "todo"
				else if ($(".list-actions").find(".line-action.status-todo").length > 0) {

					// We move the line action after the last action with the status "todo"
					$(".line-action.status-todo").last().after(cssSelector);

				} else {

					// We move the line action in first of the list
					$(cssSelector).insertBefore(".line-action:first-child");

				}

			} else if (curActionStatus === "ignored") {

				// If there is an action with the status "to consider" and no action with the status "todo" or the status "done"
				if (
					$(".list-actions").find(".line-action.status-to-consider").length > 0 &&
					$(".list-actions").find(".line-action.status-todo").length <= 0 &&
					$(".list-actions").find(".line-action.status-done").length <= 0
				) {

					// We move the line action after the last action with the status "to consider"
					$(".line-action.status-to-consider").last().after(cssSelector);

				}
				// Else if there is an action with the status "todo" and no action with the statut "done"
				else if (
					$(".list-actions").find(".line-action.status-todo").length > 0 &&
					$(".list-actions").find(".line-action.status-done").length <= 0
				) {

					// We move the line action after the last action with the status "todo"
					$(".line-action.status-todo").last().after(cssSelector);

				}
				// Else if there is an action with the status "done"
				else if ($(".list-actions").find(".line-action.status-done").length > 0) {

					// We move the line action after the last action with the status "done"
					$(".line-action.status-done").last().after(cssSelector);

				} else {

					// We move the line action in first of the list
					$(cssSelector).insertBefore(".line-action:first-child");

				}

			} else {

				// Do nothing

			}

		} else {

			// We add the status on the line action container
			$(cssSelector).addClass("status-to-consider");

			// We move the line action in first position of the list
			$(cssSelector).insertBefore(".line-action:first-child");

		}

	}

	showActionStateOnActionPlanSummary (cssSelector, state) {

		if (state !== undefined) {

			// We get the status of the current action
			const curActionStatus = state.status;

			// We add the status on the line action container
			$(cssSelector).addClass(`status-${curActionStatus}`);

		} else {

			// We add the status on the line action container
			$(cssSelector).addClass("status-to-consider");

		}

	}

}
export default Action;
