import html from "./scenario.html";
import scenarios from "Datapoints/Scenarios";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import Popin from "Components/Popin/Popin.js";

class Scenario {

	appendAndFill (cssSelector, data, isAdminButtons) {

		// We show the scenario
		$(cssSelector).append(html);

		// We define the scenario
		const curScenario = $(cssSelector).find(".item-link").last();

		// We add the id
		curScenario.attr("data-item-id", data.id);

		// We add the link
		curScenario.find("a.desc-link").attr("href", data.file_url);

		// We define and add the icon
		const curClassIcon = this.getLinkIcon(data.file_url);
		curScenario.find("a.desc-link .icon").addClass(`fa-${curClassIcon}`);

		// We add the name
		curScenario.find("a.desc-link .text").html(data.name);

		// We add the filename
		const curFilename = data.file_url.replace(/^.*[\\\/]/, "");
		curScenario.find("a.desc-link .infos-link .filename").html(curFilename);

		// We add the author name
		const authorData = data.author;
		if (authorData != null) {

			const htmlLineAuthor = `${$.i18n("text-add-item-by")} <span class="highlight">${authorData.firstname} ${authorData.lastname}</span>`;
			curScenario.find("a.desc-link .infos-link .author .name").html(htmlLineAuthor);

		} else {

			curScenario.find("a.desc-link .infos-link .author").html("");

		}

		// We add the admin buttons
		if (isAdminButtons) {

			const htmlAdminButtons = "<div class=\"admin-buttons\"><div class=\"btn-admin btn-edit\" title=\"Modifier cet exemple\"><span class=\"icon far fa-pencil-alt\"></span></div><div class=\"btn-admin btn-delete\" title=\"Supprimer cet exemple\"><span class=\"icon far fa-trash-alt\"></span></div></div>";

			curScenario.append(htmlAdminButtons);

		}

		// We trigger the events on button clicks
		curScenario.find(".btn-edit").on("click", () => {

			$(this).trigger("edit", [data.id]);

		});
		curScenario.find(".btn-delete").on("click", () => {

			$(this).trigger("delete", [data.id]);

		});

	}

	getLinkIcon (urlPath) {

		let classIcon;

		if (urlPath.indexOf(".pdf") > -1) {

			classIcon = "file-pdf";

		} else if (urlPath.indexOf(".xls") > -1 || urlPath.indexOf(".xlsx") > -1) {

			classIcon = "file-excel";

		} else if (urlPath.indexOf(".csv") > -1) {

			classIcon = "file-csv";

		} else if (urlPath.indexOf(".zip") > -1) {

			classIcon = "file-archive";

		} else if (urlPath.indexOf(".mp3") > -1 || urlPath.indexOf(".aiff") > -1 || urlPath.indexOf(".wav") > -1 || urlPath.indexOf(".ogg") > -1) {

			classIcon = "file-audio";

		} else if (urlPath.indexOf(".json") > -1 || urlPath.indexOf(".js") > -1) {

			classIcon = "file-code";

		} else if (urlPath.indexOf(".jpg") > -1 || urlPath.indexOf(".jpeg") > -1 || urlPath.indexOf(".png") > -1 || urlPath.indexOf(".gif") > -1) {

			classIcon = "file-image";

		} else if (urlPath.indexOf(".ppt") > -1 || urlPath.indexOf(".pptx") > -1) {

			classIcon = "file-powerpoint";

		} else if (urlPath.indexOf(".doc") > -1 || urlPath.indexOf(".docx") > -1) {

			classIcon = "file-word";

		} else if (urlPath.indexOf(".mp4") > -1 || urlPath.indexOf(".mov") > -1 || urlPath.indexOf(".avi") > -1 || urlPath.indexOf(".mpeg") > -1 || urlPath.indexOf(".mkv") > -1 || urlPath.indexOf(".wmv") > -1) {

			classIcon = "file-video";

		} else {

			classIcon = "link";

		}

		return classIcon;

	}

}
export default Scenario;
