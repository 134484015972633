import debug from "Core/Debug.js";
import router from "Core/router.js";
import helpMenu from "Components/HelpMenu/HelpMenu.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import helpFirstActionBanner from "Components/HelpFirstActionBanner/HelpFirstActionBanner.js";
import HelpItem from "./HelpItem.js";

class HelpItemContainer {

	load (userHelpItems) {

		// We define the global var for user help items
		this.userHelpItems = userHelpItems;

		// We show the help items
		if (this.userHelpItems !== undefined && this.userHelpItems.length > 0) {

			this.showHelpItems();

		}

	}

	showHelpItems () {

		// For each user help items
		this.userHelpItems.forEach((itemData, index) => {

			// We define the new help item
			const newHelpItem = new HelpItem(itemData);

			// We show the new help item
			newHelpItem.appendAndFill("#help-sub-menu ul.list-items", itemData);

		});

		// We listen click on help item
		$("#help-sub-menu ul.list-items li.item").on("click", this.handleClickOnHelpItem.bind(this));

	}

	handleClickOnHelpItem (e) {

		// We define the clicked item
		const curHelpItem = $(e.currentTarget);

		// We get the clicked item id
		const curHelpItemId = curHelpItem.attr("data-item-id");

		// We remove the help first action banner
		helpFirstActionBanner.remove();

		// We get the clicked help item data
		this.curHelpItemData = this.userHelpItems.filter((userHelpItem) => userHelpItem.id === curHelpItemId)[0];

		// We hide the active help button menu
		helpMenu.hideHelpButtonMenu();

		// We hide the help sub menu
		helpMenu.hideHelpSubMenu();

		// We update the local storage informations
		helpSequence.updateLocalStorageInfos(curHelpItemId, 0);

		if (window.location.pathname === this.curHelpItemData.steps[0].step_url) {

			// We init the help sequence
			helpSequence.init();

		} else {

			// We go to the page url of the first step
			router.navigate(this.curHelpItemData.steps[0].step_url);

		}

	}

}
export default new HelpItemContainer();
