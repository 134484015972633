import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import actionContainer from "Components/Action/ActionContainer.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import html from "./list.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import actions from "Datapoints/Actions.js";
import actionsPlan from "Datapoints/ActionsPlan.js";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import "./List.scss";
import "./ListPrint.scss";


class ActionsPlanList extends Page {

	constructor () {

		let routes = [
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/actions-plan/list",
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/actions-plan/list"
		];

		super(routes, AuthentifiedLayout, "Synthèse du plan d'action", [
			users,
			risks,
			actionsPlan,
			legalEntities,
			cartographies,
			profitCenters,
			branches,
			countries,
			businessActivities,
			context
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserShowThisPage.bind(this)
		];

	}

	canCurrentUserShowThisPage () {

		return new Promise((resolve, reject) => {

			if (user.canViewActionPlans() || user.canManageActionPlans()) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We get the params
		this.params = params;

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We get the current cartography ID
		this.curCartographyId = params.idCartography;

		// We get the current legal entity ID
		this.curLegalEntityId = params.idLegalEntity;

		// We get the current risk ID
		this.curRiskId = params.idRisk;

		// We get the actions plan data for the current couple cartography / legal entity
		this.actionsPlan = [];
		if (actionsPlan.data !== undefined) {

			if (
			    actionsPlan.data.actionsPlan !== undefined &&
			    actionsPlan.data.actionsPlan !== null &&
			    actionsPlan.data.actionsPlan.length > 0
			) {

				this.actionsPlan = actionsPlan.data.actionsPlan;

			}

		}

		// We show the active class on sort button
		$(".line-buttons-sort .box-sort").addClass("active");

		// We init global events
		this.initEvents();

	}

	initEvents () {

		// By default, we init actions sort by status
		this.initActionsSortByStatus();

		// We listen to click on the button to sort by status
		$("#btn-sort-status").on("click", this.handleClickOnBtnSortByStatus.bind(this));

		// We listen to click on the button to sort by risk
		$("#btn-sort-risk").on("click", this.handleClickOnBtnSortByRisk.bind(this));

	}

	initActionsSortByStatus () {

		// We define the status sections of actions
		this.actionsTodo = [];
		this.actionsDone = [];
		this.actionsIgnored = [];
		this.actionsToDefine = [];

		// We load the actions of the current cartography
		actions.getActionsCartography(this.curCartographyId).then((actionsCartography) => {

			// We show the print button
			if (
				actionsCartography !== undefined &&
				actionsCartography.length > 0 &&
				$("#btn-print").length <= 0
			) {

				this.showPrintButton();

			}

			// If we are on the summary filtered by risk
			if (this.curRiskId !== undefined) {

				const actionsRisk = actionsCartography.filter((action) => parseInt(action.risk_id) === parseInt(this.curRiskId));

				if (actionsRisk !== undefined && actionsRisk.length > 0) {

					// We load the actions sort by status
					this.loadActionsSortByStatus(actionsRisk);

					// Then, we init the actions sort by risk
					this.actionsToShowOnRisks = [];
					this.loadActionsSortByRisk(actionsRisk);

					// We remove the active class on sort button
					$(".line-buttons-sort .box-sort").removeClass("active");


				} else {

					// We show the no action message
					this.showNoActionMessage();

				}

				// We init the events of the summary filtered by risk
				this.initSummaryFilteredByRisk();

			} else {

				// We load all the actions of the current cartography
				this.loadActionsSortByStatus(actionsCartography);

			}

		});

	}

	showPrintButton () {

		// We define the html for the print button
		const htmlPrintButton = `
			<div class="line-buttons-top">
				<div class="button-top" id="btn-print">
					<span class="icon fas fa-print"></span>
					<span class="text" data-i18n="risk-label-btn-print">${$.i18n("risk-label-btn-print")}</span>
				</div>
			</div>
		`;

		// We update the sort buttons container size
		$("#container-sort-buttons").addClass("full-width");

		// We show the print button
		$(htmlPrintButton).insertBefore("#container-sort-buttons");

		// We listen the click on the print button
		$("#btn-print").on("click", () => {

			window.print();

		});

	}

	initActionsSortByRisk () {

		// We define the actions to show on risks
		this.actionsToShowOnRisks = [];

		// We load the actions of the current cartography
		actions.getActionsCartography(this.curCartographyId).then((actionsCartography) => {

			// If we are on the summary filtered by risk
			if (this.curRiskId !== undefined) {

				const actionsRisk = actionsCartography.filter((action) => parseInt(action.risk_id) === parseInt(this.curRiskId));

				if (actionsRisk !== undefined && actionsRisk.length > 0) {

					// We load only the actions of the current risk
					this.loadActionsSortByRisk(actionsRisk);

				} else {

					// We show the no action message
					this.showNoActionMessage();

				}

				// We init the events of the summary filtered by risk
				this.initSummaryFilteredByRisk();

			} else {

				// We load all the actions of the current cartography
				this.loadActionsSortByRisk(actionsCartography);

			}

		});

	}

	initSummaryFilteredByRisk () {

		// We show the btn back to the risk
		$("#btn-back-action-plan-summary").addClass("active");

		// We listen click on the back button
		$("#btn-back-action-plan-summary").on("click", this.handleClickOnBtnBackRisk.bind(this));

		// We get the current risk data
		const curRiskData = risks.find(this.curRiskId);

		// We change the page title
		$(".title-page h1").html(`<span class="text" data-i18n="title-page-action-plan-summary-for-risk">${$.i18n("title-page-action-plan-summary-for-risk")}</span>`);


		// We define the risk title
		let curRiskTitle;
		if (curRiskData[`title_${this.currentLang}`] !== "" && curRiskData[`title_${this.currentLang}`] !== null) {

			curRiskTitle = curRiskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && curRiskData.title_en !== null) {

			curRiskTitle = curRiskData.title_en;

		} else if (this.currentLang === "en" && curRiskData.title_fr !== null) {

			curRiskTitle = curRiskData.title_fr;

		} else {

			curRiskTitle = "";

		}

		// We show the risk title
		$(".title-page h1").append(`<span class="risk-title">${curRiskTitle}</span>`);

	}

	loadActionsSortByStatus (curActions) {

		// We clear the container
		$("#summary-container").html("");

		// We switch the active class on sort buttons
		$("#btn-sort-status").addClass("active");
		$("#btn-sort-risk").removeClass("active");

		if (curActions !== undefined && curActions.length > 0) {

			$(curActions).each((i, action) => {

				// We check if the current action exist in the action plan (= has a status already defined)
				let curActionInActionPlan;
				if (this.actionsPlan !== undefined && this.actionsPlan.length > 0) {

					curActionInActionPlan = this.actionsPlan.filter((actionPlan) => parseInt(actionPlan.action_id) === parseInt(action.id))[0];

				}

				// We don't show deactivated action except if the deactivated action is adopted (= status done or todo in action plan)
				if (
					action.status !== "deactivated" ||
					action.status === "deactivated" && curActionInActionPlan !== undefined && curActionInActionPlan.status === "done" ||
					action.status === "deactivated" && curActionInActionPlan !== undefined && curActionInActionPlan.status === "todo"
				) {

					if (curActionInActionPlan !== undefined) {

						// We get the status
						const actionStatus = curActionInActionPlan.status;

						if (actionStatus === "todo") {

							// We add the action to the TODO actions
							this.actionsTodo.push(action);

						} else if (actionStatus === "done") {

							// We add the action to the DONE actions
							this.actionsDone.push(action);

						} else if (actionStatus === "ignored") {

							// We do nothing for an ignored action

						} else {

							// We add the action to the TO DEFINE actions
							this.actionsToDefine.push(action);

						}

					} else {

						// We add the action to the TO DEFINE actions
						this.actionsToDefine.push(action);

					}

				}

			});

			// If there is nothing to show
			if (
				this.actionsTodo.length <= 0 &&
				this.actionsDone.length <= 0 &&
				this.actionsIgnored.length <= 0 &&
				this.actionsToDefine.length <= 0
			) {

				// We show the no action message
				this.showNoActionMessage();

			}

			// We load the actions sections
			this.loadActionsStatusSections();

		} else {

			// We show the no action message
			this.showNoActionMessage();

		}

	}

	loadActionsSortByRisk (curActions) {

		// We clear the container
		$("#summary-container").html("");

		// We switch the active class on sort buttons
		$("#btn-sort-status").removeClass("active");
		$("#btn-sort-risk").addClass("active");

		if (curActions !== undefined && curActions.length > 0) {

			$(curActions).each((i, action) => {

				// We check if the current action exist in the action plan (= has a status already defined)
				let curActionInActionPlan;
				if (this.actionsPlan !== undefined && this.actionsPlan.length > 0) {

					curActionInActionPlan = this.actionsPlan.filter((actionPlan) => parseInt(actionPlan.action_id) === parseInt(action.id))[0];

				}

				// We don't show deactivated action except if the deactivated action is adopted (= status done or todo in action plan)
				if (
					action.status !== "deactivated" ||
					action.status === "deactivated" && curActionInActionPlan !== undefined && curActionInActionPlan.status === "done" ||
					action.status === "deactivated" && curActionInActionPlan !== undefined && curActionInActionPlan.status === "todo"
				) {

					if (
						curActionInActionPlan === undefined ||
						curActionInActionPlan !== undefined && curActionInActionPlan.status !== "ignored"
					) {

						// We save the action
						this.actionsToShowOnRisks.push(action);

					}

				}

			});

			// If there is nothing to show
			if (this.actionsToShowOnRisks.length <= 0) {

				// We show the no action message
				this.showNoActionMessage();

			}

			// We load the risks sections
			this.loadRisksSections();

		} else {

			// We show the no action message
			this.showNoActionMessage();

		}

	}

	loadRisksSections () {

		// We define an array to saved the risks ids
		const risksIds = [];

		// For each actions
		$(this.actionsToShowOnRisks).each((i, action) => {

			if (risksIds.indexOf(action.risk_id) < 0) {

				// We push the risk ID in the risks array
				risksIds.push(action.risk_id);

			}

		});

		// We define context to get evaluations
		const contextData = {
			"cartography_id": this.curCartographyId,
			"legal_entity_id": this.curLegalEntityId
		};

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// For each risk ID
			$(risksIds).each((i, riskId) => {

				// We get the risk data
				const riskData = risks.find(riskId);

				// We define the card risk
				const card = new CardRisk(riskData);

				// We get the risk evaluation
				const riskEvaluation = evaluations.filter((evaluation) => evaluation.risk_id === riskId)[0];

				if (riskEvaluation !== undefined) {

					if (!riskEvaluation.risk_ignored.ignored_for_whole_legal_entity) {

						// We add the risk to the summary container
						this.addRiskToSummaryContainer(card, riskData, riskEvaluation);

						// We get the actions of the risk
						const curRiskActions = this.actionsToShowOnRisks.filter((action) => action.risk_id === riskId);

						if (curRiskActions !== undefined && curRiskActions.length > 0) {

							// We show the actions of the current risk
							this.showRiskActions(null, riskData, curRiskActions);

						}

					}

				} else {

					// We add the risk to the section
					this.addRiskToSummaryContainer(card, riskData, null);

					// We get the actions of the risk
					const curRiskActions = this.actionsToShowOnRisks.filter((action) => action.risk_id === riskId);

					if (curRiskActions !== undefined && curRiskActions.length > 0) {

						// We show the actions of the current risk
						this.showRiskActions(null, riskData, curRiskActions);

					}

				}

			});

			// We listen to click on the risks
			$(".card-risk").on("click", this.handleClickOnRisks.bind(this));

			// We listen click on the line action
			$(".risk-section .line-action").on("click", actionContainer.handleClickOnBtnMoreLineAction.bind(this));

			resolve();

		}).catch((error) => {

			debug.v("ActionsPlanList - showRisks - error in Cartographies getEvaluationForContext");

		});

	}

	addRiskToSummaryContainer (cardRisk, riskData, riskEvaluation) {

		// We add a risk section to the summary container
		$("#summary-container").append(`<div class="risk-section" id="risk-${riskData.id}"></div>`);

		// We define the risk container selector
		const riskContainerSelector = `#risk-${riskData.id}`;

		// We get the color of the card risk
		const cardColor = cardRisk.getCardColor(riskEvaluation);

		if (riskEvaluation !== undefined && riskEvaluation !== null) {

			// We show the card risk
			cardRisk.appendAndFill(riskContainerSelector, cardColor, riskData, riskEvaluation.scores);

		} else {

			// We show the card risk
			cardRisk.appendAndFill(riskContainerSelector, cardColor, riskData, []);

		}

		// We sort the risk
		this.sortRisksByColorDescending(null, riskData.id, cardColor);


	}

	showNoActionMessage () {

		// We hide the sort buttons
		$(".line-buttons-sort .box-sort").removeClass("active");

		// We define the message
		const htmlMessage = `<div class="msg-no-item"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-no-action-plan">${$.i18n("msg-no-action-plan")}</span></div></div>`;

		// We add the message to the container
		$("#summary-container").html(htmlMessage);

	}

	loadActionsStatusSections () {

		// We clear the line menu sections
		$("#line-menu-sections").html("");

		if (this.actionsTodo.length > 0) {

			// We get the number of actions
			const numActions = parseInt(this.actionsTodo.length);

			// We define the orthography of the button text
			let buttonText;
			if (numActions > 1) {

				buttonText = $.i18n("button-action-section-todo-plural");

			} else {

				buttonText = $.i18n("button-action-section-todo");

			}

			// We define the html of the section button
			const htmlButtonSection = `
				<div class="btn-menu-section todo" id="btn-menu-section-todo">
					<div class="icon fas fa-dot-circle"></div>
					<div class="content">
						<span class="num">${numActions}</span>
						<span class="text">${buttonText}</span>
					</div>
				</div>
			`;

			// We show the button
			$("#line-menu-sections").append(htmlButtonSection);

			// We listen click on the button
			$("#btn-menu-section-todo").on("click", this.handleClickOnBtnSectionTodo.bind(this));

			// We define the html of the section title
			const htmlTitleSection = `
				<div class="action-section-title">
					<span class="icon fas fa-dot-circle"></span>
					<span class="text">${$.i18n("title-action-section-todo")}</span>
				</div>
			`;

			// We show the section
			this.showActionStatusSection("todo", htmlTitleSection);

			// We show the risks
			this.showRisksInStatusSection("todo", this.actionsTodo);

		}

		if (this.actionsDone.length > 0) {

			// We get the number of actions
			const numActions = parseInt(this.actionsDone.length);

			// We define the orthography of the button text
			let buttonText;
			if (numActions > 1) {

				buttonText = $.i18n("button-action-section-done-plural");

			} else {

				buttonText = $.i18n("button-action-section-done");

			}

			// We define the html of the section button
			const htmlButtonSection = `
				<div class="btn-menu-section done" id="btn-menu-section-done">
					<div class="icon fas fa-check-circle"></div>
					<div class="content">
						<span class="num">${numActions}</span>
						<span class="text">${buttonText}</span>
					</div>
				</div>
			`;

			// We show the button
			$("#line-menu-sections").append(htmlButtonSection);

			// We listen click on the button
			$("#btn-menu-section-done").on("click", this.handleClickOnBtnSectionDone.bind(this));

			// We define the html of the section title
			const htmlTitleSection = `
				<div class="action-section-title">
					<span class="icon fas fa-check-circle"></span>
					<span class="text">${$.i18n("title-action-section-done")}</span>
				</div>
			`;

			// We show the section
			this.showActionStatusSection("done", htmlTitleSection);

			// We sStatushow the risks
			this.showRisksInStatusSection("done", this.actionsDone);

		}

		if (this.actionsIgnored.length > 0) {

			// We get the number of actions
			const numActions = parseInt(this.actionsIgnored.length);

			// We define the orthography of the button text
			let buttonText;
			if (numActions > 1) {

				buttonText = $.i18n("button-action-section-ignored-plural");

			} else {

				buttonText = $.i18n("button-action-section-ignored");

			}

			// We define the html of the section button
			const htmlButtonSection = `
				<div class="btn-menu-section ignored" id="btn-menu-section-ignored">
					<div class="icon fas fa-minus-circle"></div>
					<div class="content">
						<span class="num">${numActions}</span>
						<span class="text">${buttonText}</span>
					</div>
				</div>
			`;

			// We show the button
			$("#line-menu-sections").append(htmlButtonSection);

			// We listen click on the button
			$("#btn-menu-section-ignored").on("click", this.handleClickOnBtnSectionIgnored.bind(this));

			// We define the html of the section title
			const htmlTitleSection = `
				<div class="action-section-title">
					<span class="icon fas fa-minus-circle"></span>
					<span class="text">${$.i18n("title-action-section-ignored")}</span>
				</div>
			`;

			// We show the section
			this.showActionStatusSection("ignored", htmlTitleSection);

			// We sStatushow the risks
			this.showRisksInStatusSection("ignored", this.actionsIgnored);

		}

		if (this.actionsToDefine.length > 0) {

			// We get the number of actions
			const numActions = parseInt(this.actionsToDefine.length);

			// We define the orthography of the button text
			let buttonText;
			if (numActions > 1) {

				buttonText = $.i18n("button-action-section-to-define-plural");

			} else {

				buttonText = $.i18n("button-action-section-to-define");

			}

			// We define the html of the section button
			const htmlButtonSection = `
				<div class="btn-menu-section to-define" id="btn-menu-section-to-define">
					<div class="icon fas fa-question-circle"></div>
					<div class="content">
						<span class="num">${numActions}</span>
						<span class="text">${buttonText}</span>
					</div>
				</div>
			`;

			// We show the button
			$("#line-menu-sections").append(htmlButtonSection);

			// We listen click on the button
			$("#btn-menu-section-to-define").on("click", this.handleClickOnBtnSectionToDefine.bind(this));

			// We define the html of the section title
			const htmlTitleSection = `
				<div class="action-section-title">
					<span class="icon fas fa-question-circle"></span>
					<span class="text">${$.i18n("title-action-section-to-define")}</span>
				</div>
			`;

			// We show the section
			this.showActionStatusSection("to-define", htmlTitleSection);

			// We sStatushow the risks
			this.showRisksInStatusSection("to-define", this.actionsToDefine);

		}

	}

	showActionStatusSection (status, htmlTitle) {

		// We define the html
		const htmlContentSection = `
			<div class="action-section" id="section-${status}">
				${htmlTitle}
			</div>
		`;

		// We show the section
		$("#summary-container").append(htmlContentSection);

	}

	showRisksInStatusSection (status, actionsData) {

		// We define an array to saved the risks ids
		const risksIds = [];

		// For each actions
		$(actionsData).each((i, action) => {

			if (risksIds.indexOf(action.risk_id) < 0) {

				// We push the risk ID in the risks array
				risksIds.push(action.risk_id);

			}

		});

		// We define context to get evaluations
		const contextData = {
			"cartography_id": this.curCartographyId,
			"legal_entity_id": this.curLegalEntityId
		};

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// For each risk ID
			$(risksIds).each((i, riskId) => {

				// We get the risk data
				const riskData = risks.find(riskId);

				// We define the card risk
				const card = new CardRisk(riskData);

				// We get the risk evaluation
				const riskEvaluation = evaluations.filter((evaluation) => evaluation.risk_id === riskId)[0];

				if (riskEvaluation !== undefined) {

					if (!riskEvaluation.risk_ignored.ignored_for_whole_legal_entity) {

						// We add the risk to the section
						this.addRiskToStatusSection(status, card, riskData, riskEvaluation);

						// We get the actions of the risk
						const curRiskActions = actionsData.filter((action) => action.risk_id === riskId);

						if (curRiskActions !== undefined && curRiskActions.length > 0) {

							// We show the actions of the current risk
							this.showRiskActions(status, riskData, curRiskActions);

						}

					}

				} else {

					// We add the risk to the section
					this.addRiskToStatusSection(status, card, riskData, null);

					// We get the actions of the risk
					const curRiskActions = actionsData.filter((action) => action.risk_id === riskId);

					if (curRiskActions !== undefined && curRiskActions.length > 0) {

						// We show the actions of the current risk
						this.showRiskActions(status, riskData, curRiskActions);

					}

				}

			});

			// We listen to click on the risks
			$(".card-risk").on("click", this.handleClickOnRisks.bind(this));

			// We listen click on the line action
			$(`#section-${status} .line-action`).on("click", actionContainer.handleClickOnBtnMoreLineAction.bind(this));

			resolve();

		}).catch((error) => {

			debug.v("ActionsPlanList - showRisks - error in Cartographies getEvaluationForContext");

		});

	}

	addRiskToStatusSection (sectionStatus, cardRisk, riskData, riskEvaluation) {

		// We define the section container selector
		const sectionContainerSelector = `#section-${sectionStatus}`;

		// We add a risk section to the section container
		$(sectionContainerSelector).append(`<div class="risk-section" id="section-${sectionStatus}-risk-${riskData.id}"></div>`);

		// We define the risk container selector
		const riskContainerSelector = `#section-${sectionStatus}-risk-${riskData.id}`;

		// We get the color of the card risk
		const cardColor = cardRisk.getCardColor(riskEvaluation);

		if (riskEvaluation !== undefined && riskEvaluation !== null) {

			// We show the card risk
			cardRisk.appendAndFill(riskContainerSelector, cardColor, riskData, riskEvaluation.scores);

		} else {

			// We show the card risk
			cardRisk.appendAndFill(riskContainerSelector, cardColor, riskData, []);

		}

		// We sort the risk
		this.sortRisksByColorDescending(sectionStatus, riskData.id, cardColor);


	}

	sortRisksByColorDescending (sectionStatus) {

		if (sectionStatus !== undefined && sectionStatus !== null) {

			$(`#section-${sectionStatus} .risk-section`).sort((a, b) => {

				const rankColorA = this.getRankColor(a);
				const rankColorB = this.getRankColor(b);

				return rankColorB - rankColorA;

			}).appendTo(`#section-${sectionStatus}`);

		} else {

			$("#summary-container .risk-section").sort((a, b) => {

				const rankColorA = this.getRankColor(a);
				const rankColorB = this.getRankColor(b);

				return rankColorB - rankColorA;

			}).appendTo("#summary-container");

		}

	}

	getRankColor (cardRisk) {

		let color = $(cardRisk).find(".card-risk").attr("class").match(/card-risk color-(.*) card-risk-/)[1];

		let rankColor = 0;
		switch (color) {

			case "green":
				rankColor = 1;
				break;

			case "yellow":
				rankColor = 2;
				break;

			case "orange":
				rankColor = 3;
				break;

			case "red":
				rankColor = 4;
				break;

		}

		return rankColor;

	}

	showRiskActions (sectionStatus, riskData, actionsData) {

		let selector;

		if (sectionStatus !== undefined && sectionStatus !== null) {

			selector = `#section-${sectionStatus}-risk-${riskData.id}`;

		} else {

			selector = `#risk-${riskData.id}`;

		}

		// We show the list of actions
		actionContainer.loadActionsList(selector, actionsData, this.actionsPlan);

	}

	handleClickOnBtnSortByStatus (e) {

		// If the current button is not active
		if ($(e.currentTarget).attr("class").indexOf("active") < 0) {

			// We sort the actions by status
			this.initActionsSortByStatus();

		}

	}

	handleClickOnBtnSortByRisk (e) {

		// If the current button is not active
		if ($(e.currentTarget).attr("class").indexOf("active") < 0) {

			// We sort the actions by risk
			this.initActionsSortByRisk();

		}

	}

	handleClickOnRisks (e) {

		// We get the risk ID
		const curRiskId = $(e.currentTarget).attr("data-id-risk");

		// We save the current page url on the local storage
		localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

		// We show the risk page
		router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${curRiskId}/business-activities/all#actions`);

	}

	handleClickOnBtnSectionTodo (e) {

		if ($("#section-todo").length > 0) {

			// We get the position top of the section
			const posTopSection = $("#section-todo").offset().top;

			// We get the height of the select context
			let heightSelectContext = 0;
			if ($("#main-select-context").height() !== undefined) {

				heightSelectContext = $("#main-select-context").height();

			}

			// We show the section with smooth scrolling
			$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

		}

	}

	handleClickOnBtnSectionDone (e) {

		if ($("#section-done").length > 0) {

			// We get the position top of the section
			const posTopSection = $("#section-done").offset().top;

			// We get the height of the select context
			let heightSelectContext = 0;
			if ($("#main-select-context").height() !== undefined) {

				heightSelectContext = $("#main-select-context").height();

			}

			// We show the section with smooth scrolling
			$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

		}

	}

	handleClickOnBtnSectionIgnored (e) {

		if ($("#section-ignored").length > 0) {

			// We get the position top of the section
			const posTopSection = $("#section-ignored").offset().top;

			// We get the height of the select context
			let heightSelectContext = 0;
			if ($("#main-select-context").height() !== undefined) {

				heightSelectContext = $("#main-select-context").height();

			}

			// We show the section with smooth scrolling
			$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

		}

	}

	handleClickOnBtnSectionToDefine (e) {

		if ($("#section-to-define").length > 0) {

			// We get the position top of the section
			const posTopSection = $("#section-to-define").offset().top;

			// We get the height of the select context
			let heightSelectContext = 0;
			if ($("#main-select-context").height() !== undefined) {

				heightSelectContext = $("#main-select-context").height();

			}

			// We show the section with smooth scrolling
			$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

		}

	}

	handleClickOnBtnBackRisk () {

		// We show the risk page
		router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${this.curRiskId}/business-activities/all#actions`);

	}

}
export default new ActionsPlanList();
