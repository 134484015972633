import html from "./popin.html";
import "./Popin.scss";

class Popin {

	constructor (id, title, buttons) {

		this.id = id;
		this.title = title;
		this.buttons = buttons;

	}

	appendIn (cssSelector) {

		$(cssSelector).append(html);
		$("#popinIdToBeReplaced").attr("id", this.id);
		$(`#${this.id} .title`).html(this.title);

		this.appendButtons();

	}

	appendButtons () {


    	this.buttons.forEach((button, i) => {

    		let buttonId = `${this.id}_${button.title.toLowerCase().replace(" ", "")}`;
    		let htmlButtons = "";

    		htmlButtons += `<button class='${button.class}' id='${buttonId}'>`;
    		htmlButtons += button.iconClass != undefined ? `<i class='${button.iconClass}'></i>` : "";
    		htmlButtons += `<span class='text'>${button.title}</span>`;
    		htmlButtons += "</button>";

			$(`#${this.id} .buttons`).append(htmlButtons);

			$(`#${buttonId}`).on("click", button.callback);

		});

	}

	show () {

		$(`#${this.id}`).addClass("active");

	}

	hide () {

		$(`#${this.id}`).removeClass("active");

	}

}
export default Popin;
