import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import htmlTermsOfUse from "./terms_of_use.html";
import htmlPersonalData from "./personal_data.html";
import SinglePageLayout from "Layouts/SinglePage/SinglePageLayout.js";
import "./SinglePage.scss";

class SinglePage extends Page {

	constructor () {

		let routes = [
			"/terms-of-use",
			"/personal-data"
		];

		super(routes, SinglePageLayout, "Risk Management Platform");

	}

	render () {

		// Scroll to the top of the page
		window.scrollTo(0, 0);

		if (window.location.pathname === "/terms-of-use") {

			this.parseAndRender("page", htmlTermsOfUse);

		} else if (window.location.pathname === "/personal-data") {

			this.parseAndRender("page", htmlPersonalData);

		}

		menu.show();

		// We recall the translation
		$("html").i18n();

	}

}
export default new SinglePage();
