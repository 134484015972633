import router from "Core/router.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class HelpItems extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData () {

		let data = [
			{
				"id": "risk-evaluation",
				"slug_icon": "fa-star-half-alt",
				"title": $.i18n("title-help-guide-risk-evaluation"),
				"user_right": "evaluate_cartography",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".card-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#section-not-evaluated-risks",
								"focus_html_block_primary": "#section-not-evaluated-risks",
								"tooltip_html_block_secondary": "#section-evaluated-risks",
								"focus_html_block_secondary": "#section-evaluated-risks",
								"tooltip_content": $.i18n("help-risk-evaluation-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-risk-evaluation-step-1-tooltip-1"),
								"tooltip_gap_position_y": -75,
								"tooltip_gap_position_x": 250,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/risks/",
						"step_url_exclude_1": "/evaluate",
						"step_url_exclude_2": "/edit",
						"step_url_exclude_3": "/create",
						"step_url_exclude_4": "/select-business-activities",
						"next_step_button": "#btn-evaluate-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".risk-container .header",
								"focus_html_block_primary": ".risk-container .header",
								"tooltip_html_block_secondary": ".risk-container .header",
								"focus_html_block_secondary": ".risk-container .header",
								"tooltip_content": $.i18n("help-risk-evaluation-step-2-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -60,
								"tooltip_gap_position_x": 200,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": 4000
							},
							{
								"tooltip_id": 2,
								"tooltip_html_block_primary": "#container-description-risk",
								"focus_html_block_primary": "#container-description-risk",
								"tooltip_html_block_secondary": "#container-description-risk",
								"focus_html_block_secondary": "#container-description-risk",
								"tooltip_content": $.i18n("help-risk-evaluation-step-2-tooltip-2"),
								"error_message": null,
								"tooltip_gap_position_y": -100,
								"tooltip_gap_position_x": 50,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": 4000
							},
							{
								"tooltip_id": 3,
								"tooltip_html_block_primary": "#btn-evaluate-risk",
								"focus_html_block_primary": "#btn-evaluate-risk",
								"tooltip_html_block_secondary": "#btn-evaluate-risk",
								"focus_html_block_secondary": "#btn-evaluate-risk",
								"tooltip_content": $.i18n("help-risk-evaluation-step-2-tooltip-3"),
								"error_message": null,
								"tooltip_gap_position_y": 55,
								"tooltip_gap_position_x": -360,
								"tooltip_orientation": "top-right",
								"tooltip_duration": null
							}
						]
					}
				]
			},
			{
				"id": "user-creation",
				"slug_icon": "fa-user",
				"title": $.i18n("title-help-guide-user-creation"),
				"user_right": "admin_users",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".sidebar-menu .main-menu .btn-users_management",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_content": $.i18n("help-user-creation-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-user-creation-step-1-tooltip-1"),
								"tooltip_gap_position_y": -30,
								"tooltip_gap_position_x": 110,
								"tooltip_orientation": "left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/admin/users",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".admin-container .btn-top-admin .btn-add-user",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".admin-container .btn-top-admin .btn-add-user",
								"focus_html_block_primary": ".admin-container .btn-top-admin .btn-add-user",
								"tooltip_html_block_secondary": ".admin-container .btn-top-admin .btn-add-user",
								"focus_html_block_secondary": ".admin-container .btn-top-admin .btn-add-user",
								"tooltip_content": $.i18n("help-user-creation-step-2-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -20,
								"tooltip_gap_position_x": -420,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 3,
						"step_url": "/admin/user/create",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".admin-container .form-admin form .line-buttons-bottom .btn-submit",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "form#edit_user .list-form-fields",
								"focus_html_block_primary": "form#edit_user .list-form-fields",
								"tooltip_html_block_secondary": "form#edit_user .list-form-fields",
								"focus_html_block_secondary": "form#edit_user .list-form-fields",
								"tooltip_content": $.i18n("help-user-creation-step-3-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 30,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 2,
								"tooltip_html_block_primary": "#rights",
								"focus_html_block_primary": "#rights",
								"tooltip_html_block_secondary": "#rights",
								"focus_html_block_secondary": "#rights",
								"tooltip_content": $.i18n("help-user-creation-step-3-tooltip-2"),
								"error_message": null,
								"tooltip_gap_position_y": 45,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 3,
								"tooltip_html_block_primary": "form#edit_user  .line-add",
								"focus_html_block_primary": "#btn-add-right",
								"tooltip_html_block_secondary": "form#edit_user  .line-add",
								"focus_html_block_secondary": "#btn-add-right",
								"tooltip_content": $.i18n("help-user-creation-step-3-tooltip-3"),
								"error_message": null,
								"tooltip_gap_position_y": 0,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 4,
								"tooltip_html_block_primary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_primary": "form#edit_user .line-buttons-bottom",
								"tooltip_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"tooltip_content": $.i18n("help-user-creation-step-3-tooltip-4"),
								"error_message": null,
								"tooltip_gap_position_y": -10,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					}
				]
			},
			{
				"id": "assign-rpo",
				"slug_icon": "fa-user-tie",
				"title": $.i18n("title-help-guide-assign-rpo"),
				"user_right": "admin_risks",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".card-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#section-not-evaluated-risks",
								"focus_html_block_primary": "#section-not-evaluated-risks",
								"tooltip_html_block_secondary": "#section-evaluated-risks",
								"focus_html_block_secondary": "#section-evaluated-risks",
								"tooltip_content": $.i18n("help-assign-rpo-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-assign-rpo-step-1-tooltip-1"),
								"tooltip_gap_position_y": -30,
								"tooltip_gap_position_x": 230,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/risks/",
						"step_url_exclude_1": "/evaluate",
						"step_url_exclude_2": "/edit",
						"step_url_exclude_3": "/create",
						"step_url_exclude_4": "/select-business-activities",
						"next_step_button": "#btn-edit-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#btn-edit-risk",
								"focus_html_block_primary": "#btn-edit-risk",
								"tooltip_html_block_secondary": "#btn-edit-risk",
								"focus_html_block_secondary": "#btn-edit-risk",
								"tooltip_content": $.i18n("help-assign-rpo-step-2-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -20,
								"tooltip_gap_position_x": -420,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 3,
						"step_url": "/edit",
						"step_url_exclude_1": "/user/",
						"step_url_exclude_2": "/cartography/",
						"step_url_exclude_3": "/business-activity/",
						"step_url_exclude_4": null,
						"next_step_button": "#edit_risk .btn-submit",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "form#edit_risk select[name='rpo_id']",
								"focus_html_block_primary": "form#edit_risk select[name='rpo_id']",
								"tooltip_html_block_secondary": "form#edit_risk select[name='rpo_id']",
								"focus_html_block_secondary": "form#edit_risk select[name='rpo_id']",
								"tooltip_content": $.i18n("help-assign-rpo-step-3-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -20,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					}
				]
			},
			{
				"id": "assign-view-cartography-right-to-user",
				"slug_icon": "fa-eye",
				"title": $.i18n("title-help-guide-assign-view-cartography-right-to-user"),
				"user_right": "admin_users",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".sidebar-menu .main-menu .btn-users_management",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-assign-view-cartography-right-to-user-step-1-tooltip-1"),
								"tooltip_gap_position_y": -30,
								"tooltip_gap_position_x": 110,
								"tooltip_orientation": "left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/admin/users",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": "#users_list li a",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#users_list",
								"focus_html_block_primary": "#users_list",
								"tooltip_html_block_secondary": "#users_list",
								"focus_html_block_secondary": "#users_list",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-2-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-assign-view-cartography-right-to-user-step-2-tooltip-1"),
								"tooltip_gap_position_y": -100,
								"tooltip_gap_position_x": 200,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 3,
						"step_url": "/admin/user/show/",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": "#btn-update-user",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#btn-update-user",
								"focus_html_block_primary": "#btn-update-user",
								"tooltip_html_block_secondary": "#btn-update-user",
								"focus_html_block_secondary": "#btn-update-user",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-3-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -20,
								"tooltip_gap_position_x": -410,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 4,
						"step_url": "/admin/user/edit/",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": "#btn-add-right",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#rights",
								"focus_html_block_primary": "#rights",
								"tooltip_html_block_secondary": "#rights",
								"focus_html_block_secondary": "#rights",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-4-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 20,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 2,
								"tooltip_html_block_primary": "form#edit_user .line-add",
								"focus_html_block_primary": "#btn-add-right",
								"tooltip_html_block_secondary": "form#edit_user .line-add",
								"focus_html_block_secondary": "#btn-add-right",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-4-tooltip-2"),
								"error_message": null,
								"tooltip_gap_position_y": 0,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 3,
								"tooltip_html_block_primary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_primary": "form#edit_user .line-buttons-bottom",
								"tooltip_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-4-tooltip-3"),
								"error_message": null,
								"tooltip_gap_position_y": -10,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					}
				]
			},
			{
				"id": "assign-evaluate-cartography-right-to-user",
				"slug_icon": "fa-star",
				"title": $.i18n("title-help-guide-assign-evaluate-cartography-right-to-user"),
				"user_right": "admin_users",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".sidebar-menu .main-menu .btn-users_management",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_primary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"focus_html_block_secondary": ".sidebar-menu .main-menu .btn-users_management",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-assign-view-cartography-right-to-user-step-1-tooltip-1"),
								"tooltip_gap_position_y": -30,
								"tooltip_gap_position_x": 110,
								"tooltip_orientation": "left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/admin/users",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": "#users_list li a",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#users_list",
								"focus_html_block_primary": "#users_list",
								"tooltip_html_block_secondary": "#users_list",
								"focus_html_block_secondary": "#users_list",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-2-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-assign-view-cartography-right-to-user-step-2-tooltip-1"),
								"tooltip_gap_position_y": -100,
								"tooltip_gap_position_x": 200,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 3,
						"step_url": "/admin/user/show/",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": "#btn-update-user",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#btn-update-user",
								"focus_html_block_primary": "#btn-update-user",
								"tooltip_html_block_secondary": "#btn-update-user",
								"focus_html_block_secondary": "#btn-update-user",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-3-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": -20,
								"tooltip_gap_position_x": -410,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 4,
						"step_url": "/admin/user/edit/",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".line-buttons-bottom .btn-submit",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#rights",
								"focus_html_block_primary": "#rights",
								"tooltip_html_block_secondary": "#rights",
								"focus_html_block_secondary": "#rights",
								"tooltip_content": $.i18n("help-assign-evaluate-cartography-right-to-user-step-4-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 20,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 2,
								"tooltip_html_block_primary": "form#edit_user .line-add",
								"focus_html_block_primary": "#btn-add-right",
								"tooltip_html_block_secondary": "form#edit_user .line-add",
								"focus_html_block_secondary": "#btn-add-right",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-4-tooltip-2"),
								"error_message": null,
								"tooltip_gap_position_y": 0,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 3,
								"tooltip_html_block_primary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_primary": "form#edit_user .line-buttons-bottom",
								"tooltip_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"focus_html_block_secondary": "form#edit_user .line-buttons-bottom",
								"tooltip_content": $.i18n("help-assign-view-cartography-right-to-user-step-4-tooltip-3"),
								"error_message": null,
								"tooltip_gap_position_y": -10,
								"tooltip_gap_position_x": -430,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					}
				]
			},
			{
				"id": "publish-risk-evaluation",
				"slug_icon": "fa-file-upload",
				"title": $.i18n("title-help-guide-publish-risk-evaluation"),
				"user_right": "evaluate_cartography",
				"steps": [
					{
						"step_id": 1,
						"step_url": "/dashboard-grid",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".card-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#section-not-evaluated-risks",
								"focus_html_block_primary": "#section-not-evaluated-risks",
								"tooltip_html_block_secondary": "#section-evaluated-risks",
								"focus_html_block_secondary": "#section-evaluated-risks",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-1-tooltip-1"),
								"error_message": $.i18n("error-message-help-guide-publish-risk-evaluation-step-1-tooltip-1"),
								"tooltip_gap_position_y": -75,
								"tooltip_gap_position_x": 250,
								"tooltip_orientation": "bottom-left",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 2,
						"step_url": "/risks/",
						"step_url_exclude_1": "/evaluate",
						"step_url_exclude_2": "/edit",
						"step_url_exclude_3": "/create",
						"step_url_exclude_4": "/select-business-activities",
						"next_step_button": "#btn-evaluate-risk",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#btn-evaluate-risk",
								"focus_html_block_primary": "#btn-evaluate-risk",
								"tooltip_html_block_secondary": "#btn-evaluate-risk",
								"focus_html_block_secondary": "#btn-evaluate-risk",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-2-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 55,
								"tooltip_gap_position_x": -360,
								"tooltip_orientation": "top-right",
								"tooltip_duration": null
							}
						]
					},
					{
						"step_id": 3,
						"step_url": "/evaluate-for-business-activities",
						"step_url_exclude_1": null,
						"step_url_exclude_2": null,
						"step_url_exclude_3": null,
						"step_url_exclude_4": null,
						"next_step_button": ".btn-back-previous-page",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": "#section-intro",
								"focus_html_block_primary": "#section-intro",
								"tooltip_html_block_secondary": "#section-intro",
								"focus_html_block_secondary": "#section-intro",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-3-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 120,
								"tooltip_gap_position_x": -400,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 2,
								"tooltip_html_block_primary": "#section-intro",
								"focus_html_block_primary": "#section-intro",
								"tooltip_html_block_secondary": "#section-intro",
								"focus_html_block_secondary": "#section-intro",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-3-tooltip-2"),
								"error_message": null,
								"tooltip_gap_position_y": 320,
								"tooltip_gap_position_x": -400,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							},
							{
								"tooltip_id": 3,
								"tooltip_html_block_primary": ".btn-back-previous-page",
								"focus_html_block_primary": ".btn-back-previous-page",
								"tooltip_html_block_secondary": ".btn-back-previous-page",
								"focus_html_block_secondary": ".btn-back-previous-page",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-3-tooltip-3"),
								"error_message": null,
								"tooltip_gap_position_y": -100,
								"tooltip_gap_position_x": -300,
								"tooltip_orientation": "bottom-right",
								"tooltip_duration": null,
								"tooltip_fixed": true
							}
						]
					},
					{
						"step_id": 4,
						"step_url": "/risks/",
						"step_url_exclude_1": "/evaluate",
						"step_url_exclude_2": "/edit",
						"step_url_exclude_3": "/create",
						"step_url_exclude_4": "/select-business-activities",
						"next_step_button": ".btn-publish",
						"tooltips": [
							{
								"tooltip_id": 1,
								"tooltip_html_block_primary": ".risk-container .header",
								"focus_html_block_primary": ".risk-container .header",
								"tooltip_html_block_secondary": ".risk-container .header",
								"focus_html_block_secondary": ".risk-container .header",
								"tooltip_content": $.i18n("help-publish-risk-evaluation-step-4-tooltip-1"),
								"error_message": null,
								"tooltip_gap_position_y": 200,
								"tooltip_gap_position_x": -200,
								"tooltip_orientation": "right",
								"tooltip_duration": null
							}
						]
					}
				]
			}
		];

		this.updateData(data);

		return this.data;

	}

	find (idToFind) {

		return this.data.find((helpItem) => helpItem.id === idToFind);

	}

}
export default new HelpItems();
