import router from "Core/router.js";
import Page from "Pages/Page.js";
import debug from "Core/Debug.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import html from "./exports.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import cartographyExports from "Datapoints/Exports.js";
import "./Exports.scss";
import "./ExportsResponsive.scss";

class Exports extends Page {

	constructor () {

		let routes = [
			"/exports",
			"/exports/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/exports/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/exports/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/exports/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/exports/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/exports/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			context,
			cartographies,
			cartographyExports
		];

		super(routes, AuthentifiedLayout, "Exports", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canUserViewOrEvaluateCartography
		];

	}

	canUserViewOrEvaluateCartography (params) {

		return new Promise((resolve, reject) => {

			const canView = user.hasRight("view_evaluation", {"cartography_id": params.idCartography});
			const canEvaluate = user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography});

			if (canView || canEvaluate) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		return new Promise((resolve, reject) => {

			this.parseAndRender("page", html);

			let renderPromises = [];
			renderPromises.push(this.initGlobalVars(params));
			renderPromises.push(selectContext.show(params));
			renderPromises.push(menu.show());
			renderPromises.push(this.initEvents());
			renderPromises.push(this.setMainTitle());
			renderPromises.push(this.loadExportItems());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});


	}

	initGlobalVars (params) {

		return new Promise((resolve, reject) => {

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			// We get the current cartography id
			this.cartographyId = cartographies.getCurrentCartographyId();

			// We get the current branch id
			const curBranchId = context.getCurrentBranchId();

			// We get the current profit center id
			const curProfitCenterId = context.getCurrentProfitCenterId();

			// We get the current country id
			const curCountryId = context.getCurrentCountryId();

			// We load the current legal entity
			const curLegalEntityId = context.getCurrentLegalEntityId();

			// We defines booleean to ease algo reading
			const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
			const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
			const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
			const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
			const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
			const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
			const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

			// Profit center and legal entity consolidated
			if (isProfitCenterOnAll && isLegalEntityOnAll) {

				// We define the export params
				this.exportParams = {
					"language": this.currentLang,
					"cartography_id": this.cartographyId,
					"branch_id": curBranchId
				};

			}
			// Country and legal entity consolidated
			else if (isCountryOnAll && isLegalEntityOnAll) {

				// We define the export params
				this.exportParams = {
					"language": this.currentLang,
					"cartography_id": this.cartographyId,
					"branch_id": curBranchId,
					"profit_center_id": curProfitCenterId
				};


			}
			// Legal entity consolidated
			else if (isLegalEntityOnAll) {

				// We define the export params
				this.exportParams = {
					"language": this.currentLang,
					"cartography_id": this.cartographyId,
					"branch_id": curBranchId,
					"profit_center_id": curProfitCenterId,
					"country_id": curCountryId
				};


			}
			// No consolidation
			else {

				// We define the export params
				this.exportParams = {
					"language": this.currentLang,
					"cartography_id": this.cartographyId,
					"branch_id": curBranchId,
					"profit_center_id": curProfitCenterId,
					"country_id": curCountryId,
					"legal_entity_id": curLegalEntityId
				};

			}

		});

	}

	initEvents () {

		return new Promise((resolve, reject) => {

			// We listen click on the new export button
			$("#button-new-export").on("click", this.handleClickOnNewExportButton.bind(this));

		});

	}

	setMainTitle () {

		return new Promise((resolve, reject) => {

			// We get the current cartography name
			const curCartographyName = this.cartography[`name_${this.currentLang}`];

			// We add the current cartography name on the main title
			$(".main-title h1 .highlight").html(curCartographyName);

		});

	}

	loadExportItems () {

		return new Promise((resolve, reject) => {

			// We clar the export history section
			$("#export-history").html("");

			// We loop on exports
			for (let iCartographyExport = 0; iCartographyExport < cartographyExports.data.length; iCartographyExport++) {

				const curExport = cartographyExports.data[iCartographyExport];

				// If the current export is on the current cartography
				if (parseInt(curExport.cartography_id) === this.cartographyId) {

					// We define the export date
					const exportSeconds = `0${new Date(curExport.updated_at).getSeconds()}`.slice(-2);
					const exportMinutes = `0${new Date(curExport.updated_at).getMinutes()}`.slice(-2);
					const exportHours = `0${new Date(curExport.updated_at).getHours()}`.slice(-2);
					const exportFullTime = `${exportHours}:${exportMinutes}:${exportSeconds}`;
					const exportDay = `0${new Date(curExport.updated_at).getDate()}`.slice(-2);
					const exportMonth = `0${new Date(curExport.updated_at).getMonth() + 1}`.slice(-2);
					const exportYear = new Date(curExport.updated_at).getFullYear();
					const exportFullDate = `${exportDay}.${exportMonth}.${exportYear}`;

					// We define the html of the export item
					const htmlExportItem = `
						<div class="export-item" data-export-id="${curExport.id}">
						  <div class="export-title">
						    <div class="text">${exportFullDate}</div>
						    <div class="highlight">${exportFullTime}</div>
						  </div>
						  <div class="btn-export">
						    <span class="icon fas fa-file-export"></span>
						    <span class="text" data-i18n="button-export">Exporter</span>
						  </div>
						</div>
					`;

					// We add the export item to the export history section
					$("#export-history").prepend(htmlExportItem);

					// We listen click on the export item
					$(`.export-item[data-export-id='${curExport.id}']`).on("click", this.handleClickOnExportItem.bind(this));

				}

			}

			// If there is no export item on the export history section
			if ($("#export-history .export-item").length < 1) {

				// We show an error
				this.showError("no-export-for-cartography");

			}


		});

	}

	showError (slug) {

		let msgError;
		if (slug !== undefined && slug === "no-export-for-cartography") {

 			msgError = `${$.i18n("error-no-export-for-cartography")} <span class="highlight">${this.cartography[`name_${this.currentLang}`]}</span>`;

		} else {

			msgError = `${$.i18n("error-no-export")}`;

		}

		// We define the html of the error message
		const htmlError = `<div class="msg-global-error"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;${msgError}</div></div>`;

		// We show the message
		$("#export-history").append(htmlError);

	}

	handleClickOnNewExportButton () {

		// We create the export
		cartographyExports.createForContext(this.exportParams).then(this.createExportSuccess.bind(this), this.createExportFail.bind(this));

	}

	handleClickOnExportItem (e) {

		// We get the export item id
		const exportItemId = parseInt($(e.currentTarget).attr("data-export-id"));

		// We download the export file
		this.downloadExport(exportItemId);

	}

	downloadExport (exportId) {

		// We download the export file
		window.location.assign(`${window.location.origin}/api/exports/download/${exportId}`);

	}

	createExportSuccess (response) {

		debug.v("Exports - createExportSuccess");

		cartographyExports.getData().then(() => {

			// We download the export file
			this.downloadExport(response.export_id);

			// We load export items again
			this.loadExportItems();

		}).catch((exportError) => {

			debug.v("Exports - createExportSuccess - error in Exports getData");

		});

	}

	createExportFail (response) {

		debug.v("Exports - createExportFail");

	}

}
export default new Exports();
