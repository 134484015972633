import html from "./footer.html";
import "./Footer.scss";
import "./FooterResponsive.scss";
import "./FooterEnvSpecific.scss";

class Footer {

	show () {

		$("#footer").html(html);

		this.initEvents();

		this.showLogo();

		this.showVersionNumber();

		this.showCopyrightDate();

	}

	initEvents () {

		this.initResponsive();

		// We listen the window resize
		$(window).on("resize", () => {

			this.initResponsive();

		});

		this.initContactButton();

	}

	initResponsive () {

		// We get the window height
		const curWindowHeight = $(window).height();

		// We get the heights of menu elements
		let sumElementsMenuHeights = 20;
		if ($("#main-select-context .ban").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#main-select-context .ban").outerHeight());

		}
		if ($("#main-select-context .select-custom-name-cartography").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#main-select-context .select-custom-name-cartography").outerHeight());

		}
		if ($("#menu .logo").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .logo").outerHeight());

		}
		if ($("#menu .search-form").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .search-form").outerHeight());

		}
		if ($("#menu .menu-switch").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .menu-switch").outerHeight());

		}
		if ($("#menu .user-menu").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .user-menu").outerHeight());

		}
		if ($("#menu .main-menu").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .main-menu").outerHeight());

		}
		if ($("#menu .content-footer").outerHeight() !== undefined) {

			sumElementsMenuHeights += parseInt($("#menu .content-footer").outerHeight());

		}

		// We adapt the menu for small screens
		if (sumElementsMenuHeights >= curWindowHeight) {

			$("#menu .content-footer").addClass("small-screen");

		} else {

			$("#menu .content-footer").removeClass("small-screen");

		}

	}

	initContactButton () {

		// We define the link of the contact button
		let linkContactButton;
		if (process.env.CONTACT_EMAIL.indexOf("@") >= 0) {

			linkContactButton = `mailto:${process.env.CONTACT_EMAIL}?subject=Contact`;

		} else {

			linkContactButton = process.env.CONTACT_EMAIL;

		}

		// We init the href of the contact button
		$("#footer .btn-contact a").attr("href", linkContactButton);

	}

	showLogo () {

		// We show the logo image
		const imgURL = `${location.protocol}//${location.hostname}/assets/images/logos/logo.png`;
		$(".logo-footer").html(`<img src="${imgURL}" alt="" border="0" />`);

	}

	showVersionNumber () {

		if (process.env.VERSION_NUMBER != undefined) {

			const version = process.env.VERSION_NUMBER;
			$(".copyright").append(` - v${version}`);

		}

	}

	showCopyrightDate () {

		// We add the copyright date
	  const curYear = new Date().getFullYear();
	  if (curYear !== undefined && curYear !== null && curYear !== "") {

	    $(".content-footer .copyright .year").html(curYear);

	  }

	}

}
export default new Footer();
