import router from "Core/router.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./list.html";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import countries from "Datapoints/Countries.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
// IMPORT DATATABLES PLUGIN
import DataTable from "datatables.net-dt";
import dataTableLanguageFR from "Core/assets/lib/datatables/fr-FR.json";
import dataTableLanguageEN from "Core/assets/lib/datatables/en-GB.json";

class UsersList extends Page {

	constructor () {

		let routes = ["/admin/users"];

		let datapointsToLoad = [
			users,
			cartographies,
			branches,
			legalEntities,
			countries
		];

		super(routes, AuthentifiedLayout, "Liste des utilisateurs", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminUsers.bind(user)
		];

	}

	render () {

		return new Promise((resolve, reject) => {

			this.parseAndRender("page", html);

			// We get the current language
			this.currentLang = $.i18n().locale.substring(0, 2);

			legalEntities.getAllLegalEntities().then((allLegalEntities) => {

				let renderPromises = [];
				renderPromises.push(menu.show());
				renderPromises.push(this.initGlobalEvents());
				renderPromises.push(this.initUsersList(allLegalEntities));
				renderPromises.push(this.initDataTable());

				// We listen click on add user line
				$("#users_list tbody").on("click", "tr", this.handleClickOnUserLine.bind(this));

				Promise.all(renderPromises).then(resolve).catch(reject);

			}).catch(reject);

		});

	}

	initGlobalEvents () {

		return new Promise((resolve, reject) => {

			// To fix the container width bug, we define the container width in px
			this.setContainerWidth();

			// We listen the window resize
			$(window).on("resize", this.handleResizeWindow.bind(this));

		});

	}

	initUsersList (allLegalEntities) {


		return new Promise((resolve, reject) => {

			// Each user
			for (let iUser = 0; iUser < users.data.length; iUser++) {

				// We define the list of cartographies that the user has right for
				let userCartographiesId = [];

				// Each user right
				$.each(users.data[iUser].rights, (label, right) => {

					if (
						right.cartography_id !== undefined &&
					right.cartography_id !== null &&
					userCartographiesId.indexOf(parseInt(right.cartography_id)) < 0
					) {

						userCartographiesId.push(parseInt(right.cartography_id));

					}

				});

				// We define the html of user cartographies
				let htmlUserCartographies = "";

				// Each cartography that the user has right for
				$.each(userCartographiesId, (label, cartographyId) => {

					// We get the cartography data
					const cartographyData = cartographies.find(cartographyId);

					if (cartographyData !== undefined) {

						htmlUserCartographies += `${cartographyData[`name_${this.currentLang}`]},`;

					}

				});

				// We get the user legal entity data
				const userLegalEntityData = allLegalEntities.filter((legalEntity) => parseInt(legalEntity.id) === parseInt(users.data[iUser].legal_entity_id))[0];

				// We get the user country name
				const userCountryCode = countries.find(parseInt(userLegalEntityData.country_id))[this.currentLang];

				// We define html label for RPO
				let htmlRpoLabel = "";
				if (users.data[iUser].is_rpo) {

					htmlRpoLabel = `<span class="item" data-i18n="form-label-field-rpo-light">${$.i18n("form-label-field-rpo-light")}</span>`;

				}

				// We define html label for Editor
				let htmlEditorLabel = "";
				if (users.data[iUser].is_editor) {

					htmlEditorLabel = `<span class="item" data-i18n="form-label-field-editor">${$.i18n("form-label-field-editor")}</span>`;

				}

				// We define the user line
				const htmlUserLine = `
				<tr class="item-line" data-user-id="${users.data[iUser].id}">
					<td class='user-name'>
						<div class='icon'></div>
						<div class='text'>
							<span class='lastname'>${users.data[iUser].lastname}</span>
							<span class='firstname'>${users.data[iUser].firstname}</span>
							${htmlRpoLabel}
							${htmlEditorLabel}
						</div>
					</td>
					<td class="list-items">
						<div class="item">
							<span class="icon fas fa-laptop-house"></span>
							<span class="text">${userLegalEntityData.name}</span>
						</div>
					</td>
					<td class="list-items">${userCountryCode}</td>
					<td class="list-items">${htmlUserCartographies}</td>
					<td>
						<div class='btn-edit'>
							<i class='icon fas fa-pencil-alt'></i>
						</div>
					</td>
				</tr>
			`;

				// We show the user line
				$("#users_list tbody").append(htmlUserLine);

			}

			// We listen click on add user button
			$(".btn-add-user").on("click", this.handleClickOnAddUserButton.bind(this));

		});

	}

	initDataTable () {

		return new Promise((resolve, reject) => {

			// We define columns titles
			$("#users_list thead tr th").eq(0).html($.i18n("table-filter-fullname"));
			$("#users_list thead tr th").eq(1).html($.i18n("table-filter-legal-entity"));
			$("#users_list thead tr th").eq(2).html($.i18n("table-filter-country"));
			$("#users_list thead tr th").eq(3).html($.i18n("table-filter-cartography"));

			// We define table language
			let dataTableLanguage;
			if (this.currentLang === "fr") {

				dataTableLanguage = dataTableLanguageFR;

			} else {

				dataTableLanguage = dataTableLanguageEN;

			}

			// Setup - add a text input to each footer cell
			$("#users_list thead tr").addClass("filters").appendTo("#users_list thead");

			/* eslint-disable */
			$('#users_list').DataTable({
				language: dataTableLanguage,
				orderCellsTop: true,
				lengthMenu: [ 25, 50, 100 ],
    		pageLength: 25,
    		searching: true,
    		paging: true,
    		ordering: true,
    		scrollX: true,
    		autoWidth: true,
    		drawCallback: function( settings ) {
	          // We get the current page URL
	          let curPageURL = window.location.pathname;

	          // We define the link selector of the pagination
	          let paginationLinkSelector = $("a.paginate_button");

	          // On each pagination link selector
	          $(paginationLinkSelector).each(function(){

	          		// We define the current page number
	              let pageNum = parseInt($(this).text());

	              // We define the page URL to show
	              let pageURLToShow;

	              if(!(Number.isNaN(pageNum))){
	              	// We update the page URL to show
	              	pageURLToShow = `${curPageURL}?page=${pageNum}`;

	              } else if ($(this).attr("class").indexOf("disabled") >= 0) {

              		// We update the page URL to show
              		pageURLToShow = curPageURL;

              	} else {
              		// We get the current page number
	              	const curPageNum = parseInt($("#users_list_paginate").find("span a.paginate_button.current").text());

	              	// We define the page number to show
	              	let pageNumToShow;

              		if ($(this).attr("class").indexOf("previous") >= 0) {

              			// We update the page number to show
	              		pageNumToShow = curPageNum - 1;

	              	} else {

	              		// We update the page number to show
	              		pageNumToShow = curPageNum + 1;

	              	}

	              	// We update the page URL to show
	              	pageURLToShow = `${curPageURL}?page=${pageNumToShow}`;
              	}

	              if (pageURLToShow !== undefined) {
	              	// We add the URL to show to the link href
	              	$(this).attr("href",pageURLToShow);
	              }
	          });
	          $("a.paginate_button").on("click", function(e){
	              e.preventDefault();
	          });
	      },
        initComplete: function () {
        	let api = this.api();

      		// We set input text filter on the first column (fullname)
      		api.columns([0])
	          .eq(0)
	          .each(function (colIdx) {
	              // Set the header cell to contain the input element
	              let cell = $('.filters th').eq(
	                  $(api.column(colIdx).header()).index()
	              );
	              let title = $(cell).text();
	              // We show the column title
	              $(cell).html(`<div class="column-title">${title} <div class="button-sort up"><span class="icon fas fa-sort-alpha-up-alt"></span></div><div class="button-sort down inactive"><span class="icon fas fa-sort-alpha-down"></span></div></div>`);
	              // We show the input text filter
	              $(cell).append('<input type="text" placeholder="' + $.i18n("filter-placeholder-fullname") + '" />');

	              $(cell).click( function(e) {

									// We change the sorting picto
									if ($(e.currentTarget).find(".column-title .button-sort.down").attr("class").indexOf("inactive") >= 0) {

										$(cell).find(".column-title .button-sort.up").addClass("inactive");
										$(cell).find(".column-title .button-sort.down").removeClass("inactive");

									} else {

										$(cell).find(".column-title .button-sort.up").removeClass("inactive");
										$(cell).find(".column-title .button-sort.down").addClass("inactive");

									}
			          });

	              $( cell ).find("input").click( function(e) {
			          	e.stopPropagation();
			          });

	              // On every keypress in this input
	              $(
	                  'input',
	                  $('.filters th').eq($(api.column(colIdx).header()).index())
	              )
	                  .off('keyup change')
	                  .on('change', function (e) {
	                      // Get the search value
	                      $(this).attr('title', $(this).val());
	                      let regexr = '({search})';

	                      // Search the column for that value
	                      api
	                          .column(colIdx)
	                          .search(
	                              this.value != ''
	                                  ? regexr.replace('{search}', '(((' + this.value + ')))')
	                                  : '',
	                              this.value != '',
	                              this.value == ''
	                          )
	                          .draw();
	                          $(this).blur();
	                  })
	                  .on('keyup', function (e) {
	                      e.stopPropagation();

	                      let cursorPosition = this.selectionStart;

	                      $(this).trigger('change');
	                      $(this)
	                          .focus()[0]
	                          .setSelectionRange(cursorPosition, cursorPosition);
	                  });
	          });

	        // We set input text filter on the second column (legal entity)
      		api.columns([1])
	          .eq(0)
	          .each(function (colIdx) {
	              // Set the header cell to contain the input element
	              let cell = $('.filters th').eq(
	                  $(api.column(colIdx).header()).index()
	              );
	              let title = $(cell).text();
	              // We show the column title
	              $(cell).html(`<div class="column-title">${title} <div class="button-sort up"><span class="icon fas fa-sort-alpha-up-alt"></span></div><div class="button-sort down inactive"><span class="icon fas fa-sort-alpha-down"></span></div></div>`);
	              // We show the input text filter
	              $(cell).append('<input type="text" placeholder="' + $.i18n("filter-placeholder-legal-entity") + '" />');

	              $(cell).click( function(e) {

			          	// We change the sorting picto
									if ($(e.currentTarget).find(".column-title .button-sort.down").attr("class").indexOf("inactive") >= 0) {

										$(cell).find(".column-title .button-sort.up").addClass("inactive");
										$(cell).find(".column-title .button-sort.down").removeClass("inactive");

									} else {

										$(cell).find(".column-title .button-sort.up").removeClass("inactive");
										$(cell).find(".column-title .button-sort.down").addClass("inactive");

									}
			          });

	              $( cell ).find("input").click( function(e) {
			          	e.stopPropagation();
			          });

	              // On every keypress in this input
	              $(
	                  'input',
	                  $('.filters th').eq($(api.column(colIdx).header()).index())
	              )
	                  .off('keyup change')
	                  .on('change', function (e) {
	                      // Get the search value
	                      $(this).attr('title', $(this).val());
	                      let regexr = '({search})';

	                      // Search the column for that value
	                      api
	                          .column(colIdx)
	                          .search(
	                              this.value != ''
	                                  ? regexr.replace('{search}', '(((' + this.value + ')))')
	                                  : '',
	                              this.value != '',
	                              this.value == ''
	                          )
	                          .draw();
	                          $(this).blur();
	                  })
	                  .on('keyup', function (e) {
	                      e.stopPropagation();

	                      let cursorPosition = this.selectionStart;

	                      $(this).trigger('change');
	                      $(this)
	                          .focus()[0]
	                          .setSelectionRange(cursorPosition, cursorPosition);
	                  });
	          });

      		// We set select filters on the country and cartography columns
          api.columns([2,3])
            .every(function (colIdx) {
              // We define the current column
              let column = this;
              // We get the current column title
              let columnTitle = $(column.header()).text();
              // We define the select option text by default
              let defaultSelectOptionText;
              if (colIdx === 2) {
              	defaultSelectOptionText = $.i18n("filter-option-see-all-country");
              } else {
              	defaultSelectOptionText = $.i18n("filter-option-see-all-cartography");
              }
              // We add the title in a container
              $(column.header()).html(`<div class="column-title">${columnTitle}</div>`);
              // We add the select filter on the column header
              let select = $(`<select><option value="">${defaultSelectOptionText}</option></select>`)
                .appendTo($(column.header()))
                .on('change', function () {
                    let val = $.fn.dataTable.util.escapeRegex($(this).val());
                    column.search(val ? val : '', false, false).draw();
                });

              $( select ).click( function(e) {
		          	e.stopPropagation();
		          });

              let addedOptions = [];
              column
                .data()
                .unique()
                .sort()
                .each(function (d, j) {
									let options = d.split(',');
									let i;
	                for (i=0; i<options.length; i++) {
	                  let val = options[i].trim();

	                  if ( ! addedOptions.includes( val )) {
	                    addedOptions.push( val );
	                  }
	                }
                });

                // We add the options on the select
                $.each(addedOptions.sort(), (label, value) => {
                	if(value !== undefined && value !== null && value !== "" && value !== " ") {

                		select.append( '<option value="'+ value +'">'+ value +'</option>' );

                	}
								});
            });
        },
    });
		/* eslint-enable */

		});

	}

	setContainerWidth () {

		// We get the width of the page
		const pageWidth = $("#page").width();

		// We set the admin container width
		$(".admin-container").css("width", `${pageWidth}px`);

		// We set the filter container width
		$(".admin-container .dataTables_scrollHead .dataTables_scrollHeadInner").css("width", `${pageWidth}px`);
		$(".admin-container .dataTables_scrollHead .dataTables_scrollHeadInner .admin-table").css("width", `${pageWidth}px`);

	}

	handleResizeWindow () {

		this.setContainerWidth();

	}

	handleClickOnUserLine (e) {

		const curUserId = $(e.currentTarget).attr("data-user-id");

		router.navigate(`/admin/user/show/${curUserId}`);

	}

	handleClickOnAddUserButton () {

		router.navigate("/admin/user/create");

	}

}
export default new UsersList();
