import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import Datapoint from "Core/Datapoint.js";

class ActionsPlan extends Datapoint {

	updateOne (actionPlanData) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/plan/update",
				"data": actionPlanData
			}).
				done(resolve).
				fail(reject);

		});


	}

	getData (params) {

		if (params === undefined) {

			debug.e("Error in getData of ActionsPlan, no params object has been set.");
			return;

		}

		if (params.idCartography === undefined) {

			debug.e("Error in getData of ActionsPlan, the Cartography id is undefined.");
			return;

		}

		if (params.idLegalEntity === undefined) {

			debug.e("Error in getData of ActionsPlan, the Legal Entity id is undefined.");
			return;

		}

		let cartographyId = params.idCartography;
		let legalEntityId = params.idLegalEntity;

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/plan/${cartographyId}/${legalEntityId}`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve(this.data);

				}).
				fail((error, textStatus) => {

					// We accept the 403 and the 404
					if (error.status == 403 || error.status == 404) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	find (idToFind) {

		let actionsPlan;
		if (this.data != undefined) {

			actionsPlan = this.data.find((action) => action.id == idToFind);

		}

		return actionsPlan;

	}


}
export default new ActionsPlan();
