import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import riskEvaluateForm from "Components/RiskEvaluateForm/RiskEvaluateForm.js";
import actionContainer from "Components/Action/ActionContainer.js";
import html from "./evaluate.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import actions from "Datapoints/Actions.js";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";

class RiskEvaluate extends Page {

	constructor () {

		let routes = [
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/evaluate",
			"/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity/cartographies/:idCartography/risks/:idRisk/evaluate",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/evaluate",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity/cartographies/:idCartography/risks/:idRisk/evaluate"
		];

		let datapointsToLoad = [
			users,
			cartographies,
			legalEntities,
			branches,
			countries,
			profitCenters,
			businessActivities,
			risks,
			actions,
			context
		];

		super(routes, AuthentifiedLayout, "Évaluation d'un risque", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEvaluateThisRisk.bind(this)
		];

	}

	canCurrentUserEvaluateThisRisk (params) {

		return new Promise((resolve, reject) => {

			let risk = risks.find(params.idRisk);

			if (user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography})) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		this.params = params;

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We init events
		this.initEvents();

	}

	initEvents (params) {

		// We show the risk title
		this.showRiskTitle();

		// If there is no business activity selected
		const curPathUrl = window.location.pathname;
		if (curPathUrl.indexOf("/business-activities/") < 0) {

			// We hide the list of business activities options
			$("#name-business-activity .option-selected").hide();

			// We define the title to show
			const htmlOption = `<span class="title-business-activity msg-error" data-i18n="msg-no-business-activity-selected-for-context">${$.i18n("msg-no-business-activity-selected-for-context")}</span>`;

			// We show the title
			$("#name-business-activity").append(htmlOption);

		}

		// We set the form to show by default
		this.showForm();

		// We listen click on the back button
		$(".btn-back-previous-page").on("click", this.handleClickOnBtnBack.bind(this));

	}

	showRiskTitle () {

		// We load the risks
		risks.getRisksCartography(this.params.idCartography).then(() => {

			// We get the risk
			const curRiskData = risks.find(this.params.idRisk);

			// We show the risk title
			if (curRiskData[`title_${this.currentLang}`] !== "" && curRiskData[`title_${this.currentLang}`] !== null) {

				$(".title-page h1 .risk-title").html(curRiskData[`title_${this.currentLang}`]);

			} else if (this.currentLang === "fr" && curRiskData.title_en !== null) {

				$(".title-page h1 .risk-title").html(curRiskData.title_en);

			} else if (this.currentLang === "en" && curRiskData.title_fr !== null) {

				$(".title-page h1 .risk-title").html(curRiskData.title_fr);

			} else {

				$(".title-page h1 .risk-title").html("");

			}

		});

		$(".title-page h1 .risk-title").on("click", (event) => {

			// We show the risk page
			router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}`);

		});

	}

	showForm () {

		// We show the form section
		$("#section-form").addClass("active");

		// We add the form to the section
		const formSelector = $("#section-form");
		riskEvaluateForm.show(formSelector, this.params, null);

	}

	handleClickOnBtnBack () {

		// We show the risk page
		router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}`);

	}

}
export default new RiskEvaluate();
