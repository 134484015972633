import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import Datapoint from "Core/Datapoint.js";
import CryptoJS from "crypto-js";

class LicencingVerification extends Datapoint {

	constructor () {

		super();
		this.licenceVerified = false;
		this.licenceHasBeenCheckedButIsNotValid = false;

	}

	check () {

		return new Promise((resolve, reject) => {

			if (process.env.CLIENT_LICENCING_API_KEY !== undefined && process.env.CLIENT_LICENCING_API_KEY !== "") {

				if (this.licenceVerified) {

					resolve();

				} else {

					let clientKey = process.env.CLIENT_LICENCING_API_KEY;
					let secretKey = process.env.SECRET_LICENCING_KEY;
					let version = process.env.VERSION_NUMBER;

					api.call({
						"method": "POST",
						"url": "/licencing/verify",
						"data": {
							"client_key": clientKey,
							version
						}
					}).
						done((data) => {

							// We make sure the passphrase is the same
							const receivedPassPhrase = data.passphrase;

							// We define our passphrase
							const today = new Date();
							const day = String(today.getDate()).padStart(2, "0");
							const year = today.getFullYear();
							const month = String(today.getMonth() + 1).padStart(2, "0");
							const dateStr = day + year + month;
							const dataToHash = version + clientKey + dateStr + secretKey;
							const ourPassPhrase = CryptoJS.SHA256(dataToHash).toString();
							if (receivedPassPhrase === ourPassPhrase) {

								this.licenceVerified = true;
								resolve();

							} else {

								this.licenceHasBeenCheckedButIsNotValid = true;
								reject("Passphrases do not match");

							}

						}).
						fail(() => {

							this.licenceHasBeenCheckedButIsNotValid = true;
							reject("Licencing server request failed");

						});

				}

			} else {

				resolve();
				debug.v("No client key defined");

			}

		});

	}

}
export default new LicencingVerification();
