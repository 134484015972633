import {replaceSpecialCharacters} from "Core/helpers.js";
import html from "./cartography_scale_field.html";
import cartographies from "Datapoints/Cartographies.js";
import scales from "Datapoints/Scales.js";
import Popin from "Components/Popin/Popin.js";

class CartographyScaleField {

	init (containerSelector, params, currentLang) {

		// We define the current language
		this.currentLang = currentLang;

		if (params !== undefined && params !== null) {

			// We get the current cartography ID
			this.cartographyId = parseInt(params.idCartography);

		} else {

			// We define the cartography ID to null
			this.cartographyId = null;

		}

		// We get the current cartography data
		this.cartographyData = cartographies.find(this.cartographyId);

		// We get the current cartography impacts
		if (this.cartographyData !== undefined && this.cartographyData !== null) {

			this.cartographyImpacts = this.cartographyData.impacts;

		} else {

			this.cartographyImpacts = null;

		}

		// We add the component html to the container
		$(containerSelector).append(html);

		// We define the scales
		this.dataCartographyScales = [];

		// We initialize the scales sections
		this.initScalesSections(this.cartographyImpacts);

		// If we are on cartography edit page and there are existing scales
		if (
			window.location.pathname.indexOf("/edit") >= 0 &&
			scales.data !== undefined &&
			scales.data.length > 0
		) {

			// We autofill the scales fields
			this.autoFillCartographyScales();

		}

	}

	autoFillCartographyScales () {

		// We filter scales by current cartography
		const scalesFilteredByCurrentCartography = scales.data.filter((scale) => parseInt(scale.cartography_id) === parseInt(this.cartographyId));

		if (scalesFilteredByCurrentCartography !== undefined && scalesFilteredByCurrentCartography.length > 0) {

			// We add the scales to the data cartography scales arrray
			this.dataCartographyScales = scalesFilteredByCurrentCartography;

			// For each scale
			$(scalesFilteredByCurrentCartography).each((i, scale) => {

				// We define the container selector
				let containerSelector;
				if (scale.impact_id !== undefined && scale.impact_id !== null) {

					containerSelector = $("#section_cartography_scales").find(`.label-result[data-id-impact='${scale.impact_id}']`);

				} else {

					containerSelector = $("#section_cartography_scales").find(`.label-result[data-field-name='${scale.field_name}']`);

				}

				$.each(scale.content, (index, item) => {

			    // Find the container with the appropriate data attribute
			    const container = containerSelector.find(`.content-form-field[data-num-field="${index}"]`);

			    // Update the values in the title_fr and title_en fields
			    container.find("input[name*=\"title_fr\"]").val(replaceSpecialCharacters(item.fr.title));
			    container.find("input[name*=\"title_en\"]").val(replaceSpecialCharacters(item.en.title));

			    // Update the values in the text_fr and text_en fields
			    container.find("textarea[name*=\"text_fr\"]").val(replaceSpecialCharacters(item.fr.text));
			    container.find("textarea[name*=\"text_en\"]").val(replaceSpecialCharacters(item.en.text));

				});

				// We add the scale ID in attribute
			  containerSelector.attr("data-id-scale", scale.id);

			  // We update the status
				this.switchStatus(containerSelector, "done");

			});

		}

	}

	initEvents (containerSelector) {

		// We listen click on the scale line
		containerSelector.find(".head-infos").on("click", (e) => {

			if (!$(e.target).hasClass("btn-delete-scale")) {

				this.handleClickOnScaleLine(e);

			}

		});

		// We listen click on the delete scale button
		containerSelector.find(".btn-delete-scale").on("click", this.handleClickOnDeleteScaleButton.bind(this));

		// We listen change on fields
		containerSelector.find("input[type='text'], textarea").on("input", this.handleChangeOnAllFields.bind(this));

		// We listen click on the submit scale button
		containerSelector.find("form.edit-scale .submit-button").on("click", this.handleClickOnSubmitEditScaleButton.bind(this));

	}

	initScalesSections (dataCartographyImpacts) {

		// We show a loader on the scales container
		$("#section_cartography_scales .tab-result").addClass("loading");
		$("<div class='result-loader'></div>").insertBefore("#section_cartography_scales .tab-result");

		Promise.all([this.showScalesSections(dataCartographyImpacts)]).then(() => {

			// We hide the scales loader
			$("#section_cartography_scales .tab-result").removeClass("loading");
			$(".result-loader").remove();

		});

	}

	showScalesSections (dataCartographyImpacts) {

		// We add the scale section for the field "occurrence"
		if ($("#section_cartography_scales .label-result[data-field-name='occurrence']").length < 1) {

			this.addScaleSection("occurrence", null);

		}

		// We add the scale section for the field "control"
		if ($("#section_cartography_scales .label-result[data-field-name='control']").length < 1) {

			this.addScaleSection("control", null);

		}

		// We show the scales for impacts fields
		if (
			dataCartographyImpacts !== undefined &&
			dataCartographyImpacts !== null
		) {

			// For each impacts field
			$(dataCartographyImpacts).each((i, impact) => {

				// We define the impact scale container
				let impactScaleContainer;

				if (impact.id !== undefined && impact.id !== null) {

					impactScaleContainer = $(`#section_cartography_scales .label-result[data-id-impact='${impact.id}']`);

				} else if (impact.index !== undefined && impact.index !== null) {

					impactScaleContainer = $(`#section_cartography_scales .label-result[data-index-impact='${impact.index}']`);

				}

				// We get the impact title in the scale container
				const impactTitleInScaleContainer = impactScaleContainer.find(".name-context .text .highlight").html();

				// If the impact scale container isn't already displayed
				if (impactScaleContainer.length < 1) {

					// We add it
					this.addScaleSection("impact", impact);

				} else if (impactTitleInScaleContainer !== impact[`name_${this.currentLang}`]) {

					// We update the impact title in the scale container
					impactScaleContainer.find(".name-context .text .highlight").html(impact[`name_${this.currentLang}`]);

				}

			});

		}

	}

	addScaleSection (fieldName, impactData) {

		// For envs with CONTROL (and not PROGRESS MARGIN)
		let envContainerClass = "";
		if (
			fieldName === "control" &&
			(process.env.SHOW_PROGRESS_MARGIN === undefined || process.env.SHOW_PROGRESS_MARGIN === false || process.env.SHOW_PROGRESS_MARGIN === "false")
		) {

			// We add a class to reverse the colors
			envContainerClass = "reverse";

		}

		// We get the current field title
		const curFieldTitle = this.getFieldTitle(fieldName, impactData);

		// We get the curent field icon
		const curFieldIcon = this.getFieldIcon(fieldName, impactData);

		// We define the current impact field ID and the current impact field index
		let impactFieldId;
		let impactFieldIndex;
		let impactFieldTitle;
		if (
			impactData !== null &&
			impactData !== undefined &&
			impactData.id !== undefined
		) { // FOR EXISTING IMPACT

			impactFieldId = impactData.id;
			impactFieldIndex = "";
			impactFieldTitle = impactData[`name_${this.currentLang}`];

		} else if (
			impactData !== null &&
			impactData !== undefined &&
			impactData.id === undefined &&
			impactData.index !== undefined
		) { // FOR NEW IMPACT

			impactFieldId = "new_impact";
			impactFieldIndex = impactData.index;
			impactFieldTitle = impactData[`name_${this.currentLang}`];

		} else { // FOR ANOTHER FIELD THAN IMPACT

			impactFieldId = "";
			impactFieldIndex = "";
			impactFieldTitle = "";

		}

		// We define the html of the scale section
		const htmlScaleSection = `
			<div class="label-result full-width accordion line-scale" data-field-name="${fieldName}" data-id-impact="${impactFieldId}" data-index-impact="${impactFieldIndex}" data-title-impact="${impactFieldTitle}">
				<div class="head-infos">
					<div class="info-context">
						<div class="name-context">
							<div class="text">
								${$.i18n("title-line-result-cartography-scale")} <span class="picto ${curFieldIcon}"></span> <span class="highlight">${curFieldTitle}</span>
							</div>
						</div>
						<div class="status todo">
							<span class="status-bullet"></span>
							<span class="status-text">${$.i18n("text-status-line-scale-not-ok")}</span>
						</div>
					</div>
					<div class="other-info">
						<div class="btn-edit-scale">
							<span class="icon fas fa-pencil-alt"></span>
						</div>
						<div class="btn-delete-scale">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
				<div class="hidden-content">
					<form class="edit-scale">
						<div class="content-form cols">${this.getScaleFieldsHtml(envContainerClass)}</div>
						<div class="line-submit-button">
							<div class="mention" data-i18n="text-mention-mandatory-all-fields">${$.i18n("text-mention-mandatory-all-fields")}</div>
							<div class="submit-button big inactive">
								<span class="button-icon fas fa-check"></span>
								<span class="button-text" data-i18n="button-validate">${$.i18n("button-validate")}</span>
							</div>
						</div>
					</form>
				</div>
			</div>
		`;


		// We show the scale section
		$("#section_cartography_scales .tab-result").append(htmlScaleSection);

		// We define the container selector
		let containerSelector;
		if (fieldName !== "impact") {

			containerSelector = $(`#section_cartography_scales .label-result[data-field-name='${fieldName}']`);

		} else if (
			impactFieldId !== undefined &&
			impactFieldId === "new_impact" &&
			impactFieldIndex !== undefined &&
			impactFieldIndex !== ""
		) {

			containerSelector = $(`#section_cartography_scales .label-result[data-index-impact='${impactFieldIndex}']`);

		} else {

			containerSelector = $(`#section_cartography_scales .label-result[data-id-impact='${impactFieldId}']`);

		}

		// We init the events
		this.initEvents(containerSelector);

	}

	getScaleFieldsHtml (envContainerClass) {

		// We define the number of scores
		const numScores = 6;

		// We define the html for scale fields
		let htmlScaleFields = "";

		for (let i = numScores; i > 0; i--) {

			htmlScaleFields += `
				<div class="content-form-field third-width ${envContainerClass}" data-num-field="${i}">
					<div class="title-num color-${i}">${i}</div>
					<div class="line-form-field">
						<div class="label">
							<i class="flag flag-fr"></i>
							<span class="text" data-i18n="form-label-field-title-fr">${$.i18n("form-label-field-title-fr")}</span>
						</div>
						<input class="text-field" type="text" name="scale_${i}_title_fr" />
					</div>
					<div class="line-form-field">
						<div class="label">
							<i class="flag flag-en"></i>
							<span class="text" data-i18n="form-label-field-title-en">${$.i18n("form-label-field-title-en")}</span>
						</div>
						<input class="text-field" type="text" name="scale_${i}_title_en" />
					</div>
					<div class="line-form-field">
						<div class="label">
							<i class="flag flag-fr"></i>
							<span class="text" data-i18n="form-label-field-description-fr">${$.i18n("form-label-field-description-fr")}</span>
						</div>
						<textarea class="textarea-field" type="text" name="scale_${i}_text_fr"></textarea>
					</div>
					<div class="line-form-field">
						<div class="label">
							<i class="flag flag-en"></i>
							<span class="text" data-i18n="form-label-field-description-en">${$.i18n("form-label-field-description-en")}</span>
						</div>
						<textarea class="textarea-field" type="text" name="scale_${i}_text_en"></textarea>
					</div>
				</div>
			`;

		}

		return htmlScaleFields;

	}

	removeImpactScaleSection (impactId, impactTitle) {

		// We define the container selector to remove
		let containerSelectorToRemove;

		if (impactId !== undefined && impactId !== null && impactId !== "undefined" && impactId !== "null") {

			containerSelectorToRemove = $(`#section_cartography_scales .label-result[data-id-impact='${impactId}']`);

		} else {

			containerSelectorToRemove = $(`#section_cartography_scales .label-result[data-title-impact='${impactTitle}']`);

		}

		// We remove the impact scale container
		containerSelectorToRemove.remove();

	}

	handleClickOnScaleLine (e) {

		// We define the container selector
		const containerSelector = $(e.currentTarget).parents(".label-result");

		if (containerSelector.attr("class").indexOf("active") < 0) {

			// We show the scale content
			this.showScaleContent(containerSelector);

		} else {

			// We hide the scale content
			this.hideScaleContent(containerSelector);

		}

	}

	handleClickOnDeleteScaleButton (e) {

		// We stop the click propagation on the parent
		e.stopPropagation();

		// We get the scale line to delete
		this.scaleLineToDelete = $(e.currentTarget).parents(".label-result");

		// Delete scale confirmation popin
		this.deleteScaleConfirmationPopin = new Popin("delete-scale-confirmation-popin", `${$.i18n("popin-delete-scale-confirmation-title")}`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteScaleCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteScaleConfirm.bind(this)
			}
		]);

		$(".popin").remove();

		this.deleteScaleConfirmationPopin.appendIn("#page");

		this.deleteScaleConfirmationPopin.show();

	}

	handleDeleteScaleCancel () {

		// We hide the popin
		this.deleteScaleConfirmationPopin.hide();

	}

	handleDeleteScaleConfirm () {

		// We get the current scale ID
		const curScaleId = this.scaleLineToDelete.data("id-scale");

		// We get the current impact ID
		const curImpactId = this.scaleLineToDelete.data("id-impact");

		// We get the current field name
		const curFieldName = this.scaleLineToDelete.data("field-name");

		// If the scale already exist
		if (curScaleId !== undefined && curScaleId !== null) {

			// We delete the scale
			scales.deleteOne(curScaleId).then(() => {}, () => {

				debug.v("CartographyScaleField - handleDeleteScaleConfirm - error in Scales deleteOne");

			});

		}

		// We define the index of the scale to delete
		let indexToRemove = -1;
		if (curImpactId !== undefined && curImpactId !== null) {

			for (let i = 0; i < this.dataCartographyScales.length; i++) {

			    if (parseInt(this.dataCartographyScales[i].impact_id) === parseInt(curImpactId)) {

			        indexToRemove = i;
			        break;

				}

			}

		} else {

			for (let i = 0; i < this.dataCartographyScales.length; i++) {

			    if (this.dataCartographyScales[i].field_name === curFieldName) {

			        indexToRemove = i;
			        break;

				}

			}

		}

		// We remove the scale on the data cartography scales array
		if (indexToRemove !== -1) {

			this.dataCartographyScales.splice(indexToRemove, 1);

		}

		// We hide the popin
		this.deleteScaleConfirmationPopin.hide();

		// We clear all the fields
		this.scaleLineToDelete.find(":input[type='text'], textarea").val("");

		// We hide the scale content
		this.hideScaleContent(this.scaleLineToDelete);

		// We update the status
		this.switchStatus(this.scaleLineToDelete, "todo");

	}

	handleClickOnSubmitEditScaleButton (e) {

		// We get the current button class
		const curButtonClass = $(e.currentTarget).attr("class");

		// If the button is not inactive
		if (curButtonClass.indexOf("inactive") < 0) {

			// We define the current container selector
			const containerSelector = $(e.currentTarget).parents(".label-result");

			// We get the current field name
			const curFieldName = containerSelector.data("field-name");

			// We get the current scale ID
			const curScaleId = containerSelector.data("id-scale");

			// We define the current field scale content
			const curFieldScaleContent = {};

			// For each scale field
			containerSelector.find(".content-form-field").each(function () {

		    // We get the current field num
		    const numField = $(this).data("num-field");

		    // We initialize the object for the current field number
		    curFieldScaleContent[numField] = {
		        "fr": {},
		        "en": {}
		    };

		    // We get the fields values for all languages
		    curFieldScaleContent[numField].fr.title = $(this).find("input[name*=\"_title_fr\"]").val();
		    curFieldScaleContent[numField].fr.text = $(this).find("textarea[name*=\"_text_fr\"]").val();
		    curFieldScaleContent[numField].en.title = $(this).find("input[name*=\"_title_en\"]").val();
		    curFieldScaleContent[numField].en.text = $(this).find("textarea[name*=\"_text_en\"]").val();

			});

			// We define the scale for the current field
			const curFieldScale = {
				"cartography_id": this.cartographyId,
				"content": curFieldScaleContent
			};

			// If the field name is an impact
			if (curFieldName === "impact") {

				// We get the current impact ID
				const curImpactId = containerSelector.data("id-impact");

				// We add the impact ID to the current field scale data
				curFieldScale.impact_id = curImpactId;

			} else {

				// We add the field name to the current field scale data
				curFieldScale.field_name = curFieldName;

			}

			// We have an exception for new impact
			if (curFieldScale.impact_id === "new_impact") {

				// We get the impact index
				const impactIndex = containerSelector.data("index-impact");

				// We define the impact selector
				const impactSelector = $("#section_cartography_impacts").find(`.label-result[data-index-cartography-impact='${impactIndex}']`);

				// We save the impact name FR and the impact name EN to find it
				curFieldScale.impact_name_fr = impactSelector.find("input.impact_fr").val();
				curFieldScale.impact_name_en = impactSelector.find("input.impact_en").val();

			}

			// If the scale already exist
			if (curScaleId !== undefined) {

				// We define the index of the scale in the global array
				let index = -1;
				for (let i = 0; i < this.dataCartographyScales.length; i++) {

				    if (this.dataCartographyScales[i].id === curScaleId) {

				        index = i;
				        break;

					}

				}

				if (index !== -1) {

					// We update the scale content
				  this.dataCartographyScales[index].content = curFieldScale.content;

				}


			} else {

				// We push the current field scale in the global array with all scales
				this.dataCartographyScales.push(curFieldScale);

			}

			// We hide the scale content
			this.hideScaleContent(containerSelector);

			// We update the status
			this.switchStatus(containerSelector, "done");

		}

	}

	handleChangeOnAllFields (e) {

		// We define the container selector
		const containerSelector = $(e.currentTarget).parents(".label-result");

		// We get the index of the current
		const curContainerIndex = containerSelector.index();

		// We select all fields of the current container
		const allFields = containerSelector.find("input[type='text'], textarea");

		// We check if all the fields are filled
		const allFieldsFilled = allFields.toArray().every((field) => $(field).val().trim() !== "");

		if (allFieldsFilled) {

			// We activate the submit button
			this.activeSubmitButton(curContainerIndex);

		} else {

    		// We reset the submit button
			this.resetSubmitButton(curContainerIndex);

		}

	}

	activeSubmitButton (containerIndex) {

		// We define the container selector
		const containerSelector = $("#cartography_scales .tab-result .label-result").eq(containerIndex);

		// We define the submit button selector
		const submitButtonSelector = containerSelector.find(".submit-button");

		// We define the mention selector
		const mentionSelector = containerSelector.find(".line-submit-button .mention");

		// We get the class of the submit button
		const submitButtonClass = submitButtonSelector.attr("class");

		// We get the class of the mention
		const mentionClass = mentionSelector.attr("class");

		if (submitButtonClass.indexOf("inactive") >= 0 && mentionClass.indexOf("inactive") < 0) {

			// We active the submit button
			containerSelector.find(".submit-button").removeClass("inactive");

			// We hide the mention
			mentionSelector.addClass("inactive");

		}

	}

	resetSubmitButton (containerIndex) {

		// We define the container selector
		const containerSelector = $("#cartography_scales .tab-result .label-result").eq(containerIndex);

		// We define the submit button selector
		const submitButtonSelector = containerSelector.find(".submit-button");

		// We define the mention selector
		const mentionSelector = containerSelector.find(".line-submit-button .mention");

		// We get the class of the submit button
		const submitButtonClass = submitButtonSelector.attr("class");

		// We get the class of the mention
		const mentionClass = mentionSelector.attr("class");

		if (submitButtonClass.indexOf("inactive") < 0 && mentionClass.indexOf("inactive") >= 0) {

			// We reset the submit button
			containerSelector.find(".submit-button").addClass("inactive");

			// We show the mention
			mentionSelector.removeClass("inactive");

		}

	}

	showScaleContent (containerSelector) {

		// We add the active class on the edit button
		containerSelector.find(".btn-edit-scale").addClass("active");

		// We add the active class on the container
		containerSelector.addClass("active");

		// We open the content
		containerSelector.find(".hidden-content").slideDown(200);

	}

	hideScaleContent (containerSelector) {

		// We remove the active class on the edit button
		containerSelector.find(".btn-edit-scale").removeClass("active");

		// We remove the active class on the container
		containerSelector.removeClass("active");

		// We close the content
		containerSelector.find(".hidden-content").slideUp(200);

	}

	switchStatus (containerSelector, status) {

		// We clear the status class
		this.clearStatusClass(containerSelector);

		if (status === "done") {

			// We add the class "done"
			containerSelector.find(".status").addClass("done");

			// We update the status text
			containerSelector.find(".status .status-text").html($.i18n("text-status-line-scale-ok"));

		} else {

			// We add the class "todo"
			containerSelector.find(".status").addClass("todo");

			// We update the status text
			containerSelector.find(".status .status-text").html($.i18n("text-status-line-scale-not-ok"));

		}

	}

	clearStatusClass (containerSelector) {

		// We remove the status class "todo"
		containerSelector.find(".status").removeClass("todo");

		// We remove the status class "done"
		containerSelector.find(".status").removeClass("done");

	}

	getFieldTitle (fieldName, impactData) {

		let fieldTitle;

		if (fieldName === "occurrence") {

			fieldTitle = $.i18n("form-label-field-occurrence");

		} else if (fieldName === "control") {

			fieldTitle = $.i18n("form-label-field-control");

		} else if (fieldName === "impact") {

			fieldTitle = impactData[`name_${this.currentLang}`];

		} else {

			fieldTitle = "";

		}

		return fieldTitle;

	}

	getFieldIcon (fieldName, impactData) {

		let fieldIcon;

		if (fieldName === "occurrence") {

			fieldIcon = "fa-solid fa-redo-alt";

		} else if (fieldName === "control") {

			fieldIcon = "fa-solid fa-thumbs-up";

		} else if (fieldName === "impact") {

			fieldIcon = impactData.picto;

		} else {

			fieldIcon = "fa-solid fa-question";

		}

		return fieldIcon;

	}

	getCartographyScales () {

		// We return the cartography scales
		return this.dataCartographyScales;

	}

}
export default new CartographyScaleField();
