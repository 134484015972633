import html from "./task.html";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import debug from "Core/Debug.js";

class Task {

	appendAndFill (cssSelector, data, isAdminButtons, risk) {

		// We show the task
		$(cssSelector).append(html);

		// We define the task
		const curTask = $(cssSelector).find(".item-task").last();

		// We add the id
		curTask.attr("data-item-id", data.id);

		// We add the name
		curTask.find(".desc-task .title").html(data.name);

		// We format and add the deadline date
		if (data.due_at != null) {

			const deadlineTask = this.formatDate(data.due_at);
			curTask.find(".desc-task .info-task .date .text").html(deadlineTask);

		}

		// We add the user name if needed
		if (data.handler_id != null && data.handler_id != undefined) {

			cartographies.getPossibleTaskHandlersForCartography(risk.cartography_id).then((users) => {

				$(users).each((i, userToCheck) => {

					if (data.handler_id == userToCheck.id) {

						curTask.find(".desc-task .info-task .user .firstname").html(userToCheck.firstname);
						curTask.find(".desc-task .info-task .user .lastname").html(userToCheck.lastname);

					}

				});

			}).catch((error) => {

				debug.v("Task - appendAndFill - error in User getPossibleTaskHandlersForCartography");

			});

		}

		// We add the author name
		const authorData = data.author;
		if (authorData != null) {

			const htmlLineAuthor = `${$.i18n("text-add-item-by")} <span class="highlight">${authorData.firstname} ${authorData.lastname}</span>`;
			curTask.find(".author .name").html(htmlLineAuthor);

		} else {

			curTask.find("a.desc-link .infos-link .author").html("");

		}

		// We add the admin buttons
		if (isAdminButtons) {

			const htmlAdminButtons = "<div class=\"admin-buttons\"><div class=\"btn-admin btn-edit\" title='Modifier la tâche'><span class=\"icon far fa-pencil-alt\"></span></div><div class=\"btn-admin btn-delete\" title='Supprimer la tâche'><span class=\"icon far fa-trash-alt\"></span></div></div>";

			curTask.append(htmlAdminButtons);

		}

		// We trigger the events on button clicks
		curTask.find(".btn-edit").on("click", () => {

			$(this).trigger("edit", [data.id]);

		});
		curTask.find(".btn-delete").on("click", () => {

			$(this).trigger("delete", [data.id]);

		});

	}

	formatDate (date) {

		return date.replace(/^(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1").replace("00:00:00", "");

	}

}
export default Task;
