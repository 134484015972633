import html from "./not_allowed.html";
import router from "Core/router.js";
import Page from "Pages/Page.js";
import LoginLayout from "Layouts/Login/LoginLayout.js";
import user from "Datapoints/User.js";
import "./not_allowed.scss";

class NotAllowed extends Page {

	constructor () {

		let routes = ["/not-allowed"];

		super(routes, LoginLayout, "Risk management");

	}

	render (params) {

		this.parseAndRender("page", html);

	}

}
export default new NotAllowed();
