import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import Datapoint from "Core/Datapoint.js";

class News extends Datapoint {

	createOne (newsToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/news/create",
				"data": newsToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (newsToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/news/update",
				"data": newsToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (newsId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/news/delete/${newsId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getData () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/news"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	find (idToFind) {

		let news;
		if (this.data != undefined) {

			news = this.data.find((news) => news.id == idToFind);

		}

		return news;

	}


}
export default new News();
