import html from "./card_risk.html";
import cartographies from "Datapoints/Cartographies";
import legalEntities from "Datapoints/LegalEntities";
import businessActivities from "Datapoints/BusinessActivities";
import risks from "Datapoints/Risks";
import user from "Datapoints/User.js";
import "./CardRisk.scss";

class CardRisk {

	constructor (data) {

		this.data = data;

	}

	appendAndFill (cssSelector, cardColor, data, scores) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the card
		$(cssSelector).append(html);

		// We define the card risk
		const cardRisk = $(cssSelector).find(".card-risk").last();

		// We add the color
		cardRisk.addClass(`color-${cardColor}`);

		// We add the ID
		cardRisk.addClass(`card-risk-${data.id}`);
		cardRisk.attr("data-id-risk", data.id);

		// We add the reference
		cardRisk.find(".top .ref .text").html(data.reference);

		// We add the theme with a limitation of characters
		let riskTheme = "";
		const themeLimit = 12;
		if (cartographies.find(data.cartography_id).themes != null) {

			let dataThemeCartography = cartographies.find(data.cartography_id).themes.filter((theme) => theme.slug === data.theme)[0];

			if (dataThemeCartography !== undefined) {

				riskTheme = dataThemeCartography.lib[`${this.currentLang}`];

			}

		}

		let curTheme;
		if (riskTheme === undefined || riskTheme === null || riskTheme === "") {

			cardRisk.find(".tag").remove();

		} else if (riskTheme.length >= themeLimit) {

			curTheme = riskTheme.substring(0, themeLimit).concat("", "...");

		} else {

			curTheme = riskTheme;

		}

		cardRisk.find(".tag .text").html(curTheme);

		// We add a title to the theme
		cardRisk.find(".tag").attr("title", curTheme);

		// We add the title
		if (data[`title_${this.currentLang}`] !== "" && data[`title_${this.currentLang}`] !== null) {

			cardRisk.find(".title-risk .text").html(data[`title_${this.currentLang}`]);

		} else if (this.currentLang === "fr" && data.title_en !== null) {

			cardRisk.find(".title-risk .text").html(data.title_en);

		} else if (this.currentLang === "en" && data.title_fr !== null) {

			cardRisk.find(".title-risk .text").html(data.title_fr);

		} else {

			cardRisk.find(".title-risk .text").html("");

		}

		// We add the impact score
		if (scores.impact) {

			const convertScoreImpact = typeof scores.impact === "string" ? parseFloat(scores.impact.replace(",", ".")) : scores.impact;

			cardRisk.find(".bottom .score-impact .tooltip-score .num").html(convertScoreImpact.toFixed(1));

		}

		// We add the occurrence score
		if (scores.occurrence) {

			let convertScoreOccurrence;

			if (scores.occurrence.average !== undefined) {

				convertScoreOccurrence = typeof scores.occurrence.average === "string" ? parseFloat(scores.occurrence.average.replace(",", ".")) : scores.occurrence.average;

				// We show min & max data
				this.showMinMaxData(data.id, "occurrence", scores.occurrence.min, scores.occurrence.max);

			} else {

				convertScoreOccurrence = typeof scores.occurrence === "string" ? parseFloat(scores.occurrence.replace(",", ".")) : scores.occurrence;

			}

			if (convertScoreOccurrence !== null) {

				cardRisk.find(".bottom .score-occurrence .tooltip-score .num").html(convertScoreOccurrence.toFixed(1));

			}

		}

		// We add the control score
		if (scores.control) {

			let convertScoreControl;

			if (scores.control.average !== undefined) {

				convertScoreControl = typeof scores.control.average === "string" ? parseFloat(scores.control.average.replace(",", ".")) : scores.control.average;

				// We show min & max data
				this.showMinMaxData(data.id, "control", scores.control.min, scores.control.max);

			} else {

				convertScoreControl = typeof scores.control === "string" ? parseFloat(scores.control.replace(",", ".")) : scores.control;

			}

			if (convertScoreControl !== null) {

				cardRisk.find(".bottom .score-control .tooltip-score .num").html(convertScoreControl.toFixed(1));

			}

		}

		// We show tooltips colors
		this.getTooltipsColors(cardRisk);

		// We show the scores bullets
		this.getScoreStars(cardRisk, scores);

		// We show the description
		if (data[`description_${this.currentLang}`] !== undefined && data[`description_${this.currentLang}`] !== null && data[`description_${this.currentLang}`] !== "") {

			cardRisk.find(".description-risk").html(data[`description_${this.currentLang}`].replace(/\n/g, "<br />"));

		} else if (this.currentLang === "fr" && data.description_en !== null && data.description_en !== undefined) {

			cardRisk.find(".description-risk").html(data.description_en.replace(/\n/g, "<br />"));

		} else if (this.currentLang === "en" && data.description_fr !== null && data.description_fr !== undefined) {

			cardRisk.find(".description-risk").html(data.description_fr.replace(/\n/g, "<br />"));

		} else {

			cardRisk.find(".description-risk").html("");
			$(".section-description-risk").hide();

		}

		// We add a target _blank in all description links
		cardRisk.find(".description-risk a").attr("target", "_blank");

		// We add a http:// prefix in all description links
		cardRisk.find(".description-risk a").each(function () {

			const curHrefLink = $(this).attr("href");
			const prefixUrl = "http://";

			if (curHrefLink.indexOf("http://") === -1) {

				$(this).attr("href", prefixUrl.concat(curHrefLink));

			}

		});

		// We show all consequencies
		if (
			data.consequences !== null &&
			data.consequences !== undefined
		) {

			$.each(data.consequences, (label, value) => {

				let htmlConsequence;

				if (value && label === "cancellation_concluded_agreements") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-ban\"></i></span><span class=\"text\">Annulation d'accords conclus ou impossibilité d'exécution</span></li>";

				} else if (value && label === "order_dispose_assets") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-puzzle-piece\"></i></span><span class=\"text\">Injonction de céder des actifs, de démanteler une structure créée</span></li>";

				} else if (value && label === "exclusion_from_public_procurement") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-times-circle\"></i></span><span class=\"text\">Exclusion des marchés publics</span></li>";

				} else if (value && label === "loss_of_opportunity") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-database\"></i></span><span class=\"text\">Perte d'opportunité suite à la perte/fuite de données</span></li>";

				} else if (value && label === "delays_conduct_projects") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-business-time\"></i></span><span class=\"text\">Retards/lourdeurs dans la conduite des projets</span></li>";

				} else if (value && label === "lapse_of_guarantees") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-ellipsis-h\"></i></span><span class=\"text\">Caducité de garanties</span></li>";

				} else if (value && label === "compromised_investment") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-hand-holding-usd\"></i></span><span class=\"text\">Investissement compromis / non retour sur investissement en cas de défaillance d'une start-up</span></li>";

				} else if (value && label === "loss_competitive_advantage") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-microchip\"></i></span><span class=\"text\">Perte d'un avantage compétitif (vol de technologie)</span></li>";

				} else if (value && label === "loss_market") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-chart-pie\"></i></span><span class=\"text\">Perte de parts de marché ou d'un marché</span></li>";

				} else if (value && label === "loss_research_opportunity") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-search-dollar\"></i></span><span class=\"text\">Perte d'opportunité de recherche</span></li>";

				} else if (value && label === "prohibition_to_exercise") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-hand-paper\"></i></span><span class=\"text\">Cessation d'activité / interdiction d'exercer</span></li>";

				} else if (value && label === "loss_competitiveness") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-signal-slash\"></i></span><span class=\"text\">Perte de compétitivité</span></li>";

				} else if (value && label === "site_closure") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-store-slash\"></i></span><span class=\"text\">Fermeture de site</span></li>";

				} else if (value && label === "loss_license") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-file-certificate\"></i></span><span class=\"text\">Perte de licence / d'autorisation</span></li>";

				} else if (value && label === "loss_market_confidence") {

					htmlConsequence = "<li><span class=\"icon\"><i class=\"fas fa-users-slash\"></i></span><span class=\"text\">Perte de confiance des marchés</span></li>";

				}

				cardRisk.find(".consequences-risk ul.list-consequences").append(htmlConsequence);

			});

		} else {

			$(".section-consequences-risk").hide();
			$("#section-consequences-risk").hide();

		}

		// If we are on a graph export preview page
		if (window.location.pathname.indexOf("/export-preview/graph") >= 0) {

			this.setCardForGraphExportPreview(cardRisk);

		}

		// We init the site translation
		$("html").i18n();

	}

	setCardForGraphExportPreview (cardRisk) {

		const titleRiskSelector = cardRisk.find(".title-risk");
		const bottomRiskSelector = cardRisk.find(".bottom");

		// We add a new container on the riks card
		$("<div class='head-risk'></div>").insertBefore(titleRiskSelector);

		// We add the risk title and the bottom risk in this new container
		$(titleRiskSelector).appendTo(cardRisk.find(".head-risk"));
		$(bottomRiskSelector).appendTo(cardRisk.find(".head-risk"));

	}

	showMinMaxData (idRisk, scoreSlug, dataMin, dataMax) {

		// We don't show min and max data on dashboard list and when all business activities are selected
		if (
			window.location.pathname.indexOf("/dashboard-list") < 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0 &&
			window.location.pathname !== "/dashboard-grid" &&
			window.location.pathname !== "/dashboard-grid/" &&
			window.location.pathname !== "/" &&
			window.location.pathname !== ""
		) {

			const tooltipSelector = $(`.card-risk-${idRisk}`).find(`.score-${scoreSlug} .tooltip-score`);

			// We add a new class to the tooltip container
			tooltipSelector.addClass("average-active");

			// We add the container for min and max data
			tooltipSelector.append("<div class='box-min-max'></div>");

			// We define html for max data
			const maxDataLine = `<div class="line-min-max"><span class="icon">MAX</span><span class="name-entity">${legalEntities.find(dataMax.legal_entity_id).name}</span><span class="num-entity">${parseFloat(dataMax.value).toFixed(1)}</span></div>`;

			// We show the max line
			tooltipSelector.find(".box-min-max").append(maxDataLine);

			// We define html for min data
			const minDataLine = `<div class="line-min-max"><span class="icon">MIN</span><span class="name-entity">${legalEntities.find(dataMin.legal_entity_id).name}</span><span class="num-entity">${parseFloat(dataMin.value).toFixed(1)}</span></div>`;

			// We show the min line
			tooltipSelector.find(".box-min-max").append(minDataLine);

		}

	}

	getCardColor (item) {

		let itemColor;

		if (item !== undefined && item !== null) {

			// Get the net risk
			const scoreNetRisk = typeof item.scores.net_risk === "string" ? parseFloat(item.scores.net_risk.replace(",", ".")) : item.scores.net_risk;

			if (scoreNetRisk !== undefined && scoreNetRisk !== null) {

				if (scoreNetRisk >= 0 && scoreNetRisk <= 2.25) {

					itemColor = "green";

				} else if (scoreNetRisk > 2.25 && scoreNetRisk <= 3.5) {

					itemColor = "yellow";

				} else if (scoreNetRisk > 3.5 && scoreNetRisk <= 4.75) {

					itemColor = "orange";

				} else if (scoreNetRisk > 4.75 && scoreNetRisk <= 6) {

					itemColor = "red";

				} else {

					itemColor = "grey";

				}

			} else {

				itemColor = "grey";

			}

		} else {

			itemColor = "grey";

		}

		return itemColor;

	}

	getScoreStars (jquerySelector, scores) {

		// We get the scores for impacts
		const scoresForImpacts = [];
		scoresForImpacts.push(scores.impacts);

		// We get the scores for other labels (impact, occurrence, control)
		const scoresForOtherLabels = Object.keys(scores).
		  filter((key) => [
				"occurrence",
				"control",
				"impact"
			].includes(key)).
		  reduce((obj, key) => {

		    obj[key] = scores[key];
		    return obj;

			}, {});

		// We get score stars for impacts
		this.getScoreStarsForImpacts(jquerySelector, scoresForImpacts);

		// We get score stars for other labels (impact, occurrence, control)
		this.getScoresForOtherLabels(jquerySelector, scoresForOtherLabels);


	}

	getScoreStarsForImpacts (jquerySelector, scores) {

		$(scores[0]).each((i, impact) => {

			let scoreValue;

			if (impact !== null && impact.average !== undefined) {

				scoreValue = impact.average;

			} else {

				scoreValue = impact.score;

			}

			let htmlStars;

			if (scoreValue) {

				// We convert value in number
				const convertValue = typeof scoreValue === "string" ? parseFloat(scoreValue.replace(",", ".")) : scoreValue;
				const newValue = this.roundHalf(convertValue.toFixed(1));

				// We get the html for stars to show
				htmlStars = this.getHtmlStars(newValue);

			} else {

				// We get the html for stars with no score value
				htmlStars = this.getHtmlStars();

			}

			// We show stars
			jquerySelector.find(`.item-score[data-id-impact='${impact.id}'] ul.list-stars`).html(htmlStars);

		});

	}

	getScoresForOtherLabels (jquerySelector, scores) {

		$.each(scores, (label, value) => {

			let scoreValue;

			if (value !== null && value.average !== undefined) {

				scoreValue = value.average;

			} else {

				scoreValue = value;

			}

			let htmlStars;

			if (scoreValue) {

				// We convert value in number
				const convertValue = typeof scoreValue === "string" ? parseFloat(scoreValue.replace(",", ".")) : scoreValue;
				const newValue = this.roundHalf(convertValue.toFixed(1));

				// We get the html for stars to show
				htmlStars = this.getHtmlStars(newValue);

			} else {

				// We get the html for stars with no score value
				htmlStars = this.getHtmlStars();

			}

			// We show stars
			jquerySelector.find(`.score-${label} ul.list-stars`).html(htmlStars);

		});

	}

	roundHalf (num) {

		return Math.round(num * 2) / 2;

	}

	getHtmlStars (value) {

		let htmlStars;

		if (value !== undefined && value !== null) {

			// The classes "empty", "half" and "full" are used to recognized the stars in the browser tests. Otherwise we would have to recognise svg path data.
			if (value == 0) {

				htmlStars = "<li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 0.5) {

		  	htmlStars = "<li class=\"item-star fas fa-adjust half\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 1) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 1.5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-adjust half\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 2) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 2.5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-adjust half\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 3) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 3.5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-adjust half\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 4) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star far fa-circle empty\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 4.5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-adjust half\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star far fa-circle empty\"></li>";

			} else if (value == 5.5) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-adjust half\"></li>";

			} else if (value == 6) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li>";

			} else if (value > 6) {

				htmlStars = "<li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li><li class=\"item-star fas fa-circle full\"></li>";

			} else {

				htmlStars = "<li class=\"item-star no-value fas fa-minus\"></li><li class=\"item-star no-value fas fa-minus\"></li><li class=\"item-star no-value fas fa-minus\"></li><li class=\"item-star no-value fas fa-minus\"></li><li class=\"item-star no-value fas fa-minus\"></li><li class=\"item-star no-value fas fa-minus\"></li>";

			}

		}

		return htmlStars;

	}

	getTooltipsColors (cardRisk) {

		cardRisk.find(".tooltip-score").each(function () {

			// We get the tooltip score
			const curScore = $(this).find(".num").html();

			let curTooltipColor;

			if (curScore >= 0 && curScore <= 2.25) {

				curTooltipColor = "green";

			} else if (curScore > 2.25 && curScore <= 3.5) {

				curTooltipColor = "yellow";

			} else if (curScore > 3.5 && curScore <= 4.75) {

				curTooltipColor = "orange";

			} else if (curScore > 4.75 && curScore <= 6) {

				curTooltipColor = "red";

			} else {

				curTooltipColor = "default";

			}

			// We have an exception if we are not on a progress margin model
			if (process.env.SHOW_PROGRESS_MARGIN === undefined || process.env.SHOW_PROGRESS_MARGIN === false || process.env.SHOW_PROGRESS_MARGIN === "false") {

				// If the tooltip is for control
				if ($(this).parents(".score").attr("class").indexOf("control") >= 0) {

					// We reverse the colors
					if (curScore >= 0 && curScore <= 2.25) {

						curTooltipColor = "red";

					} else if (curScore > 2.25 && curScore <= 3.5) {

						curTooltipColor = "orange";

					} else if (curScore > 3.5 && curScore <= 4.75) {

						curTooltipColor = "yellow";

					} else if (curScore > 4.75 && curScore <= 6) {

						curTooltipColor = "green";

					} else {

						curTooltipColor = "default";

					}

				}

			}

			$(this).addClass(`color-${curTooltipColor}`);

		});

	}

	appendAndFillForEvaluationVersion (cssSelector, cardColor, data, scores) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the card
		$(cssSelector).append(html);

		// We define the card risk
		const cardRisk = $(cssSelector).find(".card-risk").last();

		// We delete the default ref and tag
		cardRisk.find(".ref").remove();
		cardRisk.find(".tag").remove();

		// We add the color
		cardRisk.addClass(`color-${cardColor}`);

		// We add the ID
		cardRisk.attr("data-id-evaluation", data.id);

		// We get the current business activity data
		const curBusinessActivity = businessActivities.find(data.business_activity_id);

		// We define the name of the business activity
		let curBusinessActivityName;
		if (curBusinessActivity !== undefined) {

			if (curBusinessActivity[`name_${this.currentLang}`] !== "" && curBusinessActivity[`name_${this.currentLang}`] !== null) {

				curBusinessActivityName = curBusinessActivity[`name_${this.currentLang}`];

			} else if (this.currentLang === "fr" && curBusinessActivity.title_en !== null) {

				curBusinessActivityName = curBusinessActivity.title_en;

			} else if (this.currentLang === "en" && curBusinessActivityName.title_fr !== null) {

				curBusinessActivityName = curBusinessActivity.title_fr;

			} else {

				curBusinessActivityName = "";

			}

			// We add the business activity
			const htmlRefBusinessActivity = `
				<div class="ref">
	        <div class="icon fas fa-building"></div>
	        <div class="text business-activity">${curBusinessActivityName}</div>
	      </div>
			`;
			cardRisk.find(".top").append(htmlRefBusinessActivity);

		}

		// We add the impact score
		if (scores.impact) {

			const convertScoreImpact = typeof scores.impact === "string" ? parseFloat(scores.impact.replace(",", ".")) : scores.impact;

			cardRisk.find(".bottom .score-impact .tooltip-score .num").html(convertScoreImpact.toFixed(1));

		}

		// We add the occurrence score
		if (scores.occurrence) {

			let convertScoreOccurrence;

			if (scores.occurrence.average !== undefined) {

				convertScoreOccurrence = typeof scores.occurrence.average === "string" ? parseFloat(scores.occurrence.average.replace(",", ".")) : scores.occurrence.average;

				// We show min & max data
				this.showMinMaxData(data.id, "occurrence", scores.occurrence.min, scores.occurrence.max);

			} else {

				convertScoreOccurrence = typeof scores.occurrence === "string" ? parseFloat(scores.occurrence.replace(",", ".")) : scores.occurrence;

			}

			if (convertScoreOccurrence !== null) {

				cardRisk.find(".bottom .score-occurrence .tooltip-score .num").html(convertScoreOccurrence.toFixed(1));

			}

		}

		// We add the control score
		if (scores.control) {

			let convertScoreControl;

			if (scores.control.average !== undefined) {

				convertScoreControl = typeof scores.control.average === "string" ? parseFloat(scores.control.average.replace(",", ".")) : scores.control.average;

				// We show min & max data
				this.showMinMaxData(data.id, "control", scores.control.min, scores.control.max);

			} else {

				convertScoreControl = typeof scores.control === "string" ? parseFloat(scores.control.replace(",", ".")) : scores.control;

			}

			if (convertScoreControl !== null) {

				cardRisk.find(".bottom .score-control .tooltip-score .num").html(convertScoreControl.toFixed(1));

			}

		}

		// If the risk is ignored
		if (parseInt(data.risk_ignored) === 1 || data.risk_ignored) {

			// We define the html for the risk ignored content
			const htmlRiskIgnoredText = `
				<div class="risk-ignored-content">
					<span class="icon fas fa-exclamation-triangle"></span>
					<span class="text" data-i18n="risk-label-risk-ignored">${$.i18n("risk-label-risk-ignored")}</span>
				</div>
			`;

			// We show the risk ignored content (and remove scores)
			cardRisk.find(".bottom").html(htmlRiskIgnoredText);

		}

		// Net risk score
		if (scores.net_risk !== undefined && scores.net_risk !== null) {

			// We define the html for the net risk score
			const htmlNetRiskScore = `
				<div class="gross-net-score" id='version-net-score'>
					<div class="text" data-i18n="risk-label-net-risk">${$.i18n("risk-label-net-risk")}</div>
					<div class="num">${parseFloat(scores.net_risk)}</div>
				</div>
			`;

			// We show the net risk score
			cardRisk.find(".bottom").prepend(htmlNetRiskScore);

		}

		// Gross risk score
		if (scores.gross_risk !== undefined && scores.gross_risk !== null) {

			// We define the html for the gross risk score
			const htmlGrossRiskScore = `
				<div class="gross-net-score" id='version-gross-score'>
					<div class="text" data-i18n="risk-label-gross-risk">${$.i18n("risk-label-gross-risk")}</div>
					<div class="num">${parseFloat(scores.gross_risk)}</div>
				</div>
			`;

			// We show the gross risk score
			cardRisk.find(".bottom").prepend(htmlGrossRiskScore);

		}

		// We show tooltips colors
		this.getTooltipsColors(cardRisk);

		// We show the scores bullets
		this.getScoreStars(cardRisk, scores);

		// We init the site translation
		$("html").i18n();

	}

	appendAndFillForUserAdmin (cssSelector, data) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the card
		$(cssSelector).append(html);

		// We define the card risk
		const cardRisk = $(cssSelector).find(".card-risk").last();

		// We define the card risk color
		const cardRiskColor = "blue";

		// We add the color
		cardRisk.addClass(`color-${cardRiskColor}`);

		// We add the ID
		cardRisk.addClass(`card-risk-${data.id}`);
		cardRisk.attr("data-id-risk", data.id);

		// We add the reference
		cardRisk.find(".top .ref .text").html(data.reference);

		// We add the theme with a limitation of characters
		let riskTheme = "";
		const themeLimit = 12;
		if (cartographies.find(data.cartography_id).themes != null) {

			let dataThemeCartography = cartographies.find(data.cartography_id).themes.filter((theme) => theme.slug === data.theme)[0];

			if (dataThemeCartography !== undefined) {

				riskTheme = dataThemeCartography.lib[`${this.currentLang}`];

			}

		}

		let curTheme;
		if (riskTheme === undefined || riskTheme === null || riskTheme === "") {

			cardRisk.find(".tag").remove();

		} else if (riskTheme.length >= themeLimit) {

			curTheme = riskTheme.substring(0, themeLimit).concat("", "...");

		} else {

			curTheme = riskTheme;

		}

		cardRisk.find(".tag .text").html(curTheme);

		// We add a title to the theme
		cardRisk.find(".tag").attr("title", curTheme);

		// We add the title
		if (data[`title_${this.currentLang}`] !== "" && data[`title_${this.currentLang}`] !== null) {

			cardRisk.find(".title-risk .text").html(data[`title_${this.currentLang}`]);

		} else if (this.currentLang === "fr" && data.title_en !== null) {

			cardRisk.find(".title-risk .text").html(data.title_en);

		} else if (this.currentLang === "en" && data.title_fr !== null) {

			cardRisk.find(".title-risk .text").html(data.title_fr);

		} else {

			cardRisk.find(".title-risk .text").html("");

		}

		// We define the html for the risk cartography
		const htmlRiskCartography = `
			<div class="item-cartography">
				<span class="icon fas fa-chalkboard-teacher"></span>
				<span class="text">${data[`cartography_name_${this.currentLang}`]}</span>
			</div>
		`;

		// We show the cartography item
		cardRisk.find(".top").prepend(htmlRiskCartography);

		// We remove the bottom (evaluation) of the card risk
		cardRisk.find(".bottom").remove();

		// We init the site translation
		$("html").i18n();

	}

}
export default CardRisk;
