import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import graphsContainer from "Components/Graphs/GraphsContainer.js";
import html from "./dashboard_graph.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import "../Dashboard.scss";
import "../DashboardResponsive.scss";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";

class DashboardGraph extends Page {

	constructor () {

		let routes = [
			"/dashboard-graph",
			"/dashboard-graph/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-graph/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-graph/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-graph/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-graph/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-graph/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			countries,
			businessActivities,
			context
		];

		super(routes, AuthentifiedLayout, "Risk Management Platform", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		// We get the params
		this.params = params;

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We define the current cartography
		this.cartography = cartographies.find(cartographies.getCurrentCartographyId());

		// We define the current legal entity
		this.legalEntity = legalEntities.find(context.getCurrentLegalEntityId());

		// We init buttons for admin cartography
		this.initAdminButtons(this.cartography.id);

		// We init risks data
		this.initRisks();

		// We initialize context events
		this.initContextEvents();

	}

	initContextEvents () {

		// We listen click on options select legal entity
		$("#name-legal-entity").on("select2:select", (e) => {

		  this.handleClickOnOptionLegalEntity();

		});

	}

	initAdminButtons (cartographyId) {

		// We define new params
		const newParams = {
			"idCartography": cartographyId
		};

		// We get the current legal entity
		const legalEntity = legalEntities.find(context.getCurrentLegalEntityId());

		// We listen click on create button
		$("#btn-add-risk .link").on("click", this.handleClickOnCreateButton.bind(this));

		// We add the cartography id in the link URL of the suggest risk button
		const curHrefBtnSuggestRisk = $("#btn-suggest-risk a").attr("href");
		$("#btn-suggest-risk a").attr("href", `/cartographies/${cartographyId}${curHrefBtnSuggestRisk}`);

		// We check if user have right to evaluate risks
		user.canEvaluateThisCartographyForThisLegalEntity(cartographyId, legalEntity.id).then((data) => {

			if (data) {

				// We show the suggest risk button
				$("#btn-suggest-risk").addClass("active");

			} else {

				// We remove the suggest risk button
				$("#btn-suggest-risk").remove();

			}

		});

		// We check if user have right to admin risks
		user.canAdminRisks(newParams).then((e) => {

			// We remove the suggest risk button
			$("#btn-suggest-risk").remove();

		}).catch((e) => {

			$("#btn-add-risk").remove();

		});

	}

	handleClickOnCreateButton () {

		router.navigate(`/legal-entities/${this.legalEntity.id}/cartographies/${this.cartography.id}/risk/create`);

	}

	initRisks () {

		return new Promise((resolve, reject) => {

			// We get the current branch id
			const curBranchId = context.getCurrentBranchId();

			// We get the current profit center id
			const curProfitCenterId = context.getCurrentProfitCenterId();

			// We get the current cartography id
			const curCartographyId = cartographies.getCurrentCartographyId();

			// We load the current legal entity
			const curLegalEntityId = context.getCurrentLegalEntityId();

			// We get the current country id
			const curCountryId = context.getCurrentCountryId();

			// We defines booleean to ease algo reading
			const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
			const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
			const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
			const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
			const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
			const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
			const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

			// If no parameters are given, we check if the legal entities has business activities
			if (!paramsGiven) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else if (isProfitCenterOnAll && isLegalEntityOnAll) {

				this.loadRisksForBranch(curCartographyId, curBranchId, resolve, reject);

			} else if (isCountryOnAll && isLegalEntityOnAll) {

				this.loadRisksForProfitCenter(curCartographyId, curProfitCenterId, resolve, reject);

			} else if (isLegalEntityOnAll) {

				this.loadRisksForCountry(curCartographyId, curCountryId, resolve, reject);

			} else if (isBusinessActivityAnId) {

				this.loadRisksForBusinessActivity(curCartographyId, curLegalEntityId, this.params.idBusinessActivity, resolve, reject);

			} else if (isBusinessActivityOnAll) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			}

		}).catch(console.e);

	}

	loadRisksForBusinessActivity (curCartographyId, curLegalEntityId, businessActivityId, resolve, reject) {

		// We load evaluations
		cartographies.getEvaluationForBusinessActivity(curCartographyId, curLegalEntityId, businessActivityId).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(curCartographyId).then((risks) => {

				this.evaluation = evaluations;

				// We remove risks content
				$("#section-not-evaluated-risks .cols-grid").html("");
				$("#section-evaluated-risks .content-col-grid").html("");
				$("#section-ignored-risks .cols-grid").html("");

				// We load the graphs
				graphsContainer.init(risks, this.evaluation);

				resolve();

			}).catch((error) => {

				debug.e("DashboardGraph - loadRisksForBusinessActivity - error in Risks getRisksCartography");
				reject();

			});

		}).catch((error) => {

			debug.e("DashboardGraph - loadRisksForBusinessActivity - error in Cartographies getEvaluationForBusinessActivity");
			reject();

		});

	}

	loadRisksForLegalEntity (curCartographyId, curLegalEntityId, resolve, reject) {

		legalEntities.getBusinessActivities(curLegalEntityId).then((businessActivities) => {

			// We show legal entity consolidation if at least one business activity exists
			if (businessActivities.length > 0) {

				const contextData = {
					"cartography_id": curCartographyId,
					"legal_entity_id": curLegalEntityId
				};

				// We load evaluations for context
				cartographies.getEvaluationForContext(contextData).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						this.evaluation = evaluations;

						// We remove risks content
						$("#section-not-evaluated-risks .cols-grid").html("");
						$("#section-evaluated-risks .content-col-grid").html("");
						$("#section-ignored-risks .cols-grid").html("");

						// We load the graphs
						graphsContainer.init(risks, this.evaluation);

						resolve();

					});

				}).catch((error) => {

					debug.v("DashboardGraph - loadRisksForLegalEntity - error in Cartographies getEvaluationForContext");
					reject();

				});

			}
			// Otherwise we show legal entity evaluations
			else {

				cartographies.getEvaluation(curCartographyId, curLegalEntityId).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						this.evaluation = evaluations;

						// We remove risks content
						$("#section-not-evaluated-risks .cols-grid").html("");
						$("#section-evaluated-risks .content-col-grid").html("");
						$("#section-ignored-risks .cols-grid").html("");

						// We load the graphs
						graphsContainer.init(risks, this.evaluation);

						resolve();

					});

				}).catch((error) => {

					debug.v("DashboardGraph - loadRisksForLegalEntity - error in Cartographies getEvaluation");
					reject();

				});

			}

		});

	}

	loadRisksForBranch (curCartographyId, curBranchId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"branch_id": curBranchId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForProfitCenter (curCartographyId, curProfitCenterId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"profit_center_id": curProfitCenterId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForCountry (curCartographyId, curCountryId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"country_id": curCountryId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForContext (contextData, resolve, reject) {

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(contextData.cartography_id).then((risks) => {

				this.evaluation = evaluations;

				// We remove risks content
				$("#section-not-evaluated-risks .cols-grid").html("");
				$("#section-evaluated-risks .content-col-grid").html("");
				$("#section-ignored-risks .cols-grid").html("");

				// We load the graphs
				graphsContainer.init(risks, this.evaluation);

				resolve();

			});

		}).catch((error) => {

			debug.v("DashboardGraph - loadRisksForContext - error in Cartographies getEvaluationForContext");
			reject();

		});

	}

	handleClickOnOptionLegalEntity (e) {

		// We init risks data
		this.initRisks();

	}

}
export default new DashboardGraph();
