import router from "Core/router.js";
import api from "Core/Api.js";
import user from "Datapoints/User.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Branches extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			if (this.data != undefined && !forceRequest) {

				resolve();
				return;

			}

			api.call({
				"method": "GET",
				"url": "/branches"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				});

		});

	}

	find (idToFind) {

		let branch;
		if (this.data !== undefined) {

			branch = this.data.find((branch) => parseInt(branch.id) === parseInt(idToFind));

		}

		return branch;

	}

	getLib (idBranch) {

		let branch = this.find(idBranch);
		return branch != undefined ? branch.fr : "";

	}

}
export default new Branches();
