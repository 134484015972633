import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./list.html";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import legalEntities from "Datapoints/LegalEntities.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import "../../Admin.scss";
import "../../AdminResponsive.scss";

class BusinessActivitiesList extends Page {

	constructor () {

		let routes = ["/admin/business-activities"];

		let datapointsToLoad = [
			users,
			businessActivities,
			branches,
			cartographies,
			legalEntities
		];

		super(routes, AuthentifiedLayout, "Liste des activités opérationnelles", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminBusinessActivities.bind(user)
		];

	}

	render () {

		return new Promise((resolve, reject) => {

			// We get the current language
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);

			this.idBusinessActivityToDelete = null;

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.initListBusinessActivities());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initListBusinessActivities () {

		if (businessActivities.data !== undefined && businessActivities.data.length > 0) {

			// We clear container
			$("#business_activities_list").html("");

			// We loop on business activities
			for (let iBusinessActivity = 0; iBusinessActivity < businessActivities.data.length; iBusinessActivity++) {

				const curBusinessActivity = businessActivities.data[iBusinessActivity];
				const curBusinessActivityId = curBusinessActivity.id;
				const curBusinessActivityName = curBusinessActivity[`name_${$.i18n().locale}`];

				// If the user do not have admin_business_activities right on this branch,
				// we skip it
				if (!user.hasRight("admin_business_activities", {"branch_id": curBusinessActivity.branch_id})) {

					continue;

				}

				const businessActivityBranchName = branches.find(curBusinessActivity.branch_id)[`${$.i18n().locale}`];

				let htmlBusinessActivityBranch = "";

				if (businessActivityBranchName !== undefined) {

					htmlBusinessActivityBranch = `<div class='item-branch'><div class="icon fas fa-code-branch"></div><div class="admin-branch-name">${businessActivityBranchName}</div></div>`;

				}

				// We add the business activity to the list
				$("#business_activities_list").append(`<li><div class="item-line" data-business-activity-id="${curBusinessActivityId}"><div class='business-activity-name'><div class='text'>${curBusinessActivityName}</div></div>${htmlBusinessActivityBranch}<div class='btn-edit'><i class='icon fas fa-pencil-alt'></i></div><div class='btn-delete'><i class='icon fas fa-trash-alt'></i></div></div></li>`);

			}

			// We listen the clic on the delete button
			$("#business_activities_list .item-line .btn-delete").on("click", this.handleClickOnDeleteBusinessActivityButton.bind(this));

			// We listen the clic on the edit button
			$("#business_activities_list .item-line").on("click", this.handleClickOnEditBusinessActivityButton.bind(this));

		} else {

			// We show the no business activity message
			this.showNoBusinessActivityMessage();

		}

		if ($("#business_activities_list li").length <= 0) {

			// We show the no business activity message
			this.showNoBusinessActivityMessage();

		}

		// We listen click on create button
		$(".btn-create").on("click", this.handleClickOnCreateButton.bind(this));

	}

	showNoBusinessActivityMessage () {

		// We define the message
		const htmlMessage = `<div class="msg-no-item"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-no-business-activity">${$.i18n("msg-no-business-activity")}</span></div></div>`;

		// We add the message to the container
		$("#business_activities_list").html(htmlMessage);

	}

	handleClickOnCreateButton () {

		router.navigate("/admin/business-activity/create");

	}

	handleClickOnEditBusinessActivityButton (e) {

		const businessActivityId = $(e.currentTarget).attr("data-business-activity-id");

		router.navigate(`/admin/business-activity/edit/${businessActivityId}`);

	}

	handleClickOnDeleteBusinessActivityButton (e) {

		// We prevent the event to bubble to line event
		e.stopPropagation();

		const businessActivityId = $(e.currentTarget).parents(".item-line").attr("data-business-activity-id");

		this.initDeleteBusinessActivity(businessActivityId);

	}

	initDeleteBusinessActivity (businessActivityId) {

		this.idBusinessActivityToDelete = parseInt(businessActivityId);

		const curBusinessActivity = businessActivities.find(businessActivityId);

		let curBusinessActivityName;
		if (this.currentLang === "fr" && curBusinessActivity.name_fr !== undefined && curBusinessActivity.name_fr !== null && curBusinessActivity.name_fr !== "") {

			curBusinessActivityName = curBusinessActivity.name_fr;

		} else if (this.currentLang === "fr" && (curBusinessActivity.name_fr === undefined || curBusinessActivity.name_fr === null || curBusinessActivity.name_fr === "")) {

			curBusinessActivityName = curBusinessActivity.name_en;

		} else if (this.currentLang === "en" && curBusinessActivity.name_en !== undefined && curBusinessActivity.name_en !== null && curBusinessActivity.name_en !== "") {

			curBusinessActivityName = curBusinessActivity.name_en;

		} else if (this.currentLang === "en" && (curBusinessActivity.name_en === undefined || curBusinessActivity.name_en === null || curBusinessActivity.name_en === "")) {

			curBusinessActivityName = curBusinessActivity.name_fr;

		}

		// Delete popin
		this.deleteBusinessActivityPopin = new Popin("delete-business-activity-popin", `${$.i18n("popin-delete-business-activity-confirmation-title")} ${curBusinessActivityName} ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteBusinessActivityCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteBusinessActivityConfirm.bind(this)
			}
		]);
		$(".popin").remove();
		this.deleteBusinessActivityPopin.appendIn("#page");

		this.deleteBusinessActivityPopin.show();

	}

	handleDeleteBusinessActivityCancel () {

		this.deleteBusinessActivityPopin.hide();

	}

	handleDeleteBusinessActivityConfirm () {

		businessActivities.deleteOne(this.idBusinessActivityToDelete).then(() => {

			this.deleteBusinessActivityPopin.hide();

			businessActivities.getData({"forceRequest": true}).then(() => {

				this.initListBusinessActivities();

			});

		}).catch((error) => {

		});

	}

}
export default new BusinessActivitiesList();
