import Dashboard from "../Dashboard.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import html from "./dashboard_list.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import context from "Datapoints/Context.js";


class DashboardList extends Dashboard {

	constructor () {

		let routes = [
			"/dashboard-list",
			"/dashboard-list/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-list/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-list/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-list/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/dashboard-list/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/dashboard-list/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			countries,
			businessActivities,
			context
		];

		super(routes, AuthentifiedLayout, "Risk Management Platform", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

		this.html = html;

	}

	initEvents () {

		// We listen to click on the button to sort by color
		$("#btn-sort-color").on("click", this.handleClickOnBtnSortByColor.bind(this));

		// We listen to click on the button to sort by date
		$("#btn-sort-date").on("click", this.handleClickOnBtnSortByDate.bind(this));

		// We listen to click on the button to sort by theme
		$("#btn-sort-theme").on("click", this.handleClickOnBtnSortByTheme.bind(this));

		// We define sort by default in risks list view
		$("#btn-sort-date").addClass("active");
		$("#btn-sort-color").removeClass("active");
		$("#btn-sort-theme").removeClass("active");


	}

	afterShowRisks (riskData) {

		this.allRisks = riskData;

		if (riskData !== undefined && riskData.length > 0) {

			// We show the sorts options
			$(".box-sort").addClass("active");

			this.sortRisksByDateDescending();

		} else {

			// We hide the sorts options
			$(".box-sort").removeClass("active");
			$(".line-buttons-sort").remove();

			// We hide the list
			$(".list-content").removeClass("active");

		}

	}


	handleClickOnBtnSortByColor (e) {

		this.sorting = "colorDesc";
		this.sortRisksByColorDescending();
		this.updateButtonsToCurrentSorting();

	}

	handleClickOnBtnSortByTheme (e) {

		this.sorting = "themeAsc";
		this.sortRisksByThemeAscending();
		this.updateButtonsToCurrentSorting();

	}

	handleClickOnBtnSortByDate (e) {

		this.sorting = "dateDesc";
		this.sortRisksByDateDescending();
		this.updateButtonsToCurrentSorting();

	}

	updateButtonsToCurrentSorting () {

		switch (this.sorting) {

			case "dateDesc":
				$("#btn-sort-color").removeClass("active");
				$("#btn-sort-theme").removeClass("active");
				$("#btn-sort-date").addClass("active");
				break;

			case "themeAsc":
				$("#btn-sort-color").removeClass("active");
				$("#btn-sort-theme").addClass("active");
				$("#btn-sort-date").removeClass("active");
				break;

			case "colorDesc":
				$("#btn-sort-color").addClass("active");
				$("#btn-sort-theme").removeClass("active");
				$("#btn-sort-date").removeClass("active");
				break;

		}

	}

	sortRisksByColorDescending () {

		$("#section-evaluated-risks .card-risk").sort((a, b) => {

			const rankColorA = this.getRankColor(a);
			const rankColorB = this.getRankColor(b);

			return rankColorB - rankColorA;

		}).appendTo("#section-evaluated-risks");

		this.sorting = "colorDesc";

	}

	getRankColor (cardRisk) {

		let color = $(cardRisk).attr("class").match(/card-risk color-(.*) card-risk-/)[1];

		let rankColor = 0;
		switch (color) {

			case "green":
				rankColor = 1;
				break;

			case "yellow":
				rankColor = 2;
				break;

			case "orange":
				rankColor = 3;
				break;

			case "red":
				rankColor = 4;
				break;

		}

		return rankColor;

	}

	sortRisksByThemeAscending () {

		// Evaluated risks
		$("#section-evaluated-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return riskA.theme.localeCompare(riskB.theme);

		}).appendTo("#section-evaluated-risks");


		// Non evaluated risks
		$("#section-not-evaluated-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return riskA.theme.localeCompare(riskB.theme);

		}).appendTo("#section-not-evaluated-risks");

		// Ignored risks
		$("#section-ignored-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return riskA.theme.localeCompare(riskB.theme);

		}).appendTo("#section-ignored-risks");

		// Pending risks
		$("#section-pending-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return riskA.theme.localeCompare(riskB.theme);

		}).appendTo("#section-pending-risks");

		this.sorting = "themeAsc";

	}

	sortRisksByDateDescending () {

		// Evaluated risks
		$("#section-evaluated-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return new Date(riskB.updated_at) - new Date(riskA.updated_at);

		}).appendTo("#section-evaluated-risks");

		// Non evaluated risks
		$("#section-not-evaluated-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return new Date(riskB.updated_at) - new Date(riskA.updated_at);

		}).appendTo("#section-not-evaluated-risks");

		// Ignored risks
		$("#section-ignored-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return new Date(riskB.updated_at) - new Date(riskA.updated_at);

		}).appendTo("#section-ignored-risks");

		// Pending risks
		$("#section-pending-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return new Date(riskB.updated_at) - new Date(riskA.updated_at);

		}).appendTo("#section-pending-risks");

		this.sorting = "dateDesc";

	}

	addRiskToEvaluatedSection (card, data, evaluation) {

		const cardColor = card.getCardColor(evaluation);
		const selector = "#section-evaluated-risks";
		card.appendAndFill(selector, cardColor, data, evaluation.scores);

	}

	addRiskToNotEvaluatedSection (card, data) {

		const selector = "#section-not-evaluated-risks";
		card.appendAndFill(selector, "grey", data, []);

		this.updateNotEvaluatedCounter();

	}

	addRiskToIgnoredSection (card, data) {

		const selector = "#section-ignored-risks";
		card.appendAndFill(selector, "grey", data, []);

		this.updateIgnoredCounter();

	}

	addRiskToPendingSection (card, data) {

		const selector = "#section-pending-risks";
		card.appendAndFill(selector, "blue", data, []);

		this.updatePendingCounter();

	}

	showContent (indexContentToShow) {

		// We define item content
		const itemContent = $(".home-container .mask-content .item-content");

		// We show the content
		if (itemContent.eq(indexContentToShow).attr("class").indexOf("active") < 0) {

			this.clearContentView();
			itemContent.eq(indexContentToShow).addClass("active");

		}

	}

	clearSwitchView () {

		$(".home-container .switch-view").removeClass("active-grid");
		$(".home-container .switch-view").removeClass("active-list");
		$(".home-container .switch-view").removeClass("active-graph");

	}

	clearContentView () {

		$(".home-container .mask-content .item-content").removeClass("active");

	}

}
export default new DashboardList();
