import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import user from "Datapoints/User.js";
import Datapoint from "Core/Datapoint.js";

class Context extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData (forceReload = false) {

		return new Promise((resolve, reject) => {

			if (this.data === undefined) {

				// We define default context parameters
				let contextParams = {};

				// We get url parameters
				let urlParams;
				if (router._lastRouteResolved !== undefined && router._lastRouteResolved !== null) {

					urlParams = router._lastRouteResolved.params;

				}

				if (urlParams !== undefined && urlParams !== null) {

					if (urlParams.idBranch !== undefined && urlParams.idBranch !== null) {

						contextParams.branch_id = parseInt(urlParams.idBranch);

					}

					if (urlParams.idLegalEntity !== undefined && urlParams.idLegalEntity !== null) {

						contextParams.legal_entity_id = parseInt(urlParams.idLegalEntity);

					}

				}

				api.call({
					"method": "POST",
					"url": "/user/get-context",
					"data": contextParams
				}, false).
					done((dataReturned) => {

						this.updateData(dataReturned.selected);
						this.allContextData = dataReturned;
						resolve();

					});

			} else {

				resolve();

			}

		});

	}

	setContext (contextParams) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/get-context",
				"data": contextParams
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned.selected);
					this.allContextData = dataReturned;
					resolve();

				});

		});

	}

	getBranches () {

		return this.allContextData != undefined ? this.allContextData.branches : undefined;

	}

	getCurrentBranch () {

		return this.data != undefined ? this.data.branch : undefined;

	}

	getCurrentBranchId () {

		return this.getCurrentBranch() != undefined ? this.getCurrentBranch().id : undefined;

	}

	getProfitCenters () {

		let profitCenters;
		if (this.allContextData != undefined) {

			if (process.env.FILTERED_CONTEXT === true || process.env.FILTERED_CONTEXT === "true") {

				profitCenters = this.getFilteredProfitCenters();

			} else {

				profitCenters = this.allContextData.profit_centers;

			}

		}

		return profitCenters;

	}

	getFilteredProfitCenters () {

		// We show only the profit centers for legal entities in the selected branch
		let legalEntitiesInBranch = this.allContextData.legal_entities.filter((legalEntity) => {

			let isInBranch = this.getCurrentBranchId() != undefined ? legalEntity.branch_id === this.getCurrentBranchId() : true;

			return isInBranch;

		});
		let profitCentersId = legalEntitiesInBranch.map((legalEntity) => legalEntity.profit_center_id);

		return this.allContextData.profit_centers.filter((profitCenter) => profitCentersId.includes(profitCenter.id));

	}

	getCurrentProfitCenter () {

		return this.data != undefined ? this.data.profit_center : undefined;

	}

	getCurrentProfitCenterId () {

		return this.getCurrentProfitCenter() != undefined ? this.getCurrentProfitCenter().id : undefined;

	}

	getCountries () {

		let countries;
		if (this.allContextData != undefined) {

			if (process.env.FILTERED_CONTEXT === true || process.env.FILTERED_CONTEXT === "true") {

				countries = this.getFilteredCountries();

			} else {

				countries = this.allContextData.countries;

			}

		}

		return countries;

	}

	getFilteredCountries () {

		// We show only the countries for legal entities in the selected profit center
		let legalEntitiesInProfitCenter = this.allContextData.legal_entities.filter((legalEntity) => {

			let isInProfitCenter = this.getCurrentProfitCenterId() != undefined ? legalEntity.profit_center_id === this.getCurrentProfitCenterId() : true;

			return isInProfitCenter;

		});
		let countriesId = legalEntitiesInProfitCenter.map((legalEntity) => legalEntity.country_id);

		return this.allContextData.countries.filter((country) => countriesId.includes(country.id));

	}

	getCurrentCountry () {

		return this.data != undefined ? this.data.country : undefined;

	}

	getCurrentCountryId () {

		return this.getCurrentCountry() != undefined ? this.getCurrentCountry().id : undefined;

	}

	getLegalEntities () {

		let legalEntities;
		if (this.allContextData != undefined) {

			if (process.env.FILTERED_CONTEXT === true || process.env.FILTERED_CONTEXT === "true") {

				legalEntities = this.getFilteredLegalEntities();

			} else {

				legalEntities = this.allContextData.legal_entities;

			}

		}

		return legalEntities;

	}

	getFilteredLegalEntities () {

		// We filter legal entities to the current branch, profit center and country
		let legalEntities;
		if (this.allContextData != undefined) {

			legalEntities = this.allContextData.legal_entities.filter((legalEntity) => {

				let isInBranch = this.getCurrentBranchId() != undefined ? legalEntity.branch_id === this.getCurrentBranchId() : true;
				let isInProfitCenter = this.getCurrentProfitCenterId() != undefined ? legalEntity.profit_center_id === this.getCurrentProfitCenterId() : true;
				let isInCountry = this.getCurrentCountryId() != undefined ? legalEntity.country_id === this.getCurrentCountryId() : true;

				return isInBranch && isInProfitCenter && isInCountry;

			});

		}

		return legalEntities;

	}

	getCurrentLegalEntity () {

		return this.data != undefined ? this.data.legal_entity : undefined;

	}

	getCurrentLegalEntityId () {

		return this.getCurrentLegalEntity() != undefined ? this.getCurrentLegalEntity().id : undefined;

	}

}
export default new Context();
