import router from "Core/router.js";
import user from "Datapoints/User.js";
import debug from "Core/Debug.js";
import loader from "Components/Loader/Loader.js";

export default class Page {

	constructor (routes, layout, title, datapointsToLoad, datapointsToLoadForceRequest) {

		this.debugLabel = this.constructor.name.length > 1 ? this.constructor.name : routes[0];

		debug.v(`${this.debugLabel} - constructor - start`);

		if (routes != undefined) {

			this.setRoute(routes);

		} else {

			debug.e(`No route defined for page ${this.debugLabel}, the page is not accessible.`);

		}

		if (layout != undefined) {

			this.layout = layout;

		} else {

			debug.i(`No layout defined for page ${this.debugLabel}.`);

		}

		if (title != undefined) {

			this.title = title;

		} else {

			debug.i(`No title defined for page ${this.debugLabel}`);

		}

		// We take the datapoints from page defition
		let datapointsToLoadFromPage = datapointsToLoad != undefined ? datapointsToLoad : [];
		datapointsToLoadFromPage = _.isArray(datapointsToLoadFromPage) ? datapointsToLoadFromPage : [datapointsToLoadFromPage];

		// We take the datapoints from layout defition
		let datapointsToLoadFromLayout = this.layout != undefined && this.layout.datapointsToLoad != undefined ? this.layout.datapointsToLoad : [];
		datapointsToLoadFromLayout = _.isArray(datapointsToLoadFromLayout) ? datapointsToLoadFromLayout : [datapointsToLoadFromLayout];

		// We merge both
		this.datapointsToLoad = datapointsToLoadFromPage.concat(datapointsToLoadFromLayout);

		// We take the forceRequests if they exists
		this.datapointsToLoadForceRequest = datapointsToLoadForceRequest;

		debug.v(`${this.debugLabel} - constructor - end`);

	}

	// We setup the routes wanted for this page
	setRoute (routes) {

		for (let iRoute = 0; iRoute < routes.length; iRoute++) {

			// We handle the NotFound
			if (routes[iRoute] == "404") {

				router.notFound(this.renderLayoutWhenDocumentLoadingIsComplete.bind(this), {
					"before": this.datapointLoad.bind(this),
					"after": this.afterPageChange.bind(this)
				});

			} else {

				router.on(routes[iRoute], this.renderLayoutWhenDocumentLoadingIsComplete.bind(this), {
					"before": this.datapointLoad.bind(this),
					"after": this.afterPageChange.bind(this)
				});

			}

		}

	}

	datapointLoad (done, params) {

		// We init params if it's undefined
		if (params === undefined || params === null) {

			params = {};

		}

		debug.v(`1 ======= > ${this.debugLabel} - datapointLoad`);
		// We show the loader here in case the layout is already loaded (changing page)
		loader.show();

		// If no datapoint is given, we render right away
		if (this.datapointsToLoad == undefined || _.isArray(this.datapointsToLoad) && this.datapointsToLoad.length == 0) {

			debug.v(`${this.debugLabel} - datapointLoad - no datapoints needed`);

			// We hide the loader
			loader.hide();

			this.checkRights(done, params);
			return;

		}

		// If only one datapoint is given, we transform to array
		if (this.datapointsToLoad.length == undefined) {

			this.datapointsToLoad = [this.datapointsToLoad];

		} else { // If it's already an array, we remove duplicates - https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array

			let previousDatapointsToLoad = this.datapointsToLoad;
			let previousNumberOfDatapoints = this.datapointsToLoad.length;

			this.datapointsToLoadFiltered = this.datapointsToLoad.filter((item, pos, self) => self.indexOf(item) == pos);
			let newNumberOfDatapoints = this.datapointsToLoadFiltered.length;

			if (previousNumberOfDatapoints > newNumberOfDatapoints) {

				debug.i(`Datapoints have been reduced in ${this.debugLabel} (${previousNumberOfDatapoints} > ${newNumberOfDatapoints}).`);

			}

		}

		// We loop on the data to load (promises) if they exists
		let datapointsPromises = [];
		for (let iData = 0; iData < this.datapointsToLoad.length; iData++) {

			debug.v(`${this.debugLabel} - Preparing the load of ${this.datapointsToLoad[iData].constructor.name}`);

			if (this.datapointsToLoad[iData] == undefined || typeof this.datapointsToLoad[iData].getData != "function") {

				// debug.e(`${this.debugLabel} - The datapoint '${iData}' ('${this.datapointsToLoad[iData].constructor.name}') to load has no getData function`);

			} else {

				// If we want to force request on this datapoint
				if (this.datapointsToLoadForceRequest !== undefined && this.datapointsToLoadForceRequest.includes(this.datapointsToLoad[iData])) {

					params.forceRequest = true;

				}

				let getData = this.datapointsToLoad[iData].getData.bind(this.datapointsToLoad[iData])(params);
				// We make sure the function is a promise
				if (typeof getData.then != "function") {

					debug.e(`${this.debugLabel} - The datapoint to load getData function of ${this.datapointsToLoad[iData].constructor.name} is not a promise (not 'then-able') - https://stackoverflow.com/a/27746324/3005056`);

				} else {

					datapointsPromises.push(getData);

				}

			}

		}
		Promise.all(datapointsPromises).then((result) => {

			debug.v(`${this.debugLabel} - datapoint loads - end in resolve`);

			// We hide the loader
			loader.hide();

			this.checkRights(done, params);

		}, (error) => {

			if (error != undefined) {

				if (error.status == 401) {

					debug.v(`${this.debugLabel} - User is not logged in so he can't load ${error.objCalling}`);

				} else if (error.status == 403) {

					debug.v(`${this.debugLabel} - User is not allowed to load ${error.objCalling}`);

				} else {

					debug.e(`Load problem of ${error.objCalling} in datapointsToLoad of ${this.debugLabel}`);
					debug.e(error);

				}

			} else {

				debug.e(`Load problem in datapointsToLoad of ${this.debugLabel} with no error`);

			}

			// We hide the loader
			loader.hide();

			this.checkRights(done, params);


		});

	}

	checkRights (done, params) {

		debug.v(`2 ======= > ${this.debugLabel} - checkRights`);

		// First we check if the user need to reset his password
		if (user.data.status == "tempPassword" && document.location != `${router.root}/user/change-temporary-password`) {

			router.navigate("/user/change-temporary-password");

		}

		// We suppose the visitor can access the page
		let isAllowed = true;

		// We loop on right validation functions if they exists
		let rightsValidations = this.rightsValidations != undefined ? this.rightsValidations : [];
		let validationsPromises = [];
		for (let iRight = 0; iRight < rightsValidations.length; iRight++) {

			let promValidation = rightsValidations[iRight](params);

			// We make sure the function is a promise
			if (typeof promValidation.then != "function") {

				debug.e(`${this.debugLabel} - The rights validations '${promValidation.name}' function is not a promise (not 'then-able') - https://stackoverflow.com/a/27746324/3005056`);

			} else {

				validationsPromises.push(promValidation);

			}

		}

		// We wait for the promises to resolve
		Promise.all(validationsPromises).then((results) => {

			results.forEach((isAllowedInPromise) => {

				if (!isAllowedInPromise) {

					isAllowed = false;

				}

			});

			if (!isAllowed) {

				debug.v(`${this.debugLabel} - checkRights - not allowed`);

				done(false);

				user.redirectUnauthorizedUser();

			} else {

				debug.v(`${this.debugLabel} - checkRights - allowed`);
				this.beforeRender(done);

			}

		}).catch((error) => {

			if (error != undefined && error.code != undefined && (error.code == 401 || error.code == 403)) {


				debug.v(`${this.debugLabel} - checkRights - not allowed`);

				done(false);

				user.redirectUnauthorizedUser();

			} else {

				debug.e(`${this.debugLabel} - Error in either one of the promises of this.rightsValidations, in the layout rendering or in the page rendering.`);
				debug.e(error);

			}

		});

	}


	// Before render, we make sure the user has the rights needed
	beforeRender (done, params) {

		// We remove a class on the body
		$("body").removeClass("select-context-show-on-page");

		debug.v(`${this.debugLabel} - no beforeRender function defined`);
		done(true);

	}

	renderLayoutWhenDocumentLoadingIsComplete (params) {

		if (document.readyState === "complete") {

			this.renderLayout(params);

		} else {

			setTimeout(() => {

				this.renderLayoutWhenDocumentLoadingIsComplete(params);

			}, 100);

		}

	}

	renderLayout (params) {

		return new Promise((resolve, reject) => {

			debug.v(`3 ======= > ${this.debugLabel} - renderLayout - ${this.layout.constructor.name}`);

			this.layout.render();
			// We show the loader here in case the layout was not already loaded (loading first page)
			loader.show();

			$("TITLE").html(this.title != undefined ? this.title : "");

			if (params == null || params == undefined || params == "") {

				this.params = {};

			} else {

				this.params = params;

			}

			try {

				loader.hide();

				const renderCall = this.render(params);

				// If render function is a promise, we chain the resolution to the end of it
				if (renderCall != undefined && typeof renderCall.then === "function") {

					renderCall.then(resolve).catch(reject);

				} else { // Otherwise, we resolve it right after

					resolve();

				}

			} catch (error) {

				loader.hide();

				debug.e(`${this.debugLabel} - Error while rendering (in render() function).`);
				debug.e(error);

			}

		}).then(() => {

			this.afterRender(params);

		}).catch(debug.e);

	}

	afterPageChange (params) {

		debug.v(`5 ======= > ${this.debugLabel} - default afterPageChange function `);

		// We scrool to the top of the page
		$("html, body").animate({"scrollTop": 0}, 400);

	}

	// This is supposed to be surcharged on the Page définition
	render (params) {

		return new Promise((resolve, reject) => {

			debug.v(`6 ======= > ${this.debugLabel} - default render function `);

			this.parseAndRender("page", "");

			resolve();

		});

	}

	afterRender (params) {

		debug.v(`7 ======= > ${this.debugLabel} - default afterRender function `);

		// After render we make sure the shown links are handled by the router, not thrue the browser
		router.refreshLinks();

	}

	parseAndRender (idToFill, htmlToUse) {

		// We replace params before insertion
		for (const param in this.params) {

			const regexToReplace = new RegExp(`:${param}`, "gi");

			htmlToUse = htmlToUse.replace(regexToReplace, this.params[param]);

		}

		$(`#${idToFill}`).html(htmlToUse);

	}

	// TODO : passer un selecteur, pas un ID
	autoFillPage (data, containerId) {

		for (const prop in data) {

			let selector = `.${prop}`;
			if (containerId != undefined) {

				selector = `#${containerId} .${prop}`;

			}

			$(selector).html(data[prop]);

		}

	}

	autoFillForm (formCssSelector, data, arrayInput = "") {

		for (const prop in data) {

			let inputName = arrayInput != "" ? `${arrayInput}[${prop}]` : prop;

			if (_.isObject(data[prop])) {

				this.autoFillForm(formCssSelector, data[prop], prop);

			} else {

				let input = $(`${formCssSelector} [name='${inputName}']`);

				if (input.length > 0) {

					// We handle checkboxes and radio
					if (input[0].type == "checkbox") {

						if (input.val() == data[prop]) {

							input.attr("checked", true);

						}

					} else if (input[0].type == "radio") { // Radios

						for (let iRadio = 0; iRadio < input.length; iRadio++) {

							let radio = input[iRadio];
							if ($(radio).val() == data[prop]) {

								$(radio).attr("checked", true);

							}

						}

					} else if ($(input).attr("type") == "text" || $(input).attr("type") == "number" || $(input).attr("type") == "email") { // If it's an input, we set the value attribute so the selenium webdriver can see it

						$(input).attr("value", data[prop]);

					} else if ($(input)[0].tagName.toLowerCase() == "select") {

						$(input).find("option").filter(function () {

							return $(this).val() == data[prop] || $(this).text() == data[prop];

						}).attr("selected", "selected");


					} else if ($(input)[0].tagName.toLowerCase() == "textarea") {

						$(input).html(data[prop]);

					}

				}


			}

		}

	}

	getFieldnameFromObject (baseField, data) {

		let fieldName = baseField;

		if (_.isObject(data[baseField])) {

			fieldName += `.${this.getFieldnameFromObject(data[baseField], data)}`;

		}

		return fieldName;

	}

	// TODO : passer un selecteur et pas une id
	clearFormErrors (formId) {

		let selector = `#${String(formId)} INPUT, #${formId} TEXTAREA, #${formId} SELECT`;

		$(selector).each((i, input) => {

			$(input).removeClass("error");

		});

		$(`#${formId} .error`).each((i, div) => {

			div.remove();

		});

	}

}

