/*
 * TODO
 * associer un objet à un formulaire pour qu'il se MAJ l'un l'autre automatiquement (à la react)
 */


// Libraries
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/menu.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/themes/base/theme.css";


// Main CSS
import "./main.scss";

// Router
import router from "./router.js";

// Pages
import "Pages/pages.js";

// Once the pages have registered their routes, we start the router
router.resolve();
