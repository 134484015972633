import html from "./gofurther.html";
import gofurthers from "Datapoints/GoFurther";
import user from "Datapoints/User.js";
import Popin from "Components/Popin/Popin.js";
import debug from "Core/Debug.js";
import GoFurther from "./GoFurther.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";

class GoFurtherContainer {

	constructor (risk) {

		this.risk = risk;

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.risk.id);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.risk.id);

		// If the user is RPO or Editor, we show the buttons
		if (this.isCurrentUserRPO || this.isCurrentUserRiskEditor) {

			this.canAdmin = true;
			this.startShowing();

		} else { // Otherwise we check if he has appropriate right

			user.canAdminRisks({"idCartography": this.risk.cartography_id}).then((result) => {

				this.canAdmin = true;
				this.startShowing();

			}).catch((e) => {

				this.canAdmin = false;
				this.startShowing();

			});

		}

	}

	startShowing () {


		this.initAdminSidebar();
		this.showGoFurthers();

	}

	showGoFurthers () {

		if (
			gofurthers.data !== null &&
			gofurthers.data !== undefined &&
			gofurthers.data.length === 0
		) {

			const htmlNoItem = `<div class="no-item" data-i18n="label-msg-no-link">${$.i18n("label-msg-no-link")}</div>`;

			$("#gofurthers ul.list-links").html(htmlNoItem);

		} else {

			// We clear container
			$("#gofurthers ul.list-links").html("");

			// We show all go furthers
			$.each(gofurthers.data, (index, item) => {

				const newGoFurther = new GoFurther(item);

				newGoFurther.appendAndFill("#gofurthers ul.list-links", item, this.canAdmin);
				$(newGoFurther).on("edit", this.handleClickEditSidebarItem.bind(this));
				$(newGoFurther).on("delete", this.handleClickDeleteSidebarItem.bind(this));

			});

		}

		// We update the control instruments number of the sub menu in the risk page
		const numItemLink = $("#slide-control-instruments .item-link").length;
		$("#sub-menu-item-control-instruments .num").html(numItemLink);

	}

	initAdminSidebar () {

		const btnAddItemSelector = $("#btn-add-gofurther");
		const formAddItemSelector = $("#gofurthers .content-box-sidebar .btn-add-item");

		// If the user do not have the right to admin cartography, we remove the button to create / update / delete link
		if (!this.canAdmin) {

			btnAddItemSelector.hide();

		} else {

			// We define click on btn add item
			btnAddItemSelector.on("click", this.handleClickAddSidebarItem.bind(this));

			// We define click on submit buttons
			$("#gofurthers .content-box-sidebar").find(".btn-submit").on("click", this.handleClickBtnCreateGoFurther.bind(this));

			// We define click on cancel buttons
			$("#gofurthers .content-box-sidebar").find(".btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

		}

	}

	handleClickAddSidebarItem (e) {

		const formSelector = $("#create_gofurther");

		// We clear all errors form
		formSelector.find("input").removeClass("error");
		formSelector.find(".error").html("");

		if (formSelector.attr("class").indexOf("active") < 0) {

			formSelector.addClass("active");

			// We hide all opened forms
			$(e.currentTarget).parent().find(".item-link").removeClass("active-form");
			$(e.currentTarget).parent().find(".item-link .form-admin-item").remove();

		} else {

			formSelector.removeClass("active");

		}

	}

	handleClickEditSidebarItem (e, goFurtherId) {

		// We define parent item selector
		const parentItemSelector = $("#gofurthers").find(`.item-link[data-item-id='${goFurtherId}']`);

		const htmlForm = $("#create_gofurther").html();

		// We hide all opened edit forms
		$("#gofurthers").find(".item-link").removeClass("active-form");
		$("#gofurthers").find(".form-admin-item").removeClass("active");

		// We hide the add item form
		$("#create_gofurther").removeClass("active");

		// We show the edit item form
		parentItemSelector.addClass("active-form");

		parentItemSelector.append(`<form id="edit_gofurther" class="form-edit-item form-admin-item active">${htmlForm}<input name='id' value='${goFurtherId}' type='hidden' /></form>`);

		this.goFurtherToEdit = gofurthers.find(parentItemSelector.attr("data-item-id"));

		$("#edit_gofurther [name='name']").attr("value", this.goFurtherToEdit.name);
		$("#edit_gofurther [name='link_url']").attr("value", this.goFurtherToEdit.link_url);

		// We define clicks
		$("#edit_gofurther .btn-submit").on("click", this.handleClickBtnUpdateGoFurther.bind(this));
		$("#edit_gofurther .btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

	}

	handleClickDeleteSidebarItem (e, goFurtherId) {

		this.goFurtherIdToDelete = goFurtherId;
		const goFurtherName = $(`#gofurthers .item-link[data-item-id='${goFurtherId}']`).find(".desc-link .text").html();

		$("#delete-item-sidebar-popin").remove();

		this.deleteSidebarItemPopin = new Popin("delete-item-sidebar-popin", `${$.i18n("popin-delete-link-confirmation-title")} '${goFurtherName}' ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteSidebarItemCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteGoFurtherConfirm.bind(this)
			}
		]);

		this.deleteSidebarItemPopin.appendIn("#page");

		this.deleteSidebarItemPopin.show();

	}

	handleClickBtnCancelForm (e) {

		// We cancel the link submission
		e.preventDefault();

		// We define the current form
		const form = $(e.currentTarget).parents("form");

		// We show item informations
		$(e.currentTarget).parents(".item-link").removeClass("active-form");

		// We hide the form
		form.removeClass("active");
		$(e.currentTarget).parents(".form-edit-item").remove();

	}

	handleClickBtnCreateGoFurther (e) {

		// We cancel the link submission
		e.preventDefault();

		// We create and populate the form data
		let form_data = new FormData();
		let goFurtherName = $("#create_gofurther INPUT[name=\"name\"]").val();
		let goFurtherLinkUrl = $("#create_gofurther INPUT[name=\"link_url\"]").val();

		// We add http:// on link url
		let linkUrlToSend;
		if (goFurtherLinkUrl != "" && goFurtherLinkUrl.indexOf("http://") === -1 && goFurtherLinkUrl.indexOf("https://") === -1) {

			linkUrlToSend = "http://".concat(goFurtherLinkUrl);

		} else {

			linkUrlToSend = goFurtherLinkUrl;

		}

		form_data.append("name", goFurtherName);
		form_data.append("link_url", linkUrlToSend);


		gofurthers.addGoFurther(form_data, this.risk.id).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("Risk goFurther creation - createSuccess");

		// We hide the form
		$("#create_gofurther").removeClass("active");

		gofurthers.getData({"idRisk": this.risk.id}).then(() => {

			this.showGoFurthers(gofurthers.data);

			// We clear all fields of creation form
			$("#create_gofurther INPUT[name=\"name\"]").val("");
			$("#create_gofurther INPUT[name=\"link_url\"]").val("");

		}).catch((error) => {

			debug.e("Risk goFurther creation - createSuccess - error in GoFurther getData");
			debug.e(error);

		});

	}

	createFail (response, textStatus) {

		debug.v("Risk goFurther creation - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": "Vous devez saisir un nom"
				},
				"link_url": {
					"required": "Vous devez saisir un lien",
					"must_be_a_link": "Votre lien doit commencer par http:// ou https://"
				}
			};

			showFormErrors("#create_gofurther", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk goFurther creation - createSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleClickBtnUpdateGoFurther (e) {

		e.preventDefault();

		// We create and populate the form data
		let goFurtherToUpdate = updateObjectWithForm({}, "#edit_gofurther");

		gofurthers.updateGoFurther(goFurtherToUpdate, this.risk.id).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

	}

	updateSuccess (response) {

		debug.v("Risk goFurther update - updateSuccess");

		gofurthers.getData({"idRisk": this.risk.id}).then(() => {

			this.showGoFurthers(gofurthers.data);

		}).catch((error) => {

			debug.e("Risk goFurther update - updateSuccess - error in GoFurther getData");
			debug.e(error);

		});

	}

	updateFail (response, textStatus) {

		debug.v("Risk goFurther update - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": "Vous devez saisir un nom"
				},
				"link_url": {
					"required": "Vous devez saisir un lien",
					"must_be_a_link": "Votre lien doit commencer par http:// ou https://"
				}
			};

			showFormErrors("#edit_gofurther", response, errorsToShow);

		} else {

			$("FORM").prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");
			debug.e("Risk goFurther update - updateSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteSidebarItemCancel () {

		this.deleteSidebarItemPopin.hide();

	}

	handleDeleteGoFurtherConfirm () {

		gofurthers.deleteGoFurther(this.risk.id, this.goFurtherIdToDelete).then(() => {

			this.deleteSidebarItemPopin.hide();

			gofurthers.getData({"idRisk": this.risk.id}).then(() => {

				this.showGoFurthers(gofurthers.data);

			}).catch((error) => {

				debug.e("Risk goFurther deletion - handleDeleteGoFurtherConfirm - error in GoFurther getData");
				debug.e(error);

			});

		}).catch(debug.e);

	}

}
export default GoFurtherContainer;
