import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Risks extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = false;
		this.ignoreCleaningForThisFields = [
			"id",
			"reference",
			"theme",
			"rpo_id",
			"consequences",
			"created_at",
			"updated_at",
			"cartography_id",
			"origin",
			"status",
			"author_id",
			"moderator_id"
		];

	}

	createOne (riskToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/risk/create",
				"data": riskToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (riskToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/risk/update",
				"data": riskToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (riskId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/delete/${riskId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	find (idToFind) {

		let risk;
		if (this.data != undefined) {

			risk = this.data.find((risk) => risk.id == idToFind);

		}

		return risk;

	}

	getData (params) {

		if (params == undefined) {

			debug.e("Error in getData of Risks, no params object has been set.");
			return;

		}

		if (params.idCartography == undefined) {

			debug.e("Error in getData of Risks, the Risk id is undefined.");
			return;

		}

		return this.getRisksCartography(params.idCartography);

	}

	getRisksCartography (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risks/${cartographyId}`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve(this.data);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve(this.data);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getRisksForMultipleCartographies (user, cartographiesArray) {

		let risksPromises = [];

		// We clear the data array because we are going to merge multiple results
		this.updateData([]);

		cartographiesArray.forEach((cartographyId) => {

			const userCanViewEvaluationForThisCarto = user.hasRight("view_evaluation", {"cartography_id": cartographyId});
			const userCanEvaluateThisCarto = user.hasRight("evaluate_cartography", {"cartography_id": cartographyId});
			const userIsAdminForThisCarto = user.hasRight("admin_risks", {"cartography_id": cartographyId});

			if (userCanViewEvaluationForThisCarto || userCanEvaluateThisCarto || userIsAdminForThisCarto) {

				risksPromises.push(new Promise((resolve, reject) => {

					api.call({
						"method": "GET",
						"url": `/risks/${cartographyId}`
					}, false).
						done((dataReturned) => {

							this.updateData(this.data.concat(dataReturned));
							resolve(this.data);

						}).
						fail((error, textStatus) => {

							// We accept the 403
							if (error.status == 403) {

								resolve(this.data);

							} else {

								error.objCalling = this.constructor.name;
								reject(error);

							}

						});

				}));

			}

		});

		return Promise.all(risksPromises);

	}

}
export default new Risks();
