import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./about.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import "./About.scss";
import "./AboutResponsive.scss";

class About extends Page {

	constructor () {

		let routes = ["/about"];

		let datapointsToLoad = [
			context,
			cartographies,
			legalEntities,
			profitCenters
		];

		super(routes, AuthentifiedLayout, "À propos", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render () {

		return new Promise((resolve, reject) => {

			this.parseAndRender("page", html);

			// We get the current cartography id
			const curCartographyId = cartographies.getCurrentCartographyId();

			// We load the current legal entity
			const curLegalEntityId = context.getCurrentLegalEntityId();

			// We show the menu
			menu.show().then(resolve);

		});

	}

}
export default new About();
