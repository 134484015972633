import user from "Datapoints/User";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import html from "./main_menu_item.html";
// Import main menu items JSON
import mainMenuItems from "Core/assets/json/main_menu_items.json";

class MainMenuItem {

	init (containerSelector, itemId) {

		// We define the css container selector of the main menu
		this.containerSelector = containerSelector;

		// We define global vars
		this.initGlobalVars();

		// We show the item
		this.appendAndFill(itemId);

	}

	initGlobalVars () {

		// We get the current language
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We get the branch id
		this.branchId = context.getCurrentBranchId();

		// We get the cartography id
		this.cartographyId = cartographies.getCurrentCartographyId();

		// We get the legeal entity id
		this.legalEntityId = context.getCurrentLegalEntityId();

		// We define if the user has the right to view or evaluate cartography
		this.canUserViewOrEvaluateCartography = false;

		// We filter rights of the user on the current cartography
		const curCartographyUserRights = user.data.rights.filter((right) => parseInt(right.cartography_id) === parseInt(this.cartographyId));

		// We define if user has right to view or evaluate the current cartography
		if (JSON.stringify(curCartographyUserRights).indexOf("view_evaluation") >= 0 || JSON.stringify(curCartographyUserRights).indexOf("evaluate_cartography") >= 0) {

			this.canUserViewOrEvaluateCartography = true;

		} else {

			this.canUserViewOrEvaluateCartography = false;

		}

	}

	appendAndFill (itemId) {

		// We get item data
		const itemData = mainMenuItems.data.filter((item) => item.id === itemId)[0];

		// We manage the display of item in the main menu container
		let isCurrentUserCanViewItem = this.getDisplayAuthorization(itemData);

		// 0 - Manage env vars
		if (
		  isCurrentUserCanViewItem &&
			itemData.conditional_env_var !== undefined &&
			itemData.conditional_env_var !== null &&
			itemData.conditional_env_var === "SHOW_BUSINESS_ACTIVITIES"
		) {

			if (process.env.SHOW_BUSINESS_ACTIVITIES === true || process.env.SHOW_BUSINESS_ACTIVITIES === "true") {

				isCurrentUserCanViewItem = true;

			} else {

				isCurrentUserCanViewItem = false;

			}

		}

		if (isCurrentUserCanViewItem) {

			// We show the item
			this.containerSelector.append(html);

			// We get the current item selector
			const itemSelector = this.containerSelector.find("li").last();

			// 1 - We add the item class
			itemSelector.addClass(itemData.class);

			// 2 - We add the item icon slug
			if (itemData.slug_icon !== null && itemData.slug_icon !== "") {

				itemSelector.find(".icon").addClass("fas");
				itemSelector.find(".icon").addClass(itemData.slug_icon);

			}

			// 3 - We add the item text slug
			itemSelector.find("a").attr("title", $.i18n(itemData.slug_text));
			itemSelector.find("a .text").attr("data-i18n", itemData.slug_text);
			itemSelector.find("a .text").html($.i18n(itemData.slug_text));

			// 4 - We add the item link href
			if (itemData.link_url !== null && itemData.link_url !== "") {

				const itemLinkUrl = this.getItemLinkUrl(itemData.link_url);
				itemSelector.find("a").attr("href", itemLinkUrl);

			} else {

				// We add a "link" div on the item
				itemSelector.prepend("<div class='link'></div>");

				// We move the link content on the new link div
				itemSelector.find("a .icon").appendTo(itemSelector.find("div.link"));
				itemSelector.find("a .text").appendTo(itemSelector.find("div.link"));

				// We remove the <a> tag
				itemSelector.find("a").remove();

			}

			// 5 - Manage alternative contents
			this.handleAlternativeContents(itemData);

		}

	}

	getDisplayAuthorization (itemData) {

		// We get the item user rights
		const itemRights = itemData.user_rights;

		// We define a new state
		let isCurrentUserCanViewItem = true;

		// If it exists a user right protection to show the item
		if (itemRights !== undefined && itemRights !== null && itemRights.length > 0) {

			// We update the state
			isCurrentUserCanViewItem = false;

			// We define the number of rights the the current user has
			let numUserRights = 0;

			itemRights.forEach((right) => {

				if (this.getUserHasCurrentRight(right)) {

					numUserRights++;

				}

			});

			// User rights limitation
			if (numUserRights > 0) {

				isCurrentUserCanViewItem = true;

			}

		}

		// EXCEPTION - Exports button
		if (itemData.class === "btn-exports") {

			isCurrentUserCanViewItem = false;

			if (
				window.location.pathname.indexOf("/cartographies/all") < 0 &&
				window.location.pathname.indexOf("/admin/") < 0
			) {

				isCurrentUserCanViewItem = true;

			}

		}

		// EXCEPTION - Methodo button
		if (itemData.class === "btn-approach") {

			isCurrentUserCanViewItem = false;

			if (
				cartographies.find(this.cartographyId) !== undefined &&
				window.location.pathname.indexOf("/cartographies/all") < 0 &&
				window.location.pathname.indexOf("/admin/") < 0
			) {

				const curCartographyApproachImageUrl = cartographies.find(this.cartographyId)[`approach_image_url_${this.currentLang}`];

				if (this.canUserViewOrEvaluateCartography) {

					if (curCartographyApproachImageUrl !== null && curCartographyApproachImageUrl !== undefined) {

						isCurrentUserCanViewItem = true;

					}

				}

			}

		}

		// EXCEPTION - Cartography management button for admin_risks
		if (
			itemData.class === "btn-cartographies_management" &&
			!user.hasAdminCartographiesRight() &&
			user.hasAdminRisksRight()
		) {

			isCurrentUserCanViewItem = true;

		}

		return isCurrentUserCanViewItem;

	}

	getUserHasCurrentRight (right) {

		let userHasRight = false;

		if (right === "canViewActionPlans") {

			if (user.canViewActionPlans()) {

				userHasRight = true;

			}

		} else if (right === "canManageActionPlans") {

			if (user.canManageActionPlans()) {

				userHasRight = true;

			}

		} else if (right === "hasAdminCartographiesRight") {

			if (user.hasAdminCartographiesRight()) {

				userHasRight = true;

			}

		} else if (right === "hasAdminBusinessActivitiesRight") {

			if (user.hasAdminBusinessActivitiesRight()) {

				userHasRight = true;

			}

		} else if (right === "hasAccessStatisticsRight") {

			if (user.hasAccessStatisticsRight()) {

				userHasRight = true;

			}

		} else if (right === "hasAdminUsersRight") {

			if (user.hasAdminUsersRight()) {

				userHasRight = true;

			}

		} else if (right === "hasCommunicateRight") {

			if (user.hasCommunicateRight()) {

				userHasRight = true;

			}

		} else if (right === "canUserViewOrEvaluateCartography") {

			if (this.canUserViewOrEvaluateCartography) {

				userHasRight = true;

			}

		} else {

			userHasRight = false;

		}

		return userHasRight;

	}

	getItemLinkUrl (itemLinkUrl) {

		let routeToShow;

		// We have an exception for button exports
		if (itemLinkUrl === "/exports") {

			// We show the page with the selected context
			if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				routeToShow = `/exports/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/all`;

			} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				routeToShow = `/exports/branches/${this.branchId}/profit-centers/all/cartographies/${this.cartographyId}/legal-entities/all`;

			} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

				routeToShow = `/exports/branches/${this.branchId}/countries/all/cartographies/${this.cartographyId}/legal-entities/all`;

			} else {

				routeToShow = `/exports/branches/${this.branchId}/cartographies/${this.cartographyId}/legal-entities/${this.legalEntityId}/business-activities/all`;

			}

			let curContextBusinessActivity;

			// We add the business activity if necessary
			if (
				window.location.pathname.indexOf("/business-activities/") >= 0 &&
				window.location.pathname.indexOf("/business-activities/all") < 0
			) {

				const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

				routeToShow = routeToShow.replace("/business-activities/all", "");
				curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

			} else if (
			  routeToShow.indexOf("/business-activities/all") < 0 &&
				window.location.pathname.indexOf("/business-activities/") >= 0 &&
				window.location.pathname.indexOf("/business-activities/all") >= 0
			) {

				curContextBusinessActivity = "/business-activities/all";

			} else {

				curContextBusinessActivity = "";

			}

			/* eslint-disable */
			routeToShow = routeToShow + curContextBusinessActivity;
			/* eslint-enable */

		} else {

			routeToShow = itemLinkUrl;

			if (itemLinkUrl.indexOf(":idBranch") >= 0) {

				routeToShow = routeToShow.replace(":idBranch", this.branchId);

			}

			if (itemLinkUrl.indexOf(":idCartography") >= 0) {

				routeToShow = routeToShow.replace(":idCartography", this.cartographyId);

			}

			if (itemLinkUrl.indexOf(":idLegalEntity") >= 0) {

				routeToShow = routeToShow.replace(":idLegalEntity", this.legalEntityId);

			}

		}

		return routeToShow;

	}

	handleAlternativeContents (itemData) {

		if (
			itemData.class === "btn-cartographies_management" &&
			!user.hasAdminCartographiesRight() &&
			user.hasAdminRisksRight()
		) {

			// We update the text of the cartography management button
			$("#menu .main-menu ul .btn-cartographies_management a").attr("title", $.i18n("admin-cartographies-edit-title-page"));
			$("#menu .main-menu ul .btn-cartographies_management a .text").attr("data-i18n", "admin-cartographies-edit-title-page");
			$("#menu .main-menu ul .btn-cartographies_management a .text").html($.i18n("admin-cartographies-edit-title-page"));

			// We update the link of the cartography management button
			$("#menu .main-menu ul .btn-cartographies_management a").attr("href", `/admin/cartography/edit/${this.cartographyId}`);

		}

	}

}
export default new MainMenuItem();
