import sanitizeHtml from "sanitize-html";

class Datapoint {

	constructor () {

		this.ignoreCleaning = false; // If true, no html cleaning will be done
		this.ignoreCleaningForThisFields = [ // Fields for wich no html cleaning will be done
			"id",
			"created_at",
			"updated_at"
		];

	}

	updateData (newData) {

		this.data = newData;

		// We loop on the data to clean html it if it's not explicitly ignored
		if ((this.ignoreCleaning === false || this.ignoreCleaning === undefined) && Array.isArray(this.data)) {

			this.data = this.cleanValue(newData);

		}

	}

	hasData () {

		let isEmptyObject = Object.keys(this.data).length === 0 && Object.getPrototypeOf(this.data) === Object.prototype;

		return this.data !== undefined && this.data !== null && !isEmptyObject && this.data !== "" && this.data !== [];

	}

	// Recursive function to clean data by looping on arrays an object properties
	cleanValue (valueToClean) {

		let cleanedValue;

		if (typeof valueToClean === "object" && !Array.isArray(valueToClean) && valueToClean !== null) {

			for (let prop in valueToClean) {

				if (this.ignoreCleaningForThisFields === undefined || !this.ignoreCleaningForThisFields.includes(prop)) {

					valueToClean[prop] = this.cleanValue(valueToClean[prop]);

				}

			}

			cleanedValue = valueToClean;

		} else if (Array.isArray(valueToClean) && valueToClean !== null) {

			valueToClean.forEach((element, i) => {

				valueToClean[i] = this.cleanValue(element);

			});

			cleanedValue = valueToClean;

		} else if (valueToClean != null && valueToClean !== true && valueToClean !== false) {

			cleanedValue = sanitizeHtml(valueToClean);

		} else {

			cleanedValue = valueToClean;

		}

		return cleanedValue;

	}

}
export default Datapoint;
