import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import Datapoint from "Core/Datapoint.js";

class Actions extends Datapoint {

	createOne (actionToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/action/create",
				"data": actionToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (actionToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/action/update",
				"data": actionToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (actionId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/action/delete/${actionId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getData (params) {

		if (params === undefined) {

			debug.e("Error in getData of Actions, no params object has been set.");
			return;

		}

		if (params.idCartography === undefined) {

			debug.e("Error in getData of Actions, the Cartography id is undefined.");
			return;

		}

		if (params.idRisk === undefined) {

			debug.e("Error in getData of Actions, the Risk id is undefined.");
			return;

		}

		let cartographyId = params.idCartography;
		let riskId = params.idRisk;

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/actions/${cartographyId}/${riskId}`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getActionsCartography (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/actions/${cartographyId}`
			}, false).
				done((data) => {

					this.dataActionsCartography = data;
					resolve(this.dataActionsCartography);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve(this.dataActionsCartography);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	find (idToFind) {

		let action;
		if (this.data != undefined) {

			action = this.data.find((action) => action.id == idToFind);

		}

		return action;

	}


}
export default new Actions();
