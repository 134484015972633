import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./action_edit.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import actions from "Datapoints/Actions.js";
import cartographies from "Datapoints/Cartographies.js";
import users from "Datapoints/Users.js";
import legalEntities from "Datapoints/LegalEntities.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import {showFormErrors, updateObjectWithForm, getUrlVars} from "Core/helpers.js";


class ActionEdit extends Page {

	constructor () {

		let routes = [
			"legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/actions/:idAction/edit",
			"/cartographies/:idCartography/risks/:idRisk/actions/:idAction/edit",
			"legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/actions/create",
			"/cartographies/:idCartography/risks/:idRisk/actions/create"
		];

		super(routes, AuthentifiedLayout, "Modification d'une action", [
		  context,
			users,
			risks,
			actions,
			legalEntities,
			cartographies
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEditThisAction.bind(this)
		];

	}

	canCurrentUserEditThisAction (params) {

		return new Promise((resolve, reject) => {

			let risk = risks.find(params.idRisk);

			let isCurrentUserRPO = false;
			let isCurrentUserRiskEditor = false;
			if (risk !== undefined) {

				isCurrentUserRPO = user.isRPO(params.idRisk);
				isCurrentUserRiskEditor = user.isRiskEditor(params.idRisk);

			}

			if (
				user.hasRight("admin_risks", {"cartography_id": params.idCartography}) ||
				isCurrentUserRPO ||
				isCurrentUserRiskEditor
			) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		this.urlVars = getUrlVars();

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		menu.show();

		this.params = params;

		this.formType = null;

		this.parseAndRender("page", html);

		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We get the previous page saved
		this.previousPage = localStorage.getItem("navigation-previousPageUrl");

		// We define the id of the current cartography
		this.idCartography = params.idCartography;

		// We define the id of the current risk
		this.idRisk = params.idRisk;

		// We define the id of current legal entity
		this.idLegalEntity = context.getCurrentLegalEntityId();

		// We initialize the page events
		this.initEvents();

		// We add translate buttons
		this.addTranslateButtons();

	}

	initEvents () {

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			if (this.urlVars[0].indexOf("create") >= 0) {

				this.formType = "create";

				this.editedAction = {};

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("action-create-title-html")}`);
				const htmlTitleCreate = `<h1><span data-i18n="action-create-title-page">${$.i18n("action-create-title-page")}</span><span class="risk-title"></span></h1>`;
				$(".title-page").html(htmlTitleCreate);

				// We update the label of the submit button
				const htmlButtonCreate = `<i class="icon fas fa-check"></i><span class="text" data-i18n="button-create">${$.i18n("button-create")}</span>`;
				$(".btn-submit").html(htmlButtonCreate);

			} else if (this.urlVars[0].indexOf("edit") >= 0) {

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("action-edit-title-html")}`);

				this.formType = "edit";

				this.idAction = this.params.idAction;

				this.editedAction = actions.find(this.params.idAction);

				this.autoFillPage(this.editedAction);
				this.autoFillForm("#edit_action", this.editedAction);

			}

			// We show the risk title
			this.showRiskTitle();

			// We initialize the rich text editor
			this.initTextEditor();

			// We redirect to the previous page
			if (this.previousPage !== undefined) {

				// We change the link on cancel button
				$("#edit_action .btn-cancel").attr("href", `${this.previousPage}#actions`);

			} else {

				// We change the link on cancel button
				$("#edit_action .btn-cancel").attr("href", `/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all#actions`);

			}

		}

	}

	showRiskTitle () {

		const dataRisk = risks.find(this.idRisk);

		// We show the title
		if (dataRisk[`title_${this.currentLang}`] !== "" && dataRisk[`title_${this.currentLang}`] !== null) {

			$(".title-page .risk-title").html(dataRisk[`title_${this.currentLang}`]);

		} else if (this.currentLang === "fr" && dataRisk.title_en !== null) {

			$(".title-page .risk-title").html(dataRisk.title_en);

		} else if (this.currentLang === "en" && dataRisk.title_fr !== null) {

			$(".title-page .risk-title").html(dataRisk.title_fr);

		} else {

			$(".title-page .risk-title").html("");

		}


		$(".title-page .risk-title").on("click", (event) => {

			// We redirect to the risk page
			router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all`);

		});

	}

	initTextEditor () {

		// Content Editor FR
		ClassicEditor.
	    create(document.querySelector("#content-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorContentFr = editor;

	      if (
	        this.editedAction !== undefined &&
	      	this.editedAction.content_fr !== undefined &&
	      	this.editedAction.content_fr !== null
	      ) {

					editor.setData(this.editedAction.content_fr);

				}

				// Bug correction : we remove the second ck-editor element
				$("#content-editor-fr").parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

		// Content Editor EN
		ClassicEditor.
	    create(document.querySelector("#content-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorContentEn = editor;

	      if (
	        this.editedAction !== undefined &&
	      	this.editedAction.content_en !== undefined &&
	      	this.editedAction.content_en !== null
	      ) {

					editor.setData(this.editedAction.content_en);

				}

				// Bug correction : we remove the second ck-editor element
				$("#content-editor-en").parents("li").find(".ck-editor").eq(1).remove();


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	addTranslateButtons () {

		// Title FR
		const translationButtonForTitleFr = new FieldTranslationButton("title_fr", "text", "fr");

		// Title EN
		const translationButtonForTitleEn = new FieldTranslationButton("title_en", "text", "en");

		// Goal FR
		const translationButtonForGoalFr = new FieldTranslationButton("goal_fr", "text", "fr");

		// Goal EN
		const translationButtonForGoalEn = new FieldTranslationButton("goal_en", "text", "en");

		setTimeout(() => {

			// Content FR
			const translationButtonForContentFr = new FieldTranslationButton("content_fr", "textarea", "fr", this.richTextEditorContentFr, this.richTextEditorContentEn);

			// Content EN
			const translationButtonForContentEn = new FieldTranslationButton("content_en", "textarea", "en", this.richTextEditorContentFr, this.richTextEditorContentEn);

		}, 100);

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("edit_action");

		this.editedAction = updateObjectWithForm(this.editedAction, "#edit_action");

		this.editedAction.content_fr = this.richTextEditorContentFr.getData();
		this.editedAction.content_en = this.richTextEditorContentEn.getData();

		this.editedAction.risk_id = parseInt(this.idRisk);
		this.editedAction.cartography_id = parseInt(this.idCartography);

		if (this.formType === "edit") {

			actions.updateOne(this.editedAction).then(this.editSuccess.bind(this), this.editFail.bind(this));

		} else if (this.formType === "create") {

			// We add the action status (= valid by default)
			this.editedAction.status = "valid";

			actions.createOne(this.editedAction).then(this.editSuccess.bind(this), this.editFail.bind(this));

		}

	}

	editSuccess (response) {

		actions.getData(this.params, {"forceRequest": true}).then(() => {

			// We update the menu
			menu.update();

			// We redirect to the previous page
			if (this.previousPage !== undefined) {

				router.navigate(`${this.previousPage}#actions`);

			} else {

				// We redirect to the risk page
				router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all#actions`);

			}

		});

	}

	editFail (response, textStatus) {

		debug.v("ActionEdit - editFail");

		if (response.status == 422) {

			let errorsToShow = {
				"title_fr": {
					"required": `${$.i18n("form-error-title-fr-required")}`
				},
				"title_en": {
					"required": `${$.i18n("form-error-title-en-required")}`
				},
				"content_fr": {
					"required": `${$.i18n("form-error-content-fr-required")}`
				},
				"content_en": {
					"required": `${$.i18n("form-error-content-en-required")}`
				},
				"goal_fr": {
					"required": `${$.i18n("form-error-goal-fr-required")}`
				},
				"goal_en": {
					"required": `${$.i18n("form-error-goal-en-required")}`
				}
			};

			showFormErrors("#edit_action", response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(".error").eq(0).parents("li").offset().top;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new ActionEdit();
