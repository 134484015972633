import helpFirstActionItems from "Datapoints/HelpFirstActionItems";
import helpFirstActionBanner from "Components/HelpFirstActionBanner/HelpFirstActionBanner.js";

class HelpFirstActionContainer {

	init () {

		// We load all the items
		this.helpFirstActionItemsData = helpFirstActionItems.getData();


		// We init each first action help item
		this.initHelpAfterFirstRiskEvaluation();

	}

	initHelpAfterFirstRiskEvaluation () {

		this.curHelpItem = this.helpFirstActionItemsData.filter((helpFirstActionItem) => helpFirstActionItem.id === "help-after-first-risk-evaluation");


		if (this.curHelpItem !== undefined) {

			const curItem = this.curHelpItem[0];

			const itemPagePath = curItem.page_path;

			const itemMaxDisplays = parseInt(curItem.max_displays);

			const itemNumberDisplays = localStorage.getItem("help-after-first-risk-evaluation") !== undefined && localStorage.getItem("help-after-first-risk-evaluation") !== null ? parseInt(localStorage.getItem("help-after-first-risk-evaluation")) : null;

			const isCurrentRiskNotEvaluated = localStorage.getItem("risk-isCurrentRiskNotEvaluated");

			if (
				window.location.pathname.indexOf(itemPagePath) >= 0 &&
				window.location.href.indexOf(curItem.page_path_exclude_1) < 0 &&
				window.location.href.indexOf(curItem.page_path_exclude_2) < 0 &&
				window.location.href.indexOf(curItem.page_path_exclude_3) < 0 &&
				window.location.href.indexOf(curItem.page_path_exclude_4) < 0 &&
				window.location.href.indexOf(curItem.page_path_exclude_5) < 0 &&
				isCurrentRiskNotEvaluated !== undefined &&
				isCurrentRiskNotEvaluated !== null &&
				(isCurrentRiskNotEvaluated === true || isCurrentRiskNotEvaluated === "true")
			) {

				// We show the first action help banner with the item content
				helpFirstActionBanner.init("help-after-first-risk-evaluation");

				if (itemNumberDisplays !== null && itemNumberDisplays >= itemMaxDisplays) {

					// We remove an item in the local storage
					localStorage.removeItem("risk-isCurrentRiskNotEvaluated");

				}

			}

		}

	}

}
export default new HelpFirstActionContainer();
