import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Statistics extends Datapoint {

	getLegalEntitiesProgression (branchId, cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/statistics/${branchId}/${cartographyId}/legal-entities-progression`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getLegalEntitiesProgressionForAllCartographies (branchId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/statistics/${branchId}/legal-entities-progression`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getProfitCentersProgression (branchId, cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/statistics/${branchId}/${cartographyId}/profit-centers-progression`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getTrafficPerLegalEntities (branchId, startDate, endDate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/statistics/${branchId}/traffic/per-legal-entities/${startDate}/${endDate}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getTrafficPerProfitCenters (branchId, startDate, endDate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/statistics/${branchId}/traffic/per-profit-centers/${startDate}/${endDate}`
			}).
				done(resolve).
				fail(reject);

		});

	}

}
export default new Statistics();
