import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./edit.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import legalEntities from "Datapoints/LegalEntities.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import {showFormErrors, updateObjectWithForm, slugify, getUrlVars} from "Core/helpers.js";

class LegalEntityCategoryForCartographyEdit extends Page {

	constructor () {

		let routes = [
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/category/edit",
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/category/edit"
		];

		let datapointsToLoad = [
			users,
			legalEntities
		];

		super(routes, AuthentifiedLayout, "Choix d'une catégorie", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEvaluateThisCartography.bind(this)
		];

	}

	canCurrentUserEvaluateThisCartography (params) {

		return new Promise((resolve, reject) => {

			if (user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography})) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		this.params = params;
		this.urlVars = getUrlVars();

		menu.show();

		this.parseAndRender("page", html);

		this.editedCategory = {};
		this.formType = null;

		this.initEvents();

		$("FORM").on("submit", this.handleSubmit.bind(this));

		router.refreshLinks();

	}

	initEvents () {

		// We define the form type
		if (this.urlVars.returnToRiskEvaluation !== undefined && this.urlVars.returnToRiskEvaluation !== null) {

			this.formType = "create";

		} else {

			this.formType = "edit";

			// We auto fill the category
			this.autoFillCategory();

		}

		// We listen click on card category
		$(".card-category").on("click", this.handleClickOnCardCategory.bind(this));

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	autoFillCategory () {

		legalEntities.getCategoryForCartography(this.params.idCartography, this.params.idLegalEntity).then((data) => {

			// If the current legal entity have a category
			if (data.category !== undefined && data.category !== null) {

				// We get the current category
				const curCategory = parseInt(data.category);

				// We had the value to the input field
				$("input[name='category']").attr("value", curCategory);

				// We select the current
				$(".card-category").each((index, cardCategory) => {

					if ($(cardCategory).attr("data-category-value") == curCategory) {

						$(cardCategory).addClass("active");

					}

			  });

			}

		});


	}

	handleClickOnCardCategory (e) {

		// We get the value of selected category
		const selectedCategoryValue = $(e.currentTarget).attr("data-category-value");

		// We had the value to the input field
		$("input[name='category']").attr("value", selectedCategoryValue);

		// We clear all the cards
		$(".card-category").removeClass("active");

		// We add the active class to the selected card
		$(e.currentTarget).addClass("active");

	}

	handleClickOnCancelButton () {

		// We redirect to the previous page
		window.history.back();

	}

	handleSubmit (e) {

		e.preventDefault();

		this.clearFormErrors("edit_legal_entity_category_for_cartography");

		const selectedCategory = $("input[name='category']").val();

		this.editedCategory = {
			"cartography_id": parseInt(this.params.idCartography),
			"legal_entity_id": parseInt(this.params.idLegalEntity),
			"category": selectedCategory
		};

		legalEntities.setCategoryForCartography(this.editedCategory).then(this.editSuccess.bind(this), this.editFail.bind(this));

	}

	editSuccess () {

		debug.v("LegalEntityCategoryForCartographyCreate - editSuccess");

		legalEntities.getCategoryForCartography(this.params.idCartography, this.params.idLegalEntity, {"forceRequest": true}).then(() => {

			if (this.formType === "create") {

				// We redirect to the risk evaluation page
				router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.urlVars.returnToRiskEvaluation}/evaluate`);

			} else {

				// We redirect to the previous page
				window.history.back();

			}

		});

	}

	editFail (response, textStatus) {

		if (response.status == 422) {

			let errorsToShow = {
				"category": {
					"required": `${$.i18n("form-error-category-required")}`,
					"bad_format": `${$.i18n("form-error-category-required")}`
				}
			};

			showFormErrors("#edit_legal_entity_category_for_cartography", response, errorsToShow);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new LegalEntityCategoryForCartographyEdit();
