import Page from "Pages/Page.js";
import user from "Datapoints/User.js";
import api from "Core/Api.js";
import Authentified from "Layouts/Authentified/AuthentifiedLayout";
import debug from "Core/Debug.js";
import router from "Core/router.js";

class Logout extends Page {

	constructor () {

		let routes = ["logout"];

		super(routes, Authentified, "Déconnexion");

	}

	afterRender () {

		this.logoutUser();

	}

	logoutUser () {

		user.logout().then(this.logoutSuccess.bind(this), this.logoutUser.bind(this));

	}

	logoutSuccess () {

		// We redirect to the login
		router.navigate("login");

		// We reload the page
		document.location.reload();

	}

	logoutFail () {

		debug.e("La déconnexion n'a pas fonctionnée.");

	}

}
export default new Logout();
