import router from "Core/router.js";
import api from "Core/Api.js";
import legalEntities from "Datapoints/LegalEntities.js";
import Datapoint from "Core/Datapoint.js";

class Countries extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			if (this.data != undefined && !forceRequest) {

				resolve();
				return;

			}

			api.call({
				"method": "GET",
				"url": "/countries"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				});

		});

	}

	getCountriesForProfitCenter (idProfitCenter) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/countries/for-profit-center/${idProfitCenter}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	find (idToFind) {

		let country;
		if (this.data !== undefined) {

			country = this.data.find((country) => parseInt(country.id) === parseInt(idToFind));

		}

		return country;

	}

	getLib (idCountry) {

		let country = this.find(idCountry);
		return country != undefined ? country.fr : "";

	}

}
export default new Countries();
