import debug from "Core/Debug.js";
import router from "Core/router.js";
import Action from "./Action.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import actions from "Datapoints/Actions.js";
import actionsPlan from "Datapoints/ActionsPlan.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import risks from "Datapoints/Risks.js";
import Popin from "Components/Popin/Popin.js";

class ActionContainer {

	load (cssSelector, actionsPlanData, isCurrentUserRPO, isCurrentUserAdminRisks) {

		// We save params
		this.actionsPlanData = actionsPlanData;
		this.isCurrentUserRPO = isCurrentUserRPO;
		this.isCurrentUserAdminRisks = isCurrentUserAdminRisks;

		return new Promise((resolve, reject) => {

			// We remove ths actions list on the container
			$(cssSelector).find(".list-actions").remove();

			// We show the actions container
			$(cssSelector).append("<div class=\"list-actions\"></div>");

			// We get the actions of the current risk
			if (actions.data !== undefined && actions.data !== null && actions.data.length > 0) {

				$(actions.data).each((i, actionData) => {

					const actionLine = new Action(actionData);

					// We get the informations of the current action in the action plan
					const actionState = actionsPlanData.filter((action) => parseInt(action.action_id) === parseInt(actionData.id))[0];

					// We show the action line
					if (
						actionData.status !== "deactivated" ||
						actionData.status === "deactivated" && (isCurrentUserRPO || isCurrentUserAdminRisks) ||
						actionData.status === "deactivated" && actionState !== undefined && actionState.status === "done" ||
						actionData.status === "deactivated" && actionState !== undefined && actionState.status === "todo"
					) {

						actionLine.appendAndFill(".list-actions", actionData, actionState);

					}

				});

				// We listen click on the config buttons
				$(".line-action .btn-admin-config").on("click", this.handleClickOnBtnConfigOnLineAction.bind(this));

				// We listen click on the edit button
				$(".line-action .btn-edit").on("click", this.handleClickOnBtnEditOnLineAction.bind(this));

				// We listen click on the delete button
				$(".line-action .btn-delete").on("click", this.handleClickOnBtnDeleteOnLineAction.bind(this));

				// We listen click on the deactivate button
				$(".line-action .btn-deactivate").on("click", this.handleClickOnBtnDeactivateOnLineAction.bind(this));

				// We listen click on the line action
				$("#btn-show-deactivated-actions").on("click", this.handleClickOnBtnToShowDeactivatedActions.bind(this));

				// We listen click on the line action
				$(".line-action").on("click", this.handleClickOnBtnMoreLineAction.bind(this));


			} else {

				// We show the no action message
				this.showMessageNoAction(cssSelector);

			}

			// We get the number of actions displayed
			const numActionsDisplayed = $("#section-actions-risk .list-actions .line-action").length;

			// If there is no action displayed
			if (numActionsDisplayed < 1) {

				// We show the no action message
				this.showMessageNoAction(cssSelector);

			}

			// We show the actions caption only if the current user can view the actions states
			if (user.canManageActionPlans() || user.canViewActionPlans()) {

				this.showActionsCaption().then(resolve);

			} else {

				resolve();

			}

			// We get the number of actions
			const numActions = $(".list-actions .line-action").length;

			// We update the actions number in the sub menu of the risk page
			$("#sub-menu-item-actions .num").html(numActions);

		});

	}

	showMessageNoAction (cssSelector) {

		// We define the html of the message to show
		const htmlMessageNoAction = `
			<div class="msg-no-action">
				<span class="icon fas fa-info-circle"></span>
				<span class="text" data-i18n="msg-no-action">${$.i18n("msg-no-action")}</span>
			</div>
		`;

		// We show the message
		$(cssSelector).find(".list-actions").html(htmlMessageNoAction);

	}

	showActionsCaption () {

		return new Promise((resolve, reject) => {

			$(".list-action-captions").remove();

			// We get the number of actions with status "to consider"
			const numActionsToConsider = $("#section-actions-risk .list-actions .line-action.status-to-consider").length;

			// We define the html of the caption "to consider"
			let htmlActionCaptionToConsider = "";
			if (numActionsToConsider !== undefined && numActionsToConsider > 0) {

				let captionTextToConsider;
				if (numActionsToConsider > 1) {

					captionTextToConsider = $.i18n("button-action-section-to-define-plural");

				} else {

					captionTextToConsider = $.i18n("button-action-section-to-define");

				}

				htmlActionCaptionToConsider = `
					<div class="action-caption to-consider">
						<div class="icon fas fa-question-circle"></div>
						<div class="content">
							<span class="num">${numActionsToConsider}</span>
							<span class="text">${captionTextToConsider}</span>
						</div>
					</div>
				`;

			}

			// We get the number of actions with status "todo"
			const numActionsTodo = $("#section-actions-risk .list-actions .line-action.status-todo").length;

			// We define the html of the caption "todo"
			let htmlActionCaptionTodo = "";
			if (numActionsTodo !== undefined && numActionsTodo > 0) {

				let captionTextTodo;
				if (numActionsTodo > 1) {

					captionTextTodo = $.i18n("button-action-section-todo-plural");

				} else {

					captionTextTodo = $.i18n("button-action-section-todo");

				}

				htmlActionCaptionTodo = `
					<div class="action-caption todo">
						<div class="icon fas fa-dot-circle"></div>
						<div class="content">
							<span class="num">${numActionsTodo}</span>
							<span class="text">${captionTextTodo}</span>
						</div>
					</div>
				`;

			}

			// We get the number of actions with status "done"
			const numActionsDone = $("#section-actions-risk .list-actions .line-action.status-done").length;

			// We define the html of the caption "done"
			let htmlActionCaptionDone = "";
			if (numActionsDone !== undefined && numActionsDone > 0) {

				let captionTextDone;
				if (numActionsDone > 1) {

					captionTextDone = $.i18n("button-action-section-done-plural");

				} else {

					captionTextDone = $.i18n("button-action-section-done");

				}

				htmlActionCaptionDone = `
					<div class="action-caption done">
						<div class="icon fas fa-check-circle"></div>
						<div class="content">
							<span class="num">${numActionsDone}</span>
							<span class="text">${captionTextDone}</span>
						</div>
					</div>
				`;

			}

			// We get the number of actions with status "ignored"
			const numActionsIgnored = $("#section-actions-risk .list-actions .line-action.status-ignored").length;

			// We define the html of the caption "ignored"
			let htmlActionCaptionIgnored = "";
			if (numActionsIgnored !== undefined && numActionsIgnored > 0) {

				let captionTextIgnored;
				if (numActionsIgnored > 1) {

					captionTextIgnored = $.i18n("button-action-section-ignored-plural");

				} else {

					captionTextIgnored = $.i18n("button-action-section-ignored");

				}

				htmlActionCaptionIgnored = `
					<div class="action-caption ignored">
						<div class="icon fas fa-minus-circle"></div>
						<div class="content">
							<span class="num">${numActionsIgnored}</span>
							<span class="text">${captionTextIgnored}</span>
						</div>
					</div>
				`;

			}

			// We get the number of actions with status "deactivated"
			const numActionsDeactivated = $("#section-actions-risk .list-actions .line-action.status-deactivated").length;

			// We define the html of the caption "deactivated"
			let htmlActionCaptionDeactivated = "";
			if (numActionsDeactivated !== undefined && numActionsDeactivated > 0) {

				let captionTextDeactivated;
				if (numActionsDeactivated > 1) {

					captionTextDeactivated = $.i18n("button-action-section-deactivated-plural");

				} else {

					captionTextDeactivated = $.i18n("button-action-section-deactivated");

				}

				htmlActionCaptionDeactivated = `
					<div class="action-caption deactivated">
						<div class="icon fas fa-ban"></div>
						<div class="content">
							<span class="num">${numActionsDeactivated}</span>
							<span class="text">${captionTextDeactivated}</span>
						</div>
					</div>
				`;

			}

			// We define html for the actions caption container
			const htmlActionsCaptionContainer = `
				<div class="list-action-captions">
					${htmlActionCaptionToConsider}
					${htmlActionCaptionTodo}
					${htmlActionCaptionDone}
					${htmlActionCaptionIgnored}
					${htmlActionCaptionDeactivated}
				</div>
			`;

			// We show the actions caption
			$(htmlActionsCaptionContainer).insertAfter("#section-actions-risk .line-top");

			resolve();

		});

	}

	loadActionsList (cssSelector, actionsList, actionsPlanList) {

		// We saved the actions plan data
		this.actionsPlanData = actionsPlanList;

		// We show the actions container
		$(cssSelector).append("<div class=\"list-actions\"></div>");

		if (actionsList !== undefined && actionsList.length > 0) {

			$(actionsList).each((i, actionData) => {

				const actionLine = new Action(actionData);

				const actionsListSelector = `${cssSelector} .list-actions`;

				// We get the informations of the current action in the action plan
				const actionState = actionsPlanList.filter((action) => parseInt(action.action_id) === parseInt(actionData.id))[0];

				// We show the action line
				actionLine.appendAndFill(actionsListSelector, actionData, actionState);

			});

			// We listen click on the config buttons
			$(".line-action .btn-admin-config").on("click", this.handleClickOnBtnConfigOnLineAction.bind(this));

			// We listen click on the edit button
			$(".line-action .btn-edit").on("click", this.handleClickOnBtnEditOnLineAction.bind(this));

		}

	}

	handleClickOnBtnToShowDeactivatedActions (e) {

		if ($(".line-action.status-deactivated").css("display") === "none") {

			// We show the deactivated actions
			$(".line-action.status-deactivated").slideDown(200);

			// We change the button icon
			$(e.currentTarget).find(".icon").removeClass("fa-eye");
			$(e.currentTarget).find(".icon").addClass("fa-eye-slash");

			// We change the button text
			$(e.currentTarget).find(".text").html($.i18n("button-hide-deactivated-actions"));

			// We scroll to the deactivated actions
			const positionLineDeactivatedActions = $(".line-deactivated-actions").eq(0).offset().top;
			$("html, body").animate({"scrollTop": positionLineDeactivatedActions}, 400);

		} else {

			// We show the deactivated actions
			$(".line-action.status-deactivated").slideUp(200);

			// We change the button icon
			$(e.currentTarget).find(".icon").removeClass("fa-eye-slash");
			$(e.currentTarget).find(".icon").addClass("fa-eye");

			// We change the button text
			$(e.currentTarget).find(".text").html($.i18n("button-show-deactivated-actions"));

		}

	}

	handleClickOnBtnShowActionsComments () {

		// We get the current button
		const curButton = $("#section-actions-risk .btn-show-actions-comments");

		if (curButton.attr("class").indexOf("active") < 0) {

			// We show the actions comments
			this.showActionsComments(curButton);

		} else {

			// We hide the actions comments
			this.hideActionsComments(curButton);

		}

	}

	showActionsComments (curButton) {

		// We define the container
		const curContainer = $("#section-actions-risk .list-actions .line-action");

		// We add the active class on the actions lines
		curContainer.addClass("active");

		// We show the contents
		curContainer.find(".content").slideDown(200);

		// We update the button more
		curContainer.find(".btn-more .icon").removeClass("fa-angle-down");
		curContainer.find(".btn-more .icon").addClass("fa-angle-up");
		curContainer.find(".btn-more .text").attr("data-i18n", "button-hide-details");
		curContainer.find(".btn-more .text").html($.i18n("button-hide-details"));

		// We change the icon of the button
		curButton.find(".icon").removeClass("fa-comment");
		curButton.find(".icon").addClass("fa-comment-slash");

		// We change the text of the button
		curButton.find(".text").html($.i18n("button-hide-comments"));

		// We add the active class on the button
		curButton.addClass("active");

	}

	hideActionsComments (curButton) {

		// We define the container
		const curContainer = $("#section-actions-risk .list-actions .line-action");

		// We remove the active class on the actions lines
		curContainer.removeClass("active");

		// We hide the contents
		curContainer.find(".content").slideUp(200);

		// We update the buttons more
		curContainer.find(".btn-more .icon").removeClass("fa-angle-up");
		curContainer.find(".btn-more .icon").addClass("fa-angle-down");
		curContainer.find(".btn-more .text").attr("data-i18n", "button-see-details");
		curContainer.find(".btn-more .text").html($.i18n("button-see-details"));

		// We change the icon of the button
		curButton.find(".icon").removeClass("fa-comment-slash");
		curButton.find(".icon").addClass("fa-comment");

		// We change the text of the button
		curButton.find(".text").html($.i18n("button-show-comments"));

		// We remove the active class on the button
		curButton.removeClass("active");

	}

	handleClickOnBtnConfigOnLineAction (e) {

		e.stopPropagation();

		const lineAction = $(e.currentTarget).parents(".line-action");

		if ($(e.currentTarget).attr("class").indexOf("btn-ignore") < 0) {

			this.showActionsPlanEditForm(lineAction);

		} else {

			this.switchActionToIgnoredStatus(lineAction);

		}

	}

	handleClickOnBtnEditOnLineAction (e) {

		e.stopPropagation();

		// We define current cartography ID
		const curCartographyId = cartographies.getCurrentCartographyId();

		// We define current legal entity ID
		const curLegalEntityId = context.getCurrentLegalEntityId();

		// We get the current risk ID
		this.curRiskId = parseInt($(e.currentTarget).parents(".line-action").attr("data-risk-id"));

		// We get the current action ID
		this.curActionId = parseInt($(e.currentTarget).parents(".line-action").attr("data-action-id"));

		// We get the current action data
		let curActionData;
		if (window.location.pathname.indexOf("/actions-plan/") >= 0) {

			curActionData = actions.dataActionsCartography.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		} else {

			curActionData = actions.data.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		}

		// We try to update the action
		actions.updateOne(curActionData).then(() => {

			router.navigate(`/legal-entities/${curLegalEntityId}/cartographies/${curCartographyId}/risks/${this.curRiskId}/actions/${this.curActionId}/edit`);

		}).catch((error) => {

			// If the error exist
			if (error.responseText.indexOf("action_already_in_actions_plans") >= 0) {

				let htmlLegalEntitiesWithCurrentAction = "";

				$(error.responseJSON.errors.id.action_already_in_actions_plans).each((i, actionPlan) => {

					htmlLegalEntitiesWithCurrentAction = `${htmlLegalEntitiesWithCurrentAction}<br />${actionPlan.legal_entity_name}`;

				});

				// Edit action error popin
				this.editActionErrorPopin = new Popin("edit-action-error-popin", `${$.i18n("popin-edit-action-error-title")}<br /><span class="highlight">${htmlLegalEntitiesWithCurrentAction}</span>`, [
					{
						"title": `${$.i18n("button-ok")}`,
						"class": "btn-submit",
						"iconClass": "icon fas fa-check",
						"callback": this.handleEditActionErrorConfirm.bind(this)
					}
				]);

				$(".popin").remove();

				this.editActionErrorPopin.appendIn("#page");

				this.editActionErrorPopin.show();


			}

		});

	}

	handleEditActionErrorConfirm (e) {

		this.editActionErrorPopin.hide();

	}

	handleClickOnBtnDeactivateOnLineAction (e) {

		e.stopPropagation();

		$(".popin").remove();

		// We get the current risk ID
		this.curRiskId = parseInt($(e.currentTarget).parents(".line-action").attr("data-risk-id"));

		// We get the current action ID
		this.curActionId = parseInt($(e.currentTarget).parents(".line-action").attr("data-action-id"));

		// We get the current action data
		let curActionData;
		if (window.location.pathname.indexOf("/actions-plan/") >= 0) {

			curActionData = actions.dataActionsCartography.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		} else {

			curActionData = actions.data.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		}

		// We define the current action name
		const curActionName = curActionData[`title_${$.i18n().locale}`];

		if (curActionData.status === "deactivated") {

			// Activate action confirm popin
			this.activateActionConfirmPopin = new Popin("activate-action-confirm-popin", `${$.i18n("popin-activate-action-confirm-title")} <span class="highlight">${curActionName}</span> ?`, [
				{
					"title": `${$.i18n("button-cancel")}`,
					"class": "btn-cancel",
					"iconClass": "icon fas fa-times",
					"callback": this.handleActivateActionCancel.bind(this)
				},
				{
					"title": `${$.i18n("button-activate")}`,
					"class": "btn-submit",
					"iconClass": "icon fas fa-check",
					"callback": this.handleActivateActionConfirm.bind(this)
				}
			]);

			this.activateActionConfirmPopin.appendIn("#page");

			this.activateActionConfirmPopin.show();

		} else {

			// Deactivate action confirm popin
			this.deactivateActionConfirmPopin = new Popin("deactivate-action-confirm-popin", `${$.i18n("popin-deactivate-action-confirm-title")} <span class="highlight">${curActionName}</span> ?`, [
				{
					"title": `${$.i18n("button-cancel")}`,
					"class": "btn-cancel",
					"iconClass": "icon fas fa-times",
					"callback": this.handleDeactivateActionCancel.bind(this)
				},
				{
					"title": `${$.i18n("button-deactivate")}`,
					"class": "btn-submit",
					"iconClass": "icon fas fa-check",
					"callback": this.handleDeactivateActionConfirm.bind(this)
				}
			]);

			this.deactivateActionConfirmPopin.appendIn("#page");

			this.deactivateActionConfirmPopin.show();

		}

	}

	handleActivateActionCancel (e) {

		this.activateActionConfirmPopin.hide();

	}

	handleActivateActionConfirm (e) {

		// We define data to update
		const dataToUpdate = {
			"id": this.curActionId,
			"status": "valid"
		};

		actions.updateOne(dataToUpdate).then(this.editActionStatusSuccess.bind(this), this.editActionStatusFail.bind(this));

	}

	handleDeactivateActionCancel (e) {

		this.deactivateActionConfirmPopin.hide();

	}

	handleDeactivateActionConfirm (e) {

		// We define data to update
		const dataToUpdate = {
			"id": this.curActionId,
			"status": "deactivated"
		};

		actions.updateOne(dataToUpdate).then(this.editActionStatusSuccess.bind(this), this.editActionStatusFail.bind(this));

	}

	editActionStatusSuccess (response) {

		// We reload actions data
		const actionsParams = {
			"idCartography": cartographies.getCurrentCartographyId(),
			"idRisk": this.curRiskId

		};
		actions.getData(actionsParams, {"forceRequest": true}).then((response) => {

			// We hide the activate action confirm popin
			if ($("#activate-action-confirm-popin").length > 0) {

				this.activateActionConfirmPopin.hide();

			}

			// We hide the deactivate action confirm popin
			if ($("#deactivate-action-confirm-popin").length > 0) {

				this.deactivateActionConfirmPopin.hide();

			}

			// We reload the actions
			this.load("#section-actions-risk", this.actionsPlanData, this.isCurrentUserRPO, this.isCurrentUserAdminRisks);

		}).catch(debug.e);

	}

	editActionStatusFail (response, textStatus) {

		debug.v("ActionContainer - editActionStatusFail");

	}

	handleClickOnBtnDeleteOnLineAction (e) {

		e.stopPropagation();

		// We get the current risk ID
		this.curRiskId = parseInt($(e.currentTarget).parents(".line-action").attr("data-risk-id"));

		// We get the current action ID
		this.curActionId = parseInt($(e.currentTarget).parents(".line-action").attr("data-action-id"));

		// We get the current action data
		let curActionData;
		if (window.location.pathname.indexOf("/actions-plan/") >= 0) {

			curActionData = actions.dataActionsCartography.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		} else {

			curActionData = actions.data.filter((action) => parseInt(action.id) === parseInt(this.curActionId))[0];

		}

		// We define the current action name
		const curActionName = curActionData[`title_${$.i18n().locale}`];

		// Delete action confirm popin
		this.deleteActionConfirmPopin = new Popin("delete-action-confirm-popin", `${$.i18n("popin-delete-action-confirm-title")} <span class="highlight">${curActionName}</span> ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteActionCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteActionConfirm.bind(this)
			}
		]);

		$(".popin").remove();

		this.deleteActionConfirmPopin.appendIn("#page");

		this.deleteActionConfirmPopin.show();

	}

	handleDeleteActionConfirm (e) {

		// We get the current cartography ID
		const curCartographyId = cartographies.getCurrentCartographyId();

		// We delete the action
		actions.deleteOne(this.curActionId).then(() => {

			// We define params
			const params = {
				"idCartography": curCartographyId,
				"idRisk": this.curRiskId
			};

			actions.getData(params, {"forceRequest": true}).then(() => {

				// We hide the popin
				this.deleteActionConfirmPopin.hide();

				// We reload the actions
				this.load("#section-actions-risk", this.actionsPlanData);

			});

		}).catch((error) => {

			// If the error exist
			if (error.responseText.indexOf("action_already_in_actions_plans") >= 0) {

				let htmlLegalEntitiesWithCurrentAction = "";

				$(error.responseJSON.errors.id.action_already_in_actions_plans).each((i, actionPlan) => {

					htmlLegalEntitiesWithCurrentAction = `${htmlLegalEntitiesWithCurrentAction}<br />${actionPlan.legal_entity_name}`;

				});

				// Delete action error popin
				this.deleteActionErrorPopin = new Popin("delete-action-error-popin", `${$.i18n("popin-delete-action-error-title")}<br /><span class="highlight">${htmlLegalEntitiesWithCurrentAction}</span>`, [
					{
						"title": `${$.i18n("button-ok")}`,
						"class": "btn-submit",
						"iconClass": "icon fas fa-check",
						"callback": this.handleDeleteActionErrorConfirm.bind(this)
					}
				]);

				$(".popin").remove();

				this.deleteActionErrorPopin.appendIn("#page");

				this.deleteActionErrorPopin.show();


			}

		});

	}

	handleDeleteActionCancel (e) {

		this.deleteActionConfirmPopin.hide();

	}

	handleDeleteActionErrorConfirm (e) {

		this.deleteActionErrorPopin.hide();

	}

	showActionsPlanEditForm (lineAction) {

		// We define current cartography ID
		const curCartographyId = cartographies.getCurrentCartographyId();

		// We define current legal entity ID
		const curLegalEntityId = context.getCurrentLegalEntityId();

		// We define current risk ID
		const curRiskId = lineAction.attr("data-risk-id");

		// We define current action ID
		const curActionId = lineAction.attr("data-action-id");

		// We redirect to the actions plan edit form
		router.navigate(`/legal-entities/${curLegalEntityId}/cartographies/${curCartographyId}/risks/${curRiskId}/actions/${curActionId}/actions-plan/edit`);

	}

	switchActionToIgnoredStatus (lineAction) {

		// We define current cartography ID
		const curCartographyId = cartographies.getCurrentCartographyId();

		// We define current legal entity ID
		const curLegalEntityId = context.getCurrentLegalEntityId();

		// We define current risk ID
		const curRiskId = lineAction.attr("data-risk-id");

		// We define current action ID
		const curActionId = lineAction.attr("data-action-id");

		if (lineAction.attr("class").indexOf("status-ignored") < 0) {

			// We define data to update
			const dataToUpdate = {
				"cartography_id": parseInt(curCartographyId),
				"legal_entity_id": parseInt(curLegalEntityId),
				"actionsPlan": [
					{
						"action_id": curActionId,
						"status": "ignored"
					}
				]
			};

			actionsPlan.updateOne(dataToUpdate).then(this.editActionPlanStatusSuccess.bind(this), this.editActionPlanStatusFail.bind(this));

		} else {

			// We redirect to the actions plan edit form
			router.navigate(`/legal-entities/${curLegalEntityId}/cartographies/${curCartographyId}/risks/${curRiskId}/actions/${curActionId}/actions-plan/edit`);

		}

	}

	editActionPlanStatusSuccess (response) {

		// If we are on the action plan summary
		if (window.location.pathname.indexOf("/actions-plan/list") >= 0) {

			// We reload the page
  		document.location.reload();

		} else {

			// We reload actions plan data
			const actionsPlanParams = {
				"idCartography": cartographies.getCurrentCartographyId(),
				"idLegalEntity": context.getCurrentLegalEntityId()
			};
			actionsPlan.getData(actionsPlanParams, {"forceRequest": true}).then((response) => {

				// We reload the actions
				this.load("#section-actions-risk", response.actionsPlan);

			}).catch(debug.e);

		}

	}

	editActionPlanStatusFail (response, textStatus) {

		debug.v("ActionContainer - editActionPlanStatusFail");

	}

	handleClickOnBtnMoreLineAction (e) {


		// If the line action is not open
		if ($(e.currentTarget).attr("class").indexOf("active") < 0) {

			// We add the active class
			$(e.currentTarget).addClass("active");

			// We show the content
			$(e.currentTarget).find(".content").slideDown(200);

			// We update the button more
			$(e.currentTarget).find(".btn-more .icon").removeClass("fa-angle-down");
			$(e.currentTarget).find(".btn-more .icon").addClass("fa-angle-up");
			$(e.currentTarget).find(".btn-more .text").attr("data-i18n", "button-hide-details");
			$(e.currentTarget).find(".btn-more .text").html($.i18n("button-hide-details"));

		} else {

			// We remove the active class
			$(e.currentTarget).removeClass("active");

			// We hide the content
			$(e.currentTarget).find(".content").slideUp(200);

			// We update the button more
			$(e.currentTarget).find(".btn-more .icon").removeClass("fa-angle-up");
			$(e.currentTarget).find(".btn-more .icon").addClass("fa-angle-down");
			$(e.currentTarget).find(".btn-more .text").attr("data-i18n", "button-see-details");
			$(e.currentTarget).find(".btn-more .text").html($.i18n("button-see-details"));

		}

	}

}
export default new ActionContainer();
