import html from "./userRightsForm.html";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import context from "Datapoints/Context.js";
import userRightsProfilesForm from "Components/UserRightsProfilesForm/UserRightsProfilesForm.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import Popin from "Components/Popin/Popin.js";
import {updateObjectWithForm} from "Core/helpers.js";
import debug from "Core/Debug.js";
import "Pages/Admin/Admin.scss";
import "Pages/Admin/AdminResponsive.scss";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";

class UserRightsForm {

	constructor (editedUser, selector) {

		$(selector).append(html);

		this.editedUser = editedUser;

		if (this.editedUser.rights === undefined || this.editedUser.rights === null) {

			this.editedUser.rights = [];

		}

		this.init();

	}

	init () {

		// We get the current language
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We define the default values of the current user rights
		this.dataCurrentUserRight = {
			"cartography_id": null,
			"branch_id": null,
			"country_id": null,
			"legal_entity_id": null,
			"rights": null
		};

		// We define the name right
		this.nameRight = "";

		// We define the active tab
		this.activeTab = "specific-right";

		// We initialize the right name dropdown
		this.initRightNameDropdown();

		// We initialize the profil form
		this.initProfilesForm();

		// We initialize global events
		this.initEvents();

		// We show the list of user rights
		this.showUserRights();

		// We init the selec/unselect all button
		this.setSelectAllButton();
		this.setUnselectAllButton();

	}

	initEvents () {

		// If the config hides the branches
		if (process.env.SHOW_BRANCHES === false || process.env.SHOW_BRANCHES === "false") {

			// We remove the branch field
			$("#field-branch-right").remove();

			// We update the steps numbers and sizes
			$("#field-cartography-right .sub-section-title .num").html("2");

			if (process.env.SHOW_COUNTRIES === false || process.env.SHOW_COUNTRIES === "false") {

				$("#field-legal-entity-right .sub-section-title .num").html("3");

			} else {

				$("#field-country-right").addClass("full-width");
				$("#field-country-right .sub-section-title .num").html("3");
				$("#field-legal-entity-right .sub-section-title .num").html("4");

			}

		}

		// If the config hides the countries
		if (process.env.SHOW_COUNTRIES === false || process.env.SHOW_COUNTRIES === "false") {

			// We remove the country field
			$("#field-country-right").remove();

			// We update the steps numbers
			$("#field-legal-entity-right .sub-section-title .num").html("4");

		}

		// We listen click on tab button
		$("#section_user_rights .title .button-title").on("click", this.handleClickOnTabButton.bind(this));

		// We listen the change on select name right
		$("SELECT[name='name-right']").on("change", this.handleChangeOnSelectNameRight.bind(this));

		// We listen the change on select cartography right
		$("SELECT[name='cartography-right']").on("change", this.handleChangeOnSelectCartographyRight.bind(this));

		// We listen the change on select branch right
		$("SELECT[name='branch-right']").on("change", this.handleChangeOnSelectBranchRight.bind(this));

		// We listen the change on select country right
		$("SELECT[name='country-right']").on("change", this.handleChangeOnSelectCountryRight.bind(this));

		// We listen the change on select legal entity right
		$("SELECT[name='legal-entity-right']").on("change", this.handleChangeOnSelectLegalEntityRight.bind(this));

		// We listen click on the add right button
		$("#btn-add-right").on("click", this.handleClickOnAddRightButton.bind(this));

	}

	initRightNameDropdown () {

		$("#right-name-dropdown").select2({
			"language": "fr",
			"templateResult": this.setRightNameTemplateResult.bind(this),
			"templateSelection": this.setRightNameTemplateSelection.bind(this)
		});

	}

	setRightNameTemplateResult (right) {

		if (!right.id) {

			return right.text;

		}

		// We get the class icon right
		const classIconRight = this.getClassIconRight(right.id);

		// We get the help right text
		const helpRightText = this.getHelpRightText(right.id);

		// We define the template html
		const htmlTemplate = $(`<div class="dropdown-line-right-name"><div class="icon-right-name fas ${classIconRight}"></div><div class="desc-right-name"><span class="title-right-name">${right.text}</span><span class="text-right-name">${helpRightText}</span></div></div>`);

		return htmlTemplate;

	}

	setRightNameTemplateSelection (right) {

		return right.text;

	}

	initProfilesForm () {

		if (process.env.RIGHTS_PROFILES_ACTIVE === true || process.env.RIGHTS_PROFILES_ACTIVE === "true") {

			this.profilesForm = new userRightsProfilesForm("#form-profiles");

			// We listen click on profile item
			$("#section_user_rights .line-custom-checkbox .item-custom-checkbox").on("click", this.handleClickOnProfileItem.bind(this));

		} else {

			// We remove the user rights tabs
			$("#user-rights-tabs").remove();

		}

	}

	showUserRights () {

		// We get user temporary rights saved on the local storage
		const userRightsSavedOnLocalStorage = localStorage.getItem("editedUser-currentRights");

		// If user rights exist on the local storage
		if (
			userRightsSavedOnLocalStorage !== undefined &&
			userRightsSavedOnLocalStorage !== null &&
			userRightsSavedOnLocalStorage !== ""
		) {

			// We get the rights
			const userTemporaryRights = JSON.parse(userRightsSavedOnLocalStorage);

			this.editedUser.rights = userTemporaryRights;

		}

		// We empty the lines
		$("#section_user_rights .tab-result").html("");

		if (this.editedUser != undefined && this.editedUser.rights !== undefined && this.editedUser.rights !== null && this.editedUser.rights.length > 0) {

			this.editedUser.rights.forEach((right, index) => {

				const contextInfos = this.getContextInfosFromRight(right);
				const contextName = contextInfos.name;

				// We get the data of the cartography
				const dataCartography = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

				// We get the cartography name
				let cartographyName;
				let htmlSeparator;
				if (dataCartography !== undefined) {

					cartographyName = dataCartography[`name_${this.currentLang}`];

					htmlSeparator = "&nbsp;&nbsp;|&nbsp;&nbsp;";

				} else {

					cartographyName = "";

					htmlSeparator = "";

				}

				// We get the class of icon of the new right
				const classIconRight = this.getClassIconRight(right.rights[0]);

				// We get the lib of the new right
				const libRight = this.getLibRight(right.rights[0]);

				// We define the html of the line of right
				const htmlLineRight = `
					<div class="select-line">
						<div class="checkbox-result">
							<span class="icon fas fa-check"></span>
						</div>
						<div class="line-result" data-index-user-rights="${index}">
							<div class="info-context">
								<span class="name-context">${contextName}</span>
								${htmlSeparator}
								<span class="name-cartography">${cartographyName}</span>
							</div>
							<div class="other-info">
								<div class="label-right">
									<span class="icon fas ${classIconRight}"></span>
									<span class="text">${libRight}</span>
								</div>
							<div class="btn-delete-right">
								<span class="icon fas fa-trash-alt"></span>
							</div>
						</div>
					</div>
				`;

		    	// We show the line of right
				$("#section_user_rights .tab-result").prepend(htmlLineRight);

			});

			// We listen the click on the select line
			$("#section_user_rights .tab-result .select-line .checkbox-result, #section_user_rights .tab-result .select-line .line-result").on("click", this.handleClickOnSelectLine.bind(this));

			// We listen the click on the delete right button
			$("#section_user_rights .tab-result .line-result .btn-delete-right").on("click", this.handleClickOnDeleteRightButton.bind(this));

		}

	}

	setSelectAllButton () {

		// We count the number of result lines
		const countSelectLine = $("#section_user_rights .tab-result .select-line").length;

		if (countSelectLine > 0) {

			$("#select-all-button").removeClass("inactive");

		} else {

			$("#select-all-button").addClass("inactive");

		}

		// We listen the click on the select all button
		$("#select-all-button").on("click", this.handleClickOnSelectAllButton.bind(this));

	}

	setUnselectAllButton () {

		// We count the number of result lines
		const countSelectLine = $("#section_user_rights .tab-result .select-line").length;

		if (countSelectLine > 0) {

			$("#unselect-all-button").removeClass("inactive");

		} else {

			$("#unselect-all-button").addClass("inactive");

		}

		// We listen the click on the unselect all button
		$("#unselect-all-button").on("click", this.handleClickOnUnselectAllButton.bind(this));

	}

	handleClickOnSelectAllButton () {

		// We define the button selector
		const $button = $("#select-all-button");

		// We select all the unselected lines
		$(".select-line:not(.active)").addClass("active");

		// We init the delete all rights button
		this.setDeleteAllRightsButton();

	}

	handleClickOnUnselectAllButton () {

		// We define the button selector
		const $button = $("#unselect-all-button");

		// We unselect all the selected lines
		$(".select-line.active").removeClass("active");

		// We init the delete all rights button
		this.setDeleteAllRightsButton();

	}

	setDeleteAllRightsButton () {

		// We count the number of selected lines
		const countSelectedLines = $("#section_user_rights .tab-result .select-line.active").length;

		if (countSelectedLines > 0) {

			// We show the button
			$("#delete-all-rights-button").removeClass("inactive");

		} else {

			$("#delete-all-rights-button").addClass("inactive");

		}

		// We listen the click on the delete all rights button
		$("#delete-all-rights-button").on("click", this.handleClickOnDeleteSelectedRightsButton.bind(this));

	}

	handleClickOnDeleteSelectedRightsButton () {

		// Delete selected rights popin
		this.deleteSelectedRightsPopin = new Popin("delete-selected-rights-popin", `${$.i18n("popin-delete-selected-rights-confirmation-title")}`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteSelectedRightsCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteSelectedRightsConfirm.bind(this)
			}
		]);

		// We remove the popin
		$(".popin").remove();

		// We add the popin on the page
		this.deleteSelectedRightsPopin.appendIn("#page");

		// We show the popin
		this.deleteSelectedRightsPopin.show();

	}

	handleDeleteSelectedRightsCancel () {

		// We hide the popin
		this.deleteSelectedRightsPopin.hide();

	}

	handleDeleteSelectedRightsConfirm () {

		// We save the global this
		const self = this;

		// For each selected right
		$("#section_user_rights .select-line.active").each(function () {

			// We get current index right
			const curIndexRight = $(this).find(".line-result").data("index-user-rights");

			// We delete the right
			self.deleteRight(curIndexRight);

		});

		// We remove the active class on all the lines
		$("#section_user_rights .select-line").removeClass("active");

		// We hide the popin
		this.deleteSelectedRightsPopin.hide();

	}

	handleClickOnSelectLine (e) {

		// We toggle the active class
		$(e.currentTarget).parents(".select-line").toggleClass("active");

		// We init the delete all rights button
		this.setDeleteAllRightsButton();

	}

	handleClickOnTabButton (e) {

		// We get class of current tab
		const currentButtonClass = $(e.currentTarget).attr("class");

		if (currentButtonClass.indexOf("active") < 0) {

			// We get the slug id of the current tab
			const currentButtonSlug = $(e.currentTarget).attr("id").replace("button-", "");

			// We update the active tab
			this.activeTab = currentButtonSlug;

			// For profiles form
			if (currentButtonSlug === "profiles") {

				this.nameRight = "profile";

				addRightButtonLabel = $.i18n("button-add-this-user-profile");

				// We show the profile choice
				this.showProfileChoice();

			} else { // For specific right form

				addRightButtonLabel = $.i18n("button-add-this-user-right");

			}

			// We change the label of the add right button
			$("#btn-add-right .text").html(addRightButtonLabel);

			// We clear the tab forms
			this.clearTabForms();

			// We clear all the tabs
			$("#section_user_rights .button-title").removeClass("active");

			// We add active class to the current tab
			$(e.currentTarget).addClass("active");

			// We clear all form contents
			$("#section_user_rights .user-rights-form-group").removeClass("active");

			// We show the form content of the current tab
			$(`#form-${currentButtonSlug}`).addClass("active");

			// We define the label of the add right button
			let addRightButtonLabel;

		}

	}

	clearTabForms () {

		// We clear the user rights fields
		this.clearFieldNameRight();
		this.clearFieldCartographyRight();
		this.clearFieldBranchRight();
		this.clearFieldCountryRight();
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		// We clear the profile choice
		this.clearProfileChoice();

		// We re-init values of the current user rights
		this.dataCurrentUserRight = {
			"cartography_id": null,
			"branch_id": null,
			"country_id": null,
			"legal_entity_id": null,
			"rights": null
		};

	}

	showProfileChoice () {

		// We show the field
		$("#field-profile-choice").removeClass("inactive");

	}

	handleClickOnProfileItem (e) {

		// We get the class of the current item
		const currentItemClass = $(e.currentTarget).attr("class");


		if (currentItemClass.indexOf("active") < 0) {

			// We get the selected profile name
			this.selectedProfileName = $(e.currentTarget).attr("data-profile-name");

			// We remove all the items
			$("#section_user_rights .line-custom-checkbox .item-custom-checkbox").removeClass("active");

			// We add the active class to the current item
			$(e.currentTarget).addClass("active");

			if (this.selectedProfileName === "super-admin") {

				// We hide all the fields
				$("#form-profiles").addClass("no-fields");
				$("#form-profiles").removeClass("only-branch");

				// We show the add right button
				$("#btn-add-right").removeClass("inactive");

			} else if (this.selectedProfileName === "head-office-admin") {

				// If the environment config hides the branches
				// We hide all the fields
				$("#form-profiles").removeClass("no-fields");
				$("#form-profiles").addClass("only-branch-and-cartography");

				// We load the branch field
				this.loadBranchRight(this.nameRight);


			} else {

				// We show all the fields
				$("#form-profiles").removeClass("no-fields");
				$("#form-profiles").removeClass("only-branch");

				// We load the branch field
				this.loadBranchRight(this.nameRight);

			}

		} else {

			// We update the selected profile name
			this.selectedProfileName = null;

			// We remove the active class to the current item
			$(e.currentTarget).removeClass("active");

			// We show all the fields
			$("#form-profiles").removeClass("no-fields");
			$("#form-profiles").removeClass("only-branch");

			// We clear the form fields
			this.clearFieldBranchRight();
			this.clearFieldCartographyRight();
			this.clearFieldCountryRight();
			this.clearFieldLegalEntityRight();

			// We clear the add right button
			this.clearAddRightButton();

		}

	}

	handleChangeOnSelectNameRight () {

		// We hide the errors and rewriting the rights
		$("FORM #rights .error").remove();
		this.showUserRights();

		// We define name right value
		this.nameRight = $("SELECT[name='name-right']").val();

		// We have exceptions if we choose some rights
		if (this.nameRight === "admin_cartographies" || this.nameRight === "access_statistics" || this.nameRight === "admin_business_activities") {

			this.loadFieldsForAdminDataRights(this.nameRight);

		} else if (this.nameRight === "communicate") {

			// We load fields for communicate right
			this.loadFieldsForCommunicateRight(this.nameRight);

		} else if (this.nameRight === "admin_risks") {

			this.loadFieldsForAdminCartographyRight(this.nameRight);

		} else {

			// We load the select of branch right
			this.loadBranchRight(this.nameRight);

		}

	}

	loadFieldsForAdminDataRights (nameRight) {

		// We add a new class to the container
		$("#section_user_rights").removeClass("communicate-selected");
		$("#section_user_rights").removeClass("admin-cartography-selected");
		$("#section_user_rights").addClass("admin-data-selected");

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			// We load branches for the rights "admin_cartographies", "admin_business_activities" and "access_statistics"
			this.loadBranchesForAdminDataRights(nameRight);

		} else {

			// We update the data of current user rights
			this.dataCurrentUserRight = {
				"cartography_id": null,
				"branch_id": context.getCurrentBranchId(),
				"country_id": null,
				"legal_entity_id": null,
				"rights": `[${nameRight}]`
			};

			// We show the add right button
			$("#btn-add-right").removeClass("inactive");

		}

	}

	loadFieldsForAdminCartographyRight (nameRight) {

		// We add a new class to the container
		$("#section_user_rights").removeClass("communicate-selected");
		$("#section_user_rights").removeClass("admin-data-selected");
		$("#section_user_rights").addClass("admin-cartography-selected");

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			// We load the select of branch right
			this.loadBranchRight(this.nameRight);

			// We change the style of the cartography field
			$("#field-cartography-right").addClass("full-width");

		}

	}

	loadFieldsForCommunicateRight (nameRight) {

		// We hide the field for cartography right
		$("#section_user_rights").removeClass("admin-data-selected");
		$("#section_user_rights").removeClass("admin-cartography-selected");
		$("#section_user_rights").addClass("communicate-selected");

		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

				// We change the step numbers
				$("#field-country-right .sub-section-title .num").html("3");
				$("#field-legal-entity-right .sub-section-title .num").html("4");

			} else {

				// We change the step numbers
				$("#field-country-right .sub-section-title .num").html("2");
				$("#field-legal-entity-right .sub-section-title .num").html("3");

			}

			// We change the style of the country field
			$("#field-country-right").addClass("full-width");

		} else {

			$("#field-country-right").remove();
			$("#field-legal-entity-right .sub-section-title .num").html("3");

		}

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			// We load branches for the right "communicate"
			this.loadBranchesForCommunicateRight(nameRight);

		} else {

			// We update the data of current user rights
			this.dataCurrentUserRight = {
				"cartography_id": null,
				"branch_id": context.getCurrentBranchId(),
				"country_id": null,
				"legal_entity_id": null,
				"rights": ["communicate"]
			};

			if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

				// We load the select of country right for the right "communicate"
				this.loadCountryForCommunicateRight(context.getCurrentBranchId());

			} else {

				// We load the legal entity field width params "all" for countries
				this.loadLegalEntityRight("all");

			}

		}


	}

	unloadFieldsForAdminRight () {

		// We remove the class to the container
		$("#section_user_rights").removeClass("admin-data-selected");
		$("#section_user_rights").removeClass("communicate-selected");
		$("#section_user_rights").removeClass("admin-cartography-selected");

		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

				// We change the step numbers
				$("#field-country-right .sub-section-title .num").html("4");
				$("#field-legal-entity-right .sub-section-title .num").html("5");

				// We change the style of the country field
				$("#field-country-right").removeClass("full-width");

			} else {

				// We change the step numbers
				$("#field-country-right .sub-section-title .num").html("3");
				$("#field-legal-entity-right .sub-section-title .num").html("4");

			}

		} else {

			$("#field-country-right").remove();
			$("#field-legal-entity-right .sub-section-title .num").html("4");

		}

	}

	loadBranchRight (nameRight) {

		// We clear the hidden fields for the right "admin_risks"
		if (nameRight !== undefined && nameRight !== "admin_risks") {

			this.unloadFieldsForAdminRight();

		}

		// We clear the current and the next fields
		this.clearFieldBranchRight();
		this.clearFieldCartographyRight();
		this.clearFieldCountryRight();
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			if (nameRight !== "" && nameRight !== undefined) {

				// We update the data of current user rights
				this.dataCurrentUserRight.rights = [nameRight];

				// We define the branch right selector
				let branchRightSelector;

				// We define the branch section
				let branchSection;

				if (this.nameRight === "profile") {

					branchRightSelector = $("#form-profiles SELECT[name='branch-right']");

					branchSection = $("#field-branch-profile");

				} else {

					branchRightSelector = $("#form-specific-right SELECT[name='branch-right']");

					branchSection = $("#field-branch-right");

				}

				user.getUserBranchesForAdminUsersRight().then((data) => {

					this.listBranches = data.branches;

					// We load branches
					if (this.listBranches !== undefined) {

						// We clear the branch select
						branchRightSelector.html("");

						this.listBranches.forEach((branch, index) => {

							// We define the option
							const htmlOption = `<option value="${branch.id}">${branch.fr}</option>`;

							if (index === 0) {

								// We add first an empty option
								branchRightSelector.append("<option value=''></option>");

								// We add the option on the select
								branchRightSelector.append(htmlOption);

							} else {

								// We add the option on the select
								branchRightSelector.append(htmlOption);

							}

						});

						// We remove the inactive status on branch section
						branchSection.removeClass("inactive");

					}

				}).catch((error) => {

					debug.v("UserRightsForm - loadBranchRight - error in User getBranchesRights");

				});

			}

		} else {

			// We update the data of current user rights
			this.dataCurrentUserRight = {
				"cartography_id": null,
				"branch_id": context.getCurrentBranchId(),
				"country_id": null,
				"legal_entity_id": null,
				"rights": [nameRight]
			};

			// We load the select of cartography right
			this.loadCartographyRight(context.getCurrentBranchId());

		}

	}

	handleChangeOnSelectBranchRight () {

		// We define branch right value
		let branchIdSelected;

		if (this.nameRight === "profile") {

			branchIdSelected = $("#form-profiles SELECT[name='branch-right']").val();

		} else {

			branchIdSelected = $("#form-specific-right SELECT[name='branch-right']").val();

		}

		// We create an exception for the right "communicate" (which don't need a cartography)
		if (this.nameRight === "communicate") {

			// We update the data of current user rights
			this.dataCurrentUserRight.branch_id = branchIdSelected;

			// We load the select of country right
			this.loadCountryRight();

		} else {

			// We load the select of cartography right
			this.loadCartographyRight(branchIdSelected);

		}

	}

	loadCartographyRight (branchIdSelected) {

		// We clear the current and the next fields
		this.clearFieldCartographyRight();
		this.clearFieldCountryRight();
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		if (branchIdSelected !== "" && branchIdSelected !== undefined) {

			// We update the data of current user rights
			this.dataCurrentUserRight.branch_id = branchIdSelected;

			// We define the cartography right selector
			let cartographyRightSelector;

			// We define the cartography section
			let cartographySection;

			if (this.nameRight === "profile") {

				cartographyRightSelector = $("#form-profiles SELECT[name='cartography-right']");

				cartographySection = $("#field-cartography-profile");

			} else {

				cartographyRightSelector = $("#form-specific-right SELECT[name='cartography-right']");

				cartographySection = $("#field-cartography-right");

			}

			user.getCartographiesByBranchesRights(this.dataCurrentUserRight.branch_id).then((data) => {

				this.listCartographies = data.cartographies.filter((cartography) => parseInt(cartography.branch_id) === parseInt(branchIdSelected));

				// We load cartographies
				if (this.listCartographies !== undefined) {

					this.listCartographies.forEach((cartography, index) => {

						// We define the option
						const htmlOption = `<option value="${cartography.id}">${cartography[`name_${this.currentLang}`]}</option>`;

						if (index === 0) {

							// We add first an empty option
							cartographyRightSelector.append("<option value=''></option>");

							// If the head office admin profile is selected
							if (
								this.activeTab === "profiles" &&
								this.selectedProfileName === "head-office-admin"
							) {

								// We the option "all" on the cartographies dropdown list
								cartographyRightSelector.append(`<option value="all">${$.i18n("filter-option-see-all-cartography")}</option>`);

							}

							// We add the option on the select
							cartographyRightSelector.append(htmlOption);

						} else {

							// We add the option on the select
							cartographyRightSelector.append(htmlOption);

						}

					});

					// We remove the inactive status on cartography section
					cartographySection.removeClass("inactive");

				}

			}).catch((error) => {

				debug.v("UserRightsForm - loadCartographyRight - error in User getCartographiesByBranchesRights");

			});

		}

	}

	handleChangeOnSelectCartographyRight () {

		// We define cartography right value
		let cartographyIdSelected;

		if (this.nameRight === "profile") {

			cartographyIdSelected = $("#form-profiles SELECT[name='cartography-right']").val();

		} else {

			cartographyIdSelected = $("#form-specific-right SELECT[name='cartography-right']").val();

		}

		if (
			this.dataCurrentUserRight.rights.indexOf("admin_risks") >= 0 ||
			this.activeTab === "profiles" && this.selectedProfileName === "head-office-admin"
		) {

			// We update the data of current user rights
			this.dataCurrentUserRight.cartography_id = cartographyIdSelected;

			// We show the add right button
			$("#btn-add-right").removeClass("inactive");

		} else if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			// We load the select of branch right
			this.loadCountryRight(cartographyIdSelected);

		} else {

			// We update the data of current user rights
			this.dataCurrentUserRight.cartography_id = cartographyIdSelected;

			// We load the legal entity field width params "all" for countries
			this.loadLegalEntityRight("all");

		}

	}

	loadBranchesForAdminDataRights (nameRight) {

		// We update the data of current user rights
		this.dataCurrentUserRight = {
			"cartography_id": null,
			"branch_id": null,
			"country_id": null,
			"legal_entity_id": null,
			"rights": [nameRight]
		};

		user.getUserBranchesForAdminUsersRight().then((data) => {

			// We load branches
			if (data.branches !== undefined) {

				// We clear the branch select
				$("SELECT[name='branch-right']").html("");

				data.branches.forEach((branch, index) => {

					// We define the option
					const htmlOption = `<option value="${branch.id}">${branch.fr}</option>`;

					if (index === 0) {

						// We add first an empty option
						$("SELECT[name='branch-right']").append("<option value=''></option>");

						// We add the option on the select
						$("SELECT[name='branch-right']").append(htmlOption);

					} else {

						// We add the option on the select
						$("SELECT[name='branch-right']").append(htmlOption);

					}

				});

				// We remove the inactive status on branch section
				$("#field-branch-right").removeClass("inactive");

				// We listen the change on select legal entity right
				$("SELECT[name='branch-right']").on("change", this.handleChangeOnSelectBranchForAdminDataRights.bind(this));

			}

		}).catch((error) => {

			debug.v("UserCreate - loadBranchesForAdminDataRights - error in User getUserBranchesForAdminUsersRight");

		});


	}

	loadBranchesForCommunicateRight (nameRight) {

		// We update the data of current user rights
		delete this.dataCurrentUserRight.cartography_id;
		this.dataCurrentUserRight = {
			"branch_id": null,
			"country_id": null,
			"legal_entity_id": null,
			"rights": [nameRight]
		};

		user.getUserBranchesForAdminUsersRight().then((data) => {

			// We load branches
			if (data.branches !== undefined) {

				// We clear the branch select
				$("SELECT[name='branch-right']").html("");

				data.branches.forEach((branch, index) => {

					// We define the option
					const htmlOption = `<option value="${branch.id}">${branch.fr}</option>`;

					if (index === 0) {

						// We add first an empty option
						$("SELECT[name='branch-right']").append("<option value=''></option>");

						// We add the option on the select
						$("SELECT[name='branch-right']").append(htmlOption);

					} else {

						// We add the option on the select
						$("SELECT[name='branch-right']").append(htmlOption);

					}

				});

				// We remove the inactive status on branch section
				$("#field-branch-right").removeClass("inactive");

				// We listen the change on select legal entity right
				$("SELECT[name='branch-right']").on("change", this.handleChangeOnSelectBranchForCommunicateRight.bind(this));

			}

		}).catch((error) => {

			debug.v("UserCreate - loadBranchesForCommunicateRight - error in User getUserBranchesForAdminUsersRight");

		});


	}

	loadCountryRight (cartographyIdSelected) {

		// We clear the current and the next fields
		this.clearFieldCountryRight();
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		if (cartographyIdSelected !== "" && cartographyIdSelected !== undefined) {

			// We update the data of current user rights
			this.dataCurrentUserRight.cartography_id = cartographyIdSelected;

			// We define the country right selector
			let countryRightSelector;

			// We define the country section
			let countrySection;

			if (this.nameRight === "profile") {

				countryRightSelector = $("#form-profiles SELECT[name='country-right']");

				countrySection = $("#field-country-profile");

			} else {

				countryRightSelector = $("#form-specific-right SELECT[name='country-right']");

				countrySection = $("#field-country-right");

			}

			// We get current branch id
			let currentUserRightBranchId;
			if (this.dataCurrentUserRight.branch_id !== undefined && this.dataCurrentUserRight.branch_id !== null) {

				currentUserRightBranchId = this.dataCurrentUserRight.branch_id;

			} else if (this.activeTab === "profiles") {

				currentUserRightBranchId = $("#form-profiles SELECT[name='branch-right']").val();

			} else {

				currentUserRightBranchId = $("#form-specific-right SELECT[name='branch-right']").val();

			}

			user.getCountriesRights(this.dataCurrentUserRight.cartography_id, currentUserRightBranchId).then((data) => {

				this.listCountries = data.countries;

				// We order countries by alphabetical order
				this.listCountries.sort((country1, country2) => country1[this.currentLang].localeCompare(country2[this.currentLang]));

				// We put the all item in the first line of the array
				const indexItemAll = this.listCountries.findIndex((country) => country === "all");
				if (indexItemAll > -1) {

					this.listCountries.splice(indexItemAll, 1);
					this.listCountries.unshift("all");

				}

				// We load countries
				if (this.listCountries !== undefined) {

					this.listCountries.forEach((country, index) => {

						// We define the option
						let htmlOption;
						if (country === "all") {

							htmlOption = `<option value=\"all\">${$.i18n("labelAllCountries")}</option>`;

						} else {

							htmlOption = `<option value="${country.id}">${country[this.currentLang]}</option>`;

						}


						if (index === 0) {

							// We add first an empty option
							countryRightSelector.append("<option value=''></option>");

							// We add the option on the select
							countryRightSelector.append(htmlOption);

						} else {

							// We add the option on the select
							countryRightSelector.append(htmlOption);

						}

					});

					// We remove the inactive status on country section
					countrySection.removeClass("inactive");

				}

			}).catch((error) => {

				debug.v("UserRightsForm - loadCountryRight - error in User getCountriesRights");

			});

		}

	}

	loadCountryForCommunicateRight (branchIdSelected) {

		// We clear the current and the next fields
		this.clearFieldCountryRight();
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		if (branchIdSelected !== "" && branchIdSelected !== undefined) {

			// We update the data of current user rights
			this.dataCurrentUserRight.branch_id = branchIdSelected;

			user.getCountriesForRightToFilterOn(this.dataCurrentUserRight.branch_id, "admin_users").then((data) => {

				this.listCountries = data.countries;

				// We order countries by alphabetical order
				this.listCountries.sort((country1, country2) => country1[this.currentLang].localeCompare(country2[this.currentLang]));

				// We put the all item in the first line of the array
				const indexItemAll = this.listCountries.findIndex((country) => country === "all");
				if (indexItemAll > -1) {

					this.listCountries.splice(indexItemAll, 1);
					this.listCountries.unshift("all");

				}

				// We load countries
				if (this.listCountries !== undefined) {

					this.listCountries.forEach((country, index) => {

						// We define the option
						let htmlOption;
						if (country === "all") {

							htmlOption = `<option value=\"all\">${$.i18n("labelAllCountries")}</option>`;

						} else {

							htmlOption = `<option value="${country.id}">${country[this.currentLang]}</option>`;

						}


						if (index === 0) {

							// We add first an empty option
							$("SELECT[name='country-right']").append("<option value=''></option>");

							// We add the option on the select
							$("SELECT[name='country-right']").append(htmlOption);

						} else {

							// We add the option on the select
							$("SELECT[name='country-right']").append(htmlOption);

						}

					});

					// We remove the inactive status on country section
					$("#field-country-right").removeClass("inactive");

				}

			}).catch((error) => {

				debug.v("UserRightsForm - loadCountryForCommunicateRight - error in User getCountriesForRightToFilterOn");

			});

		}

	}

	handleChangeOnSelectCountryRight () {

		debug.v("UserRightsForm - handleChangeOnSelectCountryRight");

		// We define country right value
		let countrySelected;

		if (this.nameRight === "profile") {

			countrySelected = $("#form-profiles SELECT[name='country-right']").val();

		} else {

			countrySelected = $("#form-specific-right SELECT[name='country-right']").val();

		}

		// We load the select of country right
		this.loadLegalEntityRight(countrySelected);

	}

	loadLegalEntityRight (countrySelected) {

		debug.v(`UserRightsForm - loadLegalEntityRight - ${countrySelected}`);

		// We clear the current and the next fields
		this.clearFieldLegalEntityRight();
		this.clearAddRightButton();

		if (countrySelected !== "" && countrySelected !== undefined) {

			debug.v("UserRightsForm - loadLegalEntityRight - Country choosen");

			// We update the data of current user rights
			this.dataCurrentUserRight.country_id = countrySelected;

			// If the config shows the countries
			if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

				debug.v("UserRightsForm - loadLegalEntityRight - With countries visible");

				this.showLegalEntityFieldWithCountry();

			} else {

				debug.v("UserRightsForm - loadLegalEntityRight - Without countries");

				this.showLegalEntityFieldWithoutCountry();

			}

		}

	}

	showLegalEntityFieldWithCountry () {

		// We get current branch id
		let currentUserRightBranchId;
		if (this.dataCurrentUserRight.branch_id !== undefined && this.dataCurrentUserRight.branch_id !== null) {

			currentUserRightBranchId = this.dataCurrentUserRight.branch_id;

		} else if (this.activeTab === "profiles") {

			currentUserRightBranchId = $("#form-profiles SELECT[name='branch-right']").val();

		} else {

			currentUserRightBranchId = $("#form-specific-right SELECT[name='branch-right']").val();

		}

		// There is an exception if the selected right is "communicate"
		if (this.dataCurrentUserRight.rights.indexOf("communicate") >= 0) {

			user.getLegalEntitiesForRightToFilterOn(currentUserRightBranchId, this.dataCurrentUserRight.country_id, "admin_users").then((data) => {

				// Because the user could scroll thrue the select with keyboard,
				// (and that's how the browser does it in test condition),
				// we want to make sure the selected val is still the one wanted when request was sent
				if (
					this.nameRight === "profile" &&
					$("#form-profiles SELECT[name='country-right']").val() != this.dataCurrentUserRight.country_id) {

					return;

				}

				if (
					this.nameRight !== "profile" &&
					$("#form-specific-right SELECT[name='country-right']").val() != this.dataCurrentUserRight.country_id) {

					return;

				}

				this.listLegalEntities = data.legalEntities;

				// We load legal entities
				this.loadLegalEntitiesSelector();

			}).catch((error) => {

				debug.v("UserRightsForm - showLegalEntityFieldWithCountry - error in User getLegalEntitiesForRightToFilterOn");

			});


		} else {

			user.getLegalEntitiesRights(this.dataCurrentUserRight.cartography_id, currentUserRightBranchId, this.dataCurrentUserRight.country_id).then((data) => {

				// Because the user could scroll thrue the select with keyboard,
				// (and that's how the browser does it in test condition),
				// we want to make sure the selected val is still the one wanted when request was sent
				if (
					this.nameRight === "profile" &&
					$("#form-profiles SELECT[name='country-right']").val() != this.dataCurrentUserRight.country_id
				) {

					return;

				}

				if (
					this.nameRight !== "profile" &&
					$("#form-specific-right SELECT[name='country-right']").val() != this.dataCurrentUserRight.country_id
				) {

					return;

				}

				this.listLegalEntities = data.legalEntities;

				// We load legal entities
				this.loadLegalEntitiesSelector();


			}).catch((error) => {

				debug.v("UserRightsForm - showLegalEntityFieldWithCountry - error in User getLegalEntitiesRights");

			});

		}

	}

	showLegalEntityFieldWithoutCountry () {

		legalEntities.getAllLegalEntities().then((data) => {

			this.listLegalEntities = data;

			// We load legal entities
			this.loadLegalEntitiesSelector();

		}).catch((error) => {

			debug.v("UserRightsForm - showLegalEntityFieldWithoutCountry - error in LegalEntities getAllLegalEntities");

		});

	}

	loadLegalEntitiesSelector () {

		if (this.listLegalEntities !== undefined) {

			// We clear the field
			this.clearFieldLegalEntityRight();

			// We define the legal entity right selector
			let legalEntityRightSelector;

			// We define the legal entity selector id
			let legalEntityRightSelectorId;

			// We define the legal entity section
			let legalEntitySection;

			if (this.nameRight === "profile") {

				legalEntityRightSelector = $("#form-profiles SELECT[name='legal-entity-right']");

				legalEntityRightSelectorId = "legal-entity-profile";

				legalEntitySection = $("#field-legal-entity-profile");

			} else {

				legalEntityRightSelector = $("#form-specific-right SELECT[name='legal-entity-right']");

				legalEntityRightSelectorId = "legal-entity-right";

				legalEntitySection = $("#field-legal-entity-right");

			}

			this.listLegalEntities.forEach((legalEntity, index) => {

				// We define the option
				let htmlOption;
				if (legalEntity === "all") {

					htmlOption = `<option value=\"all\">${$.i18n("labelAllLegalEntities")}</option>`;

				} else {

					let htmlTelaNumber = "";

					if (legalEntity.tela_number !== undefined && legalEntity.tela_number !== null && legalEntity.tela_number !== "") {

						htmlTelaNumber = `(${legalEntity.tela_number})`;

					}

					htmlOption = `<option value="${legalEntity.id}">${legalEntity.name} ${htmlTelaNumber}</option>`;

				}

				if (index === 0) {

					// We add first an empty option
					legalEntityRightSelector.append("<option value=''></option>");

					// We add the option on the select
					legalEntityRightSelector.append(htmlOption);

				} else {

					// We add the option on the select
					legalEntityRightSelector.append(htmlOption);

				}

			});

			// Initialize select 2
			$(`#${legalEntityRightSelectorId}`).select2({
				"language": "fr"
			});

			// We put the placeholder on the input search of the select2
			$(`#${legalEntityRightSelectorId}`).one("select2:open", (e) => {

				$("input.select2-search__field[aria-controls='select2-legal-entity-right-results']").prop("placeholder", `${$.i18n("form-placeholder-legal-entity")}`);

			});

			// Customize select 2
			$("#field-legal-entity-right .select2-selection__arrow").find("b").hide();
			$("#field-legal-entity-right .select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");

			// We remove the inactive status on legal entity section
			legalEntitySection.removeClass("inactive");

		}

	}

	handleChangeOnSelectBranchForAdminDataRights () {

		// We define branch right value
		const branchSelected = $("SELECT[name='branch-right']").val();

		if (branchSelected !== "" && branchSelected !== undefined) {

			// We update the data of current user rights
			this.dataCurrentUserRight.branch_id = branchSelected;

			// We show the add right button
			$("#btn-add-right").removeClass("inactive");

		}

	}

	handleChangeOnSelectBranchForCommunicateRight () {

		// We define branch right value
		const branchIdSelected = $("SELECT[name='branch-right']").val();

		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			// We load the select of country right for the right "communicate"
			this.loadCountryForCommunicateRight(branchIdSelected);

		} else {

			// We load the legal entity field width params "all" for countries
			this.loadLegalEntityRight("all");

		}


	}

	handleChangeOnSelectLegalEntityRight () {

		// We define legal entity right value
		let legalEntitySelected;

		if (this.nameRight === "profile") {

			legalEntitySelected = $("#form-profiles SELECT[name='legal-entity-right']").val();

		} else {

			legalEntitySelected = $("#form-specific-right SELECT[name='legal-entity-right']").val();

		}

		if (legalEntitySelected !== "" && legalEntitySelected !== undefined) {

			// We update the data of current user rights
			this.dataCurrentUserRight.legal_entity_id = legalEntitySelected;

			// We show the add right button
			$("#btn-add-right").removeClass("inactive");

		}

	}

	handleClickOnAddRightButton (e) {

		if (!$("#btn-add-right").hasClass("inactive")) {

			// If the rights are null, we set an empty array as we expect to work on an array
			if (this.editedUser.rights === null) {

				this.editedUser.rights = [];

			}

			// SPECIFIC RIGHT
			if (this.activeTab !== "profiles") {

				const contextInfos = this.getContextInfosFromRight(this.dataCurrentUserRight);
				let contextType = contextInfos.type;

				// We add the new right to data user rights
				if (contextType === "legal_entity") {

					this.editedUser.rights.push({
						"cartography_id": parseInt(this.dataCurrentUserRight.cartography_id),
						"legal_entity_id": parseInt(this.dataCurrentUserRight.legal_entity_id),
						"rights": [this.dataCurrentUserRight.rights[0]]
					});

				} else if (contextType === "country") {

					this.editedUser.rights.push({
						"cartography_id": parseInt(this.dataCurrentUserRight.cartography_id),
						"branch_id": parseInt(this.dataCurrentUserRight.branch_id),
						"country_id": parseInt(this.dataCurrentUserRight.country_id),
						"rights": [this.dataCurrentUserRight.rights[0]]
					});

				} else if (
					contextType === "branch" &&
					(this.dataCurrentUserRight.rights[0] === "admin_cartographies" || this.dataCurrentUserRight.rights[0] === "admin_business_activities" || this.dataCurrentUserRight.rights[0] === "access_statistics")
				) {

					this.editedUser.rights.push({
						"branch_id": parseInt(this.dataCurrentUserRight.branch_id),
						"rights": [this.dataCurrentUserRight.rights[0]]
					});

				} else if (contextType === "branch" && this.dataCurrentUserRight.rights[0] === "admin_risks") {

					this.editedUser.rights.push({
						"cartography_id": parseInt(this.dataCurrentUserRight.cartography_id),
						"all_legal_entities": true,
						"rights": [this.dataCurrentUserRight.rights[0]]
					});

				} else if (contextType === "branch") {

					this.editedUser.rights.push({
						"cartography_id": parseInt(this.dataCurrentUserRight.cartography_id),
						"branch_id": parseInt(this.dataCurrentUserRight.branch_id),
						"rights": [this.dataCurrentUserRight.rights[0]]
					});

				}

				if (this.dataCurrentUserRight.cartography_id === undefined || this.dataCurrentUserRight.cartography_id === null) {

					delete this.dataCurrentUserRight.cartography_id;
					delete this.editedUser.rights[this.editedUser.rights.length - 1].cartography_id;

				}

			} else { // PROFILES

				// We get rights from current profile
				let currentProfileRights = this.profilesForm.getRightsFromProfile(this.selectedProfileName, this.dataCurrentUserRight);

				// We add these profile rights to the edited user rights
				this.editedUser.rights = this.editedUser.rights.concat(currentProfileRights);

			}

			// We clear the user rights fields
			this.clearFieldNameRight();
			this.clearFieldCartographyRight();
			this.clearFieldBranchRight();
			this.clearFieldCountryRight();
			this.clearFieldLegalEntityRight();
			this.clearAddRightButton();

			// We clear the profile choice
			this.clearProfileChoice();

			// We listen the click on the delete right button
			$("#section_user_rights .tab-result .line-result .btn-delete-right").on("click", this.handleClickOnDeleteRightButton.bind(this));

			if (this.editedUser.id != undefined) {

				// We test the rights against the api
				users.updateOne({
					...this.editedUser,
					"pretend": true
				}).then(this.handleAPIVerifiedRights.bind(this), (error) => {

					if (error.status === 422) {

						this.handleErrorOnAddRights(error);

					}

				});

			} else {

				// We test the rights against the api
				users.createOne({
					...this.editedUser,
					"pretend": true
				}).then(this.handleAPIVerifiedRights.bind(this), (error) => {

					if (error.status === 422) {

						this.handleErrorOnAddRights(error);

					}

				});

			}

			// If the help mode is active
			if ($("body").attr("class").indexOf("help-mode-active") >= 0) {

				// We update the tooltips positions
				helpSequence.updateTooltipsPositions();

			}

			// We init the select/unselect all button
			this.setSelectAllButton();
			this.setUnselectAllButton();

		}

	}

	handleErrorOnAddRights (error) {

		// We define the text error
		let textErrors = this.getTextErrors(error);

		// We remove the last right saved
		this.editedUser.rights.pop();

		// We show the error
		$("FORM #rights .error").remove();
		let allErrors = "";
		textErrors.forEach((errorToShow) => {

			if (allErrors != "") {

				allErrors += "<br/>";

			}
			allErrors += errorToShow;

		});
		$("FORM #rights").prepend(`<div class='error'>${allErrors}</div>`);

	}

	handleAPIVerifiedRights (userReturnedByAPI) {

		// We update the rights with the one given by the api (the api filter doubles and not allowed rights)
		this.editedUser.rights = userReturnedByAPI.rights;

		// If the rights are null, we set an empty array as we expect to work on an array
		if (this.editedUser.rights == null) {

			this.editedUser.rights = [];

		}

		// We remove user rights saved in the local storage
		localStorage.removeItem("editedUser-currentRights");

		// We save the rights on the local storage
		localStorage.setItem("editedUser-currentRights", JSON.stringify(this.editedUser.rights));

		// We show the user's rights
		this.showUserRights();

	}

	handleClickOnDeleteRightButton (e) {

		// We stop the click propagation
		e.stopPropagation();

		// We get the index of the current right in dataUserRights array
		const curIndexRight = $(e.currentTarget).parents(".line-result").attr("data-index-user-rights");

		// We delete the right
		this.deleteRight(curIndexRight);

	}

	deleteRight (indexRight) {

		// We define the line selector of the right to delete
		const $rightLine = $(`#section_user_rights .line-result[data-index-user-rights=${indexRight}]`);

		// We get the right line class
		const rightLineClass = $rightLine.attr("class");

		// We temporary save the right to delete
		const userRightToDelete = this.editedUser.rights[indexRight];

		// We delete the right in the array of list of rights
		this.editedUser.rights.splice(indexRight, 1);

		// We send update request to check if we can delete this right for the user
		if (rightLineClass.indexOf("error") < 0) {

			if (this.editedUser.id != undefined) {

				users.updateOne({
					...this.editedUser,
					"pretend": true
				}).then(this.handleAPIVerifiedRights.bind(this), (error) => {

					if (error.status === 422) {

						// We define the text error
						let textErrors = this.getTextErrors(error);

						// We add the errors to the right line
						let htmlErrors = "";
						textErrors.forEach((errorToShow) => {

							htmlErrors += `<div class="msg-error"><span class="icon fas fa-exclamation-triangle"></span><span class="text">${errorToShow}</span></div>`;

						});
						$rightLine.prepend(htmlErrors);
						$rightLine.addClass("error");

						// We re-add the right to delete to the user rights list
						$rightLine.attr("data-index-user-rights", this.editedUser.rights.length);
						this.editedUser.rights.push(userRightToDelete);

					}

				});

			} else {

				users.createOne({
					...this.editedUser,
					"pretend": true
				}).then(this.handleAPIVerifiedRights.bind(this), (error) => {

					if (error.status === 422) {

						// We define the text error
						let textErrors = this.getTextErrors(error);

						// We add the error to the right line
						let htmlErrors = "";
						textErrors.forEach((errorToShow) => {

							htmlErrors += `<div class="msg-error"><span class="icon fas fa-exclamation-triangle"></span><span class="text">${errorToShow}</span></div>`;

						});
						$rightLine.prepend(htmlErrors);
						$rightLine.addClass("error");

						// We re-add the right to delete to the user rights list
						$rightLine.attr("data-index-user-rights", this.editedUser.rights.length);
						this.editedUser.rights.push(userRightToDelete);

					}

				});

			}

		}

		// If the help mode is active
		if ($("body").attr("class").indexOf("help-mode-active") >= 0) {

			// We update the tooltips positions
			helpSequence.updateTooltipsPositions();

		}

		// We init the selec/unselect all button
		this.setSelectAllButton();
		this.setUnselectAllButton();

	}

	clearFieldNameRight () {

		// We define the selected option
		$("SELECT[name='name-right']").val("");

	}

	clearFieldCartographyRight () {

		let cartographyRightSelector;

		if (this.activeTab === "profiles") {

			cartographyRightSelector = $("#form-profiles SELECT[name='cartography-right']");

		} else {

			cartographyRightSelector = $("#form-specific-right SELECT[name='cartography-right']");

		}

		// We remove all items on the cartography select
		cartographyRightSelector.html("");

		// We add the inactive status on cartography section
		if (cartographyRightSelector.parents(".sub-section").attr("class").indexOf("inactive") < 0) {

			cartographyRightSelector.parents(".sub-section").addClass("inactive");

		}

	}

	clearFieldBranchRight () {

		let branchRightSelector;

		if (this.activeTab === "profiles") {

			branchRightSelector = $("#form-profiles SELECT[name='branch-right']");

		} else {

			branchRightSelector = $("#form-specific-right SELECT[name='branch-right']");

		}

		// We remove all items on the branch select
		branchRightSelector.html("");

		// We add the inactive status on branch section
		if (branchRightSelector.parents(".sub-section").attr("class").indexOf("inactive") < 0) {

			branchRightSelector.parents(".sub-section").addClass("inactive");

		}

	}

	clearFieldCountryRight () {

		let countryRightSelector;

		if (this.activeTab === "profiles") {

			countryRightSelector = $("#form-profiles SELECT[name='country-right']");

		} else {

			countryRightSelector = $("#form-specific-right SELECT[name='country-right']");

		}

		// We remove all items on the country select
		countryRightSelector.html("");

		// We add the inactive status on country section
		if (countryRightSelector.parents(".sub-section").attr("class").indexOf("inactive") < 0) {

			countryRightSelector.parents(".sub-section").addClass("inactive");

		}

	}

	clearFieldLegalEntityRight () {

		let legalEntityRightSelector;

		if (this.activeTab === "profiles") {

			legalEntityRightSelector = $("#form-profiles SELECT[name='legal-entity-right']");

		} else {

			legalEntityRightSelector = $("#form-specific-right SELECT[name='legal-entity-right']");

		}

		// We remove all items on the legal entity select
		legalEntityRightSelector.html("");

		// We add the inactive status on legal entity section
		if (legalEntityRightSelector.parents(".sub-section").attr("class").indexOf("inactive") < 0) {

			legalEntityRightSelector.parents(".sub-section").addClass("inactive");

		}

	}

	clearAddRightButton () {

		// We add the inactive status on add right button
		if (!$("#btn-add-right").hasClass("inactive")) {

			$("#btn-add-right").addClass("inactive");

		}

	}

	clearProfileChoice () {

		$("#form-profiles .line-custom-checkbox .item-custom-checkbox").removeClass("active");

	}

	getContextInfosFromRight (right) {

		let contextName, contextType;

		if (
			right.legal_entity_id != undefined &&
				right.legal_entity_id !== "all"
		) {

			contextType = "legal_entity";

			// We get the legal entity name
			contextName = legalEntities.getLib(right.legal_entity_id);

		} else if (
			  right.country_id != undefined &&
				right.country_id !== "all"
		) {

			contextType = "country";

			// We get the context data
			const dataContext = countries.data.filter((country) => parseInt(country.id) === parseInt(right.country_id))[0];

			// We get the context name
			contextName = dataContext[this.currentLang];

		} else if (right.branch_id != undefined) {

			contextType = "branch";

			// We get the context data
			const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(right.branch_id))[0];

			// We get the context name
			contextName = dataContext.fr;

		} else if (
			right.cartography_id != undefined &&
			right.rights.indexOf("admin_risks") >= 0
		) {

			contextType = "branch";

			const cartographyData = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

			// We get the context data
			const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(cartographyData.branch_id))[0];

			// We get the context name
			contextName = dataContext.fr;

		}

		return {
			"name": contextName,
			"type": contextType
		};

	}

	getTextErrors (error) {

		let textErrors = [];

		const errorsDictionnary = [
			{
				"errorsSubStringToFind": ["user_must_have_admin_users_right_on_branch_"],
				"errorToShow": "form-error-user-must-have-admin-users-right-to-have-admin-cartographies-right"
			},
			{
				"errorsSubStringToFind": ["access_statistics_must_be_defined_on_a_branch"],
				"errorToShow": "form-error-access_statistics_must_be_defined_on_a_branch"
			},
			{
				"errorsSubStringToFind": ["admin_cartographies_must_be_defined_on_a_branch"],
				"errorToShow": "form-error-admin_cartographies_must_be_defined_on_a_branch"
			},
			{
				"errorsSubStringToFind": ["admin_business_activities_must_be_defined_on_a_branch"],
				"errorToShow": "form-error-admin_business_activities_must_be_defined_on_a_branch"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_remove_this_admin_cartographies_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_remove_this_admin_cartographies_right"
			},
			{
				"errorsSubStringToFind": ["you_must_have_admin_users_branch_right_to_give_admin_cartographies_on_branch_"],
				"errorToShow": "form-error-you_must_have_admin_users_branch_right_to_give_admin_cartographies_on_branch"
			},
			{
				"errorsSubStringToFind": [
					"user_must_have_admin_users_right_on_branch_",
					"_to_receive_admin_cartographies_right"
				],
				"errorToShow": "form-error-user_must_have_admin_users_right_on_branch_to_receive_admin_cartographies_right"
			},
			{
				"errorsSubStringToFind": ["admin_risks_must_be_defined_on_a_cartography"],
				"errorToShow": "form-error-admin_risks_must_be_defined_on_a_cartography"
			},
			{
				"errorsSubStringToFind": ["admin_risks_must_be_on_all_legal_entities"],
				"errorToShow": "form-error-admin_risks_must_be_on_all_legal_entities"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_remove_this_admin_risk_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_admin_risk_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_give_admin_risks_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_give_admin_risks_on_it"
			},
			{
				"errorsSubStringToFind": ["admin_users_must_be_defined_on_a_cartography"],
				"errorToShow": "form-error-admin_users_must_be_defined_on_a_cartography"
			},
			{
				"errorsSubStringToFind": ["admin_users_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"],
				"errorToShow": "form-error-admin_users_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_remove_this_admin_users_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_admin_users_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_give_admin_users_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_give_admin_users_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_remove_this_admin_users_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_admin_users_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_give_admin_users_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_give_admin_users_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_remove_this_admin_users_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_remove_this_admin_users_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_give_admin_users_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_give_admin_users_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country_",
					"_and_branch_",
					"_to_remove_this_admin_users_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_admin_users_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country_",
					"_and_branch_",
					"_to_give_admin_users_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_admin_users_on_it"
			},
			{
				"errorsSubStringToFind": ["evaluate_cartography_must_be_defined_on_a_cartography"],
				"errorToShow": "form-error-evaluate_cartography_must_be_defined_on_a_cartography"
			},
			{
				"errorsSubStringToFind": ["evaluate_cartography_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"],
				"errorToShow": "form-error-evaluate_cartography_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_remove_this_evaluate_cartography_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_evaluate_cartography_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_give_evaluate_cartography_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_give_evaluate_cartography_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_remove_this_evaluate_cartography_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_evaluate_cartography_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_give_evaluate_cartography_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_give_evaluate_cartography_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_remove_this_evaluate_cartography_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_remove_this_evaluate_cartography_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_give_evaluate_cartography_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_give_evaluate_cartography_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country",
					"_and_branch_",
					"_to_remove_this_evaluate_cartography_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_evaluate_cartography_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country_",
					"_and_branch_",
					"_to_give_evaluate_cartography_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_evaluate_cartography_on_it"
			},
			{
				"errorsSubStringToFind": ["view_evaluation_must_be_defined_on_a_cartography"],
				"errorToShow": "form-error-view_evaluation_must_be_defined_on_a_cartography"
			},
			{
				"errorsSubStringToFind": ["view_evaluation_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"],
				"errorToShow": "form-error-view_evaluation_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_remove_this_view_evaluation_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_view_evaluation_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_give_view_evaluation_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity_to_give_view_evaluation_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_remove_this_view_evaluation_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_remove_this_view_evaluation_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_give_view_evaluation_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_give_view_evaluation_on_it"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country_",
					"_and_branch_",
					"_to_remove_this_view_evaluation_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_view_evaluation_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_country_",
					"_and_branch_",
					"_to_give_view_evaluation_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_view_evaluation_on_it"
			},
			{
				"errorsSubStringToFind": ["manage_actions_plan_must_be_defined_on_a_cartography"],
				"errorToShow": "form-error-manage_actions_plan_must_be_defined_on_a_cartography"
			},
			{
				"errorsSubStringToFind": ["manage_actions_plan_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"],
				"errorToShow": "form-error-manage_actions_plan_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_legal_entity_",
					"_to_give_manage_actions_plan_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_legal_entity"
			},
			{
				"errorsSubStringToFind": ["you_must_have_admin_users_right_on_branch_"],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch"
			},
			{
				"errorsSubStringToFind": ["you_must_have_admin_users_right_on_country_"],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_country"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_give_manage_actions_plan_on_it"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_give_manage_actions_plan_on_it"
			},
			{
				"errorsSubStringToFind": ["user_is_rpo_for_at_least_one_risk_in_the_cartography"],
				"errorToShow": "form-error-user-is-rpo"
			},
			{
				"errorsSubStringToFind": ["user_is_task_handler_on_risk"],
				"errorToShow": "form-error-user-is-task-handler"
			},
			{
				"errorsSubStringToFind": ["user_must_have_at_least_one_visualisation_or_evaluation_right"],
				"errorToShow": "form-error-required-right"
			},
			{
				"errorsSubStringToFind": ["user_must_have_admin_users_right_on_branch_"],
				"errorToShow": "form-error-user-must-have-admin-users-right-to-have-admin-cartographies-right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_branch_",
					"_to_remove_this_admin_cartographies_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_branch_to_remove_this_admin_cartographies_right"
			},
			{
				"errorsSubStringToFind": [
					"you_must_have_admin_users_right_on_cartography_",
					"_to_remove_this_manage_actions_plan_right"
				],
				"errorToShow": "form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_manage_actions_plan_right"
			}
		];

		errorsDictionnary.forEach((entryToTest) => {

			let subStringsFound;
			entryToTest.errorsSubStringToFind.forEach((subStringToFind) => {

				if (error.responseText.indexOf(subStringToFind) >= 0) {

					subStringsFound = true;

				} else {

					subStringsFound = false;

				}

			});

			if (subStringsFound === true) {

				textErrors = [];
				textErrors.push($.i18n(entryToTest.errorToShow));

			}

		});

		return textErrors;

	}

	getClassIconRight (right) {

		let classIconRight;

		if (right === "view_evaluation") {

			classIconRight = "fa-eye";

		} else if (right === "evaluate_cartography") {

			classIconRight = "fa-star";

		} else if (right === "admin_users") {

			classIconRight = "fa-users-cog";

		} else if (right === "communicate") {

			classIconRight = "fa-comments";

		} else if (right === "manage_actions_plan") {

			classIconRight = "fa-clipboard-check";

		} else if (right === "view_actions_plan") {

			classIconRight = "fa-mask";

		} else if (right === "admin_risks") {

			classIconRight = "fa-chalkboard-teacher";

		} else if (right === "access_statistics") {

			classIconRight = "fa-chart-pie";

		} else if (right === "admin_cartographies") {

			classIconRight = "fa-cogs";

		} else if (right === "admin_business_activities") {

			classIconRight = "fa-building";

		} else {

			classIconRight = "fa-exclamation-triangle";

		}


		return classIconRight;

	}

	getLibRight (right) {

		let libRight;

		if (right === "view_evaluation") {

			libRight = $.i18n("form-label-field-right-view");

		} else if (right === "evaluate_cartography") {

			libRight = $.i18n("form-label-field-right-evaluate");

		} else if (right === "admin_users") {

			libRight = $.i18n("form-label-field-right-admin-users");

		} else if (right === "communicate") {

			libRight = $.i18n("form-label-field-right-communicate");

		} else if (right === "manage_actions_plan") {

			libRight = $.i18n("form-label-field-right-manage-action-plans");

		} else if (right === "view_actions_plan") {

			libRight = $.i18n("form-label-field-right-view-action-plans");

		} else if (right === "admin_risks") {

			libRight = $.i18n("form-label-field-right-manage-cartography");

		} else if (right === "access_statistics") {

			libRight = $.i18n("form-label-field-right-view-statistics");

		} else if (right === "admin_cartographies") {

			libRight = $.i18n("form-label-field-right-admin-cartographies");

		} else if (right === "admin_business_activities") {

			libRight = $.i18n("form-label-field-right-admin-business-activities");

		} else {

			libRight = $.i18n("form-label-field-right-other");

		}

		return libRight;

	}

	getHelpRightText (right) {

		// We get the edited user firstname
		let editedUserFirstname = "";
		if (window.location.pathname.indexOf("/user/create") >= 0) {

			editedUserFirstname = $.i18n("label-the-user");

		} else {

			editedUserFirstname = `<strong>${this.editedUser.firstname}</strong>`;

		}

		// We define the help right from the current right
		let helpText;

		if (right === "view_evaluation") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-view-evaluation")}`;

		} else if (right === "evaluate_cartography") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-evaluate-cartography")}`;

		} else if (right === "admin_users") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-admin-users")}`;

		} else if (right === "communicate") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-communicate")}`;

		} else if (right === "manage_actions_plan") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-manage-actions-plan")}`;

		} else if (right === "view_actions_plan") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-view-actions-plan")}`;

		} else if (right === "admin_risks") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-admin-risks")}`;

		} else if (right === "access_statistics") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-access-statistics")}`;

		} else if (right === "admin_cartographies") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-admin-cartographies")}`;

		} else if (right === "admin_business_activities") {

			helpText = `${editedUserFirstname} ${$.i18n("help-text-right-admin-business-activities")}`;

		} else {

			helpText = "";

		}

		return helpText;

	}

}
export default UserRightsForm;
