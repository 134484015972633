import html from "./cartography_theme_field.html";
import {slugify} from "Core/helpers.js";

class CartographyThemeField {

	init (containerSelector, currentLang) {

		// We define the current language
		this.currentLang = currentLang;

		// We add the component html to the container
		$(containerSelector).append(html);

		// We define the themes
		this.dataCartographyThemes = [];

		// We init the events
		this.initEvents();

	}

	initEvents () {

		// We listen click on the add theme button
		$("#btn-add-theme").on("click", this.handleClickOnAddThemeButton.bind(this));

		// We listen change on the theme fields
		$("#section_cartography_themes .text-field").on("input", this.handleChangeOnThemeFields.bind(this));

		// We listen click on the delete theme button
		$("#section_cartography_themes .tab-result .label-result .btn-delete-theme").on("click", this.handleClickOnDeleteThemeButton.bind(this));

		// We listen click on the theme translate button
		$("#cartography_themes .btn-translate-field").on("click", this.handleClickOnThemeTranslateButton.bind(this));

	}

	handleClickOnAddThemeButton (e) {

		if (!$("#btn-add-theme").hasClass("inactive")) {

			// We get new theme values
			const themeValueFr = $("#section_cartography_themes input[name='theme_fr']").val();
			const themeValueEn = $("#section_cartography_themes input[name='theme_en']").val();
			const themeSlug = slugify(themeValueEn);

			// We add the theme on data cartography themes
			this.dataCartographyThemes.push({
				"lib": {
					"en": themeValueEn,
					"fr": themeValueFr
				},
				"slug": themeSlug
			});

			// We get the new theme lib in the current language
			const themeLibCurrentLang = $(`#section_cartography_themes input[name='theme_${this.currentLang}']`).val();

			// We get the theme lib in the other language
			let themeLibOtherLang;
			if (this.currentLang === "fr") {

				themeLibOtherLang = $("#section_cartography_themes input[name='theme_en']").val();

			} else if (this.currentLang === "en") {

				themeLibOtherLang = $("#section_cartography_themes input[name='theme_fr']").val();

			}

			// We define the html of the label of theme
			const htmlLabelTheme = `<div class="label-result" data-index-cartography-theme="${this.dataCartographyThemes.length - 1}"><div class="info-context"><span class="name-context">${themeLibCurrentLang}</span><span class="name-sub-context">${themeLibOtherLang}</span></div><div class="other-info"><div class="btn-delete-theme"><span class="icon fas fa-trash-alt"></span></div></div></div>`;

			// We show the label of the new theme
			$("#section_cartography_themes .tab-result").prepend(htmlLabelTheme);

			// We init the events
			this.initEvents();

			// We clear the add theme form
			this.clearAddThemeForm();

			// We remove the theme field error
			$("#cartography_themes .tab-result").removeClass("tab-result-error");
			$("#cartography_themes .msg-error").remove();

		}


	}

	clearAddThemeForm () {

		// We clear the fields
		$("#section_cartography_themes input[name='theme_fr']").val("");
		$("#section_cartography_themes input[name='theme_en']").val("");

		// We clear the add theme button
		$("#btn-add-theme").addClass("inactive");

	}

	handleChangeOnThemeFields (e) {

		if ($("#section_cartography_themes input[name='theme_fr']").val() !== "" && $("#section_cartography_themes input[name='theme_en']").val() !== "") {

			// We active the add button
			$("#btn-add-theme").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-theme").addClass("inactive");

		}


	}

	handleClickOnDeleteThemeButton (e) {

		// We get the index of the current theme
		const curIndexTheme = $(e.currentTarget).parents(".label-result").attr("data-index-cartography-theme");

		// We delete the theme in the array of list of themes
		this.dataCartographyThemes.splice(curIndexTheme, 1);

		// We delete the label of the theme
		$(e.currentTarget).parents(".label-result").remove();

	}

	handleClickOnThemeTranslateButton (e) {

		// We get the current button id
		const buttonId = $(e.currentTarget).attr("id");

		// We get the current container
		const buttonContainer = $(e.currentTarget).parents(".wrap-field");

		// We get the current text field value
		const textFieldValue = buttonContainer.find(".text-field").val();

		if (textFieldValue === "") {

			// We check if the theme is translate and activate the theme creation button
			const refreshFieldToActivateThemeCreationButton = setInterval(() => {

				const newTextFieldValue = buttonContainer.find(".text-field").val();

				if (
				  buttonContainer.attr("class").indexOf("loading") < 0 &&
					newTextFieldValue !== undefined &&
					newTextFieldValue !== null &&
					newTextFieldValue !== ""
				) {

					// We active the add button
					$("#btn-add-theme").removeClass("inactive");

					clearInterval(refreshFieldToActivateThemeCreationButton);

				}

			}, 500);


		}


	}

	autoFillCartographyThemes (editedCartography) {

		if (editedCartography != undefined && editedCartography.themes !== undefined && editedCartography.themes !== null && editedCartography.themes.length > 0) {

			// We define cartography themes
			this.dataCartographyThemes = editedCartography.themes;

			editedCartography.themes.forEach((theme, index) => {

				// We get the theme lib in the current language
				const themeLibCurrentLang = theme.lib[`${this.currentLang}`];

				// We get the theme lib in the other language
				let themeLibOtherLang;
				if (this.currentLang === "fr") {

					themeLibOtherLang = theme.lib.en;

				} else if (this.currentLang === "en") {

					themeLibOtherLang = theme.lib.fr;

				}

				// We define the html of the label of theme
				const htmlLabelTheme = `
					<div class="label-result" data-index-cartography-theme="${index}">
						<div class="info-context">
							<span class="name-context">${themeLibCurrentLang}</span>
							<span class="name-sub-context">${themeLibOtherLang}</span>
						</div>
						<div class="other-info">
							<div class="btn-delete-theme">
								<span class="icon fas fa-trash-alt"></span>
							</div>
						</div>
					</div>`;

		    // We show the label of theme
				$("#section_cartography_themes .tab-result").prepend(htmlLabelTheme);

				// We init the events
				this.initEvents();

			});

		}

	}

	getCartographyThemes () {

		return this.dataCartographyThemes;

	}

}
export default new CartographyThemeField();
