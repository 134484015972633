import router from "Core/router.js";
import api from "Core/Api.js";
import Datapoint from "Core/Datapoint.js";

class Users extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaningForThisFields = [
			"id",
			"created_at",
			"updated_at",
			"email_confirmation_hash",
			"status",
			"status_updated_at",
			"rights",
			"legal_entity_id",
			"notifications_by_mail",
			"valid_for"
		];

	}

	getData (forceReload = false) {

		return new Promise((resolve, reject) => {

			if (this.data != undefined && !forceReload) {

				resolve(true);
				return;

			}

			api.call({
				"method": "GET",
				"url": "/users"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve(true);

				}).
				fail((error, textStatus) => {

					if (error.status == 403) {

						resolve(false); // We resolve because right trouble should not crash

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	updateOne (userToUpdate) {

		return new Promise((resolve, reject) => {

			// We remove un-updatable data
			delete userToUpdate.legalEntitiesUserCanAdminUserFor;
			delete userToUpdate.legalEntitiesUserCanEvaluate;

			api.call({
				"method": "POST",
				"url": "/user/update",
				"data": userToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	createOne (userToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/create",
				"data": userToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	setAdminRiskForCartography (usersIds, cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/user/rights/set-new-admin-risk-for-cartography",
				"data": {
					"users_ids": usersIds,
					"cartography_id": cartographyId
				}
			}).
				done(resolve).
				fail(reject);

		});


	}

	find (idToFind) {

		return this.data != undefined ? this.data.find((user) => user.id == idToFind) : null;

	}

	deleteOne (userToDelete) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/user/delete/${userToDelete}`
			}).
				done(resolve).
				fail(reject);

		});

	}

}
export default new Users();
