import Page from "Pages/Page.js";
import html from "./login.html";
import router from "Core/router.js";
import user from "Datapoints/User.js";
import cartographies from "Datapoints/Cartographies.js";
import menu from "Components/Menu/Menu.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import api from "Core/Api.js";
import footer from "Components/Footer/Footer.js";
import LoginLayout from "Layouts/Login/LoginLayout.js";
import debug from "Core/Debug.js";
import "./Login.scss";
import "./LoginResponsive.scss";
import Popin from "Components/Popin/Popin.js";
import licencingVerification from "Datapoints/LicencingVerification.js";

// Import i18n
import "Core/assets/lib/i18n/CLDRPluralRuleParser.js";
import "Core/assets/lib/i18n/jquery.i18n.js";
import "Core/assets/lib/i18n/jquery.i18n.messagestore.js";
import "Core/assets/lib/i18n/jquery.i18n.fallbacks.js";
import "Core/assets/lib/i18n/jquery.i18n.language.js";
import "Core/assets/lib/i18n/jquery.i18n.parser.js";
import "Core/assets/lib/i18n/jquery.i18n.emitter.js";
import "Core/assets/lib/i18n/jquery.i18n.emitter.bidi.js";
import translationFrCommon from "Core/assets/lib/i18n/languages/fr_common.json";
import translationFrEnv from "Core/assets/lib/i18n/languages/fr_env.json";
import translationEnCommon from "Core/assets/lib/i18n/languages/en_common.json";
import translationEnEnv from "Core/assets/lib/i18n/languages/en_env.json";
import flagFr from "Core/assets/icons/flags/fr.png";
import flagEn from "Core/assets/icons/flags/en.png";

class Login extends Page {

	constructor () {

		let routes = ["login"];

		super(routes, LoginLayout, "Risk Management Platform");

	}

	render () {

		this.parseAndRender("page", html);
		footer.show();

		this.initEvents();

		// We init the switch language events
		this.initEventsLanguage();

		// We show the message for user auth by SSO but still ends on login (unknown or not allowed users to the application)
		if (user.hasBeenAuthBySSO && !user.isAllowedOnRMP) {

			this.showError(`${$.i18n("login-error-global")}`);

		}

		// We init the forgotten password popin
		this.initForgottenPasswordPopin();

		// If the licencing verification is in error, we show it
		if (licencingVerification.licenceHasBeenCheckedButIsNotValid) {

			this.showError(`${$.i18n("error-licence-is-not-valid")}`);

		}

	}

	initEvents () {

		// We remove the help tooltip
		helpSequence.hideTooltip();

		// We hide the help mode overlay
		helpSequence.hideHelpMode();

		// We remove the help informations saved on the local storage
		helpSequence.removeLocalStorageInfos();

		// We add the first login information on local storage
		const isCurrentUserFirstLogin = localStorage.getItem("user-isFirstLogin");
		if (isCurrentUserFirstLogin !== false && isCurrentUserFirstLogin !== "false") {

			localStorage.setItem("user-isFirstLogin", true);

		}

		// If the basic login mode is active
		if (process.env.BASIC_LOGIN_ACTIVE === true || process.env.BASIC_LOGIN_ACTIVE === "true") {

			// We show elements
			$("#sidebar").addClass("basic-login-active");

		}

		// We show the digital pass button
		if (process.env.SHOW_DIGITAL_PASS_BUTTON === true || process.env.SHOW_DIGITAL_PASS_BUTTON === "true") {

			$(".login-form .line-submit").addClass("show-digital-pass");

		}

		// We show the email field
		if (
			process.env.SHOW_IGG !== undefined &&
			(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
		) {

			// We hide the igg field
			$("#line-form-login").remove();

			// We show the email field
			$("#line-form-email").removeClass("inactive");

		} else {

			// We hide the email field
			$("#line-form-email").remove();

		}

		// We listen the click on the submit form button
		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We init the contact button
		this.initContactButton();

	}

	initContactButton () {

		// We define the link of the contact button
		let linkContactButton;
		if (process.env.CONTACT_EMAIL.indexOf("@") >= 0) {

			linkContactButton = `mailto:${process.env.CONTACT_EMAIL}`;

		} else {

			linkContactButton = process.env.CONTACT_EMAIL;

		}

		// We init the href of the contact button
		$(".line-help .btn-help a").attr("href", linkContactButton);

	}

	initForgottenPasswordPopin () {

		$("#forgotten-password-popin").remove();

		this.forgottenPasswordPopin = new Popin("forgotten-password-popin", `${$.i18n("popin-forgotten-password-title")}`, [
			{
				"title": `${$.i18n("button-ok")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleForgottenPasswordConfirm.bind(this)
			}
		]);
		this.forgottenPasswordPopin.appendIn("#page");

		$("#btn-forgotten-password").on("click", (event) => {

			this.forgottenPasswordPopin.show();

		});

		$("#forgotten-password-popin .overlay-popin").on("click", (event) => {

			this.forgottenPasswordPopin.hide();

		});

	}

	handleForgottenPasswordConfirm () {

		this.forgottenPasswordPopin.hide();

	}

	initEventsLanguage () {

		let curLang;
		if (navigator.language !== undefined && navigator.language === "fr") {

			curLang = "fr";

		} else {

			curLang = "en";

		}

		// We init the default language
		$.i18n({
			"locale": curLang
		});

		// We load the translation files for common translations
		$.i18n().load({
			"fr": translationFrCommon, // Common contents for French
			"en": translationEnCommon // Common contents for English
		});
		// We load the translation files for environnement specific translations
		$.i18n().load({
			"fr": translationFrEnv, // Environnement specific contents for French
			"en": translationEnEnv // Environnement specific contents for English
		});

		// We init the site translation
		$("html").i18n();

		// We init the lang menu
		$("#lang-menu .current-lang").attr("data-lang", curLang);

		if (curLang === "fr") {

			$("#lang-menu .current-lang .flag img").attr("src", flagFr);
			$("#lang-menu .other-lang .flag img").attr("src", flagEn);
			$("#lang-menu .other-lang").attr("data-lang", "en");

		} else if (curLang === "en") {

			$("#lang-menu .current-lang .flag img").attr("src", flagEn);
			$("#lang-menu .other-lang .flag img").attr("src", flagFr);
			$("#lang-menu .other-lang").attr("data-lang", "fr");

		}

		// We listen click on the other lang
		$("#lang-menu .other-lang").on("click", this.handleClickOnLang.bind(this));

	}

	handleClickOnLang (e) {

		// We switch the flag on the lang menu
		const currentLangUrlFlag = $("#lang-menu .current-lang").find(".flag img").attr("src");
		const selectedLangUrlFlag = $(e.currentTarget).find(".flag img").attr("src");
		$("#lang-menu .current-lang").find(".flag img").attr("src", selectedLangUrlFlag);
		$("#lang-menu .other-lang").find(".flag img").attr("src", currentLangUrlFlag);

		// We switch the data-lang on the lang menu
		const currentCodeLang = $("#lang-menu .current-lang").attr("data-lang");
		const selectedCodeLang = $(e.currentTarget).attr("data-lang");
		$("#lang-menu .current-lang").attr("data-lang", selectedCodeLang);
		$("#lang-menu .other-lang").attr("data-lang", currentCodeLang);

		// We change the site language
		$.i18n({
			"locale": selectedCodeLang
		});

		// We init the site translation
		$("html").i18n();

		// We init the forgotten password popin
		this.initForgottenPasswordPopin();

	}

	handleSubmit (e) {

		e.preventDefault();

		this.showError("");

		if (
			process.env.SHOW_IGG !== undefined &&
			(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
		) {

			user.loginWithEmail($("input[name='email']").val(), $("input[name='password']").val()).
				then(this.loginSuccess.bind(this)).
				catch(this.loginFail.bind(this));

		} else {

			user.login($("input[name='igg']").val(), $("input[name='password']").val()).
				then(this.loginSuccess.bind(this)).
				catch(this.loginFail.bind(this));

		}

	}

	loginSuccess (data) {

		// We force the request to get the new data for when we were browsing without login
		user.getData({"forceRequest": true}).then(() => {

			cartographies.getData({"forceRequest": true}).then(() => {

				// If current user has right on multiple cartographies
				if (cartographies.data !== undefined && cartographies.data.length > 1) {

					// We redirect the user to the dashboard with global cartographies view
					user.redirectAfterLogin("/dashboard/cartographies/all");

				} else {

					// We redirect the user to the dashboard with grid view
					user.redirectAfterLogin("/dashboard-grid");

				}

			});

		});

	}

	loginFail (jqXHR, textStatus) {

		if (jqXHR.status == 404) {

			this.showError(`${$.i18n("login-error-global")}`);

		} else if (jqXHR.status == 422) {

			if (jqXHR.responseJSON.errors.igg.indexOf("user_not_valid") > -1) {

				this.showError(`${$.i18n("login-error-invalid-account")}`);

			}

			if (jqXHR.responseJSON.errors.igg.indexOf("user_in_an_out_of_scope_legal_entity") > -1) {

				this.showError(`${$.i18n("error-login-user-in-an-out-of-scope-legal-entity")}`);

			}

		} else {

			debug.e(`Error while login - ${jqXHR.status}`);
			debug.e(textStatus);

		}

	}

	showError (errorToShow) {

		$(".error").html(errorToShow);

	}

}
export default new Login();
