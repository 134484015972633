import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./list.html";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import cartographies from "Datapoints/Cartographies.js";
import news from "Datapoints/News.js";
import legalEntities from "Datapoints/LegalEntities.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import "../../Admin.scss";
import "../../AdminResponsive.scss";

class NewsList extends Page {

	constructor () {

		let routes = ["/admin/news"];

		let datapointsToLoad = [
			users,
			news,
			branches,
			countries,
			cartographies,
			legalEntities
		];

		super(routes, AuthentifiedLayout, "Liste des news", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canCommunicate.bind(user)
		];

	}

	render () {

		return new Promise((resolve, reject) => {

			// We get the current language
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);

			this.idNewsToDelete = null;

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.initListNews());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initListNews () {

		if (news.data !== undefined && news.data.length > 0) {

			// We clear container
			$("#news_list").html("");

			// We loop on news
			for (let iNews = 0; iNews < news.data.length; iNews++) {

				const curNews = news.data[iNews];
				const curNewsId = curNews.id;
				const curNewsTitle = curNews[`title_${$.i18n().locale}`];

				// TODO : récupérer le nom de la branche ou du pays ou de l'EJ
				let newsBranchName;
				let newsCountryName;
				let newsLegalEntityName;

				if (curNews.branch_id !== undefined && curNews.branch_id !== null) {

					newsBranchName = branches.find(curNews.branch_id)[`${$.i18n().locale}`];

				}

				if (curNews.country_id !== undefined && curNews.country_id !== null) {

					newsCountryName = countries.find(curNews.country_id)[`${$.i18n().locale}`];

				}

				if (curNews.legal_entity_id !== undefined && curNews.legal_entity_id !== null) {

					newsLegalEntityName = legalEntities.find(curNews.legal_entity_id).name;

				}

				let htmlNewsBranch = "";
				let htmlNewsCountry = "";
				let htmlNewsLegalEntity = "";

				if (newsBranchName !== undefined) {

					htmlNewsBranch = `<div class='item-branch'><div class="icon fas fa-code-branch"></div><div class="admin-branch-name">${newsBranchName}</div></div>`;

				}

				if (newsCountryName !== undefined) {

					htmlNewsCountry = `<div class='item-branch'><div class="icon fas fa-flag"></div><div class="admin-branch-name">${newsCountryName}</div></div>`;

				}

				if (newsLegalEntityName !== undefined) {

					htmlNewsLegalEntity = `<div class='item-branch'><div class="icon fas fa-laptop-house"></div><div class="admin-branch-name">${newsLegalEntityName}</div></div>`;

				}

				// We add the news to the list
				$("#news_list").append(`<li><div class="item-line" data-news-id="${curNewsId}"><div class='news-title'><div class='text'>${curNewsTitle}</div></div>${htmlNewsBranch}${htmlNewsCountry}${htmlNewsLegalEntity}<div class='btn-edit'><i class='icon fas fa-pencil-alt'></i></div><div class='btn-delete'><i class='icon fas fa-trash-alt'></i></div></div></li>`);

			}

			// We listen the clic on the delete button
			$("#news_list .item-line .btn-delete").on("click", this.handleClickOnDeleteNewsButton.bind(this));

			// We listen the clic on the edit button
			$("#news_list .item-line").on("click", this.handleClickOnEditNewsButton.bind(this));

		} else {

			// We define the message
			const htmlMessage = `<div class="msg-no-item"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-no-news">${$.i18n("msg-no-news")}</span></div></div>`;

			// We add the message to the container
			$("#news_list").html(htmlMessage);

		}

		// We listen click on create button
		$(".btn-create").on("click", this.handleClickOnCreateButton.bind(this));

	}

	handleClickOnCreateButton () {

		router.navigate("/admin/news/create");

	}

	handleClickOnEditNewsButton (e) {

		const newsId = $(e.currentTarget).attr("data-news-id");

		router.navigate(`/admin/news/edit/${newsId}`);

	}

	handleClickOnDeleteNewsButton (e) {

		// We prevent the event to bubble to line event
		e.stopPropagation();

		const newsId = $(e.currentTarget).parents(".item-line").attr("data-news-id");

		this.initDeleteNews(newsId);

	}

	initDeleteNews (newsId) {

		this.idNewsToDelete = parseInt(newsId);

		const curNews = news.find(newsId);

		let curNewsTitle;
		if (this.currentLang === "fr" && curNews.title_fr !== undefined && curNews.title_fr !== null && curNews.title_fr !== "") {

			curNewsTitle = curNews.title_fr;

		} else if (this.currentLang === "fr" && (curNews.title_fr === undefined || curNews.title_fr === null || curNews.title_fr === "")) {

			curNewsTitle = curNews.title_en;

		} else if (this.currentLang === "en" && curNews.title_en !== undefined && curNews.title_en !== null && curNews.title_en !== "") {

			curNewsTitle = curNews.title_en;

		} else if (this.currentLang === "en" && (curNews.title_en === undefined || curNews.title_en === null || curNews.title_en === "")) {

			curNewsTitle = curNews.title_fr;

		}

		// Delete popin
		this.deleteNewsPopin = new Popin("delete-news-popin", `${$.i18n("popin-delete-news-confirmation-title")} ${curNewsTitle} ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteNewsCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteNewsConfirm.bind(this)
			}
		]);
		$(".popin").remove();
		this.deleteNewsPopin.appendIn("#page");

		this.deleteNewsPopin.show();

	}

	handleDeleteNewsCancel () {

		this.deleteNewsPopin.hide();

	}

	handleDeleteNewsConfirm () {

		news.deleteOne(this.idNewsToDelete).then(() => {

			this.deleteNewsPopin.hide();

			news.getData({"forceRequest": true}).then(() => {

				this.initListNews();

			});

		}).catch((error) => {

		});

	}

}
export default new NewsList();
