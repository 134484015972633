import router from "Core/router.js";
import Page from "Pages/Page.js";
import debug from "Core/Debug.js";
import menu from "Components/Menu/Menu.js";
import html from "./notifications.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import users from "Datapoints/Users.js";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import news from "Datapoints/News.js";
import "./Notifications.scss";
import "./NotificationsResponsive.scss";

class Notifications extends Page {

	constructor () {

		let routes = ["/notifications"];

		let datapointsToLoad = [
			context,
			cartographies,
			legalEntities,
			profitCenters,
			users,
			businessActivities,
			news
		];

		super(routes, AuthentifiedLayout, "Notifications", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render () {

		return new Promise((resolve, reject) => {

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);

			this.notifications = [];

			// We define the sorting by default
			this.sorting = "dateDesc";

			// We get the current cartography id
			this.curCartographyId = cartographies.getCurrentCartographyId();

			// We load the current legal entity
			this.curLegalEntityId = context.getCurrentLegalEntityId();

			// We define the html for the mark as read button
			this.htmlMarkAsReadButton = "<div class=\"btn-mark-notification btn-mark-read\" data-i18n=\"[title]title-btn-mark-as-read\"><span class=\"icon fas fa-envelope\"></span><span class=\"icon-over fas fa-envelope-open\"></span></div>";

			// We define the html for the mark as unread button
			this.htmlMarkAsUnreadButton = "<div class=\"btn-mark-notification btn-mark-unread\" data-i18n=\"[title]title-btn-mark-as-unread\"><span class=\"icon fas fa-envelope-open\"></span><span class=\"icon-over fas fa-envelope\"></span></div>";

			// We init global events
			this.initEvents();

			// We load the notifications
			this.loadNotifications();

			menu.show().then(resolve);

		});

	}

	initEvents () {

		if (process.env.SEND_EMAIL_NOTIFICATIONS === true || process.env.SEND_EMAIL_NOTIFICATIONS === "true") {

			// We listen click on the notifications settings button
			$("#btn-notifications-settings").on("click", this.handleClickOnNotificationsSettingsButton.bind(this));

		} else {

			// We remove the notifications settings button
			$("#btn-notifications-settings").remove();

		}

		// We listen to click on the button to sort by type
		$("#btn-sort-type").on("click", () => {

			this.handleClickOnBtnSortByType(this.notifications);

		});

		// We listen to click on the button to sort by date
		$("#btn-sort-date").on("click", () => {

			this.handleClickOnBtnSortByDate(this.notifications);

		});

	}

	handleClickOnNotificationsSettingsButton () {

		// We reload current data user
		user.getData().then(() => {

			// We navigate to the user profile page
			router.navigate("/user/show");

		}).catch(debug.e);

	}

	loadNotifications () {

		user.getNotifications({"forceRequest": true}).then((userNotifications) => {

			this.notifications = userNotifications;

			// We remove the content
			$(".list-notifications").html("");

			// We remove the active class on sort button
			$(".line-buttons-sort .box-sort").removeClass("active");

			// If there is at least one notification
			if (userNotifications !== undefined && userNotifications.length > 0) {

				// We load the risks for user's cartographies so we can access risks datas whatever cartographies is currently active on dashboard
				const userCartographies = cartographies.data.map((cartographyData) => cartographyData.id);
				risks.getRisksForMultipleCartographies(user, userCartographies).then(() => {

					if (this.sorting === "typeAsc") {

						this.sortNotificationsByTypeAsc(userNotifications);

					} else if (this.sorting === "dateDesc") {

						this.sortNotificationsByDateDesc(userNotifications);

					} else {

						// We load unread notifications
						this.loadUnreadNotifications(userNotifications);

						// We load read notifications
						this.loadReadNotifications(userNotifications);

					}

					// If we are more than 1 notification
					if (userNotifications.length > 1) {

						// We show the sort button
						$(".line-buttons-sort .box-sort").addClass("active");

					}

				});

			} else { // If there is no notification to show

				// We define the message to show
				const htmlMessage = `<div class="msg-no-notification"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-no-notification">${$.i18n("msg-no-notification")}</span></div></div>`;

				// We define the message to show
				$(".list-notifications").html(htmlMessage);

				// We remove the active class on sort button
				$(".line-buttons-sort .box-sort").removeClass("active");

			}

		});

	}

	loadUnreadNotifications (userNotifications) {

		// We get notifications not read
		const notificationsUnread = userNotifications.filter((notification) => notification.read_at === null);

		// We loop on unread notifications
		for (let iNotificationUnread = 0; iNotificationUnread < notificationsUnread.length; iNotificationUnread++) {

			if (iNotificationUnread === 0) {

				const htmlSectionUnread = `<div class='section-notifications' id='section-unread'><div class='title-section'><span class='icon fas fa-circle'></span><span class='text' data-i18n='title-section-unread-notifications'>${$.i18n("title-section-unread-notifications")}</span></div><div class='content-section'></div></div>`;

				// We add the section container
				$(".list-notifications").append(htmlSectionUnread);

			}

			// We define notification vars
			const curNotification = notificationsUnread[iNotificationUnread];

			// We show the notification
			this.showNotificationItem(curNotification);

		}

	}

	loadReadNotifications (userNotifications) {

		// We get notifications already read
		const notificationsRead = userNotifications.filter((notification) => notification.read_at !== null);

		// We loop on notifications already read
		for (let iNotificationRead = 0; iNotificationRead < notificationsRead.length; iNotificationRead++) {

			if (iNotificationRead === 0) {

				const htmlSectionRead = `<div class='section-notifications' id='section-read'><div class='title-section'><span class='icon fas fa-check-double'></span><span class='text' data-i18n='title-section-read-notifications'>${$.i18n("title-section-read-notifications")}</span></div><div class='content-section'></div></div>`;

				// We add the section container
				$(".list-notifications").append(htmlSectionRead);

			}

			// We define notification vars
			const curNotification = notificationsRead[iNotificationRead];

			// We show the notification
			this.showNotificationItem(curNotification);

		}

	}

	handleClickOnBtnSortByType (userNotifications) {

		if (this.sorting !== "typeAsc") {

			this.sortNotificationsByTypeAsc(userNotifications);

		}

	}

	handleClickOnBtnSortByDate (userNotifications) {

		if (this.sorting !== "dateDesc") {

			this.sortNotificationsByDateDesc(userNotifications);

		}

	}

	sortNotificationsByTypeAsc (userNotifications) {

		const userNotificationsSorting = userNotifications.sort((a, b) => a.type.localeCompare(b.type));

		// We remove the content
		$(".list-notifications").html("");

		// We load unread notifications
		this.loadUnreadNotifications(userNotificationsSorting);

		// We load read notifications
		this.loadReadNotifications(userNotificationsSorting);

		// We remove the active class on the date button
		$("#btn-sort-date").removeClass("active");

		// We add the active class on the type button
		$("#btn-sort-type").addClass("active");

		// We update the sorting status
		this.sorting = "typeAsc";

	}

	sortNotificationsByDateDesc (userNotifications) {

		const userNotificationsSorting = userNotifications.sort((a, b) => b.created_at.localeCompare(a.created_at));

		// We remove the content
		$(".list-notifications").html("");

		// We load unread notifications
		this.loadUnreadNotifications(userNotificationsSorting);

		// We load read notifications
		this.loadReadNotifications(userNotificationsSorting);

		// We remove the active class on the type button
		$("#btn-sort-type").removeClass("active");

		// We add the active class on the date button
		$("#btn-sort-date").addClass("active");

		// We update the sorting status
		this.sorting = "dateDesc";

	}

	handleClickOnMarkAsReadButton (e) {

		// We get the current notification id
		const curNotificationId = $(e.currentTarget).parents(".item-notification").attr("id");

		// We mark notification as read
		this.markNotificationAsRead(curNotificationId);


	}

	handleClickOnMarkAsUnreadButton (e) {

		// We get the current notification id
		const curNotificationId = $(e.currentTarget).parents(".item-notification").attr("id");

		// We mark notification as unread
		this.markNotificationAsUnread(curNotificationId);

	}

	markNotificationAsRead (notificationId) {

		// We create an empty array to save ids notifications to mark as read
		const notificationsIdsToMarkAsRead = [""];

		// We push the notification id to the array
		notificationsIdsToMarkAsRead.push(notificationId);

		// We set mark the notifications as read
		user.setNotificationsAsRead({"notifications_ids_to_mark_as_read": notificationsIdsToMarkAsRead}).then(() => {

			user.getData().then(() => {

				// We reload the notifications
				this.loadNotifications();

			}).catch(debug.e);

		}).catch((error) => {

			debug.e(error);

		});


	}

	markNotificationAsUnread (notificationId) {

		// We create an empty array to save ids notifications to mark as unread
		const notificationsIdsToMarkAsUnread = [""];

		// We push the notification id to the array
		notificationsIdsToMarkAsUnread.push(notificationId);

		// We set mark the notifications as unread
		user.setNotificationsAsUnread({"notifications_ids_to_mark_as_unread": notificationsIdsToMarkAsUnread}).then(() => {

			user.getData().then(() => {

				// We reload the notifications
				this.loadNotifications();

			}).catch(debug.e);

		}).catch((error) => {

			debug.e(error);

		});


	}

	showNotificationItem (notification) {

		// We define notification date vars
		const curNotificationDay = `0${new Date(notification.created_at).getDate()}`.slice(-2);
		const curNotificationMonth = `0${new Date(notification.created_at).getMonth() + 1}`.slice(-2);
		const curNotificationYear = new Date(notification.created_at).getFullYear();
		const curNotificationFullDate = `${curNotificationDay}.${curNotificationMonth}.${curNotificationYear}`;

		// We define the template to show for each notification
		if (notification.type.indexOf("NewRiskSuggestion") >= 0) {

			this.showNewRiskSuggestionNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("RiskDeleted") >= 0) {

			this.showRiskDeletedNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("RiskValidated") >= 0) {

			this.showRiskValidatedNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("NewEvaluationInRed") >= 0) {

			this.showNewEvaluationInRedNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("PersistentBadEvaluation") >= 0) {

			this.showPersistentBadEvaluationNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("NewRiskOnEvaluatedCartography") >= 0) {

			this.showNewRiskOnEvaluatedCartographyNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("NewsCreated") >= 0) {

			this.showNewsCreatedNotification(notification, curNotificationFullDate);

		} else if (notification.type.indexOf("NewActionOnYourActionsPlan") >= 0) {

			this.showNewActionOnYourActionsPlanNotification(notification, curNotificationFullDate);

		}

	}

	showNewRiskSuggestionNotification (notification, date) {

		// We get the suggested risk id
		const suggestedRiskId = notification.data.risk_id;

		// We get the suggested risk data
		const suggestedRiskTitleFr = notification.data.risk_title_fr;
		const suggestedRiskTitleEn = notification.data.risk_title_en;
		const suggestedRiskReference = notification.data.risk_reference;
		const suggestedRiskAuthorId = notification.data.risk_author_id;
		const suggestedRiskTheme = notification.data.risk_theme;
		const suggestedRiskStatus = notification.data.risk_status;

		// We get the theme of the suggested risk with a limitation of characters
		let curSuggestedRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === suggestedRiskTheme)[0];

			if (dataThemeCartography != undefined) {

				curSuggestedRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curSuggestedRiskTheme;

				if (suggestedRiskTheme.length >= themeLimit) {

					curSuggestedRiskTheme = curSuggestedRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the suggested risk
		let curSuggestedRiskTitle;
		if (notification.data[`risk_title_${this.currentLang}`] !== "" && notification.data[`risk_title_${this.currentLang}`] !== null) {

			curSuggestedRiskTitle = notification.data[`risk_title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && suggestedRiskTitleEn !== null) {

			curSuggestedRiskTitle = suggestedRiskTitleEn;

		} else if (this.currentLang === "en" && suggestedRiskTitleFr !== null) {

			curSuggestedRiskTitle = suggestedRiskTitleFr;

		} else {

			curSuggestedRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the author risk data
		let htmlAuthor = "";
		let htmlAuthorLegalEntity = "";
		if (suggestedRiskAuthorId !== undefined && suggestedRiskAuthorId !== null) {

			const authorRiskData = users.find(suggestedRiskAuthorId);

			if (authorRiskData.legal_entity_id !== undefined && authorRiskData.legal_entity_id !== null) {

				const legalEntityData = legalEntities.find(authorRiskData.legal_entity_id);

				htmlAuthorLegalEntity = `
						<div class="author-legal-entity" data-i18n="[title]title-legal-entity">
							<span class="icon fas fa-laptop-house"></span>
							<span class="text">${legalEntityData.name}</span>
						</div>
					`;

			}

			htmlAuthor = `
					<div class="wrap-author">
						<div class="author" data-i18n="[title]title-author">
							<span class="icon far fa-user"></span>
							<span class="text">${authorRiskData.standard_name}</span>
						</div>
						${htmlAuthorLegalEntity}
					</div>
				`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification" data-i18n="title-notification-new-risk-suggestion">${$.i18n("title-notification-new-risk-suggestion")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							    <div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${suggestedRiskReference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curSuggestedRiskTheme}</span>
							    </div>
							  </div>
							  <div class="title-risk">
							    <span class="text">${curSuggestedRiskTitle}</span>
							  </div>
							</div>
						</div>
						${htmlAuthor}
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		if (suggestedRiskStatus === "pending") {

			$(`#${notification.id} .card-risk`).on("click", () => {

				// We mark notification as read
				this.markNotificationAsRead(notification.id);

				// We show the risk
				router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${suggestedRiskId}/edit`);

			});

		}

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showRiskDeletedNotification (notification, date) {

		// We get the deleted risk id
		const deletedRiskId = notification.data.risk_id;

		// We get the deleted risk data
		const deletedRiskTitleFr = notification.data.risk_title_fr;
		const deletedRiskTitleEn = notification.data.risk_title_en;
		const deletedRiskReference = notification.data.risk_reference;
		const deletedRiskRpoId = notification.data.risk_rpo_id;
		const deletedRiskTheme = notification.data.risk_theme;
		const deletedRiskModeratorId = notification.data.moderator_id;

		// We get the theme of the deleted risk with a limitation of characters
		let curDeletedRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(notification.data.cartography_id).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === deletedRiskTheme)[0];

			if (dataThemeCartography != undefined) {

				curDeletedRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curDeletedRiskTheme;

				if (deletedRiskTheme.length >= themeLimit) {

					curDeletedRiskTheme = curDeletedRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the deleted risk
		let curDeletedRiskTitle;
		if (notification.data[`risk_title_${this.currentLang}`] !== "" && notification.data[`risk_title_${this.currentLang}`] !== null) {

			curDeletedRiskTitle = notification.data[`risk_title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && deletedRiskTitleEn !== null) {

			curDeletedRiskTitle = ddeletedRiskTitleEn;

		} else if (this.currentLang === "en" && deletedRiskTitleFr !== null) {

			curDeletedRiskTitle = deletedRiskTitleFr;

		} else {

			curDeletedRiskTitle = "";

		}


		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the rpo and moderator full name
		let moderatorRiskHtml = "";

		// Moderator
		if (notification.data.moderator_standard_name != null && notification.data.moderator_standard_name != undefined) {

			moderatorRiskHtml = `
					<div class="wrap-author">
						<div class="moderator color-error" data-i18n="[title]title-moderator">
							<span class="intro">${$.i18n("intro-risk-deleted-by-moderator")}</span>
							<span class="icon far fa-user"></span>
			      	<span class="text">${notification.data.moderator_standard_name}</span>
			      	<a href="mailto:${notification.data.moderator_email}" class="link-mail" data-i18n="[title]title-contact-moderator">
			      		<span class="text-email" data-i18n="button-contact-by-email">Contacter</span>
			      		<span class="picto-email fas fa-at"></span>
			      	</a>
						</div>
					</div>
				`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification color-error" data-i18n="title-notification-risk-deleted">${$.i18n("title-notification-risk-deleted")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk risk-deleted">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${deletedRiskReference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curDeletedRiskTheme}</span>
							    </div>
							  </div>
							  <div class="title-risk">
							    <span class="text">${curDeletedRiskTitle}</span>
							  </div>
							</div>
							${moderatorRiskHtml}
						</div>
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showRiskValidatedNotification (notification, date) {


		// We get the validated risk id
		const validatedRiskId = notification.data.risk_id;

		// We get the validated risk data
		const validatedRiskData = risks.find(validatedRiskId);

		// We get the theme of the validated risk with a limitation of characters
		let curValidatedRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === validatedRiskData.theme)[0];

			if (dataThemeCartography != undefined) {

				curValidatedRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curValidatedRiskTheme;

				if (validatedRiskData.theme.length >= themeLimit) {

					curValidatedRiskTheme = curValidatedRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the validated risk
		let curValidatedRiskTitle;
		if (validatedRiskData[`title_${this.currentLang}`] !== "" && validatedRiskData[`title_${this.currentLang}`] !== null) {

			curValidatedRiskTitle = validatedRiskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && validatedRiskData.title_en !== null) {

			curValidatedRiskTitle = validatedRiskData.title_en;

		} else if (this.currentLang === "en" && validatedRiskData.title_fr !== null) {

			curValidatedRiskTitle = validatedRiskData.title_fr;

		} else {

			curValidatedRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the rpo and moderator full name
		// RPO
		let rpoRiskHtml = "";
		if (notification.data.rpo_standard_name != null &&
						notification.data.rpo_standard_name != undefined) {

			rpoRiskHtml = `
							<div class="rpo" data-i18n="[title]title-rpo">
						      <span class="icon fas fa-user-tie"></span>
						      <span class="text">${notification.data.rpo_standard_name}</span>
						    </div>
						`;

		}

		// Moderator
		let moderatorRiskHtml = "";
		if (notification.data.moderator_standard_name != null &&
						notification.data.moderator_standard_name != undefined) {

			moderatorRiskHtml = `
							<div class="wrap-author">
								<div class="moderator color-valid" data-i18n="[title]title-moderator">
									<span class="intro">${$.i18n("intro-risk-validated-by-moderator")}</span>
									<span class="icon far fa-user"></span>
					      	<span class="text">${notification.data.moderator_standard_name}</span>
					      	<a href="mailto:${notification.data.moderator_email}" class="link-mail" data-i18n="[title]title-contact-moderator">
					      		<span class="text-email" data-i18n="button-contact-by-email">Contacter</span>
					      		<span class="picto-email fas fa-at"></span>
					      	</a>
								</div>
							</div>
						`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification color-valid" data-i18n="title-notification-risk-validated">${$.i18n("title-notification-risk-validated")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${validatedRiskData.reference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curValidatedRiskTheme}</span>
							    </div>
							    ${rpoRiskHtml}
							  </div>
							  <div class="title-risk">
							    <span class="text">${curValidatedRiskTitle}</span>
							  </div>
							</div>
						</div>
						${moderatorRiskHtml}
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		$(`#${notification.id} .card-risk`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${validatedRiskData.id}/business-activities/all`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showNewEvaluationInRedNotification (notification, date) {

		// We get the evaluated risk id
		const evaluatedRiskId = notification.data.risk_id;

		// We get the evaluated risk data
		const evaluatedRiskData = risks.find(evaluatedRiskId);

		// We get the evaluator risk ID
		const evaluatorRiskLegalEntityId = notification.data.evaluator_legal_entity_id;
		const evaluatorRiskStandardName = notification.data.evaluator_standard_name;

		// We get the RPO standard name
		const rpoRiskStandardName = notification.data.rpo_standard_name;

		// We get the theme of the evaluated risk with a limitation of characters
		let curEvaluatedRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === evaluatedRiskData.theme)[0];

			if (dataThemeCartography != undefined) {

				curEvaluatedRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curEvaluatedRiskTheme;

				if (evaluatedRiskData.theme.length >= themeLimit) {

					curEvaluatedRiskTheme = curEvaluatedRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the evaluated risk
		let curEvaluatedRiskTitle;
		if (evaluatedRiskData[`title_${this.currentLang}`] !== "" && evaluatedRiskData[`title_${this.currentLang}`] !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && evaluatedRiskData.title_en !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData.title_en;

		} else if (this.currentLang === "en" && evaluatedRiskData.title_fr !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData.title_fr;

		} else {

			curEvaluatedRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the evaluator risk data
		let htmlEvaluator = "";
		let htmlEvaluatorLegalEntity = "";
		let htmlBusinessActivities = "";
		if (evaluatorRiskLegalEntityId !== undefined && evaluatorRiskLegalEntityId !== null) {

			const legalEntityData = legalEntities.find(evaluatorRiskLegalEntityId);

			htmlEvaluatorLegalEntity = `
						<div class="author-legal-entity color-risk-red" data-i18n="[title]title-legal-entity">
							<span class="icon fas fa-laptop-house"></span>
							<span class="text">${legalEntityData.name}</span>
						</div>
					`;

			if (notification.data.business_activities_ids !== undefined && notification.data.business_activities_ids !== null) {

				// For each business activityin the notification
				notification.data.business_activities_ids.forEach((businessActivityId) => {

					// We get the data of the current business activity
					const businessActivityData = businessActivities.find(parseInt(businessActivityId));

					// We get the name of the current business activity
					const businessActivityName = businessActivityData[`name_${this.currentLang}`];

					// We define the html for the current business activity
					const htmlBusinessActivity = `
							<div class="evaluation-business-activity color-risk-red" data-i18n="[title]title-business-activity">
								<span class="icon fas fa-building"></span>
								<span class="text">${businessActivityName}</span>
							</div>
						`;

					// We had the current vusiness activity in the html of the list of business activities
					htmlBusinessActivities += htmlBusinessActivity;

				});

			}

			htmlEvaluator = `
					<div class="wrap-author">
						<div class="author color-risk-red" data-i18n="[title]title-evaluator">
							<span class="icon far fa-user"></span>
							<span class="text">${evaluatorRiskStandardName}</span>
						</div>
						${htmlEvaluatorLegalEntity}
						${htmlBusinessActivities}
					</div>
				`;

		}

		// We get the rpo standard name
		let rpoRiskHtml = "";
		if (rpoRiskStandardName !== undefined && rpoRiskStandardName !== null) {

			rpoRiskHtml = `
						<div class="rpo" data-i18n="[title]title-rpo">
				      <span class="icon fas fa-user-tie"></span>
				      <span class="text">${rpoRiskStandardName}</span>
				    </div>
					`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification color-risk-red" data-i18n="title-notification-new-evaluation-in-red">${$.i18n("title-notification-new-evaluation-in-red")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk color-red">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${evaluatedRiskData.reference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curEvaluatedRiskTheme}</span>
							    </div>
							    ${rpoRiskHtml}
							  </div>
							  <div class="title-risk">
							    <span class="text">${curEvaluatedRiskTitle}</span>
							  </div>
							</div>
						</div>
						${htmlEvaluator}
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}


		// We add link to the risk
		$(`#${notification.id} .card-risk`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${evaluatedRiskData.id}/business-activities/all`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showPersistentBadEvaluationNotification (notification, date) {

		// We get the evaluated risk id
		const evaluatedRiskId = notification.data.risk_id;

		// We get the evaluated risk data
		const evaluatedRiskData = risks.find(evaluatedRiskId);

		// We get the evaluator risk ID
		const evaluatorRiskId = notification.data.evaluator_id;

		// We get the RPO standard name
		const riskRpoStandardName = notification.data.rpo_standard_name;

		// We get the theme of the evaluated risk with a limitation of characters
		let curEvaluatedRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === evaluatedRiskData.theme)[0];

			if (dataThemeCartography != undefined) {

				curEvaluatedRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curEvaluatedRiskTheme;

				if (evaluatedRiskData.theme.length >= themeLimit) {

					curEvaluatedRiskTheme = curEvaluatedRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the evaluated risk
		let curEvaluatedRiskTitle;
		if (evaluatedRiskData[`title_${this.currentLang}`] !== "" && evaluatedRiskData[`title_${this.currentLang}`] !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && evaluatedRiskData.title_en !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData.title_en;

		} else if (this.currentLang === "en" && evaluatedRiskData.title_fr !== null) {

			curEvaluatedRiskTitle = evaluatedRiskData.title_fr;

		} else {

			curEvaluatedRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the evaluator risk data
		let htmlEvaluator = "";
		let htmlEvaluatorLegalEntity = "";
		let htmlBusinessActivity = "";
		let evaluatorFullName = "";
		if (evaluatorRiskId !== undefined && evaluatorRiskId !== null) {

			const evaluatorRiskData = users.find(evaluatorRiskId);

			if (evaluatorRiskData !== undefined && evaluatorRiskData !== null) {

				evaluatorFullName = `${evaluatorRiskData.firstname}&nbsp;${evaluatorRiskData.lastname}`;

				if (evaluatorRiskData.legal_entity_id !== undefined && evaluatorRiskData.legal_entity_id !== null) {

					const legalEntityData = legalEntities.find(evaluatorRiskData.legal_entity_id);

					htmlEvaluatorLegalEntity = `
							<div class="author-legal-entity color-risk-red" data-i18n="[title]title-legal-entity">
								<span class="icon fas fa-laptop-house"></span>
								<span class="text">${legalEntityData.name}</span>
							</div>
						`;

				}

			}

			if (notification.data.business_activity_id !== undefined && notification.data.business_activity_id !== null) {

				const businessActivityData = businessActivities.find(parseInt(notification.data.business_activity_id));
				const businessActivityName = businessActivityData[`name_${this.currentLang}`];

				htmlBusinessActivity = `
						<div class="evaluation-business-activity color-risk-red" data-i18n="[title]title-business-activity">
							<span class="icon fas fa-building"></span>
							<span class="text">${businessActivityName}</span>
						</div>
					`;

			}

			htmlEvaluator = `
					<div class="wrap-author">
						<div class="author color-risk-red" data-i18n="[title]title-evaluator">
							<span class="icon far fa-user"></span>
							<span class="text">${evaluatorFullName}</span>
						</div>
						${htmlEvaluatorLegalEntity}
						${htmlBusinessActivity}
					</div>
				`;

		}

		// We get the rpo full name
		let rpoRiskHtml = "";
		if (riskRpoStandardName !== undefined && riskRpoStandardName !== null) {

			rpoRiskHtml = `
						<div class="rpo" data-i18n="[title]title-rpo">
				      <span class="icon fas fa-user-tie"></span>
				      <span class="text">${riskRpoStandardName}</span>
				    </div>
					`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification color-risk-red" data-i18n="title-notification-persistent-bad-evaluation">${$.i18n("title-notification-persistent-bad-evaluation")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk color-red">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${evaluatedRiskData.reference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curEvaluatedRiskTheme}</span>
							    </div>
							    ${rpoRiskHtml}
							  </div>
							  <div class="title-risk">
							    <span class="text">${curEvaluatedRiskTitle}</span>
							  </div>
							</div>
						</div>
						${htmlEvaluator}
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		// We add link to the risk
		$(`#${notification.id} .card-risk`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${evaluatedRiskData.id}/business-activities/all`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showNewRiskOnEvaluatedCartographyNotification (notification, date) {

		// We get the risk id
		const riskId = notification.data.risk_id;

		// We get the risk data
		const riskData = risks.find(riskId);

		// We get the RPO standard name
		const riskRpoStandardName = notification.data.rpo_standard_name;

		// We get the theme of the risk with a limitation of characters
		let curRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {


			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === riskData.theme)[0];

			if (dataThemeCartography != undefined) {

				curRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curRiskTheme;

				if (riskData.theme.length >= themeLimit) {

					curRiskTheme = curRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the risk
		let curRiskTitle;
		if (riskData[`title_${this.currentLang}`] !== "" && riskData[`title_${this.currentLang}`] !== null) {

			curRiskTitle = riskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && riskData.title_en !== null) {

			curRiskTitle = riskData.title_en;

		} else if (this.currentLang === "en" && riskData.title_fr !== null) {

			curRiskTitle = riskData.title_fr;

		} else {

			curRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the rpo full name
		let rpoRiskHtml = "";
		if (riskRpoStandardName !== undefined && riskRpoStandardName !== null) {

			rpoRiskHtml = `
						<div class="rpo" data-i18n="[title]title-rpo">
				      <span class="icon fas fa-user-tie"></span>
				      <span class="text">${riskRpoStandardName}</span>
				    </div>
					`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification" data-i18n="title-notification-new-risk-on-evaluated-cartography">${$.i18n("title-notification-new-risk-on-evaluated-cartography")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${riskData.reference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curRiskTheme}</span>
							    </div>
							    ${rpoRiskHtml}
							  </div>
							  <div class="title-risk">
							    <span class="text">${curRiskTitle}</span>
							  </div>
							</div>
						</div>
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		$(`#${notification.id} .card-risk`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${riskData.id}/business-activities/all`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showNewsCreatedNotification (notification, date) {

		// We define news vars
		const newsData = news.find(parseInt(notification.data.news_id));
		const newsAuthorId = notification.data.news_author_id;

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive infos-hidden";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the author risk data
		let htmlAuthor = "";
		let htmlAuthorLegalEntity = "";
		if (newsAuthorId !== undefined && newsAuthorId !== null) {

			htmlAuthor = `
					<div class="wrap-author">
						<div class="author" data-i18n="[title]title-author">
							<span class="icon far fa-user"></span>
							<span class="text">${notification.data.news_author_standard_name}</span>
						</div>
						${htmlAuthorLegalEntity}
					</div>
				`;

		}

		// We get the news content
		let newsContent = "";
		if (newsData[`content_${this.currentLang}`] !== undefined && newsData[`content_${this.currentLang}`] !== null) {

			newsContent = newsData[`content_${this.currentLang}`].substring(0, 100).concat("...");

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification" data-i18n="title-notification-news-created">${$.i18n("title-notification-news-created")}</div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="wrap-news">
								<div class="top">
									<div class="icon fas fa-newspaper"></div>
									<div class="title-news">
								    <span class="text">${newsData[`title_${this.currentLang}`]}</span>
								  </div>
								</div>
								<div class="bottom">
									<div class="content-news">${newsContent}</div>
									<div class="btn-read-news">
								  	<span class="text" data-i18n="button-read-more">${$.i18n("button-read-more")}</span>
								  	<span class="icon fas fa-angle-right"></span>
								  </div>
								</div>
							</div>
						</div>
						${htmlAuthor}
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		// We listen click on button more
		$(`#${notification.id} .wrap-news`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/news/${notification.data.news_id}`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

	showNewActionOnYourActionsPlanNotification (notification, date) {

		// We get the risk id
		const riskId = notification.data.risk_id;

		// We get the risk data
		const riskData = risks.find(riskId);

		// We get the title of the action
		let curActionTitle;
		if (notification.data[`action_title_${this.currentLang}`] !== "" && notification.data[`action_title_${this.currentLang}`] !== null) {

			curActionTitle = notification.data[`action_title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && notification.data.title_en !== null) {

			curActionTitle = notification.data.title_en;

		} else if (this.currentLang === "en" && notification.data.title_fr !== null) {

			curActionTitle = notification.data.title_fr;

		} else {

			curActionTitle = "";

		}

		// We get the RPO standard name
		const riskRpoStandardName = notification.data.rpo_standard_name;

		// We get the theme of the risk with a limitation of characters
		let curRiskTheme = "";
		let fullCurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {


			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === riskData.theme)[0];

			if (dataThemeCartography != undefined) {

				curRiskTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullCurTheme = curRiskTheme;

				if (riskData.theme.length >= themeLimit) {

					curRiskTheme = curRiskTheme.substring(0, themeLimit).concat("", "...");

				}

			}

		}

		// We get the title of the risk
		let curRiskTitle;
		if (riskData[`title_${this.currentLang}`] !== "" && riskData[`title_${this.currentLang}`] !== null) {

			curRiskTitle = riskData[`title_${this.currentLang}`];

		} else if (this.currentLang === "fr" && riskData.title_en !== null) {

			curRiskTitle = riskData.title_en;

		} else if (this.currentLang === "en" && riskData.title_fr !== null) {

			curRiskTitle = riskData.title_fr;

		} else {

			curRiskTitle = "";

		}

		// If the notification is read
		let extraNotificationClass = "";
		let htmlBtnMark = "";
		if (notification.read_at !== null) {

			// We add the extra class
			extraNotificationClass = "inactive";

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsUnreadButton;

		} else {

			// We add the mark button
			htmlBtnMark = this.htmlMarkAsReadButton;

		}

		// We get the rpo full name
		let rpoRiskHtml = "";
		if (riskRpoStandardName !== undefined && riskRpoStandardName !== null) {

			rpoRiskHtml = `
						<div class="rpo" data-i18n="[title]title-rpo">
				      <span class="icon fas fa-user-tie"></span>
				      <span class="text">${riskRpoStandardName}</span>
				    </div>
					`;

		}

		// We define the notification item
		const htmlNotification = `
				<div class="item-notification ${extraNotificationClass}" id="${notification.id}">
					${htmlBtnMark}
					<div class="wrap-notification">
						<div class="title-notification"><span class="text" data-i18n="title-notification-new-action-on-your-actions-plan">${$.i18n("title-notification-new-action-on-your-actions-plan")}</span>&nbsp;:&nbsp;<span class="highlight">${curActionTitle}</span></div>
						<div class="date-notification">
							<span class="icon far fa-calendar-alt"></span>
							<span class="text">${date}</span>
						</div>
						<div class="content-notification">
							<div class="card-risk">
								<div class="arrow">
							    <span class="icon fas fa-angle-right"></span>
							  </div>
							  <div class="top">
							  	<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
							    	<span class="icon fas fa-barcode"></span>
							    	<span class="text">${riskData.reference}</span>
							    </div>
							    <div class="tag" data-i18n="[title]title-theme">
							      <span class="icon"></span>
							      <span class="text">${curRiskTheme}</span>
							    </div>
							    ${rpoRiskHtml}
							  </div>
							  <div class="title-risk">
							    <span class="text">${curRiskTitle}</span>
							  </div>
							</div>
						</div>
					</div>
				</div>
			`;

		// We show the notification item
		if (notification.read_at !== null) {

			$("#section-read .content-section").append(htmlNotification);

		} else {

			$("#section-unread .content-section").append(htmlNotification);

		}

		$(`#${notification.id} .card-risk`).on("click", () => {

			// We mark notification as read
			this.markNotificationAsRead(notification.id);

			// We show the risk
			router.navigate(`/legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${riskData.id}/business-activities/all#actions`);

		});

		// We listen click on the notification mark as read button
		$(`#${notification.id} .btn-mark-read`).on("click", this.handleClickOnMarkAsReadButton.bind(this));

		// We listen click on the notification mark as unread button
		$(`#${notification.id} .btn-mark-unread`).on("click", this.handleClickOnMarkAsUnreadButton.bind(this));

	}

}
export default new Notifications();
