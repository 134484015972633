import router from "Core/router.js";
import api from "Core/Api.js";
import context from "Datapoints/Context.js";
import user from "Datapoints/User.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Cartographies extends Datapoint {

	constructor (params) {

		super();
		this.ignoreCleaningForThisFields = [
			"id",
			"created_at",
			"updated_at",
			"last_risk_id",
			"branch_id",
			"approach_image_fr",
			"approach_image_en",
			"admin_users"
		];

	}

	createOne (cartographyToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/cartography/create",
				"data": cartographyToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (cartographyToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/cartography/update",
				"data": cartographyToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateApproachImage (cartographyToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/cartography/update",
				"data": cartographyToUpdate,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	deleteOne (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/delete/${cartographyId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	find (idToFind) {

		let cartography;
		if (this.data != undefined) {

			cartography = this.data.find((cartography) => cartography.id == idToFind);

		}

		return cartography;

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			if (this.data != undefined && !forceRequest) {

				resolve();
				return;

			}

			api.call({
				"method": "GET",
				"url": "/cartographies"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	evaluateOne (evaluationToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/cartography/evaluate",
				"data": evaluationToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	publishEvaluationsForRisk (riskId, legalEntityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/evaluations/publish-evaluations-for-risk/${riskId}/${legalEntityId} `
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status === 403) {

						const dataError = {
							"error": "403"
						};

						resolve(dataError);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getAvailableVersionsOfEvaluations (riskId, legalEntityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/evaluations/available-versions/${riskId}/${legalEntityId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status === 403) {

						const dataError = {
							"error": "403"
						};

						resolve(dataError);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getEvaluation (cartographyId, legalEntityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/evaluations/${cartographyId}/${legalEntityId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status === 403) {

						const dataError = {
							"error": "403"
						};

						resolve(dataError);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getEvaluationForBusinessActivity (cartographyId, legalEntityId, businessActivityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/evaluations/${cartographyId}/${legalEntityId}/${businessActivityId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status === 403) {

						const dataError = {
							"error": "403"
						};

						resolve(dataError);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getEvaluationForContext (contextData) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/cartography/evaluations/for-context",
				"data": contextData
			}).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status === 403) {

						const dataError = {
							"error": "403"
						};

						resolve(dataError);

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getPossibleRPOForCartography (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/getPossibleRPOs/${cartographyId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	getPossibleTaskHandlersForCartography (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/getPossibleTaskHandlers/${cartographyId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	// CREATION : 19.09.2022 - SPRINT 48
	canNewCartographyBeCreatedInSubscription (branchId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartographies/${branchId}/can-a-cartography-be-created-in-subscription`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					error.objCalling = this.constructor.name;
					reject(error);

				});

		});

	}

	// CREATION : 19.09.2022 - SPRINT 48
	getStatus (cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/cartography/status/${cartographyId}`
			}, false).
				done((dataReturned) => {

					resolve(dataReturned);

				}).
				fail((error, textStatus) => {

					error.objCalling = this.constructor.name;
					reject(error);

				});

		});

	}

	// CREATION : 05.10.2022 - SPRINT 49
	setCurrentCartographyId (cartographyId) {

		if (cartographyId !== undefined && cartographyId !== null) {

			// We define the current cartography
			this.curCartographyId = parseInt(cartographyId);

		}

	}

	// CREATION : 05.10.2022 - SPRINT 49
	getCurrentCartographyId () {

		// We get the current branch id
		const currentBranchId = context.getCurrentBranchId();

		// We filter the cartographies for current branch
		const filteredCartographies = this.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(currentBranchId));

		// We get url parameters
		let urlParams;
		if (router._lastRouteResolved !== undefined && router._lastRouteResolved !== null) {

			urlParams = router._lastRouteResolved.params;

		}

		// We define the cartography in url params
		let cartographyIdInParams;
		if (urlParams !== undefined && urlParams !== null) {

			if (urlParams.idCartography !== undefined && urlParams.idCartography !== null && urlParams.idCartography !== "") {

				// We check if the cartography in params is in the current branch
				const urlParamCartographyInCurrentBranch = this.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(currentBranchId) && parseInt(cartography.id) === parseInt(urlParams.idCartography));

				// If the current cartography id exists in the current branch
				if (urlParamCartographyInCurrentBranch !== undefined && urlParamCartographyInCurrentBranch.length > 0) {

					// We define the current cartography
					cartographyIdInParams = parseInt(urlParams.idCartography);

				} else {

					cartographyIdInParams = null;

				}

			}

		}


		let cartographyIdToShow;

		if (this.curCartographyId !== undefined && this.curCartographyId !== null) {

			// We check if the cartography selected is in the current branch
			const selectedCartographyInCurrentBranch = this.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(currentBranchId) && parseInt(cartography.id) === parseInt(this.curCartographyId));

			// If the current cartography id exists in the current branch
			if (selectedCartographyInCurrentBranch !== undefined && selectedCartographyInCurrentBranch.length > 0) {

				cartographyIdToShow = parseInt(this.curCartographyId);

			} else if (filteredCartographies !== undefined && filteredCartographies.length > 0) {

				cartographyIdToShow = filteredCartographies[0].id;

			} else if (this.data !== undefined && this.data.length > 0) {

				cartographyIdToShow = this.data[0].id;

			} else {

				cartographyIdToShow = null;

			}

		} else if (cartographyIdInParams !== undefined && cartographyIdInParams !== null) {

			cartographyIdToShow = cartographyIdInParams;

		} else if (filteredCartographies !== undefined && filteredCartographies.length > 0) {

			cartographyIdToShow = filteredCartographies[0].id;

		} else if (this.data !== undefined && this.data.length > 0) {

			cartographyIdToShow = this.data[0].id;

		} else {

			cartographyIdToShow = null;

		}

		return cartographyIdToShow;

	}


}
export default new Cartographies();
