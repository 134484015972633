import router from "Core/router.js";
import api from "Core/Api.js";
import user from "Datapoints/User.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class LegalEntities extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaningForThisFields = [
			"id",
			"created_at",
			"updated_at",
			"tela_number",
			"branch_id",
			"profit_center_id",
			"country_id",
			"has_no_business_activity"
		];

		this.cacheBusinessActivities = [];
		this.promises = [];

	}

	createOne (legalEntityToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/legal-entity/create",
				"data": legalEntityToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (legalEntityToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/legal-entity/update",
				"data": legalEntityToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (legalEntityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/legal-entity/delete/${legalEntityId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	setCategoryForCartography (categoryData) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/legal-entity/set-category-for-cartography",
				"data": categoryData
			}).
				done(resolve).
				fail(reject);

		});

	}

	getCategoryForCartography (cartographyId, legalEntityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/legal-entity/get-category-for-cartography",
				"data": {
					"cartography_id": cartographyId,
					"legal_entity_id": legalEntityId
				}
			}).
				done(resolve).
				fail(reject);

		});

	}

	setBusinessActivities (businessActivitiesData) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/legal-entity/set-business-activities",
				"data": businessActivitiesData
			}).
				done(resolve).
				fail(reject);

		});

	}

	getBusinessActivities (legalEntityId) {

		let isPromisePending = this.promises.getBusinessActivities != undefined &&
			this.promises.getBusinessActivities.promise != undefined &&
			this.promises.getBusinessActivities.state == "pending";
		if (isPromisePending) {

			return this.promises.getBusinessActivities.promise;

		}

		this.promises.getBusinessActivities = [];
		this.promises.getBusinessActivities.promise = new Promise((resolve, reject) => {

			if (this.cacheBusinessActivities[legalEntityId] === undefined) {

				api.call({
					"method": "POST",
					"url": "/legal-entity/get-business-activities",
					"data": {
						"legal_entity_id": legalEntityId
					}
				}).
					done((result) => {

						this.promises.getBusinessActivities.state = "fullfilled";
						this.cacheBusinessActivities[legalEntityId] = result;
						resolve(this.cacheBusinessActivities[legalEntityId]);

					}).
					fail(() => {

						this.promises.getBusinessActivities.state = "failed";
						reject();

					});

			} else {

				this.promises.getBusinessActivities.state = "fullfilled";
				resolve(this.cacheBusinessActivities[legalEntityId]);

			}

		});

		return this.promises.getBusinessActivities.promise;

	}

	resetBusinessActivitiesCache () {

		this.cacheBusinessActivities = [];

	}

	getAllLegalEntities () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/legal-entities/all"
			}).
				done(resolve).
				fail(reject);

		});

	}

	getData () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/legal-entities/user-can-see-or-evaluate"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	find (idToFind) {

		let legalEntity;
		if (this.data != undefined) {

			legalEntity = this.data.find((legalEntity) => legalEntity.id == idToFind);

		}

		// If it's undefined and we have a logged in user, we check in the legal entities he can admin users for
		if (legalEntity == undefined && user.data != undefined) {

			legalEntity = user.data.legalEntitiesUserCanAdminUserFor.find((legalEntity) => legalEntity.id == idToFind);

		}

		return legalEntity;

	}

	getLib (idLegalEntity) {

		let legalEntity = this.find(idLegalEntity);
		return legalEntity != undefined ? legalEntity.name : "";

	}

	isInGivenLegalEntities (legalEntityIdToTest) {

		if (this.data != undefined) {

			return this.data.filter((legalEntity) => legalEntity.id == legalEntityIdToTest).length > 0;

		}
		return false;

	}

	hasACountry (legalEntityIdToTest) {

		if (legalEntityIdToTest !== undefined && legalEntityIdToTest !== null) {

			return this.find(legalEntityIdToTest).country_id != null && this.find(legalEntityIdToTest).country_id != undefined;

		}
		return false;

	}

	hasAProfitCenter (legalEntityIdToTest) {

		if (legalEntityIdToTest !== undefined && legalEntityIdToTest !== null) {

			return this.find(legalEntityIdToTest).profit_center_id != null && this.find(legalEntityIdToTest).profit_center_id != undefined;

		}
		return false;

	}

	getLegalEntitiesByProfitCenter () {

		return this.data.sort(this.comparePCId);

	}

	comparePCId (pcA, pcB) {

		return pcA.profit_center_id > pcB.profit_center_id ? 1 : -1;

	}

}
export default new LegalEntities();
