import html from "./404.html";
import router from "Core/router.js";
import Page from "Pages/Page.js";
import LoginLayout from "Layouts/Login/LoginLayout.js";
import user from "Datapoints/User.js";
import "./404.scss";

class NotFound extends Page {

	constructor () {

		let routes = ["404"];

		super(routes, LoginLayout, "Page non trouvée");

	}

	render (params) {

		this.parseAndRender("page", html);

	}

}
export default new NotFound();
