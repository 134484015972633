import html from "./task.html";
import tasks from "Datapoints/Tasks";
import user from "Datapoints/User.js";
import Popin from "Components/Popin/Popin.js";
import debug from "Core/Debug.js";
import {showFormErrors} from "Core/helpers.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import Task from "./Task.js";
// Import Datepicker
import "Core/assets/lib/datepicker.min.css";
import "Core/assets/lib/datepicker.min.js";
import "Core/assets/lib/datepicker.fr-FR.js";

class TaskContainer {

	constructor (risk) {

		this.risk = risk;

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.risk.id);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.risk.id);

		// If the user is RPO or Editor, we show the buttons
		if (this.isCurrentUserRPO || this.isCurrentUserRiskEditor) {

			this.canAdmin = true;
			this.startShowing();

		} else { // Otherwise we check if he has appropriate right

			user.canAdminRisks({"idCartography": this.risk.cartography_id}).then((result) => {

				this.canAdmin = true;
				this.startShowing();

			}).catch((e) => {

				this.canAdmin = false;
				this.startShowing();

			});

		}

	}

	startShowing () {

		this.initAdminSidebar();
		this.showTasks();
		this.addUserOptions();

	}

	showTasks () {

		if (
			tasks.data !== null &&
			tasks.data !== undefined &&
			tasks.data.length === 0
		) {

			const htmlNoItem = `<div class="no-item" data-i18n="label-msg-no-task">${$.i18n("label-msg-no-task")}</div>`;

			$("#tasks ul.list-tasks").html(htmlNoItem);

		} else {

			// We clear container
			$("#tasks ul.list-tasks").html("");

			// We show all tasks
			$.each(tasks.data, (index, item) => {

				const newTask = new Task(item);

				newTask.appendAndFill("#tasks ul.list-tasks", item, this.canAdmin, this.risk);
				$(newTask).on("edit", this.handleClickEditSidebarItem.bind(this));
				$(newTask).on("delete", this.handleClickDeleteSidebarItem.bind(this));

			});

		}

		// We update the control instruments number of the sub menu in the risk page
		const numItemLink = $("#slide-control-instruments .item-link").length;
		$("#sub-menu-item-control-instruments .num").html(numItemLink);

	}

	addUserOptions () {

		cartographies.getPossibleTaskHandlersForCartography(this.risk.cartography_id).then((users) => {

			$(users).each((i, userToAdd) => {

				$("FORM [name=handler_id]").append(`<option value='${userToAdd.id}'>${userToAdd.standard_name}</option>`);

			});

		}).catch((error) => {

			debug.v("TaskContainer - addUserOptions - error in User getPossibleTaskHandlersForCartography");

		});

		// We listen change on user select
		$("FORM [name=handler_id]").on("change", this.handleChangeUserSelect.bind(this));


	}

	initAdminSidebar () {

		const btnAddItemSelector = $("#btn-add-task");
		const formAddItemSelector = $("#tasks .content-box-sidebar .form-add-item");

		// If the user do not have the right to admin cartography, we remove the button to create / update / delete
		if (!this.canAdmin) {

			btnAddItemSelector.hide();

		} else {

			// We define click on btn add item
			btnAddItemSelector.on("click", this.handleClickAddSidebarItem.bind(this));

			// We define click on submit buttons
			$("#tasks .content-box-sidebar").find(".btn-submit").on("click", this.handleClickBtnCreateTask.bind(this));

			// We define click on cancel buttons
			$("#tasks .content-box-sidebar").find(".btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

		}

	}

	handleChangeUserSelect (e) {

		// We get the id of the selected user
		const userId = $("FORM [name=handler_id]").val();

		cartographies.getPossibleTaskHandlersForCartography(this.risk.cartography_id).then((users) => {

			$(users).each((i, userToCheck) => {

				if (userId == userToCheck.id) {

					if (!userToCheck.can_see_this_cartography) {

						const cartographyName = cartographies.find(this.risk.cartography_id)[`name_${$.i18n().locale}`];
						const legalEntityName = legalEntities.find(userToCheck.legal_entity_id).name;

						// We define the message to show
						const htmlUserMessage = `<div class="msg-user"><div class="icon fas fa-info-circle"></div><div class="text">${$.i18n("text-message-add-right-task-handler-part-1")} <span class="highlight">${cartographyName}</span> ${$.i18n("text-message-add-right-task-handler-part-2")} <span class="highlight">${legalEntityName}</span>.</div></div>`;

						// We show a message after the select
						$(".box-select .msg-user").remove();
						$("FORM [name=handler_id]").after(htmlUserMessage);

					} else {

						$(".box-select .msg-user").remove();

					}

				}

			});

		}).catch((error) => {

			debug.v("TaskContainer - handleChangeUserSelect - error in User getPossibleTaskHandlersForCartography");

		});

	}

	handleClickAddSidebarItem (e) {

		const formSelector = $("#create_task");

		// We clear all errors form
		formSelector.find("input").removeClass("error");
		formSelector.find("select").removeClass("error");
		formSelector.find(".error").html("");

		if (formSelector.attr("class").indexOf("active") < 0) {

			formSelector.addClass("active");

			// We clear all fields
			formSelector.find("INPUT[name=name]").val("");
			formSelector.find("INPUT[name=due_at]").val("");

			// We hide all opened forms
			$(e.currentTarget).parent().find(".item-task").removeClass("active-form");
			$(e.currentTarget).parent().find(".item-task .form-admin-item").remove();

		} else {

			formSelector.removeClass("active");

		}

	}

	handleClickEditSidebarItem (e, taskId) {

		// We define parent item selector
		const parentItemSelector = $("#tasks").find(`.item-link[data-item-id='${taskId}']`);

		// We hide all opened edit forms
		$("#tasks").find(".item-link").removeClass("active-form");
		$("#tasks").find(".form-admin-item").removeClass("active");

		const htmlForm = $("#create_task").html();

		// We hide the add item form
		$("#create_task").removeClass("active");

		// We show the edit item form
		parentItemSelector.addClass("active-form");

		parentItemSelector.append(`<form id="edit_task" class="form-edit-item form-admin-item active">${htmlForm}<input name='id' value='${taskId}' type='hidden' /></form>`);

		this.taskToEdit = tasks.find(taskId);

		$("#edit_task [name='name']").attr("value", this.taskToEdit.name);
		$(`#edit_task [name='handler_id'] OPTION[value='${this.taskToEdit.handler_id}']`).attr("selected", true);
		if (this.taskToEdit.due_at != null && this.taskToEdit.due_at != undefined) {

			$("#edit_task [name='due_at']").attr("value", this.taskToEdit.due_at.split("-").reverse().join("/"));

		}

		// We define clicks
		$("#edit_task .btn-submit").on("click", this.handleClickBtnUpdateTask.bind(this));
		$("#edit_task .btn-cancel").on("click", this.handleClickBtnCancelForm.bind(this));

		// We init the datepicker
		this.initDatepicker();

	}

	initDatepicker () {

		$("[data-toggle=\"datepicker\"]").datepicker({
			"language": "fr-FR",
			"format": "dd/mm/yyyy",
			"autoHide": true
		});

	}

	handleClickDeleteSidebarItem (e, taskId) {

		this.taskIdToDelete = taskId;
		const taskName = $(`.item-link[data-item-id='${taskId}']`).find(".content-task .desc-task .title").html();

		$("#delete-item-sidebar-popin").remove();

		this.deleteSidebarItemPopin = new Popin("delete-item-sidebar-popin", `${$.i18n("popin-delete-task-confirmation-title")} '${taskName}' ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteSidebarItemCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteTaskConfirm.bind(this)
			}
		]);

		this.deleteSidebarItemPopin.appendIn("#page");

		this.deleteSidebarItemPopin.show();

	}

	handleClickBtnCancelForm (e) {

		// We cancel the link submission
		e.preventDefault();

		// We define the current form
		const form = $(e.currentTarget).parents("form");

		// We show item informations
		$(e.currentTarget).parents(".item-link").removeClass("active-form");

		// We hide the form
		form.removeClass("active");
		$(e.currentTarget).parents(".form-edit-item").remove();

	}

	handleClickBtnCreateTask (e) {

		// We cancel the link submission
		e.preventDefault();

		// We create the and populate the form data
		let form_data = new FormData();
		let taskName = $("#create_task INPUT[name=\"name\"]").val();
		let taskHandlerId = $("#create_task SELECT[name=\"handler_id\"]").val();
		let taskDueAt = `${$("#create_task INPUT[name=\"due_at\"]").val()}`.split("/").reverse().join("-");

		form_data.append("name", taskName);
		form_data.append("handler_id", taskHandlerId);
		form_data.append("due_at", taskDueAt);

		tasks.addTask(form_data, this.risk.id).then(this.createSuccess.bind(this), this.createFail.bind(this));

	}

	createSuccess (response) {

		debug.v("Risk task creation - createSuccess");

		// We hide the form
		$("#create_task").removeClass("active");

		tasks.getData({"idRisk": this.risk.id}).then(() => {

			this.showTasks();

			// We clear all fields
			$("#create_task INPUT[name=\"name\"]").val("");
			$("#create_task SELECT[name=\"handler_id\"]").val("");
			$("#create_task INPUT[name=\"due_at\"]").val("");

		}).catch((error) => {

			debug.e("Risk task creation - createSuccess - error in Tasks getData");
			debug.e(error);

		});

	}

	createFail (response, textStatus) {

		debug.v("Risk task creation - createFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": `${$.i18n("form-error-global-name-required")}`
				},
				"handler_id": {
					"required": `${$.i18n("form-error-handler-id-required")}`,
					"unknown": `${$.i18n("form-error-handler-id-unknown")}`
				},
				"due_at": {
					"required": `${$.i18n("form-error-date-required")}`,
					"bad_format": `${$.i18n("form-error-date-bad-format")}`
				}
			};

			showFormErrors("#create_task", response, errorsToShow);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);
			debug.e("Risk task creation - createSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleClickBtnUpdateTask (e) {

		e.preventDefault();

		// We create and populate the form data
		let form_data_task = new FormData();
		let taskName = $("#edit_task INPUT[name=\"name\"]").val();
		let taskHandlerId = $("#edit_task SELECT[name=\"handler_id\"]").val();
		let taskDueAt = `${$("#edit_task INPUT[name=\"due_at\"]").val()}`.split("/").reverse().join("-");

		form_data_task.append("name", taskName);
		form_data_task.append("handler_id", taskHandlerId);
		form_data_task.append("due_at", taskDueAt);

		// We get the taskId and risk id
		form_data_task.append("id", this.taskToEdit.id);
		form_data_task.append("risk_id", this.taskToEdit.risk_id);

		tasks.updateTask(form_data_task).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

	}

	updateSuccess (response) {

		debug.v("Risk task update - updateSuccess");

		tasks.getData({"idRisk": this.risk.id}).then(() => {

			this.showTasks();

		}).catch((error) => {

			debug.e("Risk task update - updateSuccess - error in Tasks getData");
			debug.e(error);

		});

	}

	updateFail (response, textStatus) {

		debug.v("Risk task update - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"name": {
					"required": `${$.i18n("form-error-global-name-required")}`
				},
				"due_at": {
					"required": `${$.i18n("form-error-date-required")}`
				}
			};

			showFormErrors("#edit_task", response, errorsToShow);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);
			debug.e("Risk task update - updateSuccess - unhandled error");
			debug.e(response);

		}

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteSidebarItemCancel () {

		this.deleteSidebarItemPopin.hide();

	}

	handleDeleteTaskConfirm () {

		tasks.deleteTask(this.risk.id, this.taskIdToDelete).then(() => {

			this.deleteSidebarItemPopin.hide();

			tasks.getData({"idRisk": this.risk.id}).then(() => {

				this.showTasks();

			}).catch((error) => {

				debug.e("Risk task deletion - handleDeleteTaskConfirm - error in Tasks getData");
				debug.e(error);

			});

		}).catch(debug.e);

	}

}
export default TaskContainer;
