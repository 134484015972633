import html from "./userRightsProfilesForm.html";
import debug from "Core/Debug.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import userRightsForm from "Components/UserRightsForm/UserRightsForm.js";
import "Pages/Admin/Admin.scss";
import "Pages/Admin/AdminResponsive.scss";

class UserRightsProfilesForm {

	constructor (selector) {

		$(selector).append(html);

		this.init();

	}

	init () {

		// We get the current language
		this.currentLang = $.i18n().locale.substring(0, 2);

		this.initEvents();

	}

	initEvents () {

		// If the config hides the branches
		if (process.env.SHOW_BRANCHES === false || process.env.SHOW_BRANCHES === "false") {

			// We remove the branch field
			$("#field-branch-profile").remove();

			// We update the steps numbers and sizes
			$("#field-cartography-profile .sub-section-title .num").html("2");

			if (process.env.SHOW_COUNTRIES === false || process.env.SHOW_COUNTRIES === "false") {

				$("#field-legal-entity-profile .sub-section-title .num").html("3");
				$("#field-profile-choice .sub-section-title .num").html("4");

			} else {

				$("#field-country-profile .sub-section-title .num").html("3");
				$("#field-legal-entity-profile .sub-section-title .num").html("4");
				$("#field-profile-choice .sub-section-title .num").html("5");

			}

		}

		// If the config hides the countries
		if (process.env.SHOW_COUNTRIES === false || process.env.SHOW_COUNTRIES === "false") {

			// We remove the country field
			$("#field-country-profile").remove();

			// We update the steps numbers
			$("#field-legal-entity-profile .sub-section-title .num").html("4");
			$("#field-profile-choice .sub-section-title .num").html("5");

		}

	}

	getRightsFromProfile (profileName, rightContext) {

		// We define the context
		this.rightContext = rightContext;

		// We define the rights from current profile
		let rightsFromCurrentProfile = [];


		if (profileName === "head-office-admin") {

			rightsFromCurrentProfile = this.getHeadOfficeAdminRights();

		} else if (profileName === "subsidiary-admin") {

			rightsFromCurrentProfile = this.getSubsidiaryAdminRights();

		} else if (profileName === "evaluator") {

			rightsFromCurrentProfile = this.getEvaluatorRights();

		} else if (profileName === "reader") {

			rightsFromCurrentProfile = this.getReaderRights();

		} else {

			rightsFromCurrentProfile = [];

		}

		return rightsFromCurrentProfile;

	}

	getHeadOfficeAdminRights () {

		// We define head office admin rights
		this.headOfficeAdminRights = [
			{
				"branch_id": this.getBranchId(),
				"rights": ["communicate"]
			},
			{
				"branch_id": this.getBranchId(),
				"rights": ["admin_cartographies"]
			},
			{
				"branch_id": this.getBranchId(),
				"rights": ["admin_business_activities"]
			},
			{
				"branch_id": this.getBranchId(),
				"rights": ["access_statistics"]
			}
		];

		if (this.rightContext.cartography_id === "all") {

			// We filter cartographies of the selected branch
			const selectedBranchCartographies = cartographies.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(this.getBranchId()));

			// For each cartography of the selected branch, we add rights
			$.each(selectedBranchCartographies, (index, cartography) => {

				// We add the rights for each cartography
				this.addHeadOfficeRights(cartography.id, cartography.branch_id);

			});

		} else {

			// We get the cartography id
			const cartographyId = parseInt(this.rightContext.cartography_id);

			// We get the branch id
			const branchId = this.getBranchId();

			// We add the rights for selected cartography
			this.addHeadOfficeRights(cartographyId, branchId);

		}

		const headOfficeAdminRights = this.headOfficeAdminRights;

		return headOfficeAdminRights;

	}

	addHeadOfficeRights (cartographyId, branchId) {

		// VIEW EVALUATION
		let viewEvaluationRight = {
			"branch_id": branchId,
			"cartography_id": cartographyId,
			"rights": ["view_evaluation"]
		};

		// EVALUATE CARTOGRAPHY
		let evaluateCartographyRight = {
			"branch_id": branchId,
			"cartography_id": cartographyId,
			"rights": ["evaluate_cartography"]
		};

		// ADMIN USERS
		let adminUsersRight = {
			"branch_id": branchId,
			"cartography_id": cartographyId,
			"rights": ["admin_users"]
		};

		// VIEW ACTIONS PLAN
		let viewActionsPlanRight = {
			"branch_id": branchId,
			"cartography_id": cartographyId,
			"rights": ["view_actions_plan"]
		};

		// MANAGE ACTIONS PLAN
		let manageActionsPlanRight = {
			"branch_id": branchId,
			"cartography_id": cartographyId,
			"rights": ["manage_actions_plan"]
		};

		// ADMIN RISKS
		let adminRisksRight = {
			"cartography_id": cartographyId,
			"all_legal_entities": true,
			"rights": ["admin_risks"]
		};

		// We add all the rights to the profile rights
		this.headOfficeAdminRights.push(viewEvaluationRight, evaluateCartographyRight, adminUsersRight, viewActionsPlanRight, manageActionsPlanRight, adminRisksRight);

	}

	getSubsidiaryAdminRights () {

		// We define rights to add
		const rightsToAdd = [
			"admin_users",
			"evaluate_cartography",
			"view_evaluation",
			"manage_actions_plan"
		];

		// We get clean rights
		let subsidiaryAdminRights = this.getCleanRights(rightsToAdd);

		return subsidiaryAdminRights;

	}

	getEvaluatorRights () {

		// We define rights to add
		const rightsToAdd = [
			"evaluate_cartography",
			"manage_actions_plan"
		];

		// We get clean rights
		let evaluatorRights = this.getCleanRights(rightsToAdd);

		return evaluatorRights;

	}

	getReaderRights () {

		// We define rights to add
		const rightsToAdd = ["view_evaluation"];

		// We get clean rights
		let readerRights = this.getCleanRights(rightsToAdd);

		return readerRights;

	}

	getCleanRights (rightsToAdd) {

		let cleanRights = [];

		// For each rights
		$.each(rightsToAdd, (index, right) => {

			let cleanRight;

			if (
				this.rightContext.legal_entity_id !== null &&
				this.rightContext.legal_entity_id !== "all"
			) {

				cleanRight = {
					"cartography_id": this.rightContext.cartography_id,
					"legal_entity_id": this.rightContext.legal_entity_id,
					"rights": [right]
				};

			} else if (
				this.rightContext.country_id !== null &&
				this.rightContext.country_id !== "all"
			) {

				cleanRight = {
					"cartography_id": this.rightContext.cartography_id,
					"country_id": this.rightContext.country_id,
					"branch_id": this.getBranchId(),
					"rights": [right]
				};

			} else {

				cleanRight = {
					"cartography_id": this.rightContext.cartography_id,
					"branch_id": this.getBranchId(),
					"rights": [right]
				};

			}

			// We add the right to the clean rights array
			cleanRights.push(cleanRight);

		});

		return cleanRights;

	}

	getBranchId () {

		// We define the branch id
		let branchId = null;

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			branchId = this.rightContext.branch_id;

		} else {

			branchId = context.getCurrentBranchId();

		}

		return branchId;

	}

	getCountryId () {

		// We define the country id
		let countryId = null;

		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			countryId = this.rightContext.country_id;

		} else {

			countryId = "all";

		}

		return countryId;

	}

}
export default UserRightsProfilesForm;
