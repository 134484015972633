import html from "./resource.html";
import resources from "Datapoints/Resources";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import Popin from "Components/Popin/Popin.js";
import debug from "Core/Debug.js";

class Resource {

	appendAndFill (cssSelector, data, isAdminButtons) {

		// We show the resource
		$(cssSelector).append(html);

		// We define the resource
		const curResource = $(cssSelector).find(".item-link").last();

		// We add the id
		curResource.attr("data-item-id", data.id);

		let curClassIcon;
		if (data.link_url !== undefined && data.link_url !== null && data.link_url !== "") {

			// We add the link
			curResource.find("a.desc-link").attr("href", data.link_url);

			// We define the icon
			curClassIcon = this.getLinkIcon(data.link_url);

		} else if (data.file_url !== undefined && data.file_url !== null && data.file_url !== "") {

			// We add the link
			curResource.find("a.desc-link").attr("href", data.file_url);

			// We define the icon
			curClassIcon = this.getLinkIcon(data.file_url);

		}

		// We add the icon
		curResource.find("a.desc-link .icon").addClass(`fa-${curClassIcon}`);

		// We add the name
		curResource.find("a.desc-link .text").html(data.name);

		// We add the language
		let htmlFlagResourceLanguage = "";
		if (data.language !== undefined) {

			htmlFlagResourceLanguage = `<span class="flag flag-${data.language}"></span>`;

		}
		curResource.find("a.desc-link .text").prepend(htmlFlagResourceLanguage);

		// We add the filename
		if (data.file_url != null) {

			const curFilename = data.file_url.replace(/^.*[\\\/]/, "");
			curResource.find("a.desc-link .infos-link .filename").html(curFilename);

		}

		// We add the author name
		const authorData = data.author;
		if (authorData != null) {

			const htmlLineAuthor = `${$.i18n("text-add-item-by")} <span class="highlight">${authorData.firstname} ${authorData.lastname}</span>`;
			curResource.find("a.desc-link .infos-link .author .name").html(htmlLineAuthor);

		} else {

			curResource.find("a.desc-link .infos-link .author").html("");

		}

		// We format and add the file creation date
		if (data.file_created_at != null) {

			const convertedFileCreationDate = this.formatDate(data.file_created_at);
			const fileCreationDate = this.getFullDate(convertedFileCreationDate);
			curResource.find("a.desc-link .infos-link .file-creation-date").addClass("active");
			curResource.find("a.desc-link .infos-link .file-creation-date .date").html(fileCreationDate);

		}

		// We add the admin buttons
		if (isAdminButtons) {

			const htmlAdminButtons = "<div class=\"admin-buttons\"><div class=\"btn-admin btn-edit\" title='Modifier la ressource'><span class=\"icon far fa-pencil-alt\"></span></div><div class=\"btn-admin btn-delete\" title='Supprimer la ressource'><span class=\"icon far fa-trash-alt\"></span></div></div>";

			curResource.append(htmlAdminButtons);

		}

		// We trigger the events on button clicks
		curResource.find(".btn-edit").on("click", () => {

			$(this).trigger("edit", [data.id]);

		});
		curResource.find(".btn-delete").on("click", () => {

			$(this).trigger("delete", [data.id]);

		});

	}

	getLinkIcon (urlPath) {

		let classIcon;

		if (urlPath.indexOf(".pdf") > -1) {

			classIcon = "file-pdf";

		} else if (urlPath.indexOf(".xls") > -1 || urlPath.indexOf(".xlsx") > -1) {

			classIcon = "file-excel";

		} else if (urlPath.indexOf(".csv") > -1) {

			classIcon = "file-csv";

		} else if (urlPath.indexOf(".zip") > -1) {

			classIcon = "file-archive";

		} else if (urlPath.indexOf(".mp3") > -1 || urlPath.indexOf(".aiff") > -1 || urlPath.indexOf(".wav") > -1 || urlPath.indexOf(".ogg") > -1) {

			classIcon = "file-audio";

		} else if (urlPath.indexOf(".json") > -1 || urlPath.indexOf(".js") > -1) {

			classIcon = "file-code";

		} else if (urlPath.indexOf(".jpg") > -1 || urlPath.indexOf(".jpeg") > -1 || urlPath.indexOf(".png") > -1 || urlPath.indexOf(".gif") > -1) {

			classIcon = "file-image";

		} else if (urlPath.indexOf(".ppt") > -1 || urlPath.indexOf(".pptx") > -1) {

			classIcon = "file-powerpoint";

		} else if (urlPath.indexOf(".doc") > -1 || urlPath.indexOf(".docx") > -1) {

			classIcon = "file-word";

		} else if (urlPath.indexOf(".mp4") > -1 || urlPath.indexOf(".mov") > -1 || urlPath.indexOf(".avi") > -1 || urlPath.indexOf(".mpeg") > -1 || urlPath.indexOf(".mkv") > -1 || urlPath.indexOf(".wmv") > -1) {

			classIcon = "file-video";

		} else {

			classIcon = "link";

		}

		return classIcon;

	}

	formatDate (date) {

		return date.replace(/^(\d{4})-(\d{2})/, "$2.$1").replace("00:00:00", "");

	}

	getFullDate (date) {

		const curMonth = date.split(".")[0];
		const curYear = date.split(".")[1];

		let strMonth;
		if (curMonth === "01") {

			strMonth = `${$.i18n("label-january")}`;

		} else if (curMonth === "02") {

			strMonth = `${$.i18n("label-february")}`;

		} else if (curMonth === "03") {

			strMonth = `${$.i18n("label-march")}`;

		} else if (curMonth === "04") {

			strMonth = `${$.i18n("label-april")}`;

		} else if (curMonth === "05") {

			strMonth = `${$.i18n("label-may")}`;

		} else if (curMonth === "06") {

			strMonth = `${$.i18n("label-june")}`;

		} else if (curMonth === "07") {

			strMonth = `${$.i18n("label-july")}`;

		} else if (curMonth === "08") {

			strMonth = `${$.i18n("label-august")}`;

		} else if (curMonth === "09") {

			strMonth = `${$.i18n("label-september")}`;

		} else if (curMonth === "10") {

			strMonth = `${$.i18n("label-october")}`;

		} else if (curMonth === "11") {

			strMonth = `${$.i18n("label-november")}`;

		} else if (curMonth === "12") {

			strMonth = `${$.i18n("label-december")}`;

		}

		return String(`${strMonth} `) + curYear;

	}

}
export default Resource;
