import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import html from "./list.html";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import users from "Datapoints/Users.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import "../../Admin.scss";
import "../../AdminResponsive.scss";

class CartographiesList extends Page {

	constructor () {

		const routes = ["/admin/cartographies"];

		const datapointsToLoad = [
			context,
			users,
			cartographies,
			branches,
			legalEntities
		];

		const datapointsToLoadForceRequest = [cartographies];

		super(routes, AuthentifiedLayout, "Liste des cartographies", datapointsToLoad, datapointsToLoadForceRequest);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminCartographies.bind(user)
		];

	}

	render () {

		return new Promise((resolve, reject) => {

			// We get the current language
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);

			// We get the current branch id
			this.branchId = context.getCurrentBranchId();

			this.idCartographyToDelete = null;

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.initListCartographies());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initListCartographies () {

		// We init the create button
		this.initCreateButton();

		if (cartographies.data !== undefined && cartographies.data.length > 0) {

			// We clear container
			$("#cartographies_list").html("");

			// We loop on cartographies
			for (let iCartography = 0; iCartography < cartographies.data.length; iCartography++) {

				let htmlAdminUsers = "";
				let numOtherAdmin = 0;

				const curCartography = cartographies.data[iCartography];
				const curCartographyId = curCartography.id;
				const curCartographyName = curCartography[`name_${$.i18n().locale}`];

				// If the user do not have admin_cartographies right on this branch,
				// or if he do not have admin_users right on this cartography,
				// we skip it
				if (!user.hasRight("admin_cartographies", {"branch_id": curCartography.branch_id}) ||
					!user.hasRight("admin_users", {"cartography_id": curCartography.id})) {

					continue;

				}

				// We loop on users to find users with admin_risks right for this cartography
				$.each(users.data, (index, user) => {

					let isRightAdminRiskForThisCartographyExist;
					if (user.rights !== null && user.rights !== undefined) {

						isRightAdminRiskForThisCartographyExist = user.rights.filter((right) => parseInt(curCartographyId) === parseInt(right.cartography_id) && right.rights.indexOf("admin_risks") >= 0)[0];

					}

					if (isRightAdminRiskForThisCartographyExist !== undefined) {

						const firstnameAdminUser = user.firstname;
						const lastnameAdminUser = user.lastname;

						if (htmlAdminUsers === "") {

							htmlAdminUsers += `<div class='item-admin-user'><div class="icon fas fa-user-cog"></div><div class="admin-user-name"><span class="firstname">${firstnameAdminUser}</span> <span class="lastname">${lastnameAdminUser}</span></div></div>`;

						} else {

							numOtherAdmin += 1;

						}

					}

				});

				// We show the bullet with others cartography managers if necessary
				if (numOtherAdmin > 0) {

					htmlAdminUsers += `<div class='bullet-other-admin'>+${numOtherAdmin}</div>`;

				}

				const cartographyBranchName = branches.find(curCartography.branch_id)[`${$.i18n().locale}`];

				let htmlCartographyBranch = "";

				if (cartographyBranchName !== undefined) {

					htmlCartographyBranch = `<div class='item-branch'><div class="icon fas fa-code-branch"></div><div class="admin-branch-name">${cartographyBranchName}</div></div>`;

				}

				// We add the cartography to the list
				const htmlItemLine = `
					<li>
						<div class="item-line" data-cartography-id="${curCartographyId}">
							<div class='cartography-name'>
								<div class='text'>${curCartographyName}</div>
							</div>
							<div class="list-branch">${htmlCartographyBranch}</div>
							<div class="list-admin-users">${htmlAdminUsers}</div>
							<div class='btn-edit'>
								<i class='icon fas fa-pencil-alt'></i>
							</div>
							<div class='btn-delete'>
								<i class='icon fas fa-trash-alt'></i>
							</div>
						</div>
					</li>`;
				$("#cartographies_list").append(htmlItemLine);

			}

			// We listen the clic on the delete button
			$("#cartographies_list .item-line .btn-delete").on("click", this.handleClickOnDeleteCartographyButton.bind(this));

			// We listen the clic on the edit button
			$("#cartographies_list .item-line").on("click", this.handleClickOnEditCartographyButton.bind(this));

		} else {

			// We show the no cartography message
			this.showNoCartographyMessage();

		}

		if ($("#cartographies_list li").length <= 0) {

			// We show the no cartography message
			this.showNoCartographyMessage();

		}

	}

	showNoCartographyMessage () {

		// We define the message
		const htmlMessage = `<div class="msg-no-item"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span data-i18n="msg-no-cartography">${$.i18n("msg-no-cartography")}</span></div></div>`;

		// We add the message to the container
		$("#cartographies_list").html(htmlMessage);

	}

	initCreateButton () {

		// We check if the current user can add a new cartography
		cartographies.canNewCartographyBeCreatedInSubscription(this.branchId).then((response) => {

			if (response.canACartographyBeCreatedInSubscription) {

				// We define the html for the create button
				const htmlCreateButton = "<div class=\"link btn-create\"><i class=\"icon fas fa-plus\"></i><span class=\"text\" data-i18n=\"admin-users-list-label-btn-add\">Ajouter</span></div>";

				// We show the create button
				$(".btn-top-admin").append(htmlCreateButton);

				// We listen click on create button
				$(".btn-create").on("click", this.handleClickOnCreateButton.bind(this));

			}

		}).catch((error) => {

			debug.v("CartographiesList - initCreateButton - error in Cartographies canNewCartographyBeCreatedInSubscription");

		});

	}

	handleClickOnCreateButton () {

		router.navigate("/admin/cartography/create");

	}

	handleClickOnEditCartographyButton (e) {

		const cartographyId = $(e.currentTarget).attr("data-cartography-id");

		router.navigate(`/admin/cartography/edit/${cartographyId}`);

	}

	handleClickOnDeleteCartographyButton (e) {

		// We prevent the event to bubble to line event
		e.stopPropagation();

		const cartographyId = $(e.currentTarget).parents(".item-line").attr("data-cartography-id");

		this.initDeleteCartography(cartographyId);

	}

	initDeleteCartography (cartographyId) {

		this.idCartographyToDelete = parseInt(cartographyId);

		const curCartography = cartographies.find(cartographyId);

		let curCartographyName;
		if (this.currentLang === "fr" && curCartography.name_fr !== undefined && curCartography.name_fr !== null && curCartography.name_fr !== "") {

			curCartographyName = curCartography.name_fr;

		} else if (this.currentLang === "fr" && (curCartography.name_fr === undefined || curCartography.name_fr === null || curCartography.name_fr === "")) {

			curCartographyName = curCartography.name_en;

		} else if (this.currentLang === "en" && curCartography.name_en !== undefined && curCartography.name_en !== null && curCartography.name_en !== "") {

			curCartographyName = curCartography.name_en;

		} else if (this.currentLang === "en" && (curCartography.name_en === undefined || curCartography.name_en === null || curCartography.name_en === "")) {

			curCartographyName = curCartography.name_fr;

		}

		// Delete popin
		this.deleteCartographyPopin = new Popin("delete-cartography-popin", `${$.i18n("popin-delete-cartography-confirmation-title")} <span class="highlight">${curCartographyName}</span> ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteCartographyCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteCartographyConfirm.bind(this)
			}
		]);
		$(".popin").remove();
		this.deleteCartographyPopin.appendIn("#page");

		this.deleteCartographyPopin.show();

	}

	handleDeleteCartographyCancel () {

		this.deleteCartographyPopin.hide();

	}

	handleDeleteCartographyConfirm () {

		cartographies.deleteOne(this.idCartographyToDelete).then(() => {

			this.deleteCartographyPopin.hide();

			cartographies.getData({"forceRequest": true}).then(() => {

				this.initListCartographies();

			});

		}).catch((error) => {

		});

	}

}
export default new CartographiesList();
