import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Scenarios extends Datapoint {

	find (idToFind) {

		return this.data.find((scenario) => scenario.id == idToFind);

	}

	getData (params) {

		if (params == undefined) {

			debug.e("Error in getData of Scenarios, no params object has been set.");
			return;

		}

		if (params.idRisk == undefined) {

			debug.e("Error in getData of Scenarios, the Risk id is undefined.");
			return;

		}

		let riskId = params.idRisk;

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/scenarios`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	addScenario (scenario, riskId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${riskId}/scenario/create`,
				"data": scenario,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	updateScenario (scenario) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${scenario.get("risk_id")}/scenario/update`,
				"data": scenario,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	deleteScenario (riskId, scenarioId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/scenario/delete/${scenarioId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

}
export default new Scenarios();
