import html from "./help_item.html";

class HelpItem {

	appendAndFill (cssSelector, data) {

		// We show the help item
		$(cssSelector).append(html);

		// We get the current help item
		const curHelpItem = $(cssSelector).find("li.item").last();

		// We add the button title
		curHelpItem.attr("title", data.title);

		// We add the id
		curHelpItem.attr("id", `btn-help-${data.id}`);
		curHelpItem.attr("data-item-id", data.id);

		// We add the icon slug
		curHelpItem.find(".icon").addClass(data.slug_icon);

		// We add the title
		curHelpItem.find(".text").html(data.title);

	}

}
export default HelpItem;
