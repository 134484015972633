import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./edit.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import {showFormErrors, updateObjectWithForm, slugify, getUrlVars} from "Core/helpers.js";

class BusinessActivityEdit extends Page {

	constructor () {

		let routes = [
			"/admin/business-activity/edit/:id",
			"/admin/business-activity/create"
		];

		let datapointsToLoad = [
			users,
			businessActivities,
			cartographies,
			legalEntities,
			context
		];

		super(routes, AuthentifiedLayout, "Modification d'une activité opérationnelle", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminBusinessActivities.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			this.urlVars = getUrlVars();

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.params = params;
			this.idBusinessActivity = null;

			this.parseAndRender("page", html);

			this.editedBusinessActivity = {};
			this.formType = null;

			// We get the current branch id
			this.branchId = context.getCurrentBranchId();

			// We load global events
			this.initEvents();

			// We add translate buttons
			this.addTranslateButtons();

			$("FORM").on("submit", this.handleSubmit.bind(this));

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.addBranchesOptions());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initEvents () {

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			if (this.urlVars[0].indexOf("create") >= 0) {

				this.formType = "create";

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("admin-business-activities-create-title-page")}`);
				const htmlTitleCreate = `<h1 data-i18n="admin-business-activities-create-title-page">${$.i18n("admin-business-activities-create-title-page")}</h1>`;
				$(".title-page").html(htmlTitleCreate);

				// We update the label of the submit button
				const htmlButtonCreate = `<i class="icon fas fa-check"></i><span class="text" data-i18n="button-create">${$.i18n("button-create")}</span>`;
				$(".btn-submit").html(htmlButtonCreate);

			} else if (this.urlVars[0].indexOf("edit") >= 0) {

				this.formType = "edit";

				this.idBusinessActivity = this.params.id;

				this.editedBusinessActivity = businessActivities.find(this.idBusinessActivity);

				this.autoFillPage(this.editedBusinessActivity);
				this.autoFillForm("#edit_business_activity", this.editedBusinessActivity);

			}

		}

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	handleClickOnCancelButton () {

		router.navigate("/admin/business-activities");

	}

	addBranchesOptions () {

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			user.getUserBranchesForAdminBusinessActivitiesRight().then((data) => {

				$(data.branches).each((i, branch) => {

					$("FORM [name=branch_id]").append(`<option value='${branch.id}'>${branch[this.currentLang]}</option>`);

				});

				if (this.formType === "edit") {

					this.autoFillForm("#edit_business_activity", this.editedBusinessActivity);

				}

			}).catch((error) => {

				debug.v("BusinessActivityEdit - addBranchesOptions - error in User getUserBranchesForAdminBusinessActivitiesRight");

			});

		} else {

			// We remove the branch field
			$("#branch-field").remove();

		}

	}

	addTranslateButtons () {

		// Name FR
		const translationButtonForNameFr = new FieldTranslationButton("name_fr", "text", "fr");

		// Name EN
		const translationButtonForNameEn = new FieldTranslationButton("name_en", "text", "en");

	}

	handleSubmit (e) {

		e.preventDefault();
		this.clearFormErrors("edit_business_activity");

		// We get the form values
		this.editedBusinessActivity = updateObjectWithForm(this.editedBusinessActivity, "#edit_business_activity");

		if (process.env.SHOW_BRANCHES === undefined || process.env.SHOW_BRANCHES === false || process.env.SHOW_BRANCHES === "false") {

			this.editedBusinessActivity.branch_id = parseInt(this.branchId);

		}


		if (this.formType === "edit") { // EDIT BUSINESS ACTIVITY

			// Then we update all the fields
			businessActivities.updateOne(this.editedBusinessActivity).then(() => {

				this.editSuccess();

			}, this.editFail.bind(this));

		} else if (this.formType === "create") { // CREATE BUSINESS ACTIVITY

			// First, we add all the fields
			businessActivities.createOne(this.editedBusinessActivity).then((businessActivityCreationData) => {

				this.createSuccess(businessActivityCreationData);

			}, this.editFail.bind(this));

		}

	}

	editSuccess () {

		debug.v("BusinessActivityEdit - editSuccess");

		businessActivities.getData({"forceRequest": true}).then(() => {

			// We redirect to the list business activities page
			router.navigate("/admin/business-activities");

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("BusinessActivityEdit - editSuccess - error in User getData");

			});

		}).catch((error) => {

			debug.v("BusinessActivityEdit - editSuccess - error in BusinessActivities getData");

		});

	}

	createSuccess (creationData) {

		debug.v("BusinessActivityCreate - createSuccess");

		businessActivities.getData({"forceRequest": true}).then(() => {

			router.navigate("/admin/business-activities");

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("BusinessActivityCreate - createSuccess - error in User getData");

			});


		}).catch((error) => {

			debug.v("BusinessActivityCreate - createSuccess - error in BusinessActivities getData");

		});

	}

	editFail (response, textStatus) {

		if (response.status === 422) {

			let errorsToShow = {
				"name_fr": {
					"required": `${$.i18n("form-error-business-activity-name-fr-required")}`,
					"taken": `${$.i18n("form-error-business-activity-name-taken")}`
				},
				"name_en": {
					"required": `${$.i18n("form-error-business-activity-name-en-required")}`,
					"taken": `${$.i18n("form-error-business-activity-name-taken")}`
				},
				"branch_id": {
					"required": `${$.i18n("form-error-branch-required")}`,
					"not_allowed": `${$.i18n("form-error-branch-not-allowed")}`
				}
			};

			showFormErrors("#edit_business_activity", response, errorsToShow);

			// We show the first error
			let positionTopFirstError;
			if ($(".error").eq(0).parents(".section").length > 0) {

				positionTopFirstError = $(".error").eq(0).parents(".section").offset().top;

			} else {

				positionTopFirstError = $(".error").eq(0).parents("li").offset().top;

			}

			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new BusinessActivityEdit();
