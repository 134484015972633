import debug from "Core/Debug.js";

export function showFormErrors (formSelector, response, errorsToShow, errorsToIgnore = []) {

	let {errors} = response.responseJSON;

	for (const fieldName in errors) {

		let errorDiv = "";
		let htmlFieldName = getFieldnameFromLaravelErrorDotName(fieldName);
		let fieldSelector = "";
		errors[fieldName].forEach((error) => {

			if (errorsToShow[htmlFieldName] == undefined) {


				if (errorsToIgnore[htmlFieldName] == undefined) {

					debug.e(`No errors defined for the field '${htmlFieldName}'`);

				}

			} else if (errorsToShow[htmlFieldName][error] == undefined) {


				if (errorsToIgnore[htmlFieldName] == undefined || errorsToIgnore[htmlFieldName][error] == undefined) {

					debug.e(`No error defined for the error '${error}' on the field '${htmlFieldName}'`);

				}

			} else {


				errorDiv += `<div class='error msg-error'>${errorsToShow[htmlFieldName][error]}</div>`;

				if (errorsToShow[htmlFieldName][fieldSelector] != undefined) {

					fieldSelector = errorsToShow[htmlFieldName][fieldSelector];

				} else {

					fieldSelector = `${formSelector} [name="${htmlFieldName}"]`;

				}

			}

		});

		// If it's a radio, we show the error on the parent
		if ($(fieldSelector).attr("type") !== undefined && $(fieldSelector).attr("type") === "radio") {

			$(fieldSelector).parents(".line-radio").siblings(".error").remove();
			$(fieldSelector).parents(".line-radio").after(errorDiv);

		} else if ($(fieldSelector).attr("class") != undefined && $(fieldSelector).attr("class").indexOf("select2") >= 0) { // For select2

			$(fieldSelector).siblings(".error").remove();
			$(fieldSelector).addClass("error");
			$(fieldSelector).siblings(".select2-container").after(errorDiv);

		} else {

			$(fieldSelector).siblings(".error").remove();

			if (JSON.stringify($(fieldSelector).attr("class")) !== undefined && JSON.stringify($(fieldSelector).attr("class")).indexOf("tab-result") >= 0) {

				$(fieldSelector).addClass("tab-result-error").after(errorDiv);

			} else {

				$(fieldSelector).addClass("error").after(errorDiv);

			}

		}

	}

}

function getFieldnameFromLaravelErrorDotName (laravelDotName) {

	let parts = laravelDotName.split(".");
	let fieldName = "";
	let prefix = "";
	let suffix = "";
	parts.forEach((part, i) => {

		if (fieldName != "") {

			prefix = "[";
			suffix = "]";

		}

		fieldName += prefix + part + suffix;

	});

	return fieldName;

}

export function updateObjectWithForm (object, formCssSelector) {

	let selector = `${String(formCssSelector)} INPUT:not(.fake-input), ${formCssSelector} TEXTAREA, ${formCssSelector} SELECT`;
	$(selector).each((i, input) => {

		let fieldName = $(input).attr("name");
		let val = $(input).val();

		// We handle checkboxes and radio
		if (input.type == "checkbox") {

			val = $(input).is(":checked") ? 1 : 0;

		} else if (input.type == "radio") { // Radios

			// We skip the radio if it's not checked
			if (!$(input).is(":checked")) {

				return;

			}

		}

		// We don't want to create an empty value
		if (val != "" || object[fieldName] != undefined) {

			object[fieldName] = val;

		}

	});

	return object;

}

export function slugify (content) {

	return content.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");

}

export function getUrlVars () {

	let vars = [],
		hash;
	let hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
	for (let i = 0; i < hashes.length; i++) {

		hash = hashes[i].split("=");
		vars.push(hash[0]);
		vars[hash[0]] = hash[1];

	}
	return vars;

}

export function removeClassStartingWith (node, filter) {

	node.removeClass((index, className) => (className.match(new RegExp(`\\b${filter}\\S+`, "g")) || []).join(" "));

}

export function getDateFormatted (date) {

	const day = `0${new Date(date).getDate()}`.slice(-2);
	const month = `0${new Date(date).getMonth() + 1}`.slice(-2);
	const year = new Date(date).getFullYear();
	const fullDate = `${day}.${month}.${year}`;

	return fullDate;

}

export function cleanScore (score) {

	let scoreValue;

	if (score !== null && score.average !== undefined) {

		scoreValue = score.average;

	} else {

		scoreValue = score;

	}

	if (scoreValue !== undefined && scoreValue !== null) {

		const convertScore = typeof scoreValue === "string" ? parseFloat(scoreValue.replace(",", ".")) : scoreValue;
		const cleanScore = convertScore.toFixed(1);

		return cleanScore;

	}

}

export function getMarkColor (score) {

	if (score !== undefined && score !== null && score !== "") {

		let color;

		if (score >= 1 && score <= 2.25) {

			color = "green";

		} else if (score > 2.25 && score <= 3.5) {

			color = "yellow";

		} else if (score > 3.5 && score <= 4.75) {

			color = "orange";

		} else if (score > 4.75 && score <= 6) {

			color = "red";

		}

		return color;

	}

}

export function getFontAwesomeIcon (theme) {

	// Font Awesome GraphQL API endpoint
	const apiUrl = "https://api.fontawesome.com/graphql";

	// Font Awesome version
	const version = "6.4.2";

	// GraphQL query
	const query = `
        query {
            search(query: "${theme}", version: "${version}", first: 5) {
                id
            }
        }
    `;

	// Request headers
	const headers = {
		"Content-Type": "application/json",
		"Authorization": "Bearer"
	};

	// We define the icon class
	let iconClass = "";

	// Make a GraphQL request to the Font Awesome API
	return fetch(apiUrl, {
		"method": "POST",
		headers,
		"body": JSON.stringify({query})
	}).
		then((response) => response.json()).
		then((data) => {

			// Check if icons were found
			if (data && data.data && data.data.search && data.data.search.length > 0) {

				// Use the first found icon with the class "fa-solid"
				iconClass = `fa-solid fa-${data.data.search[0].id}`;

			} else {

				// No icon found, use a default icon
				iconClass = "fa-solid fa-question-circle";

			}

			// Return the iconClass
			return iconClass;

		}).
		catch((error) => {

			console.error("Error during the request to the Font Awesome API", error);
			// No icon found, use a default icon
			iconClass = "fa-solid fa-question-circle";
			return iconClass;

		});

}

export function sortUserRightsForProfileDisplay (userRights) {

	// We define a new array for user rights to sort them
	let newUserRights = [];

	// For each user right
	userRights.forEach((right, index) => {

		if (right.country_id !== undefined) {

			// We transform country_id to integer
			right.country_id = parseInt(right.country_id);

			// We delete the branch_id to simplify data sorting
			delete right.branch_id;

		} else if (right.branch_id !== undefined) {

			// We transform branch_id to integer
			right.branch_id = parseInt(right.branch_id);

		} else if (right.legal_entity_id !== undefined) {

			// We transform legal_entity_id to integer
			right.legal_entity_id = parseInt(right.legal_entity_id);

		}

		if (right.cartography_id !== undefined) {

			// We transform cartography_id to integer
			right.cartography_id = parseInt(right.cartography_id);

		}

		if (right.branch_id !== undefined || right.country_id !== undefined || right.legal_entity_id !== undefined) {

			// We push the user right on the newUserRights array
			newUserRights.push(right);

		}

	});

	// // First, we sort the newUserRights array based on branch_id
	newUserRights.sort((a, b) => {

    if ('branch_id' in a && 'branch_id' in b) {
			return a.branch_id - b.branch_id; // Sort by ascending branch_id
    }

	});

	// Then, we sort the newUserRights array based on country_id and legal_entity_id
	newUserRights.sort((a, b) => {

		if (a.country_id && !b.country_id) return -1;
    if (!a.country_id && b.country_id) return 1;
    if (a.legal_entity_id && !b.legal_entity_id) return -1;
    if (!a.legal_entity_id && b.legal_entity_id) return 1;
    return 0;

	});

	return newUserRights;

}

export function replaceSpecialCharacters (inputString) {

	// Map of special ASCII characters and their real values
  const specialCharactersMap = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#39;': "'",
      '&#x2F;': '/',
      '&#x60;': '`',
      '&#x3D;': '=',
      '&Agrave;': 'À',
      '&Aacute;': 'Á',
      '&Acirc;': 'Â',
      '&Atilde;': 'Ã',
      '&Auml;': 'Ä',
      '&Aring;': 'Å',
      '&AElig;': 'Æ',
      '&Ccedil;': 'Ç',
      '&Egrave;': 'È',
      '&Eacute;': 'É',
      '&Ecirc;': 'Ê',
      '&Euml;': 'Ë',
      '&Igrave;': 'Ì',
      '&Iacute;': 'Í',
      '&Icirc;': 'Î',
      '&Iuml;': 'Ï',
      '&ETH;': 'Ð',
      '&Ntilde;': 'Ñ',
      '&Ograve;': 'Ò',
      '&Oacute;': 'Ó',
      '&Ocirc;': 'Ô',
      '&Otilde;': 'Õ',
      '&Ouml;': 'Ö',
      '&Oslash;': 'Ø',
      '&Ugrave;': 'Ù',
      '&Uacute;': 'Ú',
      '&Ucirc;': 'Û',
      '&Uuml;': 'Ü',
      '&Yacute;': 'Ý',
      '&THORN;': 'Þ',
      '&szlig;': 'ß',
      '&agrave;': 'à',
      '&aacute;': 'á',
      '&acirc;': 'â',
      '&atilde;': 'ã',
      '&auml;': 'ä',
      '&aring;': 'å',
      '&aelig;': 'æ',
      '&ccedil;': 'ç',
      '&egrave;': 'è',
      '&eacute;': 'é',
      '&ecirc;': 'ê',
      '&euml;': 'ë',
      '&igrave;': 'ì',
      '&iacute;': 'í',
      '&icirc;': 'î',
      '&iuml;': 'ï',
      '&eth;': 'ð',
      '&ntilde;': 'ñ',
      '&ograve;': 'ò',
      '&oacute;': 'ó',
      '&ocirc;': 'ô',
      '&otilde;': 'õ',
      '&ouml;': 'ö',
      '&oslash;': 'ø',
      '&ugrave;': 'ù',
      '&uacute;': 'ú',
      '&ucirc;': 'û',
      '&uuml;': 'ü',
      '&yacute;': 'ý',
      '&thorn;': 'þ',
      '&yuml;': 'ÿ',
      '&#8364;': '€',
      '&#163;': '£',
      '&#165;': '¥',
      '&#169;': '©',
      '&#174;': '®',
      '&#8482;': '™'
  };

  // Using a regular expression to replace all special characters
  return inputString.replace(/&[a-z]+;|&#\d+;|&#x[\da-f]+;/ig, function(match) {

		// Check if the special character matches a value in the mapping
		return specialCharactersMap[match] || match;

  });
}


