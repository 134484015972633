import Navigo from "navigo";

// We initiate the router
let router = new Navigo(`${location.protocol}//${location.hostname}`, false);

router.refreshLinks = () => {

	// We rewrite links
	let links = document.querySelectorAll("a");
	for (let i = 0; i < links.length; i++) {

		let link = links[i];
		link.addEventListener("click", (e) => {

			let location = e.currentTarget.pathname;

			if (
				link.getAttribute("data-command") !== null &&
				link.getAttribute("data-command") !== undefined
			) {

				e.preventDefault();

			} else if (link.className.indexOf("richText") > -1) {

				e.preventDefault();

			} else if (link.target === "_blank" || link.href.indexOf("mailto:") > -1) {

				// We do nothing in these cases

			} else {

				e.preventDefault();
				router.navigate(location);

			}

		});

	}

};

export default router;
