import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class BusinessActivities extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaningForThisFields = [
			"id",
			"created_at",
			"updated_at",
			"branch_id",
			"has_link_to_legal_entities"
		];

	}

	createOne (businessActivityToAdd) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/business-activity/create",
				"data": businessActivityToAdd
			}).
				done(resolve).
				fail(reject);

		});


	}

	updateOne (businessActivityToUpdate) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/business-activity/update",
				"data": businessActivityToUpdate
			}).
				done(resolve).
				fail(reject);

		});


	}

	deleteOne (businessActivityId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/business-activity/delete/${businessActivityId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			if (this.data != undefined && !forceRequest) {

				resolve();
				return;

			}

			api.call({
				"method": "GET",
				"url": "/business-activities"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	find (idToFind) {

		let businessActivity;
		if (this.data != undefined) {

			businessActivity = this.data.find((businessActivity) => businessActivity.id == idToFind);

		}

		return businessActivity;

	}


}
export default new BusinessActivities();
