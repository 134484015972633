import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class IA extends Datapoint {

	getRiskDetail (title, language, cartographyId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/ai/risk/get-detail",
				"data": {
					title,
					"cartography_id": cartographyId,
					language
				}
			}, false, false).
				done(resolve).
				fail(reject);

		});


	}

	getTranslation (textToTranslate, language) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/ai/translate",
				"data": {
					"text_to_translate": textToTranslate,
					language
				}
			}, false, false).
				done(resolve).
				fail(reject);

		});


	}

}
export default new IA();
