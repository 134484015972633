import debug from "Core/Debug.js";

export default class Layout {

	constructor (html, datapointsToLoad) {

		if (html != undefined) {

			this.html = html;
			this.htmlHash = this.hashHtml(html);

		} else {

			debug.e(`Layouts must define their HTML thrue the call of super() method. It is unset for ${this.constructor.name}`);

		}
		this.datapointsToLoad = datapointsToLoad;


	}

	render (params) {

		debug.v(`4 ======= > ${this.constructor.name} - render in Layout.js`);

		if ($("#global").data("hashLayout") != this.htmlHash) {

			$("#global").html(this.html);
			$("#global").data("hashLayout", this.htmlHash);

		}

		// If the basic login mode is active
		if (process.env.BASIC_LOGIN_ACTIVE === true || process.env.BASIC_LOGIN_ACTIVE === "true") {

			$(".content-login-layout").removeClass("no-login");
			$(".content-login-layout").addClass("basic-login-active");

		} else {

			$(".content-login-layout").removeClass("basic-login-active");
			$(".content-login-layout").addClass("no-login");

		}

	}

	hashHtml () {

		let hash = 0,
			i, chr;
		for (i = 0; i < this.html.length; i++) {

			chr = this.html.charCodeAt(i);
			hash = (hash << 5) - hash + chr;
			hash |= 0; // Convert to 32bit integer

		}

		return hash;

	}

}
