import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Resources extends Datapoint {

	find (idToFind) {

		return this.data.find((resource) => resource.id == idToFind);

	}

	getData (params) {

		if (params == undefined) {

			debug.e("Error in getData of Resources, no params object has been set.");
			return;

		}

		if (params.idRisk == undefined) {

			debug.e("Error in getData of Resources, the Risk id is undefined.");
			return;

		}

		let riskId = params.idRisk;

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/resources`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	addResource (resource, riskId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${riskId}/resource/create`,
				"data": resource,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	updateResource (resource) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${resource.get("risk_id")}/resource/update`,
				"data": resource,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	deleteResource (riskId, resourceId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/resource/delete/${resourceId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

}
export default new Resources();
