import router from "Core/router.js";
import debug from "Core/Debug.js";
import {sortUserRightsForProfileDisplay} from "Core/helpers.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./show.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import risks from "Datapoints/Risks.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import context from "Datapoints/Context.js";
import Popin from "Components/Popin/Popin.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
// IMPORT I18N PLUGIN
import "Core/assets/lib/i18n/jquery.i18n.js";
// IMPORT DATATABLES PLUGIN
import DataTable from "datatables.net-dt";
import dataTableLanguageFR from "Core/assets/lib/datatables/fr-FR.json";
import dataTableLanguageEN from "Core/assets/lib/datatables/en-GB.json";

class UserShow extends Page {

	constructor () {

		let routes = ["/admin/user/show/:id"];

		super(routes, AuthentifiedLayout, "Informations sur l'utilisateur", [
			users,
			cartographies,
			legalEntities,
			branches,
			countries,
			context
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminUsers.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			// We get the current language
			this.currentLang = $.i18n().locale.substring(0, 2);
			this.idUser = params.id;

			this.parseAndRender("page", html);

			let userToShow = users.find(this.idUser);

			// We replace the status with the correct lib
			switch (userToShow.status) {

				case "valid":
					userToShow.status = $.i18n("form-label-field-status-valid");
					break;

				case "deactivated":
					userToShow.status = $.i18n("form-label-field-status-deactivated");
					break;

				case "pending":
					userToShow.status = $.i18n("form-label-field-status-pending");
					break;

				case "tempPassword":
					userToShow.status = $.i18n("form-label-field-status-temp-password");
					break;

			}

			this.autoFillPage(userToShow, "page");
			router.refreshLinks();

			this.showLegalEntityLib(userToShow);
			this.showLanguageLib(userToShow);
			this.showRpoRisks(userToShow);
			this.showEditorRisks(userToShow);
			this.showRights(userToShow);
			this.initDataTable();

			this.initAdminButtons(params);

			// If we are not in an env with IGG
			if (
				process.env.SHOW_IGG !== undefined &&
				(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
			) {

				// We remove the IGG line
				$("#line-field-igg").remove();

			}

			menu.show().then(resolve);

		});

	}

	initAdminButtons (params) {

		this.initDeleteUser();

		// We listen click on back list button
		$(".btn-back-list").on("click", this.handleClickOnBackListButton.bind(this));

		// We listen click on edit button
		$("#btn-update-user").on("click", this.handleClickOnEditButton.bind(this));

	}

	handleClickOnBackListButton () {

		router.navigate("/admin/users");

	}

	handleClickOnEditButton () {

		router.navigate(`/admin/user/edit/${this.idUser}`);

	}

	initDeleteUser () {

		const curUser = users.find(this.idUser);

		// Delete button
		this.deleteUserPopin = new Popin("delete-user-popin", `${$.i18n("popin-delete-user-confirmation-title")} ${curUser.firstname} ${curUser.lastname} ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteUserCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteUserConfirm.bind(this)
			}
		]);
		this.deleteUserPopin.appendIn("#page");

		$("#btn-delete-user").on("click", (event) => {

			this.deleteUserPopin.show();

		});

	}

	handleDeleteUserCancel () {

		this.deleteUserPopin.hide();

	}

	handleDeleteUserConfirm () {

		users.deleteOne(this.idUser).then(() => {

			users.getData({"forceRequest": true}).then(() => {

				router.navigate("/admin/users");

			});

		}).catch((error) => {

			// If the user is RPO
			if (error.status == 422 && error.responseJSON.errors != undefined && error.responseJSON.errors.id != undefined && error.responseJSON.errors.id.rpo_for != undefined) {

				const curUser = users.find(this.idUser);

				this.deleteUserPopin.hide();

				// Popin message
				let popinMsg = `${$.i18n("text-delete-prohibited")} ${curUser.firstname} ${curUser.lastname} ${$.i18n("text-error-delete-rpo-reason")}`;

				// Plural
				popinMsg += error.responseJSON.errors.id.rpo_for.length > 1 ? ` ${$.i18n("text-the-risks")}` : ` ${$.i18n("text-the-risk")}`;

				// We loop on the risk to get their reference
				let riskRefList = "";
				error.responseJSON.errors.id.rpo_for.forEach((riskInfo, i) => {

					if (riskRefList != "") {

						if (i == error.responseJSON.errors.id.rpo_for.length - 1) {

							riskRefList += " et ";

						} else {

							riskRefList += ", ";

						}

					} else {

						riskRefList = " ";

					}

					riskRefList += `${riskInfo.reference}`;

				});
				popinMsg += `${riskRefList}.`;

				// Is RPO popin
				this.isRPOPopin = new Popin("delete-user-but-is-rpo-popin", popinMsg, [
					{
						"title": "Ok",
						"class": "btn-submit",
						"iconClass": "icon fas fa-check",
						"callback": this.closeRPOPopin.bind(this)
					}
				]);
				this.isRPOPopin.appendIn("#page");
				this.isRPOPopin.show();

			} else if (error.status == 422 && error.responseJSON.errors != undefined && error.responseJSON.errors.id != undefined && error.responseJSON.errors.id.risk_where_user_is_task_handler != undefined) { // If the user is task handler

				const curUser = users.find(this.idUser);

				this.deleteUserPopin.hide();

				// Popin message
				let popinMsg = `${$.i18n("text-delete-prohibited")} ${curUser.firstname} ${curUser.lastname} ${$.i18n("text-error-delete-task-handler-reason")}`;

				// Plural
				popinMsg += error.responseJSON.errors.id.risk_where_user_is_task_handler.length > 1 ? ` ${$.i18n("text-the-risks")}` : ` ${$.i18n("text-the-risk")}`;

				// We loop on the risk to get their reference
				let riskRefList = "";
				error.responseJSON.errors.id.risk_where_user_is_task_handler.forEach((riskInfo, i) => {

					if (riskRefList != "") {

						if (i == error.responseJSON.errors.id.risk_where_user_is_task_handler.length - 1) {

							riskRefList += " et ";

						} else {

							riskRefList += ", ";

						}

					} else {

						riskRefList = " ";

					}

					riskRefList += `${riskInfo.reference}`;

				});
				popinMsg += `${riskRefList}.`;

				// Is task handler popin
				this.isTaskHandlerPopin = new Popin("delete-user-but-is-task-handler-popin", popinMsg, [
					{
						"title": "Ok",
						"class": "btn-submit",
						"iconClass": "icon fas fa-check",
						"callback": this.closeTaskHandlerPopin.bind(this)
					}
				]);
				this.isTaskHandlerPopin.appendIn("#page");
				this.isTaskHandlerPopin.show();

			} else {

				debug.e(error);

			}

		});

	}

	closeRPOPopin () {

		this.isRPOPopin.hide();

	}

	closeTaskHandlerPopin () {

		this.isTaskHandlerPopin.hide();

	}

	showLegalEntityLib (userToShow) {

		$(".legal_entity_lib").html(legalEntities.getLib(userToShow.legal_entity_id));

	}

	showLanguageLib (userToShow) {

		let languageLib;

		if (userToShow.language === "fr") {

			languageLib = $.i18n("form-label-field-language-french");

		} else if (userToShow.language === "en") {

			languageLib = $.i18n("form-label-field-language-english");

		} else {

			languageLib = $.i18n("form-label-field-no-language");

		}

		$(".language_lib").html(languageLib);

	}

	showRpoRisks (userToShow) {

		if (
	    userToShow.risks_where_rpo !== undefined &&
	    userToShow.risks_where_rpo !== null &&
	    userToShow.risks_where_rpo.length > 0
		) {

			// We create the html of the new section to show
			const htmlSection = `
				<div id="rpo_risks">
					<div class="section">
						<div class="title">
							<i class="icon fas fa-user-tie"></i>
							<span class="text" data-i18n="form-label-field-rpo">${$.i18n("form-label-field-rpo")}</span>
						</div>
						<div class="list-risks"></div>
					</div>
				</div>
			`;

			// We show the new section
			$(htmlSection).insertAfter("ul.list-fields");

			// We define the risks list selector
			const risksListSelector = $("#rpo_risks .list-risks");

			// For each risk
			userToShow.risks_where_rpo.forEach((risk, index) => {

				// We define the card risk
				const cardRisk = new CardRisk(risk);
				cardRisk.appendAndFillForUserAdmin(risksListSelector, risk);

				// We set click on the risk
				this.setClickOnRisk(risk);

			});

		}

	}

	showEditorRisks (userToShow) {

		if (
	    userToShow.risks_where_editor !== undefined &&
	    userToShow.risks_where_editor !== null &&
	    userToShow.risks_where_editor.length > 0
		) {

			// We create the html of the new section to show
			const htmlSection = `
				<div id="editor_risks">
					<div class="section">
						<div class="title">
							<i class="icon fas fa-user-plus"></i>
							<span class="text" data-i18n="form-label-field-editor">${$.i18n("form-label-field-editor")}</span>
						</div>
						<div class="list-risks"></div>
					</div>
				</div>
			`;

			// We show the new section
			$(htmlSection).insertAfter("ul.list-fields");

			// We define the risks list selector
			const risksListSelector = $("#editor_risks .list-risks");

			// For each risk
			userToShow.risks_where_editor.forEach((risk, index) => {

				// We define the card risk
				const cardRisk = new CardRisk(risk);
				cardRisk.appendAndFillForUserAdmin(risksListSelector, risk);

				// We set click on the risk
				this.setClickOnRisk(risk);

			});

		}

	}

	setClickOnRisk (risk) {

		// We get the cartography id of the risk
		const riskCartographyId = parseInt(risk.cartography_id);

		// We define if user has right to see the risk
		const userHasRightToSeeTheRisk = this.hasRightToSeeThisRisk(riskCartographyId);

		// We define the current risk selector
		const curRiskSelector = $(`.card-risk[data-id-risk=${risk.id}]`);

		// If user has right to see the risk
		if (userHasRightToSeeTheRisk) {

			// We add clickable class on the card risk
			curRiskSelector.addClass("clickable");

			// We listen click on the card risk
			curRiskSelector.click((e) => {

				// We update the current cartography
				cartographies.setCurrentCartographyId(riskCartographyId);

				// We redirect to the risk page
				router.navigate(`/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${riskCartographyId}/risks/${risk.id}/business-activities/all`);

			});

		} else {

			// We add not-allowed class on the card risk
			curRiskSelector.addClass("not-allowed");

			// We define the html for the tooltip
			const htmlTooltipRisk = `
						<div class="tooltip">
							<span class="icon fas fa-exclamation-triangle"></span>
							<span class="text">${$.i18n("tooltip-message-risk-not-allowed")}</span>
						</div>
					`;

			// We add the tooltip on the card risk
			curRiskSelector.append(htmlTooltipRisk);

		}

	}

	hasRightToSeeThisRisk (riskCartographyId) {

		let userHasRight = false;

		const canView = user.hasRight("view_evaluation", {"cartography_id": riskCartographyId});
		const canEvaluate = user.hasRight("evaluate_cartography", {"cartography_id": riskCartographyId});

		if (canView || canEvaluate) {

			userHasRight = true;

		} else {

			userHasRight = false;

		}

		return userHasRight;

	}

	showRights (userToShow) {

		if (userToShow.rights == undefined || userToShow.rights.length == 0) {

			// We show the message
			$("#section_user_rights .line-content .list-admin").html(`<div class='line-result-no-right'>${$.i18n("text-no-right")}</div>`);

		} else {

			this.showUserRights(userToShow);

		}

	}

	showUserRights (userToShow) {

		// We get the sorted user rights
		const sortedUserRights = sortUserRightsForProfileDisplay(userToShow.rights);

		// For each right on the newUserRights array
		sortedUserRights.forEach((right, index) => {

			let contextName;

			if (
				right.legal_entity_id !== undefined &&
					right.legal_entity_id !== null &&
					right.branch_id === undefined &&
					right.country_id === undefined
			) {

				// We get the legal entity name
				contextName = legalEntities.getLib(right.legal_entity_id);

			} else if (
				right.legal_entity_id === undefined &&
					right.branch_id === undefined &&
					right.country_id !== undefined &&
					right.country_id !== null
			) {

				// We get the context data
				const dataContext = countries.data.filter((country) => parseInt(country.id) === parseInt(right.country_id))[0];

				// We get the context name
				contextName = dataContext[this.currentLang];

			} else if (
				right.legal_entity_id === undefined &&
					right.branch_id !== undefined &&
					right.branch_id !== null &&
					right.country_id === undefined
			) {

				// We get the context data
				const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(right.branch_id))[0];

				// We get the context name
				contextName = dataContext.fr;

			} else if (
				right.cartography_id !== undefined &&
					right.rights.indexOf("admin_risks") >= 0
			) {

				const cartographyData = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

				// We get the context data
				const dataContext = branches.data.filter((branch) => parseInt(branch.id) === parseInt(cartographyData.branch_id))[0];

				// We get the context name
				contextName = dataContext.fr;

			}

			// We get the data of the cartography
			const dataCartography = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(right.cartography_id))[0];

			// We get the cartography name
			let cartographyName;
			if (dataCartography !== undefined) {

				cartographyName = dataCartography[`name_${this.currentLang}`];

			} else {

				cartographyName = "";

			}

			// We get the lib of the new right
			const libRight = this.getLibRight(right.rights[0]);

			// We define the html of the line of right
			const htmlLineRight = `
				<tr class="line-result line-result-datatable item-line">
					<td class="info-context context-name highlight">${contextName}</td>
					<td class="info-context cartography-name electric">${cartographyName}</td>
					<td class="info-context right-name list-items" data-right-name="${right.rights[0]}">${libRight}</td>
				</tr>
			`;

		    // We show the line of right
			$("#user_rights_list tbody").append(htmlLineRight);

		});


	}

	initDataTable () {

		// We define columns titles
		$("#user_rights_list thead tr th").eq(0).html($.i18n("table-filter-scope"));
		$("#user_rights_list thead tr th").eq(1).html($.i18n("table-filter-cartography"));
		$("#user_rights_list thead tr th").eq(2).html($.i18n("table-filter-right"));

		// We define table language
		let dataTableLanguage;
		if (this.currentLang === "fr") {

			dataTableLanguage = dataTableLanguageFR;

		} else {

			dataTableLanguage = dataTableLanguageEN;

		}

		// Setup - add a text input to each footer cell
		$("#user_rights_list thead tr").addClass("filters").appendTo("#user_rights_list thead");

		/* eslint-disable */
		$('#user_rights_list').DataTable({
			language: dataTableLanguage,
			orderCellsTop: true,
			lengthMenu: [ 25, 50, 100 ],
  		pageLength: 5,
  		searching: true,
  		paging: false,
  		ordering: true,
  		scrollX: true,
  		autoWidth: true,
  		drawCallback: function( settings ) {

      },
      initComplete: function () {
      	let api = this.api();

    		// We set select filters on the country and cartography columns
        api.columns([0,1,2])
          .every(function (colIdx) {
            // We define the current column
            let column = this;
            // We get the current column title
            let columnTitle = $(column.header()).text();
            // We define the select option text by default
            let defaultSelectOptionText;
            if (colIdx === 1) {
            	defaultSelectOptionText = $.i18n("filter-option-see-all-cartography");
            } else {
            	defaultSelectOptionText = $.i18n("filter-option-see-all-country");
            }
            // We add the title in a container
            $(column.header()).html(`<div class="column-title">${columnTitle}</div>`);
            // We add the select filter on the column header
            let select = $(`<select><option value="">${defaultSelectOptionText}</option></select>`)
              .appendTo($(column.header()))
              .on('change', function () {
                  let val = $.fn.dataTable.util.escapeRegex($(this).val());
                  column.search(val ? val : '', false, false).draw();
              });

            $( select ).click( function(e) {
	          	e.stopPropagation();
	          });

            let addedOptions = [];
            column
              .data()
              .unique()
              .sort()
              .each(function (d, j) {
								let options = d.split(',');
								let i;
                for (i=0; i<options.length; i++) {
                  let val = options[i].trim();

                  if ( ! addedOptions.includes( val )) {
                    addedOptions.push( val );
                  }
                }
              });

              // We add the options on the select
              $.each(addedOptions.sort(), (label, value) => {
              	if(value !== undefined && value !== null && value !== "" && value !== " ") {

              		select.append(`<option value="${value}">${value}</option>`);

              	}
							});
          });
      },
  	});
		/* eslint-enable */

		// We set the right name style
		this.setRightNameStyle();

		// We remove the footer infos
		$("#user_rights_list_info").remove();

	}

	setRightNameStyle () {

		const self = this;

		// Iterate over each element with the specified class
		$(".info-context.right-name").each(function () {

			// We get the right name
			const curRightName = $(this).data("right-name");

			// We get the class of icon of the right
			const classIconRight = self.getClassIconRight(curRightName);

			// We get the right lib
			const curRightLib = $(this).text();

			// We define the html to show
			const htmlItem = `
				<div class="item">
					<span class="icon fas ${classIconRight}"></span>
					<span class="text">${curRightLib}</span>
				</div>
			`;

			// We show the new item
			$(this).html(htmlItem);

		});

	}

	getClassIconRight (right) {

		let classIconRight;

		if (right === "view_evaluation") {

			classIconRight = "fa-eye";

		} else if (right === "evaluate_cartography") {

			classIconRight = "fa-star";

		} else if (right === "admin_users") {

			classIconRight = "fa-users-cog";

		} else if (right === "communicate") {

			classIconRight = "fa-comments";

		} else if (right === "manage_actions_plan") {

			classIconRight = "fa-clipboard-check";

		} else if (right === "view_actions_plan") {

			classIconRight = "fa-mask";

		} else if (right === "admin_risks") {

			classIconRight = "fa-chalkboard-teacher";

		} else if (right === "access_statistics") {

			classIconRight = "fa-chart-pie";

		} else if (right === "admin_cartographies") {

			classIconRight = "fa-cogs";

		} else if (right === "admin_business_activities") {

			classIconRight = "fa-building";

		} else {

			classIconRight = "fa-exclamation-triangle";

		}

		return classIconRight;

	}

	getLibRight (right) {

		let libRight;

		if (right === "view_evaluation") {

			libRight = $.i18n("form-label-field-right-view");

		} else if (right === "evaluate_cartography") {

			libRight = $.i18n("form-label-field-right-evaluate");

		} else if (right === "admin_users") {

			libRight = $.i18n("form-label-field-right-admin-users");

		} else if (right === "communicate") {

			libRight = $.i18n("form-label-field-right-communicate");

		} else if (right === "manage_actions_plan") {

			libRight = $.i18n("form-label-field-right-manage-action-plans");

		} else if (right === "view_actions_plan") {

			libRight = $.i18n("form-label-field-right-view-action-plans");

		} else if (right === "admin_risks") {

			libRight = $.i18n("form-label-field-right-manage-cartography");

		} else if (right === "access_statistics") {

			libRight = $.i18n("form-label-field-right-view-statistics");

		} else if (right === "admin_cartographies") {

			libRight = $.i18n("form-label-field-right-admin-cartographies");

		} else if (right === "admin_business_activities") {

			libRight = $.i18n("form-label-field-right-admin-business-activities");

		} else {

			libRight = $.i18n("form-label-field-right-other");

		}

		return libRight;

	}

}
export default new UserShow();
