import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import Popin from "Components/Popin/Popin.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import html from "./edit.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import users from "Datapoints/Users.js";
import legalEntities from "Datapoints/LegalEntities.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import AutoCompletionButton from "Components/AutoCompletionButton/AutoCompletionButton.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";


class RiskEdit extends Page {

	constructor () {

		let routes = [
			"legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/edit",
			"/cartographies/:idCartography/risks/:idRisk/edit"
		];

		super(routes, AuthentifiedLayout, "Modification d'un risque", [
			context,
			users,
			risks,
			legalEntities,
			cartographies
		]);
		this.rightsValidations = [
			user.getData.bind(user),
			this.canCurrentUserEditThisRisk.bind(this)
		];

	}

	canCurrentUserEditThisRisk (params) {

		return new Promise((resolve, reject) => {

			let risk = risks.find(params.idRisk);

			// We check if current user is a risk editor
			let isCurrentUserRiskEditor;
			if (risk !== undefined && risk.editors !== undefined) {

				isCurrentUserRiskEditor = risk.editors.filter((editor) => parseInt(editor.id) === parseInt(user.data.id))[0];

			}

			if (
				user.hasRight("admin_risks", {"cartography_id": params.idCartography}) ||
				risk != undefined && risk.rpo_id == user.data.id ||
				isCurrentUserRiskEditor !== undefined
			) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		menu.show();

		this.params = params;

		this.editedRisk = risks.find(params.idRisk);

		this.parseAndRender("page", html);

		$("FORM").on("submit", this.handleSubmit.bind(this));

		// We get the previous page URL
		this.previousPage = localStorage.getItem("navigation-previousPageUrl");

		// We define the id of the current cartography
		this.idCartography = params.idCartography;

		// We define the current cartography data
		this.cartographyData = cartographies.find(this.idCartography);

		// We define the risk editors
		this.dataRiskEditors = [];

		// We define the users list for editors
		this.usersListForEditors = users.data;

		// We define the id of the current risk
		this.idRisk = params.idRisk;

		// We define if the current user is admin risks
		this.isCurrentUserAdminRisks = user.hasRight("admin_risks", {"cartography_id": this.params.idCartography});

		// We define if the current user is RPO
		this.isCurrentUserRPO = user.isRPO(this.idRisk);

		// We define if the current user is risk editor
		this.isCurrentUserRiskEditor = user.isRiskEditor(this.idRisk);

		// We define the id of current legal entity
		this.idLegalEntity = context.getCurrentLegalEntityId();

		// We initialize the page events
		this.initEvents();

		// We initialize the theme list
		this.initThemes();

		// We initialize the RPO selector
		this.initRPO();

		// We initialize the editors
		this.initEditors();

		// We initialize the others risk list
		this.initOtherPotentialRisk();

		// We update page content if we edit a pending risk
		this.initPendingRiskEdit();

		// We add translate buttons
		this.addTranslateButtons();

		// We add auto completion buttons
		if (process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === true || process.env.SHOW_OPENAI_AUTO_COMPLETION_BUTTON === "true") {

			this.addAutoCompletionButtons();

		}

	}

	initEvents () {

		// We initialize the rich text editor
		this.initTextEditor();

		// We add the link on cancel button
		if (this.previousPage !== undefined) {

			$("#edit_risk .btn-cancel").attr("href", this.previousPage);

		} else if (this.params.idLegalEntity !== undefined) {

			$("#edit_risk .btn-cancel").attr("href", `/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all`);

		} else {

			$("#edit_risk .btn-cancel").attr("href", `/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all`);

		}

	}

	initTextEditor () {

		// Description Editor FR
		ClassicEditor.
	    create(document.querySelector("#description-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorDescriptionFr = editor;
	      editor.setData(this.editedRisk.description_fr);


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

		// Description Editor EN
		ClassicEditor.
	    create(document.querySelector("#description-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditorDescriptionEn = editor;
	      editor.setData(this.editedRisk.description_en);


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	addTranslateButtons () {

		// Title FR
		const translationButtonForTitleFr = new FieldTranslationButton("title_fr", "text", "fr");

		// Title EN
		const translationButtonForTitleEn = new FieldTranslationButton("title_en", "text", "en");

		setTimeout(() => {

			// Description FR
			const translationButtonForDescriptionFr = new FieldTranslationButton("description_fr", "textarea", "fr", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

			// Description EN
			const translationButtonForDescriptionEn = new FieldTranslationButton("description_en", "textarea", "en", this.richTextEditorDescriptionFr, this.richTextEditorDescriptionEn);

		}, 100);

	}

	addAutoCompletionButtons () {

		setTimeout(() => {

			// Description FR
			const autoCompletionForDescriptionFr = new AutoCompletionButton("title_fr", "description_fr", this.richTextEditorDescriptionFr, this.cartographyData);

			// Description EN
			const autoCompletionForDescriptionEn = new AutoCompletionButton("title_en", "description_en", this.richTextEditorDescriptionEn, this.cartographyData);

		}, 100);

	}

	initThemes () {

		let cartography = cartographies.find(this.idCartography);

		let themesOptions = "<option disabled></option>";
		$(cartography.themes).each((i, theme) => {

			let themeLib = theme.lib[this.currentLang];

			themesOptions += `<option value=${theme.slug}>${themeLib}</option>`;

		});

		$("select[name='theme']").html(themesOptions);

	}

	initRPO () {

		// We display the RPO section
		this.displayRPOSection();

		// We load the possible RPOs
		this.addRPOOptions();

	}

	initEditors () {

		// We display the editors section
		this.displayEditorsSection();

		// We autofill the editors section
		this.autoFillRiskEditors();

		// We define editors selector
		this.setEditorsSelector();

		// We listen click on the delete editor button
		$("#section_risk_editors .tab-result .label-result .btn-delete-editor").on("click", this.handleClickOnDeleteEditorButton.bind(this));

		// We listen change on the editor select
		$("#section_risk_editors select").on("change", this.handleChangeOnEditorSelect.bind(this));

		// We listen click on the add editor button
		$("#btn-add-editor").on("click", this.handleClickOnAddEditorButton.bind(this));

	}

	displayRPOSection () {

		// If the current user is RPO or editor
		if (
			!this.isCurrentUserAdminRisks &&
			(this.isCurrentUserRPO || this.isCurrentUserRiskEditor)
		) {

			// We remove the RPO section
			$("#risk_rpo").remove();

		}

	}

	displayEditorsSection () {

		// If the current user is an editor
		if (!this.isCurrentUserAdminRisks && !this.isCurrentUserRPO && this.isCurrentUserRiskEditor) {

			// We remove the editors section
			$("#risk_editors").remove();

		}

	}

	setEditorsSelector () {

		let editorsOptions = "<option></option>";
		$(this.usersListForEditors).each((i, curUser) => {

			let editorId = curUser.id;
			let editorName = curUser.standard_name;

			editorsOptions += `<option value=${editorId}>${editorName}</option>`;

		});

		$("select[name='editor_id']").html(editorsOptions);

	}

	autoFillRiskEditors () {

		$(this.editedRisk.editors).each((i, curEditor) => {

			// We get current editor id
			const curEditorId = parseInt(curEditor.id);

			// We update the users list for editors
			this.usersListForEditors = this.usersListForEditors.filter((user) => parseInt(user.id) !== curEditorId);

			// We get the current editor data
			const curEditorData = users.find(curEditorId);

			// We get the current editor fullname
			const curEditorFullname = curEditorData.standard_name;

			// We save the editors id in the data risk editors
			this.dataRiskEditors.push(curEditorId);

			// We get the index of current editor in the users list
			const editorIndex = users.data.findIndex((user) => user.id === curEditorId);

			// We define the html of the new editor label
			const htmlLabelEditor = `
				<div class="label-result" data-editor-id="${curEditorId}" data-index-risk-editor="${editorIndex}">
					<div class="info-context">
						<span class="icon fas fa-user"></span>
						<span class="name-context">${curEditorFullname}</span>
					</div>
					<div class="other-info">
						<div class="btn-delete-editor">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
			`;

			// We show the label of the new editor
			$("#section_risk_editors .tab-result").prepend(htmlLabelEditor);


		});


	}

	handleClickOnDeleteEditorButton (e) {

		// We get the current editor id
		const curEditorId = parseInt($(e.currentTarget).parents(".label-result").attr("data-editor-id"));

		// We get the current editor data
		const curEditorData = users.find(curEditorId);

		// We get the index of the current editor
		const curIndexEditor = parseInt($(e.currentTarget).parents(".label-result").attr("data-index-risk-editor"));

		// We add the editor in the users list for editors
		this.usersListForEditors.splice(curIndexEditor, 0, curEditorData);

		// We update editors selector
		this.setEditorsSelector();

		// We delete the editor in the array of list of editors
		this.dataRiskEditors = this.dataRiskEditors.filter((idEditor) => idEditor !== curEditorId);

		// We delete the label of the editor
		$(e.currentTarget).parents(".label-result").remove();

	}

	handleChangeOnEditorSelect (e) {

		if ($("#section_risk_editors select[name='editor_id']").val() !== "" && $("#section_risk_editors select[name='editor_id']").val() !== "") {

			// We active the add button
			$("#btn-add-editor").removeClass("inactive");

		} else {

			// We inactive the add button
			$("#btn-add-editor").addClass("inactive");

		}

	}

	handleClickOnAddEditorButton () {

		if (!$("#btn-add-editor").hasClass("inactive")) {

			// We get new editor id
			const newEditorId = parseInt($("#section_risk_editors select[name='editor_id']").val());

			// We update the users list for editors
			this.usersListForEditors = this.usersListForEditors.filter((user) => parseInt(user.id) !== newEditorId);

			// We update editors selector
			this.setEditorsSelector();

			// We get the new editor data
			const newEditorData = users.find(parseInt(newEditorId));

			// We get the new editor fullname
			const newEditorFullname = newEditorData.standard_name;

			// We add the editor on the editors array
			this.dataRiskEditors.push(newEditorId);

			// We get the index of current editor in the users list
			const editorIndex = users.data.findIndex((user) => user.id === newEditorId);

			// We define the html of the new editor label
			const htmlLabelEditor = `
				<div class="label-result" data-editor-id="${newEditorId}" data-index-risk-editor="${editorIndex}">
					<div class="info-context">
						<span class="icon fas fa-user"></span>
						<span class="name-context">${newEditorFullname}</span>
					</div>
					<div class="other-info">
						<div class="btn-delete-editor">
							<span class="icon fas fa-trash-alt"></span>
						</div>
					</div>
				</div>
			`;

			// We show the label of the new editor
			$("#section_risk_editors .tab-result").prepend(htmlLabelEditor);

			// We listen the click on the delete editor button
			$(`#section_risk_editors .tab-result .label-result[data-editor-id='${newEditorId}'] .btn-delete-editor`).on("click", this.handleClickOnDeleteEditorButton.bind(this));

			// We clear the add editor form
			this.clearAddEditorForm();

		}

	}

	clearAddEditorForm () {

		// We clear the fields
		$("#section_risk_editors select[name='editor_id']").val("");

		// We clear the add editor button
		$("#btn-add-editor").addClass("inactive");

	}

	initOtherPotentialRisk () {

		let cartography = cartographies.find(this.idCartography);

		let otherPotentialRiskHTML = "";
		$(cartography.other_potential_risks).each((i, opr) => {

			otherPotentialRiskHTML += `
				<div class="line-checkbox">
					<div class="item-checkbox">
            <input id="consequences[${opr.slug}]" type="checkbox" name="consequences[${opr.slug}]" value="1" />
						<label for="consequences[${opr.slug}]">
              <i class="icon ${opr.icon}"></i>
              <span class="text">${opr.lib[this.currentLang]}</span>
            </label>
					</div>
        </div>`;

		});

		$(".box-consequencies").append(otherPotentialRiskHTML);

	}

	initPendingRiskEdit () {

		if (this.editedRisk.status === "pending") {

			// We update the title of the page
			$("head").find("title").html($.i18n("risk-validate-title-page"));
			$(".title-page h1").attr("data-i18n", "");
			$(".title-page h1").html($.i18n("risk-validate-title-page"));

			// We show the contact author button
			this.showContactAuthorButton();

			// We remove cancel button
			$(".btn-cancel").remove();

			// We show back button
			$(".btn-back").addClass("active");
			$(".btn-back").on("click", this.handleClickOnBackButton.bind(this));

			// We change the submit button
			$(".btn-submit .icon").removeClass("fa-edit");
			$(".btn-submit .icon").addClass("fa-save");
			$(".btn-submit .text").attr("data-i18n", "form-label-btn-save");
			$(".btn-submit .text").html($.i18n("form-label-btn-save"));

			// We show the delete button
			$("#btn-delete-risk").addClass("active");
			this.initPopinDeleteRisk();

			// We show the validate button
			$("#btn-risk-validate").addClass("active");
			$("#btn-risk-validate").on("click", this.handleClickOnBtnRiskValidate.bind(this));

			// We had separator on last buttons line
			$(".line-buttons-bottom").last().addClass("last-buttons");

		} else {

			// We remove last buttons line
			$(".line-buttons-bottom").last().remove();

		}

	}

	initPopinDeleteRisk () {

		this.deletePopin = new Popin("delete-risk-popin", `${$.i18n("popin-delete-risk-confirmation-title")} ${this.editedRisk.reference} ?`, [
			{
				"title": `${$.i18n("button-cancel")}`,
				"class": "btn-cancel",
				"iconClass": "icon fas fa-times",
				"callback": this.handleDeleteCancel.bind(this)
			},
			{
				"title": `${$.i18n("button-delete")}`,
				"class": "btn-submit",
				"iconClass": "icon fas fa-check",
				"callback": this.handleDeleteConfirm.bind(this)
			}
		]);
		this.deletePopin.appendIn("#page");

		$("#btn-delete-risk").on("click", (event) => {

			this.deletePopin.show();

		});

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteConfirm (e) {

		this.updateRiskBeforeDelete().then(() => {

			this.deleteRisk();

		}).catch(debug.e);

	}

	updateRiskBeforeDelete (e) {

		return new Promise((resolve, reject) => {

			this.clearFormErrors("edit_risk");

			this.editedRisk = updateObjectWithForm(this.editedRisk, "#edit_risk");

			this.editedRisk.description_fr = this.richTextEditorDescriptionFr.getData();
			this.editedRisk.description_en = this.richTextEditorDescriptionEn.getData();

			// We reset consequences so the form replaces them
			this.editedRisk.consequences = [];

			this.editedRisk.risk_id = this.idRisk;
			this.editedRisk.cartography_id = this.idCartography;

			// We add the current user as moderator of the risk
			this.editedRisk.this_is_a_moderation = true;
			this.editedRisk.moderator_id = user.data.id;

			risks.updateOne(this.editedRisk).then(() => {}).catch(debug.e);


			resolve();
			reject();

		});

	}

	deleteRisk () {

		// We delete the risk
		risks.deleteOne(this.editedRisk.id).then(() => {

			router.navigate("/dashboard-grid");

		}).catch(debug.e);

	}

	showContactAuthorButton () {

		// We get author email
		let authorEmail = "";
		if (this.editedRisk.author_id !== undefined && this.editedRisk.author_id !== null) {

			const author = users.find(parseInt(this.editedRisk.author_id));

			authorEmail = author.email;

		}

		// We define email subject for contact author button
		const authorEmailSubject = `${user.data.firstname} ${user.data.lastname} ${$.i18n("risk-subject-email-contact-author")}`;

		// We define risk titles (FR and EN)
		let curRiskTitleFr;
		let curRiskTitleEn;
		let separatorRiskTitle;

		if (this.editedRisk.title_fr !== "" && this.editedRisk.title_fr !== null && this.editedRisk.title_fr !== undefined) {

			curRiskTitleFr = this.editedRisk.title_fr;

		} else {

			curRiskTitleFr = "";

		}

		if (this.editedRisk.title_en !== "" && this.editedRisk.title_en !== null && this.editedRisk.title_en !== undefined) {

			curRiskTitleEn = this.editedRisk.title_en;

		} else {

			curRiskTitleEn = "";

		}

		if (curRiskTitleFr === "" || curRiskTitleEn === "") {

			separatorRiskTitle = "";

		} else {

			separatorRiskTitle = "/";

		}

		// We define email body for contact author button
		const breakLine = "%0D%0A";
		const authorEmailBody = `----${breakLine}${$.i18n("risk-body-email-contact-author")} ${curRiskTitleFr} ${separatorRiskTitle} ${curRiskTitleEn}${breakLine}-----`;

		// We update the link on the button
		$("#btn-contact-author a").attr("href", `mailto:${authorEmail}?subject=${authorEmailSubject}&body=${authorEmailBody}`);

		// We show the button
		$("#btn-contact-author").addClass("active");

	}

	handleClickOnBackButton (e) {

		e.preventDefault();

		// We redirect to the previous page
		if (this.previousPage !== undefined) {

			router.navigate(this.previousPage);

		} else {

			router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${this.idRisk}/business-activities/all`);

		}

	}


	handleClickOnBtnRiskValidate (e) {

		// We add the current user as moderator of the risk
		this.editedRisk.this_is_a_moderation = true;

		// We change status
		this.editedRisk.status = "valid";

		this.handleSubmit(e);

	}

	addRPOOptions () {

		cartographies.getPossibleRPOForCartography(this.idCartography).then((users) => {

			$(users).each((i, userToAdd) => {

				$("FORM [name=rpo_id]").append(`<option value='${userToAdd.id}'>${userToAdd.standard_name}</option>`);

			});

			this.autoFillForm("#edit_risk", this.editedRisk);

		}).catch((error) => {

			debug.v("RiskCreate - addRPOOptions - error in User getPossibleRPOForCartography");

		});

		// We show the current cartography name on RPO tooltip
		let cartography = cartographies.find(this.idCartography);
		$("#tooltip-name-cartography").html(cartography.name);

		// We listen change on RPO select
		$("FORM [name=rpo_id]").on("change", this.handleChangeRPOSelect.bind(this));

	}

	handleChangeRPOSelect () {

		// We get the id of the selected user
		const rpoId = $("FORM [name=rpo_id]").val();

		cartographies.getPossibleRPOForCartography(this.idCartography).then((users) => {

			$(users).each((i, userToCheck) => {

				if (parseInt(rpoId) == parseInt(userToCheck.id)) {

					if (userToCheck.rights_that_will_be_added !== undefined && userToCheck.rights_that_will_be_added !== null) {

						if (userToCheck.rights_that_will_be_added.rights.indexOf("view_evaluation") > -1) {

							const cartographyName = cartographies.find(this.idCartography)[`name_${$.i18n().locale}`];

							// We define the message to show
							const htmlUserMessage = `<div class="msg-user"><div class="icon fas fa-exclamation-triangle"></div><div class="text">${$.i18n("text-message-right-that-will-be-added-part-1")} <span class="highlight">${cartographyName}</span> ${$.i18n("text-message-right-that-will-be-added-part-2")}</div></div>`;

							// We show a message after the select
							$("FORM [name=rpo_id]").parents("li").find(".msg-user").remove();
							$("FORM [name=rpo_id]").parents("li").append(htmlUserMessage);

						}

					} else {

						$("FORM [name=rpo_id]").parents("li").find(".msg-user").remove();

					}

				}

			});

		}).catch((error) => {

			debug.v("RiskEdit - handleChangeRPOSelect - error in User getPossibleRPOForCartography");

		});

	}

	handleSubmit (e) {

		debug.v("RiskEdit - handleSubmit");

		e.preventDefault();

		// If the help mode is active
		if ($("body").attr("class").indexOf("help-mode-active") >= 0) {

			// We remove the help tooltip
			helpSequence.hideTooltip();

			// We hide the help mode overlay
			helpSequence.hideHelpMode();

			// We remove the help informations saved on the local storage
			helpSequence.removeLocalStorageInfos();

		}

		this.clearFormErrors("edit_risk");

		this.editedRisk = updateObjectWithForm(this.editedRisk, "#edit_risk");

		this.editedRisk.description_fr = this.richTextEditorDescriptionFr.getData();
		this.editedRisk.description_en = this.richTextEditorDescriptionEn.getData();

		// We reset consequences so the form replaces them
		this.editedRisk.consequences = [];

		this.editedRisk.risk_id = this.idRisk;
		this.editedRisk.cartography_id = this.idCartography;

		// We update the editors_id array (and we delete the editors data used for reading only)
		delete this.editedRisk.editors;
		delete this.editedRisk.editors_infos;
		this.editedRisk.editors_ids = this.dataRiskEditors;

		risks.updateOne(this.editedRisk).then(this.updateSuccess.bind(this), this.updateFail.bind(this));

	}

	updateSuccess (response) {

		debug.v("RiskEdit - updateSuccess");

		risks.getData(this.params).then(() => {

			if (this.editedRisk.status === "pending") {

				router.navigate("/dashboard-grid");

			} else if (this.previousPage !== undefined) { // We redirect to the previous page

				router.navigate(this.previousPage);

			} else {

				router.navigate(`/legal-entities/${this.idLegalEntity}/cartographies/${this.idCartography}/risks/${response.id}/business-activities/all`);

			}

		}).catch((error) => {

			debug.v("RiskEdit - updateSuccess - error in Risks getData");

		});

	}

	updateFail (response, textStatus) {

		debug.v("RiskEdit - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"title_fr": {
					"required": `${$.i18n("form-error-title-fr-required")}`
				},
				"title_en": {
					"required": `${$.i18n("form-error-title-en-required")}`
				},
				"description_fr": {
					"required": `${$.i18n("form-error-description-fr-required")}`
				},
				"description_en": {
					"required": `${$.i18n("form-error-description-en-required")}`
				},
				"theme": {
					"required": `${$.i18n("form-error-theme-required")}`
				},
				"rpo_id": {
					"required": `${$.i18n("form-error-rpo-required")}`
				},
				"origin": {
					"required": `${$.i18n("form-error-origin-required")}`
				}
			};

			showFormErrors("#edit_risk", response, errorsToShow);

			// We show the first error
			const positionTopFirstError = $(".error").eq(0).parents("li").offset().top;
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new RiskEdit();
