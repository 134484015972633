import router from "Core/router.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import userRightsForm from "Components/UserRightsForm/UserRightsForm.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import html from "./edit.html";
import users from "Datapoints/Users.js";
import user from "Datapoints/User.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import context from "Datapoints/Context.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import debug from "Core/Debug.js";
import {showFormErrors, updateObjectWithForm, getUrlVars} from "Core/helpers.js";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";

class UserEdit extends Page {

	constructor () {

		let routes = [
			"/admin/user/edit/:id",
			"/admin/user/create"
		];

		let datapointsToLoad = [
			users,
			legalEntities,
			cartographies,
			branches,
			countries,
			context
		];

		super(routes, AuthentifiedLayout, "Modification d'un utilisateur", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAdminUsers.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			this.urlVars = getUrlVars();

			this.params = params;
			this.idUser = null;

			this.parseAndRender("page", html);

			this.editedUser = {};
			this.formType = null;

			// We load global events
			this.initEvents();

			$("FORM").on("submit", this.handleSubmit.bind(this));

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.addLegalEntitiesOptions());

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initEvents () {

		if (
			process.env.SHOW_IGG !== undefined &&
				(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
		) {

			// We remove the IGG line
			$("#line-field-igg").remove();

		}

		// If the basic login mode is active
		if (process.env.BASIC_LOGIN_ACTIVE === true || process.env.BASIC_LOGIN_ACTIVE === "true") {

			// We show elements
			$(".form-admin").addClass("basic-login-active");

		}

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			if (this.urlVars[0].indexOf("create") >= 0) {

				this.formType = "create";

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("admin-users-create-title-page")}`);
				const htmlTitleCreate = `<h1 data-i18n="admin-users-create-title-page">${$.i18n("admin-users-create-title-page")}</h1>`;
				$(".title-page").html(htmlTitleCreate);

				// We update the label of the submit button
				const htmlButtonCreate = `<i class="icon fas fa-check"></i><span class="text" data-i18n="button-create">${$.i18n("button-create")}</span>`;
				$(".btn-submit").html(htmlButtonCreate);

				// We hide the new password field
				$("[name='new_password']").parents("LI").hide();

			} else if (this.urlVars[0].indexOf("edit") >= 0) {

				this.formType = "edit";

				// We hide the password field
				$("[name='password']").parents("LI").hide();

				this.idUser = this.params.id;

				this.editedUser = users.find(this.idUser);

				this.autoFillPage(this.editedUser);
				this.initNewPasswordField();

			}

		}

		this.rightForm = new userRightsForm(this.editedUser, "#rights");

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	handleClickOnCancelButton () {

		// We remove user rights saved in the local storage
		localStorage.removeItem("editedUser-currentRights");

		if (this.formType === "edit") {

			// We redirect to the user show page
			router.navigate(`/admin/user/show/${this.idUser}`);

		} else if (this.formType === "create") {

			// We redirect to the users list page
			router.navigate("/admin/users");

		}

	}

	addLegalEntitiesOptions () {

		user.getLegalEntitiesUserCanAdminUsersForRights().then((legalEntities) => {

			// We load legal entities
			if (legalEntities !== undefined) {

				legalEntities.forEach((legalEntity, index) => {

					// We define the option
					let htmlOption;
					if (legalEntity === "all") {

						htmlOption = `<option value=\"all\">${$.i18n("labelAllLegalEntities")}</option>`;

					} else {

						let htmlTelaNumber = "";

						if (legalEntity.tela_number !== undefined && legalEntity.tela_number !== null && legalEntity.tela_number !== "") {

							htmlTelaNumber = `(${legalEntity.tela_number})`;

						}

						htmlOption = `<option value="${legalEntity.id}">${legalEntity.name} ${htmlTelaNumber}</option>`;

					}

					if (index === 0) {

						// We add first an empty option
						$("SELECT[name='legal_entity_id']").append("<option value=''></option>");

						// We add the option on the select
						$("SELECT[name='legal_entity_id']").append(htmlOption);

					} else {

						// We add the option on the select
						$("SELECT[name='legal_entity_id']").append(htmlOption);

					}

				});

				// Initialize select 2
				$("#legal-entity").select2({
					"language": "fr"
				});

				// We put the placeholder on the input search of the select2
				$("#legal-entity").one("select2:open", (e) => {

					$("input.select2-search__field[aria-controls='select2-legal-entity-results']").prop("placeholder", `${$.i18n("form-placeholder-legal-entity")}`);

				});

				// Customize select 2
				$("#line-field-legal-entity .select2-selection__arrow").find("b").hide();
				$("#line-field-legal-entity .select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");

			}

			if (this.formType === "edit") {

				// We get the current legal entity
				const curLegalEntity = legalEntities.filter((legalEntity) => parseInt(legalEntity.id) === parseInt(this.editedUser.legal_entity_id))[0];

				// We show the name of the current legal entity
				const curlegalEntityLib = curLegalEntity.tela_number != undefined ? `${curLegalEntity.name} (${curLegalEntity.tela_number})` : `${curLegalEntity.name}`;
				$("#legal-entity").val(curlegalEntityLib);
				$("#line-field-legal-entity .select2-selection__rendered").html(curlegalEntityLib);
				$("#line-field-legal-entity .select2-selection__rendered").attr("title", curlegalEntityLib);

				// We auto fill the edit user form
				this.autoFillForm("#edit_user", this.editedUser);

			}

		}).catch((error) => {

			debug.v("UserEdit - addLegalEntitiesOptions - error in User getLegalEntitiesUserCanAdminUsersForRights");

		});

	}

	initNewPasswordField () {

		// We hide the new password field
		$("[name='new_password']").parents("LI").hide();

		// We listen to the status change to show the new password field on "tempPassword" status
		$("[name='status']").on("change", this.handleChangeOnStatus.bind(this));

	}

	handleChangeOnStatus (e) {

		let selectedValue = $(e.currentTarget).val();

		if (selectedValue == "tempPassword") {

			$("[name='new_password']").parents("LI").show();

		} else {

			$("[name='new_password']").parents("LI").hide();
			$("[name='new_password']").val("");

		}

	}

	handleSubmit (e) {

		e.preventDefault();

		// We hide all errors
		$("FORM .main-error").remove();
		$("FORM .msg-error").remove();

		// If the help mode is active
		if ($("body").attr("class").indexOf("help-mode-active") >= 0) {

			// We remove the help tooltip
			helpSequence.hideTooltip();

			// We hide the help mode overlay
			helpSequence.hideHelpMode();

			// We remove the help informations saved on the local storage
			helpSequence.removeLocalStorageInfos();

		}

		this.clearFormErrors("edit_user");

		this.editedUser = updateObjectWithForm(this.editedUser, "#edit_user");

		/* This is to be uncommented to test updating a user on an environnement
		where IGG is not shown (such as TotalEnergies with digital pass).
		Without this commented, the IGG then become null and the user can't connect anymore.
		The test should fail with this uncommented and then only this commented part can be removed.
		if (
			process.env.SHOW_IGG !== undefined &&
				(process.env.SHOW_IGG === false || process.env.SHOW_IGG === "false")
		) {

			this.editedUser.igg = "null";

		}*/

		if (this.formType === "edit") {

			users.updateOne(this.editedUser).then(this.editSuccess.bind(this), this.editFail.bind(this));

		} else if (this.formType === "create") {

			users.createOne(this.editedUser).then(this.editSuccess.bind(this), this.editFail.bind(this));

		}

	}

	editSuccess () {

		users.getData({"forceRequest": true}).then(() => {

			// We remove user rights saved in the local storage
			localStorage.removeItem("editedUser-currentRights");

			// If the edited user is the current user
			if (this.editedUser.id == user.data.id) {

				// We reset the cache for the evaluation right of the current user
				user.resetCurrentUserEvaluationRightCache();

				// We reload him
				user.getData({"forceRequest": true}).then(() => {

					this.redirectAfterSuccess();

				});

			} else {

				this.redirectAfterSuccess();

			}

		});

	}

	redirectAfterSuccess () {

		// We update the menu
		menu.update();

		if (this.formType === "edit") {

			// We redirect to the user show page
			router.navigate(`/admin/user/show/${this.idUser}`);

		} else if (this.formType === "create") {

			// We redirect to the users list page
			router.navigate("/admin/users");

		}

	}

	editFail (response, textStatus) {

		if (response.status == 422) {

			let errorsToShow = {
				"igg": {
					"required": `${$.i18n("form-error-igg-required")}`,
					"bad_format": `${$.i18n("form-error-igg-bad-format")}`,
					"taken": `${$.i18n("form-error-igg-taken")}`
				},
				"legal_entity_id": {
					"required": `${$.i18n("form-error-legal_entity_id-required")}`
				},
				"firstname": {
					"required": `${$.i18n("form-error-firstname-required")}`
				},
				"lastname": {
					"required": `${$.i18n("form-error-lastname-required")}`
				},
				"email": {
					"required": `${$.i18n("form-error-email-required")}`,
					"bad_format": `${$.i18n("form-error-email-bad-format")}`,
					"taken": `${$.i18n("form-error-email-taken")}`
				},
				"status": {
					"new_password_must_be_given": `${$.i18n("form-error-password-required")}`
				},
				"new_password": {
					"bad_format": `${$.i18n("form-error-password-bad-format")}`
				},
				"password": {
					"required": `${$.i18n("form-error-password-required")}`,
					"bad_format": `${$.i18n("form-error-password-bad-format")}`
				},
				"password_confirmation": {
					"required": `${$.i18n("form-error-password-confirmation-required")}`
				}
			};

			let errorsToIgnore = {
				"rights": ["user_must_have_at_least_one_visualisation_or_evaluation_right"]
			};

			showFormErrors("#edit_user", response, errorsToShow, errorsToIgnore);

			if (response.responseText.indexOf("user_must_have_at_least_one_visualisation_or_evaluation_right") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-required-right")}</div>`);

			} else if (response.responseText.indexOf("admin_cartographies_must_be_defined_on_a_branch") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_cartographies_must_be_defined_on_a_branch")}</div>`);

			} else if (response.responseText.indexOf("admin_business_activities_must_be_defined_on_a_branch") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_business_activities_must_be_defined_on_a_branch")}</div>`);

			} else if (response.responseText.indexOf("access_statistics_must_be_defined_on_a_branch") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-access_statistics_must_be_defined_on_a_branch")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_cartographies_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_remove_this_admin_cartographies_right")}</div>`);

			} else if (response.responseText.indexOf("you_must_have_admin_users_branch_right_to_give_admin_cartographies_on_branch_") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_branch_right_to_give_admin_cartographies_on_branch")}</div>`);

			} else if (
				response.responseText.indexOf("user_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_receive_admin_cartographies_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-user_must_have_admin_users_right_on_branch_to_receive_admin_cartographies_right")}</div>`);

			} else if (response.responseText.indexOf("admin_risks_must_be_defined_on_a_cartography") >= 0) { // ERRORS ADMIN RISKS

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_risks_must_be_defined_on_a_cartography")}</div>`);

			} else if (response.responseText.indexOf("admin_risks_must_be_on_all_legal_entities") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_risks_must_be_on_all_legal_entities")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_risk_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_admin_risk_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_give_admin_risks_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_give_admin_risks_on_it")}</div>`);

			} else if (response.responseText.indexOf("admin_users_must_be_defined_on_a_cartography") >= 0) { // ERRORS ADMIN USERS

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_users_must_be_defined_on_a_cartography")}</div>`);

			} else if (response.responseText.indexOf("admin_users_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-admin_users_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_users_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_admin_users_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_give_admin_users_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_give_admin_users_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_users_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_admin_users_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_give_admin_users_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_give_admin_users_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_users_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_remove_this_admin_users_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_admin_users_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_give_admin_users_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country_") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_admin_users_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_admin_users_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country_") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_admin_users_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_admin_users_on_it")}</div>`);

			} else if (response.responseText.indexOf("evaluate_cartography_must_be_defined_on_a_cartography") >= 0) { // ERRORS EVALUATE CARTOGRAPHY

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-evaluate_cartography_must_be_defined_on_a_cartography")}</div>`);

			} else if (response.responseText.indexOf("evaluate_cartography_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-evaluate_cartography_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_evaluate_cartography_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_remove_this_evaluate_cartography_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_cartography_") >= 0 &&
				response.responseText.indexOf("_to_give_evaluate_cartography_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_cartography_to_give_evaluate_cartography_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_evaluate_cartography_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_evaluate_cartography_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_give_evaluate_cartography_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_give_evaluate_cartography_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_evaluate_cartography_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_remove_this_evaluate_cartography_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_evaluate_cartography_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_give_evaluate_cartography_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_evaluate_cartography_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_evaluate_cartography_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country_") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_evaluate_cartography_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_evaluate_cartography_on_it")}</div>`);

			} else if (response.responseText.indexOf("view_evaluation_must_be_defined_on_a_cartography") >= 0) { // ERRORS VIEW EVALUATION

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-view_evaluation_must_be_defined_on_a_cartography")}</div>`);

			} else if (response.responseText.indexOf("view_evaluation_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country") >= 0) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-view_evaluation_must_be_defined_on_a_legal_entity_a_branch_or_branch_and_country")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_view_evaluation_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_remove_this_view_evaluation_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_legal_entity_") >= 0 &&
				response.responseText.indexOf("_to_give_view_evaluation_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_legal_entity_to_give_view_evaluation_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_view_evaluation_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_remove_this_view_evaluation_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_view_evaluation_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_branch_to_give_view_evaluation_on_it")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country_") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_remove_this_view_evaluation_right") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_remove_this_view_evaluation_right")}</div>`);

			} else if (
				response.responseText.indexOf("you_must_have_admin_users_right_on_country_") >= 0 &&
				response.responseText.indexOf("_and_branch_") >= 0 &&
				response.responseText.indexOf("_to_give_view_evaluation_on_it") >= 0
			) {

				$("FORM .main-error").remove();
				$("FORM #rights").prepend(`<div class='main-error'>${$.i18n("form-error-you_must_have_admin_users_right_on_country_and_branch_to_give_view_evaluation_on_it")}</div>`);

			}

			// We show the first error
			// Error in the first part of the form
			let positionTopFirstError = 0;
			if ($(".error").eq(0).parents("li").length > 0) {

				positionTopFirstError = $(".error").eq(0).parents("li").offset().top;

			}
			// Error in the rights part of the form
			else {

				positionTopFirstError = $(".main-error").eq(0).offset().top;

			}
			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else {

			$("FORM").prepend(`<div class='main-error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new UserEdit();
