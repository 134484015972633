import router from "Core/router.js";
import Page from "Pages/Page.js";
import debug from "Core/Debug.js";
import menu from "Components/Menu/Menu.js";
import html from "./news.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import users from "Datapoints/Users.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import news from "Datapoints/News.js";
import "./News.scss";

class News extends Page {

	constructor () {

		let routes = ["/news/:idNews"];

		let datapointsToLoad = [
			cartographies,
			legalEntities,
			users,
			news
		];

		super(routes, AuthentifiedLayout, "News", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);

			this.params = params;

			menu.show().then(resolve);

			// We load the news
			this.loadNews();

		});

	}

	loadNews () {

		const newsData = news.find(this.params.idNews);
		let htmlAuthorNews = "";
		if (newsData.author_standard_name !== undefined && newsData.author_standard_name !== null) {

			htmlAuthorNews = `<div class="item-info-news"><span class="icon far fa-user"></span> ${newsData.author_standard_name}</div>`;

		}

		// We define the news date
		const newsDay = `0${new Date(newsData.updated_at).getDate()}`.slice(-2);
		const newsMonth = `0${new Date(newsData.updated_at).getMonth() + 1}`.slice(-2);
		const newsYear = new Date(newsData.updated_at).getFullYear();
		const newsFullDate = `${newsDay}.${newsMonth}.${newsYear}`;
		const htmlDate = `<div class="item-info-news"><span class="icon far fa-calendar-alt"></span> ${newsFullDate}</div>`;

		// We show the news infos
		$(".title-page").append(`<h2>${htmlAuthorNews} ${htmlDate}</h2>`);

		// We show the news title
		$(".title-news").html(newsData[`title_${this.currentLang}`]);

		// We show the news content
		$(".content-news").html(newsData[`content_${this.currentLang}`]);

	}

}
export default new News();
