import "./Risk.scss";
import "./RiskPrint.scss";
import "./RiskResponsive.scss";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import html from "./risk.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import users from "Datapoints/Users.js";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import scenarios from "Datapoints/Scenarios.js";
import resources from "Datapoints/Resources.js";
import goFurther from "Datapoints/GoFurther.js";
import tasks from "Datapoints/Tasks.js";
import actions from "Datapoints/Actions.js";
import actionsPlan from "Datapoints/ActionsPlan.js";
import Popin from "Components/Popin/Popin.js";
import GoFurtherContainer from "Components/GoFurther/GoFurtherContainer.js";
import ScenarioContainer from "Components/Scenario/ScenarioContainer.js";
import ResourceContainer from "Components/Resource/ResourceContainer.js";
import helpSequence from "Components/HelpSequence/HelpSequence.js";
import TaskContainer from "Components/Task/TaskContainer.js";
import actionContainer from "Components/Action/ActionContainer.js";
import router from "Core/router.js";
import debug from "Core/Debug.js";
import {removeClassStartingWith, getDateFormatted, cleanScore, getMarkColor, slugify} from "Core/helpers.js";
// Import Datepicker
import "Core/assets/lib/datepicker.min.css";
import "Core/assets/lib/datepicker.min.js";
import "Core/assets/lib/datepicker.fr-FR.js";

class Risk extends Page {

	constructor () {

		let routes = [
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk",
			"/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/business-activities/:idBusinessActivity",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk",
			"/profit-centers/:idProfitCenter/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/business-activities/:idBusinessActivity",
			"/countries/:idCountry/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk",
			"/countries/:idCountry/legal-entities/:idLegalEntity/cartographies/:idCartography/risks/:idRisk/business-activities/:idBusinessActivity",
			"/cartographies/:idCartography/risks/:idRisk"
		];

		let datapointsToLoad = [
			context,
			users,
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			scenarios,
			resources,
			goFurther,
			tasks,
			countries,
			businessActivities,
			actions,
			actionsPlan
		];

		super(routes, AuthentifiedLayout, "Informations sur le risque", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			this.hasRightToSeeThisRisk
		];

	}

	hasRightToSeeThisRisk (params) {

		return new Promise((resolve, reject) => {

			const canView = user.hasRight("view_evaluation", {"cartography_id": params.idCartography});
			const canEvaluate = user.hasRight("evaluate_cartography", {"cartography_id": params.idCartography});

			if (canView || canEvaluate) {

				resolve(true);

			} else {

				resolve(false);

			}

		});

	}

	render (params) {

		return new Promise((resolve, reject) => {

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.params = params;

			// We define current legal entity ID
			this.curLegalEntityId = params.idLegalEntity;

			// We define current cartography ID
			this.curCartographyId = params.idCartography;

			// We get the current cartography
			this.curCartography = cartographies.find(this.curCartographyId);

			// We define current risk ID
			this.curRiskId = params.idRisk;

			// We define if the current user has the evaluate right
			this.canCurrentUserEvaluateCartography = user.canEvaluateCartography();

			// We saved the risk id on the local storage
			localStorage.setItem("risk-currentRiskId", this.curRiskId);

			// We show the page
			this.parseAndRender("page", html);

			// We show the select of context (BEFORE THE MENU)
			selectContext.show(this.params);

			// We show the menu
			menu.show();

			// We load the risks
			risks.getRisksCartography(params.idCartography).then(() => {

				// We get the risk
				this.risk = risks.find(params.idRisk);

				// We define if the current user is RPO
				this.isCurrentUserRPO = user.isRPO(this.curRiskId);

				// We define if the current user is risk editor
				this.isCurrentUserRiskEditor = user.isRiskEditor(this.curRiskId);

				// We define if the current user has the admin risks right
				this.isCurrentUserAdminRisks = false;
				const userRightsFilteredOnAdminRisks = user.data.rights.filter((right) => parseInt(right.cartography_id) === parseInt(this.curCartographyId) && right.rights.indexOf("admin_risks") >= 0);

				if (
					userRightsFilteredOnAdminRisks !== undefined &&
					userRightsFilteredOnAdminRisks !== null &&
					userRightsFilteredOnAdminRisks.length > 0
				) {

					this.isCurrentUserAdminRisks = true;

				}

				// We get the actions plan data for the current couple cartography / legal entity
				this.actionsPlan = [];
				if (actionsPlan.data !== undefined) {

					if (
					    actionsPlan.data.actionsPlan !== undefined &&
					    actionsPlan.data.actionsPlan !== null &&
					    actionsPlan.data.actionsPlan.length > 0
					) {

						this.actionsPlan = actionsPlan.data.actionsPlan;

					}

				}


				// We do not load if no risk is present
				if (this.risk != undefined) {

					// We define if we are on consolidated view
					if (
						window.location.pathname.indexOf("/profit-centers/all/") >= 0 ||
						window.location.pathname.indexOf("/countries/all/") >= 0 ||
						window.location.pathname.indexOf("/legal-entities/all/") >= 0
					) {

						this.isConsolidatedView = true;

					} else {

						this.isConsolidatedView = false;

					}

					// We init buttons for admin risk
					this.initAdminRisk(this.risk, params);

					// We show risks data
					this.showRisk(this.risk);

					// We show scenarios
					new ScenarioContainer(this.risk);

					// We show resources
					new ResourceContainer(this.risk);

					// We show go further links
					new GoFurtherContainer(this.risk);

					// We show tasks
					new TaskContainer(this.risk);

					// We init the datepicker
					this.initDatepicker();

					// We init sub menu events
					this.initSubMenu();

					// We load the content
					this.showContent(window.location.hash);

				} else {

					router.navigate("/not-allowed");

				}

				// We get the current branch id
				const curBranchId = context.getCurrentBranchId();

				// We get the current profit center id
				const curProfitCenterId = context.getCurrentProfitCenterId();

				// We get the current cartography id
				const curCartographyId = cartographies.getCurrentCartographyId();

				// We load the current legal entity
				const curLegalEntityId = context.getCurrentLegalEntityId();

				// We get the current country id
				const curCountryId = context.getCurrentCountryId();

				// We defines booleean to ease algo reading
				const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
				const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
				const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
				const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
				const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
				const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
				const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

				// If no parameters are given, we check if the legal entities has business activities
				if (!paramsGiven) {

					this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

				} else if (isProfitCenterOnAll && isLegalEntityOnAll) {

					this.loadRisksForBranch(curCartographyId, curBranchId, resolve, reject);

				} else if (isCountryOnAll && isLegalEntityOnAll) {

					this.loadRisksForProfitCenter(curCartographyId, curProfitCenterId, resolve, reject);

				} else if (isLegalEntityOnAll) {

					this.loadRisksForCountry(curCartographyId, curCountryId, resolve, reject);

				} else if (isBusinessActivityAnId) {

					this.loadRisksForBusinessActivity(curCartographyId, curLegalEntityId, this.params.idBusinessActivity, resolve, reject);

				} else if (isBusinessActivityOnAll) {

					this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

				} else {

					this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

				}

			});

		});

	}

	loadRisksForLegalEntity (curCartographyId, curLegalEntityId, resolve, reject) {


		legalEntities.getBusinessActivities(curLegalEntityId).then((businessActivities) => {

			// We show legal entity consolidation if at least one business activity exists
			if (businessActivities.length > 0) {

				const contextData = {
					"cartography_id": curCartographyId,
					"legal_entity_id": curLegalEntityId
				};

				// We load evaluations for context
				cartographies.getEvaluationForContext(contextData).then((data) => {


					this.showLoadedRiskEvaluation(data);

					resolve();

				}).catch((error) => {

					debug.v("Risk - loadRisksForLegalEntity - error in Cartographies getEvaluationForContext");
					reject(error);

				});

			}
			// Otherwise we show legal entity evaluations
			else {


				cartographies.getEvaluation(curCartographyId, curLegalEntityId).then((data) => {

					this.showLoadedRiskEvaluation(data);

					resolve();


				}).catch((error) => {

					debug.v("Risk - loadRisksForLegalEntity - error in Cartographies getEvaluation");
					reject();

				});

			}

		});

	}

	showLoadedRiskEvaluation (riskEvaluation) {

		if (this.risk !== undefined) {

			const curRiskEvaluation = riskEvaluation.filter((evaluation) => evaluation.risk_id === this.risk.id);

			// We show the evaluation
			user.canEvaluateThisCartographyForThisLegalEntity(this.params.idCartography, this.params.idLegalEntity).then((dataEvaluationRight) => {

				// We get the most recent evaluation
				const sortedByUpdatedAt = curRiskEvaluation.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

				this.showEvaluationButton(dataEvaluationRight);
				this.showEvaluation(sortedByUpdatedAt[0], dataEvaluationRight);

			});

			// We show the version
			this.showRiskEvaluationVersion();

		}

	}

	loadRisksForBranch (curCartographyId, curBranchId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"branch_id": curBranchId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForProfitCenter (curCartographyId, curProfitCenterId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"profit_center_id": curProfitCenterId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForCountry (curCartographyId, curCountryId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"country_id": curCountryId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForBusinessActivity (curCartographyId, curLegalEntityId, businessActivityId, resolve, reject) {

		// We load evaluations
		cartographies.getEvaluationForBusinessActivity(curCartographyId, curLegalEntityId, businessActivityId).then((data) => {

			this.showLoadedRiskEvaluation(data);

			resolve();

		}).catch((error) => {

			debug.e("Risk - loadRisksForBusinessActivity - error in Cartographies getEvaluationForBusinessActivity");
			reject();

		});

	}

	loadRisksForContext (contextData, resolve, reject) {


		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((data) => {

			this.showLoadedRiskEvaluation(data);

			resolve();

		}).catch((error) => {

			debug.v("Risk - loadRisksForContext - error in Cartographies getEvaluationForContext");
			reject();

		});

	}

	initDatepicker () {

		$("[data-toggle=\"datepicker\"]").datepicker({
			"language": "fr-FR",
			"format": "dd/mm/yyyy",
			"autoHide": true
		});

		$("[data-toggle=\"datepicker-small\"]").datepicker({
			"language": "fr-FR",
			"format": "mm/yyyy",
			"autoHide": true
		});

	}

	initSubMenu () {

		// If the current user hasn't the right to evaluate
		if (!this.canCurrentUserEvaluateCartography) {

			// We change the evaluation sub menu style (we remove the number + we change the label)
			$("#sub-menu-item-evaluations").html(`<span class="text" data-i18n="item-sub-menu-risk-evaluation">${$.i18n("item-sub-menu-risk-evaluation")}</span>`);

			// We remove the title of the evaluation section
			$("#slide-evaluations .list-evaluations .title-section").remove();

		}

		// We listen the click on the sub menu item
		$("#sub-menu-risk .item").on("click", this.handleClickOnSubMenuItem.bind(this));

	}

	initAdminRisk (risk, params) {

		// Edit button
		// If the user is RPO or editor, we show the button
		if (this.isCurrentUserRPO || this.isCurrentUserRiskEditor) {

			this.shouldShowBtnEditRisk(true);

		} else { // Otherwise we check if he has appropriate right

			user.canAdminRisks(params).then((result) => {

				this.shouldShowBtnEditRisk(true);

			}).catch((e) => {

				this.shouldShowBtnEditRisk(false);

			});

		}

		// Delete button
		user.canAdminRisks(params).then((result) => {

			this.deletePopin = new Popin("delete-risk-popin", `${$.i18n("popin-delete-risk-confirmation-title")} ${this.risk.reference} ?`, [
				{
					"title": `${$.i18n("button-cancel")}`,
					"class": "btn-cancel",
					"iconClass": "icon fas fa-times",
					"callback": this.handleDeleteCancel.bind(this)
				},
				{
					"title": `${$.i18n("button-delete")}`,
					"class": "btn-submit",
					"iconClass": "icon fas fa-check",
					"callback": this.handleDeleteConfirm.bind(this)
				}
			]);
			this.deletePopin.appendIn("#page");

			$("#btn-delete-risk").addClass("active");

			$("#btn-delete-risk").on("click", (event) => {

				this.deletePopin.show();

			});

		}).catch((error) => {});

		// We init the share button
		this.initShareButton();

		// We init the print button
		this.initPrintButton();

		// We init the actions button
		this.initActionsButton();

	}

	initShareButton () {

		// We get the subject of the email to share
		const emailSubject = `${user.data.firstname} ${user.data.lastname} ${$.i18n("risk-subject-email-sharing")}`;

		// We get the risk url
		const riskUrl = window.location.href;

		// We add the mailto link to the share button
		$(".buttons-admin-risk .btn-share a").attr("href", `mailto:?subject=${emailSubject}&body=${riskUrl}`);


	}

	initPrintButton () {

		// We listen the click on the print risk button
		$(".btn-print .link").on("click", () => {

			window.print();

		});

	}

	initActionsButton () {

		// When we click on the actions button, we scroll to the actions section
		$(".pre-header .btn-actions").on("click", (event) => {

			const actionsSectionPositionTop = $("#section-actions-risk").offset().top;

			$("html, body").animate({"scrollTop": actionsSectionPositionTop - 200}, 400);

		});

	}

	shouldShowBtnEditRisk (shouldShowIt) {

		if (shouldShowIt) {

			// We show the button
			$("#btn-edit-risk").addClass("active");

			// We listen the click on the edit risk button
			$("#btn-edit-risk .link").on("click", this.handleClickOnBtnEditRisk.bind(this));

		}

	}

	handleClickOnSubMenuItem (e) {

		// We get the id of the current item
		const curSubMenuItemId = $(e.currentTarget).attr("id").replace("sub-menu-item-", "");

		// We show the content
		this.showContent(curSubMenuItemId);

	}

	showContent (idContent) {

		const newIdContent = idContent.replace("#", "");

		if ($(`#slide-${newIdContent}`).length > 0) {

			// We switch the active class on the sub menu
			$("#sub-menu-risk .item").removeClass("active");
			$(`#sub-menu-item-${newIdContent}`).addClass("active");

			// We hide all the contents
			$(".main-content .content-risk .slide-content-risk").removeClass("active");

			// We show the content
			$(`#slide-${newIdContent}`).addClass("active");

			// We add hash in the url
			window.location.hash = newIdContent;

		}

	}

	handleDeleteCancel () {

		this.deletePopin.hide();

	}

	handleDeleteConfirm () {

		risks.deleteOne(this.risk.id).then(() => {

			router.navigate("/dashboard");

		}).catch(debug.e);

	}

	showRisk (dataRisk) {

		// We show the reference
		$("#reference-risk").html(dataRisk.reference);

		// We show the origin
		const riskOrigin = dataRisk.origin;
		if (riskOrigin === "internal") {

			$("#origin-risk").html($.i18n("form-label-risk-origin-internal"));

		} else if (riskOrigin === "external") {

			$("#origin-risk").html($.i18n("form-label-risk-origin-external"));

		} else if (riskOrigin === "internal_and_external") {

			$("#origin-risk").html($.i18n("form-label-risk-origin-internal-and-external"));

		} else {

			$(".info.info-origin").hide();

		}

		// We show the RPO
		cartographies.getPossibleRPOForCartography(this.risk.cartography_id).then((users) => {

			let userFound = false;
			$(users).each((i, user) => {

				if (dataRisk.rpo_id == user.id) {

					$("#rpo-risk .firstname").html(user.firstname);
					$("#rpo-risk .lastname").html(user.lastname);

					userFound = true;

				}

			});

			if (userFound) {

				$(".info.info-rpo").addClass("active");

			}

		}).catch((error) => {

			debug.v("Risk - showRisk - error in User getPossibleRPOForCartography");

		});

		// We show the theme with a limitation of characters
		let curTheme = "";
		let fullcurTheme = "";
		const themeLimit = 20;
		const cartographyThemes = cartographies.find(this.curCartographyId).themes;

		if (cartographyThemes != null) {

			const dataThemeCartography = cartographyThemes.filter((theme) => theme.slug === dataRisk.theme)[0];

			if (dataThemeCartography != undefined) {

				curTheme = dataThemeCartography.lib[`${this.currentLang}`];
				fullcurTheme = curTheme;

				if (dataRisk.theme.length >= themeLimit) {

					curTheme = curTheme.substring(0, themeLimit).concat("", "...");

				}

				$("#theme-risk").html(curTheme);

			}

		}

		// If no theme can be shown, we remove the block
		if (curTheme == "") {

			$(".info.info-theme").remove();

		}

		// We add a title to the theme
		$("#theme-risk").parents(".info-theme").attr("title", fullcurTheme);

		// We show the title
		if (dataRisk[`title_${this.currentLang}`] !== "" && dataRisk[`title_${this.currentLang}`] !== null) {

			$("#title-risk").html(dataRisk[`title_${this.currentLang}`]);

		} else if (this.currentLang === "fr" && dataRisk.title_en !== null) {

			$("#title-risk").html(dataRisk.title_en);

		} else if (this.currentLang === "en" && dataRisk.title_fr !== null) {

			$("#title-risk").html(dataRisk.title_fr);

		} else {

			$("#title-risk").html("");

		}

		// We show the description
		if (dataRisk[`description_${this.currentLang}`] !== undefined && dataRisk[`description_${this.currentLang}`] !== null && dataRisk[`description_${this.currentLang}`] !== "") {

			$("#description-risk").html(dataRisk[`description_${this.currentLang}`].replace(/\n/g, "<br />"));

		} else if (this.currentLang === "fr" && dataRisk.description_en !== null) {

			$("#description-risk").html(dataRisk.description_en.replace(/\n/g, "<br />"));

		} else if (this.currentLang === "en" && dataRisk.description_fr !== null) {

			$("#description-risk").html(dataRisk.description_fr.replace(/\n/g, "<br />"));

		} else {

			$("#description-risk").html("");

		}

		// We add a target _blank in all description links
		$("#description-risk a").attr("target", "_blank");

		// We add a http:// prefix in all description links
		$("#description-risk a").each(function () {

			const curHrefLink = $(this).attr("href");
			const prefixUrl = "https://";

			if (curHrefLink.indexOf("http://") === -1 && curHrefLink.indexOf("https://") === -1) {

				$(this).attr("href", prefixUrl.concat(curHrefLink));

			}

		});

		// We show all consequencies
		if (
			dataRisk.consequences !== null &&
			dataRisk.consequences !== undefined
		) {

			// We need the cartographies to have the consequence label
			cartographies.getData().then(() => {

				$.each(dataRisk.consequences, (slug, value) => {

					let htmlConsequence;
					let cartography = cartographies.find(dataRisk.cartography_id);
					let otherPotentialRisk = cartography.other_potential_risks.find((opr) => opr.slug == slug);

					htmlConsequence = `<li><span class="icon"><i class="${otherPotentialRisk.icon}"></i></span><span class="text">${otherPotentialRisk.lib[this.currentLang]}</span></li>`;

					$("#consequences-risk ul.list-consequences").append(htmlConsequence);

				});

			});


		} else {

			$("#section-consequences-risk").hide();

		}

		// We show the actions
		this.showActions();

	}

	showRiskEvaluationVersion () {

		cartographies.getAvailableVersionsOfEvaluations(this.curRiskId, this.curLegalEntityId).then((evaluations) => {

			// We show the current evaluation version on header
			if (this.canCurrentUserEvaluateCartography) {

				this.showCurrentEvaluationVersion(evaluations);

			}

			// We show the evaluations versions section
			this.showEvaluationsVersionsSection(evaluations);


		}).catch((error) => {


			// We show the no data message
			this.showNoEvaluationVersionMessage();

			debug.v("Risk - showRiskEvaluationVersion - error in User getAvailableVersionsOfEvaluations");

		});

	}

	showCurrentEvaluationVersion (evaluations) {

		if (evaluations !== undefined && evaluations.length > 0) {

			// We sort evaluations by date descending
			const evaluationsSortByDateDescending = evaluations.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

			// We check if there is an evaluation in WIP
			let evaluationInWIP;
			// If we are showing all the business activity
			if (
				this.params.idBusinessActivity !== undefined &&
				this.params.idBusinessActivity !== null &&
				this.params.idBusinessActivity === "all"
			) {

				evaluationInWIP = evaluations.filter((evaluation) => evaluation.version.toLowerCase() === "wip")[0];

			}
			// If we are showing one specific business activity
			else if (
				this.params.idBusinessActivity !== undefined &&
				this.params.idBusinessActivity !== null &&
				this.params.idBusinessActivity !== "all"
			) {

				evaluationInWIP = evaluations.filter((evaluation) => parseInt(evaluation.business_activity_id) === parseInt(this.params.idBusinessActivity) && evaluation.version.toLowerCase() === "wip")[0];

			}
			// If we are showing a legal entity without business activities
			else {

				evaluationInWIP = null;

			}

			// If the user can evaluate the cartography for the current context and at least one of the evaluation is in WIP
			if (
				this.canCurrentUserEvaluateCartography &&
				evaluationInWIP !== undefined &&
				evaluationInWIP !== null
			) {


				// We define the html of the line version
				const htmlLineVersionForWIP = `
					<div class="line-version">
			      <div class="box-version" id="evaluation-version">
			        <div class="icon fas fa-calendar-alt"></div>
			        <div class="content">
			          <div class="num-version" data-i18n="label-wip">${$.i18n("label-wip")}</div>
			        </div>
			      </div>
			      <div class="btn-publish">
			        <span class="icon fas fa-file-upload"></span>
			        <span class="text" data-i18n="button-publish">${$.i18n("button-publish")}</span>
			      </div>
			    </div>
				`;

				// We clear the lines versions on the risk header
				$("#risk-header .line-version").remove();

				// We show the line version in the header
				$("#risk-header").append(htmlLineVersionForWIP);

				// We listen the click on the publish button
				$("#risk-header .btn-publish").on("click", this.handleClickOnBtnPublishEvaluation.bind(this));

			} else {

				this.lastEvaluation = null;

				if (
				  this.params.idBusinessActivity !== undefined &&
				  this.params.idBusinessActivity !== null &&
					this.params.idBusinessActivity === "all"
				) {

					this.lastEvaluation = evaluationsSortByDateDescending[0];

				} else if (
					this.params.idBusinessActivity !== undefined &&
				  this.params.idBusinessActivity !== null &&
				  this.params.idBusinessActivity !== "all"
				) {

					// We get the evaluations of the current business activity
					const curBusinessActivityEvaluations = evaluations.filter((evaluation) => parseInt(evaluation.business_activity_id) === parseInt(this.params.idBusinessActivity));

					if (curBusinessActivityEvaluations !== undefined && curBusinessActivityEvaluations.length > 0) {

						// We sort evaluations by date descending
						const curBusinessActivityEvaluationsSorByDateDescending = curBusinessActivityEvaluations.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

						// We get the last evaluation
						this.lastEvaluation = curBusinessActivityEvaluationsSorByDateDescending[0];

					} else {

						this.lastEvaluation = null;

					}

				} else {

					// We do nothing

				}


				// If there is a last evaluation published
				if (this.lastEvaluation !== undefined && this.lastEvaluation !== null) {

					// We get the version
					this.lastEvaluationVersion = this.lastEvaluation.version;

					// We get the date
					this.lastEvaluationDate = getDateFormatted(this.lastEvaluation.updated_at);

					// We define the html of the line version
					const htmlLineVersion = `
						<div class="line-version">
				      <div class="box-version" id="evaluation-version">
				        <div class="icon fas fa-calendar-alt"></div>
				        <div class="content">
				          <div data-i18n="label-version">${$.i18n("label-version")}</div>&nbsp;
				          <div class="num-version">${this.lastEvaluationVersion}</div>&nbsp;&nbsp;|&nbsp;&nbsp;
				          <div class="date-version">${this.lastEvaluationDate}</div>
				        </div>
				      </div>
				    </div>
					`;

					// We clear the lines versions on the risk header
					$("#risk-header .line-version").remove();

					// We show the line version in the header
					$("#risk-header").append(htmlLineVersion);

				}

			}

		}

	}

	handleClickOnBtnPublishEvaluation () {

		// We publish the risk evaluations
		cartographies.publishEvaluationsForRisk(this.curRiskId, this.curLegalEntityId).then(() => {

			// We show again the risk evaluation version
			this.showRiskEvaluationVersion();

			// We show the evaluations section
			this.showContent("evaluations");

			// We remove the help tooltip
			helpSequence.hideTooltip();

			// We hide the help mode overlay
			helpSequence.hideHelpMode();

			// We remove the help informations saved on the local storage
			helpSequence.removeLocalStorageInfos();

		});


	}

	showEvaluationsVersionsSection (evaluations) {

		// We clear the version sections
		$("#slide-evaluations .list-evaluations .version-section").remove();

		if (evaluations !== undefined && evaluations.length > 0) {

			// We sort evaluations by date descending
			const evaluationsSortByDateDescending = evaluations.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));


			// We filter to show the versions according to the business activity asked
			let evaluationsToShow;
			if (
				this.params.idBusinessActivity !== undefined &&
				this.params.idBusinessActivity !== null &&
				this.params.idBusinessActivity === "all"
			) {

				evaluationsToShow = evaluationsSortByDateDescending;

			} else if (
				this.params.idBusinessActivity !== undefined &&
				this.params.idBusinessActivity !== null &&
				this.params.idBusinessActivity !== "all"
			) {

				evaluationsToShow = evaluationsSortByDateDescending.filter((evaluation) => parseInt(evaluation.business_activity_id) === parseInt(this.params.idBusinessActivity));

			} else {

				evaluationsToShow = null;

			}

			// For each evaluation
			if (evaluationsToShow !== undefined && evaluationsToShow !== null) {

				$(evaluationsToShow).each((i, evaluation) => {

					// For evaluators only
					if (this.canCurrentUserEvaluateCartography) {

						// If the version section is not displayed
						if ($(`.version-section[data-version='${evaluation.version}']`).length < 1) {

							// We define the version to show
							let htmlVersionToShow;
							if (evaluation.version.toLowerCase() === "wip") {

								htmlVersionToShow = `<span class="icon fas fa-calendar-alt"></span>&nbsp;<span class="highlight">${$.i18n("label-wip")}</span>`;

							} else {

								htmlVersionToShow = `<span class="icon fas fa-calendar-alt"></span>&nbsp;<span data-i18n="label-version">${$.i18n("label-version")}</span>&nbsp;<span class="highlight">${evaluation.version}</span>`;

							}

							// We create a new version section
							const htmlVersionSection = `
								<div class="version-section" data-version="${evaluation.version}">
									<div class="title-version-section">
										<div class="text-title">
											${htmlVersionToShow}&nbsp;&nbsp;|&nbsp;&nbsp;<span class="highlight">${getDateFormatted(evaluation.updated_at)}</span>
										</div>
									</div>
								</div>
							`;

							// We show the new version section
							$("#slide-evaluations .list-evaluations").append(htmlVersionSection);

						}

					}

					// If the evaluation is not displayed
					if ($(`#slide-evaluations .card-risk[data-id-evaluation='${evaluation.id}']`).length < 1) {

						// We add the evaluation line on the good version section
						const cardEvaluation = new CardRisk(evaluation);
						const cardEvaluationColor = cardEvaluation.getCardColor(evaluation);

						let selector;
						if (this.canCurrentUserEvaluateCartography) {

							selector = `#slide-evaluations .list-evaluations .version-section[data-version='${evaluation.version}']`;

						} else {

							selector = "#slide-evaluations .list-evaluations";

						}

						cardEvaluation.appendAndFillForEvaluationVersion(selector, cardEvaluationColor, evaluation, evaluation.scores);

					}

					// We load evaluation comments
					this.loadEvaluationComments(evaluation);

				});

				// We listen the click on the buttons to show comments
				$(".btn-show-comments").on("click", this.handleClickOnBtnShowComments.bind(this));

			}

		} else {

			// We show the no data message
			this.showNoEvaluationVersionMessage();

		}

		// We get number of evaluations line
		const numEvaluations = $("#slide-evaluations .card-risk").length;

		// We show the number of evaluations on the sub menu
		$("#sub-menu-item-evaluations .num").html(numEvaluations);

	}

	loadEvaluationComments (evaluation) {

		// We define the html of the button to show comments
		const htmlBtnShowComments = `
			<div class="btn-show-comments">
				<span class="icon fas fa-comment"></span>
				<span class="text" data-i18n="button-show-comments">${$.i18n("button-show-comments")}</span>
			</div>
		`;

		// We define the comment to show
		let commentToShow;
		if (
			evaluation[`comment_${this.currentLang}`] !== undefined &&
			evaluation[`comment_${this.currentLang}`] !== null &&
			evaluation[`comment_${this.currentLang}`] !== ""
		) {

			commentToShow = evaluation[`comment_${this.currentLang}`].replace(/\n/g, "<br />");


		} else if (this.currentLang === "fr" && evaluation.comment_en !== undefined && evaluation.comment_en !== null) {

			commentToShow = evaluation.comment_en.replace(/\n/g, "<br />");

		} else if (this.currentLang === "en" && evaluation.comment_fr !== undefined && evaluation.comment_fr !== null) {

			commentToShow = evaluation.comment_fr.replace(/\n/g, "<br />");

		}

		if (commentToShow !== undefined) {

			// We define the container to show the button
			let curContainer;

			// We check if we are in a version block
			if ($("#slide-evaluations .list-evaluations .version-section").length > 0) {

				// We define the container
				curContainer = $(`.card-risk[data-id-evaluation='${evaluation.id}']`).parents(".version-section").find(".title-version-section");

				// If the comments button doesn't exist in the container
				if (curContainer.find(".btn-show-comments").length < 1) {

					// We show the button
					curContainer.append(htmlBtnShowComments);

				}

			} else {

				// We define the container
				curContainer = $(`.card-risk[data-id-evaluation='${evaluation.id}']`).parents(".list-evaluations");

				// If the comments button doesn't exist in the container
				if (curContainer.find(".btn-show-comments").length < 1) {

					// We show the button
					curContainer.prepend(htmlBtnShowComments);

				}

			}

			// We define the html of the comment block
			const htmlBoxComment = `
				<div class="box-comment">
					<div class="title-comment">
						<span class="icon fas fa-comment"></span>
						<span class="text" data-i18n="form-label-field-comment">${$.i18n("form-label-field-comment")}</span>
					</div>
					<div class="content-comment">${commentToShow}</div>
				</div>
			`;

			// We show the comment in the card risk
			$(`.card-risk[data-id-evaluation='${evaluation.id}']`).append(htmlBoxComment);

			// We add a target _blank in all comment links
			$(`.card-risk[data-id-evaluation='${evaluation.id}']`).find(".content-comment a").attr("target", "_blank");

			// We add a http:// prefix in all comment links
			$(`.card-risk[data-id-evaluation='${evaluation.id}']`).find(".content-comment a").each(function () {

				const curHrefLink = $(this).attr("href");
				const prefixUrl = "http://";

				if (curHrefLink.indexOf("http://") === -1) {

					$(this).attr("href", prefixUrl.concat(curHrefLink));

				}

			});

		}

	}

	handleClickOnBtnShowComments (e) {

		// We get the current button
		const curButton = $(e.currentTarget);

		// We define the container of the evaluations list
		const curEvaluationsList = curButton.parents(".list-evaluations");

		// We define the container
		let curContainer;

		// We check if we are in a version block
		if (curEvaluationsList.find(".version-section").length > 0) {

			// We get the container
			curContainer = curButton.parents(".version-section");

		} else {

			// We get the container
			curContainer = curEvaluationsList;

		}

		// If the comments are not displayed
		if (curButton.attr("class").indexOf("active") < 0) {

			// We show the comments
			this.showComments(curButton, curContainer);

		} else {

			// We hide the comments
			this.hideComments(curButton, curContainer);

		}

	}

	showComments (curButton, curContainer) {

		// We change the icon of the button
		curButton.find(".icon").removeClass("fa-comment");
		curButton.find(".icon").addClass("fa-comment-slash");

		// We change the text of the button
		curButton.find(".text").html($.i18n("button-hide-comments"));

		// We add the active class on the button
		curButton.addClass("active");

		// We show the comments
		curContainer.find(".card-risk .box-comment").slideDown(200);

	}

	hideComments (curButton, curContainer) {

		// We change the icon of the button
		curButton.find(".icon").removeClass("fa-comment-slash");
		curButton.find(".icon").addClass("fa-comment");

		// We change the text of the button
		curButton.find(".text").html($.i18n("button-show-comments"));

		// We remove the active class on the button
		curButton.removeClass("active");

		// We hide the comments
		curContainer.find(".card-risk .box-comment").slideUp(200);

	}


	showNoEvaluationVersionMessage () {

		// We define the html of the message to show
		const htmlMessageNoEvaluationVersion = `
			<div class="msg-no-evaluation-published">
				<span class="icon fas fa-info-circle"></span>
				<span class="text" data-i18n="msg-no-evaluation-published">${$.i18n("msg-no-evaluation-published")}</span>
			</div>
		`;

		// We show the message
		$("#slide-evaluations .list-evaluations").append(htmlMessageNoEvaluationVersion);

	}

	showActions () {

		return new Promise((resolve, reject) => {

			// If we are not on a consolidated view
			if (!this.isConsolidatedView) {

				// If the user is RPO of the current risk
				if (this.isCurrentUserRiskEditor || this.isCurrentUserRPO || this.isCurrentUserAdminRisks) {

					const htmlBtnAddAction = `
						<div class="btn-add-action">
		          <span class="icon fas fa-plus"></span>
		          <span class="text" data-i18n="button-add-action">${$.i18n("button-add-action")}</span>
		        </div>
		      `;

					// We show the button to add new action
					$("#section-actions-risk .line-top").append(htmlBtnAddAction);

					$("#section-actions-risk .btn-add-action").on("click", (event) => {

						// We save the current page url on the local storage
						localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

						// We show the add action form
						router.navigate(`legal-entities/${this.curLegalEntityId}/cartographies/${this.curCartographyId}/risks/${this.curRiskId}/actions/create`);

					});

				}

				// We show the list of actions
				actionContainer.load("#section-actions-risk", this.actionsPlan, this.isCurrentUserRPO, this.isCurrentUserAdminRisks).then(resolve);

				// We listen the click on the buttons to show comments
				$("#section-actions-risk .btn-show-actions-comments").on("click", (event) => {

					actionContainer.handleClickOnBtnShowActionsComments();

				});

			} else { // On consolidated view

				// We define the html of the message to show
				const htmlMessageNoActionOnConsolidatedView = `
					<div class="msg-no-action">
						<span class="icon fas fa-info-circle"></span>
						<span class="text" data-i18n="msg-no-action">${$.i18n("msg-no-action-on-consolidated-view")}</span>
					</div>
				`;

				// We show the message
				$("#section-actions-risk").append(`<div class="list-actions">${htmlMessageNoActionOnConsolidatedView}</div>`);

				// We hide the number on the sub menu action item
				$("#sub-menu-item-actions .num").remove();

			}

		});

	}

	showEvaluationButton (dataEvaluationRight) {

		if (dataEvaluationRight) {

			// We show the evaluate button
			$("#btn-evaluate-risk").addClass("active");
			$("#btn-evaluate-risk-inactive").removeClass("active");

			// We listen the click on the risk evaluate button
			$("#btn-evaluate-risk").on("click", this.handleClickOnBtnEvaluateRisk.bind(this));

		} else {

			// We do nothing

		}

	}

	showEvaluation (dataEvaluation, dataEvaluationRight) {

		if (dataEvaluationRight) {

			if (
				dataEvaluation !== undefined &&
				dataEvaluation.risk_ignored !== undefined &&
				(dataEvaluation.risk_ignored === true || parseInt(dataEvaluation.risk_ignored) === 1)
			) {

				// We show the risk ignored button and hide the evaluation button
				this.showRiskIgnoredButton(dataEvaluation);

			}
			// CONTEXT EJ
			// Risk ignored for all AO or for EJ without AO
			else if (
			  dataEvaluation !== undefined &&
			  dataEvaluation.risk_ignored !== undefined &&
				dataEvaluation.risk_ignored.ignored_for_whole_legal_entity !== undefined &&
				dataEvaluation.risk_ignored.ignored_for_whole_legal_entity === true
			) {

				// We show the risk ignored button and hide the evaluation button
				this.showRiskIgnoredButton(dataEvaluation);

			}
			// CONTEXT EJ
			// Risk not ignored for the EJ but ignored for at least one AO
			else if (
			  dataEvaluation !== undefined &&
				dataEvaluation.risk_ignored.ignored_for_whole_legal_entity !== undefined &&
				dataEvaluation.risk_ignored.ignored_for_whole_legal_entity === false &&
				dataEvaluation.risk_ignored.business_acitivity_ids !== undefined &&
				dataEvaluation.risk_ignored.business_acitivity_ids.length > 0
			) {

				// We hide the risk ignored button
				this.hideRiskIgnoredButton();

				// We define the html for the message to show with the list of ignored business activities evaluations
				const htmlInfoMsg = `
					<div class="info-msg-risk warning">
						<div class="intro">
							<span class="icon fas fa-exclamation-triangle"></span>
							<span class="text" data-i18n="text-intro-msg-list-business-activities-ignored">${$.i18n("text-intro-msg-list-business-activities-ignored")}</span>
						</div>
						<div class="list-business-activities"></div>
					</div>
				`;

				// We show the message container
				$(".risk-container").prepend(htmlInfoMsg);

				// For each business activity
				$(dataEvaluation.risk_ignored.business_acitivity_ids).each((i, curBusinessActivityId) => {

					// We get the business activity data
					const curBusinessActivityData = businessActivities.find(curBusinessActivityId);

					// We define the html for the business activity item
					const htmlBusinessActivityItem = `
						<div class="item-business-activity">
							<span class="icon fas fa-building"></span>
							<span class="text">${curBusinessActivityData[`name_${this.currentLang}`]}</span>
						</div>
					`;

					// We add the current business activity to the list of business activities
					$(".info-msg-risk .list-business-activities").append(htmlBusinessActivityItem);

				});

			} else if (this.risk.status !== "pending") {

				// We hide the risk ignored button
				this.hideRiskIgnoredButton();

			}

		}

		// We define risk card
		const card = new CardRisk(this.risk);

		// We get risk color and scores
		let curItemColor;
		if (dataEvaluation !== undefined &&
			dataEvaluation.scores.control !== null &&
			dataEvaluation.scores.impact !== null &&
			dataEvaluation.scores.occurrence !== null) {

			curItemColor = card.getCardColor(dataEvaluation);

			// We remove the not evaluated class on the risk container
			$(".risk-container").removeClass("not-evaluated");

			// We add the color
			removeClassStartingWith($(".risk-container .header"), "color-");
			$(".risk-container .header").addClass(`color-${curItemColor}`);
			removeClassStartingWith($(".risk-container .pre-header"), "color-");
			$(".risk-container .pre-header").addClass(`color-${curItemColor}`);

			// We set the cartography impacts
			this.setCartographyImpacts(dataEvaluation);

			// We show all scores stars
			card.getScoreStars($(".risk-container .header"), dataEvaluation.scores);

			// We set the global impact tooltip
			this.setGlobalImpactTooltip(dataEvaluation);

			// We set impacts tooltips
			this.setImpactsTooltips(dataEvaluation);

			// We set the occurrence tooltip
			this.setOccurrenceTooltip(dataEvaluation);

			// We set the control tooltip
			this.setControlTooltip(dataEvaluation);

			// We show tooltips colors
			this.getTooltipsColors();

			// We show gross risk
			const curGrossRisk = dataEvaluation.scores.gross_risk;
			if (curGrossRisk !== undefined && curGrossRisk !== null && curGrossRisk !== "") {

				const curColor = getMarkColor(curGrossRisk);
				const widthGraph = curGrossRisk * 40;

				$("#gross-risk").addClass("active");
				removeClassStartingWith($("#gross-risk .graph"), "color-");
				$("#gross-risk .graph").addClass(`color-${curColor}`);
				$("#gross-risk .graph").css("width", `${widthGraph}px`);
				removeClassStartingWith($("#gross-risk .infos"), "color-");
				$("#gross-risk .infos").addClass(`color-${curColor}`);
				$("#gross-risk").find(".num").html(curGrossRisk);

			} else {

				$("#section-line-marks").removeClass("active");
				$("#gross-risk").removeClass("active");

			}

			// We show net risk
			const curNetRisk = dataEvaluation.scores.net_risk;
			if (curNetRisk !== undefined && curNetRisk !== null && curNetRisk !== "") {

				const curColor = getMarkColor(curNetRisk);
				const widthGraph = curNetRisk * 40;

				$("#section-line-marks").addClass("active");
				removeClassStartingWith($("#section-line-marks"), "color-");
				$("#section-line-marks").addClass(`color-${curColor}`);
				$("#net-risk").addClass("active");
				removeClassStartingWith($("#net-risk .graph"), "color-");
				$("#net-risk .graph").addClass(`color-${curColor}`);
				$("#net-risk .graph").css("width", `${widthGraph}px`);
				removeClassStartingWith($("#net-risk .infos"), "color-");
				$("#net-risk .infos").addClass(`color-${curColor}`);
				$("#net-risk").find(".num").html(curNetRisk);

			} else {

				$("#net-risk").removeClass("active");

			}

			// We listen mouse over and mouse out on gross (and net) scores
			$("#gross-risk").on("mouseenter", this.handleMouseOverOnGrossAndNetScores.bind(this));
			$("#net-risk").on("mouseenter", this.handleMouseOverOnGrossAndNetScores.bind(this));
			$("#section-line-marks .middle-marks .arrow").on("mouseenter", this.handleMouseOverOnGrossAndNetScores.bind(this));
			$("#section-line-marks .middle-marks .help .icon").on("mouseenter", this.handleMouseOverOnGrossAndNetScores.bind(this));
			$("#gross-risk").on("mouseleave", this.handleMouseOutOnGrossAndNetScores.bind(this));
			$("#net-risk").on("mouseleave", this.handleMouseOutOnGrossAndNetScores.bind(this));
			$("#section-line-marks .middle-marks .arrow").on("mouseleave", this.handleMouseOutOnGrossAndNetScores.bind(this));
			$("#section-line-marks .middle-marks .help .icon").on("mouseleave", this.handleMouseOutOnGrossAndNetScores.bind(this));


		} else if (dataEvaluation !== undefined && (dataEvaluation.risk_ignored === 1 || dataEvaluation.risk_ignored.ignored_for_whole_legal_entity === true)) {

			// We add the ignored class on the risk container
			$(".risk-container").addClass("ignored");

		} else {

			// We add the not evaluated class on the risk container
			$(".risk-container").addClass("not-evaluated");

			// We save in the local storage that the risk is not evaluated
			localStorage.setItem("risk-isCurrentRiskNotEvaluated", true);

		}

	}

	setCartographyImpacts (dataEvaluation) {

		// We define the impacts evaluations by score descending
		let impactsEvaluationsSortByScoreDescending;

		// If we are on a consolidation
		if (dataEvaluation.scores.impacts[0].average !== undefined) {

			// We sort the impacts evaluations by average descending
			impactsEvaluationsSortByScoreDescending = dataEvaluation.scores.impacts.sort((a, b) => b.average - a.average);

		} else {

			// We sort the impacts evaluations by score descending
			impactsEvaluationsSortByScoreDescending = dataEvaluation.scores.impacts.sort((a, b) => b.score - a.score);

		}

		// For each impact
		$(impactsEvaluationsSortByScoreDescending).each((i, impactEvaluation) => {

			// We get the current impact data
			const curImpactData = this.curCartography.impacts.filter((impact) => parseInt(impact.id) === parseInt(impactEvaluation.id))[0];

			if (curImpactData !== undefined) {

				// We get the impact name
				const curImpactName = curImpactData[`name_${this.currentLang}`];

				// We define the html for cartography impacy
				const htmlCartographyImpact = `
					<div class="item-score score-${slugify(curImpactData.name_en)}" data-id-impact="${curImpactData.id}">
		        <div class="tooltip-score" id="tooltip-score-${slugify(curImpactData.name_en)}">
		          <div class="num"></div>
		          <div class="text">${curImpactName}</div>
		        </div>
		        <div class="title">
		          <span class="wrap-icon">
		            <i class="icon ${curImpactData.picto}"></i>
		          </span>
		          <span class="text">${curImpactName}</span>
		        </div>
		        <ul class="list-stars"></ul>
		      </div>
				`;

				// We show the impact
				$("#impact-sub-scores").append(htmlCartographyImpact);

			}

		});

		// We set the major impact
		this.setMajorImpact(dataEvaluation);

		// We delete the all the impact item score after the first 3 items
		$("#impact-sub-scores .item-score:gt(2)").remove();

	}

	setMajorImpact (dataEvaluation) {

		let curImpactMajorId;

		// If a major impact id is given, we use it
		if (dataEvaluation.scores.impact_major_id !== undefined) {

			// We get the impact major ID
			curImpactMajorId = dataEvaluation.scores.impact_major_id;

		}
		// Otherwise we highlight the highest impact
		else {

			// We get the impact major ID
			curImpactMajorId = dataEvaluation.scores.impacts.reduce((max, impact) => {

				if (impact.score > max.score) {

					return impact;

				}
				return max;

			}).id;

		}

		// We define the major impact item
		const majorImpactItem = $(`#impact-sub-scores .item-score[data-id-impact='${curImpactMajorId}']`);

		// We show the hightlight on the major impact
		majorImpactItem.addClass("highlight");

		// We move the major impact item to the first on the impacts list
		majorImpactItem.detach().prependTo("#impact-sub-scores");

	}

	setGlobalImpactTooltip (dataEvaluation) {

		if (cleanScore(dataEvaluation.scores.impact) !== undefined && cleanScore(dataEvaluation.scores.impact) !== null) {

			$("#tooltip-score-impact").find(".num").html(cleanScore(dataEvaluation.scores.impact));

		} else {

			$("#tooltip-score-impact").remove();

		}

	}

	setImpactsTooltips (dataEvaluation) {

		// We get the impacts evaluations
		const impactsEvaluations = dataEvaluation.scores.impacts;

		// For each impact sub scores
		$("#impact-sub-scores .item-score").each(function () {

			// We get the current impact ID
			const curImpactId = $(this).attr("data-id-impact");

			if (impactsEvaluations !== undefined && impactsEvaluations.length > 0) {

				const curImpactEvaluation = impactsEvaluations.filter((impactEvaluation) => parseInt(impactEvaluation.id) === parseInt(curImpactId))[0];

				if (curImpactEvaluation !== undefined && curImpactEvaluation !== null) {

					if (curImpactEvaluation.average !== undefined && curImpactEvaluation.average !== null) {

						$(this).find(".tooltip-score .num").html(cleanScore(curImpactEvaluation.average));

					} else if (curImpactEvaluation.average !== undefined && curImpactEvaluation.average === null) {

						$(this).find(".tooltip-score").remove();

					} else {

						$(this).find(".tooltip-score .num").html(cleanScore(curImpactEvaluation.score));

					}

				} else {

					$(this).find(".tooltip-score").remove();

				}

			}

		});

	}

	setOccurrenceTooltip (dataEvaluation) {

		if (cleanScore(dataEvaluation.scores.occurrence) !== undefined && cleanScore(dataEvaluation.scores.occurrence) !== null) {

			if (dataEvaluation.scores.occurrence.average !== undefined && dataEvaluation.scores.occurrence.average !== null) {

				$("#tooltip-score-occurrence").find(".num").html(cleanScore(dataEvaluation.scores.occurrence.average));

				// We show min & max data
				this.showMinMaxData("occurrence", dataEvaluation.scores.occurrence.min, dataEvaluation.scores.occurrence.max);

			} else if (dataEvaluation.scores.occurrence.average !== undefined && dataEvaluation.scores.occurrence.average === null) {

				$("#tooltip-score-occurrence").remove();

			} else {

				$("#tooltip-score-occurrence").find(".num").html(cleanScore(dataEvaluation.scores.occurrence));

			}

		} else {

			$("#tooltip-score-occurrence").remove();

		}

	}

	setControlTooltip (dataEvaluation) {

		if (cleanScore(dataEvaluation.scores.control) !== undefined && cleanScore(dataEvaluation.scores.control) !== null) {

			if (dataEvaluation.scores.control.average !== undefined && dataEvaluation.scores.control.average !== null) {

				$("#tooltip-score-control").find(".num").html(cleanScore(dataEvaluation.scores.control.average));

				// We show min & max data
				this.showMinMaxData("control", dataEvaluation.scores.control.min, dataEvaluation.scores.control.max);

			} else if (dataEvaluation.scores.control.average !== undefined && dataEvaluation.scores.control.average === null) {

				$("#tooltip-score-control").remove();

			} else {

				$("#tooltip-score-control").find(".num").html(cleanScore(dataEvaluation.scores.control));

			}

		} else {

			$("#tooltip-score-control").remove();

		}

	}

	handleMouseOverOnGrossAndNetScores (e) {

		// We show the tooltip
		$("#section-line-marks .middle-marks .help .tooltip").addClass("active");

	}

	handleMouseOutOnGrossAndNetScores (e) {

		// We show the tooltip
		$("#section-line-marks .middle-marks .help .tooltip").removeClass("active");

	}

	handlePromiseAllEvaluationsForBusinessActivities (evaluationsForBusinessActivities) {

		// We define the status
		this.isEqualEvaluation = null;

		// For each evaluation
		evaluationsForBusinessActivities.forEach((businessActivityEvaluations) => {

			// We filter the evaluation by current risk id
			const evaluationForRisk = businessActivityEvaluations.filter((evaluation) => parseInt(evaluation.risk_id) === parseInt(this.params.idRisk))[0];

			if (this.isEqualEvaluation !== false && evaluationForRisk !== undefined) {

				if (
					this.scoreFinancialImpact === undefined &&
					this.scoreImageImpact === undefined &&
					this.scoreLegalImpact === undefined &&
					this.impactMajor === undefined &&
					this.scoreOccurrence === undefined &&
					this.scoreControl === undefined &&
					this.commentEn === undefined &&
					this.commentFr === undefined
				) {

					// We change values
					this.scoreFinancialImpact = parseInt(evaluationForRisk.scores.financial_impact);
					this.scoreImageImpact = parseInt(evaluationForRisk.scores.image_impact);
					this.scoreLegalImpact = parseInt(evaluationForRisk.scores.legal_impact);
					this.impactMajor = evaluationForRisk.scores.impact_major;
					this.scoreOccurrence = parseInt(evaluationForRisk.scores.occurrence);
					this.scoreControl = parseInt(evaluationForRisk.scores.control);
					this.commentEn = evaluationForRisk.comment_en;
					this.commentFr = evaluationForRisk.comment_fr;

				} else if (
					parseInt(this.scoreFinancialImpact) === parseInt(evaluationForRisk.scores.financial_impact) &&
					parseInt(this.scoreImageImpact) === parseInt(evaluationForRisk.scores.image_impact) &&
					parseInt(this.scoreLegalImpact) === parseInt(evaluationForRisk.scores.legal_impact) &&
					this.impactMajor === evaluationForRisk.scores.impact_major &&
					parseInt(this.scoreOccurrence) === parseInt(evaluationForRisk.scores.occurrence) &&
					parseInt(this.scoreControl) === parseInt(evaluationForRisk.scores.control) &&
					this.commentEn === evaluationForRisk.comment_en &&
					this.commentFr === evaluationForRisk.comment_fr
				) {

					// We change the status
					this.isEqualEvaluation = true;

					// We change values
					this.scoreFinancialImpact = parseInt(evaluationForRisk.scores.financial_impact);
					this.scoreImageImpact = parseInt(evaluationForRisk.scores.image_impact);
					this.scoreLegalImpact = parseInt(evaluationForRisk.scores.legal_impact);
					this.impactMajor = evaluationForRisk.scores.impact_major;
					this.scoreOccurrence = parseInt(evaluationForRisk.scores.occurrence);
					this.scoreControl = parseInt(evaluationForRisk.scores.control);
					this.commentEn = evaluationForRisk.comment_en;
					this.commentFr = evaluationForRisk.comment_fr;

				} else {

					// We change the status
					this.isEqualEvaluation = false;

				}

			}

		});

	}

	showMinMaxData (scoreSlug, dataMin, dataMax) {

		const tooltipSelector = $(`#tooltip-score-${scoreSlug}`);

		// We add a new class to the tooltip container
		tooltipSelector.addClass("average-active");

		// We add the container for min and max data
		tooltipSelector.append("<div class='box-min-max'></div>");

		let nameToShowForMax = "";
		let nameToShowForMin = "";

		if (window.location.pathname.indexOf("/business-activities/all") >= 0 &&
			dataMax.business_activity_id != 0 &&
			dataMin.business_activity_id != 0) {

			nameToShowForMax = businessActivities.find(dataMax.business_activity_id)[`name_${this.currentLang}`];
			nameToShowForMin = businessActivities.find(dataMin.business_activity_id)[`name_${this.currentLang}`];

		} else {

			nameToShowForMax = legalEntities.find(dataMax.legal_entity_id).name;
			nameToShowForMin = legalEntities.find(dataMin.legal_entity_id).name;

		}

		// We define html for max data
		const maxDataLine = `<div class="line-min-max"><span class="icon">MAX</span><span class="name-entity">${nameToShowForMax}</span><span class="num-entity">${parseFloat(dataMax.value).toFixed(1)}</span></div>`;

		// We show the max line
		tooltipSelector.find(".box-min-max").append(maxDataLine);

		// We define html for min data
		const minDataLine = `<div class="line-min-max"><span class="icon">MIN</span><span class="name-entity">${nameToShowForMin}</span><span class="num-entity">${parseFloat(dataMin.value).toFixed(1)}</span></div>`;

		// We show the min line
		tooltipSelector.find(".box-min-max").append(minDataLine);

	}

	getTooltipsColors () {

		$(".header").find(".tooltip-score").each(function () {

			const curScore = $(this).find(".num").html();

			let curTooltipColor;

			if (curScore >= 0 && curScore <= 2.25) {

				curTooltipColor = "green";

			} else if (curScore > 2.25 && curScore <= 3.5) {

				curTooltipColor = "yellow";

			} else if (curScore > 3.5 && curScore <= 4.75) {

				curTooltipColor = "orange";

			} else if (curScore > 4.75 && curScore <= 6) {

				curTooltipColor = "red";

			} else {

				curTooltipColor = "default";

			}

			// We have an exception if we are not on a progress margin model
			if (process.env.SHOW_PROGRESS_MARGIN === undefined || process.env.SHOW_PROGRESS_MARGIN === false || process.env.SHOW_PROGRESS_MARGIN === "false") {

				// If the tooltip is for control
				if ($(this).parents(".item-score").attr("class").indexOf("control") >= 0) {

					// We reverse the colors
					if (curScore >= 0 && curScore <= 2.25) {

						curTooltipColor = "red";

					} else if (curScore > 2.25 && curScore <= 3.5) {

						curTooltipColor = "orange";

					} else if (curScore > 3.5 && curScore <= 4.75) {

						curTooltipColor = "yellow";

					} else if (curScore > 4.75 && curScore <= 6) {

						curTooltipColor = "green";

					} else {

						curTooltipColor = "default";

					}

				}

			}

			$(this).addClass(`color-${curTooltipColor}`);

		});

	}

	showRiskIgnoredButton (dataEvaluation) {

		// We show the risk ignored button
		$("#btn-risk-ignored").addClass("active");

		// We update the evaluate button
		$("#btn-evaluate-risk").removeClass("active");
		$("#btn-evaluate-risk-inactive").addClass("active");

		// We listen the click on the risk ignored button
		$("#btn-risk-ignored").on("click", (e) => {

			// We show the evaluation form (like the click on the evaluate button)
			this.handleClickOnBtnEvaluateRisk();

		});

	}

	hideRiskIgnoredButton () {

		// We remove the risk ignored button
		$("#btn-risk-ignored").remove();

	}

	handleClickOnBtnEvaluateRisk () {

		// We save the current page url on the local storage
		localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

		// We check if the current legal entity have a business activity
		legalEntities.getBusinessActivities(this.params.idLegalEntity).then((data) => {

			// We filter business activities by current branch
			const businessActivitiesFilteredByCurrentBranch = businessActivities.data.filter((businessActivity) => parseInt(businessActivity.branch_id) === parseInt(context.getCurrentBranchId()));

			// We get current legal entity data
			const curLegalEntityData = legalEntities.find(context.getCurrentLegalEntityId());

			let routeToUse = "";

			// We check if the current EJ have business activities selected
			if (
				data !== undefined &&
				data !== null &&
				data.length > 0 &&
				(this.params.idBusinessActivity === undefined || this.params.idBusinessActivity === null) &&
				(process.env.SHOW_BUSINESS_ACTIVITIES === true || process.env.SHOW_BUSINESS_ACTIVITIES === "true")
			) {

				// We show the evaluation page
				routeToUse = `/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/evaluate-for-business-activities`;

			} else if (
				data !== undefined &&
				data !== null &&
				data.length > 0 &&
				this.params.idBusinessActivity !== undefined &&
				this.params.idBusinessActivity !== null &&
				(process.env.SHOW_BUSINESS_ACTIVITIES === true || process.env.SHOW_BUSINESS_ACTIVITIES === "true")
			) {

				// We show the evaluation page with current business activity
				routeToUse = `/legal-entities/${this.params.idLegalEntity}/business-activities/${this.params.idBusinessActivity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/evaluate-for-business-activities`;

			} else if (
				businessActivitiesFilteredByCurrentBranch !== undefined &&
				businessActivitiesFilteredByCurrentBranch !== null &&
				businessActivitiesFilteredByCurrentBranch.length > 0 &&
				!curLegalEntityData.has_no_business_activity &&
				(process.env.SHOW_BUSINESS_ACTIVITIES === true || process.env.SHOW_BUSINESS_ACTIVITIES === "true")
			) {

				// We save the current page url on the local storage
				localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

				// We show the admin page to select business activities
				routeToUse = `/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/risks/${this.params.idRisk}/select-business-activities`;

			} else {

				// We show the evaluation page
				routeToUse = `/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}/evaluate`;

			}

			router.navigate(routeToUse);

		});

	}

	updateSuccess (response) {

		debug.v("Risk - updateSuccess");

		router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.params.idCartography}/risks/${this.params.idRisk}`);

	}

	updateFail (response, textStatus) {

		debug.v("Risk - updateFail");

	}

	addUserOptions () {

		$(users.data).each((i, userToAdd) => {

			$("FORM [name=user_id]").append(`<option value='${userToAdd.id}'>${userToAdd.standard_name}</option>`);

		});

	}

	handleClickOnBtnEditRisk () {

		// We save the current page url on the local storage
		localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

		if (this.params.idLegalEntity !== undefined) {

			router.navigate(`/legal-entities/${this.params.idLegalEntity}/cartographies/${this.curCartographyId}/risks/${this.curRiskId}/edit`);

		} else {

			router.navigate(`/cartographies/${this.curCartographyId}/risks/${this.curRiskId}/edit`);

		}

	}

}
export default new Risk();
