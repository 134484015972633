import helpFirstActionItems from "Datapoints/HelpFirstActionItems";
import html from "./help_first_action_banner.html";
import "./HelpFirstActionBanner.scss";
import "./HelpFirstActionBannerResponsive.scss";

class HelpFirstActionBanner {

	init (idItem) {

		// We remove the help banner
		this.remove();

		// We check if a step by step help is already in action
		const curHelpItemId = localStorage.getItem("help-itemId");
		const isStepByStepHelpActive = Boolean(curHelpItemId !== undefined && curHelpItemId !== null);


		if (idItem !== undefined && idItem !== null && !isStepByStepHelpActive) {

			// We load all the items
			this.helpFirstActionItemsData = helpFirstActionItems.getData();

			// We get the currentitem data
			this.curHelpFirstActionItemData = this.helpFirstActionItemsData.filter((helpFirstActionItem) => helpFirstActionItem.id === idItem);

			// We show the item
			if (this.curHelpFirstActionItemData !== undefined && this.curHelpFirstActionItemData.length > 0) {

				// We define the item to show
				this.itemToShow = this.curHelpFirstActionItemData[0];

				// We get the max of displays for this item
				const curItemMaxDisplays = this.itemToShow.max_displays;

				// We get the number of display saved on the local storage for this item
				this.itemNumDisplay = localStorage.getItem(this.itemToShow.id);

				if (
				    this.itemNumDisplay === undefined ||
				    this.itemNumDisplay === null ||
				    parseInt(this.itemNumDisplay) < parseInt(curItemMaxDisplays)
				) {

					// We show the item
					this.show(this.itemToShow);

				}

			}

		}

	}

	show (itemData) {

		// We show the item on the page content
		$("#page").append(html);

		// We get the item content
		const itemContent = itemData.content;

		// We show the item content
		$("#help-first-action-banner .content").html(itemContent);

		// We listen click on the button close
		$("#help-first-action-banner .btn-close").on("click", (event) => {

			this.handleClickOnBtnClose(itemData);

		});

		// We display the primary focus
		this.displayPrimaryFocus(itemData);

		// We update the informations saved in the local storage
		this.updateLocalStorageInfos(itemData);

	}

	remove () {

		// We remove the help banner
		$("#help-first-action-banner").remove();

		// We remove the animations
		$(".animate__animated.animate__heartBeat.animate__infinite").removeClass("animate__animated animate__heartBeat animate__infinite animate__slow");

	}

	updateLocalStorageInfos (itemData) {

		if (this.itemNumDisplay !== undefined && this.itemNumDisplay !== null) {

			const newItemNumDisplay = parseInt(this.itemNumDisplay) + 1;

			localStorage.setItem(itemData.id, newItemNumDisplay);

		} else {

			localStorage.setItem(itemData.id, 1);

		}

	}

	displayPrimaryFocus (itemData) {

		const curFocusHtmlBlockPrimary = itemData.focus_html_block_primary;

		if (curFocusHtmlBlockPrimary !== undefined) {

			setTimeout(() => {

				$(curFocusHtmlBlockPrimary).addClass("animate__animated animate__heartBeat animate__infinite animate__slow");

			}, 2000);

		}

	}

	handleClickOnBtnClose (itemData) {

		// We launch the animation to hide the help banner
		$("#help-first-action-banner").addClass("inactive");

		// We remove the animations
		const curFocusHtmlBlockPrimary = itemData.focus_html_block_primary;
		$(curFocusHtmlBlockPrimary).removeClass("animate__animated animate__heartBeat animate__infinite animate__slow");

	}

}
export default new HelpFirstActionBanner();
