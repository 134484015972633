import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class GoFurther extends Datapoint {

	find (idToFind) {

		return this.data.find((goFurther) => goFurther.id == idToFind);

	}

	getData (params) {

		if (params == undefined) {

			debug.e("Error in getData of GoFurther, no params object has been set.");
			return;

		}

		if (params.idRisk == undefined) {

			debug.e("Error in getData of GoFurther, the Risk id is undefined.");
			return;

		}

		let riskId = params.idRisk;

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/gofurthers`
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	addGoFurther (goFurther, riskId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${riskId}/gofurther/create`,
				"data": goFurther,
				"processData": false,
				"contentType": false
			}).
				done(resolve).
				fail(reject);

		});

	}

	updateGoFurther (goFurther, riskId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": `/risk/${riskId}/gofurther/update`,
				"data": goFurther
			}).
				done(resolve).
				fail(reject);

		});

	}

	deleteGoFurther (riskId, goFurtherId) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/risk/${riskId}/gofurther/delete/${goFurtherId}`
			}).
				done(resolve).
				fail(reject);

		});

	}

}
export default new GoFurther();
