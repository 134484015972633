import router from "Core/router.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import risks from "Datapoints/Risks.js";
import html from "./graph_gross_risk_and_progress_margin.html";

class GraphGrossRiskAndProgressMargin {

	appendAndFill (cssSelector, risks, evaluations, style) {

		// We show the graph
		$(cssSelector).append(html);

		// We show the risks bullets and cards
		this.showRisksBulletsAndCards(risks, evaluations, style);

		if (process.env.SHOW_PROGRESS_MARGIN === true || process.env.SHOW_PROGRESS_MARGIN === "true") {

			// We setup the graph for progress margin mode
			this.initProgressMargin();

		}

	}

	initProgressMargin () {

		// We get the thumbnail url of the graph
		const thumbGraphUrl = $("#graph-gross-risk-and-progress-margin .graph-title .graph-title-infos .picture img").attr("src");

		// We define the new thumbnail url of the graph
		const newThumbGraphUrl = thumbGraphUrl.replace("_reverse", "");

		// We update the thumbnail of the graph
		$("#graph-gross-risk-and-progress-margin .graph-title .graph-title-infos .picture img").attr("src", newThumbGraphUrl);

		// We remove the reverse class one the graph gradient
		$("#graph-gross-risk-and-progress-margin .box-graph .gradient").removeClass("reverse");

	}

	showRisksBulletsAndCards (riskData, evaluationData, style) {

		if (window.location.pathname.indexOf("preview") >= 0) {

			// We add the preview class on the container
			$("#graph-gross-risk-and-progress-margin .box-graph").addClass("preview");

		}

		// We add the style class to the graph container
		if (style !== undefined && style !== null) {

			$("#graph-gross-risk-and-progress-margin").addClass(style);

		}

		// We show the risks
		riskData.forEach((item) => {

			const card = new CardRisk(item);

			if (item.status !== undefined && item.status === "valid") {

				// We get the evaluation
				if (evaluationData !== undefined && evaluationData.error === undefined) {

					const curRiskEvaluation = evaluationData.filter((evaluation) => parseInt(evaluation.risk_id) === parseInt(item.id));

					let curItemColor;
					if (
						curRiskEvaluation[0] !== undefined &&
						curRiskEvaluation[0].scores.impact !== null &&
						curRiskEvaluation[0].scores.gross_risk !== null &&
						(curRiskEvaluation[0].scores.control !== null || curRiskEvaluation[0].scores.control.average !== null)
					) {

						curItemColor = card.getCardColor(curRiskEvaluation[0]);

						let convertedScoreImpact;
						if (curRiskEvaluation[0].scores.impact !== null && curRiskEvaluation[0].scores.impact.toString().indexOf(",") > -1) {

							convertedScoreImpact = curRiskEvaluation[0].scores.impact.toString().replace(",", ".");

						} else {

							convertedScoreImpact = curRiskEvaluation[0].scores.impact;

						}

						let convertedScoreGrossRisk;
						if (curRiskEvaluation[0].scores.gross_risk !== undefined && curRiskEvaluation[0].scores.gross_risk.toString().indexOf(",") > -1) {

							convertedScoreGrossRisk = curRiskEvaluation[0].scores.gross_risk.toString().replace(",", ".");

						} else {

							convertedScoreGrossRisk = curRiskEvaluation[0].scores.gross_risk;

						}

						let convertedScoreControl;
						if (
						  curRiskEvaluation[0].scores.control.average === undefined &&
							curRiskEvaluation[0].scores.control !== null &&
							curRiskEvaluation[0].scores.control.toString().indexOf(",") > -1
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.control.average !== undefined &&
							curRiskEvaluation[0].scores.control.average !== null &&
							curRiskEvaluation[0].scores.control.average.toString().indexOf(",") > -1
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.average.toString().replace(",", ".");

						} else if (
							curRiskEvaluation[0].scores.control.average !== undefined &&
							curRiskEvaluation[0].scores.control.average !== null &&
							curRiskEvaluation[0].scores.control.average.toString().indexOf(",") < 0
						) {

							convertedScoreControl = curRiskEvaluation[0].scores.control.average;

						} else {

							convertedScoreControl = curRiskEvaluation[0].scores.control;

						}

						// We define html
						const htmlRiskOnGraph = `<li class="item-risk" id="item-risk-${item.id}" data-score-impact="${convertedScoreImpact}" data-score-gross-risk="${convertedScoreGrossRisk}" data-score-control="${convertedScoreControl}"><div class="bullet-risk" data-id-risk="${item.id}"><span class="bullet"></span></div></li>`;

						$("#graph-gross-risk-and-progress-margin .canvas ul.list-risks").append(htmlRiskOnGraph);

						// We define positions and sizes
						const maxScore = 6;
						const widthCanvas = $(`#item-risk-${item.id}`).parents(".canvas").width();
						const heightCanvas = $(`#item-risk-${item.id}`).parents(".canvas").height();
						const impactSize = convertedScoreImpact * 15;
						const axisSize = 5;
						const posGrossRisk = heightCanvas / (maxScore + 1) * convertedScoreGrossRisk;
						const posBottomBullet = posGrossRisk - impactSize / 2;
						const posControl = widthCanvas / (maxScore + 1) * convertedScoreControl;
						const posLeftBullet = posControl - impactSize / 2;

						$(`#item-risk-${item.id}`).css({
							"left": `${posLeftBullet}px`,
							"bottom": `${posBottomBullet}px`
						});

						if (window.location.pathname.indexOf("preview") >= 0) {

							$(`#item-risk-${item.id}`).find(".bullet-risk .bullet").css({
								"width": `${impactSize * 1.5}px`,
								"height": `${impactSize * 1.5}px`
							});

						} else {

							$(`#item-risk-${item.id}`).find(".bullet-risk .bullet").css({
								"width": `${impactSize}px`,
								"height": `${impactSize}px`
							});

						}


						card.appendAndFill("#graph-card-risk-active", curItemColor, item, curRiskEvaluation[0].scores);
						this.htmlAllCardRiskOver = $("#graph-card-risk-active").html();

					}

				}

			}

		});

		if (window.location.pathname.indexOf("preview") >= 0) {

			// We move the list of risks
			$("#graph-card-risk-active").insertAfter("#graph-gross-risk-and-progress-margin .graph-card");

			// We add the preview class on the risks cards
			$("#graph-card-risk-active .card-risk").addClass("preview");

			// We remove all the score tooltips and other elemnens on the risks cards
			$("#graph-card-risk-active .card-risk .tooltip-score").remove();
			$("#graph-card-risk-active .card-risk .arrow").remove();
			$("#graph-card-risk-active .card-risk .sep").remove();
			$("#graph-card-risk-active .card-risk .btn-more").remove();
			$("#graph-card-risk-active .card-risk .content-card-risk").remove();
			$("#graph-card-risk-active .card-risk .evaluate-risk").remove();
			$("#graph-card-risk-active .tooltip-score").remove();

			// For each risk card
			$("#graph-card-risk-active .card-risk").each(function () {

				// We get the risk id
				const curRiskId = $(this).attr("data-id-risk");

				// We add the risk id before the card risk
				$(this).prepend(`<div class="big-num-risk">${curRiskId}</div>`);

			});

			// For each risk bullet on the graph
			$("#graph-gross-risk-and-progress-margin .bullet-risk").each(function () {

				// We get the risk id
				const curRiskId = $(this).attr("data-id-risk");

				// We add the risk id before the card risk
				$(this).find(".bullet").html(curRiskId);

			});

		} else {

			// We listen mouseover on bullet risk
			$("#graph-gross-risk-and-progress-margin .canvas ul.list-risks li.item-risk .bullet-risk").on("mouseover", this.handleMouseOverOnBulletRisk.bind(this));
			$("#graph-gross-risk-and-progress-margin .canvas ul.list-risks li.item-risk .bullet-risk").on("mouseleave", this.handleMouseLeaveOnBulletRisk.bind(this));

		}

		// We listen to click on the risks
		$("#graph-gross-risk-and-progress-margin .canvas ul.list-risks li.item-risk .bullet-risk").on("click", this.handleClickOnRisks.bind(this));

	}

	handleMouseOverOnBulletRisk (e) {

		// We hide the card risk active
		this.hideCardRiskActive();

		const curIdRisk = $(e.currentTarget).attr("data-id-risk");
		const curBulletWidth = $(e.currentTarget).width();
		const curBulletHeight = $(e.currentTarget).height();
		const curPosTop = $(e.currentTarget).parent(".item-risk").position().top;
		const curPosLeft = $(e.currentTarget).parent(".item-risk").position().left;

		$("#graph-card-risk-active").css("z-index", "5");

		const curCardRisk = $("#graph-card-risk-active").find(`.card-risk-${curIdRisk}`);
		curCardRisk.addClass("active");

		$("#graph-card-risk-active .card-risk:not(.active)").remove();

		$("#graph-card-risk-active").css("top", `${curPosTop + curBulletHeight}px`);
		$("#graph-card-risk-active").css("left", `${curPosLeft - curBulletWidth * 2}px`);

		// We listen to click on the risks
		$("#graph-gross-risk-and-progress-margin .box-graph .card-risk-over .card-risk.active").on("click", this.handleClickOnRisks.bind(this));

		// We listen mouseleave on card risk
		$("#graph-gross-risk-and-progress-margin .box-graph .card-risk-over .card-risk").on("mouseleave", this.handleMouseLeaveOnCardRisk.bind(this));

	}

	handleMouseLeaveOnBulletRisk (e) {

		// If the mouse over is NOT on the .card-risk element
		if ($(".card-risk:hover").length != 0) {

		} else {

			// We hide the card risk active
			this.hideCardRiskActive();

		}

	}

	handleMouseLeaveOnCardRisk (e) {

		// We hide the card risk active
		this.hideCardRiskActive();

	}

	hideCardRiskActive () {

		$("#graph-card-risk-active").html(this.htmlAllCardRiskOver);
		$("#graph-card-risk-active").css("top", "0px");
		$("#graph-card-risk-active").css("left", "0px");

	}

	handleClickOnRisks (e) {

		// Get the ID of the current risk
		const curIdRisk = $(e.currentTarget).attr("data-id-risk");

		let routeToShow;

		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all legal entities context
			routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all profit centers context
			routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			// We show the risk page with all countries context
			routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else {

			// We show the risk page
			routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

}
export default new GraphGrossRiskAndProgressMargin();
