import router from "Core/router.js";
import api from "Core/Api.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class ProfitCenters extends Datapoint {

	constructor () {

		super();
		this.ignoreCleaning = true;

	}

	getData (params) {

		return new Promise((resolve, reject) => {

			let forceRequest = params != undefined && params.forceRequest != undefined ? params.forceRequest : false;

			if (this.data != undefined && !forceRequest) {

				resolve();
				return;

			}

			api.call({
				"method": "GET",
				"url": "/profit-centers"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}

	isInGivenProfitCenters (profitCenterToTest) {

		if (this.data != undefined) {

			return this.data.filter((profitCenter) => profitCenter.id == profitCenterToTest).length > 0;

		}
		return false;

	}

	getProfitCentersForBranch (idBranch) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": `/profit-centers/for-branch/${idBranch}`
			}).
				done(resolve).
				fail(reject);

		});

	}

	find (idToFind) {

		let profitCenter;
		if (this.data != undefined) {

			profitCenter = this.data.find((profitCenter) => parseInt(profitCenter.id) == parseInt(idToFind));

		}

		return profitCenter;

	}

}
export default new ProfitCenters();
