import router from "Core/router.js";
import Page from "Pages/Page.js";
import debug from "Core/Debug.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import html from "./statistics.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import risks from "Datapoints/Risks.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import legalEntities from "Datapoints/LegalEntities.js";
import statistics from "Datapoints/Statistics.js";
import "./Statistics.scss";
import "./StatisticsResponsive.scss";
// Import EasyPieChart
import "Core/assets/lib/jquery.easypiechart.min.js";

class Statistics extends Page {

	constructor () {

		let routes = [
			"/statistics",
			"/statistics/branches/:idBranch/cartographies/:idCartography"
		];

		let datapointsToLoad = [
			cartographies,
			branches,
			legalEntities,
			profitCenters
		];

		super(routes, AuthentifiedLayout, "Statistiques", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canAccessStatistics.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.parseAndRender("page", html);
			menu.show();

			this.params = params;

			// We define the html for the message if there is no statistic data
			this.htmlMsgNoData = `<div class="msg-no-data" data-i18n="title-msg-no-stat-data">${$.i18n("title-msg-no-stat-data")}</div>`;

			// We init page events
			this.initEvents();

			// We init the main statistics table
			this.initMainStatistics().then(resolve);

		});

	}

	initEvents () {

		// We define the current branch id
		let branchId;

		if (
			this.params !== undefined &&
			this.params.idBranch !== undefined &&
			this.params.idBranch !== null
		) {

			branchId = parseInt(this.params.idBranch);

		} else {

			branchId = parseInt(context.getCurrentBranchId());

		}

		this.curBranchId = branchId;

		// We define the current cartography id
		let cartographyId;

		if (
			this.params !== undefined &&
			this.params.idCartography !== undefined &&
			this.params.idCartography !== null
		) {

			cartographyId = parseInt(this.params.idCartography);

		} else {

			cartographyId = parseInt(cartographies.getCurrentCartographyId());

		}

		this.curCartographyId = cartographyId;

		// We init the box filter
		this.initBoxFilter();

	}

	initBoxFilter () {

		// We init the branch filter
		this.initBranchFilter();

		// We init the cartography filter
		this.initCartographyFilter();

		// We define the click outside the filters list name
		$(document).click(() => {

		    this.hideListCartographiesName();
		    this.hideListBranchesName();

		});
		$("#name-cartography").click((e) => {

		    e.stopPropagation();

		});
		$("#name-branch").click((e) => {

		    e.stopPropagation();

		});

		// We catch the scroll on the page
		$(window).scroll((e) => {

			// We get the scroll
			const scroll = $(window).scrollTop();

			if (scroll > 60) {

				// We update the width of the box filter
				$(".statistics-container .title-page .box-filter").removeClass("full-width");
				$(".statistics-container .title-page .box-filter").addClass("full-width");

			} else {

				// We update the width of the box filter
				$(".statistics-container .title-page .box-filter").removeClass("full-width");

			}

		});

	}

	initBranchFilter () {

		// We define the current branch data
		const curBranchData = branches.find(this.curBranchId);

		// We get the list of current user "access_statistics" rights
		const listAccessStatisticsRights = user.data.rights.filter((right) => right.rights.indexOf("access_statistics") >= 0);

		// We get the list of branches on which current user has access_statistics right
		const listAccessStatisticsBranches = [];
		listAccessStatisticsRights.forEach((right) => {

			if (listAccessStatisticsBranches.indexOf(parseInt(right.branch_id)) < 0) {

				listAccessStatisticsBranches.push(parseInt(right.branch_id));

			}

		});

		// We create a new array with branches except current branch
		const listOtherBranches = listAccessStatisticsBranches.filter((idBranch) => parseInt(idBranch) !== parseInt(this.curBranchId));

		// We show the name of the current branch
		$("#name-branch").find(".option-selected .text").html(curBranchData[`${this.currentLang}`]);
		$("#name-branch .option-selected").attr("data-branch-id", this.curBranchId);

		// We show the branches name
		if (listOtherBranches !== undefined && listOtherBranches.length > 0) {

			$("#name-branch").removeClass("no-select");

			listOtherBranches.forEach((branchId) => {

				const branchData = branches.find(branchId);

				let textBranchName;
				if (branchData[`${this.currentLang}`] !== null && branchData[`${this.currentLang}`] !== undefined) {

					textBranchName = branchData[`${this.currentLang}`];

				} else {

					textBranchName = "";

				}

				// We define html for branches options
				const htmlOption = `<li class="option" data-branch-id="${branchData.id}"><a href="/statistics/branches/${branchData.id}/cartographies/${this.curCartographyId}">${textBranchName}</a></li>`;


				$("#name-branch .list-options").append(htmlOption);

			});

			// We listen click on branch name
			if (listOtherBranches.length > 0) {

				$("#name-branch .option-selected").on("click", this.handleClickOnBranchName.bind(this));

			}

			// We listen click on options select branch
			$("#name-branch .list-options .option").on("click", this.handleClickOnOptionBranch.bind(this));

			// After render we make sure the shown links are handled by the router, not thrue the browser
			router.refreshLinks();

		} else {

			$("#name-branch").addClass("no-select");

		}

	}

	initCartographyFilter () {

		// We define the current cartography data
		const curCartographyData = cartographies.find(this.curCartographyId);

		// We create a new array with cartographies except current cartography
		const listOtherCartographies = cartographies.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(this.curBranchId) && parseInt(cartography.id) !== parseInt(this.curCartographyId));

		// We show the name of the current cartography
		$("#name-cartography").find(".option-selected .text").html(curCartographyData[`name_${this.currentLang}`]);
		$("#name-cartography .option-selected").attr("data-cartography-id", this.curCartographyId);

		// We show the cartographies name
		if (listOtherCartographies.length > 0) {

			$("#name-cartography").removeClass("no-select");

			listOtherCartographies.forEach((cartography) => {

				let textCartographyName;
				if (cartography[`name_${this.currentLang}`] !== null && cartography[`name_${this.currentLang}`] !== undefined) {

					textCartographyName = cartography[`name_${this.currentLang}`];

				} else {

					textCartographyName = "";

				}

				// We define html for cartographies options
				const htmlOption = `<li class="option" data-cartography-id="${cartography.id}"><a href="/statistics/branches/${this.curBranchId}/cartographies/${cartography.id}">${textCartographyName}</a></li>`;

				$("#name-cartography .list-options").append(htmlOption);

			});

			// We listen click on cartography name
			if (listOtherCartographies.length > 0) {

				$("#name-cartography .option-selected").on("click", this.handleClickOnCartographyName.bind(this));

			}

			// We listen click on options select cartography
			$("#name-cartography .list-options .option").on("click", this.handleClickOnOptionCartography.bind(this));

			// After render we make sure the shown links are handled by the router, not thrue the browser
			router.refreshLinks();

		} else {

			$("#name-cartography").addClass("no-select");

		}

		if (listOtherCartographies.length === 0) {

			$("#name-cartography").addClass("no-select");

		}

	}

	initMainStatistics () {

		return new Promise((resolve, reject) => {

			// We get the profit centers progression
			statistics.getProfitCentersProgression(this.curBranchId, this.curCartographyId).then((listProfitCenters) => {

				if (listProfitCenters !== undefined && listProfitCenters.length > 0) {

					listProfitCenters.forEach((profitCenterProgression) => {

						// We get the profit center data
						const profitCenterData = profitCenters.find(parseInt(profitCenterProgression.profit_center_id));

						// We add the sort position of the profit center on each profit center progression
						profitCenterProgression.sort_position = profitCenterData.sort_position;

					});

					// We sort the profit centers progression by position
					const profitCentersProgressionSortByPosition = listProfitCenters.sort((a, b) => a.sort_position - b.sort_position);

					profitCentersProgressionSortByPosition.forEach((profitCenter) => {

						// We get profit center name
						const profitCenterName = this.getProfitCenterName(profitCenter);

						// We get profit center progression
						const profitCenterProgression = this.getProfitCenterProgression(profitCenter);

						// We define the html line table
						const htmlLineTable = `
							<div class="line-table" id="line-profit-center-${profitCenter.profit_center_id}">
								<div class="btn-arrow">
									<span class="arrow fas fa-angle-down"></span>
								</div>
								<div class="stats-profit-center">
									<div class="col-table name">${profitCenterName}</div>
									<div class="col-table progression">${profitCenterProgression}</div>
								</div>
							</div>
						`;

						// We show the line table
						$("#performance-and-traffic-by-profit-centers .content-stat .table").append(htmlLineTable);

						// We init the progress chart circles animations
						this.initProfitCentersProgressCircles();

					});

					// We load the profit centers traffic
					this.loadProfitCentersTraffic();

					// We load the legal entities progression and traffic
					this.loadLegalEntitiesProgressionAndTraffic().catch(debug.e).then(resolve);

					// We listen click on profit center line
					$("#performance-and-traffic-by-profit-centers .content-stat .table .line-table").on("click", this.handleClickOnProfitCenterLine.bind(this));

					// We listen the window resize
					$(window).on("resize", () => {

						// We hide all the drill down
						this.hideLegalEntitiesStats();

					});

				} else {

					// We show the no data message
					$("#performance-and-traffic-by-profit-centers .content-stat").html(this.htmlMsgNoData);

					resolve();

				}

			}).catch((error) => {

				// We show the no data message
				$("#performance-and-traffic-by-profit-centers .content-stat").html(this.htmlMsgNoData);

				resolve();

			});

		});

	}

	handleClickOnProfitCenterLine (e) {

		if ($(e.currentTarget).attr("class").indexOf("show-drill-down") < 0) {

			const curContainerId = $(e.currentTarget).attr("id");

			this.showLegalEntitiesStats(curContainerId);

		} else {

			this.hideLegalEntitiesStats();

		}

	}

	showLegalEntitiesStats (containerId) {

		// We hide all the drill down
		this.hideLegalEntitiesStats();

		// We show the drill down
		$(`#${containerId}`).addClass("show-drill-down");

		// We get the height of the container
		const containerHeight = $(`#${containerId}`).outerHeight();

		// We get the height of the list of legal entities
		const listLegalEntitiesHeight = $(`#${containerId}`).find(".list-legal-entities").outerHeight();

		// We change the height of the container
		$(`#${containerId}`).css("height", containerHeight + listLegalEntitiesHeight);

	}

	hideLegalEntitiesStats () {

		// We hide all the drill down
		$("#performance-and-traffic-by-profit-centers .content-stat .table .line-table:not(.line-title)").removeClass("show-drill-down");

		// We init the height by default
		$("#performance-and-traffic-by-profit-centers .content-stat .table .line-table:not(.line-title)").css("height", 182);

	}

	loadLegalEntitiesProgressionAndTraffic () {

		return new Promise((resolve, reject) => {

			// We define the html of the container of the legal entities list (with a no data message by default)
			const htmlLegalEntitiesList = `
				<div class="list-legal-entities">
					<div class="title-legal-entities" data-i18n="title-stats-list-legal-entities">
						<span class="icon fas fa-laptop-house"></span>
						<span class="text">${$.i18n("title-stats-list-legal-entities")}</span>
					</div>
					<div class="msg-no-data-legal-entities" data-i18n="title-msg-no-stat-data">${$.i18n("title-msg-no-stat-data")}</div>
				</div>
			`;

			// We show this container on all profit centers lines
			$("#performance-and-traffic-by-profit-centers .content-stat .table .line-table:not(.line-title)").each((index, item) => {

				const curItemId = $(item).attr("id");

				$(`#${curItemId}`).append(htmlLegalEntitiesList);

			});

			// We get the legal entities progression
			statistics.getLegalEntitiesProgression(this.curBranchId, this.curCartographyId).then((listLegalEntities) => {

				if (listLegalEntities !== undefined && listLegalEntities.length > 0) {

					listLegalEntities.forEach((legalEntityProgression) => {

						// We get the legal entity data
						const legalEntityData = legalEntities.find(parseInt(legalEntityProgression.legal_entity_id));

						if (legalEntityData != undefined) {

							// We get the profit center id of the current legal entity
							const legalEntityProfitCenterId = parseInt(legalEntityData.profit_center_id);

							// We calculate the completion percentage
							const legalEntityCompletionPercentage = (legalEntityProgression.cartographies[0].completion_percentage * 100).toFixed(2);

							// We define the class name of the completion
							let completionClassName = "";
							if (legalEntityCompletionPercentage <= 0) {

								completionClassName = "not-started";

							} else if (legalEntityCompletionPercentage >= 100) {

								completionClassName = "complete";

							} else {

								completionClassName = "incomplete";

							}

							// We define the html of the legal entity item
							const htmlLegalEntityItem = `
							<div class="item-legal-entity ${completionClassName}" id="item-legal-entity-${legalEntityData.id}">
								<div class="name-legal-entity">${legalEntityData.name}</div>
								<div class="stats-legal-entity">
									<div class="item-stat legal-entity-completion">
										<span class="icon fas fa-check-square"></span>
										<span class="text" data-i18n="title-item-stat-completion">${$.i18n("title-item-stat-completion")}</span>
										<span class="value">${legalEntityCompletionPercentage}%</span>
									</div>
									<div class="item-stat legal-entity-traffic">
										<span class="icon fas fa-chart-area"></span>
										<span class="text" data-i18n="title-item-stat-connections">${$.i18n("title-item-stat-connections")}</span>
										<span class="value"></span>
									</div>
									<div class="item-stat legal-entity-completed-cartographies">
										<span class="icon fas fa-chalkboard-teacher"></span>
										<span class="text" data-i18n="title-item-stat-completed-cartographies">${$.i18n("title-item-stat-completed-cartographies")}</span>
										<span class="value"></span>
									</div>
								</div>
							</div>
						`;

							// We hide the no data message ine the current profit center line
							$(`#line-profit-center-${legalEntityProfitCenterId} .list-legal-entities .msg-no-data-legal-entities`).remove();

							// We show the legal entity item
							$(`#line-profit-center-${legalEntityProfitCenterId} .list-legal-entities`).append(htmlLegalEntityItem);

						}

					});

					// We load the legal entities traffic
					this.loadLegalEntitiesTraffic();

					// We load the legal entities completed cartographies
					this.loadLegalEntitiesCompletedCartographies();

					resolve();

				}

			}).catch(debug.e);

		});

	}

	loadLegalEntitiesTraffic () {

		// We define the date object
		const date = new Date();

		// We define the start date
		const startDay = `0${date.getDate()}`.slice(-2);
		const startMonth = `0${date.getMonth()}`.slice(-2);
		const startYear = date.getFullYear();
		const startDate = `${startYear}-${startMonth}-${startDay}`;

		// We define the end date
		const endDay = `0${date.getDate()}`.slice(-2);
		const endMonth = `0${date.getMonth() + 1}`.slice(-2);
		const endYear = date.getFullYear();
		const endDate = `${endYear}-${endMonth}-${endDay}`;

		statistics.getTrafficPerLegalEntities(this.curBranchId, startDate, endDate).then((listLegalEntitiesTraffic) => {

			if (listLegalEntitiesTraffic !== undefined && listLegalEntitiesTraffic.length > 0) {

				listLegalEntitiesTraffic.forEach((legalEntityTraffic) => {

					// We get the current legal entity id
					const curLegalEntityId = parseInt(legalEntityTraffic.legal_entity_id);

					// We get the login count
					const legalEntityLoginCount = parseInt(legalEntityTraffic.login_count);

					// We get the total user
					const legalEntityTotalUser = parseInt(legalEntityTraffic.total_user);

					// We show the traffic
					$(`#item-legal-entity-${curLegalEntityId} .stats-legal-entity .item-stat.legal-entity-traffic .value`).html(`${legalEntityLoginCount} ${$.i18n("title-out-of")} ${legalEntityTotalUser}`);

				});

			}

		});

	}

	loadLegalEntitiesCompletedCartographies () {

		statistics.getLegalEntitiesProgressionForAllCartographies(this.curBranchId).then((listLegalEntities) => {

			if (listLegalEntities !== undefined && listLegalEntities.length > 0) {

				listLegalEntities.forEach((legalEntity) => {

					// We get the current legal entity id
					const curLegalEntityId = parseInt(legalEntity.legal_entity_id);

					// We calculate the number of completed cartographies
					const totalCompletedCartographies = legalEntity.cartographies.filter((cartography) => cartography.completion_percentage === 1 || cartography.completion_percentage === "1").length;

					// We show the completed cartographies number
					$(`#item-legal-entity-${curLegalEntityId} .stats-legal-entity .item-stat.legal-entity-completed-cartographies .value`).html(totalCompletedCartographies);

				});

			}

		});

	}

	getProfitCenterName (profitCenter) {

		// We get the profit center data
		const profitCenterData = profitCenters.find(parseInt(profitCenter.profit_center_id));

		const htmlProfitCenterName = `
			<div class="title-name">${profitCenterData[`${this.currentLang}`]}</div>
			<div class="ref" data-i18n="[title]risk-info-tooltip-reference">
	    	<span class="icon fas fa-barcode"></span>
	    	<span class="text">${profitCenterData.reference}</span>
	    </div>
		`;

		return htmlProfitCenterName;

	}

	getProfitCenterProgression (profitCenter) {

		// We get the profit center data
		const profitCenterData = profitCenters.find(parseInt(profitCenter.profit_center_id));

		// We get the not started number
		const numNotStarted = parseInt(profitCenter.legal_entities_completion_sums.not_started);

		// We get the incomplete number
		const numIncomplete = parseInt(profitCenter.legal_entities_completion_sums.incomplete);

		// We get the complete number
		const numComplete = parseInt(profitCenter.legal_entities_completion_sums.complete);

		// We get the sum of legal entities in the profit center
		const sumProfitCenterLegalEntities = numNotStarted + numIncomplete + numComplete;

		// We define the companies wording
		let companiesWording;
		if (sumProfitCenterLegalEntities > 1) {

			companiesWording = $.i18n("title-companies");

		} else {

			companiesWording = $.i18n("title-company");

		}

		// CIRCLE - NOT STARTED
		const percentageNotStarted = parseInt(numNotStarted * 100 / sumProfitCenterLegalEntities);
		const htmlNotStartedCircle = `
			<div class="box-chart not-started">
				<div class="chart-circle not-started" data-percent="${percentageNotStarted}">
	        <span class="chart-number small">${percentageNotStarted}%</span>
	      </div>
	      <div class="mention"><span class="highlight">${numNotStarted}</span> ${$.i18n("title-out-of")} <span class="highlight">${sumProfitCenterLegalEntities}</span><br />${companiesWording}</div>
			</div>
		`;

		// CIRCLE - INCOMPLETE
		const percentageIncomplete = parseInt(numIncomplete * 100 / sumProfitCenterLegalEntities);
		const htmlIncompleteCircle = `
			<div class="box-chart incomplete">
				<div class="chart-circle incomplete" data-percent="${percentageIncomplete}">
	        <span class="chart-number small">${percentageIncomplete}%</span>
	      </div>
	      <div class="mention"><span class="highlight">${numIncomplete}</span> ${$.i18n("title-out-of")} <span class="highlight">${sumProfitCenterLegalEntities}</span><br />${companiesWording}</div>
	     </div>
		`;

		// CIRCLE - COMPLETE
		const percentageComplete = parseInt(numComplete * 100 / sumProfitCenterLegalEntities);
		const htmlCompleteCircle = `
			<div class="box-chart complete">
				<div class="chart-circle complete" data-percent="${percentageComplete}">
	        <span class="chart-number small">${percentageComplete}%</span>
	      </div>
	      <div class="mention"><span class="highlight">${numComplete}</span> ${$.i18n("title-out-of")} <span class="highlight">${sumProfitCenterLegalEntities}</span><br />${companiesWording}</div>
	     </div>
		`;

		const htmlProfitCenterProgression = `${htmlNotStartedCircle}${htmlIncompleteCircle}${htmlCompleteCircle}`;

		return htmlProfitCenterProgression;

	}

	loadProfitCentersTraffic () {

		// We define the date object
		const date = new Date();

		// We define the start date
		const startDay = `0${date.getDate()}`.slice(-2);
		const startMonth = `0${date.getMonth()}`.slice(-2);
		const startYear = date.getFullYear();
		const startDate = `${startYear}-${startMonth}-${startDay}`;

		// We define the end date
		const endDay = `0${date.getDate()}`.slice(-2);
		const endMonth = `0${date.getMonth() + 1}`.slice(-2);
		const endYear = date.getFullYear();
		const endDate = `${endYear}-${endMonth}-${endDay}`;

		// We show the period on the traffic tooltip
		$("#tooltip-period-start").html(`${startDay}-${startMonth}-${startYear}`);
		$("#tooltip-period-end").html(`${endDay}-${endMonth}-${endYear}`);


		statistics.getTrafficPerProfitCenters(this.curBranchId, startDate, endDate).then((listProfitCentersTraffic) => {

			if (listProfitCentersTraffic !== undefined && listProfitCentersTraffic.length > 0) {

				listProfitCentersTraffic.forEach((profitCenterTraffic) => {

					// We get the login count
					const profitCenterLoginCount = parseInt(profitCenterTraffic.login_count);

					// We get the total user
					const profitCenterTotalUser = parseInt(profitCenterTraffic.total_user);

					// We get the percentage login
					const profitCenterPercentageLogin = profitCenterTraffic.percentage_login * 100;

					// We define the html item
					const htmlProfitCenterLoginCount = `
					<div class="chart-circle" data-percent="${profitCenterPercentageLogin}">
	          <span class="chart-number small">${profitCenterPercentageLogin}%</span>
	        </div>
	        <div class="mention"><span class="highlight">${profitCenterLoginCount}</span> ${$.i18n("title-out-of")} <span class="highlight">${profitCenterTotalUser}</span></div>
				`;

					// We show the item
					$(`<div class="col-table traffic">${htmlProfitCenterLoginCount}</div>`).insertAfter(`#line-profit-center-${parseInt(profitCenterTraffic.profit_center_id)} .col-table.progression`);

					// We init the traffic chart circle animation
					this.initProfitCenterTrafficCircle();

				});

			} else {

				// We show the no data message
				$("#performance-and-traffic-by-profit-centers .line-table:not(.line-title) .col-table.traffic").html(this.htmlMsgNoData);

			}

		}).catch((error) => {

			// We show the no data message
			$("#performance-and-traffic-by-profit-centers .line-table:not(.line-title) .col-table.traffic").html(this.htmlMsgNoData);

		});

	}

	initProfitCentersProgressCircles () {

		// We init the not started circle
		$("#performance-and-traffic-by-profit-centers  .chart-circle.not-started").easyPieChart({
			"barColor": "#d90303",
			"trackColor": "#dfe0e6",
			"scaleColor": false,
			"lineCap": "round",
			"lineWidth": 8,
			"size": 100,
			"animate": 1400
		});

		// We init the incomplete circle
		$("#performance-and-traffic-by-profit-centers  .chart-circle.incomplete").easyPieChart({
			"barColor": "#fe7a40",
			"trackColor": "#dfe0e6",
			"scaleColor": false,
			"lineCap": "round",
			"lineWidth": 8,
			"size": 100,
			"animate": 1400
		});

		// We init the complete circle
		$("#performance-and-traffic-by-profit-centers .chart-circle.complete").easyPieChart({
			"barColor": "#2bb860",
			"trackColor": "#dfe0e6",
			"scaleColor": false,
			"lineCap": "round",
			"lineWidth": 8,
			"size": 100,
			"animate": 1400
		});

	}

	initProfitCenterTrafficCircle () {

		// We init the not started circle
		$("#performance-and-traffic-by-profit-centers .line-table:not(.line-title) .col-table.traffic .chart-circle").easyPieChart({
			"barColor": "#2784ff",
			"trackColor": "#dfe0e6",
			"scaleColor": false,
			"lineCap": "round",
			"lineWidth": 8,
			"size": 100,
			"animate": 1400
		});

	}

	handleClickOnBranchName (e) {

		if (!$("#name-branch").hasClass("no-select") && $("#name-branch").find(".list-options").css("display") === "none") {

			// We show the list of branches
			this.showListBranchesName();

		} else {

			// We hide the list of branches
			this.hideListBranchesName();

		}

	}

	showListBranchesName () {

		// We open the list of options
		$("#name-branch").addClass("active");

		// We change the arrow icon
		$("#name-branch").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-branch").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of cartographies
		this.hideListCartographiesName();

	}

	hideListBranchesName () {

		// We close the list of options
		$("#name-branch").removeClass("active");

		// We change the arrow icon
		$("#name-branch").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-branch").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionBranch (e) {

		// We get the id of the branch selected
		const selectedBranchId = $(e.currentTarget).attr("data-branch-id");

		// We hide the list of branches
		this.hideListBranchesName();

		// We update the menu
		menu.update();

	}

	handleClickOnCartographyName (e) {

		if (!$("#main-select-context").hasClass("freeze") && !$("#name-cartography").hasClass("no-select") && $("#name-cartography").find(".list-options").css("display") === "none") {

			// We show the list of cartographies
			this.showListCartographiesName();

		} else {

			// We hide the list of cartographies
			this.hideListCartographiesName();

		}

	}

	showListCartographiesName () {

		// We open the list of options
		$("#name-cartography").addClass("active");

		// We change the arrow icon
		$("#name-cartography").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-cartography").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of branches
		this.hideListBranchesName();

	}

	hideListCartographiesName () {

		// We close the list of options
		$("#name-cartography").removeClass("active");

		// We change the arrow icon
		$("#name-cartography").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-cartography").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionCartography (e) {

		// We get the id of the cartography selected
		const selectedCartographyId = $(e.currentTarget).attr("data-cartography-id");

		// We hide the list of cartographies
		this.hideListCartographiesName();

		// We update the menu
		menu.update();

	}

}
export default new Statistics();
