(function (global, factory) {

	typeof exports === "object" && typeof module !== "undefined" ? factory(require("jquery"))
		: typeof define === "function" && define.amd ? define(["jquery"], factory)
			: factory(global.jQuery);

}(this, ($) => {


	$.fn.datepicker.languages["fr-FR"] = {
		"format": "dd/mm/yyyy",
		"days": [
			"Dimanche",
			"Lundi",
			"Mardi",
			"Mercredi",
			"Jeudi",
			"Vendredi",
			"Samedi"
		],
		"daysShort": [
			"Dim",
			"Lun",
			"Mar",
			"Mer",
			"Jeu",
			"Ven",
			"Sam"
		],
		"daysMin": [
			"Di",
			"Lu",
			"Ma",
			"Me",
			"Je",
			"Ve",
			"Sa"
		],
		"weekStart": 1,
		"months": [
			"Janvier",
			"Février",
			"Mars",
			"Avril",
			"Mai",
			"Juin",
			"Juillet",
			"Août",
			"Septembre",
			"Octobre",
			"Novembre",
			"Décembre"
		],
		"monthsShort": [
			"Jan",
			"Fev",
			"Mar",
			"Avr",
			"Mai",
			"Jun",
			"Jui",
			"Aoû",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		]
	};

}));
