class Debug {

	constructor () {
	}

	l (value) {

		if (process.env.DEBUG.toLowerCase()) {

			console.log(value);

		}

	}

	e (value) {

		if (process.env.DEBUG.toLowerCase() == "true") {

			console.error(value);

		}

	}

	i (value) {

		if (process.env.DEBUG.toLowerCase() == "true") {

			console.info(value);

		}

	}

	v (value) {

		if (process.env.DEBUG.toLowerCase() == "true" && process.env.DEBUG_VERBOSE.toLowerCase() == "true") {

			console.info(value);

		}

	}

}
export default new Debug();
