import html from "./loader.html";

class Loader {

	show () {

		// We count the elements loading
		this.countLoader = this.countLoader == undefined ? 0 : this.countLoader;
		this.countLoader++;

		// We do nothing if the loader is not in the html
		if ($("#loader").length > 0) {

			if ($("#loader").html() == "" || $("#loader").html() == undefined) {

				$("#loader").html(html);

			}

		}


	}

	hide () {

		// We count that one hide has been requested
		this.countLoader--;

		// We do nothing if the loader is not in the html or if it's empty
		if ($("#loader").length > 0 && $("#loader").html() != "" && $("#loader").html() != undefined) {

			if (this.hideTimeout != undefined) {

				clearTimeout(this.hideTimeout);

			}
			this.hideTimeout = setTimeout(this.hideLoaderIfNothingIsLoading.bind(this), 125);

		}

	}

	hideLoaderIfNothingIsLoading () {

		if (this.countLoader == 0) {

			$("#loader").html("");

		}

	}

	isShown () {

		return this.countLoader > 0;

	}

}
export default new Loader();
