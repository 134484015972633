import router from "Core/router.js";
import api from "Core/Api.js";
import debug from "Core/Debug.js";
import Datapoint from "Core/Datapoint.js";

class Exports extends Datapoint {

	createForContext (exportParams) {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "POST",
				"url": "/exports/create-for-context",
				"data": exportParams
			}).
				done(resolve).
				fail(reject);

		});


	}

	getData () {

		return new Promise((resolve, reject) => {

			api.call({
				"method": "GET",
				"url": "/exports/availables-for-user"
			}, false).
				done((dataReturned) => {

					this.updateData(dataReturned);
					resolve();

				}).
				fail((error, textStatus) => {

					// We accept the 403
					if (error.status == 403) {

						resolve();

					} else {

						error.objCalling = this.constructor.name;
						reject(error);

					}

				});

		});

	}


}
export default new Exports();
