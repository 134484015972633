import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import html from "./approach.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import "./Approach.scss";

class Approach extends Page {

	constructor () {

		let routes = ["/approach"];

		let datapointsToLoad = [
			context,
			cartographies,
			legalEntities,
			profitCenters
		];

		super(routes, AuthentifiedLayout, "La démarche", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render () {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		this.parseAndRender("page", html);
		menu.show();

		// We get the current cartography id
		this.cartographyId = cartographies.getCurrentCartographyId();

		// We load the current legal entity
		const curLegalEntityId = context.getCurrentLegalEntityId();

		// We show the content
		this.showContent();

	}


	showContent () {

		const curCartographyApproachImageUrl = cartographies.find(this.cartographyId)[`approach_image_url_${this.currentLang}`];

		if (curCartographyApproachImageUrl !== null && curCartographyApproachImageUrl !== undefined) {

			const htmlImage = `<img src="${curCartographyApproachImageUrl}" border="0" alt="" />`;

			$(".approach-full-image").append(htmlImage);

		}

	}

}
export default new Approach();
