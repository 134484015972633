import Layout from "Layouts/Layout.js";
import html from "./layout.html";
import "./AuthentifiedLayout.scss";
import "./AuthentifiedLayoutResponsive.scss";
import user from "Datapoints/User.js";
import translationFrCommon from "Core/assets/lib/i18n/languages/fr_common.json";
import translationFrEnv from "Core/assets/lib/i18n/languages/fr_env.json";
import translationEnCommon from "Core/assets/lib/i18n/languages/en_common.json";
import translationEnEnv from "Core/assets/lib/i18n/languages/en_env.json";

class AuthentifiedLayout extends Layout {

	constructor () {

		super(html, [user]);

	}

	render (params) {

		// We init the default language
		$.i18n({
			"locale": user.data.language
		});

		// We load the translation files for common translations
		$.i18n().load({
			"fr": translationFrCommon, // Common contents for French
			"en": translationEnCommon // Common contents for English
		});
		// We load the translation files for environnement specific translations
		$.i18n().load({
			"fr": translationFrEnv, // Environnement specific contents for French
			"en": translationEnEnv // Environnement specific contents for English
		});

		Layout.prototype.render.call(this);

	}

}
export default new AuthentifiedLayout();
