import router from "Core/router.js";
import menu from "Components/Menu/Menu.js";
import html from "./select_context.html";
import user from "Datapoints/User";
import context from "Datapoints/Context.js";
import branches from "Datapoints/Branches.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import "./SelectContext.scss";
import "./SelectContextResponsive.scss";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";
import debug from "Core/Debug.js";
// Import flags
import countriesFlags from "Core/assets/lib/flag-icons/css/flag-icons.min.css";

class SelectContext {

	show (params) {

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the html of the select context banner
		$("#main-select-context").prepend(html);

		// We add a class on the body
		$("body").addClass("select-context-show-on-page");

		// We get the params
		this.params = params;

		// We define the default context params
		const defaultContextParams = this.getDefaultContextParams();

		// We get the current branch id even if no branch is shown
		this.curBranchId = context.getCurrentBranchId();

		// If the config shows the branches
		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			// We init the branches
			this.initBranches();

		} else {

			// We hide the branch selector
			$("#name-branch").remove();

		}

		// We init the cartographies (!!! CARTOGRAPHIES MUST BE LOADED AFTER BRANCHES !!!)
		this.initCartographies();

		// If the config shows the profit centers
		if (process.env.SHOW_PROFIT_CENTERS === true || process.env.SHOW_PROFIT_CENTERS === "true") {

			// We init the profit centers
			this.initProfitCenters();

		} else {

			// We hide the profit center selector
			$("#name-profit-center").remove();

		}

		// If the config shows the countries
		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			// We init the countries
			this.initCountries();

		} else {

			// We hide the country selector
			$(".box-container-context-country").remove();

		}

		// We init the legal entities
		this.initLegalEntities();

		// If the config shows the business activities
		if (process.env.SHOW_BUSINESS_ACTIVITIES === true || process.env.SHOW_BUSINESS_ACTIVITIES === "true") {

			// We init the business activities
			this.initBusinessActivities();

		} else {

			// We hide the business activity selector
			$("#name-business-activity").remove();

		}

		// We check the user rights
		this.userRightsChecking({
			"idBranch": context.getCurrentBranchId(),
			"idCartography": cartographies.getCurrentCartographyId(),
			"idLegalEntity": context.getCurrentLegalEntityId()
		});

		// We init the context exception for the evaluation page
		this.initContextForEvaluation();

		// We init component events
		this.initEvents();

	}

	getDefaultContextParams () {

		let contextParams = {};
		if (this.params !== undefined && this.params !== null) {

			// For branch
			if (this.params.idBranch !== undefined && this.params.idBranch !== null) {

				contextParams.branch_id = parseInt(this.params.idBranch);

			}

			// For profit center
			if (this.params.idProfitCenter !== undefined && this.params.idProfitCenter !== null) {

				contextParams.profit_center_id = parseInt(this.params.idProfitCenter);

			}

			// For country
			if (this.params.idCountry !== undefined && this.params.idCountry !== null) {

				contextParams.country_id = parseInt(this.params.idCountry);

			}

			// For legal entity
			if (this.params.idLegalEntity !== undefined && this.params.idLegalEntity !== null) {

				contextParams.legal_entity_id = parseInt(this.params.idLegalEntity);

			}

		}

		return contextParams;

	}

	userRightsChecking (curParams) {

		// We check if user has rights on the current branch
		const isUserHasRightOnThisBranch = user.data.branchesUserHasRightFor.filter((branch) => parseInt(branch.id) === parseInt(curParams.idBranch));

		if (
			isUserHasRightOnThisBranch === undefined ||
			isUserHasRightOnThisBranch === null ||
			isUserHasRightOnThisBranch.length <= 0
		) {

			router.navigate("/not-allowed");

		}

	}

	initContextForEvaluation () {

		// We get current path url
		const curPathUrl = window.location.pathname;

		// If we are on an evaluation page
		if (curPathUrl.indexOf("evaluate") >= 0) {

			// We freeze the context ban
			$("#main-select-context").addClass("freeze");

		}

	}

	initEvents () {

		// We set the html position of the cartography selector
		this.setCartographySelectorHtmlPosition();

		// We listen the window resize
		$(window).on("resize", this.handleResizeWindow.bind(this));

		// We listen click on the main logo
		$("#main-select-context .ban .logo .link-logo").on("click", this.handleClickOnLogo.bind(this));

		// We define the click outside the cartographies list name
		$(document).click(() => {

		    this.hideListCartographiesName();
		    this.hideListBranchesName();
		    this.hideListProfitCentersName();
		    this.hideListBusinessActivitiesName();

		});
		$("#name-cartography").click((e) => {

		    e.stopPropagation();

		});
		$("#name-branch").click((e) => {

		    e.stopPropagation();

		});
		$("#name-country").click((e) => {

		    e.stopPropagation();

		});
		$("#name-profit-center").click((e) => {

		    e.stopPropagation();

		});
		$("#name-business-activity").click((e) => {

		    e.stopPropagation();

		});

		// We catch the scroll on the page
		$(window).scroll((e) => {

			// We get the scroll
			const scroll = $(window).scrollTop();

			if (scroll > 10) {

				$("#main-select-context").removeClass("scrolling");
				$("#main-select-context").addClass("scrolling");

			} else {

				$("#main-select-context").removeClass("scrolling");

			}

			// We fixed the position of the cartography select
			const posTopCartographySelect = 117 - scroll;
			$("#main-select-context .select-custom-name-cartography").css("top", posTopCartographySelect);

		});

	}

	handleResizeWindow () {

		// We set the html position of the cartography selector
		this.setCartographySelectorHtmlPosition();

	}

	setCartographySelectorHtmlPosition () {

		// We get the current window width
		const curWindowWidth = $(window).width();

		if (curWindowWidth <= 1320) {

			// We move the cartography selector inside the select context banner
			$("#name-cartography").prependTo("#main-select-context .ban");

			// We move the logo to the first position of the select context banner
			$("#main-select-context .ban .logo").prependTo("#main-select-context .ban");

		}

	}

	// CARTOGRAPHIES
	initCartographies () {

		Promise.all([
			context.getData(this.params),
			cartographies.getData(this.params)
		]).then(() => {

			// If no cartographies is given, we say it
			if (cartographies.data.length === 0) {

				// We show the error
				this.showCartographyError($.i18n("msg-error-no-cartography"));

				// We init the cartography selector
				this.loadCartographySelector(cartographies.data, true);

			}

			// If a cartography is asked but not in the given ones, we ask why
			if (
			  this.params !== undefined &&
				this.params !== null &&
				this.params !== "" &&
				this.params.idCartography !== undefined &&
				this.params.idCartography !== null &&
				this.params.idCartography !== ""
			) {

				// We check if the asked cartography exists in the cartographies
				const isCurrentCartographyExistsInCartographies = cartographies.data.filter((cartography) => parseInt(cartography.id) === parseInt(this.params.idCartography));

				if (
					isCurrentCartographyExistsInCartographies === undefined ||
					isCurrentCartographyExistsInCartographies.length < 1
				) {


					cartographies.getStatus(parseInt(this.params.idCartography)).then((response) => {

						// If the cartography is valid, there is an incoherence somewhere
						if (response.status === "valid") {

							// We show the error
							this.showCartographyError($.i18n("msg-error-code-543"));

						}
						// If the cartography is out of subscription scope, we say it
						else if (response.status === "out_of_subscription_scope") {

							// We show the error
							this.showCartographyError($.i18n("msg-subscription-limitation-for-cartography"));

						}
						// If the cartography does not exist, we say it
						else if (response.status === "not_found") {

							// We show the error
							this.showCartographyError($.i18n("msg-error-cartography-no-exists"));

						} else {

							// We do nothing

						}

					}).catch((error) => {

						debug.e("Error while loading cartography status", error);

					});

					// We init the cartography selector with the global error message
					this.loadCartographySelector(cartographies.data, true);

				} else {

					// We init the cartography selector without error message
					this.loadCartographySelector(cartographies.data, false);

				}

			} else {

				// Current branch
				const currentBranchId = context.data.branch.id;

				// We filter the cartographies for current branch
				const filteredCartographies = cartographies.data.filter((cartography) => parseInt(cartography.branch_id) === parseInt(currentBranchId));

				// If no cartographies can be shown
				if (filteredCartographies !== undefined) {

					if (filteredCartographies.length === 0) {

						// We show the error
						this.showCartographyError($.i18n("msg-error-no-cartography-for-current-branch"));

						// We init the cartography selector
						this.loadCartographySelector(filteredCartographies, true);

					} else {

						cartographies.setCurrentCartographyId(parseInt(filteredCartographies[0].id));

						// We init the cartography selector
						this.loadCartographySelector(filteredCartographies, false);

					}

				}

			}

		}).catch((error) => {

			debug.e("Error while loading context", error);

		});

	}

	showCartographyError (errorToShow) {

		// We define the error message
		const htmlErrorMessage = `<div class="msg-no-item"><div class="text"><i class="icon fas fa-info-circle"></i>&nbsp;&nbsp;<span>${errorToShow}</span></div></div>`;

		// We add the message to the container
		$(".home-container").html(htmlErrorMessage);
		$(".risk-container").html(htmlErrorMessage);
		$(".admin-container").html(htmlErrorMessage);

	}

	loadCartographySelector (listCartographies, isErrorToShow) {

		if (!isErrorToShow) {

			// We create a new array with cartographies except current cartography
			let listOtherCartographies;
			if (window.location.pathname.indexOf("/cartographies/all") >= 0) {

				listOtherCartographies = listCartographies.filter((cartography) => cartography.branch_id === this.curBranchId);

			} else {

				listOtherCartographies = listCartographies.filter((cartography) => cartography.branch_id === this.curBranchId && cartography.id !== cartographies.getCurrentCartographyId());

			}

			// We define the current cartography data
			this.curCartography = listCartographies.filter((cartography) => parseInt(cartography.id) === parseInt(cartographies.getCurrentCartographyId()))[0];

			// We show the name of the current cartography
			if (this.curCartography !== undefined && this.curCartography !== null) {

				$("#name-cartography").find(".option-selected .text").removeClass("uppercase");
				$("#name-cartography").find(".option-selected .text").html(this.curCartography[`name_${this.currentLang}`]);
				$("#name-cartography .option-selected").attr("data-cartography-id", cartographies.getCurrentCartographyId());

			}

			if (window.location.pathname.indexOf("/cartographies/all") >= 0) {

				$("#name-cartography").find(".option-selected .text").addClass("uppercase");
				$("#name-cartography").find(".option-selected .text").html($.i18n("label-btn-all-business-activity"));
				$("#name-cartography .option-selected").attr("data-cartography-id", "all");

			}

			// We show the cartographies name
			if (listOtherCartographies !== undefined && listOtherCartographies.length > 0) {

				$("#name-cartography").removeClass("no-select");
				$("#name-cartography").removeClass("msg-error");

				listOtherCartographies.forEach((cartography) => {

					let textCartographyName;
					if (cartography[`name_${this.currentLang}`] !== null && cartography[`name_${this.currentLang}`] !== undefined) {

						textCartographyName = cartography[`name_${this.currentLang}`];

					} else {

						textCartographyName = "";

					}

					// We define html for cartographies options
					const htmlOption = `<li class="option" data-cartography-id="${cartography.id}"><a href="/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartography.id}/legal-entities/${context.getCurrentLegalEntityId()}">${textCartographyName}</a></li>`;

					$("#name-cartography .list-options").append(htmlOption);

				});

				// We listen click on cartography name
				if (listOtherCartographies.length > 0) {

					$("#name-cartography .option-selected").on("click", this.handleClickOnCartographyName.bind(this));

				}

				// We listen click on options select cartography
				$("#name-cartography .list-options .option").on("click", this.handleClickOnOptionCartography.bind(this));

				// After render we make sure the shown links are handled by the router, not thrue the browser
				router.refreshLinks();

			} else {

				$("#name-cartography").addClass("no-select");
				$("#name-cartography").removeClass("msg-error");

			}

			if (listOtherCartographies.length === 0) {

				$("#name-cartography").addClass("no-select");
				$("#name-cartography").removeClass("msg-error");

			}

		}
		// If there is the selector error to show
		else {

			$("#name-cartography").addClass("no-select");
			$("#name-cartography").addClass("msg-error");
			$("#name-cartography").find(".option-selected .text").html($.i18n("msg-error-no-cartography-selected"));

		}

	}

	handleClickOnCartographyName (e) {

		if (!$("#main-select-context").hasClass("freeze") && !$("#name-cartography").hasClass("no-select") && $("#name-cartography").find(".list-options").css("display") === "none") {

			// We show the list of cartographies
			this.showListCartographiesName();

			// We listen click on the "all" button of cartographies
			$("#name-cartography .btn-all").on("click", this.handleClickOnBtnAllCartographies.bind(this));

		} else {

			// We hide the list of cartographies
			this.hideListCartographiesName();

		}

	}

	showListCartographiesName () {

		// We open the list of options
		$("#name-cartography").addClass("active");

		// We change the arrow icon
		$("#name-cartography").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-cartography").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of branches
		this.hideListBranchesName();

		// We hide the list of profit centers
		this.hideListProfitCentersName();

		// We hide the list of business activities
		this.hideListBusinessActivitiesName();

	}

	hideListCartographiesName () {

		// We close the list of options
		$("#name-cartography").removeClass("active");

		// We change the arrow icon
		$("#name-cartography").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-cartography").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionCartography (e) {

		// We reset the cache for the evaluation right of the current user
		user.resetCurrentUserEvaluationRightCache();

		// We get the id of the cartography selected
		const selectedCartographyId = $(e.currentTarget).attr("data-cartography-id");

		// We update the current cartography
		cartographies.setCurrentCartographyId(selectedCartographyId);

		// We hide the list of cartographies
		this.hideListCartographiesName();

		// We define new params
		const newParams = {
			"idCartography": selectedCartographyId
		};

		// We check if user have right to admin risks
		user.canAdminRisks(newParams).then((e) => {

			// We remove the suggest risk button
			$("#btn-suggest-risk").remove();

			// We add the cartography id in the link URL of the create risk button
			const curHrefBtnAddRisk = $("#btn-add-risk a").attr("href");
			$("#btn-add-risk a").attr("href", `/cartographies/${selectedCartographyId}${curHrefBtnAddRisk}`);

		}).catch((e) => {

			$("#btn-add-risk").remove();

		});

		// We update the menu
		menu.update();

	}

	handleClickOnBtnAllCartographies () {

		// We redirect the user to the cartographies dashboard
		router.navigate(`/dashboard/branches/${context.getCurrentBranchId()}/cartographies/all/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`);

	}
	// END CARTOGRAPHIES

	// BRANCHES
	initBranches () {

		// We get all the branches
		this.branches = context.getBranches();

		// We get the current branch
		this.curBranch = context.getCurrentBranch();

		// We init the branch selector
		this.loadBranchSelector();

	}

	loadBranchSelector () {

		// We create a new array with branches except current branch
		const listOtherBranches = this.branches.filter((branch) => branch.id !== this.curBranchId);

		// We show the name of the current branch
		$("#name-branch").find(".option-selected .text").html(this.curBranch[`${this.currentLang}`]);
		$("#name-branch .option-selected").attr("data-branch-id", this.curBranchId);

		// We show the branches name
		if (listOtherBranches !== undefined && listOtherBranches.length > 0) {

			$("#name-branch").removeClass("no-select");

			listOtherBranches.forEach((branch) => {

				let textBranchName;
				if (branch[`${this.currentLang}`] !== null && branch[`${this.currentLang}`] !== undefined) {

					textBranchName = branch[`${this.currentLang}`];

				} else {

					textBranchName = "";

				}

				// We define html for branches options
				const htmlOption = `<li class="option" data-branch-id="${branch.id}">${textBranchName}</li>`;


				$("#name-branch .list-options").append(htmlOption);

			});

			// We listen click on branch name
			if (listOtherBranches.length > 0) {

				$("#name-branch .option-selected").on("click", this.handleClickOnBranchName.bind(this));

			}

			// We listen click on options select branch
			$("#name-branch .list-options .option").on("click", this.handleClickOnOptionBranch.bind(this));

			// After render we make sure the shown links are handled by the router, not thrue the browser
			router.refreshLinks();

		} else {

			$("#name-branch").addClass("no-select");

		}

	}

	handleClickOnBranchName (e) {

		if (!$("#main-select-context").hasClass("freeze") && !$("#name-branch").hasClass("no-select") && $("#name-branch").find(".list-options").css("display") === "none") {

			// We show the list of branches
			this.showListBranchesName();

		} else {

			// We hide the list of branches
			this.hideListBranchesName();

		}

	}

	showListBranchesName () {

		// We open the list of options
		$("#name-branch").addClass("active");

		// We change the arrow icon
		$("#name-branch").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-branch").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of profit centers
		this.hideListProfitCentersName();

		// We hide the list of cartographies
		this.hideListCartographiesName();

		// We hide the list of business activities
		this.hideListBusinessActivitiesName();

	}

	hideListBranchesName () {

		// We close the list of options
		$("#name-branch").removeClass("active");

		// We change the arrow icon
		$("#name-branch").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-branch").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionBranch (e) {

		// We get the id of the branch selected
		const selectedBranchId = parseInt($(e.currentTarget).attr("data-branch-id"));

		// We update the context
		context.setContext({"branch_id": selectedBranchId}).then(() => {

			// We reset the cache for the evaluation right of the current user
			user.resetCurrentUserEvaluationRightCache();

			// We go to the dashboard
			router.navigate(`${this.getDashboardTypeToShow()}/branches/${selectedBranchId}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}`);

			// We update the menu
			menu.update();

		});

	}
	// END BRANCHES

	// PROFIT CENTERS
	initProfitCenters () {

		// We get all the profit centers
		this.profitCenters = context.getProfitCenters();

		// We get the current profit center
		this.curProfitCenter = context.getCurrentProfitCenter();

		// We get the current profit center id
		this.curProfitCenterId = context.getCurrentProfitCenterId();

		// We init the profit center selector
		this.loadProfitCenterSelector();

	}

	loadProfitCenterSelector () {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/profit-centers/all") >= 0) {

			// We change the style of the body
			$("body").addClass("average-context");

			// We change the style of the select context ban
			$("#main-select-context").addClass("average-context");

			// We hide the "all" button of profit centers
			$("#name-profit-center .btn-all").hide();

			// We hide the "all" button of legal entities
			$(".box-container-context-legal-entity .btn-all").hide();

			// We hide the profit centers select
			$("#name-profit-center .option-selected").hide();
			$("#name-profit-center .list-options").hide();

			// We hide the legal entities select
			$("#name-legal-entity").hide();

			// We define the html for all profit centers
			const htmlOptionAllProfitCenter = `
				<div class="title-profit-center msg-all">
					<div class="text">${$.i18n("label-btn-all-profit-center")}</div>
					<div class="info-title">
						<div class="tag" data-i18n="text-tag-consolidated-view">${$.i18n("text-tag-consolidated-view")}</div>
						<div class="btn-help">
							<div class="icon fas fa-info"></div>
							<div class="tooltip">
								<div class="tooltip-text">
									<span data-i18n="text-tooltip-info-context-all-profit-centers">${$.i18n("text-tooltip-info-context-all-profit-centers")}</span>
								</div>
							</div>
						</div>
					<div class="btn-close">
						<div class="icon fas fa-times"></div>
						<div class="tooltip">
							<div class="tooltip-text">
								<span data-i18n="text-tooltip-close-context-all-profit-centers">${$.i18n("text-tooltip-close-context-all-profit-centers")}</span>
							</div>
						</div>
					</div>
				</div>
			`;

			// We show the html for all profit centers
			$("#name-profit-center").append(htmlOptionAllProfitCenter);

			// We listen click on the close button
			$("#name-profit-center .title-profit-center.msg-all .btn-close").on("click", this.handleClickOnBtnCloseAllLegalEntities.bind(this));

			// We show the text "all" for country
			$("#name-country .option-selected").hide();
			$("#name-country").append(`<div class="title-country"><div class="text" data-i18n="label-btn-all-country">${$.i18n("label-btn-all-country")}</div></div>`);

			// We show the text "all" for business activity
			$("#name-business-activity .option-selected").hide();
			$("#name-business-activity .title-business-activity").remove();
			$("#name-business-activity").append(`<div class="title-business-activity"><div class="text" data-i18n="label-btn-all-business-activity">${$.i18n("label-btn-all-business-activity")}</div></div>`);

			// We show the text "all" for legal entity
			$(".box-container-context-legal-entity .title-legal-entity .text").show();

			// We hide the button "all" for legal entity and the consolidated infos of legal entity
			$(".box-container-context-legal-entity .btn-all").hide();
			$(".box-container-context-legal-entity .title-legal-entity .info-title").hide();

		} else {

			// We check if user have enough right to show the consolidated button (branch visualisation or evaluation rights)
			const userMatchingRightsForConsolidatedButton = user.data.rights.filter((right) => {

				let isRightOnCurrentCartography = parseInt(right.cartography_id) === parseInt(cartographies.getCurrentCartographyId());
				let isRightOnCurrentBranch = parseInt(right.branch_id) === parseInt(this.curBranchId);
				let isRightOnCountryLevel = right.country_id !== undefined;
				let isRightViewEvaluation = right.rights.includes("view_evaluation");
				let isRightEvaluate = right.rights.includes("evaluate_cartography");

				return isRightOnCurrentCartography && isRightOnCurrentBranch && !isRightOnCountryLevel && (isRightViewEvaluation || isRightEvaluate);

			});

			// If the current user have at least one right on branch level
			if (userMatchingRightsForConsolidatedButton !== undefined && userMatchingRightsForConsolidatedButton.length > 0) {

				// We show the button all on the profit center selector
				$("#name-profit-center .btn-all").addClass("active");

				// We listen click on the "all" button of profit centers
				$("#name-profit-center .btn-all").on("click", this.handleClickOnBtnAllProfitCenters.bind(this));

			}

			// We create a new array with profit centers except current profit center
			const listOtherProfitCenters = this.profitCenters.filter((profitCenter) => parseInt(profitCenter.id) !== parseInt(this.curProfitCenterId));

			// We show the name of the current profit center
			$("#name-profit-center").find(".option-selected .text").html(this.curProfitCenter.reference);
			$("#name-profit-center .option-selected").attr("data-profit-center-id", this.curProfitCenterId);

			// We show the profit centers name
			if (listOtherProfitCenters !== undefined && listOtherProfitCenters.length > 0) {

				$("#name-profit-center").removeClass("no-select");

				listOtherProfitCenters.forEach((profitCenter) => {

					let textProfitCenterName;
					if (profitCenter[`${this.currentLang}`] !== null && profitCenter[`${this.currentLang}`] !== undefined) {

						textProfitCenterName = profitCenter[`${this.currentLang}`];

					} else {

						textProfitCenterName = "";

					}

					// We define html for profit centers options
					const htmlOption = `
						<li class="option" data-profit-center-id="${profitCenter.id}">${textProfitCenterName}</li>
					`;


					$("#name-profit-center .list-options").append(htmlOption);

				});

				// We listen click on profit center name
				if (listOtherProfitCenters.length > 0) {

					$("#name-profit-center .option-selected").on("click", this.handleClickOnProfitCenterName.bind(this));

				}

				// We listen click on options select profit center
				$("#name-profit-center .list-options .option").on("click", this.handleClickOnOptionProfitCenter.bind(this));

				// After render we make sure the shown links are handled by the router, not thrue the browser
				router.refreshLinks();

			} else {

				$("#name-profit-center").addClass("no-select");

			}

		}

	}

	handleClickOnBtnAllProfitCenters (e) {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/risks") >= 0) {

			// We get the risk id saved on the local storage
			let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

			// We get the current risk id
			let curRiskId;
			if (localStorageCurrentRiskId !== undefined && localStorageCurrentRiskId !== null) {

				curRiskId = localStorageCurrentRiskId;

			} else {

				curRiskId = curPathUrl.replace("/evaluate", "").match(/\d+$/)[0];

			}

			// We go to the risk page
			router.navigate(`/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curRiskId}`);

		} else {

			// We go to the dashboard
			router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/profit-centers/all/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`);

		}

	}

	handleClickOnProfitCenterName (e) {

		if (!$("#main-select-context").hasClass("freeze") && !$("#name-profit-center").hasClass("no-select") && $("#name-profit-center").find(".list-options").css("display") === "none") {

			// We show the list of profit centers
			this.showListProfitCentersName();

		} else {

			// We hide the list of profit centers
			this.hideListProfitCentersName();

		}

	}

	showListProfitCentersName () {

		// We open the list of options
		$("#name-profit-center").addClass("active");

		// We change the arrow icon
		$("#name-profit-center").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-profit-center").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of branches
		this.hideListBranchesName();

		// We hide the list of cartographies
		this.hideListCartographiesName();

		// We hide the list of business activities
		this.hideListBusinessActivitiesName();

	}

	hideListProfitCentersName () {

		// We close the list of options
		$("#name-profit-center").removeClass("active");

		// We change the arrow icon
		$("#name-profit-center").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-profit-center").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionProfitCenter (e) {

		// We get the id of the selected profit center
		const selectedProfitCenterId = parseInt($(e.currentTarget).attr("data-profit-center-id"));

		// We update the context
		context.setContext({"profit_center_id": selectedProfitCenterId}).then(() => {

			// We reset the cache for the evaluation right of the current user
			user.resetCurrentUserEvaluationRightCache();

			// We go to the dashboard
			router.navigate(`${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}`);

			// We update the menu
			menu.update();

		});

	}
	//  END PROFIT CENTERS

	// COUNTRIES
	initCountries () {

		// We get all the countries
		this.countries = context.getCountries();

		// We get the current country
		this.curCountry = context.getCurrentCountry();

		// We get the current country id
		this.curCountryId = context.getCurrentCountryId();

		// We init the country selector
		this.loadCountrySelector();

	}

	loadCountrySelector () {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/countries/all") >= 0) {

			// We change the style of the body
			$("body").addClass("average-context");

			// We change the style of the select context ban
			$("#main-select-context").addClass("average-context");

			// We hide the "all" button of countries
			$(".box-container-context-country .btn-all").hide();

			// We hide the countries select
			$("#name-country").hide();

			// We define the html for all countries
			const htmlOption = `
				<div class="title-country msg-all" id="select2-name-country-container">
					<div class="text">${$.i18n("label-btn-all-country")}</div>
					<div class="info-title">
					<div class="tag" data-i18n="text-tag-consolidated-view">${$.i18n("text-tag-consolidated-view")}</div>
					<div class="btn-help">
						<div class="icon fas fa-info"></div>
						<div class="tooltip">
							<div class="tooltip-text">
								<span data-i18n="text-tooltip-info-context-all-countries">${$.i18n("text-tooltip-info-context-all-countries")}</span>
							</div>
						</div>
					</div>
					<div class="btn-close">
						<div class="icon fas fa-times"></div>
						<div class="tooltip">
							<div class="tooltip-text">
								<span data-i18n="text-tooltip-close-context-all-countries">${$.i18n("text-tooltip-close-context-all-countries")}</span>
							</div>
						</div>
					</div>
				</div>
			`;

			$("#main-select-context .box-container-context-country").append(htmlOption);

			// We listen click on the close button
			$("#main-select-context .title-country.msg-all .btn-close").on("click", this.handleClickOnBtnCloseAllLegalEntities.bind(this));

			// We show the text "all" for business activity
			$("#name-business-activity .option-selected").hide();
			$("#name-business-activity .title-business-activity").remove();
			$("#name-business-activity").append(`<div class="title-business-activity"><div class="text" data-i18n="label-btn-all-business-activity">${$.i18n("label-btn-all-business-activity")}</div></div>`);

			// We show the text "all" for legal entity
			$(".box-container-context-legal-entity .title-legal-entity .text").show();

			// We hide the button "all" for legal entity and the consolidated infos of legal entity
			$(".box-container-context-legal-entity .btn-all").hide();
			$(".box-container-context-legal-entity .title-legal-entity .info-title").hide();

		} else {

			// We check if user have enough right to show the consolidated button (branch visualisation or evaluation rights)
			const userMatchingRightsForConsolidatedButton = user.data.rights.filter((right) => {

				let isRightOnCurrentCartography = parseInt(right.cartography_id) === parseInt(cartographies.getCurrentCartographyId());
				let isRightOnCurrentBranch = parseInt(right.branch_id) === parseInt(this.curBranchId);
				let isRightOnCountryLevel = right.country_id !== undefined;
				let isRightViewEvaluation = right.rights.includes("view_evaluation");
				let isRightEvaluate = right.rights.includes("evaluate_cartography");

				return isRightOnCurrentCartography && isRightOnCurrentBranch && !isRightOnCountryLevel && (isRightViewEvaluation || isRightEvaluate);

			});

			if (this.countries.length > 0) {

				if (this.countries.length === 1) {

					$("#name-country").hide();

					const htmlOption = `<span class="title-country" id="select2-name-country-container">${this.countries[0][`${this.currentLang}`]}</span>`;

					$("#main-select-context .box-container-context-country").append(htmlOption);

				} else {

					let htmlOption = "";

					// We sort countries in alphabetical order
					this.countries.sort((a, b) => a[`${this.currentLang}`].localeCompare(b[`${this.currentLang}`]));

					// We construct the options according to array
					this.countries.forEach((country, i) => {

						let textCountryName;
						if (country[`${this.currentLang}`] !== null && country[`${this.currentLang}`] !== undefined) {

							textCountryName = country[`${this.currentLang}`];

						} else {

							textCountryName = "";

						}

						// We create an exception for the "World" country
						if (country.alpha3Code === "WW") {

							htmlOption += `<option class='optionLevel3' value="${textCountryName}"><i class="flag-icons fas fa-globe-americas"></i>${textCountryName}</option>`;

						} else {

							htmlOption += `<option class='optionLevel3' value="${textCountryName}"><i class="flag-icons ${country.alpha2Code}"></i>${textCountryName}</option>`;

						}

					});

					// We show countries options
					$("#name-country").append(htmlOption);

					// Initialize select 2
					$("#name-country").select2({
						"language": "fr",
						"templateSelection": this.formatSelect2CountryForSelection.bind(this),
						"templateResult": this.formatSelect2CountryForResult.bind(this),
						"dropdownCssClass": "dropdown-country"
					});

					// When the country select2 is opened
					$("#name-country").on("select2:open", (e) => {

						if (!$("#main-select-context").hasClass("freeze")) {

							// If the current user have at least one right on country level
							if (userMatchingRightsForConsolidatedButton !== undefined && userMatchingRightsForConsolidatedButton.length > 0) {

								// We change the position of the select2
								$(".select2-container--open .select2-dropdown--below").css("top", "54px");

								// We show the button for consolidated view
								$(".box-container-context-country .btn-all").addClass("open");

								// We listen click on the "all" button of countries
								$(".box-container-context-country .btn-all").on("click", this.handleClickOnBtnAllCountries.bind(this));

							} else {

								// We change the position of the select2
								$(".select2-container--open .select2-dropdown--below").css("top", "5px");

							}

							// We put the placeholder on the input search of the select2
							$("input.select2-search__field").prop("placeholder", `${$.i18n("title-context-country")}`);

						} else {

							$(".select2-dropdown").hide();

						}

					});

					// When the country select2 is closed
					$("#name-country").on("select2:close", (e) => {

						// We hide the button for consolidated view
						$(".box-container-context-country .btn-all").removeClass("open");

					});

					// We show the name of the current country
					const curCountryName = this.curCountry[`${this.currentLang}`];
					$("#name-country").val(curCountryName);
					$("#select2-name-country-container").attr("title", curCountryName);

					// Customize select 2
					$(".box-container-context-country .select2-selection__arrow").find("b").hide();
					$(".box-container-context-country .select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");


					// We listen click on options select country
					$("#name-country").on("select2:select", (e) => {

						this.handleClickOnOptionCountry(e);

						// We get the current id
						const curValue = e.params.data.id;

						// We update the name of the current country
						$("#name-country").val(curValue);
						$("#select2-name-country-container").attr("title", curValue);

					});

					// After render we make sure the shown links are handled by the router, not thrue the browser
					router.refreshLinks();

				}

				// We change the data country id attribute
				$("#name-country").attr("data-country-id", this.curCountryId);

			} else {

				$("#name-country").hide();

				const htmlOption = `<span class="title-country msg-error" id="select2-name-country-container" data-i18n="msg-no-legal-entity-for-context">${$.i18n("msg-no-legal-entity-for-context")}</span>`;

				$("#main-select-context .box-container-context-country").append(htmlOption);

			}

		}

	}

	formatSelect2CountryForSelection (item) {

		const curItemCountryData = this.countries.filter((country) => country[`${this.currentLang}`] === this.curCountry[`${this.currentLang}`])[0];

		let htmlItem = "";
		if (curItemCountryData !== undefined) {

			// We have an exception for the country "WORLD"
			if (curItemCountryData.alpha3Code !== undefined && curItemCountryData.alpha3Code !== null && curItemCountryData.alpha3Code === "WW") {

				htmlItem = `<i class="flag-icons fas fa-globe-americas"></i><span class="text-item text-world">${curItemCountryData.alpha3Code}</span>`;

			} else {

				htmlItem = `<i class="flag-icons ${curItemCountryData.alpha2Code}"></i><span class="text-item text-country">${curItemCountryData.alpha3Code}</span>`;

			}

		}

		const classToUse = item.element != undefined ? item.element.className : "";
		return $(`<div class='${classToUse}'>${htmlItem}</div>`);

	}

	formatSelect2CountryForResult (item) {

		const curItemCountryData = this.countries.filter((country) => country[`${this.currentLang}`] === item.text)[0];

		let htmlItem = "";
		if (curItemCountryData !== undefined) {

			// We have an exception for the country "WORLD"
			if (curItemCountryData.alpha3Code !== undefined && curItemCountryData.alpha3Code !== null && curItemCountryData.alpha3Code === "WW") {

				htmlItem = `<i class="flag-icons fas fa-globe-americas"></i><span class="text-item text-world" data-country-alpha3Code="${curItemCountryData.alpha3Code}">${item.text}</span>`;

			} else {

				htmlItem = `<i class="flag-icons ${curItemCountryData.alpha2Code}"></i><span class="text-item text-country" data-country-alpha3Code="${curItemCountryData.alpha3Code}">${item.text}</span>`;

			}

		}

		const classToUse = item.element != undefined ? item.element.className : "";
		return $(`<div class='${classToUse}'>${htmlItem}</div>`);

	}

	handleClickOnBtnAllCountries (e) {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/risks") >= 0) {

			// We get the risk id saved on the local storage
			let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

			// We get the current risk id
			let curRiskId;
			if (localStorageCurrentRiskId !== undefined && localStorageCurrentRiskId !== null) {

				curRiskId = localStorageCurrentRiskId;

			} else {

				curRiskId = curPathUrl.replace("/evaluate", "").match(/\d+$/)[0];

			}

			// We go to the risk page
			router.navigate(`/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curRiskId}`);

		} else {

			// We go to the dashboard
			router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/countries/all/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`);

		}

	}

	handleClickOnOptionCountry (e) {

		// We get the name of the country selected
		const selectedCountryName = $(e.currentTarget).find("option:selected").val();

		// We get the selected country data
		const selectedCountry = this.countries.filter((country) => country[`${this.currentLang}`] === selectedCountryName)[0];

		if (selectedCountry !== undefined) {

			// We get the id of the selected country
			const selectedCountryId = parseInt(selectedCountry.id);

			// We update the context
			context.setContext({"country_id": selectedCountryId}).then(() => {

				// We reset the cache for the evaluation right of the current user
				user.resetCurrentUserEvaluationRightCache();

				// We go to the dashboard
				router.navigate(`${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}`);

				// We update the menu
				menu.update();

			});

		}

	}
	// END COUNTRIES


	// LEGAL ENTITIES
	initLegalEntities () {

		// We get all the legal entities
		this.legalEntities = context.getLegalEntities();

		// We get the current legal entity
		this.curLegalEntity = context.getCurrentLegalEntity();

		// We get the current legal entity id
		this.curLegalEntityId = context.getCurrentLegalEntityId();

		// We init the legal entity selector
		this.loadLegalEntitySelector();

	}

	loadLegalEntitySelector () {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/legal-entities/all") >= 0) {

			// We change the style of the body
			$("body").addClass("average-context");

			// We change the style of the select context ban
			$("#main-select-context").addClass("average-context");

			// We hide the "all" button of legal entities
			$(".box-container-context-legal-entity .btn-all").hide();

			// We hide the legal entities select
			$("#name-legal-entity").hide();

			// We show text content
			const htmlOption = `
				<div class="title-legal-entity msg-all" id="select2-name-legal-entity-container">
					<div class="text">${$.i18n("label-btn-all-legal-entity")}</div>
					<div class="info-title">
					<div class="tag" data-i18n="text-tag-consolidated-view">${$.i18n("text-tag-consolidated-view")}</div>
					<div class="btn-help">
						<div class="icon fas fa-info"></div>
						<div class="tooltip">
							<div class="tooltip-text">
								<span data-i18n="text-tooltip-info-context-all-legal-entities">${$.i18n("text-tooltip-info-context-all-legal-entities")}</span>
							</div>
						</div>
					</div>
					<div class="btn-close">
						<div class="icon fas fa-times"></div>
						<div class="tooltip">
							<div class="tooltip-text">
								<span data-i18n="text-tooltip-close-context-all-legal-entities">${$.i18n("text-tooltip-close-context-all-legal-entities")}</span>
							</div>
						</div>
					</div>
				</div>
			`;

			$("#main-select-context .box-container-context-legal-entity").append(htmlOption);

			// We listen click on the close button
			$("#main-select-context .title-legal-entity.msg-all .btn-close").on("click", this.handleClickOnBtnCloseAllLegalEntities.bind(this));

			// We show the text "all" for business activity
			$("#name-business-activity .option-selected").hide();
			$("#name-business-activity .title-business-activity").remove();
			$("#name-business-activity").append(`<div class="title-business-activity"><div class="text" data-i18n="label-btn-all-business-activity">${$.i18n("label-btn-all-business-activity")}</div></div>`);

		} else {

			// We check if user have enough right to show the consolidated button (branch & country level visualisation or evaluation rights)
			let userMatchingRightsForConsolidatedButton = user.data.rights.filter((right) => {

				let isRightOnCurrentCartography = parseInt(right.cartography_id) === parseInt(cartographies.getCurrentCartographyId());
				let isRightOnCurrentBranch = parseInt(right.branch_id) === parseInt(this.curBranchId);
				let isRightViewEvaluation = right.rights.includes("view_evaluation");
				let isRightEvaluate = right.rights.includes("evaluate_cartography");

				return isRightOnCurrentCartography && isRightOnCurrentBranch && (isRightViewEvaluation || isRightEvaluate);

			});

			if (process.env.SHOW_COUNTRIES === undefined || process.env.SHOW_COUNTRIES === false || process.env.SHOW_COUNTRIES === "false") {

				userMatchingRightsForConsolidatedButton = [];

			}

			if (this.legalEntities.length > 0) {

				if (this.legalEntities.length === 1) {

					$("#name-legal-entity").hide();

					const htmlOption = `<span class="title-legal-entity one-result" id="select2-name-legal-entity-container">${this.legalEntities[0].name}</span>`;

					$("#main-select-context .box-container-context-legal-entity").append(htmlOption);

				} else {

					let htmlOption = "";

					// We sort legal entities in alphabetical order
					this.legalEntities.sort((a, b) => a.name.localeCompare(b.name));

					// We construct the options according to array
					this.legalEntities.forEach((legalEntity, i) => {

						const legalEntityLib = legalEntity.tela_number != undefined ? `${legalEntity.name} (${legalEntity.tela_number})` : `${legalEntity.name}`;

						htmlOption += `<option class='optionLevel3' value="${legalEntity.name}">${legalEntityLib}</option>`;

					});

					// We show legal entities options
					$("#name-legal-entity").append(htmlOption);

					// Initialize select 2
					$("#name-legal-entity").select2({
						"language": "fr",
						"templateResult": this.formatSelect2LegalEntity,
						"dropdownCssClass": "dropdown-legal-entity"
					});

					// When the legal entity select2 is opened
					$("#name-legal-entity").on("select2:open", (e) => {

						if (!$("#main-select-context").hasClass("freeze")) {

							// If the current user have at least one right on country level
							if (userMatchingRightsForConsolidatedButton !== undefined && userMatchingRightsForConsolidatedButton.length > 0) {

								// We change the position of the select2
								$(".select2-container--open .select2-dropdown--below").css("top", "54px");

								// We show the button for consolidated view
								$(".box-container-context-legal-entity .btn-all").addClass("open");

								// We listen click on the "all" button of legal entities
								$(".box-container-context-legal-entity .btn-all").on("click", this.handleClickOnBtnAllLegalEntities.bind(this));

							} else {

								// We change the position of the select2
								$(".select2-container--open .select2-dropdown--below").css("top", "10px");

							}

							// We put the placeholder on the input search of the select2
							$("input.select2-search__field").prop("placeholder", `${$.i18n("form-placeholder-legal-entity")}`);

						} else {

							$(".select2-dropdown").hide();

						}

					});

					// When the legal entity select2 is closed
					$("#name-legal-entity").on("select2:close", (e) => {

						// We hide the button for consolidated view
						$(".box-container-context-legal-entity .btn-all").removeClass("open");

					});

					// We show the name of the current legal entity
					const curlegalEntityLib = this.curLegalEntity.name;
					$("#name-legal-entity").val(curlegalEntityLib);
					$("#select2-name-legal-entity-container").html(curlegalEntityLib);
					$("#select2-name-legal-entity-container").attr("title", curlegalEntityLib);

					// Customize select 2
					$(".box-container-context-legal-entity .select2-selection__arrow").find("b").hide();
					$(".box-container-context-legal-entity .select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");


					// We listen click on options select legal entity
					$("#name-legal-entity").on("select2:select", (e) => {

						this.handleClickOnOptionLegalEntity();

						// We get the current id (=> ID on the select2 is the text selected without tela number)
						const curValueWithoutTelaNumber = e.params.data.id;

						// We update the name of the current legal entity
						$("#name-legal-entity").val(curValueWithoutTelaNumber);
						$("#select2-name-legal-entity-container").html(curValueWithoutTelaNumber);
						$("#select2-name-legal-entity-container").attr("title", curValueWithoutTelaNumber);

					});

					// After render we make sure the shown links are handled by the router, not thrue the browser
					router.refreshLinks();

				}

				// We change the data legal entity id attribute
				$("#name-legal-entity").attr("data-legal-entity-id", this.curLegalEntityId);

			} else {

				$("#name-legal-entity").hide();

				const htmlOption = `<span class="title-legal-entity msg-error" id="select2-name-legal-entity-container" data-i18n="msg-no-legal-entity-for-context">${$.i18n("msg-no-legal-entity-for-context")}</span>`;

				$("#main-select-context .box-container-context-legal-entity").append(htmlOption);

			}

		}

		// If the config shows the countries
		if (process.env.SHOW_COUNTRIES === true || process.env.SHOW_COUNTRIES === "true") {

			// We define the country icon class
			let countryIconClass;
			if (this.curCountry.alpha2Code === "WW") {

				countryIconClass = "fas fa-globe-americas";

			} else {

				countryIconClass = this.curCountry.alpha2Code;

			}

			// We add the current country informations on the legal entities consolidated view button
			const htmlCurCountryInfos = `
				<i class="flag-icons ${countryIconClass}"></i>
				<span class="text-item text-country">${this.curCountry.alpha3Code}</span>
			`;
			$(".box-container-context-legal-entity .btn-all .content-btn-all").append(`<span class="country-infos">${htmlCurCountryInfos}</span>`);

		} else {

			// We remove the consolidated view button for legal entities
			$(".box-container-context-legal-entity .btn-all").remove();

		}

	}

	handleClickOnBtnAllLegalEntities (e) {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/risks") >= 0) {

			// We get the risk id saved on the local storage
			let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

			// We get the current risk id
			let curRiskId;
			if (localStorageCurrentRiskId !== undefined && localStorageCurrentRiskId !== null) {

				curRiskId = localStorageCurrentRiskId;

			} else {

				curRiskId = curPathUrl.replace("/evaluate", "").match(/\d+$/)[0];

			}

			// We go to the risk page
			router.navigate(`/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curRiskId}`);

		} else {

			// We go to the dashboard
			router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`);

		}

	}

	handleClickOnBtnCloseAllLegalEntities (e) {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/risks") >= 0) {

			// We get the risk id saved on the local storage
			let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

			// We get the current risk id
			let curRiskId;
			if (localStorageCurrentRiskId !== undefined && localStorageCurrentRiskId !== null) {

				curRiskId = localStorageCurrentRiskId;

			} else {

				curRiskId = curPathUrl.replace("/evaluate", "").match(/\d+$/)[0];

			}

			// We go to the risk page
			router.navigate(`/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curRiskId}/business-activities/all`);

		} else {

			// We go to the current dashboard
			router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`);

		}

	}

	formatSelect2LegalEntity (item) {

		const classToUse = item.element != undefined ? item.element.className : "";
		return $(`<span class='${classToUse}'>${item.text}</span>`);

	}

	handleClickOnOptionLegalEntity (e) {


		// We get the full name of the legal entity selected
		const selectedLegalEntityFullName = $("#select2-name-legal-entity-container").html();

		// We get the tela number of the legal entity selected
		const telaNumber = (/\(([^()]+)\)$/).exec(selectedLegalEntityFullName)[1];

		// We get the name of the legal entity selected without tela number
		const selectedLegalEntityName = selectedLegalEntityFullName.replace(` (${telaNumber})`, "");

		// We define the selected legal entity
		const selectedLegalEntity = legalEntities.data.filter((curLegalEntity) => curLegalEntity.name === selectedLegalEntityName)[0];

		if (selectedLegalEntity !== undefined) {

			// We get the id of the selected legal entity
			const selectedLegalEntityId = parseInt(selectedLegalEntity.id);

			// We update the context
			context.setContext({"legal_entity_id": selectedLegalEntityId}).then(() => {

				// We reset the cache for the evaluation right of the current user
				user.resetCurrentUserEvaluationRightCache();

				// We change the data legal entity id attribute
				$("#name-legal-entity").attr("data-legal-entity-id", selectedLegalEntityId);

				// We go to the dashboard
				router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${selectedLegalEntityId}`);

				// We update the menu
				menu.update();

			});

		}

	}
	// END LEGAL ENTITIES

	// BUSINESS ACTIVITIES
	initBusinessActivities () {

		const curPathUrl = window.location.pathname;

		// We get the risk id saved on the local storage
		let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

		// We check if the current legal entity have a business activity
		legalEntities.getBusinessActivities(this.curLegalEntityId).then((dataBusinessActivities) => {

			if (dataBusinessActivities !== undefined && dataBusinessActivities.length > 0) {

				// We define the current business activity id
				let curIdBusinessActivity;
				if (
					this.params !== undefined &&
					this.params !== null &&
					this.params.idBusinessActivity !== undefined &&
					this.params.idBusinessActivity !== null
				) {

					curIdBusinessActivity = parseInt(this.params.idBusinessActivity);

				} else {

					curIdBusinessActivity = null;

				}


				if (
					curIdBusinessActivity !== undefined &&
					curIdBusinessActivity !== null &&
					!isNaN(curIdBusinessActivity)
				) {

					const curBusinessActivity = businessActivities.find(curIdBusinessActivity);
					const curBusinessActivityName = curBusinessActivity[`name_${this.currentLang}`];

					// We show the name of the business activity
					$("#name-business-activity").find(".option-selected .text").html(curBusinessActivityName);
					$("#name-business-activity .option-selected").attr("data-business-activity-id", curIdBusinessActivity);

				} else {

					// We show the placeholder text by default (= all)
					const htmlPlaceholderAndTooltip = `<div class='placeholder' data-i18n="label-btn-all-business-activity">${$.i18n("label-btn-all-business-activity")}</div><div class="btn-help"><div class="icon-info fas fa-info"></div><div class="tooltip"><div class="tooltip-text"><span data-i18n="text-tooltip-info-context-all-business-activities">${$.i18n("text-tooltip-info-context-all-business-activities")}</span></div></div></div>`;

					$("#name-business-activity").find(".option-selected .text").html(htmlPlaceholderAndTooltip);
					$("#name-business-activity .option-selected").attr("data-business-activity-id", "all");

				}

				// We show the button all on the business activity selector
				$("#name-business-activity .btn-all").addClass("active");
				$("#name-business-activity").addClass("btn-all-active");

				// We listen click on the "all" button of business activities
				$("#name-business-activity .btn-all").on("click", this.handleClickOnBtnAllBusinessActivities.bind(this));

				$("#name-business-activity").removeClass("no-select");

				dataBusinessActivities.forEach((businessActivity) => {

					let textBusinessActivityName;
					if (businessActivity[`name_${this.currentLang}`] !== null && businessActivity[`name_${this.currentLang}`] !== undefined) {

						textBusinessActivityName = businessActivity[`name_${this.currentLang}`];

					} else {

						textBusinessActivityName = "";

					}

					// We define html for business activities options
					let htmlOption;

					if (window.location.pathname.indexOf("/risks") >= 0) {

						htmlOption = `<li class="option" data-business-activity-id="${businessActivity.id}"><a href="/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${localStorageCurrentRiskId}/business-activities/${businessActivity.id}">${textBusinessActivityName}</a></li>`;

					} else {

						htmlOption = `<li class="option" data-business-activity-id="${businessActivity.id}"><a href="/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/${businessActivity.id}">${textBusinessActivityName}</a></li>`;

					}

					// We show the options
					$("#name-business-activity .list-options").append(htmlOption);

				});

				// We listen click on business activity name
				$("#name-business-activity .option-selected").on("click", this.handleClickOnBusinessActivityName.bind(this));

				// We listen click on options select business activity
				$("#name-business-activity .list-options .option").on("click", this.handleClickOnOptionBusinessActivity.bind(this));

				// After render we make sure the shown links are handled by the router, not thrue the browser
				router.refreshLinks();

			} else {

				$("#name-business-activity .option-selected").hide();

				const htmlOption = `<span class="title-business-activity msg-error" data-i18n="msg-no-business-activity-for-context">${$.i18n("msg-no-business-activity-for-context")}</span>`;

				if ($("#name-business-activity").find(".title-business-activity").length <= 0) {

					$("#name-business-activity").append(htmlOption);

				}

			}

		}).catch((e) => {

			// We hide the business activity selector
			$("#name-business-activity").hide();
			debug.e(e);

		});


		// We check if user has right to evaluate this cartography
		user.canEvaluateThisCartographyForThisLegalEntity(cartographies.getCurrentCartographyId(), this.curLegalEntityId).then((userCanEvaluate) => {

			// We filter business activities by current branch
			let businessActivitiesFilteredByCurrentBranch;
			if (businessActivities.data !== undefined) {

				businessActivitiesFilteredByCurrentBranch = businessActivities.data.filter((businessActivity) => parseInt(businessActivity.branch_id) === parseInt(context.getCurrentBranchId()));

			}

			// We check if the current legal entity have a business activity
			legalEntities.getBusinessActivities(this.curLegalEntityId).then((dataBusinessActivities) => {

				// We get current legal entity data
				const curLegalEntityData = legalEntities.find(context.getCurrentLegalEntityId());

				if (
					userCanEvaluate &&
					businessActivitiesFilteredByCurrentBranch !== undefined &&
					businessActivitiesFilteredByCurrentBranch !== null &&
					businessActivitiesFilteredByCurrentBranch.length > 0 &&
					dataBusinessActivities !== undefined &&
					dataBusinessActivities !== null &&
					dataBusinessActivities.length > 0
				) {

					// We show the update button for business activities
					$("#name-business-activity .title-box-context .btn-update").addClass("active");
					$("#name-business-activity .option-selected").css("top", "-5px");


				} else {

					// We hide the update button for business activities
					$("#name-business-activity .title-box-context .btn-update").removeClass("active");
					$("#name-business-activity .option-selected").css("top", "0px");

				}


				// We show the update button to select business activities if the current legal entity has no business activity selected
				if (curLegalEntityData.has_no_business_activity) {

					// We show the update button for business activities
					$("#name-business-activity .title-box-context .btn-update").addClass("active");
					$("#name-business-activity .option-selected").css("top", "-5px");

				}

				// We listen click on update button
				$("#name-business-activity .title-box-context .btn-update").on("click", this.handleClickOnBtnUpdateBusinessActivity.bind(this));

			}).catch((e) => debug.e(e));

		});

	}

	handleClickOnBtnAllBusinessActivities (e) {

		const curPathUrl = window.location.pathname;

		if (curPathUrl.indexOf("/risks") >= 0) {

			// We get the risk id saved on the local storage
			let localStorageCurrentRiskId = localStorage.getItem("risk-currentRiskId");

			// We get the current risk id
			let curRiskId;
			if (localStorageCurrentRiskId !== undefined && localStorageCurrentRiskId !== null) {

				curRiskId = localStorageCurrentRiskId;

			} else {

				curRiskId = curPathUrl.replace("/evaluate", "").match(/\d+$/)[0];

			}

			// We go to the risk page
			router.navigate(`/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curRiskId}/business-activities/all`);

		} else {

			// We go to the dashboard
			router.navigate(`/${this.getDashboardTypeToShow()}/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`);

		}

	}

	handleClickOnBusinessActivityName (e) {

		if (!$("#main-select-context").hasClass("freeze") && !$("#name-business-activity").hasClass("no-select") && $("#name-business-activity").find(".list-options").css("display") === "none") {

			// We show the list of business activities
			this.showListBusinessActivitiesName();

		} else {

			// We hide the list of business activities
			this.hideListBusinessActivitiesName();

		}

	}

	showListBusinessActivitiesName () {

		// We open the list of options
		$("#name-business-activity").addClass("active");

		// We change the arrow icon
		$("#name-business-activity").find(".option-selected .icon").removeClass("fa-angle-down");
		$("#name-business-activity").find(".option-selected .icon").addClass("fa-angle-up");

		// We hide the list of branches
		this.hideListBranchesName();

		// We hide the list of cartographies
		this.hideListCartographiesName();

		// We hide the list of profit centers
		this.hideListProfitCentersName();

	}

	hideListBusinessActivitiesName () {

		// We close the list of options
		$("#name-business-activity").removeClass("active");

		// We change the arrow icon
		$("#name-business-activity").find(".option-selected .icon").removeClass("fa-angle-up");
		$("#name-business-activity").find(".option-selected .icon").addClass("fa-angle-down");

	}

	handleClickOnOptionBusinessActivity (e) {

		// We hide the list of business activities
		this.hideListBusinessActivitiesName();

		// We reset the cache for the evaluation right of the current user
		user.resetCurrentUserEvaluationRightCache();

		// We update the menu
		menu.update();

	}

	handleClickOnBtnUpdateBusinessActivity () {

		// We save the current page url on the local storage
		localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

		// We redirect to the select business activity page
		router.navigate(`/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/select-business-activities`);

	}
	// END BUSINESS ACTIVITIES

	handleClickOnLogo () {

		// If current user has right on multiple cartographies
		if (cartographies.data !== undefined && cartographies.data.length > 1) {

			// We redirect the user to the dashboard with global cartographies view
			router.navigate(`/dashboard/branches/${context.getCurrentBranchId()}/cartographies/all/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`);

		} else {

			// We handle the homepage redirection
			this.handleHomepageRedirection();

		}

	}

	handleHomepageRedirection () {

		let routeToShow;

		// We show the page with the selected context
		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			routeToShow = `/dashboard-grid/branches/${context.getCurrentBranchId()}/profit-centers/all/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`;

		} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			routeToShow = `/dashboard-grid/branches/${context.getCurrentBranchId()}/countries/all/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/all`;

		} else {

			routeToShow = `/dashboard-grid/branches/${context.getCurrentBranchId()}/cartographies/${cartographies.getCurrentCartographyId()}/legal-entities/${context.getCurrentLegalEntityId()}/business-activities/all`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
		  routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	getDashboardTypeToShow () {

		const curPathUrl = window.location.pathname;

		let dashboardType;
		if (curPathUrl.indexOf("dashboard-list") >= 0) {

			dashboardType = "dashboard-list";

		} else if (curPathUrl.indexOf("dashboard-graph") >= 0) {

			dashboardType = "dashboard-graph";

		} else if (curPathUrl.indexOf("catalog") >= 0) {

			dashboardType = "catalog";

		} else {

			dashboardType = "dashboard-grid";

		}

		return dashboardType;

	}


}
export default new SelectContext();
