import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import Popin from "Components/Popin/Popin.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import risks from "Datapoints/Risks.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import businessActivities from "Datapoints/BusinessActivities.js";
import user from "Datapoints/User.js";
import "./Dashboard.scss";
import "./DashboardResponsive.scss";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";

export default class Dashboard extends Page {

	render (params) {

		return new Promise((resolve, reject) => {

			// We get the params
			this.params = params;

			// If the user arrive here after a login thrue SSO, he might want have a wanted page
			user.redirectAfterLogin();

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			// We show the page
			this.parseAndRender("page", this.html);

			// We show the select of context (BEFORE THE MENU)
			selectContext.show(this.params);

			// We show the menu
			menu.show();

			// We define the current cartography
			this.cartography = cartographies.find(cartographies.getCurrentCartographyId());

			try {

				// We initialize context events
				this.initContextEvents();

				// We initialize menu events
				this.initMenuEvents();

				let renderPromises = [];
				if (this.cartography !== undefined) {

					if (context.getCurrentLegalEntityId() !== null) {

						// We initialize page events
						this.initEvents();

						renderPromises.push(this.initAdminRiskButton(this.cartography.id));
						renderPromises.push(this.initRisks());

						Promise.all(renderPromises).then(resolve).catch(reject);

					} else {

						resolve();

					}

				}

			} catch (e) {

				debug.e("Loading of dashboard failed");
				debug.e(e);

			}

		});

	}

	initContextEvents () {

		// We listen click on options select legal entity
		$("#name-legal-entity").on("select2:select", (e) => {

		  this.handleClickOnOptionLegalEntity();

		});

	}

	initMenuEvents () {

		// We define click on buttons menu section
		$("#btn-risks-not-evaluated").on("click", this.handleClickOnBtnRisksNotEvaluated.bind(this));
		$("#btn-risks-ignored").on("click", this.handleClickOnBtnRisksIgnored.bind(this));
		$("#btn-risks-pending").on("click", this.handleClickOnBtnRisksPending.bind(this));

	}

	initAdminRiskButton (cartographyId) {

		return new Promise((resolve, reject) => {

			// We define new params
			const newParams = {
				"idCartography": cartographyId
			};

			// We get the current legal entity
			const legalEntity = legalEntities.find(context.getCurrentLegalEntityId());

			// We check if user have right to admin risks
			user.canAdminRisks(newParams).then((e) => {

				// We remove the suggest risk button
				$("#btn-suggest-risk").remove();

				// We add the button
				const hrefBtnAddRisk = `/legal-entities/${legalEntity.id}/cartographies/${cartographyId}/risk/create`;
				const createRiskButtonHtml = `<div id="btn-add-risk" class='active'><a href="${hrefBtnAddRisk}"><i class="icon fas fa-plus"></i><span class="text" data-i18n="button-new-risk">${$.i18n("button-new-risk")}</span></a></div>`;
				$("#risks-buttons").append(createRiskButtonHtml);

				resolve();

			}).catch((e) => {

				// We remove the button if it has been added on other cartography
				$("#btn-add-risk").remove();


				if (legalEntity !== undefined) {

					// We check if user have right to evaluate risks
					user.canEvaluateThisCartographyForThisLegalEntity(cartographyId, legalEntity.id).then((data) => {

						if (data) {

							// We add the button
							const hrefBtnSuggestRisk = `/cartographies/${cartographyId}/risk/suggest`;
							const suggestRiskButtonHtml = `<div id="btn-suggest-risk" class='active'><a href="${hrefBtnSuggestRisk}"><i class="icon fas fa-plus"></i><span class="text" data-i18n="button-suggest-risk">Proposer un risque</span></a></div>`;
							$("#risks-buttons").append(suggestRiskButtonHtml);

						} else {

							// We remove the suggest risk button if it has been added on other legal entity
							$("#btn-suggest-risk").remove();

						}
						resolve();

					}).catch((e) => {

						debug.e(e);
						reject();

					});

				}

				resolve();

			});

		});

	}

	initRisks () {

		return new Promise((resolve, reject) => {

			// We get the current branch id
			const curBranchId = context.getCurrentBranchId();

			// We get the current profit center id
			const curProfitCenterId = context.getCurrentProfitCenterId();

			// We get the current cartography id
			const curCartographyId = cartographies.getCurrentCartographyId();

			// We load the current legal entity
			const curLegalEntityId = context.getCurrentLegalEntityId();

			// We get the current country id
			const curCountryId = context.getCurrentCountryId();

			// We defines booleean to ease algo reading
			const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
			const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
			const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
			const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
			const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
			const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
			const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

			// If no parameters are given, we check if the legal entities has business activities
			if (!paramsGiven) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else if (isProfitCenterOnAll && isLegalEntityOnAll) {

				this.loadRisksForBranch(curCartographyId, curBranchId, resolve, reject);

			} else if (isCountryOnAll && isLegalEntityOnAll) {

				this.loadRisksForProfitCenter(curCartographyId, curProfitCenterId, resolve, reject);

			} else if (isLegalEntityOnAll) {

				this.loadRisksForCountry(curCartographyId, curCountryId, resolve, reject);

			} else if (isBusinessActivityAnId) {

				this.loadRisksForBusinessActivity(curCartographyId, curLegalEntityId, this.params.idBusinessActivity, resolve, reject);

			} else if (isBusinessActivityOnAll) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			}

		}).catch(console.e);

	}

	loadRisksForBusinessActivity (curCartographyId, curLegalEntityId, curBusinessActivityId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"legal_entity_id": curLegalEntityId,
			"business_activity_id": curBusinessActivityId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForLegalEntity (curCartographyId, curLegalEntityId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"legal_entity_id": curLegalEntityId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForBranch (curCartographyId, curBranchId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"branch_id": curBranchId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForProfitCenter (curCartographyId, curProfitCenterId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"profit_center_id": curProfitCenterId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForCountry (curCartographyId, curCountryId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"country_id": curCountryId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForContext (contextData, resolve, reject) {

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(contextData.cartography_id).then((risks) => {

				// We remove risks content
				this.emptyRisksContainers();

				// We show the risks
				this.showRisks(risks, evaluations);

				resolve();

			});

		}).catch((error) => {

			debug.v("Dashboard - initRisks - error in Cartographies getEvaluationForContext");
			reject();

		});

	}

	showRisks (riskData, evaluationData) {

		if (riskData !== undefined) {

			if (riskData.length > 0) {

				// We show the risks
				riskData.forEach((risk) => {

					const card = new CardRisk(risk);

					if (risk.status !== undefined && risk.status === "valid") {

						// We get the evaluation
						if (evaluationData !== undefined && evaluationData.error === undefined) {

							const riskEvaluation = evaluationData.filter((evaluation) => evaluation.risk_id === risk.id)[0];
							if (riskEvaluation !== undefined) {

								if (
								  riskEvaluation.risk_ignored !== undefined &&
									(riskEvaluation.risk_ignored === true || riskEvaluation.risk_ignored === 1 || riskEvaluation.risk_ignored === "1" || riskEvaluation.risk_ignored.ignored_for_whole_legal_entity !== undefined && riskEvaluation.risk_ignored.ignored_for_whole_legal_entity === true)
								) {

									// We put the risk in the RISK IGNORED section
									this.addRiskToIgnoredSection(card, risk);

								} else if (riskEvaluation.scores.control === null && riskEvaluation.scores.impact === null && riskEvaluation.scores.occurrence === null) {

									// We put the risk in the RISK NOT EVALUATED section
									this.addRiskToNotEvaluatedSection(card, risk);

								} else if (
									riskEvaluation.scores.control.average !== undefined &&
									riskEvaluation.scores.control.average === null &&
									riskEvaluation.scores.impact === null &&
									riskEvaluation.scores.occurrence.average !== undefined &&
									riskEvaluation.scores.occurrence.average === null
								) {

									// We put the risk in the RISK NOT EVALUATED section
									this.addRiskToNotEvaluatedSection(card, risk);

								} else {

									// We put the risk in the RISK EVALUATED section
									this.addRiskToEvaluatedSection(card, risk, riskEvaluation);

								}

								// If there is at least one WIP version of the current risk evaluation
								if (
									riskEvaluation.sums !== undefined &&
									riskEvaluation.sums.count_of_version_in_wip !== undefined &&
									riskEvaluation.sums.count_of_version_in_wip > 0
								) {

									// We add a WIP class on the card risk
									$(`.card-risk[data-id-risk='${risk.id}']`).addClass("wip");

									// We define the html for the risk version
									const htmlDraftLabel = `
									  <div class="line-risk-version">
											<div class="risk-version">
												<i class="icon fas fa-calendar-alt"></i>
												<span class="text" data-i18n="label-wip">${$.i18n("label-wip")}</span>
											</div>
										</div>`;

									// We add the risk version after the risk title
									$(htmlDraftLabel).appendTo(`.card-risk[data-id-risk='${risk.id}'] .title-risk`);

								}

							} else {

								// We put the risk in the RISK NOT EVALUATED section
								this.addRiskToNotEvaluatedSection(card, risk);

							}

						}

					} else if (risk.status !== undefined && risk.status === "pending") {

						this.addRiskToPendingSection(card, risk);

					}

				});

			}

		}

		// We define html of the message
		const htmlNoRiskSection = "<div class=\"msg-no-risk-section\"><div class=\"text\"><i class=\"icon fas fa-info-circle\"></i>&nbsp;&nbsp;</div></div>";

		// If there is no risk in risk not evaluated section, we hide it
		const numRisksNotEvaluated = $("#section-not-evaluated-risks .card-risk").length;
		if (numRisksNotEvaluated === 0) {

			$("#section-not-evaluated-risks").removeClass("active");
			$("#btn-risks-not-evaluated").removeClass("active");
			$("#btn-risks-not-evaluated").hide();

		} else {

			$("#section-not-evaluated-risks").addClass("active");
			$("#btn-risks-not-evaluated").addClass("active");
			$("#btn-risks-not-evaluated").show();

		}

		// We define number of risks in the evaluated section
		const numRisksEvaluated = $("#section-evaluated-risks .card-risk").length;

		// We get current cartography data
		const curCartographyData = cartographies.find(cartographies.getCurrentCartographyId());

		// If there is an 403 error AND at least one admin user for the current cartography
		if (
			evaluationData.error !== undefined &&
			evaluationData.error === "403" &&
			curCartographyData.admin_users !== undefined &&
			curCartographyData.admin_users !== null &&
			curCartographyData.admin_users.length > 0
		) {

			// We get current cartography name
			const curCartographyName = curCartographyData[`name_${this.currentLang}`];

			// We get the mail of the first admin users of the current cartography
			let curCartographyAdminUserMail;

			if (curCartographyData.admin_users[0].email !== undefined && curCartographyData.admin_users[0].email !== null && curCartographyData.admin_users[0].email !== "") {

				curCartographyAdminUserMail = curCartographyData.admin_users[0].email;

			} else {

				curCartographyAdminUserMail = process.env.CONTACT_EMAIL;

			}

			// We define the message
			let textMessageNoAccessEvaluation;
			if (curCartographyAdminUserMail.indexOf("@") >= 0) {

				textMessageNoAccessEvaluation = `${$.i18n("msg-no-access-evaluation-part-1")} <a href="mailto:${curCartographyAdminUserMail}?subject=${user.data.firstname} ${user.data.lastname} ${$.i18n("msg-no-access-evaluation-subject-mailto")} ${curCartographyName}">${$.i18n("msg-no-access-evaluation-part-2")}</a> ${$.i18n("msg-no-access-evaluation-part-3")}`;

			} else {

				textMessageNoAccessEvaluation = `${$.i18n("msg-no-access-evaluation-part-1")} <a href="${curCartographyAdminUserMail}" target="_blank">${$.i18n("msg-no-access-evaluation-part-2")}</a> ${$.i18n("msg-no-access-evaluation-part-3")}`;

			}

			// We show the message
			$("#section-evaluated-risks").find(".cols-grid").hide();
			$("#section-evaluated-risks .msg-no-risk-section").remove();
			$("#section-evaluated-risks").append(htmlNoRiskSection);
			$("#section-evaluated-risks .msg-no-risk-section .text").append(textMessageNoAccessEvaluation);

		} else if (
		  evaluationData.error === undefined &&
			numRisksEvaluated === 0
		) {

			// We get current legal entity data
			const curLegalEntityData = legalEntities.find(context.getCurrentLegalEntityId());

			// We get current legal entity name
			const curLegalEntityName = curLegalEntityData.name;

			// We define the message to show
			legalEntities.getBusinessActivities(context.getCurrentLegalEntityId()).then((businessActivities) => {

				// We get the current business activity
				const selectedBusinessActivity = $(".select-custom-name-business-activity .option-selected").attr("data-business-activity-id");

				let textMessage;

				if (
					businessActivities !== undefined &&
					businessActivities.length > 0 &&
					selectedBusinessActivity === "all"
				) {

					textMessage = `${$.i18n("msg-carto-no-exists-all-business-activities")} <span class="highlight">${curLegalEntityName}</span>`;

				} else if (
					businessActivities !== undefined &&
					businessActivities.length > 0 &&
					selectedBusinessActivity !== "all"
				) {

					const curBusinessActivitydata = businessActivities.filter((businessActivity) => parseInt(businessActivity.id) === parseInt(selectedBusinessActivity))[0];

					if (curBusinessActivitydata !== undefined) {

						const curBusinessActivityName = curBusinessActivitydata[`name_${this.currentLang}`];

						textMessage = `${$.i18n("msg-carto-no-exists-one-business-activity-part-1")} <span class="highlight">${curBusinessActivityName}</span>${$.i18n("msg-carto-no-exists-one-business-activity-part-2")} <span class="highlight">${curLegalEntityName}</span>`;

					}

				} else {

					textMessage = `${$.i18n("msg-carto-no-exists")} <span class="highlight">${curLegalEntityName}</span>`;

				}

				// We show the message
				$("#section-evaluated-risks").find(".cols-grid").hide();
				$("#section-evaluated-risks .msg-no-risk-section").remove();
				$("#section-evaluated-risks").append(htmlNoRiskSection);
				$("#section-evaluated-risks .msg-no-risk-section .text").append(textMessage);

			});

		} else {

			// We show the grid columns
			$("#section-evaluated-risks").find(".cols-grid").show();

			// We hide the message
			$("#section-evaluated-risks .msg-no-risk-section").remove();

		}

		// If there is no risk in risk ignored section, we hide it
		const numRisksIgnored = $("#section-ignored-risks .card-risk").length;
		if (numRisksIgnored === 0) {

			$("#section-ignored-risks").removeClass("active");
			$("#btn-risks-ignored").removeClass("active");
			$("#btn-risks-ignored").hide();

		} else {

			$("#section-ignored-risks").addClass("active");
			$("#btn-risks-ignored").addClass("active");
			$("#btn-risks-ignored").show();

		}

		// If there is no risk in pending risk section, we hide it
		const numRisksPending = $("#section-pending-risks .card-risk").length;
		if (numRisksPending === 0) {

			$("#section-pending-risks").removeClass("active");
			$("#btn-risks-pending").removeClass("active");
			$("#btn-risks-pending").hide();

		} else {

			// We define new params
			const newParams = {
				"idCartography": this.cartography.id
			};

			// We check if user have right to admin risks
			user.canAdminRisks(newParams).then((e) => {

				// We show the section
				$("#section-pending-risks").addClass("active");

				// We show the button section
				$("#btn-risks-pending").addClass("active");
				$("#btn-risks-pending").show();

			}).catch((e) => {

				// We hide the button section
				$("#btn-risks-pending").removeClass("active");
				$("#btn-risks-pending").hide();

			});

		}

		if (window.location.pathname.indexOf("dashboard-list") >= 0) {

			this.afterShowRisks(riskData);

		}

		// We listen to click on the risks
		$(".home-container .mask-content .item-content .card-risk").on("click", this.handleClickOnRisks.bind(this));

	}

	emptyRisksContainers () {

		$("#section-not-evaluated-risks .card-risk").remove();
		$("#section-evaluated-risks .card-risk").remove();
		$("#section-ignored-risks .card-risk").remove();
		$("#section-pending-risks .card-risk").remove();

	}

	updateNotEvaluatedCounter () {

		// We change the number of risks not evaluated in the menu button
		const nbrNotEvaluated = $("#section-not-evaluated-risks .card-risk").length;
		if (nbrNotEvaluated === 0) {

			$("#btn-risks-not-evaluated .content .num").html(1);

		} else {

			$("#btn-risks-not-evaluated .content .num").html(nbrNotEvaluated);

			if (nbrNotEvaluated === 1) {

				$("#btn-risks-not-evaluated .content .text").html(`${$.i18n("dashboard-label-risk-not-evaluated")}`);

			} else {

				$("#btn-risks-not-evaluated .content .text").html(`${$.i18n("dashboard-label-risk-not-evaluated-plural")}`);

			}

		}

	}

	updateIgnoredCounter () {

		// We change the number of risks ignored in the menu button
		const nbrIgnored = $("#section-ignored-risks .card-risk").length;
		if (nbrIgnored === 0) {

			$("#btn-risks-ignored .content .num").html(1);

		} else {

			$("#btn-risks-ignored .content .num").html(nbrIgnored);

			if (nbrIgnored === 1) {

				$("#btn-risks-ignored .content .text").html(`${$.i18n("dashboard-label-risk-ignored")}`);

			} else {

				$("#btn-risks-ignored .content .text").html(`${$.i18n("dashboard-label-risk-ignored-plural")}`);

			}

		}

	}

	updatePendingCounter () {

		// We change the number of pending risks in the menu button
		const nbrPending = $("#section-pending-risks .card-risk").length;
		if (nbrPending === 0) {

			$("#btn-risks-pending .content .num").html(1);

		} else {

			$("#btn-risks-pending .content .num").html(nbrPending);

			if (nbrPending === 1) {

				$("#btn-risks-pending .content .text").html(`${$.i18n("dashboard-label-risk-pending")}`);

			} else {

				$("#btn-risks-pending .content .text").html(`${$.i18n("dashboard-label-risk-pending-plural")}`);

			}

		}

	}

	handleClickOnRisks (e) {

		// We save the current page url on the local storage
		localStorage.setItem("navigation-previousPageUrl", window.location.pathname);

		// Get the ID of the current risk
		const curIdRisk = $(e.currentTarget).attr("data-id-risk");

		let routeToShow = "";

		if ($("#dashboard-grid-view").length > 0) { // If we are on grid view

			// If the risk is on pending section
			if ($(e.currentTarget).parents(".grid-content").attr("id") === "section-pending-risks") {

				// We show the edit form risk
				router.navigate(`/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/edit`);

			} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				// We show the risk page with all legal entities context
				routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				// We show the risk page with all profit centers context
				routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

				// We show the risk page with all countries context
				routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else {

				// We show the risk page
				routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/business-activities/all`;

			}

		} else if ($("#dashboard-list-view").length > 0) { // If we are on list view

			// If the risk is on pending section
			if ($(e.currentTarget).parents(".list-lines").attr("id") === "section-pending-risks") {

				// We show the edit form risk
				router.navigate(`/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/edit`);

			} else if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				// We show the risk page with all legal entities context
				routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

				// We show the risk page with all profit centers context
				routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

				// We show the risk page with all countries context
				routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

			} else {

				// We show the risk page
				routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}/business-activities/all`;

			}

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			routeToShow = routeToShow.replace("/business-activities/all", "");
			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
		  routeToShow.indexOf("/business-activities/all") < 0 &&
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null && routeToShow !== "") {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	showContent (indexContentToShow) {

		// We define item content
		const itemContent = $(".home-container .mask-content .item-content");

		// We show the content
		if (itemContent.eq(indexContentToShow).attr("class").indexOf("active") < 0) {

			this.clearContentView();
			itemContent.eq(indexContentToShow).addClass("active");

		}

	}

	clearContentView () {

		$(".home-container .mask-content .item-content").removeClass("active");

	}

	handleMouseOverOnCardRisk (e) {

		$(e.currentTarget).addClass("active");
		$(e.currentTarget).parents(".grid-content").addClass("over-card-risk");

	}

	handleMouseOutOnCardRisk (e) {

		$(".home-container .grid-content .card-risk").removeClass("active");
		$(".home-container .grid-content").removeClass("over-card-risk");

	}

	handleClickOnBtnRisksNotEvaluated () {

		// We get the position top of the section
		const posTopSection = $("#section-not-evaluated-risks").offset().top;

		// We get the height of the select context
		let heightSelectContext = 0;
		if ($("#main-select-context").height() !== undefined) {

			heightSelectContext = $("#main-select-context").height();

		}

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

	}

	handleClickOnBtnRisksIgnored () {

		// We get the position top of the section
		const posTopSection = $("#section-ignored-risks").offset().top;

		// We get the height of the select context
		let heightSelectContext = 0;
		if ($("#main-select-context").height() !== undefined) {

			heightSelectContext = $("#main-select-context").height();

		}

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

	}

	handleClickOnBtnRisksPending () {

		// We get the position top of the section
		const posTopSection = $("#section-pending-risks").offset().top;

		// We get the height of the select context
		let heightSelectContext = 0;
		if ($("#main-select-context").height() !== undefined) {

			heightSelectContext = $("#main-select-context").height();

		}

		// We show the section with smooth scrolling
		$("html, body").animate({"scrollTop": posTopSection - heightSelectContext}, 500);

	}

	handleClickOnOptionLegalEntity (e) {

		// We init risks data
		this.initRisks();

	}

}
