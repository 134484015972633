import router from "Core/router.js";
import debug from "Core/Debug.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import html from "./edit.html";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout";
import api from "Core/Api.js";
import news from "Datapoints/News.js";
import cartographies from "Datapoints/Cartographies.js";
import branches from "Datapoints/Branches.js";
import legalEntities from "Datapoints/LegalEntities.js";
import "../../Admin.scss";
import "../../AdminResponsive.scss";
import FieldTranslationButton from "Components/FieldTranslationButton/FieldTranslationButton.js";
import {showFormErrors, updateObjectWithForm, slugify, getUrlVars} from "Core/helpers.js";

class NewsEdit extends Page {

	constructor () {

		let routes = [
			"/admin/news/edit/:id",
			"/admin/news/create"
		];

		let datapointsToLoad = [
			news,
			cartographies,
			branches,
			legalEntities,
			context
		];

		super(routes, AuthentifiedLayout, "Modification d'une news", datapointsToLoad);
		this.rightsValidations = [
			user.getData.bind(user),
			user.canCommunicate.bind(user)
		];

	}

	render (params) {

		return new Promise((resolve, reject) => {

			this.urlVars = getUrlVars();

			// We get the current language
			$.i18n({"locale": user.data.language});
			this.currentLang = $.i18n().locale.substring(0, 2);

			this.params = params;
			this.idNews = null;

			this.parseAndRender("page", html);

			this.editedNews = {};
			this.richTextEditor = {};
			this.notificationPushed = false;
			this.formType = null;

			// We get the current branch id
			this.branchId = context.getCurrentBranchId();

			// We load global events
			this.initEvents();

			// We add translate buttons
			this.addTranslateButtons();

			$("FORM").on("submit", this.handleSubmit.bind(this));

			let renderPromises = [];
			renderPromises.push(menu.show());
			renderPromises.push(this.loadBranchesOptions());
			if (this.formType === "edit") {

				renderPromises.push(this.hideEditFormFields());

			}

			Promise.all(renderPromises).then(resolve).catch(reject);

		});

	}

	initEvents () {

		if (this.urlVars[0] !== undefined && this.urlVars[0] !== null) {

			if (this.urlVars[0].indexOf("create") >= 0) {

				this.formType = "create";

				// We update the title of the page
				$("html").find("title").html(`${$.i18n("admin-news-create-title-page")}`);
				const htmlTitleCreate = `<h1 data-i18n="admin-news-create-title-page">${$.i18n("admin-business-activities-create-title-page")}</h1>`;
				$(".title-page").html(htmlTitleCreate);

				// We update the label of the submit button
				const htmlButtonCreate = `<i class="icon fas fa-check"></i><span class="text" data-i18n="button-create">${$.i18n("button-create")}</span>`;
				$(".btn-submit").html(htmlButtonCreate);

			} else if (this.urlVars[0].indexOf("edit") >= 0) {

				this.formType = "edit";

				this.idNews = this.params.id;

				this.editedNews = news.find(this.idNews);

				this.autoFillPage(this.editedNews);
				this.autoFillForm("#edit_news", this.editedNews);

			}

		}

		// We initialize the rich text editor
		this.initTextEditor();

		// We manage the notifications settings
		if (process.env.SEND_EMAIL_NOTIFICATIONS === true || process.env.SEND_EMAIL_NOTIFICATIONS === "true") {

			// We listen click on notifications checkbox
			$("#notifications-settings .line-field").on("click", this.handleClickOnNotificationsCheckbox.bind(this));

		} else {

			// We remove the notifications settings
			$("#notifications-settings").remove();

		}

		// We listen click on cancel button
		$(".btn-cancel").on("click", this.handleClickOnCancelButton.bind(this));

	}

	handleClickOnCancelButton () {

		router.navigate("/admin/news");

	}

	hideEditFormFields () {

		// We hide the target fields (scope)
		$("#target-title").remove();
		$(".target-field").remove();

		// We hide the notifications settings field
		$("#notifications-settings").remove();

	}

	initTextEditor () {

		// Content Editor FR
		ClassicEditor.
	    create(document.querySelector("#content-editor-fr"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "fr",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	    	this.richTextEditor.content_fr = editor;

	      if (this.formType === "edit") {

					editor.setData(this.editedNews.comment_fr);

				}


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

		// Content Editor EN
		ClassicEditor.
	    create(document.querySelector("#content-editor-en"), {

	      "toolbar": {
	        "items": [
	          "heading",
	          "|",
	          "bold",
	          "italic",
	          "underline",
	          "link",
	          "bulletedList",
	          "numberedList",
	          "|",
	          "undo",
	          "redo"
	        ]
	      },
	      "language": "en",
	      "licenseKey": ""

	    }).
	    then((editor) => {

	      this.richTextEditor.content_en = editor;

	      if (this.formType === "edit") {

					editor.setData(this.editedNews.comment_en);

				}


	    }).
	    catch((error) => {

	      console.error("Oops, something gone wrong!");
	      console.error("Please, report the following error in the https://github.com/ckeditor/ckeditor5 with the build id and the error stack trace:");
	      console.warn("Build id: r5ecin76rjh5-dgrx4s9kgnn7");
	      console.error(error);

			});

	}

	handleClickOnNotificationsCheckbox (e) {

		const checkboxSelector = $(e.currentTarget).find(".fake-checkbox");
		const checkboxInactiveSelector = $(e.currentTarget).find(".fake-checkbox.inactive");

		if (checkboxInactiveSelector.length > 0) {

			// We show the check
			checkboxSelector.removeClass("inactive");

			// We update the value
			this.notificationPushed = true;

		} else {

			// We hide the check
			checkboxSelector.addClass("inactive");

			// We update the value
			this.notificationPushed = false;

		}

	}

	loadBranchesOptions () {

		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			// We get the user communicate rights
			const userCommunicateRights = user.data.rights.filter((right) => right.rights.indexOf("communicate") >= 0);

			// We create a new array to put user branches with the right to communicate
			let branchesWithCommunicateRight = [];

			$(userCommunicateRights).each((i, right) => {

				// Add branches for right at branch or country level
				if (right.branch_id !== undefined && right.branch_id !== null) {

					if (branchesWithCommunicateRight.indexOf(parseInt(right.branch_id)) < 0) {

						branchesWithCommunicateRight.push(parseInt(right.branch_id));

					}

				}

				// Add branches for right at legal entity level
				if (right.legal_entity_id !== undefined && right.legal_entity_id !== null) {

					const legalEntityBranchId = legalEntities.find(right.legal_entity_id).branch_id;

					if (branchesWithCommunicateRight.indexOf(parseInt(legalEntityBranchId)) < 0) {

						branchesWithCommunicateRight.push(parseInt(legalEntityBranchId));

					}

				}

			});

			// We show the branches
			$(branchesWithCommunicateRight).each((i, branchId) => {

				// We define branch data
				const branchData = branches.find(branchId);

				// We add the option for the branch
				$("FORM [name=branch_id]").append(`<option value='${branchData.id}'>${branchData[this.currentLang]}</option>`);

			});

			if (this.formType === "edit") {

				this.autoFillForm("#edit_news", this.editedNews);

			}

			// We listen the change on branch
			$("SELECT[name='branch_id']").on("change", this.handleChangeOnSelectBranch.bind(this));

		} else {

			// We remove the branch field
			$("#branch-field").remove();

			// We get the selected branch id
			const selectedBranchId = this.branchId;

			// We update the values
			this.editedNews.country_id = null;
			this.editedNews.legal_entity_id = null;

			// We load countries
			this.loadCountriesOptions(selectedBranchId);

		}

	}

	handleChangeOnSelectBranch (e) {

		// We get the selected branch id
		const selectedBranchId = $(e.currentTarget).val();

		// We update the values
		this.editedNews.country_id = null;
		this.editedNews.legal_entity_id = null;

		// We load countries
		this.loadCountriesOptions(selectedBranchId);

	}

	clearCountriesOptions () {

		// We remove the countries options
		$("SELECT[name='country_id']").html("");

		// We disable the country select
		$("SELECT[name='country_id']").parents("li").removeClass("disabled");
		$("SELECT[name='country_id']").parents("li").addClass("disabled");

	}

	clearLegalEntitiesOptions () {

		// We remove the legal entities options
		$("SELECT[name='legal_entity_id']").html("");

		// We disable the legal entity select
		$("SELECT[name='legal_entity_id']").parents("li").removeClass("disabled");
		$("SELECT[name='legal_entity_id']").parents("li").addClass("disabled");

		// We remove the selected legal entity on select 2
		$("#legal-entity-id").val("");
		$(".select2-selection__rendered").html("");
		$(".select2-selection__rendered").attr("title", "");

	}

	loadCountriesOptions (selectedBranchId) {

		// We clear the countries
		this.clearCountriesOptions();

		// We clear the legal entities
		this.clearLegalEntitiesOptions();

		if (selectedBranchId !== undefined && selectedBranchId !== null && selectedBranchId !== "") {

			user.getCountriesForRightToFilterOn(selectedBranchId, "communicate").then((data) => {

				// We get countries
				this.listCountries = data.countries;

				// We order countries by alphabetical order
				this.listCountries.sort((country1, country2) => country1[this.currentLang].localeCompare(country2[this.currentLang]));

				// We put the all item in the first line of the array
				const indexItemAll = this.listCountries.findIndex((country) => country === "all");
				if (indexItemAll > -1) {

					this.listCountries.splice(indexItemAll, 1);
					this.listCountries.unshift("all");

				}

				// We show countries
				if (this.listCountries !== undefined) {

					// We remove the countries options
					$("SELECT[name='country_id']").html("");

					this.listCountries.forEach((country, index) => {

						// We define the option
						let htmlOption;
						if (country === "all") {

							htmlOption = `<option value=\"all\">${$.i18n("labelAllCountries")}</option>`;

						} else {

							htmlOption = `<option value="${country.id}">${country[this.currentLang]}</option>`;

						}


						if (index === 0) {

							// We add first an empty option
							$("SELECT[name='country_id']").append("<option value=''></option>");

							// We add the option on the select
							$("SELECT[name='country_id']").append(htmlOption);

						} else {

							// We add the option on the select
							$("SELECT[name='country_id']").append(htmlOption);

						}

					});

					// We remove the inactive status on country select
					$("SELECT[name='country_id']").parents("li").removeClass("disabled");

				}

				// We listen the change on country
				$("SELECT[name='country_id']").on("change", this.handleChangeOnSelectCountry.bind(this));

			}).catch((error) => {

				debug.v("NewsEdit - loadCountriesOptions - error in User getCountriesForRightToFilterOn");

			});

		} else {

			// We disable the country select
			$("SELECT[name='country_id']").parents("li").removeClass("disabled");
			$("SELECT[name='country_id']").parents("li").addClass("disabled");

			// We disable the legal entity select
			$("SELECT[name='legal_entity_id']").parents("li").removeClass("disabled");
			$("SELECT[name='legal_entity_id']").parents("li").addClass("disabled");

		}

	}

	handleChangeOnSelectCountry (e) {

		// We get the selected branch id
		let selectedBranchId;
		if (process.env.SHOW_BRANCHES === true || process.env.SHOW_BRANCHES === "true") {

			selectedBranchId = $("SELECT[name='branch_id']").val();

		} else {

			selectedBranchId = this.branchId;

		}

		// We get the selected country id
		const selectedCountryId = $(e.currentTarget).val();

		// We update the value
		this.editedNews.legal_entity_id = null;

		// We load legal entities
		this.loadLegalEntitiesOptions(selectedBranchId, selectedCountryId);

	}

	loadLegalEntitiesOptions (selectedBranchId, selectedCountryId) {

		// We clear the legal entities
		this.clearLegalEntitiesOptions();

		if (
		  selectedBranchId !== undefined &&
			selectedBranchId !== null &&
			selectedBranchId !== "" &&
			selectedCountryId !== undefined &&
			selectedCountryId !== null &&
			selectedCountryId !== ""
		) {

			user.getLegalEntitiesForRightToFilterOn(selectedBranchId, selectedCountryId, "communicate").then((data) => {

				// Because the user could scroll thrue the select with keyboard,
				// (and that's how the browser does it in test condition),
				// we want to make sure the selected val is still the one wanted when request was sent
				if ($("SELECT[name='country_id']").val() != selectedCountryId) {

					return;

				}

				// We get legal entities
				this.listLegalEntities = data.legalEntities;

				// We load legal entities
				if (this.listLegalEntities !== undefined) {

					$("SELECT[name='legal_entity_id']").html("");

					this.listLegalEntities.forEach((legalEntity, index) => {

						// We define the option
						let htmlOption;
						if (legalEntity === "all") {

							htmlOption = `<option value=\"all\">${$.i18n("labelAllLegalEntities")}</option>`;

						} else {

							let htmlTelaNumber = "";

							if (legalEntity.tela_number !== undefined && legalEntity.tela_number !== null && legalEntity.tela_number !== "") {

								htmlTelaNumber = `(${legalEntity.tela_number})`;

							}

							htmlOption = `<option value="${legalEntity.id}">${legalEntity.name} ${htmlTelaNumber}</option>`;

						}

						if (index === 0) {

							// We add first an empty option
							$("SELECT[name='legal_entity_id']").append("<option value=''></option>");

							// We add the option on the select
							$("SELECT[name='legal_entity_id']").append(htmlOption);

						} else {

							// We add the option on the select
							$("SELECT[name='legal_entity_id']").append(htmlOption);

						}

					});

					// Initialize select 2
					$("#legal-entity-id").select2({
						"language": "fr"
					});

					// We put the placeholder on the input search of the select2
					$("#legal-entity-id").one("select2:open", (e) => {

						$("input.select2-search__field").prop("placeholder", `${$.i18n("form-placeholder-legal-entity")}`);

					});

					// Customize select 2
					$(".select2-selection__arrow").find("b").hide();
					$(".select2-selection__arrow").append("<i class=\"icon fas fa-angle-down\"></i>");

					// We remove the inactive status on country select
					$("SELECT[name='legal_entity_id']").parents("li").removeClass("disabled");

				}

			}).catch((error) => {

				debug.v("NewsEdit - loadLegalEntitiesOptions - error in User getLegalEntitiesForRightToFilterOn");

			});

		} else {

			// We disable the legal entity select
			$("SELECT[name='legal_entity_id']").parents("li").removeClass("disabled");
			$("SELECT[name='legal_entity_id']").parents("li").addClass("disabled");

		}

	}

	addTranslateButtons () {

		// Title FR
		const translationButtonForTitleFr = new FieldTranslationButton("title_fr", "text", "fr");

		// Title EN
		const translationButtonForTitleEn = new FieldTranslationButton("title_en", "text", "en");

		setTimeout(() => {

			// Content FR
			const translationButtonForContentFr = new FieldTranslationButton("content_fr", "textarea", "fr", this.richTextEditor.content_fr, this.richTextEditor.content_en);

			// Content EN
			const translationButtonForContentEn = new FieldTranslationButton("content_en", "textarea", "en", this.richTextEditor.content_fr, this.richTextEditor.content_en);

		}, 100);

	}

	handleSubmit (e) {

		e.preventDefault();
		this.clearFormErrors("edit_news");

		// We get the form values
		this.editedNews = updateObjectWithForm(this.editedNews, "#edit_news");

		if (process.env.SHOW_BRANCHES === undefined || process.env.SHOW_BRANCHES === false || process.env.SHOW_BRANCHES === "false") {

			this.editedNews.branch_id = parseInt(this.branchId);

		}

		// We add the pushed field
		if (this.formType === "create") {

			this.editedNews.pushed = this.notificationPushed;

		}

		// We define country id
		if (
			this.editedNews.country_id !== undefined &&
			this.editedNews.country_id !== null &&
			this.editedNews.country_id !== "" &&
			this.editedNews.country_id !== "all"
		) {

			this.editedNews.country_id = parseInt(this.editedNews.country_id);

		} else {

			delete this.editedNews.country_id;

		}

		// We define legal entity id
		if (
			this.editedNews.legal_entity_id !== undefined &&
			this.editedNews.legal_entity_id !== null &&
			this.editedNews.legal_entity_id !== "" &&
			this.editedNews.legal_entity_id !== "all"
		) {

			this.editedNews.legal_entity_id = parseInt(this.editedNews.legal_entity_id);

		} else {

			delete this.editedNews.legal_entity_id;

		}

		if (this.formType === "edit") { // EDIT NEWS

			// We can't update the pushed attribute, so we remove it from the data sent
			let dataToSend = this.editedNews;
			delete dataToSend.pushed;

			// Then we update all the fields
			news.updateOne(this.editedNews).then(() => {

				this.editSuccess();

			}, this.editFail.bind(this));

		} else if (this.formType === "create") { // CREATE NEWS

			// We define branch id
			this.editedNews.branch_id = this.editedNews.branch_id == "none" ? null : parseInt(this.editedNews.branch_id);

			// First, we add all the fields
			news.createOne(this.editedNews).then((newsCreationData) => {

				this.createSuccess(newsCreationData);

			}, this.editFail.bind(this));

		}

	}

	editSuccess () {

		debug.v("NewsEdit - editSuccess");

		news.getData({"forceRequest": true}).then(() => {

			// We redirect to the list news page
			router.navigate("/admin/news");

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("NewsEdit - editSuccess - error in User getData");

			});

		}).catch((error) => {

			debug.v("NewsEdit - editSuccess - error in News getData");

		});

	}

	createSuccess (creationData) {

		debug.v("NewsCreate - createSuccess");

		news.getData({"forceRequest": true}).then(() => {

			router.navigate("/admin/news");

			// We get the current user data
			user.getData().then().catch((error) => {

				debug.v("NewsCreate - createSuccess - error in User getData");

			});


		}).catch((error) => {

			debug.v("NewsCreate - createSuccess - error in News getData");

		});

	}

	editFail (response, textStatus) {

		if (response.status === 422) {

			let errorsToShow = {
				"title_fr": {
					"required": `${$.i18n("form-error-news-title-fr-required")}`,
					"taken": `${$.i18n("form-error-news-title-fr-taken")}`
				},
				"title_en": {
					"required": `${$.i18n("form-error-news-title-en-required")}`,
					"taken": `${$.i18n("form-error-news-title-en-taken")}`
				},
				"content_fr": {
					"required": `${$.i18n("form-error-news-content-fr-required")}`
				},
				"content_en": {
					"required": `${$.i18n("form-error-news-content-en-required")}`
				},
				"branch_id": {
					"required": `${$.i18n("form-error-news-target-required")}`,
					"not_allowed": `${$.i18n("form-error-branch-not-allowed")}`
				},
				"country_id": {
					"required": `${$.i18n("form-error-news-target-required")}`,
					"not_allowed": `${$.i18n("form-error-country-not-allowed")}`
				},
				"legal_entity_id": {
					"required": `${$.i18n("form-error-news-target-required")}`,
					"not_allowed": `${$.i18n("form-error-legal-entity-not-allowed")}`
				}
			};

			showFormErrors("#edit_news", response, errorsToShow);

			// We show the first error
			let positionTopFirstError;
			if ($(".error").eq(0).parents(".section").length > 0) {

				positionTopFirstError = $(".error").eq(0).parents(".section").offset().top;

			} else {

				positionTopFirstError = $(".error").eq(0).parents("li").offset().top;

			}

			$("html, body").animate({"scrollTop": positionTopFirstError}, 400);

		} else if (response.status === 403) {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-target-rights")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		} else {

			$("FORM").prepend(`<div class='error'>${$.i18n("form-error-global")}</div>`);

			// We back on the top of the page
			$("html, body").animate({"scrollTop": 0}, 400);

		}

	}

}
export default new NewsEdit();
