import router from "Core/router.js";
import debug from "Core/Debug.js";
import {showFormErrors, updateObjectWithForm} from "Core/helpers.js";
import Page from "Pages/Page.js";
import menu from "Components/Menu/Menu.js";
import selectContext from "Components/SelectContext/SelectContext.js";
import CardRisk from "Components/CardRisk/CardRisk.js";
import html from "./catalog.html";
import AuthentifiedLayout from "Layouts/Authentified/AuthentifiedLayout.js";
import user from "Datapoints/User.js";
import context from "Datapoints/Context.js";
import cartographies from "Datapoints/Cartographies.js";
import legalEntities from "Datapoints/LegalEntities.js";
import profitCenters from "Datapoints/ProfitCenters.js";
import branches from "Datapoints/Branches.js";
import countries from "Datapoints/Countries.js";
import risks from "Datapoints/Risks.js";
import "./Catalog.scss";
import "./CatalogResponsive.scss";
// Import select2
import "Core/assets/lib/select2/css/select2.min.css";
import "Core/assets/lib/select2/js/select2.full.min.js";
import "Core/assets/lib/select2/js/i18n/fr.js";

class Catalog extends Page {

	constructor () {

		let routes = [
			"/catalog",
			"/catalog/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/catalog/branches/:idBranch/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/catalog/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/catalog/branches/:idBranch/profit-centers/:idProfitCenter/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity",
			"/catalog/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity",
			"/catalog/branches/:idBranch/countries/:idCountry/cartographies/:idCartography/legal-entities/:idLegalEntity/business-activities/:idBusinessActivity"
		];

		let datapointsToLoad = [
			context,
			cartographies,
			legalEntities,
			profitCenters,
			branches,
			countries
		];

		super(routes, AuthentifiedLayout, "Catalogue de risques", datapointsToLoad);
		this.rightsValidations = [user.getData.bind(user)];

	}

	render (params) {

		// We get the params
		this.params = params;

		// We get the current language
		$.i18n({"locale": user.data.language});
		this.currentLang = $.i18n().locale.substring(0, 2);

		// We show the page
		this.parseAndRender("page", html);

		// We show the select of context (BEFORE THE MENU)
		selectContext.show(this.params);

		// We show the menu
		menu.show();

		// We define the current branch
		this.branch = branches.find(context.getCurrentBranchId());

		// We define the current cartography
		this.cartography = cartographies.find(cartographies.getCurrentCartographyId());

		// We define the current cartography
		this.legalEntity = legalEntities.find(context.getCurrentLegalEntityId());

		// We init risks data
		this.initRisks();

		// We initialize page events
		this.initEvents();

		// We initialize context events
		this.initContextEvents();

	}

	initContextEvents () {

		// We listen click on options select legal entity
		$("#name-legal-entity").on("select2:select", (e) => {

		  this.handleClickOnOptionLegalEntity();

		});

	}

	initEvents () {

		// We show the name of the current cartography in the page title
		$("#main-page-title .small-text .name-cartography").html(this.cartography.name);

		// We listen to click on the button to sort by color
		$("#btn-sort-color").on("click", this.handleClickOnBtnSortByColor.bind(this));

		// We listen to click on the button to sort by theme
		$("#btn-sort-theme").on("click", this.handleClickOnBtnSortByTheme.bind(this));

		// We define sort by default
		$("#btn-sort-color").removeClass("active");
		$("#btn-sort-theme").addClass("active");

	}

	initRisks () {

		return new Promise((resolve, reject) => {

			// We get the current branch id
			const curBranchId = context.getCurrentBranchId();

			// We get the current profit center id
			const curProfitCenterId = context.getCurrentProfitCenterId();

			// We get the current cartography id
			const curCartographyId = cartographies.getCurrentCartographyId();

			// We load the current legal entity
			const curLegalEntityId = context.getCurrentLegalEntityId();

			// We get the current country id
			const curCountryId = context.getCurrentCountryId();

			// We defines booleean to ease algo reading
			const paramsGiven = this.params !== undefined && this.params !== null && this.params !== "";
			const isProfitCenterOnAll = paramsGiven && this.params.idProfitCenter === "all";
			const isLegalEntityOnAll = paramsGiven && this.params.idLegalEntity === "all";
			const isCountryOnAll = paramsGiven && this.params.idCountry === "all";
			const isBusinessActivityGiven = paramsGiven && this.params.idBusinessActivity !== undefined && this.params.idBusinessActivity !== null;
			const isBusinessActivityAnId = isBusinessActivityGiven && this.params.idBusinessActivity !== "all";
			const isBusinessActivityOnAll = isBusinessActivityGiven && this.params.idBusinessActivity === "all";

			// If no parameters are given, we check if the legal entities has business activities
			if (!paramsGiven) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else if (isProfitCenterOnAll && isLegalEntityOnAll) {

				this.loadRisksForBranch(curCartographyId, curBranchId, resolve, reject);

			} else if (isCountryOnAll && isLegalEntityOnAll) {

				this.loadRisksForProfitCenter(curCartographyId, curProfitCenterId, resolve, reject);

			} else if (isLegalEntityOnAll) {

				this.loadRisksForCountry(curCartographyId, curCountryId, resolve, reject);

			} else if (isBusinessActivityAnId) {

				this.loadRisksForBusinessActivity(curCartographyId, curLegalEntityId, this.params.idBusinessActivity, resolve, reject);

			} else if (isBusinessActivityOnAll) {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			} else {

				this.loadRisksForLegalEntity(curCartographyId, curLegalEntityId, resolve, reject);

			}

		}).catch(console.e);

	}

	loadRisksForBusinessActivity (curCartographyId, curLegalEntityId, businessActivityId, resolve, reject) {

		// We load evaluations
		cartographies.getEvaluationForBusinessActivity(curCartographyId, curLegalEntityId, businessActivityId).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(curCartographyId).then((risks) => {

				// We remove risks content
				$("#list-risks").html("");

				if (risks.length > 0) {

					// We show the risks
					this.showRisks(risks, evaluations);

				} else {

					// We hide the title
					$("#main-page-title").hide();

					// We show the no risk message
					$("#msg-no-risk .text .highlight").html($("#name-cartography .option-selected .text").html());
					$("#msg-no-risk").addClass("active");

				}

				resolve();

			}).catch((error) => {

				debug.e("Catalog - loadRisksForBusinessActivity - error in Risks getRisksCartography");
				reject();

			});

		}).catch((error) => {

			debug.e("Catalog - loadRisksForBusinessActivity - error in Cartographies getEvaluationForBusinessActivity");
			reject();

		});

	}

	loadRisksForLegalEntity (curCartographyId, curLegalEntityId, resolve, reject) {

		legalEntities.getBusinessActivities(curLegalEntityId).then((businessActivities) => {

			// We show legal entity consolidation if at least one business activity exists
			if (businessActivities.length > 0) {

				const contextData = {
					"cartography_id": curCartographyId,
					"legal_entity_id": curLegalEntityId
				};

				// We load evaluations for context
				cartographies.getEvaluationForContext(contextData).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						// We remove risks content
						$("#list-risks").html("");

						if (risks.length > 0) {

							// We show the risks
							this.showRisks(risks, evaluations);

						} else {

							// We hide the title
							$("#main-page-title").hide();

							// We show the no risk message
							$("#msg-no-risk .text .highlight").html($("#name-cartography .option-selected .text").html());
							$("#msg-no-risk").addClass("active");

						}

						resolve();

					});

				}).catch((error) => {

					debug.v("Catalog - loadRisksForLegalEntity - error in Cartographies getEvaluationForContext");
					reject();

				});

			}
			// Otherwise we show legal entity evaluations
			else {

				cartographies.getEvaluation(curCartographyId, curLegalEntityId).then((evaluations) => {

					// We load risks
					risks.getRisksCartography(curCartographyId).then((risks) => {

						// We remove risks content
						$("#list-risks").html("");

						if (risks.length > 0) {

							// We show the risks
							this.showRisks(risks, evaluations);

						} else {

							// We hide the title
							$("#main-page-title").hide();

							// We show the no risk message
							$("#msg-no-risk .text .highlight").html($("#name-cartography .option-selected .text").html());
							$("#msg-no-risk").addClass("active");

						}

						resolve();

					});

				}).catch((error) => {

					debug.v("Catalog - loadRisksForLegalEntity - error in Cartographies getEvaluation");
					reject();

				});

			}

		});

	}

	loadRisksForBranch (curCartographyId, curBranchId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"branch_id": curBranchId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForProfitCenter (curCartographyId, curProfitCenterId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"profit_center_id": curProfitCenterId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForCountry (curCartographyId, curCountryId, resolve, reject) {

		// We define the context data
		const contextData = {
			"cartography_id": curCartographyId,
			"country_id": curCountryId
		};

		this.loadRisksForContext(contextData, resolve, reject);

	}

	loadRisksForContext (contextData, resolve, reject) {

		// We load evaluations for context
		cartographies.getEvaluationForContext(contextData).then((evaluations) => {

			// We load risks
			risks.getRisksCartography(contextData.cartography_id).then((risks) => {

				// We remove risks content
				$("#list-risks").html("");

				if (risks.length > 0) {

					// We show the risks
					this.showRisks(risks, evaluations);

				} else {

					// We hide the title
					$("#main-page-title").hide();

					// We show the no risk message
					$("#msg-no-risk .text .highlight").html($("#name-cartography .option-selected .text").html());
					$("#msg-no-risk").addClass("active");

				}

				resolve();

			});

		}).catch((error) => {

			debug.v("Catalog - loadRisksForContext - error in Cartographies getEvaluationForContext");
			reject();

		});

	}

	showRisks (riskData, evaluationData) {

		if (riskData !== undefined) {

			if (riskData.length > 0) {

				// We show the risks
				riskData.forEach((risk) => {

					const card = new CardRisk(risk);

					if (risk.status !== undefined && risk.status === "valid") {

						// We get the evaluation
						if (evaluationData !== undefined) {

							const riskEvaluation = evaluationData.filter((evaluation) => evaluation.risk_id === risk.id)[0];
							// We put the risk in the list of risks
							$("#list-risks").css("display", "flex");
							const selector = "#list-risks";
							if (riskEvaluation !== undefined) {

								// We define card color
								const cardColor = card.getCardColor(riskEvaluation);
								card.appendAndFill(selector, cardColor, risk, riskEvaluation.scores);

							} else {

								card.appendAndFill(selector, "grey", risk, []);

							}

							// We had a unique id on evaluation form
							$("#list-risks .card-risk").last().find("form.evaluate-risk").attr("id", `form-evaluate-risk-${risk.id}`);

							// We apply auto fill form
							this.autoFillForm(`#form-evaluate-risk-${risk.id}`, riskEvaluation);

							// We save the loaded value
							$("[name^='scores[']").each((i, input) => {

								$(input).attr("data-loaded-value", $(input).val());

							});

							// We hide the submit button
							$(`.card-risk-${risk.id} .line-buttons-bottom`).hide();

							// We listen submit on form
							$(`#form-evaluate-risk-${risk.id}`).on("submit", (e) => {

								// We cancel the form submission
								e.preventDefault();

								this.handleSubmit(risk, riskEvaluation);

							});

							// We show the major impact
							if (riskEvaluation !== undefined && riskEvaluation.scores.impact_major !== undefined) {

								this.autoFillMajorImpact(risk.id, riskEvaluation, riskEvaluation.scores.impact_major);

							}

						}

					}

				});

				// We show the buttons of the menu
				$("#btn-risks-not-evaluated").addClass("active");
				$("#btn-risks-ignored").addClass("active");

				// We listen on change on ignored checkbox
				$("[name='risk_ignored']").on("change", this.handleCheckboxRiskIgnoredChange.bind(this));

				// We listen on change on all the scores
				$("[name^='scores[']").on("change keypress keyup keydow", this.handleScoreChange.bind(this));

				// We listen change on impact scores fields
				$("[name='scores[financial_impact]']").on("change keypress keyup keydown", this.handleImpactChange.bind(this));
				$("[name='scores[image_impact]']").on("change keypress keyup keydown", this.handleImpactChange.bind(this));
				$("[name='scores[legal_impact]']").on("change keypress keyup keydown", this.handleImpactChange.bind(this));

			}

		}

		// We listen the click on the button more
		$("#list-risks .card-risk .btn-more").on("click", this.handleClickOnBtnMore.bind(this));

		this.afterShowRisks(riskData);

	}

	afterShowRisks (riskData) {

		if (riskData.length > 0) {

			// We show the sorts options
			$(".box-sort").addClass("active");

			this.sortRisksByThemeAscending();

		} else {

			// We hide the sorts options
			$(".box-sort").removeClass("active");

			// We hide the list
			$(".list-content").removeClass("active");

		}

	}

	handleClickOnBtnSortByColor (e) {

		this.sorting = "colorAsc";
		this.sortRisksByColorAscending();
		this.updateButtonsToCurrentSorting();

	}

	handleClickOnBtnSortByTheme (e) {

		this.sorting = "themeAsc";
		this.sortRisksByThemeAscending();
		this.updateButtonsToCurrentSorting();

	}

	updateButtonsToCurrentSorting () {

		switch (this.sorting) {

			case "colorAsc":
				$("#btn-sort-color").addClass("active");
				$("#btn-sort-theme").removeClass("active");
				break;

			case "themeAsc":
				$("#btn-sort-color").removeClass("active");
				$("#btn-sort-theme").addClass("active");
				break;

		}

	}

	sortRisksByColorAscending () {

		$("#list-risks .card-risk").sort((a, b) => {

			const rankColorA = this.getRankColor(a);
			const rankColorB = this.getRankColor(b);

			return rankColorA - rankColorB;

		}).appendTo("#list-risks");

		this.sorting = "colorAsc";

	}

	getRankColor (cardRisk) {

		let color;
		if ($(cardRisk).attr("class").match(/card-risk color-(.*) card-risk-/) !== null) {

			color = $(cardRisk).attr("class").match(/card-risk color-(.*) card-risk-/)[1];

		} else {

			color = "grey";

		}

		let rankColor = 0;
		switch (color) {

			case "grey":
				rankColor = 1;
				break;

			case "green":
				rankColor = 2;
				break;

			case "yellow":
				rankColor = 3;
				break;

			case "orange":
				rankColor = 4;
				break;

			case "red":
				rankColor = 5;
				break;

		}

		return rankColor;

	}

	sortRisksByThemeAscending () {

		$("#list-risks .card-risk").sort((a, b) => {

			const riskA = risks.find($(a).attr("data-id-risk"));
			const riskB = risks.find($(b).attr("data-id-risk"));

			return riskA.theme.localeCompare(riskB.theme);

		}).appendTo("#list-risks");

		this.sorting = "themeAsc";

	}

	autoFillMajorImpact (riskId, riskEvaluation, curMajorImpact) {

		const curFormId = `form-evaluate-risk-${riskId}`;

		let inputFinancial = $(`#${curFormId} [name='scores[financial_impact]']`);
		let inputImage = $(`#${curFormId} [name='scores[image_impact]']`);
		let inputLegal = $(`#${curFormId} [name='scores[legal_impact]']`);

		let financialEqualsImage = inputFinancial.val() == inputImage.val();
		let financialEqualsLegal = inputFinancial.val() == inputLegal.val();
		let ImageEqualsLegal = inputImage.val() == inputLegal.val();

		if (
			curMajorImpact === "financial_impact" &&
			(riskEvaluation.scores.financial_impact === riskEvaluation.scores.image_impact || riskEvaluation.scores.financial_impact === riskEvaluation.scores.legal_impact)
		) {

			inputFinancial.after(this.getHtmlForImpactMajor("financial_impact"));
			$(`#${curFormId} .impact_major_financial_impact`).attr("checked", "checked");

			if (financialEqualsImage) {

				inputImage.after(this.getHtmlForImpactMajor("image_impact"));

			} else if (financialEqualsLegal) {

				inputLegal.after(this.getHtmlForImpactMajor("legal_impact"));

			}

		} else if (
			curMajorImpact === "image_impact" &&
			(riskEvaluation.scores.image_impact === riskEvaluation.scores.financial_impact || riskEvaluation.scores.image_impact === riskEvaluation.scores.legal_impact)
		) {

			inputImage.after(this.getHtmlForImpactMajor("image_impact"));
			$(`#${curFormId} .impact_major_image_impact`).attr("checked", "checked");

			if (financialEqualsImage) {

				inputFinancial.after(this.getHtmlForImpactMajor("financial_impact"));

			} else if (ImageEqualsLegal) {

				inputLegal.after(this.getHtmlForImpactMajor("legal_impact"));

			}

		} else if (
			curMajorImpact === "legal_impact" &&
			(riskEvaluation.scores.legal_impact === riskEvaluation.scores.financial_impact || riskEvaluation.scores.legal_impact === riskEvaluation.scores.image_impact)
		) {

			inputLegal.after(this.getHtmlForImpactMajor("legal_impact"));
			$(`#${curFormId} .impact_major_legal_impact`).attr("checked", "checked");

			if (financialEqualsLegal) {

				inputFinancial.after(this.getHtmlForImpactMajor("financial_impact"));

			} else if (ImageEqualsLegal) {

				inputImage.after(this.getHtmlForImpactMajor("image_impact"));

			}

		}

	}

	handleImpactChange (e) {

		const curFormId = $(e.currentTarget).parents("form").attr("id");

		let inputFinancial = $(`#${curFormId} [name='scores[financial_impact]']`);
		let inputImage = $(`#${curFormId} [name='scores[image_impact]']`);
		let inputLegal = $(`#${curFormId} [name='scores[legal_impact]']`);

		let financialEqualsImage = inputFinancial.val() == inputImage.val();
		let financialEqualsLegal = inputFinancial.val() == inputLegal.val();
		let ImageEqualsLegal = inputImage.val() == inputLegal.val();

		let maxImpactValue = Math.max(...[
			inputFinancial.val(),
			inputImage.val(),
			inputLegal.val()
		]);

		let financialIsAmongBiggest = inputFinancial.val() == maxImpactValue;
		let imageIsAmongBiggest = inputImage.val() == maxImpactValue;
		let legalIsAmongBiggest = inputLegal.val() == maxImpactValue;

		let showMajorOnFinancial = (financialEqualsImage || financialEqualsLegal) && inputFinancial.val() != "" && financialIsAmongBiggest;
		let showMajorOnImage = (financialEqualsImage || ImageEqualsLegal) && inputImage.val() != "" && imageIsAmongBiggest;
		let showMajorOnLegal = (financialEqualsLegal || ImageEqualsLegal) && inputLegal.val() != "" && legalIsAmongBiggest;

		if (
			showMajorOnFinancial &&
			financialIsAmongBiggest &&
			inputFinancial.siblings(`#${curFormId} .impact_major_content`).length == 0
		) {

			inputFinancial.after(this.getHtmlForImpactMajor("financial_impact"));

		} else if (!showMajorOnFinancial) {

			inputFinancial.siblings(`#${curFormId} .impact_major_content`).remove();

		}

		if (
			showMajorOnImage &&
			imageIsAmongBiggest &&
			inputImage.siblings(`#${curFormId} .impact_major_content`).length == 0
		) {

			inputImage.after(this.getHtmlForImpactMajor("image_impact"));

		} else if (!showMajorOnImage) {

			inputImage.siblings(`#${curFormId} .impact_major_content`).remove();

		}

		if (
			showMajorOnLegal &&
			legalIsAmongBiggest &&
			inputLegal.siblings(`#${curFormId} .impact_major_content`).length == 0
		) {

			inputLegal.after(this.getHtmlForImpactMajor("legal_impact"));

		} else if (!showMajorOnLegal) {

			inputLegal.siblings(`#${curFormId} .impact_major_content`).remove();

		}

	}

	handleScoreChange (e) {

		const riskId = $(e.currentTarget).parents(".card-risk").attr("data-id-risk");

		// We loop on the scores to see if one has changed
		let atLeastOneIsDifferent = false;
		$("[name^='scores[']").each((i, input) => {

			let inputName = $(input).attr("name");
			if (inputName != "scores[impact_major]") {

				let loadedValue = $(input).attr("data-loaded-value");
				let currentValue = $(input).val();

				if (currentValue != loadedValue) {

					atLeastOneIsDifferent = true;

				}

			} else if (inputName == "scores[impact_major]") {

				atLeastOneIsDifferent = true;

			}

		});

		// We show the submit button if a score has changed
		if (atLeastOneIsDifferent) {

			$(`.card-risk-${riskId} .line-buttons-bottom`).show();

		} else {

			$(`.card-risk-${riskId} .line-buttons-bottom`).hide();

		}

	}

	getHtmlForImpactMajor (value) {

		return `<span class='impact_major_content'><input class='impact_major_${value}' type='radio' name='scores[impact_major]' value='${value}'/></span>`;

	}

	handleCheckboxRiskIgnoredChange (e) {

		// We get the risk id of the checkbox
		const curRiskId = $(e.currentTarget).attr("id").match(/\d+/)[0];

		// We load the risks
		risks.getRisksCartography(this.cartography.id).then((risks) => {

			const curRisk = risks.filter((risk) => risk.id == curRiskId)[0];

			cartographies.getEvaluation(this.cartography.id, this.legalEntity.id).then((evaluationData) => {

				const curRiskEvaluation = evaluationData.filter((evaluation) => evaluation.risk_id == curRiskId)[0];

			});

		});

		$(`.card-risk-${curRiskId} .line-buttons-bottom`).show();

	}

	handleSubmit (risk, riskEvaluation) {

		debug.v("Catalog - handleSubmit");

		this.clearFormErrors(`form-evaluate-risk-${risk.id}`);

		// If no evaluation is found (first time the risk is evaluated for these legal entity), we create an empty one
		if (riskEvaluation == undefined) {

			riskEvaluation = {};

		}

		// We get manually the scores
		riskEvaluation.scores = {};
		riskEvaluation.scores.financial_impact = $(`#form-evaluate-risk-${risk.id} input[name='scores[financial_impact]']`).val();
		riskEvaluation.scores.image_impact = $(`#form-evaluate-risk-${risk.id} input[name='scores[image_impact]']`).val();
		riskEvaluation.scores.legal_impact = $(`#form-evaluate-risk-${risk.id} input[name='scores[legal_impact]']`).val();
		riskEvaluation.scores.occurrence = $(`#form-evaluate-risk-${risk.id} input[name='scores[occurrence]']`).val();
		riskEvaluation.scores.control = $(`#form-evaluate-risk-${risk.id} input[name='scores[control]']`).val();
		riskEvaluation.scores.impact_major = $(`#form-evaluate-risk-${risk.id} input[name='scores[impact_major]']:checked`).val();
		riskEvaluation.risk_id = risk.id;
		riskEvaluation.legal_entity_id = this.legalEntity.id;
		riskEvaluation.risk_ignored = $(`#form-evaluate-risk-${risk.id} input[name='risk_ignored']:checked`).length == 1 ? 1 : 0;

		cartographies.evaluateOne(riskEvaluation).then((value) => {

		  this.updateSuccess(value, risk);

		  // We hide the submit button
			$(`.card-risk-${risk.id} .line-buttons-bottom`).hide();


		}, (reason) => {

		  this.updateFail(risk.id, reason);

		});

	}

	updateSuccess (response, risk) {

		debug.v("Catalog - updateSuccess");

		const params = {
			"idCartography": this.cartography.id,
			"idLegalEntity": this.legalEntity.id
		};

		risks.getData(params).then(() => {

			router.navigate(`/catalog/branches/${this.branch.id}/cartographies/${this.cartography.id}/legal-entities/${this.legalEntity.id}`);

			cartographies.getEvaluation(this.cartography.id, this.legalEntity.id).then((evaluationData) => {

				const riskEvaluation = evaluationData.filter((evaluation) => evaluation.risk_id === risk.id)[0];

				// We update the card color
				this.updateCardColor(risk, riskEvaluation);

			}).catch((error) => {

				debug.v("Catalog - updateSuccess - error in Cartographies getEvaluation");

			});

		}).catch((error) => {

			debug.v("Catalog - updateSuccess - error in Risks getData");

		});

	}

	updateFail (riskId, response, textStatus) {

		debug.v("Catalog - updateFail");

		if (response.status == 422) {

			let errorsToShow = {
				"scores[occurrence]": {
					"required": "Vous devez saisir une occurrence",
					"min_1_max_6": "Une occurrence ne peut aller que de 1 à 6"
				},
				"scores[control]": {
					"required": "Vous devez saisir une maîtrise",
					"min_1_max_6": "Une maîtrise ne peut aller que de 1 à 6"
				},
				"scores[financial_impact]": {
					"at_least_one_impact_required": "Vous devez saisir au moins un impact",
					"min_1_max_6": "Un impact ne peut aller que de 1 à 6",
					"no_major_impact_given": "Vous devez choisir un impact principal"
				},
				"scores[image_impact]": {
					"at_least_one_impact_required": "Vous devez saisir au moins un impact",
					"min_1_max_6": "Un impact ne peut aller que de 1 à 6",
					"no_major_impact_given": "Vous devez choisir un impact principal"
				},
				"scores[legal_impact]": {
					"at_least_one_impact_required": "Vous devez saisir au moins un impact",
					"min_1_max_6": "Un impact ne peut aller que de 1 à 6",
					"no_major_impact_given": "Vous devez choisir un impact principal"
				},
				"risk_id": {
					"required": "Le risque n'est pas défini"
				},
				"legal_entity_id": {
					"required": "L'entité juridique n'est pas définie"
				}
			};

			showFormErrors(`#form-evaluate-risk-${riskId}`, response, errorsToShow);

		} else {

			$(`#form-evaluate-risk-${riskId}`).prepend("<div class='error'>Une erreur est survenue, merci de contacter l'administrateur.</div>");

		}

	}

	updateCardColor (risk, riskEvaluation) {

		const card = new CardRisk(risk);

		// We define card color
		const cardColor = card.getCardColor(riskEvaluation);

		// We clear color and add new color class
		$("#list-risks").find(`.card-risk-${risk.id}`).removeClass("color-green color-yellow color-orange color-red color-grey");
		$("#list-risks").find(`.card-risk-${risk.id}`).addClass(`color-${cardColor}`);

	}

	handleClickOnBtnMore (e) {

		const containerSelector = $(e.currentTarget).parents(".card-risk");
		const contentSelector = $(e.currentTarget).parents(".card-risk").find(".content-card-risk");

		if (contentSelector.attr("class").indexOf("active") < 0) { // We show the card risk content

			// We get height of the current card risk container
			const curCardRiskHeight = containerSelector.height();

			// We get height of the current card risk content
			const curContentHeight = contentSelector.height();

			// We define the top position of the content of the current card risk
			contentSelector.css("top", curCardRiskHeight);

			// We change the height of the current card risk
			containerSelector.css("height", curCardRiskHeight + curContentHeight);

			// We add an active class on the content card risk
			contentSelector.addClass("active");

			// We change the icon of the button
			$(e.currentTarget).find(".icon").removeClass("fa-angle-down");
			$(e.currentTarget).find(".icon").addClass("fa-angle-up");

			// We change the text of the button
			$(e.currentTarget).find(".text").html($.i18n("label-btn-less-content-card-risk"));

			// We listen to click on the see risk button
			$(".btn-see-risk").on("click", this.handleClickOnBtnSeeRisk.bind(this));


		} else { // We hide the card risk content

			// We change the height of the current card risk
			containerSelector.css("height", "auto");

			// We add an active class on the content card risk
			contentSelector.removeClass("active");

			// We change the icon of the button
			$(e.currentTarget).find(".icon").removeClass("fa-angle-up");
			$(e.currentTarget).find(".icon").addClass("fa-angle-down");

			// We change the text of the button
			$(e.currentTarget).find(".text").html($.i18n("label-btn-more-content-card-risk"));

		}

	}

	handleClickOnBtnSeeRisk (e) {

		// Get the ID of the current risk
		const curIdRisk = $(e.currentTarget).parents(".card-risk").attr("data-id-risk");

		let routeToShow;

		if (window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all legal entities context
			routeToShow = `/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") >= 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") < 0) {

			// We show the risk page with all profit centers context
			routeToShow = `/profit-centers/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else if (window.location.pathname.indexOf("/profit-centers/all") < 0 && window.location.pathname.indexOf("/legal-entities/all") >= 0 && window.location.pathname.indexOf("/countries/all") >= 0) {

			// We show the risk page with all countries context
			routeToShow = `/countries/all/legal-entities/all/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		} else {

			// We show the risk page
			routeToShow = `/legal-entities/${context.getCurrentLegalEntityId()}/cartographies/${cartographies.getCurrentCartographyId()}/risks/${curIdRisk}`;

		}

		let curContextBusinessActivity;

		// We add the business activity if necessary
		if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") < 0
		) {

			const idBusinessActivity = $("#name-business-activity .option-selected").attr("data-business-activity-id");

			curContextBusinessActivity = `/business-activities/${idBusinessActivity}`;

		} else if (
			window.location.pathname.indexOf("/business-activities/") >= 0 &&
			window.location.pathname.indexOf("/business-activities/all") >= 0
		) {

			curContextBusinessActivity = "/business-activities/all";

		} else {

			curContextBusinessActivity = "";

		}

		// We add the redirection
		if (routeToShow !== undefined && routeToShow !== null) {

			router.navigate(routeToShow + curContextBusinessActivity);

		}

	}

	handleClickOnOptionLegalEntity (e) {

		// We init risks data
		this.initRisks();

	}

}
export default new Catalog();
